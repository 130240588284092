import { createSelector } from 'reselect';

export const selectSlackAuthCallbackState = state => state.SlackAuthCallback;

export const selectError = createSelector(
  selectSlackAuthCallbackState,
  (state, props) => state.error
);

export const selectFetchingStart = createSelector(
  selectSlackAuthCallbackState,
  state => state.fetchingStart
);

export const selectAuthenticatingWithSlack = createSelector(
  selectSlackAuthCallbackState,
  state => state.authenticatingWithSlack
);
