import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';
import { errorsToSingleMessage } from 'utils/graphql-errors';

const initialState = Immutable({
  fetching: false,
  error: false,
  claimantPercentile: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ClAIMANT_PERCENTILE.START: {
      return state.merge({ fetching: true, error: false }, { deep: true });
    }

    case actionTypes.ClAIMANT_PERCENTILE.SUCCESS: {
      const { x, y, claimantPercentile, claimantScore } = action.payload;
      return state.merge(
        {
          fetching: false,
          error: false,
          claimantPercentile: {
            x,
            y,
            claimantPercentile,
            claimantScore,
          },
        },
        { deep: true }
      );
    }
    case actionTypes.ClAIMANT_PERCENTILE.FAILURE: {
      const { errors } = action.payload;
      return state.merge(
        { fetching: false, error: errorsToSingleMessage({ errors }) },
        { deep: true }
      );
    }
    default:
      return state;
  }
}
