import React from 'react';
import { Modal } from 'reactstrap';

import classnames from 'classnames';
import styles from './index.module.scss';

const ModalV2 = ({ className, children, ...passThrough }) => {
  return (
    <Modal className={classnames(className, styles.modalDialog)} {...passThrough}>
      {children}
    </Modal>
  );
};

export default ModalV2;
