import React, { Component } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import TextField from 'ui/common/form/fields/text-field';
import SubmitButton from 'ui/common/form/submit-button';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { messageTypes } from 'store/features/common/flash/builder';
import { addMessage } from 'store/features/common/flash/actions';
import { fetchClaim } from 'store/features/claims/actions';
const { request: fetchClaimRequest } = fetchClaim;
const fieldNames = {
  feedback: 'feedback',
  improvements: 'suggestedImprovements',
};

const labels = defineMessages({
  feedback: {
    id: 'graphql.claim.feedback.comment',
    defaultMessage: 'Update Feedback',
  },
  suggestedImprovements: {
    id: 'graphql.claim.suggestedImprovements',
    defaultMessage: 'Suggested Improvements',
  },
});

const buttons = defineMessages({
  submit: {
    id: 'graphql.claimreview.buttons.submit-comment',
    defaultMessage: 'Submit',
  },
});

class EditFeedback extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
    this.state = {
      hideForm: false,
    };
  }

  async onSubmit(values) {
    const { client, addMessage, exp, fetchClaimRequest, claim: { id: claimId } } = this.props;
    const { feedback, suggestedImprovements } = values || {};

    try {
      await client.mutate({
        mutation: gql`
          mutation vote_editFeedback($voteId: String!, $updatedFeedback: VoteFeedbackInput) {
            vote_editFeedback(voteId: $voteId, updatedFeedback: $updatedFeedback) {
              explanation
              suggestedImprovements
            }
          }
        `,
        variables: {
          voteId: exp.voteId,
          updatedFeedback: {
            explanation: feedback ? feedback : exp.feedback,
            suggestedImprovements: suggestedImprovements
              ? suggestedImprovements
              : exp.suggestedImprovements,
          },
        },
      });
      this.props.reset();
      addMessage({
        kind: messageTypes.success,
        content: 'Review updated',
      });
      this.setState({
        hideForm: true,
      });
      fetchClaimRequest({ claimId });
    } catch (error) {
      alert(error);
    }
  }

  render() {
    const {
      className,
      handleSubmit,
      form: formName,
      intl: { formatMessage },
      initialValues,
    } = this.props;

    const { hideForm } = this.state;

    return (
      <div>
        {!hideForm && (
          <form className={classnames(className)} onSubmit={handleSubmit(this.onSubmit)}>
            <div className="col-10">
              {initialValues &&
                initialValues.feedback && (
                  <Field
                    name={fieldNames.feedback}
                    component={TextField}
                    rows={3}
                    multiLine
                    label={formatMessage(labels.feedback)}
                    hideLabel
                  />
                )}
              {initialValues &&
                initialValues.suggestedImprovements && (
                  <Field
                    name={fieldNames.improvements}
                    component={TextField}
                    rows={3}
                    multiLine
                    label={formatMessage(labels.suggestedImprovements)}
                    hideLabel
                  />
                )}
            </div>
            <div className="col-2 mt-3">
              <SubmitButton className="py-1" formName={formName}>
                {formatMessage(buttons.submit)}
              </SubmitButton>
            </div>
          </form>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {
      feedback: ownProps && ownProps.initialValues && ownProps.initialValues.feedback,
      suggestedImprovements:
        ownProps && ownProps.initialValues && ownProps.initialValues.suggestedImprovements,
    },
  };
}

const mapDispatchToProps = {
  addMessage,
  fetchClaimRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'edit-feedback-form',
    enableReinitialize: true,
  })(injectIntl(withApollo(EditFeedback)))
);
