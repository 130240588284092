/*
  PayForClaimReportButton
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { compose } from 'redux';
import { show as showModal } from 'redux-modal';

import StripeCheckoutButton from 'ui/components/stripe-checkout-button';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';

import messages from './messages';
import { selectCurrentUserEmail } from 'store/features/auth/selectors';
import { fetchClaim } from 'store/features/claims/actions';
import { fetchClaimPrice, payClaimPrice } from 'store/graphql/PayForClaimReportButton/actions';
import {
  selectIsFetchingPrice,
  selectClaimPrice,
  selectIsPaying,
} from 'store/graphql/PayForClaimReportButton/selectors';
import PayForClaimReportButtonReducer from 'store/graphql/PayForClaimReportButton/reducer';
import injectReducer from 'utils/injectReducer';

import fetchPriceQuery from './fetchPriceQuery.gql';
import payMutation from './payMutation.gql';

import SuccessModal, { MODAL_NAME as SUCCESS_MODAL_NAME } from './success-modal';
import * as ampHelper from 'services/amplitude-helper';

const { request: fetchClaimRequest } = fetchClaim;

const gatewayNames = {
  stripe: 'Stripe',
};

class PayForClaimReportButton extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  static propTypes = {
    claimId: PropTypes.string,
  };

  componentDidMount() {
    this.fetchPrice(this.props);
  }

  async fetchPrice(props) {
    const { client } = props;
    const {
      claimId,
      isFetchingPrice,
      fetchClaimPriceStart,
      fetchClaimPriceSuccess,
      fetchClaimPriceFailure,
      addMessage,
    } = props;

    if (isFetchingPrice) return;

    try {
      fetchClaimPriceStart({ claimId });
      const result = await client.query({ query: fetchPriceQuery, variables: { claimId } });

      if (resultHasErrors(result)) {
        throw new Error(errorsToSingleMessage({ errors: result.errors }));
      } else {
        fetchClaimPriceSuccess({ claimId, result: result.data.getClaimReportPrice });
      }
    } catch (error) {
      fetchClaimPriceFailure({ claimId, error: error.message });
      addMessage({ kind: messageTypes.danger, content: error.message });
    }
  }

  trackStripeButtonClick() {
    const { claimId, price: { amountInCents, currency } = {} } = this.props;
    ampHelper.logEvent('claim_report_pay_clicked', {
      gateway: gatewayNames.stripe,
      claimId,
      amountInCents,
      currency,
    });
  }

  trackStripePaymentSubmitted() {
    const { claimId, price: { amountInCents, currency } = {} } = this.props;
    ampHelper.logEvent('claim_report_pay_submitted', {
      gateway: gatewayNames.stripe,
      claimId,
      amountInCents,
      currency,
    });
  }

  trackStripePaymentSuccess({ paymentId, reportId }) {
    const { claimId, price: { amountInCents, currency } = {} } = this.props;
    ampHelper.logEvent('claim_report_pay_success', {
      gateway: gatewayNames.stripe,
      claimId,
      amountInCents,
      currency,
      paymentId,
    });
  }

  trackStripePaymentFailed({ error }) {
    const { claimId, price: { amountInCents, currency } = {} } = this.props;
    ampHelper.logEvent('claim_report_pay_failed', {
      gateway: gatewayNames.stripe,
      claimId,
      amountInCents,
      currency,
      error,
    });
  }

  async onStripeTokenReceived(token) {
    const { client } = this.props;
    const {
      claimId,
      payClaimPriceStart,
      payClaimPriceSuccess,
      payClaimPriceFailure,
      showModal,
      addMessage,
    } = this.props;

    try {
      const paymentInfo = {
        name: 'Stripe',
        paymentMethod: token.type,
        token: token.id,
      };
      payClaimPriceStart({ claimId, paymentInfo });
      this.trackStripePaymentSubmitted();
      const result = await client.mutate({
        mutation: payMutation,
        variables: { claimId, gateway: paymentInfo },
      });

      if (resultHasErrors(result)) {
        throw new Error(errorsToSingleMessage({ errors: result.errors }));
      } else {
        const data = result.data && result.data.captureClaimReportPayment;
        const payment = data && data.payment;
        const reportId = data && data.reportId;
        if (payment && payment.paymentStatus === 'CAPTURED') {
          payClaimPriceSuccess({ claimId, result: result.data.captureClaimReportPayment });
          showModal(SUCCESS_MODAL_NAME, { reportId });
          this.trackStripePaymentSuccess({ paymentId: payment._id, reportId });
          // fetchClaimRequest({ claimId }, { background: true });
        } else {
          throw new Error('Payment Failed');
        }
      }
    } catch (error) {
      payClaimPriceFailure({ claimId, error: error.message });
      addMessage({ kind: messageTypes.danger, content: error.message });
      this.trackStripePaymentFailed({
        error: typeof error.message === 'string' ? error.message : '',
      });
    }
  }

  render() {
    const { currentUserEmail, price, isPaying, intl: { formatMessage } } = this.props;

    return (
      <span>
        <StripeCheckoutButton
          isReady={true}
          loading={isPaying}
          disabled={!price}
          stripeOptions={{
            amountInCents: price ? price.amountInCents : 2000,
            currency: price ? price.currency : 'USD',
            onTokenReceived: this.onStripeTokenReceived,
            email: currentUserEmail,
            allowRememberMe: false,
          }}
          onClick={this.trackStripeButtonClick}
        >
          {isPaying
            ? formatMessage(messages.isPaying)
            : price
              ? formatMessage(messages.viewReportWithPrice, {
                  amount: price.amountInCents / 100,
                  currencySymbol: '$',
                  currency: price.currency,
                })
              : formatMessage(messages.viewReport)}
        </StripeCheckoutButton>
        <SuccessModal />
      </span>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { claimId } = ownProps;
  return {
    currentUserEmail: selectCurrentUserEmail(state),
    isFetchingPrice: selectIsFetchingPrice(state, { claimId }),
    isPaying: selectIsPaying(state, { claimId }),
    price: selectClaimPrice(state, { claimId }),
  };
}

const mapDispatchToProps = {
  fetchClaimPriceStart: fetchClaimPrice.start,
  fetchClaimPriceSuccess: fetchClaimPrice.success,
  fetchClaimPriceFailure: fetchClaimPrice.failure,
  payClaimPriceRequest: payClaimPrice.request,
  payClaimPriceStart: payClaimPrice.start,
  payClaimPriceSuccess: payClaimPrice.success,
  payClaimPriceFailure: payClaimPrice.failure,
  fetchClaimRequest,
  showModal,
  addMessage,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'PayForClaimReportButton',
  reducer: PayForClaimReportButtonReducer,
});

export default compose(withReducer, withConnect)(injectIntl(withApollo(PayForClaimReportButton)));
