import { defineMessages } from 'react-intl';

export default defineMessages({
  emailVerified: {
    id: 'auth.messages.email-verified',
    defaultMessage: 'Your email is verified successfully',
  },
  loggedIn: {
    id: 'auth.messages.logged-in',
    defaultMessage: 'Logged in successfully',
  },
  passwordChanged: {
    id: 'auth.messages.password-changed',
    defaultMessage: 'Password changed successfully',
  },
  passwordSet: {
    id: 'auth.messages.password-set',
    defaultMessage: 'Password set successfully',
  },
  passwordResetSuccess: {
    id: 'auth.messages.password-reset-success',
    defaultMessage: 'Password reset successfully. Please login to continue',
  },
  authenticationRequired: {
    id: 'auth.messages.authentication-required',
    defaultMessage: 'You need to login first',
  },
  reauthenticationRequired: {
    id: 'auth.messages.reauthentication-required',
    defaultMessage: 'You need to login again',
  },
  accountDeleted: {
    id: 'auth.messages.accountDeleted',
    defaultMessage: 'This account is deleted.',
  },
  unableToFindUser: {
    id: 'auth.messages.unableToFindUser',
    defaultMessage: 'Unable to find user.',
  },
  unauthorized: {
    id: 'auth.messages.unauthorized',
    defaultMessage: 'You are not allowed to perform this action',
  },
  confirmEthereumAddress: {
    id: 'auth.messages.confirmEthereumAddress',
    defaultMessage: `
    <p class="lead">
    Please confirm your Ethereum Wallet Address. Once set, this address can not be changed!
    This address will be used for your reputation score and to receive IND tokens.
    <br />
    <strong>{ethereumAddress}</strong>
    </p>`,
  },
  facebookAuthError: {
    id: 'auth.messages.facebookAuthError',
    defaultMessage: 'Facebook Auth Failed. Please try again',
  },
  googleAuthError: {
    id: 'auth.messages.googleAuthError',
    defaultMessage: 'Google Auth Failed. Please try again',
  },
  linkedInAuthError: {
    id: 'auth.messages.linkedInAuthError',
    defaultMessage: 'LinkedIn Auth Failed. Please try again',
  },
  uPortAuthError: {
    id: 'auth.messages.uPortAuthError',
    defaultMessage: 'uPort Auth Failed. Please try again',
  },
  externalAuthSignUpAdditionalFieldsRequired: {
    id: 'auth.messages.externalAuthSignUpAdditionalFieldsRequired',
    defaultMessage: 'Please fill following fields to complete signup process',
  },
  resendVerificationEmail: {
    id: 'auth.messages.resendVerificationEmail',
    defaultMessage:
      'If a user with this email exists and the email is unverified, an email will be sent to you shortly along with a verification link.',
  },
});
