import { combineReducers } from 'redux';

import common from './common/reducer';
import auth from './auth/reducer';
import users from './users/reducer';
import claims from './claims/reducer';
import votes from './votes/reducer';
import admin from './admin/reducer';
import workExperience from './work-experience/reducer';
import education from './education/reducer';
import companies from './companies/reducer';
import badges from './badges/reducer';
import ratingCriterias from './rating-criterias/reducer';
import wizardStatuses from './wizard/reducer';
import interests from './interests/reducer';
import professions from './professions/reducer';
import ethereum from './ethereum/reducer';
import claimReports from './claim-reports/reducer';

// DO NOT REMOVE new-reducer-import. The generator needs this to insert new reducer
import smartRoute from './smart-route/reducer';
// new-reducer-import

export default combineReducers({
  ethereum,
  common,
  auth,
  users,
  claims,
  votes,
  admin,
  workExperience,
  education,
  companies,
  badges,
  wizardStatuses,
  ratingCriterias,
  interests,
  // DO NOT REMOVE new-reducer-combine. The generator needs this to insert new reducer
  smartRoute,
  // new-reducer-combine
  professions,
  claimReports,
});
