import routeTemplates from 'ui/common/routes/templates';
import { createMatchSelector } from 'ui/common/routes/selectors';

export const wizardNames = {
  signUp: 'signUp',
};

export const componentNames = {
  welcome: 'WelcomePage',
  interests: 'InterestsForm',
  skills: 'SkillsForm',
  socialLinks: 'SocialLinksForm',
  earnedBadge: 'EarnedBadgePage',
};

export const whitelistUrlMatchers = {
  acceptAdvisorConnection: createMatchSelector({
    path: routeTemplates.companies.acceptAdvisorConnection,
    exact: true,
  }),
};

export function belongsToWhitelistUrl(state) {
  const urls = Object.keys(whitelistUrlMatchers);
  for (let i = 0; i < urls.length; i++) {
    const match = whitelistUrlMatchers[urls[i]](state);
    if (match) return match;
  }
  return false;
}
