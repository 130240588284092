import { defineMessages } from 'react-intl';

import { fieldNames } from './model';

export const labels = defineMessages({
  [fieldNames.acceptTermsAndConditions]: {
    id: `auth.consent.fields.acceptTermsAndCondition.label`,
    defaultMessage:
      'By checking this box, I confirm that I have read and agree to the updated {terms} and {privacy}. I also acknowledge that I am of the proper age to give consent in my jurisdiction.',
  },
});

export const header = defineMessages({
  title: {
    id: 'auth.consent.header.title',
    defaultMessage: 'We have updated our Terms of use and Privacy Policy',
  },
});

export const content = defineMessages({
  description: {
    id: 'auth.consent.content.description',
    defaultMessage:
      "By agreeing to the policies below, you are consenting to Indorse, Indorse's partners, and third party vendors using your personal data to provide our offerings, and improve our services for you as more fully described in our Privacy Policies page",
  },
  chooseToDelete: {
    id: 'auth.consent.content.chooseToDelete',
    defaultMessage: 'Or you can choose to {link} your account.',
  },
  pageTitle: {
    id: 'auth.consent.content.page-title',
    defaultMessage: 'Tada!',
  },
  generalPageTitle: {
    id: 'auth.consent.content.general-page-title',
    defaultMessage: 'Welcome back!',
  },
  pageSubTitle: {
    id: 'auth.consent.content.page-sub-title',
    defaultMessage:
      'Get access to hundreds of industry experts worldwide ready to assess your tech candidates. All at your fingertips, in one push of a button.',
  },
  emsPageSubTitle: {
    id: 'auth.consent.content.ems-page-sub-title',
    defaultMessage:
      'The data & insights you need to develop, sustain and empower a high performing tech team!',
  },
  generalPageSubTitle: {
    id: 'auth.consent.content.general-page-sub-title',
    defaultMessage:
      "We've updated our Terms of Use and Privacy Policy. Please agree to those before continuing.",
  },
  pageCompanies: {
    id: 'auth.consent.content.page-companies',
    defaultMessage: "You're in good company.",
  },
});

export const buttons = defineMessages({
  submit: {
    id: 'auth.consent.buttons.submit',
    defaultMessage: 'Get Started',
  },
  agree: {
    id: 'auth.consent.buttons.agree',
    defaultMessage: 'I agree',
  },
  delete: {
    id: 'auth.consent.buttons.delete',
    defaultMessage: 'here',
  },
});
