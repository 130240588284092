import React, { Component } from 'react';
import { connect } from 'react-redux';

import GithubAuthButton from 'ui/graphql/GithubAuthButton';
import LinkGithubButton from 'ui/graphql/LinkGithubButton';
import Loading from 'ui/common/loading';

import * as storage from 'store/features/auth/storage';
import { githubMethod } from 'resources/github';
import { selectAuthenticatingWithGithub } from 'store/graphql/GithubAuthCallback/selectors';
import { selectLoginWithGithub } from 'store/graphql/GithubAuthButton/selectors';
import { selectLinkingWithGithub } from 'store/graphql/LinkGithubButton/selectors';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import GithubAuthCallbackReducer from 'store/graphql/GithubAuthCallback/reducer';

export class GithubAuthCallback extends Component {
  render() {
    const { isAuthenticatingWithGithub } = this.props;
    const authenticationWithGithub = storage.getDataInLocalStorage(
      githubMethod.authenticationWithGithub
    );
    const linkingWithGithub = storage.getDataInLocalStorage(githubMethod.linkingWithGithub);
    if (isAuthenticatingWithGithub) return <Loading />;

    return (
      <div>
        {authenticationWithGithub && <GithubAuthButton />}
        {linkingWithGithub && <LinkGithubButton />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isAuthenticatingWithGithub = selectAuthenticatingWithGithub(state);
  const isLoginWithGithub = selectLoginWithGithub(state);
  const isLinkingWithGithub = selectLinkingWithGithub(state);

  return {
    isAuthenticatingWithGithub,
    isLoginWithGithub,
    isLinkingWithGithub,
  };
}

const withConnect = connect(mapStateToProps, null);
const withReducer = injectReducer({
  key: 'GithubAuthCallback',
  reducer: GithubAuthCallbackReducer,
});

export default compose(withReducer, withConnect)(GithubAuthCallback);
