import { defineMessages } from 'react-intl';

export default defineMessages({
  myClaimsBreadcrumb: {
    id: 'graphql.ClaimViewPdf.breadcrumb.my-claims',
    defaultMessage: 'My Claims',
  },
  myVotesBreadcrumb: {
    id: 'graphql.ClaimViewPdf.breadcrumb.my-votes',
    defaultMessage: 'My Votes',
  },
  titleWithLevel: {
    id: 'graphql.ClaimViewPdf.title-with-level',
    defaultMessage: 'I can code in {skillName} at the level of {level}',
  },
  titleWithoutLevel: {
    id: 'graphql.ClaimViewPdf.title-without-level',
    defaultMessage: 'I can code in {skillName}',
  },
  titleForAssignmentClaim: {
    id: 'graphql.ClaimViewPdf.title-for-assignment-claim',
    defaultMessage: 'I have solved a {jobRole} assignment in {skills}',
  },
  proofLabel: {
    id: 'graphql.ClaimViewPdf.proof.label',
    defaultMessage: 'You can view my work at:',
  },
  claimStatus: {
    id: 'graphql.ClaimViewPdf.claim-status.label',
    defaultMessage: 'Claim Status:',
  },
  voting: {
    id: 'graphql.ClaimViewPdf.voting.label',
    defaultMessage: 'Voting',
  },
  selectSkillToVote: {
    id: 'graphql.ClaimViewPdf.selectSkillToVote.label',
    defaultMessage: 'Please select a skill to vote',
  },
  signVote: {
    id: 'graphql.ClaimViewPdf.signVote.label',
    defaultMessage: 'Sign your vote',
  },
  claimPartnerName: {
    id: 'graphql.ClaimViewPdf.claim-partner-name.label',
    defaultMessage: 'From partner:',
  },
});

export const content = defineMessages({
  toolTipsHeader: {
    id: 'graphql.ClaimViewPdf.feedbacks.toolTipsHeader',
    defaultMessage: 'What are you voted for?',
  },
  feedbackSubtitle: {
    id: 'graphql.ClaimViewPdf.feedbacks.feedbackSubtitle',
    defaultMessage: 'Feedback by the validators',
  },
  poorRating: {
    id: 'graphql.ClaimViewPdf.feedbacks.rating.poorRating',
    defaultMessage: `Poor`,
  },
  goodRating: {
    id: 'graphql.ClaimViewPdf.feedbacks.rating.goodRating',
    defaultMessage: `Good`,
  },
});

export const feedbackSectionMessages = defineMessages({
  feedback: {
    id: 'graphql.ClaimViewPdf.feedbackSection.feedback',
    defaultMessage: 'Comments',
  },
  voted: {
    id: 'graphql.ClaimViewPdf.feedbackSection.voted',
    defaultMessage: 'Voted',
  },
  indorsed: {
    id: 'graphql.ClaimViewPdf.feedbackSection.recommended',
    defaultMessage: 'Recommended',
  },
  flagged: {
    id: 'graphql.ClaimViewPdf.feedbackSection.notRecommended',
    defaultMessage: 'Not Recommended',
  },
  actions: {
    id: 'graphql.ClaimViewPdf.feedbackSection.actions',
    defaultMessage: 'Actions',
  },
  hideBy: {
    id: 'graphql.ClaimViewPdf.feedbackSection.hideBy',
    defaultMessage: 'Hidden By',
  },
});

export const buttons = defineMessages({
  back: {
    id: 'graphql.ClaimViewPdf.buttons.back',
    defaultMessage: 'Back',
  },
  viewFeedback: {
    id: 'graphql.ClaimViewPdf.buttons.viewFeedback',
    defaultMessage: 'Feedback by the validators',
  },
  indorse: {
    id: 'graphql.ClaimViewPdf.buttons.recommend',
    defaultMessage: 'Recommend',
  },
  flag: {
    id: 'graphql.ClaimViewPdf.buttons.notRecommend',
    defaultMessage: 'Not Recommend',
  },
});

export const pdfViewMessages = defineMessages({
  title: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.title',
    defaultMessage: `Candidate's Coding Skill Report`,
  },
  status: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.status',
    defaultMessage: `Status:`,
  },
  numberOfVotes: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.numberOfVotes',
    defaultMessage: `No. of Votes:`,
  },
  skill: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.skill',
    defaultMessage: `Skill :`,
  },
  proficiencyLevel: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.proficiencyLevel',
    defaultMessage: `Proficiency :`,
  },
  votesReceived: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.votesReceived',
    defaultMessage: `Votes Received:`,
  },
  indorsedVotes: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.indorsedVotes',
    defaultMessage: `{voteCount, number} {voteCount, plural, 
      one {vote}
      other {votes}
    }`,
  },
  repositoryLink: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.repositoryLink',
    defaultMessage: `Submission Link:`,
  },
  goToPrintView: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.goToPrintView',
    defaultMessage: `Go To Print View`,
  },
  description: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.description',
    defaultMessage: `Description:`,
  },
  generatePDF: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.generatePDF',
    defaultMessage: `Generate PDF`,
  },
  claimPartnerName: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.claimPartnerName',
    defaultMessage: `From partner:`,
  },
  plagiarismWarning: {
    id: 'graphql.ClaimViewPdf.pdfViewMessages.plagiarismWarning',
    defaultMessage: `There's a high probability that this code has been plagiarised`,
  },
});

export const dialog = defineMessages({
  confirm: {
    id: 'graphql.ClaimViewPdf.feedbacks.confirm.dialog.message',
    defaultMessage:
      'Hide this comment only if it is inappropriate. If the comment is relevant but not elaborate, downvote instead.',
  },
  pdfDownloadMessage: {
    id: 'graphql.ClaimViewPdf.download.pdf.pricing',
    defaultMessage:
      'Thanks for your interest! Since we are in a beta phase, we are giving out the report to our first 1000 users for free! You can now download PDF from the button on the upper right corner.',
  },
});
