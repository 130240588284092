import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import StripePNG from './stripe.png';
import { logEvent } from 'services/amplitude-helper';

import classnames from 'classnames';
import styles from './index.module.scss';
import { show as showModal } from 'redux-modal';
// import { MODAL_NAME as GO_TO_DASHBOARD } from 'ui/graphql/EmsCreateBlanketTest/go-to-dashboard';
import Button from 'ui/common/button';
import { resultHasErrors } from 'utils/graphql-errors';
import { connect } from 'react-redux';
import { selectIsLoggedIn } from 'store/features/auth/selectors';
import { logUserProperties } from 'services/amplitude-helper';
// import routeGenerators from 'ui/common/routes/generators';

const ConfirmationIfLoggedIn = () => {
  return (
    <div>
      <h2 className="text-center" style={{ fontSize: '24px' }}>
        Almost done!
      </h2>
      <div className={classnames(styles.pointContainer)}>
        <div className="row justify-content-left mt-4">
          <i
            style={{ color: '#00B1A4', fontSize: '1rem' }}
            className="fa fa-check-circle mr-2 mt-1"
          />
          <p className={classnames(styles.point)}>Your card has been added successfully.</p>
        </div>
        <div className="row justify-content-left mt-4">
          <i
            style={{ color: '#00B1A4', fontSize: '1rem' }}
            className="fa fa-check-circle mr-2 mt-1"
          />
          <p className={classnames(styles.point)}>Your 14 days free trial has been activated.</p>
        </div>
        <div className={'text-center px-5 mt-4 text-muted'}>
          <p style={{ fontSize: '16px', lineHeight: '24px' }}>
            To access the dashboard for Metamorph, please go to your inbox and follow the access
            link in the email.
          </p>
        </div>
      </div>
    </div>
  );
};

const renderSuccess = props => {
  // function from props is called only if user is in setup wiz, else it is called from payment flow
  try {
    props.toNextPricingStep();
  } catch (e) {
    return <ConfirmationIfLoggedIn />;
  }
  // props.history.push(
  //   routeGenerators.companies.edit({
  //     prettyId: props.prettyId,
  //     section: 'engagement',
  //   })
  // );
  // props.showModal(GO_TO_DASHBOARD);
};

const CLIENT_LOGO_IMAGES = [
  {
    imgUrl:
      'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/home-credit.png',
  },

  {
    imgUrl: 'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/grab.svg',
  },
  {
    imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/times-internet-logo.png',
  },
  {
    imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/Adobe-Logo.svg',
  },
];

const ErrorResult = ({ children }) => <div className={styles.error}>{children}</div>;

const renderPaymentStatus = props => {
  return (
    <div>
      <div className={'row justify-content-center'}>
        <div className="col-md-11">
          <div className="row justify-content-center pt-3">
            <h3 className="text-center w-75">
              A verification email has been sent to you, please verify your email address to access
              your account!
            </h3>
          </div>
          <div className="row justify-content-center mb-5">
            {/* <Button
              textCenter
              isReady
              className={classnames(styles.stepBtn, 'px-4')}
              onClick={() => props.history.push(routeTemplates.root)}
            >
              Done
            </Button> */}

            <div className="my-5 text-center">
              <h3 style={{ fontSize: '20px' }}>You're in good company.</h3>
              <div className="d-flex justify-content-between align-items-center col-md-10 offset-sm-1">
                <img
                  src={CLIENT_LOGO_IMAGES[0].imgUrl}
                  alt="home credit"
                  style={{ width: '20%' }}
                />
                <img src={CLIENT_LOGO_IMAGES[1].imgUrl} alt="grab" style={{ width: '20%' }} />
                <img
                  className="mt-2"
                  src={CLIENT_LOGO_IMAGES[2].imgUrl}
                  alt="capita"
                  style={{ width: '20%', height: '60%' }}
                />
                <img
                  className="mt-2"
                  src={CLIENT_LOGO_IMAGES[3].imgUrl}
                  alt="singsaver"
                  style={{ width: '15%', height: '60%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StripeCheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();
  const { setShowDashboard } = props;

  const [isLoading, setLoading] = useState(false);
  const [showCheckoutForm, setCheckoutForm] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    setLoading(true); // show payment button loader

    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (payload.error) {
      setErrorMessage(payload.error.message);
      setLoading(false);
    } else {
      setErrorMessage(null);
      await createSubscription(payload);
    }
  };

  const createSubscription = async payload => {
    const { email, prettyId, isLoggedIn, couponName } = props;
    // const { plan, isAnnually } = params || {};

    let form = {
      paymentMethod: payload.paymentMethod.id,
      email,
      companyPrettyId: prettyId,
      coupon: couponName || null,
    };

    try {
      const result = await props.client.mutate({
        mutation: gql`
          mutation createMeteredSubscription($form: CreateMeteredSubscriptionForm!) {
            createMeteredSubscription(form: $form) {
              subscriptionStatus
            }
          }
        `,
        variables: {
          form,
        },
      });

      const { data, errors } = result;

      if (resultHasErrors({ errors })) {
        console.log(errors);
      } else {
        setLoading(false);
        const { createMeteredSubscription } = data;

        if (
          createMeteredSubscription.subscriptionStatus === 'active' ||
          createMeteredSubscription.subscriptionStatus === 'trialing'
        ) {
          setCheckoutForm(false);
          if (props.setDone) props.setDone();
        }
        if (isLoggedIn) {
          if (setShowDashboard) setShowDashboard();
          logEvent('ems_pricing_checkout_clicked');
        } else {
          if (window.amplitude) {
            const ampInstance = window.amplitude.getInstance();
            if (ampInstance) ampInstance.setUserId(email);
          }
          logUserProperties({
            isDirectSQL: true,
            isSQL: true,
            signup_source_email_pricing: true,
            // plan_selected: plan,
            // isAnnually,
          });
          logEvent('ems_pricing_payment_complete');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {showCheckoutForm ? (
        <React.Fragment className="pt-2">
          <label>Card Information</label>
          <CardElement
            hidePostalCode={true}
            className={classnames(styles.cardInput, 'border rounded')}
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />

          {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}

          <div className="row justify-content-center text-center pt-4">
            <img src={StripePNG} alt={'stripe'} />
          </div>

          {/*<div className="row justify-content-center pt-3 pb-3">*/}
          {/*  <h3 className="text-center w-75">*/}
          {/*    <span className={styles.boldText}>Note:</span> You won't be charged immediately. Your*/}
          {/*    billing starts once the free trial ends.*/}
          {/*  </h3>*/}
          {/*</div>*/}

          <div className="row justify-content-center pt-1 mt-5 pb-5">
            <Button
              textCenter
              isReady
              type="submit"
              onClick={event => handleSubmit(event)}
              disabled={!stripe}
              className={classnames(styles.stepBtn, 'px-4')}
              loading={isLoading}
            >
              Checkout
            </Button>
          </div>
        </React.Fragment>
      ) : (
        <div>{props.isLoggedIn ? renderSuccess(props) : renderPaymentStatus(props)}</div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoggedIn: selectIsLoggedIn(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
  };
}

export default withRouter(
  withApollo(connect(mapStateToProps, mapDispatchToProps)(StripeCheckoutForm))
);
