import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import themeConfig from 'ui/theme/config';
import styles from './index.module.scss';
// import LineTopSvg from 'resources/page/ems/metamorph/Line-top.svg';
// import LineBottomSvg from 'resources/page/ems/metamorph/Line-bottom.svg';
import LineSvg from 'resources/page/ems/metamorph/Line.svg';
import CirclePng from 'resources/page/ems/metamorph/Circle.png';

class HowItWorks extends Component {
  constructor() {
    super();
    this.state = { activeTab: 0 };
  }
  onSelectActiveTab(id) {
    this.setState({ activeTab: id });
  }

  renderTabDetails({ info, points, alt, img_url }) {
    return (
      <div className={classnames(styles.content, 'row justify-content-center ')}>
        <div className={classnames(styles.contentLeft, 'col-md-6')}>
          <img src={img_url} alt={alt} width="450" />
        </div>
        <div className={classnames(styles.contentRight, 'col-md-6')}>
          <h3 className={classnames(styles.info)}>{info}</h3>
          <div className={classnames(styles.info)}>
            {points.map((point, idx) => (
              <div className="d-flex justify-content-start my-3" key={idx}>
                <i
                  style={{ color: '#00B1A4', fontSize: '1rem' }}
                  className="fa fa-check-circle mr-3 mt-1"
                />
                <p>{point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderMobileTabDetails({ info, points, alt, img_url }) {
    return (
      <div className={classnames(styles.content, 'row justify-content-center')}>
        <div className={classnames('col-md-10 text-center')}>
          <img src={img_url} alt={alt} width="350" className="mx-auto mt-4" />
        </div>
        <div className={classnames('col-md-10')}>
          <h3 className={classnames(styles.info)}>{info}</h3>
          <div className={classnames(styles.info)}>
            {points.map((point, idx) => (
              <div className="d-flex justify-content-start my-3" key={idx}>
                <i
                  style={{ color: '#00B1A4', fontSize: '1rem' }}
                  className="fa fa-check-circle mr-3 mt-1"
                />
                <p>{point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderDesktop() {
    return (
      <div className="position-relative">
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold mt-4')}>
          How Metamorph works
        </h1>
        <h3 className={classnames(styles.mainSubtitle)}>Creating an engaged workforce!</h3>

        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-4 pt-5 pb-4">
            <h3 className={classnames(styles.step)}>STEP 1</h3>
          </div>
          <div className={classnames('col-4 pt-5 pb-4')}>
            <h3 className={classnames(styles.step)}>STEP 2</h3>
          </div>
          <div className={classnames('col-4 pt-5 pb-4')}>
            <h3 className={classnames(styles.step)}>STEP 3</h3>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-2">
          <div className="col-4">
            <img src={CirclePng} width="100%" alt="circle1" className={classnames(styles.circle)} />
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Coding+Micro-Tests.png"
              alt="pulse-survey"
              width="300"
              className="m-auto position-absolute"
              style={{ top: '1em', left: 0, right: 0 }}
            />
          </div>
          <div className={classnames('col-4')}>
            <img src={CirclePng} width="100%" alt="circle2" className={classnames(styles.circle)} />
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Actionable-Insights.png"
              alt="insights"
              width="300"
              className="m-auto position-absolute"
              style={{ top: '1em', left: 0, right: 0 }}
            />
          </div>

          <div className={classnames('col-4')}>
            <img src={CirclePng} width="100%" alt="circle3" className={classnames(styles.circle)} />
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Pulse+Survey.png"
              alt="micro-test"
              width="300"
              className="m-auto position-absolute"
              style={{ top: '1em', left: 0, right: 0 }}
            />
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-2">
          <div className="col-4">
            <h2 className={styles.heading}>Invite your team</h2>
          </div>
          <div className={classnames('col-4')}>
            <h2 className={styles.heading}>Pulse Surveys</h2>
          </div>

          <div className={classnames('col-4')}>
            <h2 className={styles.heading}>Improve engagement</h2>
          </div>
        </div>

        <div className="row justify-content-center align-items-start">
          <div className="col-4">
            <p className={classnames(styles.text)}>
              Invite your team through our guided Setup Wizard
            </p>
          </div>
          <div className={classnames('col-4')}>
            <p className={classnames(styles.text)}>
              Your developers spend less than 5 minutes to answer a few questions about their coding
              practices
            </p>
          </div>

          <div className={classnames('col-4')}>
            <p className={classnames(styles.text)}>
              We provide you with actionable insights and industry best practices to improve your
              team’s engagement
            </p>
          </div>
        </div>
        <div className={classnames(styles.lineOne)}>
          <img src={LineSvg} width="100%" alt="line1" />
        </div>
        <div className={classnames(styles.lineTwo)}>
          <img src={LineSvg} width="100%" alt="line2" />
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold mt-4')}>
          How Metamorph works
        </h1>
        <h3 className={classnames(styles.mainSubtitle)}>Creating an engaged workforce!</h3>

        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-12 mt-5 pt-2">
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Coding+Micro-Tests.png"
              alt="pulse-survey"
              width="350"
              className="mx-auto"
            />
          </div>
          <div className={classnames('col-10')}>
            <h3 className={classnames(styles.step)}>STEP 1</h3>
            <h2 className={styles.heading}>Invite your team</h2>
            <p className={classnames(styles.text)}>
              Invite your team through our guided Setup Wizard
            </p>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-12 mt-5">
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Actionable-Insights.png"
              alt="insights"
              width="350"
              className="mx-auto"
            />
          </div>
          <div className={classnames('col-10')}>
            <h3 className={classnames(styles.step)}>STEP 2</h3>
            <h2 className={styles.heading}>Pulse Surveys</h2>
            <p className={classnames(styles.text)}>
              Your developers spend less than 5 minutes to answer a few questions about their coding
              practices
            </p>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-5">
          <div className="col-12 mt-5">
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Pulse+Survey.png"
              alt="micro-test"
              width="350"
              className="mx-auto"
            />
          </div>
          <div className={classnames('col-10')}>
            <h3 className={classnames(styles.step)}>STEP 3</h3>
            <h2 className={styles.heading}>Improve engagement</h2>
            <p className={classnames(styles.text)}>
              We provide you with actionable insights and industry best practices to improve your
              team’s engagement
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.blueBackground)}>
        <div className={classnames('page-max-width pt-4 pb-5')}>
          <div className={classnames('container-fluid my-5')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(HowItWorks);
