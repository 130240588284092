import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import icons from './icon-list.json';
import './styles.scss';

/**
 * Returns a single icon Object
 * @param {string} name - "name" property of icon
 * @param {Object} [iconsObj=icons] - JSON Array of Objects
 * @example
 * // Returns a single icon Object
 * this.findIcon('copy-code', icons.json);
 */
export function findIcon(name, iconsObj = icons) {
  const icon = iconsObj.filter(obj => obj.name === name);

  if (icon.length === 0) {
    return false;
  } else if (icon.length > 1) {
    throw new Error('Multiple icons found...');
  } else {
    return icon[0];
  }
}

/**
 * Returns "svgData" Object
 * @param {string} iconName - "name" property of icon
 * @example
 * // Returns svgData Object for given iconName
 * this.getSvgData('copy-code');
 */
export function getSvgData(iconName) {
  const icon = findIcon(iconName);
  return icon ? icon.svgData : false;
}

/**
 * Returns Tooltip Element
 */
export function buildTooltip(tooltip) {
  return <div className="icon-tooltip arrow-down">{tooltip}</div>;
}

/**
 * Returns Elements/Nodes for SVG
 * @param {Object} svgData - JSON Object for an SVG icon
 * @example
 * // Returns SVG elements
 * const svgData = getSvgData('copy-code');
 * svgShapes(svgData);
 */
export function svgShapes(svgData) {
  const svgElements = Object.keys(svgData)
    .filter(key => svgData[key])
    .map(svgProp => {
      const data = svgData[svgProp];

      let returnData = [];

      if (svgProp === 'circles') {
        data.map((circle, index) => {
          const circleProps = {
            cx: circle.cx,
            cy: circle.cy,
            r: circle.r,
            key: `circle${index}`,
          };

          return returnData.push(<circle {...circleProps} />);
        });
      }
      if (svgProp === 'ellipses') {
        data.map((ellipse, index) => {
          const ellipseProps = {
            cx: ellipse.cx,
            cy: ellipse.cy,
            rx: ellipse.rx,
            ry: ellipse.ry,
            key: `circle${index}`,
          };
          return returnData.push(<ellipse {...ellipseProps} />);
        });
      }
      if (svgProp === 'paths') {
        data.map((path, index) => returnData.push(<path d={path.d} key={`key${index}`} />));
      }
      if (svgProp === 'polygons') {
        data.map((polygon, index) =>
          returnData.push(<polygon points={polygon.points} key={`key${index}`} />)
        );
      }
      if (svgProp === 'rects') {
        data.map((rect, index) => {
          const rectProps = {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
          };

          return returnData.push(<rect {...rectProps} key={`key${index}`} />);
        });
      }

      return returnData.map(element => element);
    });

  return svgElements;
}

export function isPrefixed(name) {
  return name.split('--')[0] === 'icon';
}

const Icon = ({
  children,
  className,
  description,
  fill,
  fillRule,
  height,
  name,
  role,
  style,
  background,
  inverted,
  width,
  iconRef,
  tooltip,
  onClick,
  disabled,
  ...other
}) => {
  const icon = isPrefixed(name) ? findIcon(name) : findIcon(`icon--${name}`);

  const IconClasses = classNames(className, {
    'icon-wrap': true,
    'with-background': background,
    inverted: inverted,
    disabled: disabled,
  });

  const props = {
    className,
    fill,
    fillRule,
    height: height || icon.height,
    name: isPrefixed ? name : `icon--${name}`,
    role,
    style,
    viewBox: icon.viewBox,
    width: width || icon.width,
    ref: iconRef,
    onClick,
    ...other,
  };

  const svgContent = icon ? svgShapes(icon.svgData) : '';

  const iconTooltip = tooltip && tooltip.length > 0 ? buildTooltip(tooltip) : '';

  const childrenWrapper = <div className="children">{children}</div>;

  return (
    <div className={IconClasses} onClick={onClick}>
      {svgContent && (
        <svg {...props} aria-label={description} alt={description}>
          <title>{description}</title>
          {svgContent}
        </svg>
      )}
      {children && childrenWrapper}
      {iconTooltip}
    </div>
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  height: PropTypes.string,
  role: PropTypes.string,
  style: PropTypes.object,
  background: PropTypes.bool,
  inverted: PropTypes.bool,
  viewBox: PropTypes.string,
  width: PropTypes.string,
  tooltip: PropTypes.string,
};

Icon.defaultProps = {
  background: false,
  inverted: false,
  role: 'img',
  tooltip: '',
  description: 'Provide a description that will be used as the title',
};

export { icons };
export default Icon;
