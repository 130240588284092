export function autoSuggestSchools(schoolName) {
  return {
    endpoint: 'findschool',
    method: 'POST',
    data: { schoolName },
    requireAuth: true,
  };
}

function sanitizeEducationEntry(values) {
  const finalValues = {};
  if (values.currentlyStudying || !values.endDate) delete values.endDate;

  if (values.skills) finalValues.skills = values.skills;
  if (values.schoolName) finalValues.schoolName = values.schoolName;
  if (values.schoolId) finalValues.schoolId = values.schoolId;
  if (values.fieldOfStudy) finalValues.fieldOfStudy = values.fieldOfStudy;
  if (values.grade) finalValues.grade = values.grade;
  if (values.activities) finalValues.activities = values.activities;
  if (values.description) finalValues.description = values.description;
  if (values.startDate) finalValues.startDate = values.startDate;
  if (values.endDate) finalValues.endDate = values.endDate;
  if (values.degree) finalValues.degree = values.degree;

  return finalValues;
}

export function addEducationEntry(values) {
  return {
    endpoint: 'updateeducation',
    method: 'POST',
    data: sanitizeEducationEntry(values),
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function updateEducationEntry({ id: itemKey, ...values }) {
  return {
    endpoint: 'updateeducation',
    method: 'POST',
    data: { itemKey, ...sanitizeEducationEntry(values) },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function deleteEducationEntry({ id: itemKey }) {
  return {
    endpoint: 'deleteeducation',
    method: 'POST',
    data: { itemKey },
    requireAuth: true,
  };
}
