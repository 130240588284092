import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

import { buildMessage } from './builder';

export const addMessage = ({ id, kind, content, dismissable, disableTimeout }) => {
  return defineAction(
    types.ADD_MESSAGE,
    buildMessage({
      id,
      kind,
      content,
      dismissable,
      disableTimeout,
    })
  );
};

export const removeMessage = id => defineAction(types.REMOVE_MESSAGE, { id });

export const removeAll = () => defineAction(types.REMOVE_ALL);
