import React from 'react';
import Icon from 'ui/common/icon';
import classnames from 'classnames';

export default ({ className, children, toggle, isOpen, ...passThrough }) => {
  return (
    <div className={classnames(className)} {...passThrough}>
      <div className="d-flex p-2 justify-content-between" onClick={toggle}>
        {children}
        <Icon
          name={isOpen ? 'fa fa-caret-down' : 'fa fa-caret-right'}
          className="mr-3 mt-3"
          description="More"
        />
      </div>
    </div>
  );
};
