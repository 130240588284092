import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';

import * as actionTypes from 'store/features/votes/action-types';

function currentUser(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.FETCH_CURRENT_USER_VOTES.START:
      return state.merge({
        fetching: true,
      });
    case actionTypes.FETCH_CURRENT_USER_VOTES.SUCCESS:
      return state.merge({
        fetching: false,
        lastFetchedAt: new Date().toISOString(),
      });
    case actionTypes.FETCH_CURRENT_USER_VOTES.FAILURE:
      return state.merge({
        fetching: false,
      });
    default:
      return state;
  }
}

function byId(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.FETCH_VOTE.START:
      const { voteId: fetchingVoteId } = action.payload;
      if (fetchingVoteId) {
        return state.merge({
          [fetchingVoteId]: {
            fetching: true,
          },
        });
      }
      return state;
    case actionTypes.FETCH_VOTE.SUCCESS:
    case actionTypes.FETCH_VOTE.FAILURE:
      const { voteId: fetchedVoteId } = action.payload;
      if (fetchedVoteId) {
        return state.merge({
          [fetchedVoteId]: {
            fetching: false,
          },
        });
      }
      return state;
    case actionTypes.REGISTER_TO_VOTE.START:
      const { voteId: registeringToVoteId } = action.payload;
      if (registeringToVoteId) {
        return state.merge({
          [registeringToVoteId]: {
            registering: true,
          },
        });
      }
      return state;
    case actionTypes.REGISTER_TO_VOTE.SUCCESS:
    case actionTypes.REGISTER_TO_VOTE.FAILURE:
      const { voteId: registeredToVoteId } = action.payload;
      if (registeredToVoteId) {
        return state.merge({
          [registeredToVoteId]: {
            registering: false,
          },
        });
      }
      return state;
    case actionTypes.ENDORSE.START:
      const { voteId: endorsingVoteId } = action.payload;
      if (endorsingVoteId) {
        return state.merge({
          [endorsingVoteId]: {
            endorsing: true,
          },
        });
      }
      return state;
    case actionTypes.ENDORSE.SUCCESS:
    case actionTypes.ENDORSE.FAILURE:
      const { voteId: endorsedVoteId } = action.payload;
      if (endorsedVoteId) {
        return state.merge({
          [endorsedVoteId]: {
            endorsing: false,
          },
        });
      }
      return state;
    case actionTypes.FLAG.START:
      const { voteId: flaggingVoteId } = action.payload;
      if (flaggingVoteId) {
        return state.merge({
          [flaggingVoteId]: {
            flagging: true,
          },
        });
      }
      return state;
    case actionTypes.FLAG.SUCCESS:
    case actionTypes.FLAG.FAILURE:
      const { voteId: flaggedVoteId } = action.payload;
      if (flaggedVoteId) {
        return state.merge({
          [flaggedVoteId]: {
            flagging: false,
          },
        });
      }
      return state;
    case actionTypes.CAST.START:
      const { voteId: castingVoteId, txHash: castingTxHash } = action.payload;
      if (castingVoteId) {
        return state.merge({
          [castingVoteId]: {
            casting: true,
            txHash: castingTxHash,
          },
        });
      }
      return state;
    case actionTypes.CAST.SUCCESS:
    case actionTypes.CAST.FAILURE:
      const { voteId: castedVoteId, txHash: castedTxHash } = action.payload;
      if (castedVoteId) {
        return state.merge({
          [castedVoteId]: {
            casting: false,
            txHash: castedTxHash,
          },
        });
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({
  currentUser,
  byId,
});
