export function fetchCurrentUserVotes() {
  return {
    endpoint: 'votes',
    method: 'GET',
    requireAuth: true,
  };
}

export function fetchVote({ voteId }) {
  return {
    endpoint: `votes/${voteId}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function registerToVote({ claimId }) {
  return {
    endpoint: `votes/${claimId}/register`,
    method: 'POST',
    requireAuth: true,
  };
}

export function endorse({ claimId, voteId, skillId, feedback, timeTakenForFeedback }) {
  return {
    endpoint: `votes/${voteId}`,
    method: 'POST',
    data: { endorse: true, feedback, skillid: skillId, timeTakenForFeedback },
    requireAuth: true,
    decamlizeInput: false,
  };
}

export function flag({
  claimId,
  voteId,
  skillId,
  feedback,
  timeTakenForFeedback,
  flaggedReasonType,
}) {
  return {
    endpoint: `votes/${voteId}`,
    method: 'POST',
    data: { endorse: false, feedback, skillid: skillId, timeTakenForFeedback, flaggedReasonType },
    requireAuth: true,
    decamlizeInput: false,
  };
}

export function submitTransactionInfo({ claimId, voteId, txHash }) {
  return {
    endpoint: `votes/${voteId}/txHash`,
    method: 'POST',
    data: { txHash },
    requireAuth: true,
    decamlizeInput: false,
  };
}
