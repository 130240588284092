import { createSelector } from 'reselect';

export const selectMetamorphLoginCallbackState = state => state.MetamorphLoginCallback;

export const selectError = createSelector(
  selectMetamorphLoginCallbackState,
  (state, props) => state.error
);

export const selectIsUpdating = createSelector(
  selectMetamorphLoginCallbackState,
  state => state.updating
);

export const selectPrettyId = createSelector(
  selectMetamorphLoginCallbackState,
  state => state.prettyId
);
