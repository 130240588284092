/*
	validator.js is used to validate all fields in any forms
	https://validatejs.org/#validators for all validations.
*/
export const fieldNames = {
  proof: 'proof',
};

export default {
  [fieldNames.proof]: {
    presence: true,
    url: true,
  },
};
