import { createSelector } from 'reselect';

export const selectAdminFetchMyCompaniesState = state =>
  (state && state.AdminFetchMyCompanies) || {};

export const selectMyCompanies = createSelector(
  selectAdminFetchMyCompaniesState,
  (state, props) => state.myCompanies || []
);

export const selectIsCurrentUserAdminOfCompanyWithId = createSelector(
  selectMyCompanies,
  (state, props) => props.companyId,
  (myCompanies, companyId) => (myCompanies || []).filter(myCompany => myCompany.id === companyId)
);

export const selectParams = createSelector(selectAdminFetchMyCompaniesState, state => state.params);

export const selectIsFetchingCompanies = createSelector(
  selectAdminFetchMyCompaniesState,
  state => state.fetching
);
