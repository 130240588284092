import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import './styles.scss';
import Icon from '../Icon/Icon';

export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectorShown: props.selectorShown || false,
    };
  }

  findOptionByValue(value) {
    const { options } = this.props;

    const filtered = (options || []).filter(option => option && option.value === value);

    return filtered.length > 0 ? filtered[0] : undefined;
  }

  findSelectedOption() {
    const { value } = this.props;
    return this.findOptionByValue(value);
  }

  toggleSelectorShown() {
    const { selectorShown } = this.state;
    this.setState({
      selectorShown: !selectorShown,
    });
  }

  updateSelection(evt, value) {
    this.toggleSelectorShown();
    const { onSelect } = this.props;

    if (typeof onSelect !== 'undefined') onSelect(value);
  }

  handleClickOutside(evt) {
    this.setState({
      selectorShown: false,
    });
  }

  render() {
    const { id, options, hasError, errorMessage, wrapperClass, disabled } = this.props;
    const { selectorShown } = this.state;
    const selectClasses = classNames(this.props.className, {
      'react-custom-select': true,
      'selector-shown': selectorShown,
      disabled: disabled,
      invalid: hasError,
    });
    const selectedOption = this.findSelectedOption();

    const selectProps = {
      className: selectClasses,
    };

    return (
      <div className={wrapperClass}>
        <div {...selectProps} tabIndex="0">
          {this.props.children !== undefined && (
            <div className="selection-label pb-2">{this.props.children}</div>
          )}
          {selectedOption ? (
            <div
              id={id}
              className="selection"
              onClick={() => !disabled && this.toggleSelectorShown()}
            >
              {selectedOption.text}
              <Icon name="icon--arrow-down" />
            </div>
          ) : (
            <div
              id={id}
              className="selection placeholder"
              onClick={() => !disabled && this.toggleSelectorShown()}
            >
              {this.props.placeholder}
              <Icon name="icon--arrow-down" />
            </div>
          )}

          {selectorShown && (
            <ul>
              {options.map(option => {
                const activeClasses = classNames({
                  active: selectedOption && selectedOption.value === option.value,
                });
                return (
                  <li
                    className={activeClasses}
                    key={`react-custom-select-${option.value}`}
                    onClick={evt => this.updateSelection(evt, option.value)}
                  >
                    {option.text}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {hasError && <p className="text-danger">{errorMessage}</p>}
      </div>
    );
  }
}

Select.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  selectorShown: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  wrapperClass: PropTypes.string,
};

Select.defaultProps = {
  options: [
    { text: 'one', value: 1, default: true },
    { text: 'two', value: 2 },
    { text: 'three', value: 3 },
    { text: 'four', value: 4 },
  ],
  wrapperClass: 'py-2',
};

export default onClickOutside(Select);
