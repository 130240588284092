import { defineApiActionTypes, defineConfirmationActionTypes } from 'store/common/action-helpers';

const prefix = 'features/votes';

export const FETCH_CURRENT_USER_VOTES = defineApiActionTypes(`${prefix}/FETCH_CURRENT_USER_VOTES`);

export const FETCH_VOTE = defineApiActionTypes(`${prefix}/FETCH_CURRENT_VOTE`);

export const REGISTER_TO_VOTE = defineApiActionTypes(`${prefix}/REGISTER_TO_VOTE`);

export const ENDORSE = defineApiActionTypes(`${prefix}/ENDORSE`);

export const FLAG = defineApiActionTypes(`${prefix}/FLAG`);

const CAST_CANCEL = defineConfirmationActionTypes(`${prefix}/CAST`).CANCEL;
export const CAST = { ...defineApiActionTypes(`${prefix}/CAST`), CANCEL: CAST_CANCEL };

export const CHECK_STATUS = defineApiActionTypes(`${prefix}/CHECK_STATUS`);
