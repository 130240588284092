import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/users';

export const APPROVE_USER = defineApiActionTypes(`${prefix}/APPROVE_USER`);

export const DISAPPROVE_USER = defineApiActionTypes(`${prefix}/DISAPPROVE_USER`);

export const ASSIGN_ROLE = defineApiActionTypes(`${prefix}/ASSIGN_ROLE`);

export const FETCH_USER_BY_ID = defineApiActionTypes(`${prefix}/FETCH_SINGLE_BY_ID`);

export const FETCH_USER_BY_USERNAME = defineApiActionTypes(`${prefix}/FETCH_SINGLE_BY_USERNAME`);

export const IMPORT_LINKEDIN_PDF = defineApiActionTypes(`${prefix}/IMPORT_LINKEDIN_PDF`);

export const SET_ETHEREUM_ADDRESS = defineApiActionTypes(`${prefix}/SET_ETHEREUM_ADDRESS`);

export const SET_CURRENT_USER_ETHEREUM_ADDRESS = defineApiActionTypes(
  `${prefix}/SET_CURRENT_USER_ETHEREUM_ADDRESS`
);

export const DELETE_CURRENT_USER = defineApiActionTypes(`${prefix}/DELETE_CURRENT_USER`);

export const UPDATE_CURRENT_PROFILE_SECTION = `${prefix}/UPDATE_CURRENT_PROFILE_SECTION`;

export const UPDATE_SKILL_IS_HIGHLIGHTED = `${prefix}/UPDATE_SKILL_IS_HIGHLIGHTED`;
