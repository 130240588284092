/*
  EmsContactForm
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { compose, bindActionCreators } from 'redux';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import { connectModal as reduxModal, hide as hideModal } from 'redux-modal';
import injectReducer from 'utils/injectReducer';
import classnames from 'classnames';
import SubmitButton from 'ui/common/form/submit-button';
import * as actionTypes from 'store/graphql/EmsContactForm/action-types';
import {
  selectIsFetching,
  selectIsUpdating,
  selectError,
} from 'store/graphql/EmsContactForm/selectors';
import EmsContactFormReducer from 'store/graphql/EmsContactForm/reducer';
import Button from 'ui/common/button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import ContactForm, { FORM_NAME } from './form';

export const MODAL_NAME = 'ems-contact-form-modal';

class EmsContactForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { showForm: props.showForm || false };
  }

  async onSubmit(values) {
    const {
      client,
      submitFormStart,
      submitFormSuccess,
      submitFormError,
      addMessage,
      hideModal,
    } = this.props;

    submitFormStart();

    try {
      const recaptchaToken = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
        { action: 'emscontactform' }
      );

      const resultFromGraphQl = await client.mutate({
        mutation: gql`
          mutation recordContactRequest($form: ContactRequestInput!) {
            recordContactRequest(form: $form)
          }
        `,
        variables: {
          form: {
            firstName: values.fullName && values.fullName.split(' ')[0],
            lastName: values.fullName && values.fullName.split(' ')[1],
            email: values.businessEmail,
            companyName: values.companyName,
            country: values.country,
            message: values.message,
          },
          context: {
            headers: {
              'x-recaptcha-token': recaptchaToken,
            },
          },
        },
      });

      if (resultFromGraphQl.error) {
        submitFormError(resultFromGraphQl.error);
      } else {
        submitFormSuccess();
        addMessage({
          kind: messageTypes.success,
          content: {
            id: 'contact-submission-recieved',
            defaultMessage: `Thanks! A product specialist will get back to you shortly.`,
          },
          disableTimeout: true,
        });
        hideModal(MODAL_NAME);
      }
    } catch (error) {
      submitFormError(error.message);
    }
  }

  render() {
    const { show, handleHide } = this.props;

    const { showForm } = this.state;

    return (
      <Modal isOpen={show} style={{ minWidth: '1000px' }} centered={true}>
        <ModalHeader toggle={handleHide} />
        <ModalBody>
          <h1 className={classnames('typography-section-headline-big text-center')}>
            Learn more about Indorse!
          </h1>
          {!showForm ? (
            <div className="row justify-content-center">
              <h2 className="col-9 text-center typography-section-headline-small">
                We know that the best way to understand if Indorse fits your needs is to do a guided
                tour with one of our specialists!
              </h2>
            </div>
          ) : (
            <div className="row justify-content-center">
              <h3 className="col-9 text-center typography-section-headline-small">
                Simply fill the form and start your guided tour with one of our super cool Indorse
                specialists!
              </h3>
              <ContactForm onSubmit={this.onSubmit} />
            </div>
          )}
        </ModalBody>
        <ModalFooter className="row m-auto pb-5">
          {!showForm ? (
            <div>
              <Button
                isReady={true}
                onClick={() => this.setState({ showForm: true })}
                className="m-auto py-3 px-4"
                style={{ fontSize: '24px' }}
              >
                My guided tour!
              </Button>
            </div>
          ) : (
            <div className="row justify-content-center">
              <div className="text-center col-8">
                <SubmitButton
                  isReady
                  formName={FORM_NAME}
                  explicitlyTriggerSubmit
                  className="m-auto py-3 px-4"
                  style={{ fontSize: '24px' }}
                >
                  Submit
                </SubmitButton>
              </div>
              <div className="text-center col-8 mt-4">
                <small className="text-muted">
                  Indorse will need these contact information you provide to contact you about this
                  specific product. You will not receive any email not related to the current
                  product. For our privacy practices, please review our{' '}
                  <a href="https://indorse.io/terms-of-use">Terms of Use</a> and{' '}
                  <a href="https://indorse.io/privacy-policy">Privacy Policy</a>
                  .
                </small>
              </div>
            </div>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    fetching: selectIsFetching(state),
    updating: selectIsUpdating(state),
    errorMessage: selectError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitFormStart: () => dispatch({ type: actionTypes.SUBMIT_FORM.START }),
    submitFormSuccess: () => dispatch({ type: actionTypes.SUBMIT_FORM.SUCCESS }),
    submitFormError: error =>
      dispatch({ type: actionTypes.SUBMIT_FORM.FAILURE, payload: { error } }),
    addMessage: bindActionCreators(addMessage, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'EmsContactForm',
  reducer: EmsContactFormReducer,
});

export default compose(withReducer, withConnect)(
  reduxModal({
    name: MODAL_NAME,
  })(injectIntl(withApollo(EmsContactForm)))
);
