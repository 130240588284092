import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/claims';

export const FETCH_USER_CLAIMS = defineApiActionTypes(`${prefix}/FETCH_USER_CLAIMS`);

export const CREATE_CLAIM = defineApiActionTypes(`${prefix}/CREATE`);

export const FETCH_CLAIM = defineApiActionTypes(`${prefix}/FETCH_SINGLE`);

export const VOTE_SUBMISSION_RECEIVED = `${prefix}/VOTE_SUBMISSION_RECEIVED`;
