import Immutable from 'seamless-immutable';

import * as userActionTypes from 'store/features/admin/users/action-types';

const defaultPerPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50;

const initialState = Immutable({
  ids: [],
  fetching: false,
  currentPage: null,
  perPage: defaultPerPage,
  totalUsers: 0,
  totalVerifiedUsers: 0,
  sort: { field: '', order: null },
  matchingUsers: 0,
  matchingVerifiedUsers: 0,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case userActionTypes.FETCH_USERS.REQUEST:
      const { pageNo: fetchingPage, perPage: fetchingPerPage, sort: fetchingSort } = action.payload;
      return state.merge({
        ids: [],
        fetching: true,
        currentPage: fetchingPage,
        perPage: fetchingPerPage || defaultPerPage,
        totalUsers: 0,
        totalVerifiedUsers: 0,
        matchingVerifiedUsers: 0,
        sort: fetchingSort,
        matchingUsers: 0,
      });
    case userActionTypes.FETCH_USERS.SUCCESS:
      const { pageNo: currentPage } = action.payload.input;
      const {
        users: fetchedUserIds,
        totalUsers,
        matchingUsers,
        totalVerifiedUsers,
        resultsetVerifiedUsers,
        matchingVerifiedUsers,
      } = action.payload.result;

      return state.merge({
        ids: fetchedUserIds,
        fetching: false,
        currentPage,
        totalUsers,
        totalVerifiedUsers,
        matchingVerifiedUsers: resultsetVerifiedUsers || matchingVerifiedUsers,
        matchingUsers: typeof matchingUsers === 'number' ? matchingUsers : totalUsers,
        error: null,
      });
    case userActionTypes.FETCH_USERS.FAILURE:
      return state.merge({
        ids: [],
        fetching: false,
      });
    default:
      return state;
  }
}
