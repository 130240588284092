import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import { parseApiDate } from 'store/common/schemas';
import moment from 'moment';
import * as messages from 'ui/users/delete/messages';
import Alert from 'ui/common/alert';

import { selectCurrentUserDeletionRequestedAt } from 'store/features/auth/selectors';

class RequestedDelete extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { currentUserDeletionRequestedAt, intl: { formatMessage } } = this.props;

    if (!currentUserDeletionRequestedAt) return null;

    const iso = parseApiDate(currentUserDeletionRequestedAt);

    return (
      <div className="row">
        <div className="col">
          <Alert color={'neutral'}>
            <FormattedHTMLMessage
              id="user.delete.requested"
              defaultMessage="We have received your request for account deletion on {requestedDate}. <br /> Your account will be deleted in 30 business days after receiving the request."
              values={{ requestedDate: moment(iso).format('YYYY-MM-DD HH:mm') }}
            />
            <br />
            <br />
            {formatMessage(messages.footer.needAssistace)}{' '}
            <a className="ml-1" href="mailto:help@indorse.io">
              help@indorse.io
            </a>
          </Alert>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUserDeletionRequestedAt: selectCurrentUserDeletionRequestedAt(state),
  };
}

export default connect(mapStateToProps, null)(injectIntl(RequestedDelete));
