/*
  ContactForm
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { reduxForm, Field } from 'redux-form';
import { withApollo } from 'react-apollo';

import TextField from 'ui/common/form/fields/text-field';
import validator from 'ui/common/form/validator';
import fields, { fieldNames } from './model';
import messages from './messages';
// import LocalMultiAutoCompleteField from 'ui/common/form/fields/local-multi-auto-complete-field';
// import productOptions from './options';

const validate = validator(fields);
export const FORM_NAME = 'ems-contact-form';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { intl: { formatMessage }, handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit} className="row justify-content-center">
        <div className="col-8">
          <Field
            label={formatMessage(messages[fieldNames.fullName])}
            name={fieldNames.fullName}
            component={TextField}
          />
        </div>
        <div className="col-8">
          <Field
            label={formatMessage(messages[fieldNames.businessEmail])}
            name={fieldNames.businessEmail}
            component={TextField}
          />
        </div>
        <div className="col-8">
          <Field
            label={formatMessage(messages[fieldNames.companyName])}
            name={fieldNames.companyName}
            component={TextField}
          />
        </div>
        <div className="col-8">
          <Field
            label={formatMessage(messages[fieldNames.country])}
            name={fieldNames.country}
            component={TextField}
          />
        </div>
        {/* Dont remove. Need this for main landing page*/}
        {/* <div className="col-8 my-3">
          <Field
            label={formatMessage(messages[fieldNames.product])}
            name={fieldNames.product}
            component={LocalMultiAutoCompleteField}
            options={productOptions}
            apiResponseConfig={{
              valueKey: 'name',
            }}
          />
        </div> */}
        <div className="col-8">
          <Field
            name={fieldNames.message}
            component={TextField}
            multiLine
            rows={4}
            characterCount
            characterCountMax={250}
            label={formatMessage(messages[fieldNames.message])}
          />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: FORM_NAME,
  validate,
})(injectIntl(withApollo(ContactForm)));
