import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { connectModal as reduxModal, hide as hideModal } from 'redux-modal';
import { Modal } from 'ui/common/modal';
import Button from 'ui/common/button';
import classnames from 'classnames';
import styles from '../exit-intent-modal/index.module.scss';
import UNVERIFIEND_MODAL from './unverified-modal.png';
import Icon from '../../../common/icon';
export const MODAL_NAME = 'ems-user-employee-modal';

class UserEmployeeModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  render() {
    const { show, handleHide, hideModal, email, adminEmail } = this.props;

    return (
      <Modal isOpen={show} size="sm">
        <div>
          <div className={'p-0 m-0'}>
            <div className={classnames('pb-3 m-0 px-0', styles.highlight)}>
              <div className={'d-flex justify-content-end pt-3 px-2'}>
                <Icon
                  onClick={() => handleHide()}
                  background
                  name="icon--close"
                  tooltip="Close"
                  description="Close"
                />
              </div>
              <div>
                <img
                  src={UNVERIFIEND_MODAL}
                  className={'mx-auto'}
                  alt={'intent-visual'}
                  width={'80%'}
                />
              </div>
              <div className={classnames(styles.last, 'mt-2 pt-1 px-4')}>
                <h3 className="text-center" style={{ fontSize: '16px' }}>
                  Metamorph by
                </h3>
                <img
                  alt="square"
                  width="150"
                  src={'https://indorse-staging-bucket.s3.amazonaws.com/indorse_logo.svg'}
                  className="m-auto"
                />
              </div>
            </div>
            <div className={'my-4 p-0 m-0'}>
              <div className={classnames('px-4', styles.unverifiedBodyMobile)}>
                <h2 className={classnames(styles.heading, 'mb-2')}>Already registered</h2>
                <hr className={styles.divider} />
                <p className={'mt-3'}>
                  You are already registered as a developer with the email address{' '}
                  <strong>{email}</strong>. Please contact your admin <strong>{adminEmail}</strong>{' '}
                  if you wish to manage the company account.
                </p>
                <Button
                  onClick={() => hideModal(MODAL_NAME)}
                  className={classnames('mt-4 px-4', styles.btn)}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(injectIntl(UserEmployeeModal))
);
