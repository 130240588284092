import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entitySelectors from 'store/entities/selectors';

export const selectSkillById = createSelector(
  entitySelectors.selectAllEntities,
  (state, props) => props.id,
  (entities, id) => denormalize(id, schemas.skill, entities)
);
