import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { Modal, ModalHeader, ModalBody } from 'ui/common/modal';
import { connectModal as reduxModal, hide as hideModal } from 'redux-modal';
import autoBind from 'react-autobind';
import styles from './index.module.scss';
// import Button from 'ui/common/button';
import greenCircle from 'resources/common/green-circle.svg';
import yellowSquare from 'resources/common/yellow-square.svg';
import orangeTriangle from 'resources/common/orange-triangle.svg';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { show as showModal } from 'redux-modal';
import { logEvent, logUserProperties } from 'services/amplitude-helper';
import { Field, reduxForm } from 'redux-form';
import FormError from 'ui/common/form/form-error';
import TextField from 'ui/common/form/fields/text-field';
import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';
import gql from 'graphql-tag';
import { messageTypes } from 'store/features/common/flash/builder';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';

import SubmitButton from 'ui/common/form/submit-button';
import validator from 'ui/common/form/validator';
import fields, { fieldNames } from './model';
import * as messages from './messages';
import * as storage from '../../../../store/features/auth/storage';
import routeGenerators from '../../../common/routes/generators';
import { MODAL_NAME as VERIFY_EMAIL_MODAL } from './verify-email-modal';
import { MODAL_NAME as LOGIN_LOGIN_USER_MODAL } from './login-user-modal';
import { MODAL_NAME as USER_EMPLOY_MODAL } from './user-employee-modal';
import { MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL } from './ems-company-exists-for-domain-modal';
import { MODAL_NAME as COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN } from './ems-company-exists-for-domain-modal-loggedin';
import * as authActionTypes from '../../../../store/features/auth/action-types';
import { withRouter } from 'react-router';

import LoginWithGoogleButton from 'ui/auth/metamorph/login-with-google';
import LoginWithFacebookButton from 'ui/auth/metamorph/login-with-facebook';
// import LoginWithLinkedInButton from 'ui/auth/metamorph/login-with-linkedin';
import LoginWithSlackButton from 'ui/auth/metamorph/login-with-slack';
import { MODAL_NAME as ERROR_MODAL } from './error-modal';

export const MODAL_NAME = 'ems-get-started-modal';
const validate = validator(fields);
const FORM_NAME = 'create-ems-company-beta';

const CLIENT_LOGO_IMAGES = [
  {
    imgUrl:
      'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/home-credit.png',
  },

  {
    imgUrl: 'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/grab.svg',
  },
  {
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/static-images/existing-customers/capita-sg.png',
  },
  {
    imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/Adobe-Logo.svg',
  },
];

class GetStartedModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isCompanyCreated: false,
    };
  }

  async onSendVerificationEmail(email) {
    const { client, addMessage } = this.props;

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'resend_verification_email' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_company_resendVerification($email: EmailAddress!) {
            ems_company_resendVerification(email: $email) {
              status
              error
            }
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { data, errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
      } else {
        localStorage.setItem('popup', 'false');
        const { ems_company_resendVerification } = data;
        const error = ems_company_resendVerification && ems_company_resendVerification.error;
        if (error) {
          addMessage({
            kind: messageTypes.danger,
            content:
              'You have already accepted the invitation or you are not an admin of any company!',
          });
        } else {
          const { status } = ems_company_resendVerification;
          if (status === 'EMAIL_SENT') {
            addMessage({
              kind: messageTypes.success,
              content: 'We have sent you a verification mail.',
            });
          }
        }
        this.props.hideModal(VERIFY_EMAIL_MODAL);
      }
    } catch (error) {
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
    }
  }

  async onSubmit(values) {
    const { email } = values;
    const {
      client,
      addMessage,
      intl: { formatMessage },
      loginSuccessRequest,
      showModal,
      handleHide,
    } = this.props;

    logEvent('LP_getstartedmodal_getstarted_clicked', {
      label: formatMessage(messages.buttons.submitButton),
      source: window && window.location.pathname,
    });

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_create_company($email: EmailAddress) {
            ems_create_company(email: $email) {
              status
              error
              token
              company {
                companyId
                companyPrettyId
                createdAt
              }
              emailDomainCompanies {
                acl {
                  email
                }
              }
            }
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { data, errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        logEvent('create_ems_company_failed', {
          error: errorsToSingleMessage({ errors }),
        });
      } else {
        // const { createCompany: { id } } = data;
        // if (id) {
        //   this.setState({
        //     isCompanyCreated: true,
        //   });
        // }
        // disable exit intent
        localStorage.setItem('popup', 'false');

        if (window.amplitude) {
          const ampInstance = window.amplitude.getInstance();
          window.localStorage.setItem('email', email);
          if (ampInstance) ampInstance.setUserId(email.toLowerCase());
        }
        logUserProperties({ signup_source: 'email' });
        logEvent('create_ems_company_waitlist_clicked', {
          label: formatMessage(messages.buttons.submitButton),
          source: window && window.location.pathname,
          email: email,
        });
        const { ems_create_company } = data;
        const error = ems_create_company && ems_create_company.error;
        if (!!error) {
          handleHide();
          showModal(ERROR_MODAL, {});
        } else {
          const { status, token, company, emailDomainCompanies } = ems_create_company;

          if (status === 'ACCOUNT_CREATED') {
            const { companyPrettyId } = company;
            storage.setToken(token);
            loginSuccessRequest({
              token,
              redirect: true,
              from: routeGenerators.companies.edit({
                prettyId: companyPrettyId,
              }),
            });
          } else if (status === 'ACCOUNT_UPSERTED') {
            const { companyPrettyId } = company;
            this.props.history.push(
              routeGenerators.companies.edit({
                prettyId: companyPrettyId,
              })
            );
          } else if (
            status === 'ACCOUNT_EXISTS_UNVERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_UNVERIFIED'
          ) {
            // Change here to show popup
            handleHide();
            showModal(VERIFY_EMAIL_MODAL, {
              email,
              onSubmit: () => this.onSendVerificationEmail(email),
            });
          } else if (
            status === 'ACCOUNT_EXISTS_VERIFIED' ||
            status === 'SAME_DOMAIN_SAME_EMAIL_COMPANY_EXISTS_VERIFIED'
          ) {
            // Change here to show popup
            handleHide();
            showModal(LOGIN_LOGIN_USER_MODAL, { email, onSubmit: () => null });
          } else if (status === 'USER_IS_EMPLOYEE') {
            // Change here to show popup
            handleHide();
            showModal(USER_EMPLOY_MODAL, { email, onSubmit: () => null });
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_EMAIL_SENT') {
            // Change here to show popup
            handleHide();
            showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL, { onSubmit: () => null });
          } else if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_LOGGED_IN') {
            try {
              const email =
                Array.isArray(emailDomainCompanies) && emailDomainCompanies.length > 0
                  ? emailDomainCompanies[0].acl[0].email
                  : null;
              showModal(COMPANY_EXISTS_EMAIL_SENT_MODAL_LOGGED_IN, { email });
            } catch (err) {
              showModal(ERROR_MODAL, {});
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
      logEvent('create_company_failed', {
        error: error.message,
        label: formatMessage(messages.buttons.submitButton),
      });
    }
  }

  renderCompanyCreatedThanksModal() {
    const { intl: { formatMessage } } = this.props;
    const { isCompanyCreated } = this.state;

    return (
      <Modal isOpen={isCompanyCreated} className="modal-lg">
        <ModalBody className="col-md-10 mt-5 offset-sm-1">
          <div className="pb-3 text-center">
            <h1
              className="text-center mx-3 indorse-blue mb-5"
              style={{ fontSize: '60px', fontWeight: '900' }}
            >
              {formatMessage(messages.content.pageTitle)}
            </h1>
            <div className="mt-4 text-center">
              <ul style={{ maxWidth: '32em' }} className="m-auto">
                <li className="ml-2">
                  <h3 style={{ fontSize: '20px', lineHeight: '30px' }} className="text-left">
                    To verify your email, please check your inbox.
                  </h3>
                </li>
                <li className="ml-2">
                  <h3 style={{ fontSize: '20px', lineHeight: '30px' }} className="text-left">
                    Do not forget to check your spam folder and whitelist our email -
                    info@indorse.io
                  </h3>
                </li>
              </ul>
            </div>
          </div>
          <hr className={classnames(styles.divider)} />
          <div className="my-5 text-center">
            <h3 style={{ fontSize: '20px' }}>{formatMessage(messages.content.pageCompanies)}</h3>
            <div className="d-flex justify-content-between align-items-center py-4 col-md-10 offset-sm-1">
              <img src={CLIENT_LOGO_IMAGES[0].imgUrl} alt="home credit" style={{ width: '20%' }} />
              <img src={CLIENT_LOGO_IMAGES[1].imgUrl} alt="grab" style={{ width: '20%' }} />
              <img
                className="mt-2"
                src={CLIENT_LOGO_IMAGES[2].imgUrl}
                alt="capita"
                style={{ width: '20%', height: '60%' }}
              />
              <img
                className="mt-2"
                src={CLIENT_LOGO_IMAGES[3].imgUrl}
                alt="singsaver"
                style={{ width: '15%', height: '60%' }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  render() {
    const { isCompanyCreated } = this.state;
    const {
      handleSubmit,
      intl: { formatMessage },
      form: formName,
      show,
      handleHide,
      invalid,
      from,
    } = this.props;

    return (
      <Modal size="lg" style={{ minWidth: '1000px' }} isOpen={show}>
        <div>
          <div className={'row p-0 m-0'}>
            <div className={classnames('col-5 py-5 m-0 px-0', styles.highlight)}>
              <div className={classnames(styles.column, 'mt-5 px-4')}>
                <img src={greenCircle} alt="square" className={classnames(styles.shape, 'mr-2')} />
                <h3>Setup in 20 seconds</h3>
              </div>
              <div className={classnames(styles.column, 'mt-4 pt-2 px-4')}>
                <img src={yellowSquare} alt="square" className={classnames(styles.shape, 'mr-2')} />
                <h3>Free for 5 users</h3>
              </div>
              <div className={classnames(styles.column, 'mt-4 pt-2 px-4')}>
                <img
                  src={orangeTriangle}
                  alt="square"
                  className={classnames(styles.shape, 'mr-2')}
                />
                <h3>Backed by research</h3>
              </div>
              <div className={classnames(styles.last, 'mt-3 pt-1 px-4')}>
                <h3 className="text-center">Metamorph by</h3>
                <img
                  alt="square"
                  width="200"
                  src={'https://indorse-staging-bucket.s3.amazonaws.com/indorse_logo.svg'}
                  className="m-auto"
                />
              </div>
              <div className={classnames(styles.visualOne)}>
                <img
                  src={'https://indorse-staging-bucket.s3.amazonaws.com/Login-Visuals-1.png'}
                  alt={'visual-1'}
                  width="100"
                />
              </div>
              <div className={classnames(styles.visualTwo)}>
                <img
                  src={'https://indorse-staging-bucket.s3.amazonaws.com/Login-Visuals-3.png'}
                  alt={'visual-2'}
                  width="100"
                />
              </div>
            </div>
            <div className={'col-7 p-0 m-0'}>
              <ModalHeader
                toggle={() => {
                  logEvent('LP_getstartedmodal_close_clicked');
                  handleHide();
                }}
              />
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <FormError formName={formName} />
                <div className="my-5 text-center">
                  <div className="my-4">
                    <LoginWithGoogleButton from={from} />
                  </div>

                  {/* TODO: fix linkedin login */}
                  {/* <div className="my-4">
                    <LoginWithLinkedInButton from={from} />
                  </div> */}

                  <div className="my-4">
                    <LoginWithSlackButton />
                  </div>

                  <div className="my-4">
                    <LoginWithFacebookButton from={from} />
                  </div>
                </div>
                <div className={classnames(styles.or, 'd-flex text-center py-2')}>
                  <hr className={classnames('mt-2', styles.line)} />
                  <h3 className="mx-3">OR</h3>
                  <hr className={classnames('mt-2', styles.line)} />
                </div>
                <div className="d-flex justify-content-center">
                  <div className="flex-grow-1 col-5">
                    <Field
                      name={fieldNames.adminEmail}
                      component={TextField}
                      label={formatMessage(messages.label[fieldNames.adminEmail])}
                      style={{ maxWidth: '250px' }}
                      data-hj-whitelist
                    />
                  </div>

                  <SubmitButton
                    isReady
                    formName={FORM_NAME}
                    className={classnames(styles.submitBtn, 'ml-4')}
                    disabled={invalid}
                  >
                    {formatMessage(messages.buttons.submitButton)}
                  </SubmitButton>
                </div>
              </form>
            </div>
          </div>
        </div>
        {isCompanyCreated && this.renderCompanyCreatedThanksModal()}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
    addMessage: bindActionCreators(addMessage, dispatch),
    loginSuccessRequest: ({ token, redirect, from }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
      }),
  };
}

function mapStateToProps(state, ownProps) {
  const from = ownProps && ownProps.location && ownProps.location.pathname;
  return { from };
}

export default withRouter(
  reduxModal({
    name: MODAL_NAME,
  })(
    connect(mapStateToProps, mapDispatchToProps)(
      reduxForm({
        form: FORM_NAME,
        validate,
      })(injectIntl(withApollo(GetStartedModal)))
    )
  )
);
