import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

export default function reducer(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.ADMIN_UPDATE_VALIDATOR_COMMENT.START: {
      return state.merge(
        {
          [action.payload.voteId]: {
            updating: true,
          },
        },
        { deep: true }
      );
    }

    case actionTypes.ADMIN_UPDATE_VALIDATOR_COMMENT.FAILURE: {
      const { error } = action.payload;

      return state.merge(
        {
          [action.payload.voteId]: {
            updating: false,
            error,
          },
        },
        { deep: true }
      );
    }

    case actionTypes.ADMIN_UPDATE_VALIDATOR_COMMENT.SUCCESS: {
      const { result } = action.payload;

      return state.merge({
        [action.payload.voteId]: {
          updating: false,
          error: false,
          validatorComment: {
            voteValidatorId: action.payload.voteValidatorId,
            ...result,
          },
        },
      });
    }

    default:
      return state;
  }
}
