import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const labels = defineMessages({
  [fieldNames.howItBenefits]: {
    id: 'graphql.exit-intent.howItBenefits',
    defaultMessage: 'Not clear how it benefits me',
  },
  [fieldNames.howItWorks]: {
    id: 'graphql.exit-intent.howItWorks',
    defaultMessage: 'Not clear how it works',
  },
  [fieldNames.featuresMissing]: {
    id: 'graphql.exit-intent.featuresMissing',
    defaultMessage: 'Critical feature missing',
  },
  [fieldNames.suggestions]: {
    id: 'graphql.exit-intent.suggestions',
    defaultMessage: 'Any suggestions',
  },
});
