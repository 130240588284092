export const fieldNames = {
  adminEmail: 'email',
};

export default {
  [fieldNames.adminEmail]: {
    presence: {
      message: '^Please enter your email',
    },
    email: true,
  },
};
