import React from 'react';
import V2CheckBox from 'ui/common/rebranding/components/Checkbox';

export default ({ input, label, meta, onClick }) => {
  const { name, onBlur, onChange, value, checked, ...passThrough } = input;

  const { form, touched, submitting, error } = meta;

  const inputId = `${form}-${input.name}-${value}`;

  const hasError = (touched || submitting) && value !== error && !!error;
  const errorText = Array.isArray(error) ? error.join('\n') : error;

  return (
    <V2CheckBox
      {...passThrough}
      id={inputId}
      labelText={label}
      isChecked={!!value}
      onChange={checked => {
        onChange(checked);
        onClick && onClick();
      }}
      hasError={hasError}
      errorMessage={hasError ? errorText : null}
    />
  );
};
