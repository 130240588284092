import { createSelector } from 'reselect';

const selectFetchAipSkillsState = state => (state && state.FetchAIPSkills) || {};

export const selectIsFetching = createSelector(selectFetchAipSkillsState, (state, props) => {
  return state.fetching;
});

export const selectAIPSkills = createSelector(selectFetchAipSkillsState, (state, props) => {
  return state.aipSkills;
});

export const selectError = createSelector(selectFetchAipSkillsState, (state, props) => state.error);
