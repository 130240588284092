import * as actionTypes from './action-types';
import { defineAction } from 'store/common/action-helpers';

export const fetchWizardDefinition = {
  request: ({ name }) => defineAction(actionTypes.FETCH_WIZARD_DEFINITION.REQUEST, { name }),
  start: ({ name }) => defineAction(actionTypes.FETCH_WIZARD_DEFINITION.START, { name }),
  success: ({ response }) =>
    defineAction(actionTypes.FETCH_WIZARD_DEFINITION.SUCCESS, { response }),
  failure: ({ error }) => defineAction(actionTypes.FETCH_WIZARD_DEFINITION.FAILURE, { error }),
};

export const fetchWizardStatus = {
  request: ({ name, apiConfig }) =>
    defineAction(actionTypes.FETCH_WIZARD_STATUS.REQUEST, { name, apiConfig }),
  start: ({ name, apiConfig }) =>
    defineAction(actionTypes.FETCH_WIZARD_STATUS.START, { name, apiConfig }),
  success: ({ name, response }) =>
    defineAction(actionTypes.FETCH_WIZARD_STATUS.SUCCESS, { name, response }),
  failure: ({ name, error }) =>
    defineAction(actionTypes.FETCH_WIZARD_STATUS.FAILURE, { name, error }),
};

export const completeStep = {
  request: ({ name, apiConfig, afterCompleteRoute }) =>
    defineAction(actionTypes.COMPLETE_STEP.REQUEST, { name, apiConfig }, { afterCompleteRoute }),
  start: ({ name, apiConfig }) =>
    defineAction(actionTypes.COMPLETE_STEP.START, { name, apiConfig }),
  success: ({ name, response }) =>
    defineAction(actionTypes.COMPLETE_STEP.SUCCESS, { name, response }),
  failure: ({ name, error }) => defineAction(actionTypes.COMPLETE_STEP.FAILURE, { name, error }),
};

export const finishWizard = {
  request: ({ name, apiConfig, afterCompleteRoute }) =>
    defineAction(actionTypes.FINISH_WIZARD.REQUEST, { name, apiConfig }, { afterCompleteRoute }),
  start: ({ name, apiConfig }) =>
    defineAction(actionTypes.FINISH_WIZARD.START, { name, apiConfig }),
  success: ({ name, response }) =>
    defineAction(actionTypes.FINISH_WIZARD.SUCCESS, { name, response }),
  failure: ({ name, error }) => defineAction(actionTypes.FINISH_WIZARD.FAILURE, { name, error }),
};

export const wizardFinished = ({ name, afterCompleteRoute }) =>
  defineAction(actionTypes.WIZARD_FINISHED, { name }, { afterCompleteRoute });

export const skipStep = {
  request: ({ name, apiConfig, afterCompleteRoute }) =>
    defineAction(actionTypes.SKIP_STEP.REQUEST, { name, apiConfig }, { afterCompleteRoute }),
  start: ({ name, apiConfig }) => defineAction(actionTypes.SKIP_STEP.START, { name, apiConfig }),
  success: ({ name, response }) => defineAction(actionTypes.SKIP_STEP.SUCCESS, { name, response }),
  failure: ({ name, error }) => defineAction(actionTypes.SKIP_STEP.FAILURE, { name, error }),
};
