import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { connectModal as reduxModal, hide as hideModal } from 'redux-modal';
import { Modal, ModalHeader } from 'ui/common/modal';
import Button from 'ui/common/button';
import classnames from 'classnames';
import styles from '../exit-intent-modal/index.module.scss';
import UNVERIFIEND_MODAL from './unverified-modal.png';
export const MODAL_NAME = 'ems-logged-in-user-get-started-modal';

class LoggedInUserGetStartedModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      loading: false,
    };
  }

  setLoading() {
    this.setState({ loading: true });
  }

  render() {
    const { show, handleHide, onSubmit } = this.props;

    return (
      <Modal isOpen={show} size="lg">
        <div>
          <div className={'row p-0 m-0'}>
            <div className={classnames('col-4 py-5 m-0 px-0', styles.highlight)}>
              <div className={'mt-4'}>
                <img src={UNVERIFIEND_MODAL} alt={'intent-visual'} width={'100%'} />
              </div>
              <div className={classnames(styles.last, 'mt-3 pt-1 px-4')}>
                <h3 className="text-center">Metamorph by</h3>
                <img
                  alt="square"
                  width="200"
                  src={'https://indorse-staging-bucket.s3.amazonaws.com/indorse_logo.svg'}
                  className="m-auto"
                />
              </div>
            </div>
            <div className={'col-8 p-0 m-0'}>
              <ModalHeader toggle={handleHide} />
              <div className={classnames('col-10 mx-auto', styles.unverifiedBody)}>
                <h2 className={classnames(styles.heading, 'mb-2')}>Confirm</h2>
                <hr className={styles.divider} />
                <p className={'mt-3'}>
                  This action will create a new Metamorph account for you with the same Indorse
                  credentials.
                </p>
                <Button
                  onClick={() => {
                    this.setLoading();
                    onSubmit();
                  }}
                  className={classnames('mt-4 px-4', styles.btn)}
                  loading={this.state.loading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(injectIntl(LoggedInUserGetStartedModal))
);
