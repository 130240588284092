import React from 'react';
import classnames from 'classnames';
import commonStyles from './index.module.scss';

import Hero from './hero';
// import Steps from './steps';
import Features from './features';
import Cta from './cta';
import ExistingCustomers from 'ui/pages/home-V2/existing-customers/loadable';
import Specialities from './specialities';
import HowItWorks from './how-it-works';
import { logEvent } from 'services/amplitude-helper';

class EMS extends React.Component {
  componentDidMount() {
    logEvent('landed_on_ems', {
      source: window && window.location.pathname,
    });

    const ampIns = window.amplitude.getInstance();

    if (window.localStorage.getItem('userId')) {
      ampIns.setUserId(window.localStorage.getItem('userId'));
    } else {
      const randomUserId = Math.random()
        .toString(36)
        .substr(2, 9);
      window.localStorage.setItem('userId', randomUserId);
      ampIns.setUserId(randomUserId);
    }
  }
  render() {
    return (
      <div className={classnames(commonStyles.home)}>
        <Hero />
        <ExistingCustomers />
        <HowItWorks />
        <Specialities />
        <Features />
        <Cta />
      </div>
    );
  }
}

export default EMS;
