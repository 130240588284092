import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/ethereum';

export const EXTRACT_BASIC_INFO_FROM_WEB3 = defineApiActionTypes(
  `${prefix}/EXTRACT_BASIC_INFO_FROM_WEB3`
);

export const POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3 = {
  REGISTER: `${prefix}/POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3_REGISTER`,
  UNREGISTER: `${prefix}/POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3_UNREGISTER`,
};
