import { createSelector } from 'reselect';

const selectAssignmentDetailsState = state => state.AssignmentDetails;

export const selectIsFetchingAssignment = createSelector(
  selectAssignmentDetailsState,
  (state, props) => state.fetching
);
export const selectError = createSelector(
  selectAssignmentDetailsState,
  (state, props) => state.error
);
export const selectAssignment = createSelector(
  selectAssignmentDetailsState,
  (state, props) => state.assignment
);
export const selectIsSubmittingAssignment = createSelector(
  selectAssignmentDetailsState,
  (state, props) => state.submitting
);
export const selectClaimIdFromFinishAssignment = createSelector(
  selectAssignmentDetailsState,
  (state, props) => state && state.assignment && state.assignment.claimId
);
