export const skillCategoryIds = {
  arts: 'arts',
  technology: 'technology',
  crafts: 'crafts',
  business: 'business',
  health: 'health',
  // culinary: 'culinary',
  service: 'service',
  education: 'education',
  misc: 'misc',
};

export const skillCategories = Object.keys(skillCategoryIds)
  .map(key => skillCategoryIds[key])
  .reduce((categories, categoryId) => {
    let name;

    if (categoryId === 'arts') {
      name = 'Arts & Design';
    } else if (categoryId === 'misc') {
      name = 'Miscellaneous';
    } else {
      name = categoryId[0].toUpperCase() + categoryId.substr(1);
    }

    categories[categoryId] = {
      id: categoryId,
      name,
      className: `cat-${categoryId}`,
    };
    return categories;
  }, {});
