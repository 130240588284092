import React from 'react';

import brand from 'resources/brand';

export default function() {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div
          className="col-12 col-md-8"
          dangerouslySetInnerHTML={{
            __html: brand.cookieConsent.content,
          }}
        />
      </div>
    </div>
  );
}
