import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import themeConfig from 'ui/theme/config';
import styles from './index.module.scss';
import { slackbotTabs } from 'resources/page/ems/metamorph';
import {
  CollapsibleCard,
  CollapsibleCardHeader,
  CollapsibleCardBody,
} from 'ui/common/collapsible-card';

class HowItWorks extends Component {
  constructor() {
    super();
    this.state = { activeTab: 0 };
  }
  onSelectActiveTab(id) {
    this.setState({ activeTab: id });
  }

  renderTabDetails({ info, points, alt, img_url }) {
    return (
      <div className={classnames(styles.content, 'row justify-content-center ')}>
        <div className={classnames(styles.contentLeft, 'col-md-6')}>
          <img key={alt} src={img_url} alt={alt} width="450" />
        </div>
        <div className={classnames(styles.contentRight, 'col-md-6')}>
          <h3 className={classnames(styles.info)}>{info}</h3>
          <div className={classnames(styles.info)}>
            {points.map((point, idx) => (
              <div className="d-flex justify-content-start my-3" key={idx}>
                <i
                  style={{ color: '#00B1A4', fontSize: '1rem' }}
                  className="fa fa-check-circle mr-3 mt-1"
                />
                <p>{point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderMobileTabDetails({ info, points, alt, img_url }) {
    return (
      <div className={classnames(styles.content, 'row justify-content-center')}>
        <div className={classnames('col-md-10 text-center')}>
          <img src={img_url} alt={alt} width="250" className="mx-auto mt-4 mb-3" />
        </div>
        <div className={classnames('col-md-10')}>
          <h3 className={classnames(styles.info)}>{info}</h3>
          <div className={classnames(styles.info)}>
            {points.map((point, idx) => (
              <div className="d-flex justify-content-start my-3" key={idx}>
                <i
                  style={{ color: '#00B1A4', fontSize: '1rem' }}
                  className="fa fa-check-circle mr-3 mt-1"
                />
                <p>{point}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderDesktop() {
    const { activeTab } = this.state;
    return (
      <div>
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold mt-4')}>How it works</h1>
        <h3 className={classnames(styles.mainSubtitle)}>
          Lead your team by better understanding their performance in software development
        </h3>

        <div className="row">
          <div className="col-12 mt-5 pt-2">
            <div className={classnames('d-flex mx-0', styles.tabContainer)}>
              <div className={'col-4 p-0'}>
                <div
                  className={classnames(
                    'd-flex align-items-center justify-content-center',
                    styles.tabItem,
                    activeTab === 0 ? styles.tabSelected : ''
                  )}
                  onClick={() => this.onSelectActiveTab(0)}
                >
                  <h2>
                    Coding <br /> micro-challenges
                  </h2>
                </div>
              </div>
              <div className={'col-4 p-0'}>
                <div
                  className={classnames(
                    'd-flex align-items-center justify-content-center',
                    styles.tabItem,
                    activeTab === 1 ? styles.tabSelected : ''
                  )}
                  onClick={() => this.onSelectActiveTab(1)}
                >
                  <h2>Pulse Surveys</h2>
                </div>
              </div>
              <div className={'col-4 p-0'}>
                <div
                  className={classnames(
                    'd-flex align-items-center justify-content-center',
                    styles.tabItem,
                    activeTab === 2 ? styles.tabSelected : ''
                  )}
                  onClick={() => this.onSelectActiveTab(2)}
                >
                  <h2>Continuous Insights</h2>
                </div>
              </div>
            </div>
            <div className={classnames(styles.card, 'm-auto')}>
              {this.renderTabDetails(slackbotTabs[activeTab])}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    const { activeTab } = this.state;
    return (
      <div>
        <h1 className={classnames(styles.mainTitle, 'font-weight-bold mt-4')}>How it works</h1>
        <h3 className={classnames(styles.mainSubtitle)}>
          Lead your team by better understanding their performance in software development
        </h3>

        <div className="row">
          <div className="col-12 mt-2 pt-2">
            <CollapsibleCard>
              <CollapsibleCardHeader
                isOpen={activeTab === 0}
                className="py-2 pl-2 pointer"
                toggle={() => this.onSelectActiveTab(0)}
              >
                <h2 className={styles.heading}>Coding micro-challenges</h2>
              </CollapsibleCardHeader>
              <CollapsibleCardBody isOpen={activeTab === 0}>
                <div className={classnames(styles.card, 'm-auto')}>
                  {this.renderMobileTabDetails(slackbotTabs[activeTab])}
                </div>
              </CollapsibleCardBody>
            </CollapsibleCard>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-2 pt-2">
            <CollapsibleCard>
              <CollapsibleCardHeader
                isOpen={activeTab === 1}
                className="py-2 pl-2 pointer"
                toggle={() => this.onSelectActiveTab(1)}
              >
                <h2 className={styles.heading}>Pulse Surveys</h2>
              </CollapsibleCardHeader>
              <CollapsibleCardBody isOpen={activeTab === 1}>
                <div className={classnames(styles.card, 'm-auto')}>
                  {this.renderMobileTabDetails(slackbotTabs[activeTab])}
                </div>
              </CollapsibleCardBody>
            </CollapsibleCard>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-2 pt-2">
            <CollapsibleCard>
              <CollapsibleCardHeader
                isOpen={activeTab === 2}
                className="py-2 pl-2 pointer"
                toggle={() => this.onSelectActiveTab(2)}
              >
                <h2 className={styles.heading}>Continuous Insights</h2>
              </CollapsibleCardHeader>
              <CollapsibleCardBody isOpen={activeTab === 2}>
                <div className={classnames(styles.card, 'm-auto')}>
                  {this.renderMobileTabDetails(slackbotTabs[activeTab])}
                </div>
              </CollapsibleCardBody>
            </CollapsibleCard>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.blueBackground)}>
        <div className={classnames('page-max-width pt-4 pb-5')}>
          <div className={classnames('container-fluid my-5')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(HowItWorks);
