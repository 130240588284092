import { animateScroll as scroll } from 'react-scroll';

export function scrollTo(divId, beautifyOffset) {
  // golden function: automatically scroll to #positions or any other div with id.
  const BEAUTIFY_OFFSET = beautifyOffset || 150;

  const element = document.getElementById(divId);
  const offsetTop = element && element.offsetTop ? element.offsetTop : BEAUTIFY_OFFSET;
  scroll.scrollTo(offsetTop - BEAUTIFY_OFFSET);
}

export function scrollTop() {
  scroll.scrollToTop();
}
