import isAbsoluteUrl from 'is-absolute-url';

export function ensureScheme(value, defaultScheme = 'https') {
  if (!value || value.length === 0 || value[0] === '/') return value;

  if (value.indexOf('://') < 0) return `${defaultScheme}://${value}`;

  return value;
}

export function ensureHost(value, defaultHost = window.location.origin) {
  if (!value || !defaultHost || isAbsoluteUrl(value)) {
    return value;
  }

  return `${defaultHost}${defaultHost[defaultHost.length - 1] === '/' || value[0] === '/'
    ? ''
    : '/'}${value}`;
}

export function buildIPFSUrl(hash) {
  return hash ? `https://ipfs.io/ipfs/${hash}` : hash;
}

export function convertToCloudfrontCDNURL(s3URL) {
  return (
    s3URL &&
    s3URL.replace('indorse-staging-bucket.s3.amazonaws.com', 'd1czgnl1rmm8mf.cloudfront.net')
  );
}
