import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from 'ui/common/icon';

import classnames from 'classnames';
import styles from './index.module.scss';

class ModalHeaderV2 extends Component {
  static propTypes = {
    tag: PropTypes.string,
    toggle: PropTypes.func,
  };

  static defaultProps = {
    tag: 'h2',
  };

  render() {
    const { tag: Tag, className, children, toggle, ...passThrough } = this.props;

    const hasContent = children && React.Children.count(children) > 0;

    return (
      <div
        className={classnames(className, styles.modalHeader, {
          [styles.onlyCloseIcon]: !hasContent,
        })}
        {...passThrough}
      >
        {hasContent && <Tag className="pt-2">{children}</Tag>}
        {toggle && (
          <div>
            <Icon
              onClick={() => toggle()}
              background
              name="icon--close"
              tooltip="Close"
              description="Close"
            />
          </div>
        )}
      </div>
    );
  }
}

export default ModalHeaderV2;
