import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import classnames from 'classnames';
import styles from './index.module.scss';

export class MemberCard extends Component {
  render() {
    const { member, intl: { formatMessage }, cardSmall, isResponsive } = this.props;
    const { name, position, bio, img, indorseUrl } = member;

    return (
      <div
        className={classnames({
          [styles.responsive]: isResponsive,
          'py-3': true,
        })}
      >
        <a
          className={classnames({
            [styles.memberCard]: true,
            [styles.memberCardSmall]: cardSmall,
          })}
          href={indorseUrl}
        >
          <div className={classnames(styles.memberCardBody)}>
            <div className="d-inline-flex pb-3">
              <div className="pr-3">
                <img src={img} alt={name} className={classnames(styles.avatar, `rounded-circle`)} />
              </div>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h3>{member.name}</h3>
                </li>
                <li>
                  {(member && (member.username ? `@${member.username}` : `${member.name}`)) || ''}
                </li>
                <li>
                  <p className="text-muted">{formatMessage(position.member)}</p>
                </li>
              </ul>
            </div>

            <div className="pt-3 text-muted">
              <p>{bio}</p>
            </div>
          </div>
        </a>
      </div>
    );
  }
}

export default injectIntl(MemberCard);
