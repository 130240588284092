import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import ClickListener from '../../internal/ClickListener';
import FloatingMenu from '../../internal/FloatingMenu';
import OptimizedResize from '../../internal/OptimizedResize';
import Icon from '../Icon';
import './styles.scss';

// eslint-disable-next-line no-unused-vars
const on = (element, ...args) => {
  element.addEventListener(...args);
  return {
    release() {
      element.removeEventListener(...args);
      return null;
    },
  };
};

/**
 * @param {Element} menuBody The menu body with the menu arrow.
 * @returns {FloatingMenu~offset} The adjustment of the floating menu position, upon the position of the menu arrow.
 * @private
 */
// eslint-disable-next-line no-unused-vars
const getMenuOffset = menuBody => {
  const menuWidth = menuBody.offsetWidth;
  const arrowStyle = menuBody.ownerDocument.defaultView.getComputedStyle(menuBody, ':before');
  const values = ['top', 'left', 'width', 'height', 'border-top-width'].reduce(
    (o, name) => ({
      ...o,
      [name]: Number((/^([\d-]+)px$/.exec(arrowStyle.getPropertyValue(name)) || [])[1]),
    }),
    {}
  );
  if (Object.keys(values).every(name => !isNaN(values[name]))) {
    const { top, left, width, height, 'border-top-width': borderTopWidth } = values;
    return {
      left: menuWidth / 2 - (left + Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2),
      top: Math.sqrt(Math.pow(borderTopWidth, 2) * 2) - top,
    };
  }
};

export default class OverflowMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: this.props.open,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.open && !this.state.open) {
      requestAnimationFrame(() => {
        this.getMenuPosition();
      });
      return false; // Let `.getMenuPosition()` cause render
    }
    return true;
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      this.getMenuPosition();
    });
    this.hResize = OptimizedResize.add(() => {
      this.getMenuPosition();
    });
  }

  // eslint-disable react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open) {
      this.setState({ open: nextProps.open });
    }
  }

  componentWillUnmount() {
    this.hResize.release();
  }

  getMenuPosition = () => {
    if (this.menuEl) {
      const menuPosition = this.menuEl.getBoundingClientRect();
      this.setState({ menuPosition });
    }
  };

  handleClick = evt => {
    this.setState({
      open: !this.state.open,
    });

    this.props.onClick(evt);
  };

  handleClickOutside = () => {
    this.closeMenu();
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  bindMenuEl = menuEl => {
    this.menuEl = menuEl;
  };

  render() {
    const {
      id,
      tabIndex,
      ariaLabel,
      children,
      iconDescription,
      iconName,
      floatingMenu,
      iconClass,
      onClick,
      renderIcon,
      ...other
    } = this.props;

    const { open } = this.state;

    const overflowMenuClasses = classNames(this.props.className, 'noselect', 'overflow-menu', {
      'overflow-menu--open': open,
    });

    const overflowMenuOptionsClasses = classNames('overflow-menu-options', {
      'is-open': open,
    });

    const overflowMenuIconClasses = classNames('overflow-menu-icon', iconClass);

    const childrenWithProps = React.Children.toArray(children).map(child =>
      React.cloneElement(child, {
        closeMenu: this.closeMenu,
      })
    );

    const menuBody = (
      <ul className={overflowMenuOptionsClasses} tabIndex="-1">
        {childrenWithProps}
      </ul>
    );
    const wrappedMenuBody = !floatingMenu ? (
      menuBody
    ) : (
      <div role="menuitem">
        <FloatingMenu menuPosition={this.state.menuPosition}>{menuBody}</FloatingMenu>
      </div>
    );

    const iconProps = {
      className: overflowMenuIconClasses,
      description: iconDescription,
    };

    return (
      <ClickListener onClickOutside={this.handleClickOutside}>
        <div
          {...other}
          onClick={this.handleClick}
          role="button"
          aria-haspopup
          aria-expanded={this.state.open}
          className={overflowMenuClasses}
          aria-label={ariaLabel}
          id={id}
          tabIndex={tabIndex}
          ref={this.bindMenuEl}
        >
          {renderIcon ? (
            renderIcon(iconProps)
          ) : (
            <Icon {...iconProps} background name={iconName} style={{ width: '100%' }} />
          )}
          {open && wrappedMenuBody}
        </div>
      </ClickListener>
    );
  }
}

OverflowMenu.propTypes = {
  /**
   * `true` if the menu should be open.
   */
  open: PropTypes.bool,

  /**
   * `true` if the menu should be floated.
   * Useful when the container of the triggering element cannot have `overflow:visible` style, etc.
   */
  floatingMenu: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  iconDescription: PropTypes.string.isRequired,
  iconName: PropTypes.string,
  /**
   * The adjustment in position applied to the floating menu.
   */
  menuOffset: PropTypes.oneOfType([
    PropTypes.shape({
      top: PropTypes.number,
      left: PropTypes.number,
    }),
    PropTypes.func,
  ]),
  iconClass: PropTypes.string,
  renderIcon: PropTypes.func,
};

OverflowMenu.defaultProps = {
  ariaLabel: 'list of options',
  iconDescription: 'open and close list of options',
  iconName: 'overflow-menu',
  open: false,
  floatingMenu: false,
  onClick: () => {},
  tabIndex: 0,
};
