import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const approveUser = {
  request: ({ userId }) => defineAction(types.APPROVE_USER.REQUEST, { userId }),
  start: ({ userId }) => defineAction(types.APPROVE_USER.START, { userId }),
  success: ({ userId }) => defineAction(types.APPROVE_USER.SUCCESS, { userId }),
  failure: ({ userId, error }) => defineAction(types.APPROVE_USER.FAILURE, { userId, error }),
};

export const disapproveUser = {
  request: ({ userId }) => defineAction(types.DISAPPROVE_USER.REQUEST, { userId }),
  start: ({ userId }) => defineAction(types.DISAPPROVE_USER.START, { userId }),
  success: ({ userId }) => defineAction(types.DISAPPROVE_USER.SUCCESS, { userId }),
  failure: ({ userId, error }) => defineAction(types.DISAPPROVE_USER.FAILURE, { userId, error }),
};

export const assignRole = {
  request: ({ userId, role }) => defineAction(types.ASSIGN_ROLE.REQUEST, { userId, role }),
  start: ({ userId, role }) => defineAction(types.ASSIGN_ROLE.START, { userId, role }),
  success: ({ userId, role }) => defineAction(types.ASSIGN_ROLE.SUCCESS, { userId, role }),
  failure: ({ userId, role, error }) =>
    defineAction(types.ASSIGN_ROLE.FAILURE, { userId, role, error }),
};

export const fetchUserById = {
  request: ({ userId }) => defineAction(types.FETCH_USER_BY_ID.REQUEST, { userId }),
  start: ({ userId }) => defineAction(types.FETCH_USER_BY_ID.START, { userId }),
  success: ({ userId }) => defineAction(types.FETCH_USER_BY_ID.SUCCESS, { userId }),
  failure: ({ userId, error }) => defineAction(types.FETCH_USER_BY_ID.FAILURE, { userId, error }),
};

export const fetchUserByUsername = {
  request: ({ username }) => defineAction(types.FETCH_USER_BY_USERNAME.REQUEST, { username }),
  start: ({ username }) => defineAction(types.FETCH_USER_BY_USERNAME.START, { username }),
  success: ({ username }) => defineAction(types.FETCH_USER_BY_USERNAME.SUCCESS, { username }),
  failure: ({ username, error }) =>
    defineAction(types.FETCH_USER_BY_USERNAME.FAILURE, { username, error }),
};

export const importLinkedInPdf = {
  request: ({ file }, modal) =>
    defineAction(types.IMPORT_LINKEDIN_PDF.REQUEST, { file }, { modal }),
  start: ({ file }) => defineAction(types.IMPORT_LINKEDIN_PDF.START, { file }),
  success: () => defineAction(types.IMPORT_LINKEDIN_PDF.SUCCESS),
  failure: ({ error }) => defineAction(types.IMPORT_LINKEDIN_PDF.FAILURE, { error }),
};

export const setEthereumAddress = {
  request: ({ userId, ethereumAddress, form, modal }) =>
    defineAction(types.SET_ETHEREUM_ADDRESS.REQUEST, { userId, ethereumAddress }, { form, modal }),
  start: ({ userId, ethereumAddress }) =>
    defineAction(types.SET_ETHEREUM_ADDRESS.START, { userId, ethereumAddress }),
  success: ({ userId, ethereumAddress }) =>
    defineAction(types.SET_ETHEREUM_ADDRESS.SUCCESS, { userId, ethereumAddress }),
  failure: ({ error, userId, ethereumAddress }) =>
    defineAction(types.SET_ETHEREUM_ADDRESS.FAILURE, { error, userId, ethereumAddress }),
};

export const setCurrentUserEthereumAddress = {
  request: ({ ethereumAddress, signature, form, modal, redirectToProfile, wizard }) =>
    defineAction(
      types.SET_CURRENT_USER_ETHEREUM_ADDRESS.REQUEST,
      { ethereumAddress, signature },
      { form, modal, redirectToProfile, wizard }
    ),
  start: ({ ethereumAddress, signature }) =>
    defineAction(types.SET_CURRENT_USER_ETHEREUM_ADDRESS.START, { ethereumAddress, signature }),
  success: ({ currentUserId, ethereumAddress, ethereumAddressVerified }) =>
    defineAction(types.SET_CURRENT_USER_ETHEREUM_ADDRESS.SUCCESS, {
      currentUserId,
      ethereumAddress,
      ethereumAddressVerified,
    }),
  failure: ({ error, ethereumAddress, signature }) =>
    defineAction(types.SET_CURRENT_USER_ETHEREUM_ADDRESS.FAILURE, {
      error,
      ethereumAddress,
      signature,
    }),
};

export const deleteCurrentUser = {
  request: (values, form) => defineAction(types.DELETE_CURRENT_USER.REQUEST, { values }, { form }),
  start: values => defineAction(types.DELETE_CURRENT_USER.START, { values }),
  success: response => defineAction(types.DELETE_CURRENT_USER.SUCCESS, response),
  failure: error => defineAction(types.DELETE_CURRENT_USER.FAILURE, error),
};

export const updateCurrentProfileSection = (userId, sectionId) => {
  return {
    type: types.UPDATE_CURRENT_PROFILE_SECTION,
    payload: { userId, sectionId },
  };
};
