import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import SubmitButton from 'ui/common/form/submit-button';
import RequestedDelete from 'ui/users/details/requested-delete';
import styles from './index.module.scss';
import validator from 'ui/common/form/validator';
import model from './model';
import * as messages from './messages';
import routeTemplates from 'ui/common/routes/templates';
import { acceptCurrentTermsAndPrivacy } from 'store/features/auth/actions';
import { selectCurrentUserDeletionRequestedAt } from 'store/features/auth/selectors';

const { request: acceptCurrentTermsAndPrivacyRequest } = acceptCurrentTermsAndPrivacy;

const FORM_NAME = 'consent';

const validate = validator(model);

class Consent extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onSubmit() {
    const { acceptCurrentTermsAndPrivacyRequest, form } = this.props;
    acceptCurrentTermsAndPrivacyRequest({ acceptTermsAndConditions: true }, form);
  }

  render() {
    const { currentUserDeletionRequestedAt, handleSubmit, intl: { formatMessage } } = this.props;

    if (currentUserDeletionRequestedAt) {
      return <RequestedDelete />;
    }

    return (
      <div className={classnames('py-4 container', styles.page)}>
        <Helmet>
          <title>{formatMessage(messages.header.title)}</title>
        </Helmet>
        <h1 className="text-center mx-3 my-5" style={{ fontSize: '52px', fontWeight: '900' }}>
          {formatMessage(messages.content.generalPageTitle)}
        </h1>
        <div className="row mt-4 text-center justify-content-center">
          <h3
            className="col-12 col-md-8"
            style={{ fontSize: '20px', lineHeight: '30px', maxWidth: '550px' }}
          >
            {formatMessage(messages.content.generalPageSubTitle)}
          </h3>
        </div>
        <div>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="mt-3 text-center mb-5">
              <SubmitButton formName={FORM_NAME} className={classnames(styles.submitBtn)}>
                {formatMessage(messages.buttons.agree)}
              </SubmitButton>
            </div>
          </form>
          <div className="text-center row justify-content-center position-relative mt-4">
            <small className="text-muted">
              By clicking "I agree" button, you agree to Indorse's{' '}
              <a href="https://indorse.io/terms-of-use">Terms of Use</a> and{' '}
              <a href="https://indorse.io/privacy-policy">Privacy Policy</a>
            </small>
          </div>
          <div className="text-center row justify-content-center position-relative mt-1">
            <small className="text-muted">
              <FormattedMessage
                {...messages.content.chooseToDelete}
                values={{
                  link: <Link to={routeTemplates.users.delete}>permanently delete</Link>,
                }}
              />
            </small>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUserDeletionRequestedAt: selectCurrentUserDeletionRequestedAt(state),
  };
}

const mapDispatchToProps = { acceptCurrentTermsAndPrivacyRequest };

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    validate,
  })(injectIntl(Consent))
);
