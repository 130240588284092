import React from 'react';
import classnames from 'classnames';
import commonStyles from './index.module.scss';
import { withRouter } from 'react-router-dom';
import Hero from './hero';
import SecondaryCTA from './cta';
import Usage from './usage';
import Features from './features';
import HowTo from './how-to';
import { connect } from 'react-redux';
import ExistingCustomers from 'ui/pages/home-V2/existing-customers/loadable';

import { show as showModal } from 'redux-modal';
import MetamorphHelmet from 'ui/pages/ems-indorse/metamorph-helmet';

class EMS extends React.Component {
  render() {
    return (
      <div className={classnames(commonStyles.home)}>
        <MetamorphHelmet />
        <Hero />
        <div
          style={{ backgroundColor: '#EFF3FE' }}
          className="pt-4 pt-xl-5 pb-xl-3 pt-lg-5 pb-lg-3 py-md-5 py-sm-5"
        >
          <h2 className={classnames(commonStyles.trust, 'm-0')}>The best teams trust us</h2>
          <ExistingCustomers autoplay={false} dots={false} />
        </div>
        <HowTo />
        <Features />
        <Usage />
        <SecondaryCTA />
      </div>
    );
  }
}

const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(withRouter(EMS));
