import { generateSortParameterURLValue } from 'utils/sort';
import { decamelize } from 'humps';

export function autoSuggestSkills(name, category = '') {
  const qs = new URLSearchParams();
  qs.set('name', name);

  if (category) qs.set('category', category);

  return {
    endpoint: `skills?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function updateCurrentUserSkills(values) {
  return {
    endpoint: 'updateskills',
    method: 'POST',
    data: {
      skills: values,
    },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function addUserSkill({ userId, skill: { name, level, isHighlighted } }) {
  return {
    endpoint: `users/${userId}/skills`,
    method: 'POST',
    data: {
      name,
      level,
      isHighlighted,
    },
    decamlizeInput: false,
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function createSkill({ name, category }) {
  return {
    endpoint: 'skills',
    method: 'POST',
    data: {
      name,
      category,
    },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function deleteSkill(id) {
  return {
    endpoint: `skills/${id}`,
    method: 'DELETE',
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function fetchSkills({
  filters = {},
  pageNo,
  perPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  sort,
  onlyAipSkills,
}) {
  const sortParam = generateSortParameterURLValue(sort);
  const qs = new URLSearchParams();

  if (filters) {
    Object.keys(filters).forEach(filterName => {
      const filterValue = filters[filterName];
      if (typeof filterValue !== undefined && filterValue !== null && filterValue !== [])
        qs.set('search', filterValue);
    });
  }

  !!onlyAipSkills && qs.set('onlyAipSkills', onlyAipSkills);

  if (sortParam && sortParam.length > 0) {
    qs.set('sort', decamelize(sortParam));
  }
  // qs.set('pageNumber', pageNo || 1);
  // qs.set('pageSize', perPage);

  return {
    endpoint: `admin/skills/${pageNo || 1}/${perPage}/?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}
