/*
	validator.js is used to validate all fields in any forms
	https://validatejs.org/#validators for all validations.
*/
export const fieldNames = {
  survey: 'survey',
};

export default {
  [fieldNames.survey]: {
    presence: true,
    length: { maximum: 200 },
  },
};
