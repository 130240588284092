import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { connectModal as reduxModal } from 'redux-modal';
import { submit } from 'redux-form';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';

import Button from 'ui/common/button';
import { deleteEducationEntry } from 'store/features/education/actions';
import { selectEducationEntryById } from 'store/features/education/selectors';

const {
  confirm: deleteEducationEntryConfirm,
  cancel: deleteEducationEntryCancel,
} = deleteEducationEntry;

const messages = defineMessages({
  title: {
    id: 'delete-education-entry-confirmation-dialog.title',
    defaultMessage: 'Delete',
  },
  content: {
    id: 'delete-education-entry-confirmation-dialog.content',
    defaultMessage: 'Are you sure you want to delete "{entryName}" from education?',
  },
  defaultEntryName: {
    id: 'delete-education-entry-confirmation-dialog.defaultEntryName',
    defaultMessage: 'this entry',
  },
  confirm: {
    id: 'delete-education-entry-confirmation-dialog.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'delete-education-entry-confirmation-dialog.cancel',
    defaultMessage: 'Cancel',
  },
});

export const MODAL_NAME = id => `delete-education-entry-confirmation-dialog-${id}`;

class DeleteEducationEntryConfirmationDialog extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  confirm() {
    const { id } = this.props;
    this.props.deleteEducationEntryConfirm({ id });
  }

  cancel() {
    const { id } = this.props;
    this.props.deleteEducationEntryCancel({ id });
  }

  render() {
    const { entry, show, handleHide, intl: { formatMessage } } = this.props;

    return (
      <Modal isOpen={show}>
        <ModalHeader toggle={handleHide} />
        <ModalBody>
          <FormattedMessage
            {...messages.content}
            values={{
              entryName: entry ? entry.schoolName : formatMessage(messages.defaultEntryName),
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.confirm}>
            {formatMessage(messages.confirm)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;

  return {
    id,
    entry: id ? selectEducationEntryById(state, { id }) : undefined,
  };
}

const mapDispatchToProps = {
  submit,
  deleteEducationEntryConfirm,
  deleteEducationEntryCancel,
};

const ConnectedDeleteEducationEntryConfirmationDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeleteEducationEntryConfirmationDialog));

export default function({ id }) {
  const name = MODAL_NAME(id);
  const WrappedModal = reduxModal({
    name,
  })(ConnectedDeleteEducationEntryConfirmationDialog);
  return <WrappedModal id={id} />;
}
