import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import autoBind from 'react-autobind';

import { DropdownMenu, DropdownItem, DropdownToggle, Dropdown } from 'reactstrap';
import routeGenerators from 'ui/common/routes/generators';
import { NavLink } from 'react-router-dom';

import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import { logEvent } from 'services/amplitude-helper';

class Companies extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      dropDownDashboardOpen: false,
      dropDownCandidatesOpen: false,
      dropDownJobsOpen: false,
    };
  }

  toggleDashboardDropdown() {
    this.setState(prevState => ({
      dropDownDashboardOpen: !prevState.dropDownDashboardOpen,
    }));
  }

  toggleJobsDropdown() {
    this.setState(prevState => ({
      dropDownJobsOpen: !prevState.dropDownJobsOpen,
    }));
  }

  toggleCandidatesDropdown() {
    this.setState(prevState => ({
      dropDownCandidatesOpen: !prevState.dropDownCandidatesOpen,
    }));
  }

  renderDesktop() {
    const { companies } = this.props;
    return (
      <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
        <div>
          {companies.length > 1 ? (
            <div className="d-flex">
              <Dropdown
                isOpen={this.state.dropDownDashboardOpen}
                toggle={this.toggleDashboardDropdown}
                className="my-auto pr-4"
              >
                <DropdownToggle>Dashboard</DropdownToggle>
                <DropdownMenu>
                  {companies.map((company, index) => (
                    <DropdownItem key={index}>
                      <NavLink
                        exact
                        to={
                          company && company.features && company.features.ems
                            ? routeGenerators.companies.edit({
                                prettyId: company.prettyId,
                                section: 'analysis',
                              })
                            : routeGenerators.companies.edit({ prettyId: company.prettyId })
                        }
                      >
                        {company.companyName}
                      </NavLink>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>

              {/* <Dropdown
                isOpen={this.state.dropDownJobsOpen}
                toggle={this.toggleJobsDropdown}
                className="my-auto pr-4"
              >
                <DropdownToggle>Overview</DropdownToggle>
                <DropdownMenu>
                  {companies.map(
                    (company, index) =>
                      company &&
                      company.features.magicLink && (
                        <DropdownItem key={index}>
                          <NavLink
                            exact
                            to={routeGenerators.companies.edit({
                              prettyId: company.prettyId,
                              section: 'jobs-management',
                            })}
                          >
                            {company.companyName}
                          </NavLink>
                        </DropdownItem>
                      )
                  )}
                </DropdownMenu>
              </Dropdown> */}

              {/* <Dropdown
                isOpen={this.state.dropDownCandidatesOpen}
                toggle={this.toggleCandidatesDropdown}
                className="my-auto pr-4"
              >
                <DropdownToggle>Candidates</DropdownToggle>
                <DropdownMenu>
                  {companies.map(
                    (company, index) =>
                      company &&
                      company.features.magicLink && (
                        <DropdownItem key={index}>
                          <NavLink
                            exact
                            to={routeGenerators.companies.edit({
                              prettyId: company.prettyId,
                              section: 'candidates',
                            })}
                          >
                            {company.companyName}
                          </NavLink>
                        </DropdownItem>
                      )
                  )}
                </DropdownMenu>
              </Dropdown> */}
            </div>
          ) : (
            <span>
              {companies.map((company, index) => (
                <span key={index}>
                  <NavLink
                    className="pr-4"
                    exact
                    to={
                      company && company.features && company.features.ems
                        ? routeGenerators.companies.edit({
                            prettyId: company.prettyId,
                            section: 'analysis',
                          })
                        : routeGenerators.companies.edit({ prettyId: company.prettyId })
                    }
                    onClick={() => logEvent('clicked-on-dashboard')}
                  >
                    Dashboard
                  </NavLink>

                  {/*{company &&*/}
                  {/*  company.features.magicLink && (*/}
                  {/*    <span>*/}
                  {/*      /!* <NavLink*/}
                  {/*        className="pr-4"*/}
                  {/*        exact*/}
                  {/*        to={routeGenerators.companies.edit({*/}
                  {/*          prettyId: company.prettyId,*/}
                  {/*          section: 'jobs-management',*/}
                  {/*        })}*/}
                  {/*      >*/}
                  {/*        Overview*/}
                  {/*      </NavLink> *!/*/}

                  {/*      /!* <NavLink*/}
                  {/*        className="pr-4"*/}
                  {/*        exact*/}
                  {/*        to={routeGenerators.companies.edit({*/}
                  {/*          prettyId: company.prettyId,*/}
                  {/*          section: 'candidates',*/}
                  {/*        })}*/}
                  {/*      >*/}
                  {/*        Candidates*/}
                  {/*      </NavLink> *!/*/}
                  {/*    </span>*/}
                  {/*  )}*/}
                </span>
              ))}
            </span>
          )}
        </div>
      </MediaQuery>
    );
  }

  renderMobile() {
    const { companies } = this.props;
    return (
      <MediaQuery className="container-fluid px-0" maxWidth={themeConfig.layout.gridBreakpoints.sm}>
        <div>
          {companies.map((company, index) => (
            <span key={index}>
              <NavLink
                exact
                to={
                  company && company.features && company.features.ems
                    ? routeGenerators.companies.edit({
                        prettyId: company.prettyId,
                        section: 'analysis',
                      })
                    : routeGenerators.companies.edit({ prettyId: company.prettyId })
                }
              >
                {company.companyName}
              </NavLink>
            </span>
          ))}
        </div>
      </MediaQuery>
    );
  }

  render() {
    return (
      <div>
        {this.renderDesktop()}
        {this.renderMobile()}
      </div>
    );
  }
}

export default connect(null, null)(withApollo(injectIntl(Companies)));
