import moment from 'moment';

export const KNOWN_VOTE_HASHES = {
  endorse: '0x5950ebe73a617667a66f67d525282c827c82ef4d89ae8dcd8336d013773b6b7f',
  flag: '0x1544e7eb480cc28803f7ff893bd999e62fb759c7d172fc27414594c5d9c925f2',
};

export const STATUSES = {
  registered: 'registered',
  pending_registration: 'pending_registration',
  registration_missed: 'registration_missed',
  endorsed: 'endorsed',
  flagged: 'flagged',
  pending_vote: 'pending_vote',
  vote_missed: 'vote_missed',
  requiresContractCheck: 'requires_contract_check',
};

export const SC_TRANSACTION_STATUSES = {
  pending: 'PENDING',
  success: 'SUCCESS',
  failed: 'FAILURE',
};

export const calculateVoteStatus = vote => {
  const { votingRound, claim } = vote;
  if (!votingRound || !claim) return null;

  if (
    claim.requiresRegistration &&
    votingRound.endRegistration &&
    moment().isBefore(votingRound.endRegistration)
  ) {
    return vote.registered ? STATUSES.registered : STATUSES.pending_registration;
  } else if (votingRound.endVoting) {
    if (moment().isBefore(votingRound.endVoting)) {
      if (claim.requiresRegistration && !vote.registered) {
        return STATUSES.registration_missed;
      } else if (claim.scClaimId) {
        // Not using votedAt for now
        if (vote.scVoteExists) {
          return vote.endorsed ? STATUSES.endorsed : STATUSES.flagged;
        } else if (vote.txStatus) {
          return vote.txStatus === SC_TRANSACTION_STATUSES.failed
            ? STATUSES.pending_vote
            : STATUSES.requiresContractCheck;
        } else if (
          (claim.type !== 'CASE_STUDY' &&
            ((vote.allowedSkillIdsForVoter &&
              (vote.allowedSkillIdsForVoter.length === 0 ||
                vote.allowedSkillIdsForVoter.every(
                  asifv => !!claim.skills.find(cs => cs.skill.id === asifv && cs.isMaxVoteReached)
                ))) ||
              (claim.skills && claim.skills.every(cs => cs.isMaxVoteReached)))) ||
          (claim.type === 'CASE_STUDY' && !vote.isReservationAvailable && !vote.isVoteReserved)
        ) {
          return STATUSES.vote_missed;
        } else {
          return STATUSES.pending_vote;
        }
      } else {
        // Legacy (Pre SC) votes
        if (vote.votedAt) {
          return vote.endorsed ? STATUSES.endorsed : STATUSES.flagged;
        } else if (
          (claim.type !== 'CASE_STUDY' &&
            ((vote.allowedSkillIdsForVoter &&
              (vote.allowedSkillIdsForVoter.length === 0 ||
                vote.allowedSkillIdsForVoter.every(
                  asifv =>
                    !!claim.skills.find(
                      cs => cs.skill && cs.skill.id === asifv && cs.isMaxVoteReached
                    )
                ))) ||
              (claim.skills && claim.skills.every(cs => cs.isMaxVoteReached)))) ||
          (claim.type === 'CASE_STUDY' && !vote.isReservationAvailable && !vote.isVoteReserved)
        ) {
          /*
            return vote_missed status when:
            1a. votes by skill already reached limit - for v1  & v2
            1b. votes by question already reached - v3(CASE_STUDY)
            or 
            2. votes by tier already reached limit
          */
          return STATUSES.vote_missed;
        } else {
          return STATUSES.pending_vote;
        }
      }
    } else {
      if (claim.scClaimId) {
        if (vote.scVoteExists) {
          return vote.endorsed ? STATUSES.endorsed : STATUSES.flagged;
        } else if (vote.txStatus) {
          return vote.txStatus === SC_TRANSACTION_STATUSES.failed
            ? STATUSES.vote_missed
            : STATUSES.requiresContractCheck;
        } else {
          return STATUSES.vote_missed;
        }
      } else {
        // Legacy (Pre SC) votes
        if (vote.votedAt) {
          return vote.endorsed ? STATUSES.endorsed : STATUSES.flagged;
        } else {
          return STATUSES.vote_missed;
        }
      }
    }
  }
};

// Expiring soon according to deadline
// Voted according to vote date, recent first
// Missed
export const calculateCurrentUserVoteSortOrder = vote => {
  switch (vote.status) {
    case STATUSES.pending_registration:
      return [1, moment(vote.votingRound.endRegistration).diff(moment(), 's')];
    case STATUSES.registered:
    case STATUSES.pending_vote:
      return [1, moment(vote.votingRound.endVoting).diff(moment(), 's')];
    case STATUSES.endorsed:
    case STATUSES.flagged:
      return [2, moment().diff(moment(vote.votedAt), 's')];
    case STATUSES.vote_missed:
      return [3, moment().diff(moment(vote.votedAt), 's')];
    case STATUSES.registration_missed:
      return [3, moment().diff(moment(vote.registeredAt), 's')];
    default:
      return [3, Number.MAX_VALUE];
  }
};
