import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const fetchUserClaims = {
  request: ({ userId }) => defineAction(types.FETCH_USER_CLAIMS.REQUEST, { userId }),
  start: ({ userId }) => defineAction(types.FETCH_USER_CLAIMS.START, { userId }),
  success: ({ userId, result }) =>
    defineAction(types.FETCH_USER_CLAIMS.SUCCESS, { userId, result }),
  failure: ({ userId, error }) => defineAction(types.FETCH_USER_CLAIMS.FAILURE, { userId, error }),
};

export const createClaim = {
  request: ({ values, form, assessmentId }) =>
    defineAction(types.CREATE_CLAIM.REQUEST, {
      values,
      form,
      assessmentId,
    }),
  start: ({ values, assessmentId }) =>
    defineAction(types.CREATE_CLAIM.START, { values, assessmentId }),
  success: ({ token, claim }) => defineAction(types.CREATE_CLAIM.SUCCESS, { token, claim }),
  failure: error => defineAction(types.CREATE_CLAIM.FAILURE, error),
};

export const fetchClaim = {
  request: ({ claimId }, { background = false } = {}) =>
    defineAction(types.FETCH_CLAIM.REQUEST, { claimId }, { background }),
  start: ({ claimId }, { background = false } = {}) =>
    defineAction(types.FETCH_CLAIM.START, { claimId }, { background }),
  success: ({ claimId }) => defineAction(types.FETCH_CLAIM.SUCCESS, { claimId }),
  failure: ({ claimId, error }) => defineAction(types.FETCH_CLAIM.FAILURE, { claimId, error }),
};

export const voteSubmissionReceived = ({
  claimId,
  voteId,
  skill,
  noOfTentativeVotes,
  isMaxVoteReached,
  tiers,
}) =>
  defineAction(types.VOTE_SUBMISSION_RECEIVED, {
    claimId,
    skill,
    voteId,
    noOfTentativeVotes,
    isMaxVoteReached,
    tiers,
  });
