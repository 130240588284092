import { createSelector } from 'reselect';

const selectUserClaimSurveyModalState = state => state.UserClaimSurveyModal;

export const selectIsSubmitting = createSelector(
  selectUserClaimSurveyModalState,
  (state, props) => state.submitting
);

export const selectError = createSelector(
  selectUserClaimSurveyModalState,
  (state, props) => state.error
);
