import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

const fetchClaimsInput = input => ({
  pageNo: 1,
  perPage: Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  ...input,
});

export const fetchClaims = {
  request: payload => defineAction(types.FETCH_CLAIMS.REQUEST, fetchClaimsInput(payload)),
  start: payload => defineAction(types.FETCH_CLAIMS.START, fetchClaimsInput(payload)),
  success: ({ input, result }) =>
    defineAction(types.FETCH_CLAIMS.SUCCESS, { input: fetchClaimsInput(input), result }),
  failure: ({ input, error }) =>
    defineAction(types.FETCH_CLAIMS.FAILURE, { input: fetchClaimsInput(input), error }),
};

export const approveClaim = {
  request: ({ claimId, force }) => defineAction(types.APPROVE_CLAIM.REQUEST, { claimId, force }),
  start: ({ claimId, force }) => defineAction(types.APPROVE_CLAIM.START, { claimId, force }),
  success: ({ claimId, force }) => defineAction(types.APPROVE_CLAIM.SUCCESS, { claimId, force }),
  failure: ({ claimId, force, error }) =>
    defineAction(types.APPROVE_CLAIM.FAILURE, { claimId, force, error }),
};

export const disapproveClaim = {
  request: ({ claimId, disapprovalReason }) =>
    defineAction(types.DISAPPROVE_CLAIM.REQUEST, { claimId, disapprovalReason }),
  start: ({ claimId, disapprovalReason }) =>
    defineAction(types.DISAPPROVE_CLAIM.START, { claimId, disapprovalReason }),
  success: ({ claimId }) => defineAction(types.DISAPPROVE_CLAIM.SUCCESS, { claimId }),
  failure: ({ claimId, error }) => defineAction(types.DISAPPROVE_CLAIM.FAILURE, { claimId, error }),
};
