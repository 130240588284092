import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { Parallax } from 'react-scroll-parallax';
import parallaxStyles from 'ui/common/parallax/index.module.scss';
import ParallaxFormNine from 'resources/common/parallax/parallax-form-9.svg';

import classnames from 'classnames';
import styles from './index.module.scss';
import commonStyles from '../index.module.scss';

import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';

import * as messages from './messages';
import teamPhoto1 from 'resources/page/about-company/img/team1.webp';
import teamPhoto2 from 'resources/page/about-company/img/team2.webp';

export class Intro extends Component {
  renderDesktop() {
    const { intl: { formatMessage } } = this.props;

    return (
      <div
        className={classnames(
          commonStyles.secPt1,
          commonStyles.secPtM1,
          commonStyles.secPb2,
          commonStyles.secPbM4,
          'container-fluid page-max-width'
        )}
        id="company-intro-section"
      >
        <div
          className={classnames(
            parallaxStyles.parS1,
            parallaxStyles.parT5,
            'position-absolute offset-10'
          )}
        >
          <img src={ParallaxFormNine} alt="Parallax Form" />
        </div>
        <div className="row mx-0">
          <div className={classnames(styles.leftCol, 'col-lg-6 col-12 px-0 px-md-3')}>
            <h2
              className={classnames(commonStyles.typographyHlMain, commonStyles.secPb1)}
              dangerouslySetInnerHTML={{ __html: formatMessage(messages.content.title) }}
            />
            <div className={classnames('row')}>
              <div className={classnames(styles.copy, 'col-lg-9 col-9')}>
                <p
                  className={classnames(commonStyles.typographyPLarge)}
                  dangerouslySetInnerHTML={{ __html: formatMessage(messages.content.copy) }}
                />
              </div>
            </div>
          </div>
          <div className={classnames(styles.rightCol, 'col-lg-5 ml-auto px-0 px-md-3')}>
            <Parallax offsetYMax={20} offsetYMin={-20}>
              <div className={classnames(commonStyles.secPb1, commonStyles.secPbM3)}>
                <img
                  className={classnames(styles.introPhotos)}
                  src={teamPhoto1}
                  alt="indorse Team"
                />
                {/* <p className="mt-2">{formatMessage(messages.content.imgCaption1)}</p> */}
              </div>
              <div>
                <img
                  className={classnames(styles.introPhotos)}
                  src={teamPhoto2}
                  alt="indorse Team"
                />
                {/* <p className="mt-2">{formatMessage(messages.content.imgCaption2)}</p> */}
              </div>
            </Parallax>
          </div>
        </div>
      </div>
    );
  }
  renderMobile() {
    const { intl: { formatMessage } } = this.props;

    return (
      <div
        className={classnames(
          commonStyles.secPt1,
          commonStyles.secPtM1,
          commonStyles.secPb2,
          commonStyles.secPbM4,
          'container-fluid page-max-width'
        )}
        id="company-intro-section"
      >
        <div
          className={classnames(
            parallaxStyles.parS1,
            parallaxStyles.parT5,
            'position-absolute offset-10'
          )}
        >
          <img src={ParallaxFormNine} alt="Parallax Form" />
        </div>
        <div className="row mx-0">
          <div className={classnames(styles.leftCol, 'col-lg-6 col-12 px-0 px-md-3 py-5 py-lg-0')}>
            <h2
              className={classnames(commonStyles.typographyHlMain, commonStyles.secPbM3)}
              dangerouslySetInnerHTML={{ __html: formatMessage(messages.content.title) }}
            />
            <div className={classnames('row')}>
              <div className={classnames(styles.copy, 'col-lg-12 col-12')}>
                <p
                  className={classnames(commonStyles.typographyPLarge)}
                  dangerouslySetInnerHTML={{ __html: formatMessage(messages.content.copy) }}
                />
              </div>
            </div>
          </div>
          <div className={classnames(styles.rightCol, 'col-lg-5 ml-auto px-0 px-md-3')}>
            <div className={classnames(commonStyles.secPb1, commonStyles.secPbM3)}>
              <img className={classnames(styles.introPhotos)} src={teamPhoto1} alt="indorse Team" />
              {/* <p className="mt-2">{formatMessage(messages.content.imgCaption1)}</p> */}
            </div>
            <div>
              <img className={classnames(styles.introPhotos)} src={teamPhoto2} alt="indorse Team" />
              {/* <p className="mt-2">{formatMessage(messages.content.imgCaption2)}</p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div>
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
          {this.renderDesktop()}
        </MediaQuery>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
          {this.renderMobile()}
        </MediaQuery>
      </div>
    );
  }
}

export default injectIntl(Intro);
