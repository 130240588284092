import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';

import FooterV2 from 'ui/common/rebranding/components/Footer/Footer';

import brands from 'resources/brands';
import brand from 'resources/brand';

import classnames from 'classnames';

class Footer extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  renderFooter() {
    const { className, inverted, intl: { formatMessage } } = this.props;

    return (
      <div
        className={classnames({
          'bg-white': true,
          [className]: true,
        })}
      >
        <FooterV2
          inverted={inverted}
          infoNavItems={[
            {
              link: brands.linkedIn.url,
              title: formatMessage(brands.linkedIn.messages.name),
              isTargetBlank: true,
            },

            {
              link: brands.facebook.url,
              title: formatMessage(brands.facebook.messages.name),
              isTargetBlank: true,
            },
            {
              link: brands.reddit.url,
              title: formatMessage(brands.reddit.messages.name),
              isTargetBlank: true,
            },
            {
              link: brands.telegram.url,
              title: formatMessage(brands.telegram.messages.name),
              isTargetBlank: true,
            },
            {
              link: brand.email.url,
              title: formatMessage(brand.email.messages.name),
              isTargetBlank: true,
            },
          ]}
          socialNavItems={[
            {
              link: brands.twitter.url,
              title: formatMessage(brands.twitter.messages.name),
              isTargetBlank: true,
            },
            {
              link: brands.medium.url,
              title: formatMessage(brands.medium.messages.name),
              isTargetBlank: true,
            },
            {
              link: brand.faq.url,
              title: formatMessage(brand.faq.messages.name),
              isTargetBlank: true,
            },
            {
              link: brand.blog.url,
              title: formatMessage(brand.blog.messages.name),
              isTargetBlank: true,
            },
            {
              link: brand.helpCenter.url,
              title: formatMessage(brand.helpCenter.messages.name),
              isTargetBlank: true,
            },
          ]}
          copyrightText={formatMessage(brand.copyRight.messages.name)}
          newsLetterText={formatMessage(brand.newsLetter.messages.name)}
          footerBottomNavItems={[
            {
              link: brand.termsOfUse.currentVersion.url,
              title: formatMessage(brand.termsOfUse.currentVersion.messages.name),
              isTargetBlank: false,
            },
            {
              link: brand.privacyPolicy.currentVersion.url,
              title: formatMessage(brand.privacyPolicy.currentVersion.messages.name),
              isTargetBlank: false,
            },
          ]}
        />
      </div>
    );
  }

  render() {
    return <div>{this.renderFooter()}</div>;
  }
}

export default injectIntl(Footer);
