import moment from 'moment';

export const dateYearMonthFieldNames = {
  year: 'year',
  month: 'month',
};

export const fieldNames = {
  schoolName: 'schoolName',
  schoolNameSearch: 'schoolNameSearch',
  degree: 'degree',
  fieldOfStudy: 'fieldOfStudy',
  grade: 'grade',
  startDate: 'startDate',
  endDate: 'endDate',
  activities: 'activities',
  description: 'description',
  currentlyStudying: 'currentlyStudying',
};

export default function(values = {}) {
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;
  const startDateYear =
    values &&
    values[fieldNames.startDate] &&
    values[fieldNames.startDate][dateYearMonthFieldNames.year];
  const endDateYear =
    values &&
    values[fieldNames.endDate] &&
    values[fieldNames.endDate][dateYearMonthFieldNames.year];
  const startDateMonth =
    values &&
    values[fieldNames.startDate] &&
    values[fieldNames.startDate][dateYearMonthFieldNames.month];
  const currentlyStudying = values && values.currentlyStudying;

  return {
    [fieldNames.schoolName]: {
      presence: !values[fieldNames.schoolNameSearch],
      length: {
        maximum: 100,
      },
    },
    [fieldNames.schoolNameSearch]: {
      length: {
        maximum: 100,
      },
    },
    [fieldNames.degree]: {
      length: {
        maximum: 100,
      },
    },
    [fieldNames.fieldOfStudy]: {
      presence: true,
      length: {
        maximum: 100,
      },
    },
    [fieldNames.grade]: {
      length: {
        maximum: 80,
      },
    },
    [`${fieldNames.startDate}.${dateYearMonthFieldNames.year}`]: {
      presence: true,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: currentYear - 50,
        // lessThanOrEqualTo: endDateYear || (currentYear),
        lessThanOrEqualTo: currentYear,
        // notLessThanOrEqualTo: endDateYear ? "must be less than or equal to End date year" : "must be less than or equal to %{count}"
        notLessThanOrEqualTo: 'must be less than or equal to %{count}',
      },
    },
    [`${fieldNames.startDate}.${dateYearMonthFieldNames.month}`]: {
      presence: true,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: startDateYear === currentYear ? currentMonth : 12,
        // notLessThanOrEqualTo: startDateYear === endDateYear ? "must be less than or equal to End date month" : "must be a valid month"
        notLessThanOrEqualTo:
          startDateYear === currentYear
            ? `must be less than or equal to today's date`
            : 'must be a valid month',
      },
    },
    [`${fieldNames.endDate}.${dateYearMonthFieldNames.year}`]: {
      presence: !currentlyStudying,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: startDateYear || currentYear - 50,
        notGreaterThanOrEqualTo: startDateYear
          ? 'must be greater than or equal to Start date year'
          : 'must be greater than or equal to %{count}',
        lessThanOrEqualTo: currentYear,
      },
    },
    [`${fieldNames.endDate}.${dateYearMonthFieldNames.month}`]: {
      presence: !currentlyStudying,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: startDateYear === endDateYear ? startDateMonth || 1 : 1,
        notGreaterThanOrEqualTo:
          startDateYear === endDateYear
            ? 'must be greater than or equal to Start date month'
            : 'must be a valid month',
        lessThanOrEqualTo: endDateYear === currentYear ? currentMonth : 12,
        notLessThanOrEqualTo:
          startDateYear === currentYear
            ? `must be less than or equal to today's date`
            : 'must be a valid month',
      },
    },
    [fieldNames.currentlyStudying]: {},
    [fieldNames.activities]: {
      length: {
        maximum: 500,
      },
    },
    [fieldNames.description]: {
      length: {
        maximum: 2000,
      },
    },
  };
}
