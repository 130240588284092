import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  error: false,
  loginWithGithub: false,
  token: null,
  userExists: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_WITH_GITHUB_CODE.START: {
      return state.merge({
        loginWithGithub: true,
      });
    }
    case actionTypes.LOGIN_WITH_GITHUB_CODE.SUCCESS: {
      const { token, userExists } = action.payload;

      return state.merge({
        loginWithGithub: false,
        userExists,
        token,
      });
    }
    case actionTypes.LOGIN_WITH_GITHUB_CODE.FAILURE: {
      return state.merge({
        loginWithGithub: false,
      });
    }

    default:
      return state;
  }
}
