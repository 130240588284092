import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import Alert from 'ui/common/alert';
import FormError from 'ui/common/form/form-error';
import TextField from 'ui/common/form/fields/text-field';
import validator from 'ui/common/form/validator';

import fields, { fieldNames } from './model';
import * as messages from './messages';
import { selectCurrentUserEthereumAddress } from 'store/features/auth/selectors';

export const FORM_NAME = 'set-ethereum-address';

const validate = validator(fields);

class SetEthereumAddressForm extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { className, handleSubmit, form: formName, intl: { formatMessage } } = this.props;

    return (
      <div className={className}>
        <div className={classnames('row align-items-center')}>
          <div className="col-12">
            <form title={formatMessage(messages.content.updateTitle)} onSubmit={handleSubmit}>
              <FormError formName={formName} />
              <div className="py-2">
                <Field
                  name={fieldNames.ethereumAddress}
                  component={TextField}
                  label={formatMessage(messages.labels.ethereumAddress)}
                />
              </div>
              <Alert color="danger">{formatMessage(messages.content.warning)}</Alert>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const currentUserEthereumAddress = selectCurrentUserEthereumAddress(state);

  return {
    initialValues: { [fieldNames.ethereumAddress]: currentUserEthereumAddress },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    validate,
  })(injectIntl(SetEthereumAddressForm))
);
