import React from 'react';
import { render } from 'react-dom';
import createHistory from 'history/createBrowserHistory';
import ScrollToTop from 'ui/app/scroll-to-top';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';
import { ConnectedRouter } from 'react-router-redux';
import shortid from 'shortid';
import { ParallaxProvider } from 'react-scroll-parallax';

import 'ui/theme/global.scss';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';

import loadPolyfills from './dynamic-polyfills';
import createStore from './store';
import App from './ui/app';
import installRaven from './install-raven';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import './moment-config';
import './DrawSVGPlugin';
import Web3Provider from 'ui/ethereum/web3-provider';
import './utils/crypto';

// START react-apollo-related imports
import { ApolloClient } from 'apollo-client';
// import { WebSocketLink } from 'apollo-link-ws';
import { setContext } from 'apollo-link-context';
// import { split } from 'apollo-link';
// import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import { createUploadLink } from 'apollo-upload-client';
// import { supportsWebSocketsNatively } from 'utils/websocket';

// Create a link that include httpLink and uploadLink
const link = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_HTTP_LINK,
});

let graphQlLink = link;

// if (supportsWebSocketsNatively()) {
//   // Create a WebSocket link:
//   const userToken = localStorage.getItem('token');
//   const wsLink = new WebSocketLink({
//     uri: process.env.REACT_APP_APOLLO_WS_LINK,
//     options: {
//       reconnect: true,
//       connectionParams: {
//         authorization: userToken ? `Bearer ${userToken}` : '',
//       },
//     },
//   });

//   // using the ability to split links, we can send data to each link
//   // depending on what kind of operation is being sent
//   graphQlLink = split(
//     // split based on operation type
//     ({ query }) => {
//       const { kind, operation } = getMainDefinition(query);
//       return kind === 'OperationDefinition' && operation === 'subscription';
//     },
//     wsLink,
//     link
//   );
// }

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  const finalHeaders = {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  };

  if (window.amplitude) {
    const ampInstance = window.amplitude.getInstance();
    if (ampInstance) {
      const ampDeviceId = ampInstance.options ? ampInstance.options.deviceId : null;
      if (ampDeviceId) finalHeaders['X-Amp-Device-Id'] = ampDeviceId;
      const ampSessionId = ampInstance._session_id;
      if (ampSessionId) finalHeaders['X-Amp-Session-Id'] = ampSessionId;
    }
  }

  return {
    headers: finalHeaders,
  };
});

export const client = new ApolloClient({
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
  // to a different host
  link: authLink.concat(graphQlLink),
  cache: new InMemoryCache({
    dataIdFromObject: o => {
      if (o && o.__typename) {
        if (o.__typename === 'JobCompany') {
          return o.id
            ? `${o.__typename}:${o.id}:${shortid.generate()}`
            : `${o.__typename}:${shortid.generate()}`;
        } else if (o.__typename === 'UserData') {
          if (!o.id) o.id = shortid.generate();
          // return `${o.__typename}:${o.id}`;
        }
      }
      return defaultDataIdFromObject(o);
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

require('./api/errors');

addLocaleData([...en]);

const raven = installRaven();
const history = createHistory();
const store = createStore(raven, history);

// need to add graphqlInjectedReducers to replaceReducer on the go
store.graphqlInjectedReducers = {};

loadPolyfills().then(() => {
  render(
    <Provider store={store}>
      <IntlProvider>
        <Web3Provider>
          <ParallaxProvider>
            <ConnectedRouter history={history}>
              <ApolloProvider client={client}>
                <ScrollToTop>
                  <App />
                </ScrollToTop>
              </ApolloProvider>
            </ConnectedRouter>
          </ParallaxProvider>
        </Web3Provider>
      </IntlProvider>
    </Provider>,
    document.getElementById('root')
  );
});

unregisterServiceWorker();
