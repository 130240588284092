import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginWithSlackButton from 'ui/auth/metamorph/login-with-slack';
import SlackAuthButton from 'ui/graphql/SlackAuthButton';

import Loading from 'ui/common/loading';

import * as storage from 'store/features/auth/storage';
import { slackMethod } from 'resources/slack';
import { selectAuthenticatingWithSlack } from 'store/graphql/SlackAuthCallback/selectors';
import { selectLoginWithSlack } from 'store/graphql/SlackAuthButton/selectors';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import SlackAuthCallbackReducer from 'store/graphql/SlackAuthCallback/reducer';

export class SlackAuthCallback extends Component {
  render() {
    const { isAuthenticatingWithSlack, from } = this.props;
    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );

    if (isAuthenticatingWithSlack) return <Loading />;
    if (from) {
      return <div>{authenticationWithSlack && <LoginWithSlackButton from={from} />}</div>;
    }

    return (
      <div>
        <SlackAuthButton />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const isAuthenticatingWithSlack = selectAuthenticatingWithSlack(state);
  const isLoginWithSlack = selectLoginWithSlack(state);
  const location = window.location;
  const authData = new URLSearchParams(location.search);
  const from = authData.get('from');

  return {
    isAuthenticatingWithSlack,
    isLoginWithSlack,
    from,
  };
}

const withConnect = connect(mapStateToProps, null);
const withReducer = injectReducer({
  key: 'SlackAuthCallback',
  reducer: SlackAuthCallbackReducer,
});

export default compose(withReducer, withConnect)(SlackAuthCallback);
