// Copied from https://github.com/ReactTraining/react-router/pull/5543
// TODO: When react-router-redux includes this by default

import { matchPath } from 'react-router';

export const getLocation = state => state.router.location;

export const createMatchSelector = path => {
  let lastPathname = null;
  let lastMatch = null;
  return state => {
    const { pathname } = getLocation(state);
    if (pathname === lastPathname) {
      return lastMatch;
    }
    lastPathname = pathname;
    const match = matchPath(pathname, path);
    if (!match || !lastMatch || match.url !== lastMatch.url) {
      lastMatch = match;
    }
    return lastMatch;
  };
};
