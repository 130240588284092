import { createSelector } from 'reselect';

const selectLibsState = state => state.libs;

export const selectFacebookSdkLibLoadState = createSelector(selectLibsState, libsState => {
  const { loading, loaded, error } = libsState.facebookSdk;
  return { loading, loaded, error };
});

export const selectGoogleApiLibLoadState = createSelector(selectLibsState, libsState => {
  const { loading, loaded, error } = libsState.googleApi;
  return { loading, loaded, error };
});

export const selectGoogleAuthLibLoadState = createSelector(selectLibsState, libsState => {
  const { loading, loaded, error } = libsState.googleAuth;
  return { loading, loaded, error };
});

export const selectRecaptchaSdkLibLoadState = createSelector(selectLibsState, libsState => {
  const { loading, loaded, error } = libsState.reCaptchaSdk;
  return { loading, loaded, error };
});

export const selectWeb3LibLoadState = createSelector(selectLibsState, libsState => {
  const { loading, loaded, error } = libsState.web3;
  return { loading, loaded, error };
});

export const selectStripeCheckoutSdkLibLoadState = createSelector(selectLibsState, libsState => {
  const { loading, loaded, error } = libsState.stripeCheckoutSdk;
  return { loading, loaded, error };
});
