import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.START_ASSIGNMENT.START: {
      const { assignmentId } = action.payload;
      return state.merge(
        {
          [assignmentId]: {
            fetching: true,
            error: false,
          },
        },
        { deep: true }
      );
    }

    case actionTypes.START_ASSIGNMENT.FAILURE: {
      const { error, assignmentId } = action.payload;
      return state.merge(
        {
          [assignmentId]: {
            fetching: false,
            error,
          },
        },
        { deep: true }
      );
    }

    case actionTypes.START_ASSIGNMENT.SUCCESS: {
      const { assignmentId } = action.payload;
      return state.merge(
        {
          [assignmentId]: {
            fetching: false,
            error: false,
          },
        },
        { deep: true }
      );
    }
    default:
      return state;
  }
}
