import Immutable from 'seamless-immutable';

import * as types from './action-types';
import {
  SET_ETHEREUM_ADDRESS,
  SET_CURRENT_USER_ETHEREUM_ADDRESS,
} from 'store/features/users/action-types';
import { parseApiDate } from 'store/common/schemas';

const initialState = Immutable({
  loggedIn: false,
  token: undefined,
  fetchingCurrentUser: false,
  currentUser: undefined,
  currentUserFetchedAtleastOnce: false,
  signedUp: false,
  emailVerification: {
    verifying: false,
    verified: false,
    error: undefined,
  },
  forgotPasswordEmailSent: false,
  authenticatingWithFacebook: false,
  linkingWithFacebook: false,
  authenticatingWithGoogle: false,
  linkingWithGoogle: false,
  authenticatingWithLinkedIn: false,
  linkingWithLinkedIn: false,
  authenticatingWithUPort: false,
  linkingWithUPort: false,
  pollingUPort: false,
  uPortData: null,
  pollingUPortStatus: null,
  saving: false,
  currentUserInitialized: false,
  impressApi: {
    key: undefined,
    error: undefined,
  },
  settingCurrentUserEthereumAddress: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SIGN_UP.START:
      return state.set('signedUp', false);
    case types.SIGN_UP.SUCCESS:
      return state.merge({
        signedUp: true,
        authenticatingWithFacebook: false,
        authenticatingWithGoogle: false,
      });
    case types.SIGN_UP.FAILURE:
      return state.merge({
        signedUp: false,
        authenticatingWithFacebook: false,
        authenticatingWithGoogle: false,
      });
    case types.VERIFY_EMAIL.START:
      return state.merge({
        emailVerification: {
          verifying: true,
          verified: false,
          error: undefined,
        },
      });
    case types.VERIFY_EMAIL.SUCCESS:
      return state.merge({
        emailVerification: {
          verifying: false,
          verified: true,
          error: undefined,
        },
      });
    case types.VERIFY_EMAIL.FAILURE:
      return state.merge({
        emailVerification: {
          verifying: false,
          verified: false,
          error:
            (action && action.payload && action.payload.message) ||
            'There was a error performing this action. Please try again',
        },
      });
    case types.LOGIN.SUCCESS:
      return state.merge({
        loggedIn: true,
        token: action.payload.token,
        currentUser: action.payload.user,
      });
    case types.LOGIN.FAILURE:
    case types.LOGOUT.SUCCESS:
    case types.LOGOUT.FAILURE:
      return state.merge({
        loggedIn: false,
        token: undefined,
        currentUser: undefined,
        currentUserFetchedAtleastOnce: false,
        currentUserInitialized: false,
        emailVerification: {
          verifying: false,
          verified: false,
          error: undefined,
        },
      });
    case types.FETCH_CURRENT_USER.START:
      return state.merge({ fetchingCurrentUser: true });
    case types.FETCH_CURRENT_USER.SUCCESS:
      const { user } = action.payload;
      return state.merge(
        {
          fetchingCurrentUser: false,
          currentUser: { ...user, registeredAt: parseApiDate(user.timestamp) },
          currentUserFetchedAtleastOnce: true,
        },
        { deep: true }
      );
    case types.FETCH_CURRENT_USER.FAILURE:
      return state.merge({ fetchingCurrentUser: false });
    case types.UPDATE_CURRENT_USER.START:
      return state.merge({
        saving: true,
      });
    case types.UPDATE_CURRENT_USER.SUCCESS:
      return state.merge(
        {
          currentUser: action.payload.user,
          saving: false,
        },
        { deep: true }
      );
    case types.UPDATE_CURRENT_USER.FAILURE:
      return state.merge({
        saving: false,
      });
    case types.FORGOT_PASSWORD.START:
      return state.merge({ forgotPasswordEmailSent: false });
    case types.FORGOT_PASSWORD.SUCCESS:
      return state.merge({ forgotPasswordEmailSent: true });
    case types.FORGOT_PASSWORD.FAILURE:
      return state.merge({ forgotPasswordEmailSent: false });
    case types.CLEAR_FORGOT_PASSWORD_EMAIL_STATUS:
      return state.merge({ forgotPasswordEmailSent: false });
    case types.FACEBOOK_AUTH.START:
      return state.merge({ authenticatingWithFacebook: true });
    case types.FACEBOOK_AUTH.SUCCESS:
    case types.FACEBOOK_AUTH.FAILURE:
      return state.merge({ authenticatingWithFacebook: false });
    case types.LINK_FACEBOOK.START:
      return state.merge({ linkingWithFacebook: true });
    case types.LINK_FACEBOOK.SUCCESS:
      return state.merge(
        { linkingWithFacebook: false, currentUser: { isFacebookLinked: true } },
        { deep: true }
      );
    case types.LINK_FACEBOOK.FAILURE:
      return state.merge({ linkingWithFacebook: false });
    case types.GOOGLE_AUTH.START:
      return state.merge({ authenticatingWithGoogle: true });
    case types.GOOGLE_AUTH.SUCCESS:
    case types.GOOGLE_AUTH.FAILURE:
      return state.merge({ authenticatingWithGoogle: false });
    case types.LINK_GOOGLE.START:
      return state.merge({ linkingWithGoogle: true });
    case types.LINK_GOOGLE.SUCCESS:
      return state.merge(
        { linkingWithGoogle: false, currentUser: { isGoogleLinked: true } },
        { deep: true }
      );
    case types.LINK_GOOGLE.FAILURE:
      return state.merge({ linkingWithGoogle: false });
    case types.LINKED_IN_AUTH.START:
      return state.merge({ authenticatingWithLinkedIn: true });
    case types.LINKED_IN_AUTH.SUCCESS:
    case types.LINKED_IN_AUTH.FAILURE:
      return state.merge({ authenticatingWithLinkedIn: false });
    case types.LINK_LINKED_IN.START:
      return state.merge({ linkingWithLinkedIn: true });
    case types.LINK_LINKED_IN.SUCCESS:
      return state.merge(
        { linkingWithLinkedIn: false, currentUser: { isLinkedInLinked: true } },
        { deep: true }
      );
    case types.LINK_LINKED_IN.FAILURE:
      return state.merge({ linkingWithLinkedIn: false });
    case types.UPORT_AUTH.START:
      return state.merge({ authenticatingWithUPort: true });
    case types.UPORT_AUTH.SUCCESS: {
      const { qr, token } = action.payload;
      return state.merge({
        authenticatingWithUPort: false,
        uPortData: { qr, token },
      });
    }
    case types.UPORT_AUTH.FAILURE:
      return state.merge({ authenticatingWithUPort: false });
    case types.LINK_UPORT.START:
      return state.merge({ linkingWithUPort: true });
    case types.LINK_UPORT.SUCCESS:
      const { qr, token } = action.payload;
      return state.merge(
        {
          linkingWithUPort: false,
          uPortData: { qr, token },
          currentUser: { isUPortLinked: true },
        },
        { deep: true }
      );
    case types.LINK_UPORT.FAILURE:
      return state.merge({ linkingWithUPort: false });
    case types.POLL_UPORT.START:
      return state.merge({ pollingUPort: true, pollingUPortStatus: 'PENDING' });
    case types.POLL_UPORT.SUCCESS:
    case types.POLL_UPORT.FAILURE:
      const { status } = action.payload;
      return state.merge({ pollingUPort: false, pollingUPortStatus: status });
    case SET_CURRENT_USER_ETHEREUM_ADDRESS.START:
      return state.merge({
        settingCurrentUserEthereumAddress: true,
      });
    case SET_ETHEREUM_ADDRESS.SUCCESS:
      const {
        userId: setEthereumAddressUserId,
        ethereumAddress: newEthereumAddress,
      } = action.payload;
      if (
        state.currentUser &&
        state.currentUser.id &&
        state.currentUser.id === setEthereumAddressUserId
      ) {
        return state.merge(
          {
            currentUser: {
              ethereumAddress: newEthereumAddress,
            },
          },
          { deep: true }
        );
      }
      return state;
    case SET_CURRENT_USER_ETHEREUM_ADDRESS.SUCCESS:
      const {
        ethereumAddress: newVerifiableEthereumAddress,
        ethereumAddressVerified: newEthereumAddressVerified,
      } = action.payload;
      if (state.currentUser && state.currentUser.id) {
        return state.merge(
          {
            settingCurrentUserEthereumAddress: false,
            currentUser: {
              ethereumAddress: newVerifiableEthereumAddress,
              ethereumAddressVerified: newEthereumAddressVerified,
            },
          },
          { deep: true }
        );
      }
      return state;
    case SET_CURRENT_USER_ETHEREUM_ADDRESS.FAILURE:
      return state.merge({
        settingCurrentUserEthereumAddress: false,
      });
    case types.SET_HAS_CONNECTIONS_ENTITY:
      if (state.currentUser) {
        return state.merge(
          {
            currentUser: {
              hasConnectionEntity: action && action.payload && action.payload.hasEntity,
            },
          },
          { deep: true }
        );
      }
      return state;
    case types.MARK_CURRENT_USER_INITIALIZED:
      return state.merge({ currentUserInitialized: true }, { deep: true });
    case types.IMPRESS_AI_AUTH.START: {
      const { skillName } = action.payload;
      return state.merge({
        impressApi: {
          key: undefined,
          [skillName]: {
            loading: true,
            error: undefined,
            loadedAt: undefined,
          },
        },
      });
    }
    case types.IMPRESS_AI_AUTH.SUCCESS: {
      const { skillName } = action.payload;
      return state.merge({
        impressApi: {
          key: action.payload.impressOAuthKey,
          [skillName]: {
            loadedAt: Date.now(),
            loading: false,
            error: undefined,
            chatbotUuid: action.payload.chatbotUuid,
          },
        },
      });
    }
    case types.IMPRESS_AI_AUTH.FAILURE: {
      const { skillName } = action.payload;
      return state.merge({
        impressApi: {
          key: undefined,
          [skillName]: {
            loadedAt: undefined,
            error: action.payload.error,
            loading: false,
          },
        },
      });
    }
    case types.SUBS_NEW_BADGE_CURRENT_USER: {
      const { badge } = action.payload;
      const latestBadges = state.getIn(['currentUser', 'badges']).asMutable();
      latestBadges.push(badge);
      return state.setIn(['currentUser', 'badges'], latestBadges);
    }

    case types.SET_PASSWORD.SUCCESS: {
      return state.setIn(['currentUser', 'isPass'], true);
    }

    default:
      return state;
  }
}
