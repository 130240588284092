import Immutable from 'seamless-immutable';
import * as actionTypes from './action-types';

const defaultPerPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50;

const initialState = Immutable({
  ids: [],
  submitting: false,
  deleting: false,
  fetching: false,
  currentPage: null,
  perPage: defaultPerPage,
  totalSkills: 0,
  sort: { field: '', order: null },
  matchingSkills: 0,
});

export default function listing(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_SKILL.START:
      return state.merge({
        submitting: true,
      });
    case actionTypes.CREATE_SKILL.SUCCESS:
      return state.merge({
        submitting: false,
      });
    case actionTypes.FETCH_SKILLS.REQUEST:
      const { pageNo: fetchingPage, perPage: fetchingPerPage, sort: fetchingSort } = action.payload;
      return state.merge({
        ids: [],
        fetching: true,
        currentPage: fetchingPage,
        perPage: fetchingPerPage || defaultPerPage,
        totalSkills: 0,
        sort: fetchingSort,
        matchingSkills: 0,
      });
    case actionTypes.FETCH_SKILLS.SUCCESS:
      const { pageNo: currentPage } = action.payload.input;
      const { skills: fetchedSkillsIds, totalSkills, matchingSkills } = action.payload.result;

      return state.merge({
        ids: fetchedSkillsIds,
        fetching: false,
        currentPage,
        totalSkills,
        matchingSkills: typeof matchingSkills === 'number' ? matchingSkills : totalSkills,
        error: null,
      });
    case actionTypes.FETCH_SKILLS.FAILURE:
      return state.merge({
        ids: [],
        fetching: false,
      });
    case actionTypes.DELETE_SKILL.START:
      return state.merge({
        deleting: true,
      });
    case actionTypes.DELETE_SKILL.SUCCESS:
      return state.merge({
        ids: state.ids.filter(id => id !== action.payload.id),
        totalSkills: Math.max(0, state.totalSkills - 1),
        matchingSkills: Math.max(0, state.matchingSkills - 1),
        deleting: false,
      });
    default:
      return state;
  }
}

// TODO: maybe add prettyId && how confirmation on how to remove skill
// function byPrettyId(state = Immutable({}), action) {
//   switch (action.type) {
//     case actionTypes.REMOVE_SKILLS.START:
//       return state.merge(
//         {
//           [action.payload.prettyId]: {
//             deleting: true,
//           },
//         },
//         { deep: true }
//       );
//     case actionTypes.REMOVE_SKILLS.SUCCESS:
//       return state.merge(
//         {
//           [action.payload.prettyId]: {
//             deleting: false,
//           },
//         },
//         { deep: true }
//       );
//     case actionTypes.REMOVE_SKILLS.FAILURE:
//       return state.merge(
//         {
//           [action.payload.prettyId]: {
//             deleting: false,
//           },
//         },
//         { deep: true }
//       );
//     default:
//       return state;
//   }
// }

// export default combineReducers ({
//   listing,
//   byPrettyId,
// });
