export const providerNames = {
  metaMask: 'MetaMask',
  unknown: 'Unknown',
};

export function findProviderNameByWeb3ProviderInstance(provider) {
  if (provider) {
    if (typeof provider.isMetaMask !== 'undefined' && provider.isMetaMask) {
      return providerNames.metaMask;
    }
  }

  return providerNames.unknown;
}
