import Immutable from 'seamless-immutable';
import { combineReducers } from 'redux';

import * as actionTypes from './action-types';

function byName(state = Immutable({}), action) {
  if (!action || !action.payload || !action.payload.contractName) return state;

  switch (action.type) {
    case actionTypes.FETCH_SMART_CONTRACT_BY_NAME.START:
      return state.merge(
        {
          [action.payload.contractName]: {
            fetching: true,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_SMART_CONTRACT_BY_NAME.SUCCESS:
      return state.merge(
        {
          [action.payload.contractName]: {
            fetching: false,
            lastFetchedAt: new Date().toISOString(),
            error: null,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_SMART_CONTRACT_BY_NAME.FAILURE:
      return state.merge(
        {
          [action.payload.contractName]: {
            fetching: false,
            error: action.payload.error,
          },
        },
        { deep: true }
      );
    default:
      return state;
  }
}

export default combineReducers({ byName });
