/*
  GithubAuthButton
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import shortid from 'shortid';
import { bindActionCreators } from 'redux';
import { buildMessage } from 'store/features/common/flash/builder';
import * as storage from 'store/features/auth/storage';
import * as flashActions from 'store/features/common/flash/actions';
import jwtDecode from 'jwt-decode';
import Button from 'ui/common/button';
import { githubMethod } from 'resources/github';
import routeTemplates from 'ui/common/routes/templates';
import messages from './messages';
import * as authCallbackActionTypes from 'store/graphql/GithubAuthCallback/action-types';
import * as actionTypes from 'store/graphql/GithubAuthButton/action-types';
import * as authActionTypes from 'store/features/auth/action-types';
import {
  selectFetchingStart,
  selectAuthenticatingWithGithub,
  selectGithubAuthCallbackState,
} from 'store/graphql/GithubAuthCallback/selectors';
import {
  selectLoginWithGithub,
  selectError,
  selectGithubAuthButtonState,
} from 'store/graphql/GithubAuthButton/selectors';
import Loading from 'ui/common/loading';
import * as smartRouteActions from 'store/features/smart-route/actions';
import * as libSelectors from 'store/libs/selectors';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import GithubAuthButtonReducer from 'store/graphql/GithubAuthButton/reducer';
import GithubAuthCallbackReducer from 'store/graphql/GithubAuthCallback/reducer';

class GithubAuthButton extends Component {
  static propTypes = {
    existingValues: PropTypes.object,
    from: PropTypes.string,
    githubCTA: PropTypes.bool,
    isReady: PropTypes.bool,
    redirectToFromPage: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { loginWithGithubCodeStart } = this.props;

    const authenticationWithGithub = storage.getDataInLocalStorage(
      githubMethod.authenticationWithGithub
    );

    const location = window.location;
    const authData = new URLSearchParams(location.search);
    const code = authData.get('code');
    const state = authData.get('state');

    if (location && location.search && authenticationWithGithub && code && state) {
      // Move this here so that the loading spinner can load immediate.
      loginWithGithubCodeStart();
    }
  }

  async ultimateLoginWithGithub() {
    const { isFetchingStart, githubAuthSuccess } = this.props;
    const fromLocation = storage.getDataInLocalStorage('github-from');
    const authenticationWithGithub = storage.getDataInLocalStorage(
      githubMethod.authenticationWithGithub
    );

    if (!isFetchingStart) {
      const location = window.location;
      const authData = new URLSearchParams(location.search);
      const code = authData.get('code');
      const state = authData.get('state');

      if (location && location.search && authenticationWithGithub && code && state) {
        githubAuthSuccess({ code, state });

        this.loginWithGithubCode(code, state, fromLocation);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const previousRecaptchaStatus = !!(
      prevProps.recaptchaStatus && prevProps.recaptchaStatus.loaded
    );
    const currentRecaptchaStatus = !!(
      this.props.recaptchaStatus && this.props.recaptchaStatus.loaded
    );
    if (previousRecaptchaStatus !== currentRecaptchaStatus) {
      this.ultimateLoginWithGithub();
    }
  }

  async loginWithGithubCode(code, state, fromLocation) {
    const {
      client,
      existingValues,
      loginSuccessRequest,
      redirectUri,
      loginWithGithubCodeSuccess,
      loginWithGithubCodeFailure,
      push,
      setSmartRoute,
    } = this.props;
    const { tokenFromClaimVerify } = existingValues || {};

    try {
      const recaptchaToken = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
        { action: 'loginwithgithub' }
      );

      const response = await client.mutate({
        mutation: gql`
          mutation githubLogin($code: String!, $state: String!, $redirect_uri: String!) {
            githubLogin(code: $code, state: $state, redirect_uri: $redirect_uri) {
              userExists
              token
            }
          }
        `,
        variables: {
          code: code,
          state: state,
          redirect_uri: redirectUri,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });

      const { data, errors } = response;

      if (errors && errors[0].message) {
        push(fromLocation, {
          flash: buildMessage({
            id: 'login-with-github.error',
            kind: 'danger',
            content: errors[0].message,
          }),
        });

        loginWithGithubCodeFailure({ error: errors[0].message });
      } else if (data && data.githubLogin && data.githubLogin.userExists === true) {
        storage.setToken(data.githubLogin.token);
        storage.deleteDataInLocalStorage(githubMethod.authenticationWithGithub);
        storage.setDataInLocalStorage(githubMethod.loginWithGithub, true);
        const isGithubCTA = storage.getDataInLocalStorage(githubMethod.githubCallToAction);
        const shouldRedirect = isGithubCTA ? false : true;

        loginSuccessRequest({
          token: data.githubLogin.token,
          redirect: shouldRedirect,
          from: fromLocation,
        });
        loginWithGithubCodeSuccess({
          userExists: data.githubLogin.userExists,
          token: data.githubLogin.token,
        });

        if (fromLocation) {
          storage.deleteDataInLocalStorage('github-from');
        }
      } else if (data && data.githubLogin && data.githubLogin.userExists === false) {
        const decoded = jwtDecode(data.githubLogin.token);
        const { name } = decoded;

        storage.deleteDataInLocalStorage(githubMethod.authenticationWithGithub);

        push(routeTemplates.auth.signUp, {
          fromLocation,
          provider: 'github',
          fieldValues: { ...existingValues, name },
          providerValues: { token: data.githubLogin.token },
          tokenFromClaimVerify,
          flash: buildMessage({
            id: 'github-auth-button.additional-fields-required',
            kind: 'info',
            content: messages.additionalFieldsRequired,
          }),
        });
        loginWithGithubCodeFailure({
          error: messages.additionalFieldsRequired,
        });

        setSmartRoute({
          isEmailVerify: true,
        });

        if (fromLocation) {
          setSmartRoute({
            routeAfterEmailVerify: fromLocation,
          });

          storage.deleteDataInLocalStorage('github-from');
        }
      }
    } catch (error) {
      loginWithGithubCodeFailure({ error });
    }
  }

  async githubAuthCallback() {
    const {
      githubAuthStart,
      githubAuthFailure,
      redirectUri,
      githubCTA,
      from,
      redirectToFromPage,
    } = this.props;

    githubAuthStart();
    /* here is where i set a boolean to true in localstorage, 
    this decide the redirect to claim/new page in the loginSuccess fnc*/
    if (githubCTA) storage.setDataInLocalStorage(githubMethod.githubCallToAction, true);

    try {
      const urlParams = new URLSearchParams();
      const requestState = shortid.generate();

      urlParams.set('client_id', process.env.REACT_APP_GITHUB_CLIENT_ID);
      urlParams.set('redirect_uri', redirectUri);
      urlParams.set('scope', 'user:email');
      urlParams.set('state', requestState);
      urlParams.set('allow_signup', 'true');
      window.location = `${process.env.REACT_APP_GITHUB_API_URL}?${urlParams}`;

      if (redirectToFromPage) {
        storage.setDataInLocalStorage('github-from', from);
      }

      storage.setDataInLocalStorage(githubMethod.authenticationWithGithub, true);
    } catch (apiError) {
      flashActions.addMessage({
        id: 'github.auth.status',
        kind: 'danger',
        content: apiError,
      });
      githubAuthFailure({ error: apiError });
    }
  }

  render() {
    const {
      isAuthenticatingWithGithub,
      isLoginWithGithub,
      isFetchingStart,
      children,
      isReady,
      isBlock,
      ...other
    } = this.props;

    if (isLoginWithGithub) {
      return <Loading />;
    }

    return (
      <Button
        isReady={isReady || false}
        onClick={this.githubAuthCallback}
        loading={isAuthenticatingWithGithub || isFetchingStart || isLoginWithGithub}
        disabled={isAuthenticatingWithGithub}
        iconPosition="suffix"
        isBlock={isBlock}
        {...other}
      >
        {children ? (
          <div>{children}</div>
        ) : (
          <div className="d-flex">
            <i className="fab fa-github mr-2" />
            <FormattedMessage id="auth.github-button" defaultMessage="Github" />
          </div>
        )}
      </Button>
    );
  }
}

function mapStateToProps(state, props) {
  const redirectUri = `${window.location.origin}/github-auth`;
  const githubAuthButtonState = selectGithubAuthButtonState(state);
  const githubAuthCallback = selectGithubAuthCallbackState(state);
  const isFetchingStart = githubAuthCallback && selectFetchingStart(state);
  const isAuthenticatingWithGithub = githubAuthCallback && selectAuthenticatingWithGithub(state);
  const isLoginWithGithub = githubAuthButtonState && selectLoginWithGithub(state);
  const recaptchaStatus = libSelectors.selectRecaptchaSdkLibLoadState(state);

  const from =
    (state && state.router && state.router.location && state.router.location.pathname) || '';

  return {
    errorMessage: githubAuthButtonState && selectError(state),
    redirectUri,
    isFetchingStart,
    isAuthenticatingWithGithub,
    isLoginWithGithub,
    recaptchaStatus,
    from,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    githubAuthStart: () => dispatch({ type: authCallbackActionTypes.GITHUB_AUTH.START }),
    githubAuthSuccess: ({ code, state }) =>
      dispatch({ type: authCallbackActionTypes.GITHUB_AUTH.SUCCESS, payload: { code, state } }),
    githubAuthFailure: error =>
      dispatch({ type: authCallbackActionTypes.GITHUB_AUTH.FAILURE, payload: { error } }),

    loginWithGithubCodeStart: () => dispatch({ type: actionTypes.LOGIN_WITH_GITHUB_CODE.START }),
    loginWithGithubCodeSuccess: ({ userExists, token }) =>
      dispatch({
        type: actionTypes.LOGIN_WITH_GITHUB_CODE.SUCCESS,
        payload: { userExists, token },
      }),
    loginWithGithubCodeFailure: ({ error }) =>
      dispatch({ type: actionTypes.LOGIN_WITH_GITHUB_CODE.FAILURE, payload: { error } }),
    loginSuccessRequest: ({ token, redirect, from }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
      }),
    push: (routeName, locationState) => dispatch(push(routeName, locationState)),
    setSmartRoute: bindActionCreators(smartRouteActions.setSmartRoute, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withGithubAuthButtonReducer = injectReducer({
  key: 'GithubAuthButton',
  reducer: GithubAuthButtonReducer,
});
const withGithubAuthCallbackReducer = injectReducer({
  key: 'GithubAuthCallback',
  reducer: GithubAuthCallbackReducer,
});

export default compose(withGithubAuthButtonReducer, withGithubAuthCallbackReducer, withConnect)(
  injectIntl(withApollo(GithubAuthButton))
);
