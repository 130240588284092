import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const fetchClaimReport = {
  request: ({ reportId }) => defineAction(types.FETCH_CLAIM_REPORT.REQUEST, { reportId }),
  start: ({ reportId }) => defineAction(types.FETCH_CLAIM_REPORT.START, { reportId }),
  success: ({ reportId, claimId }) =>
    defineAction(types.FETCH_CLAIM_REPORT.SUCCESS, { reportId, claimId }),
  failure: ({ reportId, error }) =>
    defineAction(types.FETCH_CLAIM_REPORT.FAILURE, { reportId, error }),
};
