/*
  GetStartedCompanyAdmin
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';

// import GetStarted from './get-started';
// import TeamStanding from './team-standing';
import Features from './features';
import Part from './part-finding';
import ConnectToSlack from './connect-to-slack';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import GetStartedCompanyAdminReducer from 'store/graphql/GetStartedCompanyAdmin/reducer';
import injectReducer from 'utils/injectReducer';
import {
  selectCompanyDetails,
  selectHasCompletedTour,
  selectHasOnboarded,
  selectIsUpdating,
} from 'store/graphql/GetStartedCompanyAdmin/selectors';
import * as actionTypes from 'store/graphql/GetStartedCompanyAdmin/action-types';
import routeGenerators from 'ui/common/routes/generators';
import Loading from 'ui/common/loading';
import Authenticated from 'ui/common/auth/authenticated';
import { logUserProperties } from 'services/amplitude-helper';

import themeConfig from '../../../theme/config';
import MediaQuery from 'react-responsive';
// const ON_BOARDING_STEPS = ['get-started', 'career-path', 'team-standing', 'features'];
const EVENT_TYPES = ['GET_STARTED_TOUR', 'ONBOARDING'];

class GetStartedCompanyAdmin extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      step: 0,
      isSlackConnected: false,
    };
  }

  componentDidMount() {
    this.getCompanyConfig();
    this.getCompanyByPrettyId();
  }

  componentDidUpdate(prevProps) {
    const { prettyId, hasCompletedTour, hasOnboarded } = this.props;
    if (hasOnboarded && hasCompletedTour) {
      this.props.history.push(routeGenerators.companies.edit({ prettyId, section: 'engagement' }));
    } else if (prevProps.hasOnboarded === null && hasOnboarded === false) {
      this.props.history.push(`${routeGenerators.companies.onBoard({ prettyId })}`);
    }
  }

  async getCompanyByPrettyId() {
    const {
      client,
      getCompanyDetailsStart,
      getCompanyDetailsSuccess,
      getCompanyDetailsError,
      prettyId,
    } = this.props;

    getCompanyDetailsStart();

    try {
      const result = await client.query({
        query: gql`
          query getCompanyByPrettyId($prettyId: String!) {
            getCompanyByPrettyId(prettyId: $prettyId) {
              id
              companyName
              features {
                hackathon
                ems
              }
            }
          }
        `,
        variables: {
          prettyId: prettyId,
        },
      });

      if (resultHasErrors(result)) {
        getCompanyDetailsError(errorsToSingleMessage(result.errors));
      } else {
        const { getCompanyByPrettyId } = result.data;
        getCompanyDetailsSuccess({ companyDetails: getCompanyByPrettyId });
        logUserProperties({
          isMQL: true,
        });
        if (
          getCompanyByPrettyId &&
          getCompanyByPrettyId.features &&
          getCompanyByPrettyId.features.ems
        ) {
          this.getEvent();
        }
      }
    } catch (error) {
      getCompanyDetailsError(errorsToSingleMessage(error));
    }
  }

  async getEvent() {
    const {
      client,
      setGetStartedStatusStart,
      setGetStartedStatusSuccess,
      setGetStartedStatusError,
      companyDetails,
    } = this.props;
    setGetStartedStatusStart();

    try {
      const result = await client.query({
        query: gql`
          query ems_get_caEvents($companyId: String!) {
            ems_get_caEvents(companyId: $companyId) {
              type
              status
            }
          }
        `,
        variables: {
          companyId: companyDetails.id,
        },
      });

      if (resultHasErrors(result)) {
        setGetStartedStatusError(errorsToSingleMessage(result.errors));
      } else {
        const { ems_get_caEvents } = result.data;
        let set = ems_get_caEvents && ems_get_caEvents.filter(e => EVENT_TYPES.includes(e.type));
        setGetStartedStatusSuccess(set[0], set[1]);
      }
    } catch (error) {
      setGetStartedStatusError(errorsToSingleMessage(error));
    }
  }

  async setEvent() {
    const {
      client,
      setGetStartedStatusStart,
      setGetStartedStatusSuccess,
      setGetStartedStatusError,
      companyDetails,
    } = this.props;

    setGetStartedStatusStart();

    try {
      const resultFromGraphQl = await client.mutate({
        mutation: gql`
          mutation ems_set_caEvent(
            $companyId: String!
            $type: EmsCAEventEnum!
            $status: EmsCAEventStatusEnum!
          ) {
            ems_set_caEvent(companyId: $companyId, type: $type, status: $status) {
              type
              status
            }
          }
        `,
        variables: {
          companyId: companyDetails.id,
          type: 'GET_STARTED_TOUR',
          status: 'DONE',
        },
      });

      if (resultFromGraphQl.error) {
        setGetStartedStatusError(resultFromGraphQl.error);
      } else {
        const { data } = resultFromGraphQl;
        const { ems_set_caEvent } = data;
        let set = ems_set_caEvent && ems_set_caEvent.filter(e => EVENT_TYPES.includes(e.type));

        setGetStartedStatusSuccess(set[0], set[1]);
      }
    } catch (error) {
      setGetStartedStatusError(error.message);
    }
  }

  async getCompanyConfig() {
    const { client, prettyId } = this.props;

    try {
      const response = await client.query({
        query: gql`
          query getCompanyConfig($companyPrettyId: String!) {
            getCompanyConfig(companyPrettyId: $companyPrettyId) {
              isSlackConnected
            }
          }
        `,
        variables: {
          companyPrettyId: prettyId,
        },
      });

      const { data, errors } = response;

      if (resultHasErrors({ errors })) {
        console.log(errorsToSingleMessage({ errors }));
      } else {
        let getCompanyConfig = data.getCompanyConfig;
        if (getCompanyConfig)
          this.setState({
            isSlackConnected: getCompanyConfig.isSlackConnected,
          });

        // logUserProperties({ signup_source: getCompanyConfig.isSlackConnected ? 'slack' : 'mail' });
      }
    } catch (error) {
      console.log(errorsToSingleMessage({ errors: error.message }));
    }
  }

  toNextStep() {
    this.setState({ step: this.state.step + 1 });
  }

  renderDesktop() {
    return (
      <div className={classnames('p-5')}>
        <div className={classnames('mx-5')}>
          {this.state.step === 0 && (
            <Features
              toNextStep={this.toNextStep}
              setEvent={this.setEvent}
              isSlackConnected={this.state.isSlackConnected}
            />
          )}
          {/*{this.state.step === 1 && (*/}
          {/*  <Part*/}
          {/*    isSlackConnected={this.state.isSlackConnected}*/}
          {/*    toNextStep={this.toNextStep}*/}
          {/*    setEvent={this.setEvent}*/}
          {/*  />*/}
          {/*)}*/}
          {!this.state.isSlackConnected &&
            this.state.step === 1 && <ConnectToSlack setEvent={this.setEvent} />}
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className={classnames('py-4')}>
        <div className={classnames('mx-2')}>
          {this.state.step === 0 && <Features toNextStep={this.toNextStep} />}
          {this.state.step === 1 && (
            <Part
              isSlackConnected={this.state.isSlackConnected}
              toNextStep={this.toNextStep}
              setEvent={this.setEvent}
            />
          )}
          {!this.state.isSlackConnected &&
            this.state.step === 2 && <ConnectToSlack setEvent={this.setEvent} />}
        </div>
      </div>
    );
  }

  render() {
    const { updating } = this.props;
    if (updating) return <Loading />;
    return (
      <div>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
          {this.renderMobile()}
        </MediaQuery>
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
          {this.renderDesktop()}
        </MediaQuery>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps && ownProps.match && ownProps.match.params;

  const prettyId = routeParams && routeParams.prettyId;
  return {
    companyDetails: selectCompanyDetails(state),
    hasCompletedTour: selectHasCompletedTour(state),
    hasOnboarded: selectHasOnboarded(state),
    updating: selectIsUpdating(state),
    prettyId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGetStartedStatusStart: () => dispatch({ type: actionTypes.SET_GET_STARTED_STATUS.START }),
    setGetStartedStatusSuccess: (onboardStatus, getStartedStatus) =>
      dispatch({
        type: actionTypes.SET_GET_STARTED_STATUS.SUCCESS,
        payload: { onboardStatus, getStartedStatus },
      }),
    setGetStartedStatusError: error =>
      dispatch({
        type: actionTypes.SET_GET_STARTED_STATUS.FAILURE,
        payload: { error },
      }),
    getCompanyDetailsStart: () => dispatch({ type: actionTypes.GET_COMPANY_DETAILS.START }),
    getCompanyDetailsSuccess: ({ companyDetails }) =>
      dispatch({
        type: actionTypes.GET_COMPANY_DETAILS.SUCCESS,
        payload: { companyDetails },
      }),
    getCompanyDetailsError: error =>
      dispatch({
        type: actionTypes.GET_COMPANY_DETAILS.FAILURE,
        payload: { error },
      }),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'GetStartedCompanyAdmin',
  reducer: GetStartedCompanyAdminReducer,
});

export default Authenticated(
  withRouter(compose(withReducer, withConnect)(injectIntl(withApollo(GetStartedCompanyAdmin))))
);
