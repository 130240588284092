import { defineApiActionTypes, defineAsyncActionTypes } from 'store/common/action-helpers';

const prefix = 'features/admin';

export const FETCH_CLAIMS = defineApiActionTypes(`${prefix}/FETCH_CLAIMS`);

export const APPROVE_CLAIM = defineApiActionTypes(`${prefix}/APPROVE_CLAIM`);

export const DISAPPROVE_CLAIM = defineApiActionTypes(`${prefix}/DISAPPROVE_CLAIM`);

export const FETCH_REJECTION_REASONS = defineAsyncActionTypes(`${prefix}/FETCH_REJECTION_REASONS`);
