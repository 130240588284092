import * as types from './action-types';
import { defineAction } from 'store/common/action-helpers';

export const startAssignment = {
  start: ({ assignmentId }) => defineAction(types.START_ASSIGNMENT.START, { assignmentId }),
  success: ({ result, assignmentId }) =>
    defineAction(types.START_ASSIGNMENT.SUCCESS, { result, assignmentId }),
  failure: ({ errors, assignmentId }) =>
    defineAction(types.START_ASSIGNMENT.FAILURE, { errors, assignmentId }),
};
