import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  saving: false,
});

export function setProfessions(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_USER_PROFESSIONS.START:
      return state.merge({
        saving: true,
      });

    case actionTypes.SET_USER_PROFESSIONS.SUCCESS:
    case actionTypes.SET_USER_PROFESSIONS.FAILURE:
      return state.merge({
        saving: false,
      });
    default:
      return state;
  }
}

export default combineReducers({
  setProfessions,
});
