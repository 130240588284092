import { defineApiActionTypes, defineConfirmationActionTypes } from 'store/common/action-helpers';

const prefix = 'features/education';

export const ADD_EDUCATION_ENTRY = defineApiActionTypes(`${prefix}/ADD_ENTRY`);

export const UPDATE_EDUCATION_ENTRY = defineApiActionTypes(`${prefix}/UPDATE_ENTRY`);

export const DELETE_EDUCATION_ENTRY = {
  ...defineApiActionTypes(`${prefix}/DELETE_ENTRY`),
  ...defineConfirmationActionTypes(`${prefix}/DELETE_ENTRY`),
};
