import { call, select, put } from 'redux-saga/effects';

import { callApiJson } from 'api/call';
import { apiValidationErrorsToFormErrors } from 'api/errors';
import * as authActions from 'store/features/auth/actions';
import * as authSelectors from 'store/features/auth/selectors';

function convertApiErrorToReduxFormErrors(error) {
  let formErrors = {};
  formErrors._error = error.message || 'There was a error performing this action. Please try again';

  if (
    error.response &&
    error.response.status === 422 &&
    error.jsonSchemaValidation &&
    error.validations &&
    error.validations.body
  ) {
    formErrors = {
      ...error.formErrors,
      ...apiValidationErrorsToFormErrors(error.validations.body),
    };
  }

  return formErrors;
}

export default function* callApi(config) {
  let finalConfig = { ...config };

  if (config.requireAuth || config.optionalAuth) {
    const token = yield select(authSelectors.selectAuthToken);
    if (token) {
      finalConfig.headers = {
        ...finalConfig.headers,
        Authorization: `Bearer ${token.value}`,
      };
    }
  }

  try {
    const response = yield call(callApiJson, finalConfig);
    return response;
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.status === 401) yield put(authActions.invalidateSession());
      else if (error.response.status === 403) {
        // Ugly hack. Remove when backend returns correct status
        if (
          error.message &&
          error.message.toLowerCase &&
          (error.message.toLowerCase() === 'user is not logged in' ||
            error.message.toLowerCase() === 'must be logged in')
        ) {
          yield put(authActions.invalidateSession());
        } else {
          yield put(authActions.unauthorized());
        }
      }
    }

    if (error.response && error.response.status >= 500) {
      let newMessage = 'There was an error performing this action.';
      if (error.requestID && error.message) newMessage = error.message;
      error.devMessage = error.message;
      error.message = newMessage;
      console.error(error);
    }

    if (finalConfig.convertApiErrorsToReduxFormErrors) {
      error.formErrors = convertApiErrorToReduxFormErrors(error);
    }

    throw error;
  }
}
