import * as actionTypes from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const fetchCompanyByPrettyId = {
  request: ({ prettyId }, { background = false } = {}) =>
    defineAction(actionTypes.FETCH_COMPANY_BY_PRETTY_ID.REQUEST, { prettyId }, { background }),
  start: ({ prettyId }, { background = false } = {}) =>
    defineAction(actionTypes.FETCH_COMPANY_BY_PRETTY_ID.START, { prettyId }, { background }),
  success: ({ prettyId }) =>
    defineAction(actionTypes.FETCH_COMPANY_BY_PRETTY_ID.SUCCESS, { prettyId }),
  failure: ({ prettyId, error }) =>
    defineAction(actionTypes.FETCH_COMPANY_BY_PRETTY_ID.FAILURE, { prettyId, error }),
};

export const fetchCompanyTeamMembersByPrettyId = {
  request: ({ prettyId }) =>
    defineAction(actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.REQUEST, { prettyId }),
  start: ({ prettyId }) =>
    defineAction(actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.START, { prettyId }),
  success: ({ prettyId, teamMemberIds }) =>
    defineAction(actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.SUCCESS, {
      prettyId,
      teamMemberIds,
    }),
  failure: ({ prettyId, error }) =>
    defineAction(actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.FAILURE, { prettyId, error }),
};

export const fetchCompanyAdvisorsByPrettyId = {
  request: ({ prettyId }) =>
    defineAction(actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.REQUEST, { prettyId }),
  start: ({ prettyId }) =>
    defineAction(actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.START, { prettyId }),
  success: ({ prettyId, advisorIds }) =>
    defineAction(actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.SUCCESS, {
      prettyId,
      advisorIds,
    }),
  failure: ({ prettyId, error }) =>
    defineAction(actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.FAILURE, { prettyId, error }),
};

export const trackCompanyMemberVerifiedClick = (company, member) =>
  defineAction(actionTypes.TRACK_COMPANY_MEMBER_VERIFIED_CLICK, { company, member });
