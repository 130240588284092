import { schema } from 'normalizr';
import { buildIPFSUrl } from 'utils/url';

function parseBool(value) {
  return typeof value === 'string' ? value === 'true' : !!value;
}

export function parseApiDate(value) {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return new Date(value * 1000).toISOString();
}

function normalizeEntityDate(value) {
  if (!value || (!value.year && !value.month)) return null;

  if (value.year) value.year = Number(value.year);
  if (!value.year) return null;

  if (value.month) value.month = Number(value.month);

  return { year: value.year, month: value.month };
}

export const educationEntry = new schema.Entity(
  'education',
  {},
  {
    idAttribute: 'itemKey',
    processStrategy: (entity, parent, key) => {
      entity.id = entity.itemKey;
      entity.meta = { type: 'education' };

      entity.startDate = normalizeEntityDate(entity.startDate);
      entity.endDate = entity.currentlyStudying ? null : normalizeEntityDate(entity.endDate);
      entity.currentlyStudying = !entity.endDate;

      if (key === 'education') {
        entity.userId = entity.userId || parent.id;
      }

      return entity;
    },
  }
);

export const workExperienceEntry = new schema.Entity(
  'workExperience',
  {},
  {
    idAttribute: 'itemKey',
    processStrategy: (entity, parent, key) => {
      entity.id = entity.itemKey;
      entity.meta = { type: 'workExperience' };

      entity.startDate = normalizeEntityDate(entity.startDate);
      entity.endDate = entity.currentlyWorking ? null : normalizeEntityDate(entity.endDate);
      entity.currentlyWorking = !entity.endDate;
      if (key === 'work') {
        entity.userId = entity.userId || parent.id;
      }

      return entity;
    },
  }
);

export const badge = new schema.Entity(
  'badges',
  {},
  {
    idAttribute: 'id',
    processStrategy: (entity, parent, key) => {
      return { ...entity, prettyId: entity.id };
    },
  }
);

export const ratingCriterias = new schema.Entity(
  'ratingCriterias',
  {},
  {
    idAttribute: 'key',
    processStrategy: (entity, parent, key) => {
      return { ...entity };
    },
  }
);

export const skillCategory = new schema.Entity('skillCategories');

export const skill = new schema.Entity('skills', { category: skillCategory });

export const userSkill = new schema.Entity('userSkills', {
  skill: skill,
});

export const user = new schema.Entity(
  'users',
  {
    education: [educationEntry],
    work: [workExperienceEntry],
    skills: [userSkill],
    badges: [badge],
    pendingBadges: [badge],
  },
  {
    processStrategy: (entity, parent, key) => {
      const result = { ...entity, registeredAt: parseApiDate(entity.timestamp) };
      if (
        result.username &&
        result.imgUrl &&
        result.photoIpfs &&
        ['startupeddy', 'patrickmccorry'].indexOf(result.username) >= 0
      ) {
        result.imgUrl = buildIPFSUrl(result.photoIpfs);
      }
      return result;
    },
  }
);

export const votingRound = new schema.Entity(
  'votingRounds',
  {},
  {
    processStrategy: (entity, parent, key) => {
      const { claimId, endRegistration, endVoting, ...other } = entity;
      const result = {
        ...other,
        endRegistration: endRegistration ? parseApiDate(endRegistration) : null,
        endVoting: parseApiDate(endVoting),
      };
      if (claimId) result.claim = { id: claimId };
      return result;
    },
  }
);

export const vote = new schema.Entity(
  'votes',
  {},
  {
    processStrategy: (entity, parent, key) => {
      const {
        claimId,
        votingRoundId,
        voterId,
        registered,
        registeredAt,
        endorsed,
        votedAt,
        ...other
      } = entity;
      const result = {
        ...other,
        registered: parseBool(registered),
        registeredAt: parseApiDate(registeredAt),
        endorsed: parseBool(endorsed),
        votedAt: parseApiDate(votedAt),
      };
      if (claimId) result.claim = { id: claimId };
      if (votingRoundId) result.votingRound = { id: votingRoundId };
      if (voterId) result.voter = { id: voterId };
      return result;
    },
  }
);

export const claim = new schema.Entity(
  'claims',
  {},
  {
    processStrategy: (entity, parent, key) => {
      const { ownerId, createdAt, ...other } = entity;
      const { votingRound, votingRounds, vote } = parent;
      const result = { ...other, createdAt: createdAt ? parseApiDate(createdAt) : null };
      result.requiresRegistration = !result.scClaimId && votingRound && votingRound.endRegistration;
      if (ownerId) result.owner = { id: ownerId };
      if (votingRound) result.votingRound = votingRound;
      if (!result.votingRound && votingRounds && votingRounds.length)
        result.votingRound = votingRounds[0]; // For now
      if (vote) result.vote = { id: vote.id };
      result.isForDiwali = entity.desc && entity.desc.toLowerCase().indexOf('diwali dhamaka') >= 0;
      return result;
    },
  }
);

votingRound.define({
  claim: claim,
});

vote.define({
  votingRound: votingRound,
  voter: user,
  claim: claim,
});

claim.define({
  owner: user,
  votingRound: votingRound,
  vote: vote,
});

export const companyTeamMemberConnection = new schema.Entity(
  'companyTeamMemberConnections',
  {
    user: user,
  },
  {
    processStrategy: (entity, parent, key) => {
      const { creationTimestamp, acceptedTimestamp, rejectedTimestamp, ...passThough } = entity;

      return {
        ...passThough,
        creationTimestamp: creationTimestamp ? parseApiDate(creationTimestamp) : null,
        acceptedTimestamp: acceptedTimestamp ? parseApiDate(acceptedTimestamp) : null,
        rejectedTimestamp: rejectedTimestamp ? parseApiDate(rejectedTimestamp) : null,
      };
    },
  }
);

companyTeamMemberConnection.define({
  user: user,
});

export const companyAdvisorConnection = new schema.Entity(
  'companyAdvisorConnections',
  {
    user: user,
  },
  {
    processStrategy: (entity, parent, key) => {
      const { creationTimestamp, acceptedTimestamp, rejectedTimestamp, ...passThough } = entity;

      return {
        ...passThough,
        creationTimestamp: creationTimestamp ? parseApiDate(creationTimestamp) : null,
        acceptedTimestamp: acceptedTimestamp ? parseApiDate(acceptedTimestamp) : null,
        rejectedTimestamp: rejectedTimestamp ? parseApiDate(rejectedTimestamp) : null,
      };
    },
  }
);

companyAdvisorConnection.define({
  user: user,
});

export const company = new schema.Entity(
  'companies',
  {
    teamMembers: [companyTeamMemberConnection],
    advisors: [companyAdvisorConnection],
  },
  {
    idAttribute: 'prettyId',
  }
);

export const wizardDefinition = new schema.Entity(
  'wizardDefinitions',
  {},
  {
    idAttribute: 'name',
  }
);

export const smartContract = new schema.Entity(
  'smartContracts',
  {},
  { idAttribute: 'contractName' }
);
