import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  error: null,
  loginWithSlack: false,
  token: null,
  ok: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_WITH_SLACK_CODE.START: {
      return state.merge({
        loginWithSlack: true,
        error: null,
      });
    }
    case actionTypes.LOGIN_WITH_SLACK_CODE.SUCCESS: {
      const { token, ok } = action.payload;

      return state.merge({
        loginWithSlack: false,
        ok,
        token,
        error: null,
      });
    }
    case actionTypes.LOGIN_WITH_SLACK_CODE.FAILURE: {
      const { error } = action.payload;
      return state.merge({
        loginWithSlack: false,
        error: error.message,
      });
    }

    default:
      return state;
  }
}
