import React from 'react';

import classnames from 'classnames';
import styles from './index.module.scss';

const ModalFooterV2 = ({ className, children, ...passThrough }) => {
  const childCounts = React.Children.count(children);
  const footerStyle = childCounts >= 2 ? styles.modalFooterBetween : styles.modalFooterRight;

  return (
    <div className={classnames(className, footerStyle)} {...passThrough}>
      {children}
    </div>
  );
};

export default ModalFooterV2;
