import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import autoBind from 'react-autobind';

import MembersCard from 'ui/pages/home-V2/members-card';
import MembersSlider from 'ui/pages/home-V2/members-slider';
import Button from 'ui/common/button';

import teamMembers from 'resources/page/company/team';
import advisors from 'resources/page/company/advisors';

import parallaxStyles from 'ui/common/parallax/index.module.scss';
import ParallaxFormThree from 'resources/common/parallax/parallax-form-3.svg';
import ParallaxFormFour from 'resources/common/parallax/parallax-form-4.svg';

import classnames from 'classnames';
import commonStyles from '../index.module.scss';

import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';

export class TeamsAndAdvisors extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      showAllTeamsMembers: false,
      showAllAdvisors: false,
    };
  }

  toggleShowAllTeamMembers() {
    this.setState({
      showAllTeamsMembers: !this.state.showAllTeamsMembers,
    });
  }

  toggleShowAllAdvisors() {
    this.setState({
      showAllAdvisors: !this.state.showAllAdvisors,
    });
  }

  intialMembers(members) {
    return members.slice(0, 8);
  }

  renderDesktop() {
    return (
      <div className="page-max-width">
        <MediaQuery
          className={classnames(commonStyles.secPy2, 'container-fluid')}
          minWidth={themeConfig.layout.gridBreakpoints.sm}
        >
          {/* teams */}
          <div className={classnames(commonStyles.secPb2)}>
            <div
              className={classnames(
                'row justify-content-center text-center pb-3 mx-3',
                commonStyles.borderBottom
              )}
            >
              <div className="col-12">
                <h2>
                  <FormattedMessage id="page.home.team-members.title" defaultMessage="Team" />
                </h2>
              </div>
            </div>
            <div className="row mx-1 pt-3">
              {(this.state.showAllTeamsMembers
                ? teamMembers
                : this.intialMembers(teamMembers)
              ).map((team, index) => {
                return (
                  <div key={index} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <MembersCard member={team} />
                  </div>
                );
              })}
            </div>
            <div className="row justify-content-center pt-3">
              <Button isReady={false} onClick={this.toggleShowAllTeamMembers}>
                {this.state.showAllTeamsMembers ? (
                  <FormattedMessage
                    id="page.home.team-advisors.button.less"
                    defaultMessage="Show Less"
                  />
                ) : (
                  <FormattedMessage
                    id="page.home.team-advisors.button.more"
                    defaultMessage="Load More"
                  />
                )}
              </Button>
            </div>
          </div>
          {/* advisors */}
          <div>
            <div
              className={classnames(
                'row justify-content-center text-center pb-3 mx-3',
                commonStyles.borderBottom
              )}
            >
              <div className="col-12">
                <h2>
                  <FormattedMessage id="page.home.advisor.title" defaultMessage="Advisors" />
                </h2>
              </div>
            </div>
            <div className="row mx-1 pt-3">
              {(this.state.showAllAdvisors
                ? advisors
                : this.intialMembers(advisors)
              ).map((advisor, index) => {
                return (
                  <div key={index} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                    <MembersCard cardSmall member={advisor} />
                  </div>
                );
              })}
            </div>
            {/*<div className="row justify-content-center text-center pt-3">*/}
            {/*  <Button isReady={false} onClick={this.toggleShowAllAdvisors}>*/}
            {/*    {this.state.showAllAdvisors ? (*/}
            {/*      <FormattedMessage*/}
            {/*        id="page.home.team-advisors.button.less"*/}
            {/*        defaultMessage="Show Less"*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      <FormattedMessage*/}
            {/*        id="page.home.team-advisors.button.more"*/}
            {/*        defaultMessage="Load More"*/}
            {/*      />*/}
            {/*    )}*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </MediaQuery>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className="page-max-width">
        <MediaQuery
          className={classnames('container-fluid px-0')}
          maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}
        >
          {/* teams */}
          <div className={classnames(commonStyles.secPtM4)}>
            <div
              className={classnames(
                'row justify-content-center text-center pb-md-3 mx-4',
                commonStyles.borderBottom
              )}
            >
              <div className="col-12">
                <h2>
                  <FormattedMessage id="page.home.team-members.title" defaultMessage="Team" />
                </h2>
              </div>
            </div>

            <div className="pt-3">
              <MembersSlider responsive={false} members={teamMembers} />
            </div>
          </div>
          {/* advisors */}
          <div className={classnames(commonStyles.secPtM4)}>
            <div
              className={classnames(
                'row justify-content-center text-center pb-md-3 mx-4',
                commonStyles.borderBottom
              )}
            >
              <div className="col-12">
                <h2>
                  <FormattedMessage id="page.home.advisor.title" defaultMessage="Advisors" />
                </h2>
              </div>
            </div>
          </div>

          <div className="pt-3">
            <MembersSlider responsive={false} members={advisors} cardSmall />
          </div>
        </MediaQuery>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div
          className={classnames(
            parallaxStyles.parS1,
            parallaxStyles.parT130,
            'position-absolute offset-1 d-md-none'
          )}
        >
          <img src={ParallaxFormThree} alt="Parallax Form" />
        </div>
        <div
          className={classnames(
            parallaxStyles.parS1,
            parallaxStyles.parT10,
            'position-absolute offset-10'
          )}
        >
          <img src={ParallaxFormFour} alt="Parallax Form" />
        </div>
        {this.renderDesktop()}
        {this.renderMobile()}
      </div>
    );
  }
}

export default injectIntl(TeamsAndAdvisors);
