import React, { Component } from 'react';
import autobind from 'react-autobind';
import { FormFeedback } from 'reactstrap';

//https://github.com/luisvinicius167/ityped
import { init as initTyped } from 'ityped/dist';

import { callApiJson } from 'api/call';
import injectRequiredIndicator from 'ui/common/form/indicators/required';

import AutoSuggest from 'ui/common/rebranding/components/AutoSuggest/AutoSuggest';

class ApiAutoCompleteField extends Component {
  constructor(props) {
    super(props);

    autobind(this);

    this.state = {
      options: [],
      searchText: '',
      isSearching: false,
    };
  }

  componentDidMount() {
    const { typedPlaceholder } = this.props;

    if (typedPlaceholder) {
      const myElement = document.querySelector('#find-skill-undefined');

      initTyped(myElement, {
        placeholder: true,
        typeSpeed: 130,
        backSpeed: 90,
        showCursor: false,
        strings: ['Python', 'Javascript', 'Solidity', 'Java', 'C#', 'Ruby', 'C++'],
      });
    }
  }

  static defaultProps = {
    allowCustom: true,
  };

  onChange(selected) {
    const {
      allowCustom,
      textFieldName,
      valueFieldName,
      apiResponseConfig: { textKey, valueKey },
    } = this.props;
    const textField = this.props[textFieldName];
    const valueField = this.props[valueFieldName];

    const value = selected;

    if (value) {
      textField.input.onChange(textKey && typeof value === 'object' ? value[textKey] : value);
      valueField.input.onChange(
        valueKey && typeof value === 'object' ? value['id'] : allowCustom ? value : null
      );
    } else {
      textField.input.onChange(null);
      valueField.input.onChange(null);
    }
  }

  onSearch(searchText) {
    const { apiRequestConfig, apiResponseConfig: { resultsKey, valueKey, textKey } } = this.props;

    return new Promise((resolve, reject) => {
      if (searchText) {
        // this.setState({ isSearching: true, searchText });
        callApiJson(apiRequestConfig(searchText)).then(response => {
          let results = response[resultsKey];

          if (results && results.length >= 0) {
            results = results.map(result => {
              return valueKey && result[valueKey]
                ? { ...result, id: result[valueKey] }
                : { [textKey]: result, id: result };
            });
          }

          resolve(results);
        });
      }
    });
  }

  onInputChange(inputText) {
    const { searchFieldName } = this.props;
    const inputField = this.props[searchFieldName];

    if (inputText) {
      inputField.input.onChange(inputText);
    }
  }

  render() {
    const {
      textFieldName,
      valueFieldName,
      searchFieldName,
      label,
      hint,
      // disabled,
      apiResponseConfig: { textKey },
    } = this.props;

    const textField = this.props[textFieldName];
    const valueField = this.props[valueFieldName];
    const searchField = this.props[searchFieldName];

    const {
      meta: { form, touched: textFieldTouched, submitting, error: textFieldError },
    } = textField;

    const { meta: { touched: searchFieldTouched, error: searchFieldError } } = searchField;
    const textFieldHasError = (textFieldTouched || submitting) && !!textFieldError;
    const searchFieldHasError = (searchFieldTouched || submitting) && !!searchFieldError;

    const inputId = `${form}-${valueField.name}`;

    // const selected = [
    //   valueField && valueField.input && valueField.input.value
    //     ? textKey
    //       ? { id: valueField.input.value, [textKey]: textField.input.value }
    //       : textField.input.value
    //     : undefined,
    // ].filter(Boolean);

    return (
      <div className="py-2">
        <AutoSuggest
          id={inputId}
          onChange={this.onChange}
          suggestionFn={this.onSearch}
          suggestionField={textKey}
          value={textField.input.value}
          placeholder={hint}
          labelText={label}
          hasError={textFieldHasError || searchFieldError}
          errorMessage={textFieldHasError || searchFieldError}
        />
        {textFieldHasError && (
          <FormFeedback className={textFieldHasError ? 'd-block' : null}>
            <p className="text-danger">{textFieldError}</p>
          </FormFeedback>
        )}
        {searchFieldHasError && (
          <FormFeedback className={searchFieldHasError ? 'd-block' : null}>
            <p className="text-danger">{searchFieldError}</p>
          </FormFeedback>
        )}
      </div>
    );
  }
}

export default injectRequiredIndicator(ApiAutoCompleteField);
