import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import styles from './index.module.scss';
import commonStyles from '../index.module.scss';
import classnames from 'classnames';

import parallaxStyles from 'ui/common/parallax/index.module.scss';
import ParallaxFormThree from 'resources/common/parallax/parallax-form-3.svg';
import ParallaxFormFive from 'resources/common/parallax/parallax-form-5.svg';

import { whitepaperHeadline } from 'resources/page/home/whitepaper/';
import { animateHeadline } from './animation.js';
import Fade from 'react-reveal/Fade';

export class WhitePaper extends Component {
  render() {
    return (
      <div
        className={classnames(
          commonStyles.secPt2,
          commonStyles.secPtM4,
          'container-fluid page-max-width position-relative'
        )}
      >
        <Fade fraction={1} duration={0} onReveal={animateHeadline}>
          <div
            className={classnames(styles.whitepaperHeadline)}
            dangerouslySetInnerHTML={{ __html: whitepaperHeadline[0].svg }}
          />
        </Fade>
        <Fade bottom duration={1600} distance="40px">
          <div
            className={classnames(
              parallaxStyles.parS1,
              parallaxStyles.parT10,
              'position-absolute offset-1'
            )}
          >
            <img src={ParallaxFormThree} alt="Parallax Form" />
          </div>
          <div className={classnames(parallaxStyles.parS1, 'position-absolute offset-10')}>
            <img src={ParallaxFormFive} alt="Parallax Form" />
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-12 pt-5">
              {/* <Button download={brand.whitepaper.en.downloadName} href={brand.whitepaper.en.url}>
                <FormattedMessage
                  id="home.white.paper.button"
                  defaultMessage="Download Whitepaper"
                />
              </Button> */}
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

export default injectIntl(WhitePaper);
