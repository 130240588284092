import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

export default function(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.FETCH_CLAIM_REPORT.START:
      const { reportId: fetchingReportId } = action.payload;
      if (fetchingReportId) {
        return state.merge(
          {
            [fetchingReportId]: {
              fetching: true,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_CLAIM_REPORT.SUCCESS:
      const { reportId: fetchedReportId, claimId: fetchedClaimId } = action.payload;
      if (fetchedReportId) {
        return state.merge(
          {
            [fetchedReportId]: {
              fetching: false,
              lastFetchedAt: new Date().toISOString(),
              claimId: fetchedClaimId,
            },
          },
          { deep: true }
        );
      }
      return state;
    default:
      return state;
  }
}
