import { defineMessages } from 'react-intl';

export default defineMessages({
  created: {
    id: 'admin.badges.messages.create',
    defaultMessage: 'Badge created successfully',
  },
  updated: {
    id: 'admin.badges.messages.updated',
    defaultMessage: 'Badge updated successfully',
  },
  confirmDelete: {
    id: 'admin.badges.messages.delete.confirm',
    defaultMessage: `<p class="lead">Are you sure you want to delete badge <strong>{prettyId}</strong>?</p>`,
  },
  assign: {
    id: 'admin.badges.messages.assign',
    defaultMessage: 'Assigned successfully',
  },
  unassign: {
    id: 'admin.badges.messages.unassign',
    defaultMessage: 'Unassigned successfully',
  },
  confirmAssign: {
    id: 'admin.badges.messages.assign.confirm',
    defaultMessage: `<p class="lead">You are about to <strong>assign badges</strong>. Are you sure?</p>`,
  },
  confirmUnassign: {
    id: 'admin.badges.messages.unassign.confirm',
    defaultMessage: `<p class="lead"> You are about to <strong>unassign badges</strong>. Are you sure?</p>`,
  },
});
