import moment from 'moment';
import { levels, levelIds, findValidatableBySkillName } from 'resources/validations';

const CLAIM_EXTENSION_DEADLINE = Number(
  process.env.REACT_APP_CLAIM_EXTENSION_DEADLINE || 48 * 60 * 60 * 1000
);

export const STATUSES = {
  pendingApprovalFromAdmin: 'pendingApprovalFromAdmin',
  disapprovedByAdmin: 'disapprovedByAdmin',
  queuedForRelease: 'queuedForRelease',
  registration: 'registration',
  voting: 'voting',
  evaluated: 'evaluated',
  tallying: 'tallying',
  endorsed: 'endorsed',
  flagged: 'flagged',
  unverified: 'unverified',
  tentativelyIndorsed: 'tentativelyIndorsed',
  tentativelyFlagged: 'tentativelyFlagged',
  adminCanReOpenClaim: 'adminCanReOpenClaim',
  pendingVerification: 'pendingVerification',
};

export const apiFinalStatusToUIStatus = {
  indorsed: STATUSES.endorsed,
  flagged: STATUSES.flagged,
  unverified: STATUSES.unverified,
  [true]: STATUSES.endorsed,
  [false]: STATUSES.unverified,
};

export const apiStatusToUIStatus = {
  indorsed: STATUSES.indorsed,
  rejected: STATUSES.flagged,
  no_consensus: STATUSES.unverified,
  pending_verification: STATUSES.pendingVerification,
};

export const calculateClaimSkillInfo = (claim, aipSkills) => {
  if (!claim) return {};
  return findValidatableBySkillName(claim.title, aipSkills);
};

export const calculateClaimLevelInfo = claim => {
  if (!claim) return null;

  return levels[claim.level] || levels[levelIds.intermediate];
};

export const calculateClaimStatus = claim => {
  const { status, finalStatus, votingRound, canBeReopened, scDeadline } = claim;

  /*  
    ID-1509 - checking the status if adminCanReOpenClaim is true base on BE
    returning canBeReopened is true and claim.sc_deadline - 48 * 60 * 60 > current_unix_timestamp
  */
  if (canBeReopened && moment().isBefore(moment(scDeadline * 1000 - CLAIM_EXTENSION_DEADLINE)))
    return STATUSES.adminCanReOpenClaim;

  if (typeof status !== 'undefined' && status !== null && apiStatusToUIStatus[status])
    return apiStatusToUIStatus[status];

  if (
    typeof finalStatus !== 'undefined' &&
    finalStatus !== null &&
    apiFinalStatusToUIStatus[finalStatus]
  )
    return apiFinalStatusToUIStatus[finalStatus];

  if (typeof claim.approved === 'undefined' && typeof claim.disapproved === 'undefined')
    return STATUSES.pendingApprovalFromAdmin;
  if (claim.disapproved) return STATUSES.disapprovedByAdmin;

  if (claim.approved && !claim.releasedAt && !votingRound) return STATUSES.queuedForRelease;

  if (!claim.approved || !votingRound) return null;

  if (
    claim.requiresRegistration &&
    votingRound.endRegistration &&
    moment().isBefore(votingRound.endRegistration)
  ) {
    return STATUSES.registration;
  } else if (votingRound.endVoting) {
    if (moment().isBefore(votingRound.endVoting)) {
      // ID-1343 showing `tentatively indorsed` or `tentatively rejected`
      if (claim.endorseCount || claim.flagCount) {
        const endorsements = claim.endorseCount || 0;
        const flags = claim.flagCount || 0;
        if (endorsements > flags) {
          return STATUSES.tentativelyIndorsed;
        } else if (endorsements < flags) {
          return STATUSES.tentativelyFlagged;
        }
      }
      return STATUSES.voting;
    } else {
      if (typeof status !== 'undefined' && status !== null && apiStatusToUIStatus[status])
        return apiStatusToUIStatus[status];
      else if (typeof finalStatus !== 'undefined' && finalStatus !== null)
        return apiFinalStatusToUIStatus[finalStatus];
      else if (claim.isScClaim && claim.scClaimId) return STATUSES.tallying;

      if (claim.endorseCount || claim.flagCount) {
        const endorsements = claim.endorseCount || 0;
        const flags = claim.flagCount || 0;
        if (endorsements > flags) {
          return STATUSES.endorsed;
        } else if (endorsements < flags) {
          return STATUSES.flagged;
        }
      }
    }

    return STATUSES.unverified;
  }
};

// ID-1934 FlaggedReasonType is more commonly used now so better put the definition here

export const FlaggedReasonTypes = {
  plagiarism: 'plagiarism',
  codingLanguageMismatch: 'codingLanguageMismatch',
  noRepo: 'noRepo',
  exposingSensitiveInfo: 'exposingSensitiveInfo',
  others: 'others',
};

export const processFlaggedReasons = flaggedReasonType => {
  /*
    Convert flaggedReasonType { others: 1, noRepo: 3, sensitiveInfo: 3 } to ["noRepo", "sensitiveInfo"]
  */
  const processedReasons = Object.keys(flaggedReasonType || {}).filter(reasonType => {
    // ID-2134 show plagiarised claim only if no. of plagiarised votes > 1
    if (reasonType === FlaggedReasonTypes.plagiarism && flaggedReasonType[reasonType] === 1)
      return false;
    return reasonType !== FlaggedReasonTypes.others && flaggedReasonType[reasonType] > 0;
  });

  return processedReasons;
};
