import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const Card = ({ className, children, id, borderless, ...other }) => {
  const cardClasses = classNames(className, 'card');

  return (
    <div
      id={id}
      className={cardClasses}
      {...other}
      style={borderless ? { border: 'none', boxShadow: '0 0 30px 0 rgba(76,147,230,0.1)' } : {}}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

export default Card;
