import { actionTypes as formActionTypes } from 'redux-form';
import { getFormSyncErrors, getFormAsyncErrors, getFormSubmitErrors } from 'redux-form';

function getErrorEvents(parentName, errors) {
  return Object.keys(errors || {}).reduce((events, field) => {
    const fieldErrors = errors[field];
    if (Array.isArray(fieldErrors)) {
      return events.concat(
        errors[field].map(error => ({
          hitType: 'event',
          eventCategory: 'form-error',
          eventAction: `${parentName}${field}`,
          eventLabel: error,
        }))
      );
    } else if (typeof fieldErrors === 'string') {
      return events.concat({
        hitType: 'event',
        eventCategory: 'form-error',
        eventAction: `${parentName}${field}`,
        eventLabel: fieldErrors,
      });
    } else if (fieldErrors) {
      return events.concat(getErrorEvents(`${parentName}${field}.`, fieldErrors) || []);
    }

    return events;
  }, []);
}

const formSubmitFailed = (action, prevState, nextState) => {
  const form = action.meta.form;
  const isError = action.error;

  if (!isError) return null;

  const syncErrors = getFormSyncErrors(form)(nextState);
  const asyncErrors = getFormAsyncErrors(form)(nextState);
  const submitErrors = getFormSubmitErrors(form)(nextState);

  const events = [
    ...getErrorEvents(`${form}/`, syncErrors),
    ...getErrorEvents(`${form}/`, asyncErrors),
    ...getErrorEvents(`${form}/`, submitErrors),
  ];

  return events;
};

export default {
  [formActionTypes.SET_SUBMIT_FAILED]: formSubmitFailed,
};
