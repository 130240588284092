import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import arrowDownImg from 'resources/common/arrow_down_blue.svg';
import { scrollTo } from 'ui/common/scroller';
import heroImage from 'resources/page/company/hero/img/heroImage.jpg';

import classnames from 'classnames';
import styles from './index.module.scss';
import * as messages from './messages';

import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import commonStyles from '../index.module.scss';

export class Hero extends Component {
  render() {
    const { intl: { formatMessage } } = this.props;

    return (
      <div className={classnames(styles.hero, 'page-max-width')}>
        <div className={classnames(commonStyles.secPt1, commonStyles.secPtM0, 'container-fluid')}>
          <div className="row justify-content-center pt-3 pt-md-4 pb-0">
            <img className="row justify-content-center col-12 col-md-10" src={heroImage} alt="" />
          </div>
          {/* <div className="row justify-content-center py-2">
            <p className="row justify-content-start col-12 col-md-10">
              {formatMessage(messages.content.photoCaption)}
            </p>
          </div> */}
          <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.md}>
            <div className="text-center py-3 hero-arrow">
              <img
                onClick={() => scrollTo('company-intro-section', 50)}
                className="d-block mx-auto pt-3 pointer bounce"
                src={arrowDownImg}
                width="20px"
                alt="arrow-down"
              />
              <p className="text-secondary">{formatMessage(messages.content.scroll)}</p>
            </div>
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default injectIntl(Hero);
