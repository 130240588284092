import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { connectModal as reduxModal } from 'redux-modal';
import { submit } from 'redux-form';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';

import { isSubmitting } from 'redux-form';

import Button from 'ui/common/button';
import { setEthereumAddress } from 'store/features/users/actions';
import {
  selectCurrentUserId,
  selectCurrentUserEthereumAddress,
} from 'store/features/auth/selectors';

import * as messages from './messages';
import Form, { FORM_NAME } from './form';

const { request: setEthereumAddressRequest } = setEthereumAddress;

export const MODAL_NAME = 'set-ethereum-address';

class SetEthereumAddress extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onSubmit(values) {
    this.props.setEthereumAddressRequest({
      userId: this.props.userId,
      ...values,
      form: FORM_NAME,
      modal: MODAL_NAME,
    });
  }

  save() {
    this.props.submit(FORM_NAME);
  }

  render() {
    const {
      userHasEthereumAddress,
      show,
      intl: { formatMessage },
      handleHide,
      isSaving,
      title,
    } = this.props;

    return (
      <Modal isOpen={show}>
        <ModalHeader toggle={handleHide}>
          {title
            ? title && !!title.id && !!title.defaultMessage ? formatMessage(title) : title
            : formatMessage(
                userHasEthereumAddress ? messages.content.updateTitle : messages.content.addTitle
              )}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={this.onSubmit} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.save} loading={isSaving}>
            {formatMessage(messages.buttons.save)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const selectIsSubmitting = isSubmitting(FORM_NAME);

function mapStateToProps(state) {
  return {
    userId: selectCurrentUserId(state),
    userHasEthereumAddress: selectCurrentUserEthereumAddress(state),
    isSaving: selectIsSubmitting(state),
  };
}

const mapDispatchToProps = {
  submit,
  setEthereumAddressRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(injectIntl(SetEthereumAddress))
);
