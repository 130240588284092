import React from 'react';
import classnames from 'classnames';

import Avatar from 'react-avatar';

import themeConfig from 'ui/theme/config';
import { ensureScheme, buildIPFSUrl } from 'utils/url';

const scheme = process.env.REACT_APP_FILE_STORAGE_DEFAULT_SCHEME || 'https';

export default function({ user, className, size, disableURL, ...otherProps }) {
  const url = user
    ? ensureScheme(user.imgUrl ? user.imgUrl : buildIPFSUrl(user.photoIpfs), scheme)
    : undefined;
  if (url && !disableURL) {
    return <Avatar src={url} className={className} size={size} round {...otherProps} />;
  } else {
    return (
      <Avatar
        name={user.name}
        maxInitials={3}
        className={classnames('bg-dark', 'text-light', 'text-center', className)}
        size={size}
        color={themeConfig.palette.primary}
        round
        {...otherProps}
      />
    );
  }
}
