import { createSelector } from 'reselect';

const selectPayForClaimReportButtonState = state => state.PayForClaimReportButton;

export const selectIsFetchingPrice = createSelector(
  selectPayForClaimReportButtonState,
  (state, props) => props.claimId,
  (state, claimId) => state.fetchPrice[claimId] && !!state.fetchPrice[claimId].inProgress
);

export const selectIsPaying = createSelector(
  selectPayForClaimReportButtonState,
  (state, props) => props.claimId,
  (state, claimId) => state.pay[claimId] && !!state.pay[claimId].inProgress
);

export const selectClaimPrice = createSelector(
  selectPayForClaimReportButtonState,
  (state, props) => props.claimId,
  (state, claimId) => state.fetchPrice[claimId] && state.fetchPrice[claimId].data
);

export const selectErrorInFetchingPrice = createSelector(
  selectPayForClaimReportButtonState,
  (state, props) => props.claimId,
  (state, claimId) => state.fetchPrice[claimId] && state.fetchPrice[claimId].error
);

export const selectErrorInPaying = createSelector(
  selectPayForClaimReportButtonState,
  (state, props) => props.claimId,
  (state, claimId) => state.pay[claimId] && state.pay[claimId].error
);
