import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';

const ModalAlert = ({ className, id, children }) => {
  return (
    <div
      id={id}
      className={classnames(
        className,
        styles.modalAlert,
        'row justify-content-center border-bottom border-top my-5 '
      )}
    >
      <div className="p-5 text-center col-6">{children}</div>
    </div>
  );
};

ModalAlert.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ModalAlert;
