import { fork } from 'redux-saga/effects';

import auth from './auth/saga';
import admin from './admin/saga';
import users from './users/saga';
import claims from './claims/saga';
import votes from './votes/saga';
import votingRounds from './voting-rounds/saga';
import education from './education/saga';
import workExperience from './work-experience/saga';
import companies from './companies/saga';
import badges from './badges/saga';
import ratingCriterias from './rating-criterias/saga';
import wizards from './wizard/saga';
import interests from './interests/saga';
import professions from './professions/saga';
import ethereum from './ethereum/saga';
import claimReports from './claim-reports/saga';

// DO NOT REMOVE new-saga-import. The generator needs this to insert new saga
// new-saga-import

export default function* entities() {
  yield fork(ethereum);
  yield fork(auth);
  yield fork(admin);
  yield fork(users);
  yield fork(claims);
  yield fork(votes);
  yield fork(votingRounds);
  yield fork(education);
  yield fork(workExperience);
  yield fork(companies);
  yield fork(badges);
  yield fork(wizards);
  yield fork(interests);
  // DO NOT REMOVE new-saga-fork. The generator needs this to insert new saga
  // new-saga-fork
  yield fork(professions);
  yield fork(claimReports);
  // ID-1926 get dynamic rating criterias description from BE instead
  yield fork(ratingCriterias);
}
