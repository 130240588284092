import React from 'react';

import classnames from 'classnames';

export default ({ className, children, ...passThrough }) => {
  return (
    <div className={classnames(className)} {...passThrough}>
      {children}
    </div>
  );
};
