import { createSelector } from 'reselect';

export const selectGithubAuthCallbackState = state => state.GithubAuthCallback;

export const selectError = createSelector(
  selectGithubAuthCallbackState,
  (state, props) => state.error
);

export const selectFetchingStart = createSelector(
  selectGithubAuthCallbackState,
  state => state.fetchingStart
);

export const selectAuthenticatingWithGithub = createSelector(
  selectGithubAuthCallbackState,
  state => state.authenticatingWithGithub
);
