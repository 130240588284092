const imagesPath = './images';

export default [
  {
    name: 'Smith + Crown',
    imageUrl: require(`${imagesPath}/smithCrown.png`),
    url: 'https://www.smithandcrown.com/',
  },
  {
    name: 'Wings',
    imageUrl: require(`${imagesPath}/wings.png`),
    url: 'https://wings.ai/',
  },
  {
    name: 'Coinsilium',
    imageUrl: require(`${imagesPath}/coinsilium.png`),
    url:
      'https://www.ibtimes.co.uk/blockchain-accelerator-coinsilium-invests-singapores-indorse-1625379',
  },
  {
    name: 'Blockchain Assets',
    imageUrl: require(`${imagesPath}/blockchainAssets.png`),
    url: 'https://www.bca.fund/',
  },
  {
    name: '#Hashed',
    imageUrl: require(`${imagesPath}/hashed.png`),
    url: 'https://www.hashed.com/',
  },
  {
    name: 'Datawallet',
    imageUrl: require(`${imagesPath}/dataWallet.png`),
    url:
      'https://www.banklesstimes.com/2018/01/05/datawallet-indorse-steemit-let-users-profit-personal-data/',
  },
  {
    name: 'Gimli',
    imageUrl: require(`${imagesPath}/gimli.png`),
    url: 'https://medium.com/joinindorse/announcing-the-gimli-partnership-with-indorse-22b645c6f5f',
  },
  {
    name: 'DNN',
    imageUrl: require(`${imagesPath}/dnn.png`),
    url:
      'https://www.prnewswire.com/news-releases/dnn-signs-partnership-with-indorse-a-decentralized-network-for-professionals-654417183.html',
  },
  {
    name: 'Qiibee',
    imageUrl: require(`${imagesPath}/qb.png`),
    url:
      'https://www.hrtechnologist.com/news/rewards-and-recognition/qiibee-partners-with-indorse-to-reward-employees/',
  },
  {
    name: 'Canya',
    imageUrl: require(`${imagesPath}/canYa.png`),
    url: 'https://blog.canya.com/2017/10/14/canya-indorse-official-partnership/',
  },
  {
    name: 'Bitjob',
    imageUrl: require(`${imagesPath}/bitJob.png`),
    url: 'http://www.banklesstimes.com/2017/09/09/indorse-bitjob-to-change-job-searching/',
  },
  {
    name: 'Consensys Ad tech',
    imageUrl: require(`${imagesPath}/consensys.png`),
    url:
      'http://www.the-blockchain.com/2017/11/03/consensys-ad-tech-announces-collaboration-indorse-ensure-authenticity-professional-networks',
  },
  {
    name: 'Tutellus.io',
    imageUrl: require(`${imagesPath}/tutellus.png`),
    url: `https://medium.com/tutellus-io/indorse-and-tutellus-join-forces-to-improve-user-skills-accreditations-through-blockchain-239cfac0967a`,
  },
  {
    name: 'Dock.io',
    imageUrl: require(`${imagesPath}/dockIo.png`),
    url: `https://medium.com/dock-io/partnership-announcement-dock-io-indorse-6b5fd06fe566`,
  },
  {
    name: 'Fund Request',
    imageUrl: require(`${imagesPath}/fundRequest.png`),
    url: `https://blog.fundrequest.io/indorse-integrates-with-fundrequest-b1a4c5db7b32`,
  },
  {
    name: 'Change',
    imageUrl: require(`${imagesPath}/change.png`),
    url: `https://medium.com/joinindorse/announcing-the-indorse-partnership-with-change-326250fd36c0?source=collection_home---6------6----------------`,
  },
  {
    name: 'Appcoins',
    imageUrl: require(`${imagesPath}/appcoins.png`),
    url:
      'https://www.crowdfundinsider.com/2017/11/124711-indorse-announces-strategic-collaboration-appcoins/',
  },
  {
    name: 'Top ICO List',
    imageUrl: require(`${imagesPath}/topIcoList.png`),
    url: 'https://topicolist.com/',
  },
  {
    name: 'Airbloc',
    imageUrl: require(`${imagesPath}/abl.png`),
    url: 'https://airbloc.org/',
  },
  {
    name: 'QIEX',
    imageUrl: require(`${imagesPath}/qiex.png`),
    url: 'https://www.qiex.com/',
  },
  {
    name: 'ICOCrunch',
    imageUrl: require(`${imagesPath}/icoCrunch.png`),
    url:
      'http://blocktribune.com/crypto-research-platform-icocrunch-partners-with-indorse-to-ensure-legitimacy-of-icos/',
  },
  {
    name: 'Fysical',
    imageUrl: require(`${imagesPath}/fysical.png`),
    url: 'https://fysical.org/',
  },
  {
    name: 'Foresting',
    imageUrl: require(`${imagesPath}/foresting.png`),
    url: 'http://foresting.io/',
  },
  {
    name: 'GovBlocks',
    imageUrl: require(`${imagesPath}/gov-block.jpg`),
    url: 'https://govblocks.io/',
  },
  {
    name: 'W 12',
    imageUrl: require(`${imagesPath}/w-12.jpg`),
    url: 'https://tokensale.w12.io/',
  },
  {
    name: 'Dream',
    imageUrl: require(`${imagesPath}/dream.jpg`),
    url: 'https://thirtyk.com/2018/07/03/indorse-dream-ai/',
  },
  {
    name: 'TrustWallet',
    imageUrl: require(`${imagesPath}/trust-wallet.png`),
    url: 'https://trustwalletapp.com/',
  },
  {
    name: 'ParachuteToken',
    imageUrl: require(`${imagesPath}/parachute.png`),
    url: 'https://www.parachutetoken.com/',
  },
  {
    name: 'HitBTC',
    imageUrl: require(`${imagesPath}/hitbtc.png`),
    url: 'https://hitbtc.com/',
  },
];
