import { call, put, fork, takeEvery, take, race, cancel, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';

import * as actionTypes from './action-types';
import * as actions from './actions';

import smartContracts from './smart-contracts/saga';

function* extractBasicInfo({ payload: { web3 } }) {
  yield put(actions.extraBasicInfoFromWeb3.start());

  try {
    const networkName = yield call([web3, web3.detectNetworkAsync]);
    const providerName = web3.detectProvider();
    const accounts = yield call([web3, web3.getAccountsAsync]);
    const medianGasPriceInGwei = yield call([web3, web3.getGasPriceAsync], 'gwei');
    yield put(
      actions.extraBasicInfoFromWeb3.success({
        networkName,
        providerName,
        accounts,
        medianGasPriceInGwei: medianGasPriceInGwei ? medianGasPriceInGwei.toString(10) : '1',
      })
    );
  } catch (error) {
    yield put(actions.extraBasicInfoFromWeb3.failure({ error: error.message }));
  }
}

function* watchExtractBasicInfo() {
  yield takeEvery(actionTypes.EXTRACT_BASIC_INFO_FROM_WEB3.REQUEST, extractBasicInfo);
}

function* pollToRextractBasicInfoFromWeb3({ payload }) {
  try {
    yield call(console.log, 'Polling basic info started', payload);
    while (true) {
      yield call(extractBasicInfo, { payload });
      yield call(delay, 1000);
    }
  } finally {
    if (yield cancelled()) yield call(console.log, 'Poll basic info cancelled', payload.pollerId);
  }
}

function* manageBasicInfoPollers() {
  while (true) {
    const pollers = [];

    const starter = yield take(actionTypes.POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3.REGISTER);
    if (pollers.indexOf(starter.payload.pollerId) === -1) pollers.push(starter.payload.pollerId);

    const task = yield fork(pollToRextractBasicInfoFromWeb3, starter);

    while (true) {
      const { register, unregister } = yield race({
        register: take(actionTypes.POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3.REGISTER),
        unregister: take(actionTypes.POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3.UNREGISTER),
      });

      if (register) {
        if (pollers.indexOf(register.payload.pollerId) === -1)
          pollers.push(register.payload.pollerId);
      } else if (unregister) {
        if (pollers.indexOf(unregister.payload.pollerId) >= 0)
          pollers.splice(pollers.indexOf(unregister.payload.pollerId), 1);

        if (pollers.length === 0) {
          yield cancel(task);
          break;
        }
      }
    }
  }
}

export default function* ethereum() {
  yield fork(watchExtractBasicInfo);
  yield fork(manageBasicInfoPollers);
  yield fork(smartContracts);
}
