const imagesPath = './images';

export default [
  {
    name: 'TechInAsia',
    url:
      'https://www.techinasia.com/coding-assessment-platform-provider-banks-65m-indias-times-group',
    imageUrl: require(`${imagesPath}/techAsia.png`),
  },
  {
    name: 'ItsNiceThat',
    url:
      'https://www.itsnicethat.com/articles/indorse-moby-digg-graphic-design-sponsored-content-150519',
    imageUrl: require(`${imagesPath}/ItsNiceThat.png`),
  },
  {
    name: 'YourStory',
    url: 'https://yourstory.com/2019/06/startup-funding-indorse-brandcapital',
    imageUrl: require(`${imagesPath}/YourStory.png`),
  },
  {
    name: 'Softonic',
    url: 'https://en.softonic.com/articles/decentralized-apps',
    imageUrl: require(`${imagesPath}/softonic.png`),
  },
  {
    name: 'E27',
    url: 'https://e27.co/after-a-year-of-rain-indorse-is-ready-to-enjoy-the-sunshine-20190308/',
    imageUrl: require(`${imagesPath}/e27.png`),
  },
  {
    name: 'PeopleMatters',
    url:
      'https://www.peoplematters.in/news/funding-investment/singapore-based-skills-verification-startup-indorse-raises-65-mn-22089',
    imageUrl: require(`${imagesPath}/people-matters.png`),
  },
  {
    name: 'DealStreetAsia',
    url: 'https://www.dealstreetasia.com/stories/brand-capital-indorse-140702/',
    imageUrl: require(`${imagesPath}/Deal-Street-Asia.png`),
  },
  {
    name: 'VcCircle',
    url:
      'https://www.vccircle.com/brand-capital-to-back-blockchain-powered-singapore-startup-indorse',
    imageUrl: require(`${imagesPath}/vcc.png`),
  },
  {
    name: 'Nasdaq',
    url:
      'http://www.nasdaq.com/article/how-blockchain-will-shape-the-future-of-social-networks-cm835390',
    imageUrl: require(`${imagesPath}/nasdaq.png`),
  },
  {
    name: 'Forbes',
    url:
      'https://www.forbes.com/sites/nikolaikuznetsov/2017/08/16/decentralizing-the-world-blockchain-and-the-removal-of-centralized-profit-centers/#3db4d47753f4',
    imageUrl: require(`${imagesPath}/forbes.png`),
  },
  {
    name: 'Inc',
    url:
      'https://www.inc.com/brian-d-evans/blockchain-is-now-aiming-to-disrupt-social-network.html',
    imageUrl: require(`${imagesPath}/inc.png`),
  },
  {
    name: 'TNW',
    url:
      'https://thenextweb.com/contributors/2017/08/17/blockchain-solves-complicated-data-ownership-problem/',
    imageUrl: require(`${imagesPath}/tnw.png`),
  },
  {
    name: 'Investopedia',
    url:
      'https://www.investopedia.com/news/ethereum-blockchain-social-media-privacy-problem-linkedin-indorse/',
    imageUrl: require(`${imagesPath}/investopedia.png`),
  },
  {
    name: 'CNBC',
    url:
      'https://www.cnbc.com/2017/09/06/china-ico-ban-will-help-to-stop-fraud-and-scams-experts-say.html',
    imageUrl: require(`${imagesPath}/cnbc.png`),
  },
  {
    name: 'VB',
    url:
      'https://venturebeat.com/2017/09/09/how-blockchain-will-finally-convert-you-control-over-your-own-data/ ',
    imageUrl: require(`${imagesPath}/vb.png`),
  },
  {
    name: 'Bitcoin.com',
    url:
      'https://news.bitcoin.com/indorse-decentralized-professional-network-ethereum-joins-forces-wings-dao-crowdfund-pricing-engagement/',
    imageUrl: require(`${imagesPath}/bitcoin.png`),
  },
  {
    name: 'Independent',
    url:
      'https://www.independent.co.uk/life-style/gadgets-and-tech/news/facebook-cryptocurrency-launch-bitcoin-blockchain-david-marcus-a8343261.html',
    imageUrl: require(`${imagesPath}/independent.png`),
  },

  {
    name: 'Nulltx',
    url: 'https://nulltx.com/the-2018-year-of-cryptocurrency-challenge-week-21/',
    imageUrl: require(`${imagesPath}/nulltx.png`),
  },
  {
    name: 'EthNews',
    url: 'https://www.ethnews.com/indorse-announces-token-airdrop',
    imageUrl: require(`${imagesPath}/ethNews.png`),
  },
  {
    name: 'BlockchainNews',
    url:
      'http://www.the-blockchain.com/2018/01/10/consilium-ups-stake-indorses-decentralized-social-network/',
    imageUrl: require(`${imagesPath}/blockchainNews.png`),
  },
  {
    name: 'BlockTribune',
    url:
      'http://blocktribune.com/crypto-research-platform-icocrunch-partners-with-indorse-to-ensure-legitimacy-of-icos/',
    imageUrl: require(`${imagesPath}/blockTribune.png`),
  },
  {
    name: 'SignaporeBusiness',
    url:
      'https://sbr.com.sg/information-technology/exclusive/blockchain-powered-social-media-start-indorse-raises-10m-in-recent-',
    imageUrl: require(`${imagesPath}/signaporeBusiness.png`),
  },
  {
    name: 'HostingAdvice',
    imageUrl: require(`${imagesPath}/hosting-advice.png`),
    url:
      'https://www.hostingadvice.com/blog/reduce-the-noise-with-the-ethereum-based-job-network-by-indorse/',
  },
];
