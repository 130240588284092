import Immutable from 'seamless-immutable';
import * as actionTypes from './action-types';
import * as acceptInviteActionTypes from 'store/graphql/VerifyTokenCompanyAdmin/action-types';
import { errorsToSingleMessage } from 'utils/graphql-errors';

const initialState = Immutable({
  myCompanies: [],
  fetching: false,
  error: false,
  params: {},
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_MY_COMPANIES.START: {
      const params = action.payload;
      return state.merge(
        {
          fetching: true,
          error: false,
          params,
        },
        { deep: true }
      );
    }
    case actionTypes.FETCH_MY_COMPANIES.SUCCESS: {
      const { result: { myCompanies } } = action.payload;
      return state.merge({ fetching: false, error: false, myCompanies }, { deep: true });
    }
    case actionTypes.FETCH_MY_COMPANIES.FAILURE: {
      const { errors } = action.payload;
      return state.merge(
        {
          fetching: false,
          error: errorsToSingleMessage({ errors }),
        },
        { deep: true }
      );
    }
    case acceptInviteActionTypes.ACCEPT_INVITE.SUCCESS: {
      const { result: { acceptInviteToCompanyACL: { company } } } = action.payload;
      const newCompany = [...state.myCompanies, company];
      return state.merge({ myCompanies: newCompany }, { deep: true });
    }
    default:
      return state;
  }
}
