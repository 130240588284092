/*
 * AssignmentDetails Messages
 *
 * This contains all the text for the AssignmentDetails component.
 */
import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const header = defineMessages({
  title: {
    id: 'graphql.AssignmentDetails.header.title',
    defaultMessage: `{title}`,
    description: 'some cool story',
  },
});

export const labels = defineMessages({
  [fieldNames.proof]: {
    id: 'graphql.AssignmentDetails.labels.proof',
    defaultMessage: `Assignment Link (Your Bitbucket project repo or personal project repo)`,
  },
});

export const buttons = defineMessages({
  back: {
    id: 'graphql.AssignmentDetails.buttons.back',
    defaultMessage: `Back to My Profile`,
  },
  submit: {
    id: 'graphql.AssignmentDetails.buttons.submit',
    defaultMessage: `Submit Assignment`,
  },
  loginWithGithub: {
    id: 'graphql.AssignmentDetails.buttons.loginWithGithub',
    defaultMessage: `Login with GitHub`,
  },
  browseGithub: {
    id: 'graphql.AssignmentDetails.buttons.browseGithub',
    defaultMessage: `Browse GitHub Repositories`,
  },
  useThisRepo: {
    id: 'graphql.AssignmentDetails.buttons.useThisRepo',
    defaultMessage: 'Use this repository',
  },
});

export const content = defineMessages({
  scope: {
    id: 'graphql.AssignmentDetails.content.scope',
    defaultMessage: `Scope of Assignment`,
  },
  timePeriod: {
    id: 'graphql.AssignmentDetails.content.timePeriod',
    defaultMessage: `Estimated Duration: <b>{duration}</b>`,
  },
  testTitle: {
    id: 'graphql.AssignmentDetails.content.testTitle',
    defaultMessage: `Assignment Details`,
  },
  basicFeature: {
    id: 'graphql.AssignmentDetails.content.basicFeature',
    defaultMessage: `Basic feature of the application should include:`,
  },
  evaluate: {
    id: 'graphql.AssignmentDetails.content.evaluate',
    defaultMessage: `In this assignment, you will be evaluated on:`,
  },
  evaluateItemOne: {
    id: 'graphql.AssignmentDetails.content.evaluateItemOne',
    defaultMessage: `Code Quality`,
  },
  evaluateItemTwo: {
    id: 'graphql.AssignmentDetails.content.evaluateItemTwo',
    defaultMessage: `Depth of Code`,
  },
  evaluateItemThree: {
    id: 'graphql.AssignmentDetails.content.evaluateItemThree',
    defaultMessage: `Authenticity`,
  },
  successMessage: {
    id: 'graphql.AssignmentDetails.content.successMessage',
    defaultMessage: `Great, the assignment has been submitted successfully.`,
  },
  postRepoLink: {
    id: 'graphql.AssignmentDetails.content.postRepoLink',
    defaultMessage:
      'Please create a new GitHub or Bitbucket repo where you will upload the relevant project files. Please properly document your code.',
  },
  selectFrameworks: {
    id: 'graphql.AssignmentDetails.content.message',
    defaultMessage: 'Select the framework that you worked on this assignment',
  },
});

export const githubTitle = defineMessages({
  title: {
    id: 'graphql.AssignmentDetails.githubTitle.title',
    defaultMessage: 'Select your Github Repository',
  },
  miniOne: {
    id: 'graphql.AssignmentDetails.githubTitle.miniOne',
    defaultMessage: 'Select your Github Repository and submit your repository',
  },
  miniTwo: {
    id: 'graphql.AssignmentDetails.githubTitle.miniTwo',
    defaultMessage: 'for validation by our expert community',
  },
});

export const tableHeaders = defineMessages({
  title: {
    id: 'graphql.AssignmentDetails.tableHeaders.title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'graphql.AssignmentDetails.tableHeaders.description',
    defaultMessage: 'Description',
  },
  skills: {
    id: 'graphql.AssignmentDetails.tableHeaders.skills',
    defaultMessage: 'Skills',
  },
  update: {
    id: 'graphql.AssignmentDetails.tableHeaders.update',
    defaultMessage: 'Update',
  },
  stars: {
    id: 'graphql.AssignmentDetails.tableHeaders.stars',
    defaultMessage: 'Stars',
  },
  status: {
    id: 'graphql.AssignmentDetails.tableHeaders.status',
    defaultMessage: 'Status',
  },
});

export const statuses = defineMessages({
  statusLabel: {
    id: 'graphql.AssignmentDetails.statusLabel',
    defaultMessage: 'Assignment Status',
  },
  votesLabel: {
    id: 'graphql.AssignmentDetails.votesLabel',
    defaultMessage: 'Votes Received',
  },
  votingExpiry: {
    id: 'graphql.AssignmentDetails.votingExpiry',
    defaultMessage: 'Voting Expiry',
  },
  startAssignment: {
    id: 'graphql.AssignmentDetails.startAssignment',
    defaultMessage: 'Start assignment',
  },
  pendingValidation: {
    id: 'graphql.AssignmentDetails.pendingValidations',
    defaultMessage: 'Pending validation',
  },
  indorsed: {
    id: 'graphql.AssignmentDetails.indorsed',
    defaultMessage: 'Recommended',
  },
  flagged: {
    id: 'graphql.AssignmentDetails.flagged',
    defaultMessage: 'Not Recommended',
  },
  retake: {
    id: 'graphql.AssignmentDetails.retake',
    defaultMessage: 'Re-take this validation',
  },
  viewFeedback: {
    id: 'graphql.AssignmentDetails.viewFeedback',
    defaultMessage: 'View validators feedback',
  },
  votingEnded: {
    id: 'graphql.AssignmentDetails.votingEnded',
    defaultMessage: 'Voting Period Ended',
  },
  votesReceived: {
    id: 'graphql.AssignmentDetails.votesReceived',
    defaultMessage: 'Votes Received',
  },
});
