/*
  StartAssignmentBtn
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';

import routeGenerators from 'ui/common/routes/generators';
import routeTemplates from 'ui/common/routes/templates';
import Button from 'ui/common/button';
import messages from './messages';
import { startAssignment } from 'store/graphql/StartAssignmentBtn/actions';
import { selectError, selectIsFetching } from 'store/graphql/StartAssignmentBtn/selectors';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import StartAssignmentBtnReducer from 'store/graphql/StartAssignmentBtn/reducer';

const {
  start: startAssignmentStart,
  failure: startAssignmentFailure,
  success: startAssignmentSuccess,
} = startAssignment;

class StartAssignmentBtn extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  async startAssignmentClicked() {
    const {
      client,
      startAssignmentStart,
      startAssignmentSuccess,
      startAssignmentFailure,
      assignmentId,
      enableEndpointCall,
      refreshOnSuccess,
      onStartAssignmentClickCallback,
      userAssessmentId,
      addMessage,
    } = this.props;

    if (!assignmentId) return;

    const assignmentdetailsPath = routeGenerators.assignments.details({ assignmentId });

    if (!enableEndpointCall) {
      if (onStartAssignmentClickCallback) {
        onStartAssignmentClickCallback(assignmentId);
      } else {
        this.props.history.push(assignmentdetailsPath);
      }
    } else {
      startAssignmentStart({ assignmentId });
      try {
        const resultFromGraphQl = await client.mutate({
          mutation: gql`
            mutation startAssignment($id: String!, $userAssessmentId: String) {
              startAssignment(assignmentID: $id, userAssessmentId: $userAssessmentId) {
                _id
                assignment_id
                started_on
                finished_on
                owner_id
                proof
                claim_id
                status
                claim {
                  _id
                  expires_at
                  status
                  votesThreshold
                  completedVoteCount
                }
                skills {
                  skill {
                    name
                  }
                }
              }
            }
          `,
          variables: {
            id: assignmentId,
            userAssessmentId,
          },
        });

        if (resultFromGraphQl.errors) {
          startAssignmentFailure({ errors: resultFromGraphQl.errors, assignmentId });

          // ID-2013 redirect user to login page if `"Must be logged in"`

          let finalMessage = resultFromGraphQl.errors[0].message;
          if (finalMessage.indexOf('Must be logged in') > -1) {
            finalMessage =
              'Redirecting you to login page... You can start assignment after logging in successfully';
            addMessage({
              kind: messageTypes.danger,
              content: finalMessage,
            });
            setTimeout(() => {
              this.props.history.push(routeTemplates.auth.login);
            }, 2000);
          } else {
            addMessage({
              kind: messageTypes.danger,
              content: resultFromGraphQl.errors[0].message,
            });
          }
        } else {
          const { startAssignment } = resultFromGraphQl.data;
          startAssignmentSuccess({
            result: startAssignment,
            assignmentId,
          });

          if (onStartAssignmentClickCallback) {
            onStartAssignmentClickCallback(assignmentId);
          } else {
            refreshOnSuccess
              ? window.location.reload()
              : this.props.history.push(assignmentdetailsPath);
          }
        }
      } catch (error) {
        startAssignmentFailure({ errors: error.messages, assignmentId });
      }
    }
  }

  render() {
    const {
      assignmentId,
      enableEndpointCall,
      isInProgress,
      label,
      isReady,
      intl: { formatMessage },
      ...passThrough
    } = this.props;

    return (
      <Button
        {...passThrough}
        isReady={isReady || false}
        loading={isInProgress}
        onClick={this.startAssignmentClicked}
        disabled={!assignmentId}
      >
        {label ||
          (enableEndpointCall
            ? formatMessage(messages.startAssignmentBtn)
            : formatMessage(messages.submitAssignmentBtn))}
      </Button>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { assignmentId, onStartAssignmentClick: onStartAssignmentClickCallback } = ownProps;
  return {
    isInProgress: selectIsFetching(state, { assignmentId }),
    errorMessage: selectError(state, { assignmentId }),
    onStartAssignmentClickCallback,
  };
}

const mapDispatchToProps = {
  startAssignmentStart,
  startAssignmentSuccess,
  startAssignmentFailure,
  addMessage,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'StartAssignmentBtn',
  reducer: StartAssignmentBtnReducer,
});

export default withRouter(
  compose(withReducer, withConnect)(injectIntl(withApollo(StartAssignmentBtn)))
);
