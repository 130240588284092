export const tableRowData = {
  'Free Trial': ['14 Days', '14 Days', '14 Days'],
  'Number of Admin Seats': [
    { count: '5', contactSales: true },
    { count: '10', contactSales: true },
    { count: 'Unlimited', contactSales: false },
  ],
  'Number of Engineers': [
    { count: 'Upto 60', contactSales: true },
    { count: 'Upto 200', contactSales: true },
    { count: 'Unlimited', contactSales: false },
  ],
  'Engagement Metrics': ['Yes', 'Yes', 'Yes'],
  'Slack bot': ['Yes', 'Yes', 'Yes'],
  'Microsoft Teams bot': ['Coming Soon', 'Coming Soon', 'Coming Soon'],
  'Weekly Analytics Report': ['No', 'Yes', 'Customizable Reports'],
  SLA: ['Basic', 'Priority', 'Dedicated Account Manager'],
};

export const featuresData = {
  growth: [
    '14 Days Free Trial',
    '5 Admin Seats',
    'Up to 60 Engineers',
    'Engagement Metrics',
    'Slack bot',
    // 'Microsoft Teams bot (coming soon)',
    'Basic SLA',
  ],
  premium: [
    '14 Days Free Trial',
    '10 Admin Seats',
    'Up to 200 Engineers',
    'Engagement Metrics',
    'Slack bot',
    // 'Microsoft Teams bot (coming soon)',
    'Weekly Analytics Report',
    'Priority SLA',
  ],
  enterprise: [
    '14 Days Free Trial',
    'Unlimited Admin Seats',
    'Unlimited Engineers',
    'Engagement Metrics',
    'Slack bot',
    'Microsoft Teams bot (coming soon)',
    'Customizable Reports',
    'Dedicated Account Manager',
  ],
};
