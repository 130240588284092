import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/professions';
import callApi from 'store/api/saga';

import * as flashActions from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
// import * as messages from 'ui/signup-wizard/professions/messages';

import * as wizardActions from 'store/features/wizard/actions';

function* setUserProfessions({ payload, meta }) {
  const { values, userId } = payload;
  const { form, wizard } = meta;

  yield put(actions.setUserProfessions.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.setUserProfessions(values, userId));

    yield put(actions.setUserProfessions.success(response));
    if (!wizard) {
      yield put(
        flashActions.addMessage({
          id: `profession.messages.success`,
          kind: messageTypes.success,
          content: 'Success',
          // content: messages.responses.professionSuccess,
          dismissable: true,
        })
      );
    }

    if (form) yield put(stopSubmit(form));
    if (wizard) {
      const { name, apiConfig } = wizard;
      yield put(wizardActions.completeStep.request({ name, apiConfig }));
    }
  } catch (error) {
    yield put(actions.setUserProfessions.failure(error));

    yield put(
      flashActions.addMessage({
        id: `interest.messages.error`,
        kind: messageTypes.danger,
        content: error.message,
      })
    );
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchSetUserProfessions() {
  yield takeEvery(actionTypes.SET_USER_PROFESSIONS.REQUEST, setUserProfessions);
}

export default function* votes() {
  yield fork(watchSetUserProfessions);
}
