import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { connectModal as reduxModal, hide as hideModal } from 'redux-modal';
import { Modal, ModalHeader } from 'ui/common/modal';
import Button from 'ui/common/button';
import ExitIntentForm from './form';
import styles from './index.module.scss';
import INTENT_VISUALS from './intent-visual.png';
export const MODAL_NAME = 'exit-intent-modal';

class ExitIntentModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  setExitIntent() {
    localStorage.setItem('exitIntent', 'true');
  }

  render() {
    const { show, handleHide } = this.props;

    return (
      <Modal size="lg" style={{ minWidth: '1000px' }} isOpen={show}>
        <div>
          <div className={'row p-0 m-0'}>
            <div className={classnames('col-4 py-5 m-0 px-0', styles.highlight)}>
              <div className={classnames(styles.header, 'mt-2 pt-1 px-4')}>
                <h1 className="lead">Talk to a product specialist</h1>
                <Button
                  className={classnames(styles.btn, 'primary')}
                  isReady={false}
                  onClick={() => {
                    window.fcWidget &&
                      window.fcWidget.open({
                        replyText:
                          'Hey there, I would like to request a Demo and discover more about Metamorph!',
                      });
                  }}
                >
                  Get in Touch
                </Button>
              </div>
              <div className={'mt-4'}>
                <img src={INTENT_VISUALS} alt={'intent-visual'} width={'100%'} />
              </div>
            </div>
            <div className={'col-8 p-0 m-0'}>
              <ModalHeader
                toggle={props => {
                  this.setExitIntent();
                  handleHide(props);
                }}
              />
              <ExitIntentForm />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(injectIntl(ExitIntentModal))
);
