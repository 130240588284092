import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, FormattedHTMLMessage } from 'react-intl';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';

import Button from 'ui/common/button';
import { confirm, cancel } from 'store/features/common/confirmation-dialog/actions';
import { selectIsOpen, selectMessage } from 'store/features/common/confirmation-dialog/selectors';

const messages = defineMessages({
  title: {
    id: 'confirmation-dialog.title',
    defaultMessage: 'Confirm',
  },
  confirm: {
    id: 'confirmation-dialog.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'confirmation-dialog.cancel',
    defaultMessage: 'Cancel',
  },
});

class ConfirmationDialog extends Component {
  renderMessage(message) {
    if (
      typeof message === 'object' &&
      message.hasOwnProperty('id') &&
      message.hasOwnProperty('defaultMessage')
    )
      return (
        <p className="text-center">
          <FormattedHTMLMessage {...message} />
        </p>
      );

    return <p className="text-center">{message}</p>;
  }

  render() {
    const { isOpen, message, intl: { formatMessage }, confirm, cancel } = this.props;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={cancel} />
        <ModalBody>{this.renderMessage(message)}</ModalBody>
        <ModalFooter>
          <Button isReady={false} onClick={confirm}>
            {formatMessage(messages.confirm)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    isOpen: selectIsOpen(state),
    message: selectMessage(state),
  };
}

const mapDispatchToProps = {
  confirm,
  cancel,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmationDialog));
