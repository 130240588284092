import { defineMessages } from 'react-intl';

export default defineMessages({
  entryAdded: {
    id: 'education.entries.added',
    defaultMessage: 'Added education entry successfully',
  },
  entryUpdated: {
    id: 'education.entries.updated',
    defaultMessage: 'Updated education entry successfully',
  },
  entryDeleted: {
    id: 'education.entries.deleted',
    defaultMessage: 'Deleted education entry successfully',
  },
});
