import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const loadFacebookSdkLib = {
  request: () => defineAction(types.LOAD_FACEBOOK_SDK_LIB.REQUEST),
  start: () => defineAction(types.LOAD_FACEBOOK_SDK_LIB.START),
  success: () => defineAction(types.LOAD_FACEBOOK_SDK_LIB.SUCCESS),
  failure: error => defineAction(types.LOAD_FACEBOOK_SDK_LIB.FAILURE, error),
};

export const loadGoogleApiLib = {
  start: () => defineAction(types.LOAD_GOOGLE_API_LIB.START),
  success: () => defineAction(types.LOAD_GOOGLE_API_LIB.SUCCESS),
  failure: error => defineAction(types.LOAD_GOOGLE_API_LIB.FAILURE, error),
};

export const loadGoogleAuthLib = {
  request: () => defineAction(types.LOAD_GOOGLE_AUTH_LIB.REQUEST),
  start: () => defineAction(types.LOAD_GOOGLE_AUTH_LIB.START),
  success: () => defineAction(types.LOAD_GOOGLE_AUTH_LIB.SUCCESS),
  failure: error => defineAction(types.LOAD_GOOGLE_AUTH_LIB.FAILURE, error),
};

export const loadReCaptchaSdkLib = {
  start: () => defineAction(types.LOAD_RECAPTCHA_SDK_LIB.START),
  success: () => defineAction(types.LOAD_RECAPTCHA_SDK_LIB.SUCCESS),
  failure: error => defineAction(types.LOAD_RECAPTCHA_SDK_LIB.FAILURE, error),
};

export const loadWeb3Lib = {
  request: () => defineAction(types.LOAD_WEB3_LIB.REQUEST),
  start: () => defineAction(types.LOAD_WEB3_LIB.START),
  success: () => defineAction(types.LOAD_WEB3_LIB.SUCCESS),
  failure: error => defineAction(types.LOAD_WEB3_LIB.FAILURE, error),
};

export const loadStripeCheckoutLib = {
  start: () => defineAction(types.LOAD_STRIPE_CHECKOUT_LIB.START),
  success: () => defineAction(types.LOAD_STRIPE_CHECKOUT_LIB.SUCCESS),
  failure: error => defineAction(types.LOAD_STRIPE_CHECKOUT_LIB.FAILURE, error),
};
