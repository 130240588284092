import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectCompanyByPrettyId } from 'store/features/companies/selectors';
import {
  selectIsLoggedIn,
  selectCurrentUserFetchedAtleastOnce,
  selectHasCurrentUserAcceptedCurrentTermsAndPrivacy,
  selectLatestAcceptedTcVersionByCurrentUser,
  selectLatestAcceptedPrivacyVersionByCurrentUser,
} from 'store/features/auth/selectors';
import GeneralConsent from 'ui/auth/consent/general-consent';

import Consent from 'ui/auth/consent';
import EmsConsent from 'ui/auth/consent/ems-consent';
import { selectMyCompanies } from 'store/graphql/AdminFetchMyCompanies/selectors';

function mapStateToProps(state, props) {
  const routeParams = props && props.match && props.match.params;

  const prettyId = routeParams && routeParams.prettyId;
  const myCompanies = selectMyCompanies(state);
  const latestAcceptedTcVersionByCurrentUser = selectLatestAcceptedTcVersionByCurrentUser(state);
  const latestAcceptedPrivacyVersionByCurrentUser = selectLatestAcceptedPrivacyVersionByCurrentUser(
    state
  );
  const isLatestTc =
    latestAcceptedTcVersionByCurrentUser === 3 || !latestAcceptedTcVersionByCurrentUser;
  const isLatestPrivacy =
    latestAcceptedPrivacyVersionByCurrentUser === 3 || !latestAcceptedPrivacyVersionByCurrentUser;
  const currentCompany = myCompanies.find(comp => comp.prettyId === prettyId);
  const company = selectCompanyByPrettyId(state, { prettyId });
  const isEmsCompany =
    currentCompany && currentCompany.features
      ? currentCompany.features.ems
      : company && company.features && company.features.ems;
  // const isEmsCompany = ;
  return {
    loggedIn: selectIsLoggedIn(state),
    currentUserFetchedAtleastOnce: selectCurrentUserFetchedAtleastOnce(state),
    currentTcAndPrivacyAcceptedByCurrentUser: selectHasCurrentUserAcceptedCurrentTermsAndPrivacy(
      state
    ),
    isEmsCompany,
    company,
    isNewUser: isLatestTc && isLatestPrivacy,
  };
}

const AuthenticatedConsentCheck = WrappedComponent => {
  class Wrapper extends React.Component {
    render() {
      const {
        loggedIn,
        currentUserFetchedAtleastOnce,
        currentUserApproved,
        location,
        currentTcAndPrivacyAcceptedByCurrentUser,
        isEmsCompany,
        isNewUser,
        company,
        ...passThrough
      } = this.props;
      if (loggedIn && currentUserFetchedAtleastOnce && !currentTcAndPrivacyAcceptedByCurrentUser) {
        if ((isEmsCompany || !company) && isNewUser) {
          return <EmsConsent />;
        } else if (isNewUser) {
          return <Consent />;
        } else {
          return <GeneralConsent />;
        }
      }

      return <WrappedComponent {...passThrough} />;
    }
  }

  Wrapper.displayName = 'AuthenticatedConsentCheck';

  return withRouter(connect(mapStateToProps)(Wrapper));
};

export default AuthenticatedConsentCheck;
