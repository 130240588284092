import { createSelector } from 'reselect';

export const selectLinkGithubButtonState = state => state.LinkGithubButton;

export const selectError = createSelector(
  selectLinkGithubButtonState,
  (state, props) => state.error
);

export const selectLinkingWithGithub = createSelector(
  selectLinkGithubButtonState,
  linkGithubButtonState => linkGithubButtonState && linkGithubButtonState.linkingWithGithub
);

export const selectLinkedWithGithub = createSelector(
  selectLinkGithubButtonState,
  linkGithubButtonState => linkGithubButtonState && linkGithubButtonState.linkedWithGithub
);
