import React, { Component } from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Authenticated from 'ui/common/auth/authenticated';
import { selectIsSignedUp, selectIsCurrentUserApproved } from 'store/features/auth/selectors';
import routeTemplates from 'ui/common/routes/templates';
import Layout from 'ui/auth/layout';
import Alert from 'ui/common/alert';

const messages = defineMessages({
  title: {
    id: 'auth.approval-required.title',
    defaultMessage: 'Approval required',
  },
});

class ApprovalRequired extends Component {
  render() {
    const { signedUp, currentUserApproved, intl: { formatMessage } } = this.props;

    if (currentUserApproved) {
      return <Redirect to={routeTemplates.root} />;
    }

    return (
      <Layout title={formatMessage(messages.title)} showFooter={false}>
        {signedUp && (
          <Alert color="success" inverse>
            <FormattedMessage
              id="auth.approval-required.signed-up"
              defaultMessage="Thank you for signing up"
            />
          </Alert>
        )}
        <Alert color="warning">
          <FormattedMessage
            id="auth.approval-required.instructions"
            defaultMessage="Your account has not been approved by your administrator yet. You will receive an email when admin approves you account"
          />
        </Alert>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    signedUp: selectIsSignedUp(state),
    currentUserApproved: selectIsCurrentUserApproved(state),
  };
}

export default Authenticated(connect(mapStateToProps)(injectIntl(ApprovalRequired)), {
  approvalRequired: false,
});
