import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';

import styles from './index.module.scss';
import Button from 'ui/common/button';
import { googleAuth } from 'store/features/auth/actions';
import { loadGoogleAuthLib } from 'store/libs/actions';
import {
  selectIsGoogleAuthAvailable,
  selectIsAuthenticatingWithGoogle,
  selectIsAuthenticatingWithExternalAccount,
} from 'store/features/auth/selectors';
import { logEvent } from 'services/amplitude-helper';

const { request: googleAuthRequest } = googleAuth;

class LoginWithGoogleButton extends Component {
  static propTypes = {
    existingValues: PropTypes.object,
    from: PropTypes.string,
  };

  constructor(props) {
    super(props);

    autoBind(this);
  }

  componentDidMount() {
    this.props.loadGoogleAuthLib();
  }

  onClick() {
    const { googleAuthRequest, existingValues, from } = this.props;
    logEvent('LP_getstartedmodal_google_clicked', { source: from });
    localStorage.setItem('ems_social_login_with', 'google');
    googleAuthRequest({ existingValues, from });
  }

  render() {
    const {
      googleAuthAvailable,
      authenticatingWithExternalAccount,
      // authenticatingWithGoogle,
    } = this.props;

    return (
      <Button
        className={classnames(styles.googleBtn, styles.socialBtn, 'px-4 position-relative')}
        isReady={false}
        onClick={this.onClick}
        disabled={!googleAuthAvailable || authenticatingWithExternalAccount}
      >
        <i
          className="fab fa-google mr-2"
          style={{ fontSize: '20px', position: 'absolute', left: '25px' }}
        />
        <span style={{ marginLeft: '37px' }}>
          <FormattedMessage
            id="metamorph.auth.google-button"
            defaultMessage="Sign up with Google"
          />
        </span>
      </Button>
    );
  }
}

function mapStateToProps(state) {
  return {
    googleAuthAvailable: selectIsGoogleAuthAvailable(state),
    authenticatingWithExternalAccount: selectIsAuthenticatingWithExternalAccount(state),
    authenticatingWithGoogle: selectIsAuthenticatingWithGoogle(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    googleAuthRequest: bindActionCreators(googleAuthRequest, dispatch),
    loadGoogleAuthLib: bindActionCreators(loadGoogleAuthLib.request, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginWithGoogleButton));
