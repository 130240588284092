import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from 'ui/common/loading';

import * as storage from 'store/features/auth/storage';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import EmsApps from 'store/graphql/EmsApps/reducer';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { errorsToSingleMessage, resultHasErrors } from '../../../utils/graphql-errors';
import { messageTypes } from '../../../store/features/common/flash/builder';
import { addMessage } from '../../../store/features/common/flash/actions';
import { show as showModal } from 'redux-modal';
import { push } from 'react-router-redux';
import routeGenerators from '../../common/routes/generators';
export const BITBUCKET_INTEGRATION_COMPANY_OBJ_KEY = 'BITBUCKET_INTEGRATION_COMPANY_OBJ';
const SETUP = 'setup';

const BitBucketAppIntegration = props => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const fetchCurrentPlan = async () => {
    setLoading(true);
    try {
      const { client, addMessage, bbCode, company, push, from } = props;

      const response = await client.mutate({
        mutation: gql`
          mutation bitbucketApp_postInstallation($code: String!, $companyId: String!) {
            bitbucketApp_postInstallation(code: $code, companyId: $companyId) {
              companyPrettyId
            }
          }
        `,
        variables: {
          code: bbCode,
          companyId: company && company.id,
        },
      });

      const { data, errors } = response;
      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        setError(true);
      } else {
        const { bitbucketApp_postInstallation } = data;
        storage.deleteDataInLocalStorage(BITBUCKET_INTEGRATION_COMPANY_OBJ_KEY);
        // console.log('githubApp_postInstallation : ', githubApp_postInstallation);
        if (from === SETUP) {
          push(
            routeGenerators.ems.gitAppInstallSuccessSetup({
              prettyId: bitbucketApp_postInstallation.companyPrettyId,
              companyId: company && company.id,
            })
          );
        } else {
          push(
            routeGenerators.companies.edit({
              prettyId: bitbucketApp_postInstallation.companyPrettyId,
              section: 'analytics',
            })
          );
        }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCurrentPlan();
  }, []);

  if (isError) return <h1 className={'mt-5 text-center'}>Error</h1>;

  return isLoading && <Loading />;
};

function mapStateToProps(state, ownProps) {
  const query = new URLSearchParams(ownProps.location.search);
  const bbCode = query.get('code');
  let gitAppData = storage.getDataInLocalStorage(BITBUCKET_INTEGRATION_COMPANY_OBJ_KEY);
  gitAppData = JSON.parse(gitAppData);
  const { company, from } = gitAppData;
  return {
    bbCode,
    company,
    from,
  };
}

const mapDispatchToProps = {
  addMessage,
  showModal,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'EmsApps',
  reducer: EmsApps,
});

export default compose(withReducer, withConnect)(withApollo(BitBucketAppIntegration));

// http://localhost:4000/ems/github-app-callback?code=d3ca17496d490e3ae05f&installation_id=10872288&setup_action=install
