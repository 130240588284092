import { findNetworkNameByWeb3NetworkId } from 'resources/ethereum/networks';

import { findProviderNameByWeb3ProviderInstance } from 'resources/ethereum/providers';

// Thin wrapper around Web3
// Will be used in future to handle multiple injected Web3 versions
// Currently only supports versions ~ 0.2
export default class Web3Facade {
  constructor(web3) {
    this.web3 = web3;
  }

  detectNetworkAsync() {
    return new Promise((resolve, reject) => {
      this.web3.version.getNetwork((err, networkId) => {
        if (err) reject(err);
        else resolve(findNetworkNameByWeb3NetworkId(networkId));
      });
    });
  }

  detectProvider() {
    return findProviderNameByWeb3ProviderInstance(this.web3.currentProvider);
  }

  getAccountsAsync() {
    return new Promise((resolve, reject) => {
      this.web3.eth.getAccounts((err, accounts) => {
        if (err) reject(err);
        else resolve(accounts);
      });
    });
  }

  hasPrivacyMode() {
    return (
      this.web3 &&
      this.web3.currentProvider &&
      typeof this.web3.currentProvider.enable !== 'undefined'
    );
  }

  unlockAsync() {
    return new Promise((resolve, reject) => {
      if (this.hasPrivacyMode()) {
        this.web3.currentProvider
          .enable()
          .then(resolve)
          .catch(reject);
      } else {
        resolve();
      }
    });
  }

  signPersonalMessageAsync(message, ethereumAddress) {
    let preparedMessage = new Buffer(message).toString('hex');
    preparedMessage = `0x${preparedMessage}`;

    return new Promise((resolve, reject) => {
      this.web3.personal.sign(preparedMessage, ethereumAddress, (err, signature) => {
        if (err) reject(err);
        else resolve(signature);
      });
    });
  }

  getContract(abi, address) {
    const contractDef = this.web3.eth.contract(abi);
    const contract = contractDef.at(address);
    return contract;
  }

  prepareContractTransactionData(abi, address, functionName, ...functionParams) {
    const contract = this.getContract(abi, address);
    const func = contract[functionName];
    if (func) return func.getData(...functionParams);
  }

  sendContractTransactionAsync(contractAddress, fromAddress, preparedData, options = {}) {
    return new Promise((resolve, reject) => {
      this.web3.eth.sendTransaction(
        {
          to: contractAddress,
          from: fromAddress,
          data: preparedData,
          ...options,
        },
        (err, txHash) => {
          if (err) {
            reject(err);
          } else {
            resolve(txHash);
          }
        }
      );
    });
  }

  toHex(message) {
    return this.web3.toHex(message);
  }

  fromWei(value, unit) {
    return this.web3.fromWei(value, unit);
  }

  toWei(value, unit) {
    return this.web3.toWei(value, unit);
  }

  getGasPriceAsync(unit) {
    return new Promise((resolve, reject) => {
      this.web3.eth.getGasPrice((err, gasPrice) => {
        if (err) reject(err);
        else resolve(this.fromWei(gasPrice, unit));
      });
    });
  }

  callContractAsync(contractABI, contractAddress, functionName, functionParams) {
    return new Promise((resolve, reject) => {
      const contract = this.getContract(contractABI, contractAddress);
      contract[functionName].call(functionParams, (err, data) => {
        if (err) reject(err);
        else resolve(data);
      });
    });
  }

  signTypedDataV3(ethAddress, data) {
    return new Promise((resolve, reject) => {
      this.web3.currentProvider.sendAsync(
        {
          method: 'eth_signTypedData_v3',
          params: [ethAddress, data],
          from: ethAddress,
        },
        (err, result) => {
          if (err) {
            reject(err);
            return;
          }

          if (result.error) {
            reject(result.error);
            return;
          }

          const signature = result.result.substring(2);
          const r = '0x' + signature.substring(0, 64);
          const s = '0x' + signature.substring(64, 128);
          const v = parseInt(signature.substring(128, 130), 16);
          // The signature is now comprised of r, s, and v.
          resolve({
            r,
            s,
            v,
          });
        }
      );
    });
  }
}
