import { educationEntry as schema } from 'store/common/schemas';
import { createEntitiesReducer, createEntitiesSelector } from './helpers';

import { DELETE_EDUCATION_ENTRY } from 'store/features/education/action-types';

export const reducer = createEntitiesReducer(schema, (state, action) => {
  switch (action.type) {
    case DELETE_EDUCATION_ENTRY.SUCCESS:
      return state.without(action.payload.id);
    default:
      return state;
  }
});

export const selector = createEntitiesSelector(schema);
