import { generateSortParameterURLValue } from 'utils/sort';
import { decamelize } from 'humps';

export function fetchAllBadges() {
  return {
    endpoint: `badges`,
    method: 'GET',
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function fetchBadges({
  filters = {},
  pageNo,
  perPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  sort,
}) {
  const sortParam = generateSortParameterURLValue(sort);
  const qs = new URLSearchParams();
  if (filters)
    Object.keys(filters).forEach(filterName => {
      const filterValue = filters[filterName];
      if (typeof filterValue !== undefined && filterValue !== null && filterValue !== [])
        qs.set(filterName, filterValue);
    });
  if (sortParam && sortParam.length > 0) qs.set('sort', decamelize(sortParam));
  qs.set('pageNumber', pageNo || 1);
  qs.set('pageSize', perPage);

  return {
    endpoint: `badges?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function deleteBadge({ prettyId }) {
  return {
    endpoint: `badges/${prettyId}`,
    method: 'DELETE',
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function createBadge({
  badgeName,
  prettyId,
  description,
  logoData,
  ctaLink,
  ctaText,
  impressAiChatbotUuid,
}) {
  return {
    endpoint: 'badges',
    method: 'POST',
    data: {
      badgeName,
      prettyId,
      description,
      logoData,
      ctaLink,
      ctaText,
      chatbotUuid: impressAiChatbotUuid,
    },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function updateBadge({
  prettyId: currentPrettyId,
  values: { badgeName, description, logoData, ctaLink, ctaText, impressAiChatbotUuid },
}) {
  return {
    endpoint: `badges/${currentPrettyId}`,
    method: 'PATCH',
    data: {
      badgeName,
      description,
      logoData,
      ctaLink,
      ctaText,
      chatbotUuid: impressAiChatbotUuid,
    },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function fetchBadgeByPrettyId({ prettyId }) {
  return {
    endpoint: `badges/${prettyId}`,
    method: 'GET',
    requireAuth: false,
  };
}

export function autoSuggestBadges(values) {
  const fields = ['id', 'name'].join(',');

  const qs = new URLSearchParams();
  qs.set('search', values);
  qs.set('fields', fields);

  return {
    endpoint: `badges/?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function assignBadges({ badges, emails, assign = 1 }) {
  return {
    endpoint: 'badges/massassign',
    method: 'POST',
    data: {
      badges,
      emails,
      assign,
    },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}
