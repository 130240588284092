import { createSelector } from 'reselect';

export const selectGithubAuthButtonState = state => state.GithubAuthButton;

export const selectError = createSelector(
  selectGithubAuthButtonState,
  (state, props) => state.error
);

export const selectFetchingStart = createSelector(
  selectGithubAuthButtonState,
  state => state && state.fetchingStart
);

export const selectLoginWithGithub = createSelector(
  selectGithubAuthButtonState,
  state => state && state.loginWithGithub
);
