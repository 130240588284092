import * as actionTypes from './action-types';
import { defineAction } from 'store/common/action-helpers';

export const trackGetBadgeClick = (badge, member) =>
  defineAction(actionTypes.TRACK_GET_BADGE_CLICK, { badge, member });

export const fetchBadgeByPrettyId = {
  request: ({ prettyId }) =>
    defineAction(actionTypes.FETCH_BADGE_BY_PRETTY_ID.REQUEST, { prettyId }),
  start: ({ prettyId }) => defineAction(actionTypes.FETCH_BADGE_BY_PRETTY_ID.START, { prettyId }),
  success: ({ prettyId }) =>
    defineAction(actionTypes.FETCH_BADGE_BY_PRETTY_ID.SUCCESS, { prettyId }),
  failure: ({ prettyId, error }) =>
    defineAction(actionTypes.FETCH_BADGE_BY_PRETTY_ID.FAILURE, { prettyId, error }),
};

export const fetchAllBadges = {
  request: () => defineAction(actionTypes.FETCH_ALL_BADGES.REQUEST, {}),
  start: () => defineAction(actionTypes.FETCH_BADGE_BY_PRETTY_ID.START, {}),
  success: () => defineAction(actionTypes.FETCH_BADGE_BY_PRETTY_ID.SUCCESS, {}),
  failure: ({ error }) => defineAction(actionTypes.FETCH_BADGE_BY_PRETTY_ID.FAILURE, { error }),
};
