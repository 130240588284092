/*
  HackathonClaimSubmission
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { Helmet } from 'react-helmet';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { compose, bindActionCreators } from 'redux';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import { withRouter } from 'react-router';
import Alert from 'ui/common/alert';
import { errorsToSingleMessage } from 'utils/graphql-errors';
import { show as showModal } from 'redux-modal';
import TextField from 'ui/common/form/fields/text-field';
import validator from 'ui/common/form/validator';
import SubmitButton from 'ui/common/form/submit-button';
import fields, { fieldNames } from './model';
import styles from './index.module.scss';
import messages from './messages';
import * as actionTypes from 'store/graphql/HackathonClaimSubmission/action-types';
import {
  selectIsFetching,
  selectIsUpdating,
  selectError,
} from 'store/graphql/HackathonClaimSubmission/selectors';
import HackathonClaimSubmissionReducer from 'store/graphql/HackathonClaimSubmission/reducer';
import injectReducer from 'utils/injectReducer';
import Button from 'ui/common/button';
import Icon from 'ui/common/icon';
import HackathonClaimSubmissionSuccessModal, {
  MODAL_NAME as SUCCESS_MODAL,
} from 'ui/graphql/HackathonClaimSubmission/success-modal';

const validate = validator(fields);
const formName = 'hackathon-claim-submission';

class HackathonClaimSubmission extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  async onSubmit(values) {
    const {
      client,
      submitClaimStart,
      submitClaimSuccess,
      submitClaimError,
      event,
      addMessage,
      showModal,
    } = this.props;

    const submission = {
      first_name: values.participants[0] && values.participants[0].name.split(' ')[0],
      last_name: (values.participants[0] && values.participants[0].name.split(' ')[1]) || '',
      email: values.participants[0] && values.participants[0].email,
      repoURL: values.url,
      description: 'submission',
      team:
        values.participants && values.participants.length > 1
          ? values.participants.splice(1, values.participants.length - 1)
          : [],
    };

    const recaptchaToken = await window.grecaptcha.execute(
      process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
      { action: 'hackathonclaimsubmissionform' }
    );

    submitClaimStart();

    try {
      const resultFromGraphQl = await client.mutate({
        mutation: gql`
          mutation createHackathonSubmission(
            $hackathonPublicId: String!
            $submission: HackathonSubmission!
          ) {
            createHackathonSubmission(
              hackathonPublicId: $hackathonPublicId
              submission: $submission
            ) {
              createdAt
            }
          }
        `,
        variables: {
          hackathonPublicId: event,
          submission,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });

      if (resultFromGraphQl.error) {
        submitClaimError(resultFromGraphQl.error);
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors: resultFromGraphQl.error }),
        });
      } else {
        submitClaimSuccess();
        showModal(SUCCESS_MODAL);
        this.props.reset();
      }
    } catch (error) {
      submitClaimError(error.message);
      addMessage({
        kind: messageTypes.danger,
        content: errorsToSingleMessage({ error }),
      });
    }
  }

  renderParticipantDetails({ fields, meta }) {
    const { intl: { formatMessage } } = this.props;
    if (fields.length === 0) fields.push();
    return (
      <div className="pb-5 pt-4">
        {fields.map((participant, index) => {
          return (
            <div key={index} className="row pb-3">
              <div className="mt-2 col-5">
                <Field
                  name={`${participant}.name`}
                  component={TextField}
                  label={`Participant ${index + 1} Name`}
                />
              </div>
              <div className="mt-2 col-5">
                <Field
                  name={`${participant}.email`}
                  component={TextField}
                  label={`Participant ${index + 1} Email`}
                />
              </div>

              <div className="col-2 align-bottom pt-4 text-right">
                {fields.length > 1 && (
                  <Button
                    color="info"
                    icon="fa fa-trash"
                    isReady={false}
                    onClick={() => fields.remove(index)}
                  />
                )}
              </div>
            </div>
          );
        })}
        <div className="list-inline">
          <div className="list-inline-item">
            <Icon className="m-0" name="fa fa-plus" background onClick={() => fields.push()} />
          </div>
          <p className="list-inline-item">{formatMessage(messages.addParticipant)}</p>
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, submitting, errorMessage, intl: { formatMessage } } = this.props;

    return (
      <div className={classnames('container py-5')}>
        <Helmet>
          <title>{formatMessage(messages.title)}</title>
        </Helmet>
        <h2 className="text-center mt-4">{formatMessage(messages.pageTitle)}</h2>
        <h6 className="text-muted text-center">{formatMessage(messages.subPageTitle)}</h6>
        <div className={classnames('row justify-content-center align-items-center')}>
          <div className="col-12 col-sm-6 pt-5">
            <div className="col-sm-12 border border-secondary rounded p-3">
              <form onSubmit={handleSubmit(this.onSubmit)} className="m-md-4">
                <div className="pb-4 text-center">
                  <p className={classnames(styles.submitCode)}>
                    {formatMessage(messages.submitCode)}
                  </p>
                </div>
                {errorMessage && (
                  <Alert color="danger" inverse>
                    {errorMessage}
                  </Alert>
                )}
                <div>
                  <Field
                    name={fieldNames.url}
                    component={TextField}
                    label={formatMessage(messages[fieldNames.url])}
                  />
                </div>

                <div>
                  <FieldArray
                    name={fieldNames.participants}
                    component={this.renderParticipantDetails}
                  />
                </div>
                <div className="mt-3 text-center">
                  <SubmitButton fullWidth formName={formName} submitting={submitting}>
                    {formatMessage(messages.submitButton)}
                  </SubmitButton>
                </div>
                <HackathonClaimSubmissionSuccessModal />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { match: { params: { event } } } = props;
  return {
    fetching: selectIsFetching(state),
    updating: selectIsUpdating(state),
    errorMessage: selectError(state),
    event,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitClaimStart: () => dispatch({ type: actionTypes.SUBMIT_CLAIM.START }),
    submitClaimSuccess: () => dispatch({ type: actionTypes.SUBMIT_CLAIM.SUCCESS }),
    submitClaimError: error =>
      dispatch({ type: actionTypes.SUBMIT_CLAIM.FAILURE, payload: { error } }),
    addMessage: bindActionCreators(addMessage, dispatch),
    showModal: bindActionCreators(showModal, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'HackathonClaimSubmission',
  reducer: HackathonClaimSubmissionReducer,
});

export default withRouter(
  compose(withReducer, withConnect)(
    reduxForm({
      form: formName,
      validate,
    })(injectIntl(withApollo(HackathonClaimSubmission)))
  )
);
