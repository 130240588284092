import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import autoBind from 'react-autobind';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';

import styles from './index.module.scss';
import themeConfig from 'ui/theme/config';

class EmsPricingDashboard extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderDesktop() {
    return (
      <div className={classnames(styles.hero)}>
        <div>
          <div className="row d-flex page-max-width mt-5 align-items-stretch justify-content-between">
            <div className="col-7 mt-3 negative-z-index">
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/section4_image.png'}
                alt="report"
                width="560px"
                height="410px"
              />
            </div>

            <div className="col-5">
              <div className="row mt-5 pt-3">
                <h1 className={classnames(styles.title)}>Leadership is about people</h1>
              </div>
              <div className="row">
                <div className={classnames(styles.subtitle)}>
                  Designed for your software engineering team, Metamorph has one core objective:
                  provide you with greater visibility into your team to increase employee
                  engagement, performance and productivity.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        <div className={classnames('container', styles.topSection)}>
          <div className="row mt-3">
            <div className={classnames('col-12', styles.titleLeftMargin)}>
              <div className="row">
                <h1 className={classnames(styles.title)}>Leadership is about people</h1>
              </div>

              <div className={classnames(styles.subtitle)}>
                Designed for your software engineering team, Metamorph has one core objective:
                provide you with greater visibility into your team to increase employee engagement,
                performance and productivity.
              </div>
            </div>
            <div className={classnames('col-12 mt-2')}>
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/section4_image.png'}
                alt="report"
                width="100%"
                height="280px"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
          {this.renderDesktop()}
        </MediaQuery>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
          {this.renderMobile()}
        </MediaQuery>
      </div>
    );
  }
}

export default withRouter(injectIntl(EmsPricingDashboard));
