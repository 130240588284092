import { defineMessages } from 'react-intl';

export const headers = defineMessages({
  media: {
    id: 'home.labels.media',
    defaultMessage: 'As seen on:',
  },
  partners: {
    id: 'home.labels.partners',
    defaultMessage: 'Partners',
  },
});
