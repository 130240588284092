import { defineMessages } from 'react-intl';

const termsOfUseVersions = [
  {
    messages: defineMessages({
      name: {
        id: 'brand.term-of-use.title',
        defaultMessage: 'Terms of use',
      },
    }),
    content: require('./terms-of-use-2020-05-22.content.html'),
    version: 3,
    lastModified: new Date(2020, 5, 22),
    url: `/terms-of-use`,
  },
  {
    messages: defineMessages({
      name: {
        id: 'brand.term-of-use.title',
        defaultMessage: 'Terms of use',
      },
    }),
    content: require('./terms-of-use-2018-05-24.content.html'),
    version: 2,
    lastModified: new Date(2018, 5, 24),
    url: `/terms-of-use`,
  },
  {
    messages: defineMessages({
      name: {
        id: 'brand.term-of-use.title',
        defaultMessage: 'Terms of use',
      },
    }),
    content: require('./terms-of-use-2017-11-24.content.html'),
    version: 1,
    lastModified: new Date(2017, 11, 24),
    url: `/terms-of-use`,
  },
];
const currentTermsVersion =
  termsOfUseVersions.filter(
    v => v.version === Number(process.env.REACT_APP_CURRENT_TERMS_VERSION)
  )[0] || termsOfUseVersions[0];

const privacyPolicyVersions = [
  {
    messages: defineMessages({
      name: {
        id: 'brand.privacy-policy.title',
        defaultMessage: 'Privacy Policy',
      },
    }),
    content: require('./privacy-policy-2020-05-22.content.html'),
    version: 3,
    lastModified: new Date(2020, 5, 22),
    url: '/privacy-policy',
  },
  {
    messages: defineMessages({
      name: {
        id: 'brand.privacy-policy.title',
        defaultMessage: 'Privacy Policy',
      },
    }),
    content: require('./privacy-policy-2018-05-24.content.html'),
    version: 2,
    lastModified: new Date(2018, 5, 24),
    url: '/privacy-policy',
  },
  {
    messages: defineMessages({
      name: {
        id: 'brand.privacy-policy.title',
        defaultMessage: 'Privacy Policy',
      },
    }),
    content: require('./privacy-policy-2017-11-24.content.html'),
    version: 1,
    lastModified: new Date(2017, 11, 24),
    url: '/privacy-policy',
  },
];
const currentPrivacyVersion =
  privacyPolicyVersions.filter(
    v => v.version === Number(process.env.REACT_APP_CURRENT_PRIVACY_VERSION)
  )[0] || privacyPolicyVersions[0];

export default {
  name: 'Indorse',
  email: {
    messages: defineMessages({
      name: {
        id: 'brand.email.title',
        defaultMessage: 'Contact us',
      },
    }),
    url: 'mailto:help@indorse.io',
  },
  faq: {
    messages: defineMessages({
      name: {
        id: 'brand.faq.title',
        defaultMessage: 'FAQ',
      },
    }),
    url: '/faq',
  },
  blog: {
    messages: defineMessages({
      name: {
        id: 'brand.blog.title',
        defaultMessage: 'Blog',
      },
    }),
    url: 'https://resources.indorse.io',
  },
  logo: {
    default: require('./logo-icon.png'),
    orangeBlue: require('./logo-orange-blue.jpg'),
    blue: require('./logo-blue.png'),
    meta: require('./logo-meta.webp'),
    withname: require('./indorse-logo.png'),
    withname2x: require('./indorse-logo@2x.png'),
    withname3x: require('./indorse-logo@3x.png'),
    footer: require('./indorse-logo.png'),
    footer2x: require('./indorse-logo@2x.png'),
    footer3x: require('./indorse-logo@3x.png'),
  },
  howToParticipate: {
    url: 'https://medium.com/joinindorse/how-to-participate-in-indorse-token-sale-8ddb50003b4f',
  },
  tokenSale: {
    appUrl: process.env.REACT_APP_ICON_APP_URL,
  },
  termsOfUse: {
    versions: termsOfUseVersions,
    currentVersion: currentTermsVersion,
  },
  privacyPolicy: {
    content: require('./privacy-policy-2020-05-22.content.html'),
    versions: privacyPolicyVersions,
    currentVersion: currentPrivacyVersion,
  },
  cookieConsent: {
    content: require('./cookie-consent-22-04-2019.content.html'),
  },
  helpCenter: {
    messages: defineMessages({
      name: {
        id: 'brand.help-center.title',
        defaultMessage: 'Help Center',
      },
    }),

    url: `https://help.indorse.io/hc/en-us `,
  },
  copyRight: {
    messages: defineMessages({
      name: {
        id: 'brand.copyright.title',
        defaultMessage: 'Copyright 2017 - Present | Indorse Pte. Ltd',
      },
    }),
  },
  newsLetter: {
    messages: defineMessages({
      name: {
        id: 'brand.news-letter.title',
        defaultMessage: 'Sign up for our newsletter and stay up-to-date.',
      },
    }),
  },
};
