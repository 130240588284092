import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/auth';

export const SIGN_UP = defineApiActionTypes(`${prefix}/SIGN_UP`);

export const VERIFY_EMAIL = defineApiActionTypes(`${prefix}/VERIFY_EMAIL`);

export const RESEND_VERIFICATION_EMAIL = defineApiActionTypes(
  `${prefix}/RESEND_VERIFICATION_EMAIL`
);

export const LOGIN = defineApiActionTypes(`${prefix}/LOGIN`);

export const LOGOUT = defineApiActionTypes(`${prefix}/LOGOUT`);

export const CHANGE_PASSWORD = defineApiActionTypes(`${prefix}/CHANGE_PASSWORD`);

export const SET_PASSWORD = defineApiActionTypes(`${prefix}/SET_PASSWORD`);

export const FORGOT_PASSWORD = defineApiActionTypes(`${prefix}/FORGOT_PASSWORD`);

export const CLEAR_FORGOT_PASSWORD_EMAIL_STATUS = `${prefix}/CLEAR_FORGOT_PASSWORD_EMAIL_STATUS`;

export const RESET_PASSWORD = defineApiActionTypes(`${prefix}/RESET_PASSWORD`);

export const INVALIDATE_SESSION = `${prefix}/INVALIDATE_SESSION`;

export const FETCH_CURRENT_USER = defineApiActionTypes(`${prefix}/FETCH_CURRENT_USER`);

export const UPDATE_CURRENT_USER = defineApiActionTypes(`${prefix}/UPDATE_CURRENT_USER`);

export const UNAUTHORIZED = `${prefix}/UNAUTHORIZED`;

export const IMPORT_LINKED_IN_PROFILE_TEST = 'profile/IMPORT_LINKED_IN_PROFILE_TEST';

export const FACEBOOK_AUTH = defineApiActionTypes(`${prefix}/FACEBOOK_AUTH`);

export const LINK_FACEBOOK = defineApiActionTypes(`${prefix}/LINK_FACEBOOK`);

export const GOOGLE_AUTH = defineApiActionTypes(`${prefix}/GOOGLE_AUTH`);

export const LINK_GOOGLE = defineApiActionTypes(`${prefix}/LINK_GOOGLE`);

export const LINKED_IN_AUTH = defineApiActionTypes(`${prefix}/LINKED_IN_AUTH`);

export const LINK_LINKED_IN = defineApiActionTypes(`${prefix}/LINK_LINKED_IN`);

export const UPORT_AUTH = defineApiActionTypes(`${prefix}/UPORT_AUTH`);

export const LINK_UPORT = defineApiActionTypes(`${prefix}/LINK_UPORT`);

export const POLL_UPORT = defineApiActionTypes(`${prefix}/POLL_UPORT`);

export const SET_HAS_CONNECTIONS_ENTITY = `${prefix}/SET_HAS_CONNECTIONS_ENTITY`;

export const MARK_CURRENT_USER_INITIALIZED = `${prefix}/MARK_CURRENT_USER_INITIALIZED`;

export const IMPRESS_AI_AUTH = defineApiActionTypes(`${prefix}/IMPRESS_AI_AUTH`);

export const ACCEPT_CURRENT_TERMS_AND_PRIVACY = defineApiActionTypes(
  `${prefix}/ACCEPT_CURRENT_TERMS_AND_PRIVACY`
);

export const SUBS_NEW_BADGE_CURRENT_USER = `${prefix}/SUBS_NEW_BADGE_CURRENT_USER`;

export const LOGIN_ADVISOR = `${prefix}/LOGIN_ADVISOR`;

export const LOGIN_COMPANY_ADMIN = `${prefix}/LOGIN_COMPANY_ADMIN`;

export const LOGIN_CLAIMS_FLOW = `${prefix}/LOGIN_CLAIMS_FLOW`;

export const LOGIN_EMS_EMPLOYEE = `${prefix}/LOGIN_EMS_EMPLOYEE`;

export const LOGIN_SUCCESS_REQUEST = `${prefix}/LOGIN_SUCCESS_REQUEST`;
