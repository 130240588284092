import { defineMessages } from 'react-intl';

export const header = defineMessages({
  title: {
    id: `interests.title`,
    defaultMessage: `What are you most interested in?`,
  },
  subtitle: {
    id: `interests.subtitle`,
    defaultMessage: `We'll keep it private, don't worry. This helps us understand your needs better.`,
  },
});

export const responses = defineMessages({
  interestsSuccess: {
    id: `interests.messages.success`,
    defaultMessage: `Interests saved successfully.`,
  },
  interestsError: {
    id: `interests.messages.error`,
    defaultMessage: `Interests set Failed. Please try again!`,
  },
});
