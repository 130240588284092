import Raven from 'raven-js';

// Yanked from https://git.io/vS8DV re-used under CC0
// with some tiny modifications
function isError(value) {
  switch ({}.toString.call(value)) {
    case '[object Error]':
      return true;
    case '[object Exception]':
      return true;
    case '[object DOMException]':
      return true;
    default:
      return value instanceof Error;
  }
}

function supportsErrorEvent() {
  try {
    new ErrorEvent(''); // eslint-disable-line no-new
    return true;
  } catch (e) {
    return false;
  }
}

function isErrorEvent(value) {
  return supportsErrorEvent() && {}.toString.call(value) === '[object ErrorEvent]';
}

const originalCaptureException = Raven.captureException;
Raven.captureException = function(ex) {
  var isNotError = !isError(ex);
  var isNotErrorEvent = !isErrorEvent(ex);
  var isErrorEventWithoutError = isErrorEvent(ex) && !ex.error;

  if ((isNotError && isNotErrorEvent) || isErrorEventWithoutError) {
    return originalCaptureException.bind(this)(JSON.stringify(ex));
  } else {
    return originalCaptureException.bind(this)(ex);
  }
};

export default function installRaven() {
  const sentryKey = process.env.REACT_APP_SENTRY_KEY;
  const sentryProject = process.env.REACT_APP_SENTRY_PROJECT;
  const ravenDSN = `https://${sentryKey}@sentry.io/${sentryProject}`;

  // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
  const ravenOptions = {
    whitelistUrls: (process.env.REACT_APP_SENTRY_WHITE_LIST_URLS || '')
      .split(',')
      .map(urlPattern => new RegExp(urlPattern)),
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    ignoreUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    debug: process.env.NODE_ENV === 'development',
  };

  if (sentryKey && sentryProject && ravenDSN) {
    Raven.config(ravenDSN, ravenOptions).install();
    Raven.enabled = true;
  }
  return Raven;
}
