export function resultHasErrors({ errors } = {}) {
  return errors && errors.length > 0;
}

export function errorsToSingleMessage({ errors }) {
  if (!errors) return;

  if (Array.isArray(errors)) {
    const messages = errors.map(err => err.message);
    return messages.join('\n');
  } else {
    return errors.toString();
  }
}
