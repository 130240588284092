import moment from 'moment';

export const dateYearMonthFieldNames = {
  year: 'year',
  month: 'month',
};

export const fieldNames = {
  title: 'title',
  companyName: 'companyName',
  companyNameSearch: 'companyNameSearch',
  companyId: 'companyId',
  location: 'location',
  startDate: 'startDate',
  endDate: 'endDate',
  currentlyWorking: 'currentlyWorking',
  description: 'description',
};

export default function(values = {}) {
  const currentYear = moment().year();
  const currentMonth = moment().month() + 1;
  const startDateYear =
    values &&
    values[fieldNames.startDate] &&
    values[fieldNames.startDate][dateYearMonthFieldNames.year];
  const endDateYear =
    values &&
    values[fieldNames.endDate] &&
    values[fieldNames.endDate][dateYearMonthFieldNames.year];
  const startDateMonth =
    values &&
    values[fieldNames.startDate] &&
    values[fieldNames.startDate][dateYearMonthFieldNames.month];
  // const endDateMonth = values && values[fieldNames.endDate] && values[fieldNames.endDate][dateYearMonthFieldNames.month];
  const currentlyWorking = values && values.currentlyWorking;

  return {
    [fieldNames.title]: {
      presence: true,
      length: {
        maximum: 100,
      },
    },
    [fieldNames.companyName]: {
      presence: !values[fieldNames.companyNameSearch],
      length: {
        maximum: 100,
      },
    },
    [fieldNames.companyNameSearch]: {
      length: {
        maximum: 100,
      },
    },
    [fieldNames.location]: {
      presence: true,
      length: {
        maximum: 100,
      },
    },
    [`${fieldNames.startDate}.${dateYearMonthFieldNames.year}`]: {
      presence: true,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: currentYear - 50,
        // lessThanOrEqualTo: endDateYear || (currentYear),
        lessThanOrEqualTo: currentYear,
        // notLessThanOrEqualTo: endDateYear ? "must be less than or equal to End date year" : "must be less than or equal to %{count}"
        notLessThanOrEqualTo: 'must be less than or equal to %{count}',
      },
    },
    [`${fieldNames.startDate}.${dateYearMonthFieldNames.month}`]: {
      presence: true,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 1,
        // lessThanOrEqualTo: startDateYear === endDateYear ? endDateMonth || 12 : 12,
        lessThanOrEqualTo: startDateYear === currentYear ? currentMonth : 12,
        // notLessThanOrEqualTo: startDateYear === endDateYear ? "must be less than or equal to End date month" : "must be a valid month"
        notLessThanOrEqualTo:
          startDateYear === currentYear
            ? `must be less than or equal to today's date`
            : 'must be a valid month',
      },
    },
    [`${fieldNames.endDate}.${dateYearMonthFieldNames.year}`]: {
      presence: !currentlyWorking,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: startDateYear || currentYear - 50,
        notGreaterThanOrEqualTo: startDateYear
          ? 'must be greater than or equal to Start date year'
          : 'must be greater than or equal to %{count}',
        lessThanOrEqualTo: currentYear,
      },
    },
    [`${fieldNames.endDate}.${dateYearMonthFieldNames.month}`]: {
      presence: !currentlyWorking,
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: startDateYear === endDateYear ? startDateMonth || 1 : 1,
        notGreaterThanOrEqualTo:
          startDateYear === endDateYear
            ? 'must be greater than or equal to Start date month'
            : 'must be a valid month',
        lessThanOrEqualTo: endDateYear === currentYear ? currentMonth : 12,
        notLessThanOrEqualTo:
          startDateYear === currentYear
            ? `must be less than or equal to today's date`
            : 'must be a valid month',
      },
    },
    [fieldNames.currentlyWorking]: {},
    [fieldNames.description]: {
      length: {
        maximum: 2000,
      },
    },
  };
}
