import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import { defineMessages } from 'react-intl';
import Loading from 'ui/common/loading';

import {
  selectIsLoggedIn,
  selectIsCurrentUserApproved,
  selectCurrentUserFetchedAtleastOnce,
  selectCurrentUser,
} from 'store/features/auth/selectors';
import routeTemplates from 'ui/common/routes/templates';
import { messageTypes, messageIds, buildMessage } from 'store/features/common/flash/builder';

import AuthenticatedConsentCheck from './authenticated-consent-check';

const messages = defineMessages({
  authenticationRequired: {
    id: 'common.auth.authenticated.required',
    defaultMessage: 'You need to login first',
  },
});

function mapStateToProps(state) {
  return {
    loggedIn: selectIsLoggedIn(state),
    currentUser: selectCurrentUser(state),
    currentUserApproved: selectIsCurrentUserApproved(state),
    currentUserFetchedAtleastOnce: selectCurrentUserFetchedAtleastOnce(state),
  };
}

const approvalRequiredDefault = process.env.ADMIN_APPROVAL_REQUIRED_FOR_USER === 'true';

const Authenticated = (
  WrappedComponent,
  {
    showLoadingTillCurrentUserFetched = false,
    approvalRequired = approvalRequiredDefault,
    flash = true,
    preConsentRequired = true,
  } = {}
) => {
  class Wrapper extends React.Component {
    render() {
      const {
        loggedIn,
        currentUserFetchedAtleastOnce,
        currentUserApproved,
        location,
        currentUser,
        ...passThrough
      } = this.props;
      // console.log('currentUser (Authenticated) : ', currentUser);

      if (loggedIn) {
        if (approvalRequired && !currentUserApproved) {
          return (
            <Redirect
              to={{
                pathname: routeTemplates.auth.approvalRequired,
                state: null,
              }}
            />
          );
        }

        if (showLoadingTillCurrentUserFetched && !currentUserFetchedAtleastOnce) return <Loading />;

        return <WrappedComponent {...passThrough} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: routeTemplates.auth.login,
              state: flash
                ? {
                    from: location,
                    flash: buildMessage({
                      id: messageIds.auth.status,
                      kind: messageTypes.danger,
                      content: messages.authenticationRequired,
                    }),
                  }
                : undefined,
            }}
          />
        );
      }
    }
  }

  Wrapper.displayName = 'Authenticated';

  const Component = withRouter(connect(mapStateToProps)(Wrapper));

  if (preConsentRequired) return AuthenticatedConsentCheck(Component);

  return Component;
};

export default Authenticated;
