import Immutable from 'seamless-immutable';
import { combineReducers } from 'redux';

import * as actionTypes from 'store/features/admin/claims/action-types';
import * as moderateClaimActionTypes from 'store/graphql/AdminModerateClaimModal/action-types';

const defaultPerPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50;

const initialState = Immutable({
  ids: [],
  fetching: false,
  currentPage: null,
  perPage: defaultPerPage,
  totalClaims: 0,
  sort: { field: '', order: null },
  matchingClaims: 0,
  error: false,
  moderation: null,
  rejectionReasons: null,
});

function listing(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CLAIMS.REQUEST:
      const { pageNo: fetchingPage, perPage: fetchingPerPage, sort: fetchingSort } = action.payload;
      return state.merge({
        ids: [],
        fetching: true,
        currentPage: fetchingPage,
        perPage: fetchingPerPage || defaultPerPage,
        totalClaims: 0,
        sort: fetchingSort,
        matchingClaims: 0,
      });
    case actionTypes.FETCH_CLAIMS.SUCCESS:
      const { pageNo: currentPage } = action.payload.input;
      const { claims: fetchedClaimsIds, totalClaims, matchingClaims } = action.payload.result;

      return state.merge({
        ids: fetchedClaimsIds,
        fetching: false,
        currentPage,
        totalClaims,
        matchingClaims: typeof matchingClaims === 'number' ? matchingClaims : totalClaims,
        error: null,
      });
    case actionTypes.FETCH_CLAIMS.FAILURE:
      const { error } = action.payload;

      return state.merge({
        ids: [],
        fetching: false,
        error,
      });
    case actionTypes.FETCH_REJECTION_REASONS.START: {
      return state.set('fetching', true);
    }

    case actionTypes.FETCH_REJECTION_REASONS.SUCCESS: {
      const { rejectionReasons } = action.payload;

      return state.merge({
        fetching: false,
        rejectionReasons,
        error: null,
      });
    }

    case actionTypes.FETCH_REJECTION_REASONS.FAILURE: {
      const { error } = action.payload;
      return state.set('fetching', false).set('error', error);
    }
    default:
      return state;
  }
}

const byIdInitialState = Immutable({});

function byId(state = byIdInitialState, action) {
  switch (action.type) {
    case actionTypes.APPROVE_CLAIM.START:
      const { claimId: approvingClaimId } = action.payload;

      if (approvingClaimId) {
        return state.merge(
          {
            [approvingClaimId]: { approving: true },
          },
          { deep: true }
        );
      }

      return state;
    case actionTypes.APPROVE_CLAIM.SUCCESS:
      const { claimId: approvedClaimId } = action.payload;

      if (approvedClaimId) {
        return state.merge(
          {
            [approvedClaimId]: {
              approving: false,
              lastApprovedAt: new Date().toISOString(),
              error: null,
            },
          },
          { deep: true }
        );
      }

      return state;
    case actionTypes.APPROVE_CLAIM.FAILURE:
      const { claimId: approvalFailedClaimId, error: approvalError } = action.payload;

      if (approvalFailedClaimId) {
        return state.merge(
          {
            [approvalFailedClaimId]: {
              approving: false,
              error: approvalError,
            },
          },
          { deep: true }
        );
      }

      return state;
    case actionTypes.DISAPPROVE_CLAIM.START:
      const { claimId: disapprovingClaimId } = action.payload;

      if (disapprovingClaimId) {
        return state.merge(
          {
            [disapprovingClaimId]: { disapproving: true },
          },
          { deep: true }
        );
      }

      return state;
    case actionTypes.DISAPPROVE_CLAIM.SUCCESS:
      const { claimId: disapprovedClaimId } = action.payload;

      if (disapprovedClaimId) {
        return state.merge(
          {
            [disapprovedClaimId]: {
              disapproving: false,
              lastApprovedAt: new Date().toISOString(),
              error: null,
            },
          },
          { deep: true }
        );
      }

      return state;
    case actionTypes.DISAPPROVE_CLAIM.FAILURE:
      const { claimId: disapprovalFailedClaimId, error: disapprovalError } = action.payload;

      if (disapprovalFailedClaimId) {
        return state.merge(
          {
            [disapprovalFailedClaimId]: {
              disapproving: false,
              error: disapprovalError,
            },
          },
          { deep: true }
        );
      }

      return state;

    case moderateClaimActionTypes.MODERATE_CLAIM.SUCCESS:
      const { claimId: moderatedClaimId, moderation } = action.payload;

      if (moderatedClaimId) {
        return state.merge(
          {
            [moderatedClaimId]: {
              moderating: false,
              error: null,
              moderation,
            },
          },
          { deep: true }
        );
      }

      return state;

    default:
      return state;
  }
}

export default combineReducers({ listing, byId });
