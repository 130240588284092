import { call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { push } from 'react-router-redux';
import { startSubmit, stopSubmit } from 'redux-form';
import { hide as hideModal } from 'redux-modal';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as wizardActions from 'store/features/wizard/actions';
import * as api from 'api/config/users';
import callApi from 'store/api/saga';
import routeTemplates from 'ui/common/routes/templates';
import routeGenerators from 'ui/common/routes/generators';
import messages from 'ui/users/messages';
import { messageTypes } from 'store/features/common/flash/builder';
import { addMessage } from 'store/features/common/flash/actions';
import { fetchCurrentUser } from 'store/features/auth/actions';
import { convertToDataURL } from 'utils/file';

import skillsSaga from './skills/saga';
import {
  selectCurrentUserId,
  selectCurrentUserEthereumAddress,
  selectCurrentUser,
  selectIsFetchingCurrentUser,
} from '../auth/selectors';

function* approveUser({ payload }) {
  const { userId } = payload;
  yield put(actions.approveUser.start({ userId }));

  try {
    yield call(callApi, api.approveUser({ userId }));
    yield put(actions.approveUser.success({ userId }));
  } catch (error) {
    yield put(actions.approveUser.failure({ userId, error }));
  }
}

function* watchApproveUser() {
  yield takeEvery(actionTypes.APPROVE_USER.REQUEST, approveUser);
}

function* disapproveUser({ payload }) {
  const { userId } = payload;
  yield put(actions.disapproveUser.start({ userId }));

  try {
    yield call(callApi, api.disapproveUser({ userId }));
    yield put(actions.disapproveUser.success({ userId }));
  } catch (error) {
    yield put(actions.disapproveUser.failure({ userId, error }));
  }
}

function* watchDisapproveUser() {
  yield takeEvery(actionTypes.DISAPPROVE_USER.REQUEST, disapproveUser);
}

// TODO: Remove when ID-1096 is fixed
function generateUserSkillIds(user) {
  if (user && user.id && user.skills && user.skills.length && user.skills.length > 0) {
    const userId = user._id || user.id;
    if (userId) {
      user.skills.forEach((userSkill, index) => {
        if (!userSkill.id && userSkill.skill && userSkill.skill.id)
          userSkill.id = `${userId}_skills_${userSkill.skill.id}`;
      });
    }
  }
}

function* fetchUserById({ payload }) {
  const { userId } = payload;

  const fetchingCurrentUser = yield select(selectIsFetchingCurrentUser);
  const currentUser = yield select(selectCurrentUser);
  if (fetchingCurrentUser && currentUser && currentUser.id === userId) return;

  yield put(actions.fetchUserById.start({ userId }));

  try {
    const response = yield call(callApi, api.fetchUserById({ userId }));
    if (response && response.profile) {
      generateUserSkillIds(response.profile);
    }
    const schema = { profile: schemas.user };
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchUserById.success({ userId }));
  } catch (error) {
    yield put(actions.fetchUserById.failure({ userId, error }));
    yield put(push(routeTemplates.app.linkNotFound));
  }
}

function* watchFetchUserById() {
  yield takeEvery(actionTypes.FETCH_USER_BY_ID.REQUEST, fetchUserById);
}

function* fetchUserByUsername({ payload }) {
  const { username } = payload;

  const fetchingCurrentUser = yield select(selectIsFetchingCurrentUser);
  const currentUser = yield select(selectCurrentUser);
  if (fetchingCurrentUser && currentUser && currentUser.username === username) return;

  yield put(actions.fetchUserByUsername.start({ username }));

  try {
    const response = yield call(callApi, api.fetchUserByUsername({ username }));
    if (response && response.profile) {
      generateUserSkillIds(response.profile);
    }
    const schema = { profile: schemas.user };
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchUserByUsername.success({ username }));
  } catch (error) {
    yield put(actions.fetchUserByUsername.failure({ username, error }));
    yield put(push(routeTemplates.app.linkNotFound));
  }
}

function* watchFetchUserByUsername() {
  yield takeEvery(actionTypes.FETCH_USER_BY_USERNAME.REQUEST, fetchUserByUsername);
}

function* assignRole({ payload }) {
  const { userId, role } = payload;
  yield put(actions.assignRole.start({ userId, role }));

  try {
    yield call(callApi, api.assignRole({ userId, role }));
    yield put(actions.assignRole.success({ userId, role }));
  } catch (error) {
    yield put(actions.assignRole.failure({ userId, error }));
  }
}

function* watchAssignRole() {
  yield takeEvery(actionTypes.ASSIGN_ROLE.REQUEST, assignRole);
}

function* importLinkedInPdf({ payload, meta }) {
  const { modal } = meta;
  const { file } = payload;

  yield put(actions.importLinkedInPdf.start({ file }));
  const fileData = yield call(convertToDataURL, file);

  try {
    const response = yield call(callApi, api.importLinkedInPdf({ file: fileData }));
    if (response.profile) {
      generateUserSkillIds(response.profile);
      const schema = { profile: schemas.user };
      const { entities } = normalize(response, schema);
      yield put(entityActions.mergeEntities(entities));
    } else {
      yield put(fetchCurrentUser.request());
    }

    yield put(actions.importLinkedInPdf.success());

    if (modal) yield put(hideModal(modal));

    yield put(
      addMessage({
        kind: messageTypes.warning,
        content: messages.importLindekedInPdfSuccess,
      })
    );
  } catch (error) {
    yield put(
      actions.importLinkedInPdf.failure({
        error: (error && error.message) || messages.importLindekedInPdfFailure,
      })
    );
  }
}

function* watchImportLinkedInPdf() {
  yield takeEvery(actionTypes.IMPORT_LINKEDIN_PDF.REQUEST, importLinkedInPdf);
}

function* setEthereumAddress({ payload, meta }) {
  const { userId, ethereumAddress } = payload;
  const { form, modal } = meta;

  yield put(actions.setEthereumAddress.start({ userId, ethereumAddress }));
  if (form) yield put(startSubmit(form));

  try {
    yield call(callApi, api.setEthereumAddress({ userId, ethereumAddress }));
    yield put(actions.setEthereumAddress.success({ userId, ethereumAddress }));
    if (form) yield put(stopSubmit(form));
    if (modal) yield put(hideModal(modal));
  } catch (error) {
    yield put(
      actions.setEthereumAddress.failure({ error: error.message, userId, ethereumAddress })
    );
    if (form) yield put(stopSubmit(form, error.formErrors || error.message));
  }
}

function* watchSetEthereumAddress() {
  yield takeEvery(actionTypes.SET_ETHEREUM_ADDRESS.REQUEST, setEthereumAddress);
}

function* setCurrentUserEthereumAddress({ payload, meta }) {
  const { ethereumAddress, signature } = payload;
  const { form, modal, redirectToProfile, wizard } = meta;
  const currentUserEthereumAddressAlreadySet = !!(yield select(selectCurrentUserEthereumAddress));

  yield put(actions.setCurrentUserEthereumAddress.start({ ethereumAddress, signature }));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(
      callApi,
      api.setCurrentUserEthereumAddress({ ethereumAddress, signature })
    );
    const currentUserId = yield select(selectCurrentUserId);
    yield put(
      actions.setCurrentUserEthereumAddress.success({
        currentUserId,
        ethereumAddress: response.ethereumAddress,
        ethereumAddressVerified: response.ethereumAddressVerified,
      })
    );

    if (form) yield put(stopSubmit(form));
    if (modal) yield put(hideModal(modal));

    if (currentUserEthereumAddressAlreadySet) {
      yield put(
        addMessage({
          kind: messageTypes.success,
          content: messages.verifyCurrentUserEthAddressSuccess,
        })
      );
    } else {
      yield put(
        addMessage({
          kind: messageTypes.success,
          content: messages.addCurrentUserEthAddressSuccess,
        })
      );
    }

    if (redirectToProfile) {
      const currentUser = yield select(selectCurrentUser);
      yield put(push(routeGenerators.users.bestProfile({ user: currentUser })));
    }

    if (wizard) {
      const { name, apiConfig } = wizard;
      yield put(wizardActions.completeStep.request({ name, apiConfig }));
    }
  } catch (error) {
    yield put(
      actions.setCurrentUserEthereumAddress.failure({
        error: error.message,
        ethereumAddress,
        signature,
      })
    );

    if (form) yield put(stopSubmit(form, error.formErrors || error.message));
  }
}

function* watchSetCurrentUserEthereumAddress() {
  yield takeEvery(
    actionTypes.SET_CURRENT_USER_ETHEREUM_ADDRESS.REQUEST,
    setCurrentUserEthereumAddress
  );
}

function* deleteCurrentUser({ payload: values, meta }) {
  const { form } = meta;
  yield put(actions.deleteCurrentUser.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.deleteCurrentUser(values));
    yield put(actions.deleteCurrentUser.success(response));
    if (form) yield put(stopSubmit(form));

    yield put(fetchCurrentUser.request());
    const currentUser = yield select(selectCurrentUser);
    yield put(push(routeGenerators.users.bestProfile({ user: currentUser })));
  } catch (error) {
    yield put(actions.deleteCurrentUser.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchDeleteCurrentUser() {
  yield takeEvery(actionTypes.DELETE_CURRENT_USER.REQUEST, deleteCurrentUser);
}

export default function* users() {
  yield fork(watchFetchUserById);
  yield fork(watchFetchUserByUsername);
  yield fork(watchApproveUser);
  yield fork(watchDisapproveUser);
  yield fork(watchAssignRole);
  yield fork(watchImportLinkedInPdf);
  yield fork(watchSetEthereumAddress);
  yield fork(watchSetCurrentUserEthereumAddress);
  yield fork(watchDeleteCurrentUser);
  yield fork(skillsSaga);
}
