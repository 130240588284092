import { generateSortParameterURLValue } from 'utils/sort';

export function fetchUserClaims({ userId }) {
  return {
    endpoint: `users/${userId}/claims`,
    method: 'GET',
    requireAuth: true,
  };
}

export function createClaim({ title, level, description: desc, proof }, assessmentId) {
  return {
    endpoint: 'claims',
    method: 'POST',
    data: { title, level, desc, proof, assessmentId },
    requireAuth: true,
    decamlizeInput: false,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function fetchClaim({ claimId }) {
  return {
    endpoint: `claims/${claimId}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function fetchClaims({
  filters = {},
  pageNo,
  perPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  sort,
}) {
  const sortParam = generateSortParameterURLValue(sort);
  const qs = new URLSearchParams();
  if (filters)
    Object.keys(filters).forEach(filterName => {
      const filterValue = filters[filterName];
      if (typeof filterValue !== undefined && filterValue !== null && filterValue !== [])
        qs.set(filterName, filterValue);
    });
  if (sortParam && sortParam.length > 0) qs.set('sort', sortParam);
  qs.set('pageNo', pageNo || 1);
  qs.set('perPage', perPage || 1);

  return {
    endpoint: `claimsall?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function approveClaim({ claimId, force }) {
  return {
    endpoint: `claims/${claimId}/approve`,
    data: { force },
    method: 'POST',
    requireAuth: true,
  };
}

export function disapproveClaim({ claimId, disapprovalReason }) {
  return {
    endpoint: `claims/${claimId}/disapprove`,
    data: { disapprovalReason },
    method: 'POST',
    requireAuth: true,
    decamlizeInput: false,
  };
}
