import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import square from 'resources/common/orange-square.svg';
import circle from 'resources/common/green-circle.svg';
import triangle from 'resources/common/yellow-triangle.svg';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';

const CHROME = 'https://api.iconify.design/logos:chrome.svg';
const FIREFOX = 'https://api.iconify.design/logos:firefox.svg';
const OPERA = 'https://api.iconify.design/logos:opera.svg';

const RenderDesktop = props => {
  return (
    <div className={classnames(styles.secondaryCta)}>
      <div className="container d-flex align-items-center justify-content-center">
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/ems-ext-secondary-cta-1.png"
          alt="cta1"
          width="200"
          className="mt-5"
          style={{ transform: 'translateX(-0.5rem)' }}
        />
        <div className={styles.heading}>
          <h1 className={styles.title}>Go behind the scenes and uncover the Git activty</h1>
          <h3 className="m-0 mt-5" style={{ color: 'white' }}>
            Download Extension
          </h3>
          <div className={styles.ctaBtn}>
            <div className="d-flex align-items-center justify-content-center">
              <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                <img src={CHROME} alt="chrome" className={classnames('')} />
              </a>
              <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                <img src={FIREFOX} alt="firefox" className={classnames('')} />
              </a>
              <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                <img src={OPERA} alt="opera" className={classnames()} />
              </a>
            </div>
          </div>
          <div
            className={classnames(
              styles.policy,
              'text-center row justify-content-center position-relative'
            )}
          >
            <span className="mb-2 d-flex justify-content-center mr-2">
              <img src={circle} alt="square" className={classnames(styles.svg, 'mr-2')} />
              Free forever
            </span>
            <span className="mb-2 d-flex justify-content-center mx-2">
              <img src={square} alt="square" className={classnames(styles.svg, 'mr-2')} />
              Dev tool
            </span>
            <span className="mb-2 d-flex justify-content-center mx-2">
              <img src={triangle} alt="square" className={classnames(styles.svg, 'mr-2')} />
              Productivity
            </span>
          </div>
        </div>
        <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/ems-ext-secondary-cta-2.png"
          alt="cta2"
          width="200"
          className="mb-5 pb-5"
        />
      </div>
    </div>
  );
};

const RenderMobile = props => {
  return (
    <div className={classnames(styles.secondaryCta)}>
      <div className="container d-flex align-items-center justify-content-center">
        {/* <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/ems-ext-secondary-cta-1.png"
          alt="cta1"
          className={classnames(styles.img1, 'mb-5 pb-5')}
          style={{ transform: 'translateX(-0.3rem)' }}
        /> */}
        <div className={styles.heading}>
          <h1 className={styles.title}>
            Go behind the<br />scenes and uncover<br />the Git activty
          </h1>
          <h3 className="m-0 mt-5" style={{ color: 'white' }}>
            Download Extension
          </h3>
          <div className={styles.ctaBtn}>
            <div className="d-flex align-items-center justify-content-center">
              <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                <img src={CHROME} alt="chrome" className={classnames('')} />
              </a>
              <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                <img src={FIREFOX} alt="firefox" className={classnames('')} />
              </a>
              <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                <img src={OPERA} alt="opera" className={classnames()} />
              </a>
            </div>
          </div>
        </div>
        {/* <img
          src="https://indorse-staging-bucket.s3.amazonaws.com/ems-ext-secondary-cta-2.png"
          alt="cta2"
          style={{ transform: 'translateX(-1rem) translateY(3rem)' }}
          className={classnames(styles.img2)}
        /> */}
      </div>
      <div
        className={classnames(
          styles.policy,
          'text-center row justify-content-center position-relative'
        )}
      >
        <span className="mb-2 d-flex justify-content-center mr-2">
          <img src={circle} alt="square" className={classnames(styles.svg, 'mr-2')} />
          Free forever
        </span>
        <span className="mb-2 d-flex justify-content-center mx-2">
          <img src={square} alt="square" className={classnames(styles.svg, 'mr-2')} />
          Dev tool
        </span>
        <span className="mb-2 d-flex justify-content-center mx-2">
          <img src={triangle} alt="square" className={classnames(styles.svg, 'mr-2')} />
          Productivity
        </span>
      </div>
    </div>
  );
};

const secondaryCTASection = props => {
  return (
    <div className={classnames(styles.section)}>
      <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
        <RenderDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
        <RenderMobile />
      </MediaQuery>
    </div>
  );
};

export default secondaryCTASection;
