import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  submitting: false,
  error: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.USER_SEND_SURVEY.START: {
      return state.set('submitting', true);
    }

    case actionTypes.USER_SEND_SURVEY.FAILURE: {
      const { error } = action.payload;
      return state.merge({
        submitting: false,
        error,
      });
    }

    case actionTypes.USER_SEND_SURVEY.SUCCESS: {
      return state.merge({
        submitting: false,
        error: false,
      });
    }

    default:
      return state;
  }
}
