/*
	validator.js is used to validate all fields in any forms
	https://validatejs.org/#validators for all validations.
*/
export const fieldNames = {
  disapprovalReason: 'disapprovalReason',
  selectReason: 'selectReason',
};

export default {
  [fieldNames.disapprovalReason]: {
    presence: true,
  },
  [fieldNames.selectReason]: {
    presence: false,
  },
};
