import * as actionTypes from './action-types';
import { defineAction } from 'store/common/action-helpers';

export const setUserProfessions = {
  request: (values, userId, { form, wizard }) =>
    defineAction(actionTypes.SET_USER_PROFESSIONS.REQUEST, { values, userId }, { form, wizard }),
  start: values => defineAction(actionTypes.SET_USER_PROFESSIONS.START, values),
  success: response => defineAction(actionTypes.SET_USER_PROFESSIONS.SUCCESS, response),
  failure: error => defineAction(actionTypes.SET_USER_PROFESSIONS.FAILURE, error),
};
