import * as types from './action-types';
import { defineAction } from 'store/common/action-helpers';

// request
export const fetchSmartContractByName = {
  request: ({ contractName }) =>
    defineAction(types.FETCH_SMART_CONTRACT_BY_NAME.REQUEST, { contractName }),
  start: ({ contractName }) =>
    defineAction(types.FETCH_SMART_CONTRACT_BY_NAME.START, { contractName }),
  success: ({ contractName }) =>
    defineAction(types.FETCH_SMART_CONTRACT_BY_NAME.SUCCESS, { contractName }),
  failure: ({ contractName, error }) =>
    defineAction(types.FETCH_SMART_CONTRACT_BY_NAME.FAILURE, { contractName, error }),
};
