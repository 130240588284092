import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as authSelectors from 'store/features/auth/selectors';
import * as entitySelectors from 'store/entities/selectors';
import { compareByStartDateThenEndDate } from 'utils/date';

const selectWorkExperienceFeatureState = state => state.features.workExperience;

const workExperienceEntities = createSelector(
  entitySelectors.selectAllEntities,
  entities => entities.workExperience
);

export const selectCurrentUserWorkExperienceEntries = createSelector(
  entitySelectors.selectAllEntities,
  workExperienceEntities,
  authSelectors.selectCurrentUserId,
  (entities, workExperienceEntriesById, currentUserId) => {
    if (!currentUserId) return [];

    return denormalize(
      Object.keys(workExperienceEntriesById),
      [schemas.workExperienceEntry],
      entities
    )
      .filter(e => e.userId === currentUserId)
      .sort(compareByStartDateThenEndDate);
  }
);

export const selectUserWorkExperienceEntries = createSelector(
  entitySelectors.selectAllEntities,
  (state, props) => props.userId,
  workExperienceEntities,
  (entities, userId, workExperienceEntriesById) => {
    if (!userId) return [];

    return denormalize(
      Object.keys(workExperienceEntriesById),
      [schemas.workExperienceEntry],
      entities
    )
      .filter(e => e.userId === userId)
      .sort(compareByStartDateThenEndDate);
  }
);

export const selectWorkExperienceEntryById = createSelector(
  entitySelectors.selectAllEntities,
  (state, props) => props.id,
  (entities, entryId) => denormalize(entryId, schemas.workExperienceEntry, entities)
);

export const selectIsSavingEditWorkExperience = createSelector(
  selectWorkExperienceFeatureState,
  workExperienceState => workExperienceState.editWorkExperience.saving
);

export const selectIsDeletingUpdateWorkExperience = createSelector(
  selectWorkExperienceFeatureState,
  workExperienceState => workExperienceState.deleteWorkExperience.deleting
);
