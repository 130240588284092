export const data = {
  featureList: [
    'Engagement Metrics',
    'Slack Bot',
    'Microsoft Teams Bot',
    'Weekly Analytics Report',
    'SLA',
  ],
  standard: {
    price: '2',
    isPromo: true,
    promoPrice: '1',
    name: 'Standard',
    color: '#2A2498',
    features: [true, true, 'Coming Soon', true, 'Priority'],
  },
  enterprise: {
    price: '2',
    isPromo: true,
    promoPrice: '1',
    name: 'Standard',
    color: '#000000',
    features: [true, true, 'Coming Soon', 'Customizable Reports', 'Dedicated Account manager'],
  },
};
