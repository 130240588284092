import { defineMessages } from 'react-intl';
const imagesPath = './images';

export default {
  // slack: {
  //   name: "Slack",
  //   images: {
  //     mark: require(`${imagesPath}/slack-mark.svg`),
  //     favicon: require(`${imagesPath}/slack-favicon-32.png`)
  //   },
  //   url: "https://joinindorse.herokuapp.com/"
  // },
  twitter: {
    messages: defineMessages({
      name: {
        id: 'brands.twitter.title',
        defaultMessage: 'Twitter',
      },
    }),
    images: {
      logoBlue: require(`${imagesPath}/twitter-logo-blue.svg`),
      footer: require(`${imagesPath}/social-01.png`),
    },
    url: 'https://twitter.com/joinindorse?lang=eng',
  },
  telegram: {
    messages: defineMessages({
      name: {
        id: 'brands.telegram.title',
        defaultMessage: 'Telegram',
      },
    }),
    url: 'https://t.me/indorseio',
    images: {
      footer: require(`${imagesPath}/social-02.png`),
    },
  },
  medium: {
    messages: defineMessages({
      name: {
        id: 'brands.medium.title',
        defaultMessage: 'Medium',
      },
    }),
    images: {
      logoColorSmall: require(`${imagesPath}/medium-m-color-100px.png`),
      footer: require(`${imagesPath}/social-03.png`),
    },
    url: 'https://medium.com/joinIndorse',
  },
  facebook: {
    messages: defineMessages({
      name: {
        id: 'brands.facebook.title',
        defaultMessage: 'Facebook',
      },
    }),
    images: {
      logoColorSmall: null,
      footer: null,
    },
    url: 'https://www.facebook.com/JoinIndorse/',
  },
  reddit: {
    messages: defineMessages({
      name: {
        id: 'brands.Reddit.title',
        defaultMessage: 'Reddit',
      },
    }),
    images: {
      logoColorSmall: null,
      footer: null,
    },
    url: 'https://www.reddit.com/r/indorse/',
  },
  linkedIn: {
    messages: defineMessages({
      name: {
        id: 'brands.linkedIn.title',
        defaultMessage: 'LinkedIn',
      },
    }),
    images: {
      logoColorSmall: null,
      footer: null,
    },
    url: 'https://www.linkedin.com/company/indorse/',
  },
};
