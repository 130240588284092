/*
  UserClaimSurveyModal
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withApollo } from 'react-apollo';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { connectModal as reduxModal } from 'redux-modal';
import { withRouter } from 'react-router';

import gql from 'graphql-tag';

import Alert from 'ui/common/alert';
import validator from 'ui/common/form/validator';
import SubmitButton from 'ui/common/form/submit-button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';

import RadioAndTextField from 'ui/common/form/fields/radio-and-text-field';
import fields, { fieldNames } from './model';

import * as actionTypes from 'store/graphql/UserClaimSurveyModal/action-types';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';

import routeTemplates from 'ui/common/routes/templates';

import * as smartRouteSelectors from 'store/features/smart-route/selectors';
import { selectIsSubmitting, selectError } from 'store/graphql/UserClaimSurveyModal/selectors';
import { selectUserCreatedClaim } from 'store/features/claims/selectors';
import { selectClaimIdFromFinishAssignment } from 'store/graphql/AssignmentDetails/selectors';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import UserClaimSurveyModalReducer from 'store/graphql/UserClaimSurveyModal/reducer';
import AssignmentDetailsReducer from 'store/graphql/AssignmentDetails/reducer';

import * as messages from './messages';
import boastClaimMessages from 'ui/claims/messages';
//
import { addMessage } from 'store/features/common/flash/actions';
import { messageIds } from 'store/features/common/flash/builder';

export const MODAL_NAME = 'graphql/UserClaimSurveyModal/modal';
export const FORM_NAME = 'user-claim-survey-modal';

const validate = validator(fields);

class UserClaimSurveyModal extends Component {
  static propTypes = {
    claimId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  async onSubmitSurvey(values) {
    const {
      client,
      claimId,
      sendSurveyStart,
      sendSurveySuccess,
      sendSurveyFailure,
      redirectRoute,
      push,
      canDispatchAddMessage,
    } = this.props;
    const { survey } = values;
    sendSurveyStart();

    try {
      const resultFromGraphQl = await client.mutate({
        mutation: gql`
          mutation sendSurveyResult($claimId: String!, $answer: String!) {
            sendSurveyResult(claimId: $claimId, answer: $answer)
          }
        `,
        variables: {
          claimId,
          answer: survey,
        },
      });

      const { errors } = resultFromGraphQl;

      if (resultHasErrors({ errors })) {
        sendSurveyFailure(errorsToSingleMessage({ errors }));
      } else {
        const { sendSurveyResult } = resultFromGraphQl.data;
        sendSurveySuccess({ success: sendSurveyResult });

        push(redirectRoute);

        // Hiding addMessage only for Assignment Claim due to redirect to thank you page

        if (!canDispatchAddMessage) {
          addMessage({
            id: messageIds.claims.createSuccess,
            kind: 'success',
            content: boastClaimMessages.created,
          });
        }
      }
    } catch (error) {
      sendSurveyFailure(error.message);
    }
  }

  onToggle() {
    const { push, handleHide, redirectRoute } = this.props;
    handleHide();
    push(redirectRoute);
  }

  render() {
    const { show, handleSubmit, submitting, errorMessage, intl: { formatMessage } } = this.props;

    const surveyOptions = [
      {
        name: formatMessage(messages.options.browseMoreJobs),
        value: 'Browse more jobs',
      },
      {
        name: formatMessage(messages.options.referred),
        value: 'Referred to by hiring manager/company',
      },
      {
        name: formatMessage(messages.options.trying),
        value: 'Trying out the platform',
      },
      {
        name: formatMessage(messages.options.others),
        value: 'others',
      },
    ];

    return (
      <Modal isOpen={show} size="lg">
        <form onSubmit={handleSubmit(this.onSubmitSurvey)}>
          <ModalHeader toggle={this.onToggle} className="text-center">
            {formatMessage(messages.header.title)}
          </ModalHeader>
          <ModalBody>
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <p>{formatMessage(messages.header.description)}</p>
            <div className="pt-3">
              <Field
                name={fieldNames.survey}
                component={RadioAndTextField}
                options={surveyOptions}
                multiLine
                characterCount
                characterCountMax={200}
                textFieldLabel={formatMessage(messages.labels.textFieldLabel)}
              />
            </div>
            {/*this.renderForm()*/}
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end">
            <SubmitButton submitting={submitting} formName={FORM_NAME} isReady={false}>
              {formatMessage(messages.button.submit)}
            </SubmitButton>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const claimId = selectUserCreatedClaim(state) || selectClaimIdFromFinishAssignment(state);

  // ID-1511 this is huge assumption for adding redirectRoute base on which claim is created
  const redirectRoute =
    (ownProps &&
      ownProps.match &&
      ownProps.match.path === routeTemplates.assignments.details &&
      routeTemplates.assignments.thanks) ||
    smartRouteSelectors.selectRouteAfterClaimCreation(state);

  const canDispatchAddMessage =
    ownProps && ownProps.match && ownProps.match.path === routeTemplates.assignments.details;

  return {
    claimId,
    errorMessage: selectError(state),
    submitting: selectIsSubmitting(state),
    canDispatchAddMessage: !!canDispatchAddMessage,
    redirectRoute,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendSurveyStart: () => dispatch({ type: actionTypes.USER_SEND_SURVEY.START }),
    sendSurveySuccess: success =>
      dispatch({ type: actionTypes.USER_SEND_SURVEY.SUCCESS, payload: { success } }),
    sendSurveyFailure: error =>
      dispatch({ type: actionTypes.USER_SEND_SURVEY.FAILURE, payload: { error } }),
    push: bindActionCreators(push, dispatch),
    addMessage: bindActionCreators(addMessage, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withUserClaimSurveyModalReducer = injectReducer({
  key: 'UserClaimSurveyModal',
  reducer: UserClaimSurveyModalReducer,
});

const withAssignmentDetailsReducer = injectReducer({
  key: 'AssignmentDetails',
  reducer: AssignmentDetailsReducer,
});

export default withRouter(
  compose(withUserClaimSurveyModalReducer, withAssignmentDetailsReducer, withConnect)(
    reduxModal({
      name: MODAL_NAME,
    })(
      reduxForm({
        form: FORM_NAME,
        validate,
      })(injectIntl(withApollo(UserClaimSurveyModal)))
    )
  )
);
