import { defineMessages } from 'react-intl';

export default defineMessages({
  register: {
    id: 'votes.messages.register',
    defaultMessage: 'Register',
  },
  registered: {
    id: 'votes.messages.registered',
    defaultMessage: 'Registered',
  },
  yourVote: {
    id: 'votes.messages.yourVote',
    defaultMessage: 'Your vote',
  },
  voteNow: {
    id: 'votes.messages.voteNow',
    defaultMessage: 'Vote now!',
  },
  endorse: {
    id: 'votes.messages.endorse',
    defaultMessage: 'Recommend',
  },
  endorsed: {
    id: 'votes.messages.recommended',
    defaultMessage: 'Recommended',
  },
  flag: {
    id: 'votes.messages.flag',
    defaultMessage: 'Not Recommend',
  },
  flagged: {
    id: 'votes.messages.notRecommended',
    defaultMessage: 'Not Recommended',
  },
  reported: {
    id: 'votes.messages.reported',
    defaultMessage: 'Reported',
  },
  registrationTimeRemaining: {
    id: 'votes.messages.registrationTimeRemaining',
    defaultMessage: '{relativeTime} remaining for registration',
  },
  votingTimeRemaining: {
    id: 'votes.messages.votingTimeRemaining',
    defaultMessage: '{relativeTime} remaining for voting',
  },
  confirmRegistration: {
    id: 'votes.messages.confirmRegistration',
    defaultMessage: 'Are you sure you want to register to verify claim "{title}"?',
  },
  registrationMissed: {
    id: 'votes.messages.registrationMissed',
    defaultMessage: 'Registration missed',
  },
  confirmVoteType: {
    id: 'votes.messages.confirmVoteType',
    defaultMessage:
      '<div class="lead pb-3">Are you sure you want to {voteType} this claim?</div> <div>You cannot change your vote once you click <b>Confirm</b> button below.</div>',
  },
  votingMissed: {
    id: 'votes.messages.votingMissed',
    defaultMessage: 'Voting missed',
  },
  transactionPending: {
    id: 'votes.messages.transactionPending',
    defaultMessage: 'Transaction pending',
  },
  transactionFailed: {
    id: 'votes.messages.transactionFailed',
    defaultMessage: 'Transaction failed',
  },
  completeYourVote: {
    id: 'votes.messages.completeYourVote',
    defaultMessage: 'Vote',
  },
  statusPendingTitle: {
    id: 'votes.messages.statusPendingTitle',
    defaultMessage: 'Claims Pending Your Vote',
  },
  statusCompleteTitle: {
    id: 'votes.messages.statusCompleteTitle',
    defaultMessage: 'Past Claims',
  },
  voteIsDone: {
    id: 'votes.messages.voteIsDone',
    defaultMessage: 'Vote is Done!',
  },
});
