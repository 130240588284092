import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  data: [],
  fetching: false,
  updating: false,
  error: false,
  companyDetails: null,
  hasOnboarded: null,
  hasCompletedTour: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_VERSION.START: {
      return state.set('fetching', true);
    }

    case actionTypes.FETCH_COMPANY_VERSION.FAILURE: {
      const { error } = action.payload;
      return state.set('fetching', false).set('error', error);
    }

    case actionTypes.FETCH_COMPANY_VERSION.SUCCESS: {
      const { version } = action.payload;
      return state.merge({
        fetching: false,
        error: false,
        data: version,
      });
    }

    case actionTypes.SET_GET_STARTED_STATUS.START: {
      return state.set('updating', true);
    }

    case actionTypes.SET_GET_STARTED_STATUS.FAILURE: {
      const { error } = action.payload;
      return state.set('updating', false).set('error', error);
    }

    case actionTypes.SET_GET_STARTED_STATUS.SUCCESS: {
      const { onboardStatus, getStartedStatus } = action.payload;
      return state.merge({
        updating: false,
        error: false,
        hasOnboarded: onboardStatus.type === 'ONBOARDING' && onboardStatus.status === 'DONE',
        hasCompletedTour:
          getStartedStatus.type === 'GET_STARTED_TOUR' && getStartedStatus.status === 'DONE',
      });
    }

    case actionTypes.GET_COMPANY_DETAILS.START: {
      return state.set('fetching', true);
    }

    case actionTypes.GET_COMPANY_DETAILS.FAILURE: {
      const { error } = action.payload;
      return state.set('fetching', false).set('error', error);
    }

    case actionTypes.GET_COMPANY_DETAILS.SUCCESS: {
      const { companyDetails } = action.payload;
      return state.merge({
        fetching: false,
        error: false,
        companyDetails,
      });
    }

    default:
      return state;
  }
}
