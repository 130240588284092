import { defineMessages } from 'react-intl';

const imagesPath = './images';

export default [
  {
    type: 'advisor',
    name: 'Benedict Chan',
    username: 'bencxr',
    position: defineMessages({
      member: {
        id: 'advisors.benedict-chan.position',
        defaultMessage: 'Director of Engineering, BitGo, Inc.',
      },
    }),
    bio: '',
    // bio:
    //   'Director of Engineering, BitGo, Inc., Creator of Ether.li (first ethereum multisig web wallet)',
    img: require(`${imagesPath}/benedict-chan-200x200.jpg`),
    indorseUrl: 'https://indorse.io/bencxr',
    linkedInUrl: 'https://www.linkedin.com/in/bencxr/',
    twitterUrl: 'https://twitter.com/bencxr',
  },
  {
    type: 'advisor',
    name: 'Loi Luu',
    username: 'loiluu',
    position: defineMessages({
      member: {
        id: 'advisors.loi-luu.position',
        defaultMessage: 'Founder and Developer, Kyber Network & SmartPool Project',
      },
    }),
    bio: '',
    // bio:
    //   'Founder and Developer for the SmartPool Project, Oyente, Developer, Ethereum Foundation Researcher, PhD Candidate in Computer Science at National University of Singapore',
    img: require(`${imagesPath}/loi-luu-200x200.jpg`),
    indorseUrl: 'https://indorse.io/loiluu',
    linkedInUrl: 'https://www.linkedin.com/in/loiluu',
    twitterUrl: 'https://twitter.com/loi_luu',
  },
  {
    type: 'advisor',
    name: 'Patrick McCorry',
    username: 'patrickmccorry',
    position: defineMessages({
      member: {
        id: 'advisors.patrick-maccory.position',
        defaultMessage: 'Research Associate (Blockchain), UCL',
      },
    }),
    bio: '',
    img: require(`${imagesPath}/patrick-maccory-200x200.jpg`),
    indorseUrl: 'https://indorse.io/patrickmccorry',
    linkedInUrl: 'https://www.linkedin.com/in/patrick-mccorry-923b2254/',
    twitterUrl: 'https://twitter.com/paddyncl',
  },
  {
    type: 'advisor',
    name: 'Eddy Travia',
    username: 'startupeddy',
    position: defineMessages({
      member: {
        id: 'advisors.eddy-trivia.position',
        defaultMessage: 'CEO, Coinsilium',
      },
    }),
    bio: '',
    // bio:
    //   'Pioneer investor in blockchain startups & CEO of Coinsilium, an accelerator that finances and manages the development of early-stage blockchain technology companies.',
    img: require(`${imagesPath}/eddy-travia-200x200.jpg`),
    indorseUrl: 'https://indorse.io/startupeddy',
    linkedInUrl: 'https://uk.linkedin.com/in/startupeddy',
    twitterUrl: 'https://twitter.com/startupeddy',
  },
  {
    type: 'advisor',
    name: 'Subhajit Mandal',
    username: 'subhajit',
    position: defineMessages({
      member: {
        id: 'advisors.subhajit-mandal.position',
        defaultMessage: 'Director, LumenLab',
      },
    }),
    bio: '',
    img: require(`${imagesPath}/subhajit-mandal.jpeg`),
    indorseUrl: 'https://indorse.io/subhajit',
    linkedInUrl: 'https://www.linkedin.com/in/mandalsubhajit/',
    twitterUrl: null,
  },
];
