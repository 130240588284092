import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import autoBind from 'react-autobind';
import MediaQuery from 'react-responsive';
import Slider from 'react-slick';
import Fade from 'react-reveal/Fade';

import medias from 'resources/page/home/media';
import partners from 'resources/page/home/partners';

import themeConfig from 'ui/theme/config';
import ExternalLink from 'ui/common/external-link';

import * as messages from './messages';
import styles from './index.module.scss';
import commonStyles from '../index.module.scss';

const knownSectionTypes = {
  partners: 'partners',
  medias: 'medias',
};

const defaultSliderSettings = {
  dots: true,
  infinite: true,
  speed: 1500,
  slidesToShow: 5,
  slidesToScroll: 5,
  arrows: false,
  autoplay: true,
  isResponsive: true,
  autoplaySpeed: 4500,
  cssEase: 'ease',
  responsive: [
    {
      breakpoint: themeConfig.layout.gridBreakpoints.md,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 2000,
        speed: 1000,
      },
    },
  ],
};

class MediaAndPartners extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderEntry(item) {
    const { name, url, imageUrl } = item;

    if (!imageUrl || !url) return null;

    return (
      <ExternalLink className={classnames(styles.imgWrapper)} href={url} openInNewTab>
        <img className="img-fluid mx-auto d-block" src={imageUrl} alt={name} />
      </ExternalLink>
    );
  }

  renderSectionForDesktop(sectionType) {
    return (
      <Slider {...defaultSliderSettings}>
        {sectionType === knownSectionTypes.medias &&
          medias.map((media, index) => {
            return (
              <div key={`media-${index}`} className={classnames('col-2 py-4')}>
                {this.renderEntry(media)}
              </div>
            );
          })}
        {sectionType === knownSectionTypes.partners &&
          partners.map((media, index) => {
            return (
              <div key={`media-${index}`} className={classnames('col-2 py-4')}>
                {this.renderEntry(media)}
              </div>
            );
          })}
      </Slider>
    );
  }

  renderSectionBatchwise(sectionType, start, end) {
    return (
      <div className="d-flex flex-wrap">
        {knownSectionTypes.medias === sectionType &&
          medias.slice(start, end).map((media, index) => {
            return (
              <div key={`media-sm-${index}}`} className={classnames('col-6 py-4')}>
                {this.renderEntry(media)}
              </div>
            );
          })}
        {knownSectionTypes.partners === sectionType &&
          partners.slice(start, end).map((media, index) => {
            return (
              <div key={`media-sm-${index}}`} className={classnames('col-6 py-4')}>
                {this.renderEntry(media)}
              </div>
            );
          })}
      </div>
    );
  }

  renderMediaForMobile() {
    const mediaSliderSetting = Object.assign({}, defaultSliderSettings);
    return (
      <div className={classnames('row')}>
        <div className="col">
          <Slider {...mediaSliderSetting}>
            {this.renderSectionBatchwise(knownSectionTypes.medias, 0, 6)}
            {this.renderSectionBatchwise(knownSectionTypes.medias, 6, 12)}
            {this.renderSectionBatchwise(knownSectionTypes.medias, 12, 18)}
          </Slider>
        </div>
      </div>
    );
  }

  renderPartnersForMobile() {
    const partnerSliderSetting = Object.assign({}, defaultSliderSettings);
    return (
      <div className={classnames('row')}>
        <div className="col">
          <Slider {...partnerSliderSetting}>
            {this.renderSectionBatchwise(knownSectionTypes.partners, 0, 6)}
            {this.renderSectionBatchwise(knownSectionTypes.partners, 6, 12)}
            {this.renderSectionBatchwise(knownSectionTypes.partners, 12, 18)}
            {this.renderSectionBatchwise(knownSectionTypes.partners, 18, 24)}
            {this.renderSectionBatchwise(knownSectionTypes.partners, 24, 30)}
          </Slider>
        </div>
      </div>
    );
  }

  renderMedia() {
    return (
      <div>
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.md}>
          {this.renderSectionForDesktop(knownSectionTypes.medias)}
        </MediaQuery>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.md - 1}>
          {this.renderMediaForMobile()}
        </MediaQuery>
      </div>
    );
  }

  renderPartners() {
    return (
      <div>
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.md}>
          {this.renderSectionForDesktop(knownSectionTypes.partners)}
        </MediaQuery>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.md - 1}>
          {this.renderPartnersForMobile()}
        </MediaQuery>
      </div>
    );
  }

  render() {
    // const { intl: { formatMessage }, showMediaOnly } = this.props;
    const { intl: { formatMessage } } = this.props;
    return (
      <Fade bottom duration={1600} distance="40px">
        <div
          className={classnames(
            commonStyles.secPb2,
            commonStyles.secPbM4,
            styles.overflowHidden,
            'container-fluid page-max-width media-and-partner'
          )}
        >
          <div>
            <div className={classnames('row')}>
              <div className={classnames('col-12')}>
                <h2 className={classnames('text-center')}>
                  {formatMessage(messages.headers.media)}
                </h2>
                <hr />
              </div>
            </div>
            <div className={classnames('row')}>
              <div className={classnames('col-12 pb-4')}>{this.renderMedia()}</div>
            </div>
          </div>
          {/*{!showMediaOnly && (*/}
          {/*  <div*/}
          {/*    className={classnames(*/}
          {/*      commonStyles.secPt2,*/}
          {/*      commonStyles.secPtM4,*/}
          {/*      styles.overflowHidden*/}
          {/*    )}*/}
          {/*  >*/}
          {/*    <div className={classnames('row')}>*/}
          {/*      <div className={classnames('col-12')}>*/}
          {/*        <h2 className={classnames('text-center')}>*/}
          {/*          {formatMessage(messages.headers.partners)}*/}
          {/*        </h2>*/}
          {/*        <hr />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className={classnames('row')}>*/}
          {/*      <div className={classnames('col-12 pb-4')}>{this.renderPartners()}</div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </Fade>
    );
  }
}

export default injectIntl(MediaAndPartners);
