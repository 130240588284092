import { defineMessages } from 'react-intl';

import { fieldNames, dateYearMonthFieldNames } from './model';

export const labels = defineMessages({
  [fieldNames.title]: {
    id: `work-experience.new.fields.title.label`,
    defaultMessage: 'Title',
  },
  [fieldNames.companyName]: {
    id: `work-experience.new.fields.companyName.label`,
    defaultMessage: 'Company',
  },
  [fieldNames.location]: {
    id: `work-experience.new.fields.location.label`,
    defaultMessage: 'Location',
  },
  [`${fieldNames.startDate}`]: {
    id: `work-experience.new.fields.startDate.label`,
    defaultMessage: 'From',
  },
  [`${fieldNames.startDate}.${dateYearMonthFieldNames.year}`]: {
    id: `work-experience.new.fields.startDate.year.label`,
    defaultMessage: 'Year',
  },
  [`${fieldNames.startDate}.${dateYearMonthFieldNames.month}`]: {
    id: `work-experience.new.fields.startDate.month.label`,
    defaultMessage: 'Month',
  },
  [`${fieldNames.endDate}`]: {
    id: `work-experience.new.fields.endDate.label`,
    defaultMessage: 'To',
  },
  [`${fieldNames.endDate}.${dateYearMonthFieldNames.year}`]: {
    id: `work-experience.new.fields.endDate.year.label`,
    defaultMessage: 'Year',
  },
  [`${fieldNames.endDate}.${dateYearMonthFieldNames.month}`]: {
    id: `work-experience.new.fields.endDate.month.label`,
    defaultMessage: 'Month',
  },
  [fieldNames.currentlyWorking]: {
    id: `work-experience.new.fields.currentlyWorking.label`,
    defaultMessage: 'Currently Working',
  },
  [fieldNames.description]: {
    id: `work-experience.new.fields.description.label`,
    defaultMessage: 'Description',
  },
});
