import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

function byId(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.FETCH_CLAIM.START:
      const { claimId: fetchingClaimId } = action.payload;
      const { background: fetchingInBackground } = action.meta || {};
      if (fetchingClaimId) {
        return state.merge(
          {
            [fetchingClaimId]: {
              fetching: true,
              fetchingInBackground,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_CLAIM.SUCCESS:
      const { claimId: fetchedClaimId } = action.payload;
      if (fetchedClaimId) {
        return state.merge(
          {
            [fetchedClaimId]: {
              fetching: false,
              lastFetchedAt: new Date().toISOString(),
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.CREATE_CLAIM.SUCCESS:
      const createdClaimId = action.payload && action.payload.claim && action.payload.claim;

      if (createdClaimId) {
        return state.merge(
          {
            [createdClaimId]: {
              fetching: false,
              lastFetchedAt: new Date().toISOString(),
            },
          },
          { deep: true }
        );
      }
      return state;
    default:
      return state;
  }
}

function groupedByUser(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_CLAIMS.START:
      const { userId: fetchingUserId } = action.payload;
      if (fetchingUserId) {
        return state.merge(
          {
            [fetchingUserId]: {
              fetching: true,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_USER_CLAIMS.SUCCESS:
      const { userId: fetchedUserId, result: { claims } } = action.payload;
      if (fetchedUserId) {
        return state.merge(
          {
            [fetchedUserId]: {
              fetching: false,
              lastFetchedAt: new Date().toISOString(),
              ids: (claims || []).map(e => e.claim),
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_USER_CLAIMS.FAILURE:
      const { userId: failedFetchUserId, error: fetchError } = action.payload;
      if (failedFetchUserId) {
        return state.merge(
          {
            [failedFetchUserId]: {
              fetching: false,
              ids: [],
              fetchError,
              lastFetchErrorAt: new Date().toISOString(),
            },
          },
          { deep: true }
        );
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  groupedByUser,
});
