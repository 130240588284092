import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const fetchCurrentUserVotes = {
  request: () => defineAction(types.FETCH_CURRENT_USER_VOTES.REQUEST),
  start: () => defineAction(types.FETCH_CURRENT_USER_VOTES.START),
  success: () => defineAction(types.FETCH_CURRENT_USER_VOTES.SUCCESS),
  failure: error => defineAction(types.FETCH_CURRENT_USER_VOTES.FAILURE, error),
};

export const registerToVote = {
  request: ({ voteId }) => defineAction(types.REGISTER_TO_VOTE.REQUEST, { voteId }),
  start: ({ voteId, claimId }) => defineAction(types.REGISTER_TO_VOTE.START, { voteId, claimId }),
  success: ({ voteId, claimId }) =>
    defineAction(types.REGISTER_TO_VOTE.SUCCESS, { voteId, claimId }),
  failure: error => defineAction(types.REGISTER_TO_VOTE.FAILURE, error),
};

export const fetchVote = {
  request: ({ voteId }) => defineAction(types.FETCH_VOTE.REQUEST, { voteId }),
  start: ({ voteId }) => defineAction(types.FETCH_VOTE.START, { voteId }),
  success: ({ voteId }) => defineAction(types.FETCH_VOTE.SUCCESS, { voteId }),
  failure: ({ voteId, error }) => defineAction(types.FETCH_VOTE.FAILURE, { voteId, error }),
};

export const endorse = {
  request: ({ voteId, skillId, feedback, timeTakenForFeedback, actionType }) =>
    defineAction(types.ENDORSE.REQUEST, {
      voteId,
      skillId,
      feedback,
      timeTakenForFeedback,
      actionType,
    }),
  start: ({ voteId, claimId, skillId, feedback, timeTakenForFeedback, actionType }) =>
    defineAction(types.ENDORSE.START, {
      voteId,
      claimId,
      skillId,
      feedback,
      timeTakenForFeedback,
      actionType,
    }),
  success: ({ voteId, claimId, votingToken, timeTakenForFeedback, actionType }) =>
    defineAction(types.ENDORSE.SUCCESS, {
      voteId,
      claimId,
      votingToken,
      timeTakenForFeedback,
      actionType,
    }),
  failure: ({ voteId, error }) => defineAction(types.ENDORSE.FAILURE, { voteId, error }),
};

export const flag = {
  request: ({ voteId, skillId, feedback, timeTakenForFeedback, flaggedReasonType, actionType }) =>
    defineAction(types.FLAG.REQUEST, {
      voteId,
      skillId,
      feedback,
      timeTakenForFeedback,
      actionType,
      flaggedReasonType, // ID-1862 Validators can flag codebase due to PLAGIARISM
    }),
  start: ({
    voteId,
    claimId,
    skillId,
    feedback,
    timeTakenForFeedback,
    flaggedReasonType,
    actionType,
  }) =>
    defineAction(types.FLAG.START, {
      voteId,
      claimId,
      skillId,
      feedback,
      timeTakenForFeedback,
      actionType,
      flaggedReasonType, // ID-1862 Validators can flag codebase due to PLAGIARISM
    }),
  success: ({
    voteId,
    claimId,
    votingToken,
    timeTakenForFeedback,
    flaggedReasonType,
    actionType,
  }) =>
    defineAction(types.FLAG.SUCCESS, {
      voteId,
      claimId,
      votingToken,
      timeTakenForFeedback,
      actionType, // ID-1869 need this to render loading spinner besides StartYourReview/ReportThisClaim buttons
      flaggedReasonType, // ID-1862 Validators can flag codebase due to PLAGIARISM
    }),
  failure: ({ voteId, error }) => defineAction(types.FLAG.FAILURE, { voteId, error }),
};

export const castVote = {
  request: ({ voteId, claimId, txHash }) =>
    defineAction(types.CAST.REQUEST, { voteId, claimId, txHash }),
  start: ({ voteId, claimId, txHash }) => defineAction(types.CAST.START, { voteId, claimId }),
  success: ({ voteId, claimId, txHash }) =>
    defineAction(types.CAST.SUCCESS, { voteId, claimId, txHash }),
  failure: error => defineAction(types.CAST.FAILURE, error),
  cancel: ({ voteId, claimId }) => defineAction(types.CAST.CANCEL, { voteId, claimId }),
};

export const checkStatus = {
  request: ({ voteId }) => defineAction(types.CHECK_STATUS.REQUEST, { voteId }),
  start: ({ voteId }) => defineAction(types.CHECK_STATUS.START, { voteId }),
  success: ({ voteId, endorsed }) => defineAction(types.CHECK_STATUS.SUCCESS, { voteId, endorsed }),
  failure: error => defineAction(types.CHECK_STATUS.FAILURE, error),
};
