import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import './styles.scss';

export class TextInput extends Component {
  handleChange = evt => {
    if (this.props.onChange) {
      this.props.onChange(evt, evt.currentTarget.value);
    }
  };

  render() {
    const {
      className,
      id,
      hideLabel,
      type,
      disabled,
      labelText,
      placeholder,
      prefix,
      suffix,
      hasError,
      errorMessage,
      useValue,
      wrapperClass,
      ...other
    } = this.props;

    const hasValue = other && other.value && !!other.value.toString().length;

    const errorId = id + '-error-msg';
    const inputClasses = classNames('text-input', className, {
      'is-valid': hasValue && !hasError,
      'is-invalid': hasError,
      'has-value': hasValue,
      'text-muted': disabled,
    });
    const itemClasses = classNames('form-item', {
      prefix: prefix,
      suffix: suffix,
    });
    const labelClasses = classNames('text-input-label', {
      'd-none': hideLabel,
      'has-placeholder': placeholder,
      'text-muted': disabled,
    });

    let textInputProps = {
      id: id,
      type: type || 'text',
      disabled: disabled,
    };

    const label = labelText ? (
      <label htmlFor={id} className={labelClasses}>
        {labelText}
      </label>
    ) : null;

    const error = hasError ? (
      <div>
        <div className="form-requirement" id={errorId} />
        {errorMessage}
      </div>
    ) : null;

    const textInput = (
      <input
        {...textInputProps}
        {...other}
        onChange={this.handleChange}
        className={inputClasses}
        placeholder={placeholder}
      />
    );

    return (
      <div className={wrapperClass}>
        <div className={itemClasses}>
          {textInput}
          {label}
        </div>
        {hasError && <span className="text-danger">{error}</span>}
      </div>
    );
  }
}

TextInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideLabel: PropTypes.bool,
  errorMessage: PropTypes.string,
  prefix: PropTypes.bool,
  suffix: PropTypes.bool,
  useValue: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

TextInput.defaultProps = {
  className: 'text-input',
  disabled: false,
  type: 'text',
  errorMessage: '',
  wrapperClass: 'py-2',
};

export default TextInput;
