import { defineMessages } from 'react-intl';

import { fieldNames } from './model';

export const labels = defineMessages({
  [fieldNames.ethereumAddress]: {
    id: `users.set-ethereum-address.fields.ethereumAddress.label`,
    defaultMessage: 'Ethereum address',
  },
});

export const content = defineMessages({
  addTitle: {
    id: 'users.set-ethereum-address.header.title.add',
    defaultMessage: 'Add Ethereum address',
  },
  updateTitle: {
    id: 'users.set-ethereum-address.header.title.update',
    defaultMessage: 'Update Ethereum address',
  },
  warning: {
    id: 'users.set-ethereum-address.warning',
    defaultMessage:
      'ETH address cannot be updated if it has been used for any transactions on Indorse',
  },
});

export const buttons = defineMessages({
  save: {
    id: 'users.set-ethereum-address.buttons.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'users.set-ethereum-address.buttons.cancel',
    defaultMessage: 'Cancel',
  },
});
