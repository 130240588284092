import React from 'react';
import styles from './index.module.scss';
import CHECK from 'ui/graphql/EmsBlanketTest/check.svg';
import { Card } from 'ui/common/card';
import classnames from 'classnames';

const FeatureCard = ({ feature, isSelected, onSelect }) => {
  return (
    <Card
      className={classnames(
        'py-5 px-auto',
        isSelected ? styles.featureCardSelected : styles.featureCard
      )}
      onClick={() => onSelect(feature.id)}
      style={{ height: '150px' }}
    >
      <img
        src={feature.image}
        className={classnames(styles.img, 'd-block mx-auto pt-3 mb-4')}
        width="75px"
        alt="ems-career-path"
      />
      <h3 className={classnames(styles.heading, 'text-center')}>{feature.heading}</h3>
      <div>
        <img
          className={classnames('position-absolute', isSelected ? styles.check : styles.unchecked)}
          src={CHECK}
          height="28px"
          width="28px"
          alt="checkbox"
        />
      </div>
    </Card>
  );
};

export default FeatureCard;
