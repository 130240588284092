import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { fieldPropTypes } from 'redux-form';

import SimpleSelectField from './simple-select-field';

const { input: inputPropTypes, ...restFieldPropTypes } = fieldPropTypes;
const { onDragStart, ...restInputPropTypes } = inputPropTypes;

function generateAvailableMonths(min = 1, max = 12, locale, value) {
  const result = [];
  const monthNames = moment.localeData(locale).months();
  for (let month = min; month <= max; month++) {
    result.push({
      value: month,
      text: monthNames[month - 1],
    });
  }
  // if (value && (value < min || value > max) && (value >= 1 && value <= 12)) {
  //   result.unshift({ value, text: monthNames[value - 1] });
  // }
  return result;
}

export default class MonthField extends React.Component {
  constructor(props) {
    super(props);

    const { min, max, locale, input: { value }, options } = props;

    this.state = {
      options: !options && min && max ? generateAvailableMonths(min, max, locale, value) : options,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { min, max, locale } = this.props;
    if (
      !nextProps.children &&
      nextProps.min &&
      nextProps.max &&
      nextProps.locale &&
      nextProps.input
    ) {
      if (nextProps.min !== min || nextProps.max !== max || nextProps.locale !== locale) {
        this.setState({
          options: generateAvailableMonths(
            nextProps.min,
            nextProps.max,
            nextProps.locale,
            nextProps.input.value
          ),
        });
      }
    }
  }

  render() {
    const { min, max, locale, ...rest } = this.props;

    return <SimpleSelectField options={this.state.options} {...rest} />;
  }
}

MonthField.propTypes = {
  ...restFieldPropTypes,
  input: PropTypes.shape(restInputPropTypes),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
};
