import Web3Facade from './web3-facade';
import { getWeb3Async, getInjectedWeb3Provider } from './utils';
import { networks } from 'resources/ethereum/networks';

const defaultNetworkName = process.env.REACT_APP_SC_NETWORK;

export function createInteractiveWeb3FacadeAsync(interactiveWeb3Provider) {
  return new Promise((resolve, reject) => {
    if (!interactiveWeb3Provider) reject(new Error('Ethereum Provider Not Passed'));
    getWeb3Async().then(Web3 => {
      const web3 = new Web3(interactiveWeb3Provider);
      resolve(new Web3Facade(web3));
    });
  });
}

export function createInteractiveWeb3FacadeFromInjectedProviderAsync() {
  return createInteractiveWeb3FacadeAsync(getInjectedWeb3Provider());
}

export function createNonInteractiveWeb3FacadeAsync(networkName = defaultNetworkName) {
  return new Promise((resolve, reject) => {
    const network = networks[networkName];
    if (!network) reject(new Error('Ethereum Network Not Found'));
    else {
      getWeb3Async().then(Web3 => {
        const provider = new Web3.providers.HttpProvider(network.httpProviderUrl);
        if (!provider) reject(new Error('Ethereum Provider Not Found'));

        const web3 = new Web3(provider);
        resolve(new Web3Facade(web3));
      });
    }
  });
}
