import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';

import * as companyActionTypes from './action-types';

const defaultPerPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50;

const listingInitialState = Immutable({
  ids: [],
  fetching: false,
  currentPage: null,
  perPage: defaultPerPage,
  totalCompanies: 0,
  sort: { field: '', order: null },
  matchingCompanies: 0,
});

function listing(state = listingInitialState, action) {
  switch (action.type) {
    case companyActionTypes.FETCH_COMPANIES.REQUEST:
      const { pageNo: fetchingPage, perPage: fetchingPerPage, sort: fetchingSort } = action.payload;
      return state.merge({
        ids: [],
        fetching: true,
        currentPage: fetchingPage,
        perPage: fetchingPerPage || defaultPerPage,
        totalCompanies: 0,
        sort: fetchingSort,
        matchingCompanies: 0,
      });
    case companyActionTypes.FETCH_COMPANIES.SUCCESS:
      const { pageNo: currentPage } = action.payload.input;
      const {
        companies: fetchedCompanyIds,
        totalCompanies,
        matchingCompanies,
      } = action.payload.result;

      return state.merge({
        ids: fetchedCompanyIds,
        fetching: false,
        currentPage,
        totalCompanies,
        matchingCompanies:
          typeof matchingCompanies === 'number' ? matchingCompanies : totalCompanies,
        error: null,
      });
    case companyActionTypes.FETCH_COMPANIES.FAILURE:
      return state.merge({
        ids: [],
        fetching: false,
      });
    case companyActionTypes.DELETE_COMPANY.SUCCESS:
      const idInListing = state.ids.indexOf(action.payload.prettyId) >= 0;
      if (!idInListing) return state;

      return state.merge(
        {
          ids: state.ids.filter(id => id !== action.payload.prettyId),
          totalCompanies: Math.max(0, state.totalCompanies - 1),
          matchingCompanies: Math.max(0, state.matchingCompanies - 1),
        },
        { deep: true }
      );
    default:
      return state;
  }
}

function byPrettyId(state = Immutable({}), action) {
  switch (action.type) {
    case companyActionTypes.DELETE_COMPANY.START:
      return state.merge(
        {
          [action.payload.prettyId]: {
            deleting: true,
          },
        },
        { deep: true }
      );
    case companyActionTypes.DELETE_COMPANY.SUCCESS:
      return state.merge(
        {
          [action.payload.prettyId]: {
            deleting: false,
          },
        },
        { deep: true }
      );
    case companyActionTypes.DELETE_COMPANY.FAILURE:
      return state.merge(
        {
          [action.payload.prettyId]: {
            deleting: false,
          },
        },
        { deep: true }
      );
    default:
      return state;
  }
}

export default combineReducers({
  listing,
  byPrettyId,
});
