/* eslint-disable react/style-prop-object */
import React, { Component } from 'react';
import classnames from 'classnames';
import { createNonInteractiveWeb3FacadeAsync } from 'ethereum/web3-facade-factory';
import { networkNames } from 'resources/ethereum/networks';
import { callApiJson } from 'api/call';
import { injectIntl, FormattedNumber } from 'react-intl';
import { INDORSE_TOKEN_ABI } from 'resources/ethereum/config';

const INDORSE_TOKEN_ADDRESS =
  `0xf8e386eda857484f5a12e4b5daa9984e06e73705` || process.env.REACT_APP_INDORSE_TOKEN_ADDRESS;
const cryptoCompareEndpoint = 'https://min-api.cryptocompare.com/data/price?fsym=IND&tsyms=USD';

export class UserIndorseToken extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indorseTokenBalance: null,
      indorseTokenConversionRateToUSD: null,
    };
  }

  componentDidMount() {
    this.getIndorseTokenBalance();
    this.getIndorseTokenToUSDConversionRate();
  }

  async getIndorseTokenToUSDConversionRate() {
    try {
      const response = await callApiJson({ endpoint: cryptoCompareEndpoint });
      const { uSD } = response;

      this.setState({
        indorseTokenConversionRateToUSD: uSD,
      });
    } catch (error) {}
  }

  async getIndorseTokenBalance() {
    const { userEthereumAddress } = this.props;

    try {
      const web3 = await createNonInteractiveWeb3FacadeAsync(networkNames.mainnet);
      const response = await web3.callContractAsync(
        INDORSE_TOKEN_ABI,
        INDORSE_TOKEN_ADDRESS,
        'balanceOf',
        userEthereumAddress
      );

      const indorseTokenBalance = web3.fromWei(response, 'ether').toNumber();

      this.setState({
        indorseTokenBalance: indorseTokenBalance,
      });
    } catch (error) {}
  }

  render() {
    const { className } = this.props;
    const { indorseTokenBalance, indorseTokenConversionRateToUSD } = this.state;
    const indorseTokenBalanceInUSD =
      typeof indorseTokenBalance === 'number' && typeof indorseTokenConversionRateToUSD === 'number'
        ? indorseTokenBalance * indorseTokenConversionRateToUSD
        : null;

    return (
      <div className={classnames(className, 'text-right')}>
        {typeof indorseTokenBalance === 'number' && (
          <div>
            <FormattedNumber
              value={indorseTokenBalance}
              minimumFractionDigits={2}
              maximumFractionDigits={2}
              style="decimal"
            />{' '}
            IND
          </div>
        )}
        {typeof indorseTokenBalanceInUSD === 'number' && (
          <div>
            <FormattedNumber
              value={indorseTokenBalanceInUSD}
              maximumFractionDigits={2}
              style="currency"
              currency="USD"
            />{' '}
            USD
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(UserIndorseToken);
