import { defineAction } from 'store/common/action-helpers';

import * as actionTypes from './action-types';

export const addWorkExperienceEntry = {
  request: (values, { form, modal, wizard }) =>
    defineAction(actionTypes.ADD_WORK_EXPERIENCE_ENTRY.REQUEST, values, { form, modal, wizard }),
  start: values => defineAction(actionTypes.ADD_WORK_EXPERIENCE_ENTRY.START, values),
  success: ({ workExperienceEntry }) =>
    defineAction(actionTypes.ADD_WORK_EXPERIENCE_ENTRY.SUCCESS, { workExperienceEntry }),
  failure: error => defineAction(actionTypes.ADD_WORK_EXPERIENCE_ENTRY.FAILURE, error),
};

export const updateWorkExperienceEntry = {
  request: (values, form, modal) =>
    defineAction(actionTypes.UPDATE_WORK_EXPERIENCE_ENTRY.REQUEST, values, { form, modal }),
  start: values => defineAction(actionTypes.UPDATE_WORK_EXPERIENCE_ENTRY.START, values),
  success: ({ workExperienceEntry }) =>
    defineAction(actionTypes.UPDATE_WORK_EXPERIENCE_ENTRY.SUCCESS, { workExperienceEntry }),
  failure: error => defineAction(actionTypes.UPDATE_WORK_EXPERIENCE_ENTRY.FAILURE, error),
};

export const deleteWorkExperienceEntry = {
  request: ({ id }, modal) =>
    defineAction(actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.REQUEST, { id }, { modal }),
  confirm: ({ id }, modal) =>
    defineAction(actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.CONFIRM, { id }, { modal }),
  start: ({ id }) => defineAction(actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.START, { id }),
  success: ({ id }) => defineAction(actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.SUCCESS, { id }),
  failure: error => defineAction(actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.FAILURE, error),
  cancel: ({ id }, modal) =>
    defineAction(actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.CANCEL, { id }, { modal }),
};
