import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/wizard';
import callApi from 'store/api/saga';

import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';

function* fetchWizardDefinition({ payload }) {
  const { name } = payload;
  yield put(actions.fetchWizardDefinition.start({ name }));
  try {
    const response = yield call(callApi, api.fetchWizardDefinition({ name }));
    const schema = schemas.wizardDefinition;
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchWizardDefinition.success({ name }));
  } catch (error) {
    yield put(actions.fetchWizardDefinition.failure({ name, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchWizardDefinination() {
  yield takeEvery(actionTypes.FETCH_WIZARD_DEFINITION.REQUEST, fetchWizardDefinition);
}

export function* fetchWizardStatus({ payload }) {
  const { name, apiConfig } = payload;
  yield put(actions.fetchWizardStatus.start({ name, apiConfig }));
  try {
    const response = yield call(callApi, apiConfig);
    yield put(actions.fetchWizardStatus.success({ name, response }));
  } catch (error) {
    yield put(actions.fetchWizardStatus.failure({ name, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchWizardStatus() {
  yield takeEvery(actionTypes.FETCH_WIZARD_STATUS.REQUEST, fetchWizardStatus);
}

function* completeStep({ payload, meta = {} }) {
  const { name, apiConfig } = payload;
  const { afterCompleteRoute } = meta;
  yield put(actions.completeStep.start({ name, apiConfig }));
  try {
    const response = yield call(callApi, apiConfig);
    response.currentStep = response.failed ? response.currentStep : response.nextStep;
    delete response.nextStep;
    yield put(actions.completeStep.success({ name, response }));
    if (response.wizardFinished) {
      yield put(actions.wizardFinished({ name, afterCompleteRoute }));
    }
  } catch (error) {
    yield put(actions.completeStep.failure({ name, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchCompleteStep() {
  yield takeEvery(actionTypes.COMPLETE_STEP.REQUEST, completeStep);
}

function* skipStep({ payload, meta = {} }) {
  const { name, apiConfig } = payload;
  const { afterCompleteRoute } = meta;
  yield put(actions.skipStep.start({ name, apiConfig }));
  try {
    const response = yield call(callApi, apiConfig);
    response.currentStep = response.failed ? response.currentStep : response.nextStep;
    delete response.nextStep;
    yield put(actions.skipStep.success({ name, response }));
    if (response.wizardFinished) {
      yield put(actions.wizardFinished({ name, afterCompleteRoute }));
    }
  } catch (error) {
    yield put(actions.skipStep.failure({ name, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchSkipStep() {
  yield takeEvery(actionTypes.SKIP_STEP.REQUEST, skipStep);
}

function* finishWizard({ payload, meta = {} }) {
  const { name, apiConfig } = payload;
  const { afterCompleteRoute } = meta;
  yield put(actions.finishWizard.start({ name, apiConfig }));
  try {
    const response = yield call(callApi, apiConfig);
    yield put(actions.finishWizard.success({ name, response }));
    if (response.wizardFinished) {
      yield put(actions.wizardFinished({ name, afterCompleteRoute }));
    }
  } catch (error) {
    yield put(actions.finishWizard.failure({ name, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchExitWizard() {
  yield takeEvery(actionTypes.FINISH_WIZARD.REQUEST, finishWizard);
}

export default function* wizardSaga() {
  yield fork(watchFetchWizardDefinination);
  yield fork(watchFetchWizardStatus);
  yield fork(watchCompleteStep);
  yield fork(watchSkipStep);
  yield fork(watchExitWizard);
}
