import moment from 'moment';

import { compareNumber } from './number';

export function presentDateInYearAndMonth() {
  const now = new Date();
  return { year: now.getFullYear(), month: now.getMonth() + 1 };
}

export function ensureDateMonth(date) {
  if (date && date.year) {
    let month = date.month || 1;
    month = Math.max(1, Math.min(month, 12));
    if (month === date.month) return date;
    return { ...date, month };
  }
  return date;
}

export function compareDateByYearAndMonth(left, right) {
  if (!left || !right) {
    if (left) return 1;
    if (right) return -1;
    return 0;
  }

  const yearComparison = compareNumber(left.year, right.year);
  if (yearComparison !== 0) return yearComparison;

  // Years are same. Compare months now
  const monthComparison = compareNumber(left.month, right.month);
  if (monthComparison !== 0) return monthComparison;

  return 0;
}

export function minDateByYearAndMonth(left, right, noFalseyCompare = true) {
  if (noFalseyCompare && !left) return right;
  if (noFalseyCompare && !right) return left;
  const comparison = compareDateByYearAndMonth(left, right);
  if (comparison <= 0) return left;
  return right;
}

export function maxDateByYearAndMonth(left, right, noFalseyCompare = true) {
  if (noFalseyCompare && !left) return right;
  if (noFalseyCompare && !right) return left;
  const comparison = compareDateByYearAndMonth(left, right);
  if (comparison < 0) return right;
  return left;
}

export function compareByStartDateThenEndDate(left, right) {
  const leftStartDate = ensureDateMonth(left && left.startDate);
  const rightStartDate = ensureDateMonth(right && right.startDate);
  const startDateComparison = compareDateByYearAndMonth(leftStartDate, rightStartDate);
  if (startDateComparison !== 0) return startDateComparison;

  // Start dates are same, compare by end date
  const leftEndDate = left
    ? ensureDateMonth(left.endDate) || presentDateInYearAndMonth()
    : undefined;
  const rightEndDate = right
    ? ensureDateMonth(right.endDate) || presentDateInYearAndMonth()
    : undefined;
  return compareDateByYearAndMonth(leftEndDate, rightEndDate);
}

export function formatEntryDateLongYearShortMonth(date, locale = 'en') {
  if (!date) return '';

  const localeData = moment.localeData(locale);
  const monthNames = localeData.monthsShort();

  return `${monthNames[date.month - 1]} ${date.year}`;
}

export function formatEntryDateShortYearShortMonth(date, locale = 'en') {
  if (!date) return '';

  const localeData = moment.localeData(locale);
  const monthNames = localeData.monthsShort();

  return `${monthNames[date.month - 1]} ${date.year ? date.year.toString().substr(-2) : ''}`;
}
