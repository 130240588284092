import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import { selectAllEntities, selectClaimEntities } from 'store/entities/selectors';
import * as authSelectors from 'store/features/auth/selectors';
import * as helpers from './helpers';
import * as voteHelpers from 'store/features/votes/helpers';

export const selectClaimsFeatureState = state => state.features.claims;

export const selectUserClaims = createSelector(
  selectAllEntities,
  selectClaimEntities,
  (state, props) => props,
  (entities, claimsById, { userId, aipSkills }) =>
    denormalize(Object.keys(claimsById), [schemas.claim], entities)
      .filter(c => c.owner && c.owner.id === userId)
      .map(c => ({
        ...c,
        skill:
          c.skills && Array.isArray(c.skills) && c.skills.length > 0
            ? c.skills[0].skill
            : helpers.calculateClaimSkillInfo(c, aipSkills),
        level: helpers.calculateClaimLevelInfo(c),
        status: helpers.calculateClaimStatus(c),
      }))
);

export const selectIsFetchingUserClaims = createSelector(
  selectClaimsFeatureState,
  (state, props) => props.userId,
  (claimsFeatureState, userId) => {
    const userData = claimsFeatureState.groupedByUser && claimsFeatureState.groupedByUser[userId];
    return userData && userData.fetching;
  }
);

export const selectUserClaimsAtleastOnce = createSelector(
  selectClaimsFeatureState,
  (state, props) => props.userId,
  (claimsFeatureState, userId) => {
    const userData = claimsFeatureState.groupedByUser && claimsFeatureState.groupedByUser[userId];
    return userData && !!userData.lastFetchedAt;
  }
);

export const selectClaimById = createSelector(
  selectAllEntities,
  (state, props) => props,
  (entities, { id, aipSkills }) => {
    const claim = denormalize(id, schemas.claim, entities);
    if (claim) {
      const result = {
        ...claim,
        statusFromBE: claim.status,
        skill:
          claim.skills && Array.isArray(claim.skills) && claim.skills.length > 0
            ? claim.skills[0].skill
            : helpers.calculateClaimSkillInfo(claim, aipSkills),
        level: helpers.calculateClaimLevelInfo(claim),
        status: helpers.calculateClaimStatus(claim),
      };
      if (claim.vote)
        result.vote = { ...claim.vote, status: voteHelpers.calculateVoteStatus(claim.vote) };
      return result;
    }
    return claim;
  }
);

export const selectIsFetchingClaim = createSelector(
  selectClaimsFeatureState,
  (state, props) => props.id,
  (claimsFeatureState, claimId) =>
    claimsFeatureState.byId[claimId] && claimsFeatureState.byId[claimId].fetching
);

export const selectIsFetchingClaimInBackground = createSelector(
  selectClaimsFeatureState,
  (state, props) => props.id,
  (claimsFeatureState, claimId) =>
    claimsFeatureState.byId[claimId] &&
    claimsFeatureState.byId[claimId].fetching &&
    claimsFeatureState.byId[claimId].fetchingInBackground
);

export const selectClaimFetchedAtleastOnce = createSelector(
  selectClaimsFeatureState,
  (state, props) => props.id,
  (claimsFeatureState, claimId) =>
    claimsFeatureState.byId[claimId] && !!claimsFeatureState.byId[claimId].lastFetchedAt
);

export const selectIsCurrentUsersClaimById = createSelector(
  selectClaimEntities,
  (state, props) => props.id,
  authSelectors.selectCurrentUserId,
  (claimsState, id, currentUserId) => {
    const claim = claimsState[id];
    return (
      claim &&
      claim.owner &&
      (typeof claim.owner === 'object'
        ? claim.owner.id === currentUserId
        : claim.owner === currentUserId)
    );
  }
);

export const selectCanCurrentUserVoteOnClaimById = createSelector(
  selectAllEntities,
  (state, props) => props.id,
  authSelectors.selectCurrentUserId,
  (entities, id, currentUserId) => {
    const claim = denormalize(id, schemas.claim, entities);
    return claim && claim.vote && claim.vote.voter && claim.vote.voter.id === currentUserId;
  }
);

export const selectUserClaimsWithDuplicatesForMultiSkillAssignments = createSelector(
  selectUserClaims,
  uniqueUserClaims => {
    const result = [];

    (uniqueUserClaims || []).forEach(claim => {
      if (!claim.type || claim.type !== 'ASSIGNMENT') {
        result.push(claim);
      } else {
        const { userAssignment } = claim;
        if (userAssignment) {
          userAssignment.skills.forEach(skillItem => {
            const claimCopy = {
              ...claim,
              skill: skillItem.skill,
              level: helpers.calculateClaimLevelInfo(skillItem),
              status:
                claim.status === helpers.STATUSES.endorsed
                  ? skillItem.validated ? helpers.STATUSES.endorsed : helpers.STATUSES.flagged
                  : claim.status,
            };
            result.push(claimCopy);
          });
        } else {
          result.push(claim);
        }
      }
    });

    return result;
  }
);

export const selectUserCreatedClaim = createSelector(
  selectClaimsFeatureState,
  (state, props) => state.byId && Object.keys(state.byId)[0]
);
