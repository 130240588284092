import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { startSubmit, stopSubmit } from 'redux-form';
import { push } from 'react-router-redux';

import * as schemas from 'store/common/schemas';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as entityActions from 'store/entities/actions';
import * as api from 'api/config/badges';
import callApi from 'store/api/saga';
import routeTemplates from 'ui/common/routes/templates';
import { addMessage } from 'store/features/common/flash/actions';
import { buildMessage, messageTypes } from 'store/features/common/flash/builder';
import { isFile, convertToDataURL } from 'utils/file';
import messages from 'ui/admin/badges/messages';
import { confirmSaga as confirm } from 'store/features/common/confirmation-dialog/saga';
import { parseErrorsForAssigments } from './helpers';

function* fetchBadges({ payload }) {
  yield put(actions.fetchBadges.start(payload));
  try {
    const response = yield call(callApi, api.fetchBadges(payload));

    if (response && response.badges) {
      response.badges.forEach(badge => {
        if (!badge.prettyId) {
          badge['prettyId'] = badge.id;
        }
      });
      const schema = { badges: [schemas.badge] };
      const { entities, result } = normalize(response, schema);
      yield put(entityActions.mergeEntities(entities));
      yield put(actions.fetchBadges.success({ input: payload, result }));
    }
  } catch (error) {
    yield put(actions.fetchBadges.failure({ input: payload, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchBadges() {
  yield takeEvery(actionTypes.FETCH_BADGES.REQUEST, fetchBadges);
}

function* deleteBadge({ payload }) {
  const { prettyId } = payload;

  const confirmationMessage = {
    ...messages.confirmDelete,
    values: { prettyId },
  };

  const confirmed = yield call(confirm, { message: confirmationMessage });
  if (!confirmed) {
    return;
  }

  yield put(actions.deleteBadge.start({ prettyId }));

  try {
    yield call(callApi, api.deleteBadge({ prettyId }));
    yield put(actions.deleteBadge.success({ prettyId }));
  } catch (error) {
    yield put(actions.deleteBadge.failure({ prettyId, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchDeleteBadge() {
  yield takeEvery(actionTypes.DELETE_BADGE.REQUEST, deleteBadge);
}

function* convertImagesToDataUrls(values) {
  const { logo, admin, ...newValues } = values;
  if (logo && isFile(logo)) {
    newValues.logoData = yield call(convertToDataURL, logo);
  }
  return newValues;
}

function* createBadge({ payload: values, meta }) {
  const form = meta && meta.form;
  yield put(actions.createBadge.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const finalValues = yield call(convertImagesToDataUrls, values);

    const response = yield call(callApi, api.createBadge(finalValues));
    if (form) yield put(stopSubmit(form));

    if (response && !response.prettyId) {
      response['prettyId'] = response.id;
    }
    const schema = schemas.badge;
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.createBadge.success(result));

    yield put(
      push(routeTemplates.admin.badges.root, {
        flash: buildMessage({ kind: messageTypes.success, content: messages.created }),
      })
    );
  } catch (error) {
    yield put(actions.createBadge.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchCreateBadge() {
  yield takeEvery(actionTypes.CREATE_BADGE.REQUEST, createBadge);
}

function* updateBadge({ payload: { prettyId, values }, meta }) {
  const form = meta && meta.form;
  yield put(actions.updateBadge.start({ prettyId, values }));
  if (form) yield put(startSubmit(form));

  try {
    const finalValues = yield call(convertImagesToDataUrls, values);

    const response = yield call(callApi, api.updateBadge({ prettyId, values: finalValues }));
    if (form) yield put(stopSubmit(form));
    // TODO- remove once we have prettyId in reponse
    if (response && !response.prettyId) {
      response['prettyId'] = response.id;
    }
    const schema = schemas.badge;
    const { entities, result } = normalize(response, schema);
    if (prettyId !== finalValues.prettyId)
      yield put(entityActions.removeEntities({ [schema.key]: [prettyId] }));
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.updateBadge.success(result));

    yield put(
      push(routeTemplates.admin.badges.root, {
        flash: buildMessage({ kind: messageTypes.success, content: messages.updated }),
      })
    );
  } catch (error) {
    yield put(actions.updateBadge.failure({ prettyId, error }));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchUpdateBadge() {
  yield takeEvery(actionTypes.UPDATE_BADGE.REQUEST, updateBadge);
}

function* assignBadges({ payload: { values }, meta }) {
  const form = meta && meta.form;
  const { badges, emails, assign } = values;
  const commaSeparatedEmails =
    (emails &&
      emails
        .split(',')
        .map(entry => entry.trim())
        .filter(Boolean)) ||
    [];
  const newAssign = assign && parseInt(assign, 10);
  const finalValues = { badges, emails: commaSeparatedEmails, assign: newAssign };
  const confirmAssignMessage = {
    ...messages.confirmAssign,
    values: finalValues,
  };
  const confirmUnassignMessage = {
    ...messages.confirmUnassign,
    values: finalValues,
  };

  const confirmed = yield call(confirm, {
    message: newAssign === 1 ? confirmAssignMessage : confirmUnassignMessage,
  });
  if (!confirmed) {
    return;
  }

  yield put(actions.assignBadges.start({ values: finalValues }));
  if (form) yield put(startSubmit(form));
  try {
    const response = yield call(callApi, api.assignBadges(finalValues));
    if (form) yield put(stopSubmit(form));
    yield put(actions.assignBadges.success(response));
    yield put(
      push(routeTemplates.admin.badges.root, {
        flash: buildMessage({
          kind: messageTypes.success,
          content: newAssign === 1 ? messages.assign : messages.unassign,
        }),
      })
    );
  } catch (error) {
    yield put(actions.assignBadges.failure(error));
    const _error = parseErrorsForAssigments(error);
    if (form) yield put(stopSubmit(form, { _error }));
  }
}

function* watchAssignBadges() {
  yield takeEvery(actionTypes.ASSIGN_BADGES.REQUEST, assignBadges);
}

export default function* badges() {
  yield fork(watchFetchBadges);
  yield fork(watchCreateBadge);
  yield fork(watchDeleteBadge);
  yield fork(watchUpdateBadge);
  yield fork(watchAssignBadges);
}
