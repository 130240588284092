import { call, fork, put, takeEvery, select, race, take } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { push } from 'react-router-redux';
import { show, hide } from 'redux-modal';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as selectors from './selectors';
import * as api from 'api/config/votes';
import callApi from 'store/api/saga';
import * as flashActions from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import votesMessages from 'ui/votes/messages';
import { confirmSaga as confirm } from 'store/features/common/confirmation-dialog/saga';
import routeGenerators from 'ui/common/routes/generators';
import {
  selectCurrentUserId,
  selectCurrentUserEthereumAddress,
} from 'store/features/auth/selectors';
import { SET_ETHEREUM_ADDRESS } from 'store/features/users/action-types';
import { UPDATE_TRIGGER_CASTING } from 'store/graphql/SubmitVoteButton/action-types';
import { STATUSES as VOTE_STATUSES } from 'store/features/votes/helpers';

import { MODAL_NAME as SET_ETHEREUM_ADDRESS_MODAL_NAME } from 'ui/users/set-eth-address/index';

const MODAL_HIDE = '@redux-modal/HIDE';

function* fetchCurrentUserVotes({ payload }) {
  const isFetching = yield select(selectors.selectCurrentUserVotesFetching);
  if (isFetching) return;

  yield put(actions.fetchCurrentUserVotes.start());

  try {
    const response = yield call(callApi, api.fetchCurrentUserVotes());
    const schema = {
      results: [
        {
          claim: schemas.claim,
          vote: schemas.vote,
          votingRound: schemas.votingRound,
        },
      ],
    };
    const { entities, result } = normalize(response, schema);

    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchCurrentUserVotes.success());

    if (result && result.results && result.results.length > 0) {
      for (let i = 0; i <= result.results.length; i++) {
        const voteId = result.results[i].vote;
        const vote = yield select(selectors.selectVoteById, { id: voteId });
        if (vote.status === VOTE_STATUSES.requiresContractCheck)
          yield put(actions.checkStatus.request({ voteId }));
      }
    }
  } catch (error) {
    yield put(actions.fetchCurrentUserVotes.failure(error));
  }
}

function* watchFetchCurrentUserVotes() {
  yield takeEvery(actionTypes.FETCH_CURRENT_USER_VOTES.REQUEST, fetchCurrentUserVotes);
}

function* fetchVote({ payload }) {
  const { voteId } = payload;

  yield put(actions.fetchVote.start());

  try {
    const response = yield call(callApi, api.fetchVote({ voteId }));
    const schema = schemas.vote;
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchVote.success({ voteId }));
  } catch (error) {
    yield put(actions.fetchCurrentUserVotes.failure({ voteId, error }));
  }
}

function* watchFetchVote() {
  yield takeEvery(actionTypes.FETCH_VOTE.REQUEST, fetchVote);
}

function* registerToVote({ payload }) {
  const { voteId } = payload;

  const isRegistering = yield select(selectors.selectIsRegisteringToVote, { id: voteId });
  if (isRegistering) return;

  const vote = yield select(selectors.selectVoteById, { id: voteId });
  const claim = vote.claim;
  const confirmationMessage = {
    ...votesMessages.confirmRegistration,
    values: { title: claim.title },
  };
  const confirmed = yield call(confirm, { message: confirmationMessage });
  if (!confirmed) {
    return;
  }

  const claimId = claim.id;
  yield put(actions.registerToVote.start({ voteId, claimId }));

  try {
    yield call(callApi, api.registerToVote({ claimId }));
    yield put(actions.registerToVote.success({ voteId, claimId }));
  } catch (error) {
    yield put(actions.registerToVote.failure(error));
    if (error && error.message)
      yield put(flashActions.addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchRegisterToVote() {
  yield takeEvery(actionTypes.REGISTER_TO_VOTE.REQUEST, registerToVote);
}

function* ensureEthAddress() {
  const currentUserId = yield select(selectCurrentUserId);
  const currentUserEthereumAddress = yield select(selectCurrentUserEthereumAddress);

  if (currentUserId && !currentUserEthereumAddress) {
    yield put(show(SET_ETHEREUM_ADDRESS_MODAL_NAME));
    const { ethAddressSet } = yield race({
      ethAddressSet: take(
        action =>
          action &&
          action.type === SET_ETHEREUM_ADDRESS.SUCCESS &&
          action.payload &&
          action.payload.userId === currentUserId
      ),
      ethAddressModalClosed: take(
        action =>
          action &&
          action.type === MODAL_HIDE &&
          action.payload &&
          action.payload.modal === SET_ETHEREUM_ADDRESS_MODAL_NAME
      ),
    });
    // Safety net
    yield put(hide(SET_ETHEREUM_ADDRESS_MODAL_NAME));
    return ethAddressSet;
  }

  return true;
}

function* ensureVoteType({ vote, endorse }) {
  const confirmationMessage = {
    ...votesMessages.confirmVoteType,
    values: {
      title: vote.claim.title,
      voteType: endorse
        ? votesMessages.endorse.defaultMessage.toLowerCase()
        : votesMessages.flag.defaultMessage.toLowerCase(),
    },
  };
  const confirmed = yield call(confirm, { message: confirmationMessage });
  return confirmed;
}

function* endorse({ payload }) {
  const { voteId, skillId, feedback, timeTakenForFeedback, actionType } = payload;
  const isVoting = yield select(selectors.selectIsVoting, { id: voteId });
  if (isVoting) return;

  if (!(yield call(ensureEthAddress))) return;

  const vote = yield select(selectors.selectVoteById, { id: voteId });
  const claimId = vote.claim.id;

  if (!vote.endorsed && !(yield call(ensureVoteType, { vote, endorse: true }))) return;
  yield put(
    actions.endorse.start({ voteId, claimId, skillId, feedback, timeTakenForFeedback, actionType })
  );
  try {
    const response = yield call(
      callApi,
      api.endorse({ claimId, voteId, skillId, feedback, timeTakenForFeedback })
    );
    yield put(actions.endorse.success({ voteId, claimId, votingToken: response.votingToken }));

    // ID-1524: Using EIP-712 signature
    yield put({
      type: UPDATE_TRIGGER_CASTING,
      payload: {
        decision: 'YES',
        voteId,
        actionType, // normalReview or reportClaim
      },
    });
  } catch (error) {
    yield put(actions.endorse.failure({ voteId, error }));
    if (error && error.message)
      yield put(flashActions.addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchEndorse() {
  yield takeEvery(actionTypes.ENDORSE.REQUEST, endorse);
}

function* flag({ payload }) {
  const {
    voteId,
    skillId,
    feedback,
    timeTakenForFeedback,
    flaggedReasonType,
    actionType,
  } = payload;
  const isVoting = yield select(selectors.selectIsVoting, { id: voteId });
  if (isVoting) return;

  if (!(yield call(ensureEthAddress))) return;

  const vote = yield select(selectors.selectVoteById, { id: voteId });
  const claimId = vote.claim.id;

  if (!vote.flagged && !(yield call(ensureVoteType, { vote, endorse: false }))) return;
  yield put(
    actions.flag.start({
      voteId,
      claimId,
      skillId,
      feedback,
      timeTakenForFeedback,
      flaggedReasonType,
      actionType,
    })
  );

  try {
    const response = yield call(
      callApi,
      api.flag({ claimId, voteId, skillId, feedback, timeTakenForFeedback, flaggedReasonType })
    );
    yield put(actions.flag.success({ voteId, claimId, votingToken: response.votingToken }));

    // ID-1524: Using EIP-712 signature
    yield put({
      type: UPDATE_TRIGGER_CASTING,
      payload: {
        decision: 'NO',
        voteId,
        flaggedReasonType,
        actionType,
      },
    });
  } catch (error) {
    yield put(actions.flag.failure({ voteId, error }));
    if (error && error.message)
      yield put(flashActions.addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFlag() {
  yield takeEvery(actionTypes.FLAG.REQUEST, flag);
}

function* castVote({ payload }) {
  const { voteId, claimId, txHash } = payload;

  yield put(actions.castVote.start({ voteId, claimId, txHash }));

  try {
    yield put(push(routeGenerators.claims.details({ id: claimId })));

    const response = yield call(callApi, api.submitTransactionInfo({ voteId, claimId, txHash }));
    const schema = schemas.vote;
    const { entities } = normalize(response, schema);
    const mergableEntities = { [schema.key]: entities[schema.key] };
    yield put(entityActions.mergeEntities(mergableEntities));
    yield put(actions.castVote.success({ voteId, claimId, txHash }));

    yield put(actions.checkStatus.request({ voteId }));
  } catch (error) {
    yield put(actions.castVote.failure(error.message));
  }
}

function* watchCastVote() {
  yield takeEvery(actionTypes.CAST.REQUEST, castVote);
}

function* cancelCastVote({ payload }) {
  const { claimId } = payload;

  yield put(push(routeGenerators.claims.details({ id: claimId })));
}

function* watchCancelCastVote() {
  yield takeEvery(actionTypes.CAST.CANCEL, cancelCastVote);
}

export default function* votes() {
  yield fork(watchFetchCurrentUserVotes);
  yield fork(watchFetchVote);
  yield fork(watchRegisterToVote);
  yield fork(watchEndorse);
  yield fork(watchFlag);
  yield fork(watchCastVote);
  yield fork(watchCancelCastVote);
}
