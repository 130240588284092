import React from 'react';
import V2TextInput from 'ui/common/rebranding/components/TextInput';
import V2TextArea from 'ui/common/rebranding/components/TextArea/TextArea';

export default function TextInput({
  children,
  className,
  input,
  label,
  description,
  hint,
  readOnly,
  onChange,
  handleSubmit,
  hidden,
  surroundInput = false,
  rows,
  multiLine,
  meta: { form, touched, submitting, error },
  characterCount, // boolean
  characterCountMax, // integer
  ...passThrough
}) {
  const inputId = `${form}-${input.name}`;
  const value = input.value;
  const hasError = (touched || submitting) && value !== error && !!error;
  const errorText = Array.isArray(error) ? error.join('\n') : error;

  if (hidden) return null;

  let component;
  if (multiLine) {
    component = (
      <V2TextArea
        {...input}
        {...passThrough}
        className={className}
        id={inputId}
        placeholder={hint}
        labelText={label}
        defaultValue={value}
        disabled={readOnly}
        errorMessage={errorText}
        hasError={hasError}
        rows={rows}
        characterCount={characterCount}
        characterCountMax={characterCountMax}
      />
    );
  } else {
    component = (
      <V2TextInput
        {...input}
        {...passThrough}
        className={className}
        id={inputId}
        disabled={readOnly}
        labelText={label}
        placeholder={hint}
        errorMessage={errorText}
        hasError={hasError}
      />
    );
  }
  return component;
}
