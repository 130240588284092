import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/companies';
import callApi from 'store/api/saga';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';

function* fetchCompanyByPrettyId({ payload, meta = {} }) {
  const { prettyId } = payload;
  const { background = false } = meta;
  yield put(actions.fetchCompanyByPrettyId.start({ prettyId }, { background }));

  try {
    const response = yield call(callApi, api.fetchCompanyByPrettyId({ prettyId }));

    const schema = schemas.company;
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchCompanyByPrettyId.success({ prettyId }));
  } catch (error) {
    yield put(actions.fetchCompanyByPrettyId.failure({ prettyId, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchCompanyByPrettyId() {
  yield takeEvery(actionTypes.FETCH_COMPANY_BY_PRETTY_ID.REQUEST, fetchCompanyByPrettyId);
}

function* fetchCompanyTeamMembersByPrettyId({ payload }) {
  const { prettyId } = payload;
  yield put(actions.fetchCompanyTeamMembersByPrettyId.start({ prettyId }));

  try {
    const response = yield call(callApi, api.fetchCompanyTeamMembersByPrettyId({ prettyId }));
    const schema = [schemas.companyTeamMemberConnection];
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(
      actions.fetchCompanyTeamMembersByPrettyId.success({ prettyId, teamMemberIds: result })
    );
  } catch (error) {
    yield put(actions.fetchCompanyTeamMembersByPrettyId.failure({ prettyId, error }));
  }
}

function* watchFetchCompanyTeamMembersByPrettyId() {
  yield takeEvery(
    actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.REQUEST,
    fetchCompanyTeamMembersByPrettyId
  );
}

function* fetchCompanyAdvisorsByPrettyId({ payload }) {
  const { prettyId } = payload;
  yield put(actions.fetchCompanyAdvisorsByPrettyId.start({ prettyId }));

  try {
    const response = yield call(callApi, api.fetchCompanyAdvisorsByPrettyId({ prettyId }));
    const schema = [schemas.companyAdvisorConnection];
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchCompanyAdvisorsByPrettyId.success({ prettyId, advisorIds: result }));
  } catch (error) {
    yield put(actions.fetchCompanyAdvisorsByPrettyId.failure({ prettyId, error }));
  }
}

function* watchFetchCompanyAdvisorsByPrettyId() {
  yield takeEvery(
    actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.REQUEST,
    fetchCompanyAdvisorsByPrettyId
  );
}

export default function* companiesSaga() {
  yield fork(watchFetchCompanyByPrettyId);
  yield fork(watchFetchCompanyTeamMembersByPrettyId);
  yield fork(watchFetchCompanyAdvisorsByPrettyId);
}
