import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styles from './index.module.scss';
import classnames from 'classnames';
import { show as showModal } from 'redux-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import autoBind from 'react-autobind';
import { logEvent } from 'services/amplitude-helper';
import Button from 'ui/common/button';
import { MODAL_NAME as EMS_CONTACT_MODAL } from 'ui/graphql/EmsContactForm';

export class Cta extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  showContactForm = () => {
    this.props.showModal(EMS_CONTACT_MODAL, { showForm: true });
  };

  renderDesktop() {
    return (
      <div>
        <h2 className={classnames('text-center', styles.title)}>
          Built for you. Focused on your engineering team.
        </h2>

        <h3 className={classnames('text-center', styles.subtitle)}>
          Lead your team by better collecting feedback and understanding their performance in
          software development
        </h3>
        <div className="row justify-content-center">
          <Button
            className={classnames('mt-3 px-5 text-center', styles.whiteBtn)}
            isReady={false}
            onClick={() => {
              window.fcWidget &&
                window.fcWidget.open({
                  replyText:
                    "Hi there, I would like to request a Demo for Indorse's Workforce Management Solutions!",
                });
              // this.showContactForm();
              logEvent('ems-pricing-explore-more');
            }}
            style={{ fontSize: '18px', height: '45px' }}
          >
            Explore More
          </Button>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className="row">
        <div className="col-12">
          <div>
            <h2 className={classnames('text-center', styles.title)}>Built for you.</h2>
            <h2 className={classnames('text-center', styles.title)}>
              Focused on your engineering team.
            </h2>
            <h3 className={classnames('text-center', styles.subtitle)}>
              Lead your team by better collecting feedback and understanding their performance in
              software development
            </h3>
            <div className="row justify-content-center">
              <Button
                className={classnames('mt-3 px-4 text-center', styles.whiteBtn)}
                isReady={false}
                onClick={() => {
                  // window.fcWidget.open({
                  //   replyText:
                  //     "Hi there, I would like to request a Demo for Indorse's Workforce Management Solutions!",
                  // });
                  this.showContactForm();
                  logEvent('ems-pricing-explore-more');
                }}
                style={{ fontSize: '18px', height: '45px' }}
              >
                Explore More
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.blueBackground)}>
        <div className={classnames('page-max-width')}>
          <div className={classnames('container-fluid mt-4')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(injectIntl(Cta));
