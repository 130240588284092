import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as authSelectors from 'store/features/auth/selectors';
import { selectAllEntities, selectEducationEntities } from 'store/entities/selectors';
import { compareByStartDateThenEndDate } from 'utils/date';

const selectEducationFeatureState = state => state.features.education;

export const selectCurrentUserEducationEntries = createSelector(
  selectAllEntities,
  selectEducationEntities,
  authSelectors.selectCurrentUserId,
  (entities, educationEntriesById, currentUserId) => {
    if (!currentUserId) return [];

    return denormalize(Object.keys(educationEntriesById), [schemas.educationEntry], entities)
      .filter(e => e.userId === currentUserId)
      .sort(compareByStartDateThenEndDate);
  }
);

export const selectUserEducationEntries = createSelector(
  selectAllEntities,
  (state, props) => props.userId,
  selectEducationEntities,
  (entities, userId, selectEducationEntities) => {
    if (!userId) return [];

    return denormalize(Object.keys(selectEducationEntities), [schemas.educationEntry], entities)
      .filter(e => e.userId === userId)
      .sort(compareByStartDateThenEndDate);
  }
);

export const selectEducationEntryById = createSelector(
  selectAllEntities,
  (state, props) => props.id,
  (entities, entryId) => denormalize(entryId, schemas.educationEntry, entities)
);

export const selectIsSavingEducation = createSelector(
  selectEducationFeatureState,
  educationFeatureState => educationFeatureState.editEducation.saving
);

// export const selectIsSavingUpdateEducation = createSelector(
//   selectEducationFeatureState,
//   educationFeatureState => educationFeatureState.updateEducation.saving
// );

export const selectIsDeletingUpdateEducation = createSelector(
  selectEducationFeatureState,
  educationFeatureState => educationFeatureState.deleteEducation.deleting
);
