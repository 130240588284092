import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Switch, Route } from 'react-router';
// import Helmet from './helmet';
import { Link } from 'react-router-dom';

import styles from './index.module.scss';
import Layout from './layout';
import CookieConsent from 'react-cookie-consent';

import routeTemplates from 'ui/common/routes/templates';
import Authenticated from 'ui/common/auth/authenticated';
import AuthenticatePromptModal from 'ui/auth/prompt-modal';
import ConfirmationDialog from 'ui/common/confirmation-dialog';
import NewEducationEntryDialog from 'ui/education/new';
import NewWorkExperienceEntryDialog from 'ui/work-experience/new';
import Home from 'ui/pages/home-V2/loadable';
import Hackathons from 'ui/pages/hackathons/loadable';
import Company from 'ui/pages/company';
import Faq from 'ui/pages/faq/loadable';
import DevelopersPage from 'ui/pages/developers-page/loadable';
import EMS from 'ui/pages/ems';
import EMSExtensionLandingPage from 'ui/pages/ems-ext';
import EMSIndorse from 'ui/pages/ems-indorse';
import EMSSlack from 'ui/pages/ems-slack';
import EMSPricing from 'ui/pages/ems-pricing';
import EMSPricingWizard from 'ui/pages/ems-pricing/wizard';
import ProductsPage from 'ui/pages/product-page/loadable';
import TermsOfUse from 'ui/pages/terms-of-use';
import PrivacyPolicy from 'ui/pages/privacy-policy';
import CookiePolicy from 'ui/pages/cookie-consent';
import SignUp from 'ui/auth/sign-up/loadable';
import VerificationEmailSent from 'ui/auth/verification-email-sent/loadable';
import ResendVerificationEmail from 'ui/auth/resend-verification-email/loadable';
import VerifyEmail from 'ui/auth/verify-email/loadable';
import Login from 'ui/auth/login/loadable';
import LoginWithUport from 'ui/auth/login-with-uport/loadable';
import ChangePassword from 'ui/auth/change-password/loadable';
import LinkedAccounts from 'ui/auth/linked-accounts/loadable';
import SetPassword from 'ui/auth/set-password/loadable';
import ForgotPassword from 'ui/auth/forgot-password/loadable';
import ResetPassword from 'ui/auth/reset-password/loadable';
import ApprovalRequired from 'ui/auth/approval-required';
import UserProfile from 'ui/users/details/loadable';
import CompanyProfile from 'ui/companies/details/loadable';
import EditCompany from 'ui/companies/edit/loadable';

import ThankYou from 'ui/pages/thank-you/loadable';
import BlockChain101 from 'ui/blockchain-101/loadable';
import CompanyLandingPage from 'ui/pages/company-landing-page/loadable';
import Validate from 'ui/pages/validate/loadable';
import SignUpWizard from 'ui/auth/sign-up-wizard/loadable';
import GithubAuthCallback from 'ui/pages/github-auth-callback';
import EmsGithubAppCallback from 'ui/pages/ems-apps/github';
import EmsBitBucketAppCallback from 'ui/pages/ems-apps/bitbucket';
import EmsGitlabAppCallback from 'ui/pages/ems-apps/gitlab';
import EmsGitAppInstallSuccessRedirectToSetup from 'ui/pages/ems-apps/git-install-redirect-setup';
import SlackAuthCallback from 'ui/pages/slack-auth-callback';
import SlackAddCallback from 'ui/pages/slack-add-callback';
import SlackConnectCallback from 'ui/pages/slack-connect-callback';
import MetamorphLoginCallback from 'ui/pages/metamorph-login-callback';
import LinkedInCsv from 'ui/graphql/LinkedInCsv/loadable';
import ListCompanies from 'ui/graphql/ListCompanies/loadable';
import MyEvaluations from 'ui/graphql/MyEvaluations/loadable';
import AssignmentDetails from 'ui/graphql/AssignmentDetails/loadable';
import ValidatorsLeaderboard from 'ui/graphql/ValidatorsLeaderboard/loadable';
import AssessmentMagicLink from 'ui/graphql/AssessmentMagicLink/loadable';
import EmsAssessmentMagicLink from 'ui/graphql/EmsAssessmentMagicLink/loadable';
import EmsHandleInviteLink from 'ui/graphql/EmsHandleInviteLink/loadable';
import EmsSetupWizard from 'ui/graphql/EmsSetupWizard/loadable';
import EmsInitialBlanketTest from 'ui/graphql/EmsInitialBlanketTest/loadable';
import EmsUserLearningPath from 'ui/graphql/EmsUserLearningPath/loadable';
import ViewCandidates from 'ui/graphql/ViewCandidates/loadable';
import PartnerAcceptInvite from 'ui/graphql/PartnerAcceptInvite/loadable';
import ClaimReportDetails from 'ui/claim-reports/details';
import BecomeAValidator from 'ui/validator/become-a-validator/loadable';
import DevControlPanelAuditTable from 'ui/graphql/DevControlPanelAuditTable/loadable';
import MongoMailsList from 'ui/graphql/MongoMailsList/loadable';
import ViewTestDetails from 'ui/graphql/ViewTestDetails/loadable';
import ListCreditPackage from 'ui/graphql/ListCreditPackage/loadable';
import OnBoardingCompanyAdmin from 'ui/graphql/OnBoardingCompanyAdmin/loadable';
import CaseStudyChatbotReport from 'ui/graphql/CaseStudyReport/loadable';
import GetStartedCompanyAdmin from 'ui/companies/get-started/';
import EMSGetStartedCompanyAdmin from 'ui/companies/ems/get-started/';
import EmsEmployeeOnBoard from 'ui/companies/ems/ems-employee-on-board/loadable';
import JsonIPFS from 'ui/ipfs';
import HackathonLeaderboard from 'ui/graphql/HackathonLeaderboard/loadable';
import LinkNotFound from 'ui/app/link-not-found/loadable';

import Admin from 'ui/admin/loadable';
import Users from 'ui/users/loadable';
import Claims from 'ui/claims/loadable';
import Votes from 'ui/votes/loadable';
import PanelReviews from 'ui/panel-reviews/loadable';
import Badges from 'ui/badges/loadable';
import Assignment from 'ui/assignment/loadable';
import Thanks from 'ui/pages/assignment-thanks-page/loadable';
import AdminAcceptThanks from 'ui/pages/company-admin-accept-thanks/loadable';
import ValidatorFeatures from 'ui/validator/loadable';
import DevControlPanel from 'ui/graphql/DevControlPanel/loadable';
import HackathonClaimSubmission from 'ui/graphql/HackathonClaimSubmission';

/*
  DO NOT delete import-new-route-top
  Code generator needs those two to dynamically import route for you.
*/

// import-new-route-top

export const AuthenticatedUserProfile = Authenticated(UserProfile);

const MainLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

class App extends Component {
  render() {
    return (
      <div className={styles.app}>
        {/*<Helmet />*/}
        <Switch>
          <Route
            exact
            path={routeTemplates.auth.verificationEmailSent}
            component={VerificationEmailSent}
          />
          <Route
            exact
            path={routeTemplates.auth.resendVerificationEmail}
            component={ResendVerificationEmail}
          />
          <Route exact path={routeTemplates.auth.verifyEmail} component={VerifyEmail} />
          <Route exact path={routeTemplates.auth.forgotPassword} component={ForgotPassword} />
          <Route exact path={routeTemplates.auth.resetPassword} component={ResetPassword} />
          <Route exact path={routeTemplates.auth.approvalRequired} component={ApprovalRequired} />
          <Route path={routeTemplates.thankYou} component={ThankYou} />

          <Route
            exact
            path={routeTemplates.ems.githubAppCallback}
            component={EmsGithubAppCallback}
          />
          <Route
            exact
            path={routeTemplates.ems.bitBucketAppCallback}
            component={EmsBitBucketAppCallback}
          />
          <Route
            exact
            path={routeTemplates.ems.gitlabAppCallback}
            component={EmsGitlabAppCallback}
          />
          <Route
            exact
            path={routeTemplates.ems.gitAppInstallSuccessSetup}
            component={EmsGitAppInstallSuccessRedirectToSetup}
          />

          <Route exact path={routeTemplates.githubAuth} component={GithubAuthCallback} />
          <Route exact path={routeTemplates.slackAuth} component={SlackAuthCallback} />
          <Route exact path={routeTemplates.slackAdd} component={SlackAddCallback} />
          <Route exact path={routeTemplates.slackConnect} component={SlackConnectCallback} />
          <Route exact path={routeTemplates.metamorphLogin} component={MetamorphLoginCallback} />
          <Route
            path={routeTemplates.caseStudyChatbotReports.details}
            exact
            component={CaseStudyChatbotReport}
          />

          <Route exact path={routeTemplates.auth.login} component={Login} />
          <Route exact path={routeTemplates.auth.loginWithUport} component={LoginWithUport} />
          <Route exact path={routeTemplates.auth.signUp} component={SignUp} />

          <MainLayout exact path={routeTemplates.emsLandingPage} component={EMS} />
          <MainLayout
            exact
            path={routeTemplates.emsExtLandingPage}
            component={EMSExtensionLandingPage}
          />
          <MainLayout exact path={routeTemplates.emsLandingPageLegacy} component={EMS} />
          <MainLayout exact path={routeTemplates.emsPricingPageLegacy} component={EMSPricing} />
          <MainLayout exact path={routeTemplates.emsIndorseLandingPage} component={EMSIndorse} />
          <MainLayout exact path={routeTemplates.emsLandingPageSlack} component={EMSSlack} />
          <MainLayout exact path={routeTemplates.emsPricingPage} component={EMSPricing} />
          <MainLayout exact path={routeTemplates.emsPricingWizard} component={EMSPricingWizard} />
          <MainLayout exact path={routeTemplates.app.linkNotFound} component={LinkNotFound} />
          <MainLayout exact path={routeTemplates.developersPage} component={DevelopersPage} />
          <MainLayout exact path={routeTemplates.admin.mongoMails} component={MongoMailsList} />
          <MainLayout exact path={routeTemplates.products} component={ProductsPage} />
          <MainLayout exact path={routeTemplates.root} component={Home} />
          <MainLayout exact path={routeTemplates.hackathons} component={Hackathons} />
          <MainLayout exact path={routeTemplates.aboutCompany} component={Company} />
          <MainLayout exact path={routeTemplates.termsOfUse} component={TermsOfUse} />
          <MainLayout exact path={routeTemplates.privacyPolicy} component={PrivacyPolicy} />
          <MainLayout exact path={routeTemplates.cookieConsent} component={CookiePolicy} />
          <MainLayout exact path={routeTemplates.faq} component={Faq} />
          <MainLayout exact path={routeTemplates.company} component={CompanyLandingPage} />
          <MainLayout exact path={routeTemplates.ipfs} component={JsonIPFS} />
          {/* <MainLayout exact path={routeTemplates.merkle} component={Merkle} /> */}
          <MainLayout
            exact
            path={routeTemplates.users.myProfile}
            component={AuthenticatedUserProfile}
            name="myProfile"
          />
          <MainLayout
            exact
            path={routeTemplates.ems.employee.learningPath}
            component={EmsUserLearningPath}
          />
          <MainLayout exact path={routeTemplates.imports.linkedInCsv} component={LinkedInCsv} />
          <MainLayout exact path={routeTemplates.auth.changePassword} component={ChangePassword} />
          <MainLayout exact path={routeTemplates.auth.setPassword} component={SetPassword} />
          <MainLayout exact path={routeTemplates.auth.linkedAccounts} component={LinkedAccounts} />
          <MainLayout exact path={routeTemplates.wizard.signUp} component={SignUpWizard} />
          <MainLayout path={routeTemplates.admin.root} component={Admin} />
          <MainLayout path={routeTemplates.admin.companies.accept} component={AdminAcceptThanks} />
          <MainLayout path={routeTemplates.users.root} component={Users} />
          <MainLayout path={routeTemplates.claims.root} component={Claims} />
          <MainLayout path={routeTemplates.claimReports.details} component={ClaimReportDetails} />
          <MainLayout path={routeTemplates.votes.root} component={Votes} />
          <MainLayout path={routeTemplates.panelReviews} component={PanelReviews} />
          <MainLayout path={routeTemplates.validatorFeatures.root} component={ValidatorFeatures} />

          <MainLayout exact path={routeTemplates.companies.root} component={ListCompanies} />

          <MainLayout
            exact
            path={routeTemplates.companies.vanityProfile}
            component={CompanyProfile}
          />

          <MainLayout
            exact
            path={routeTemplates.ems.employee.blanketTest}
            component={EmsInitialBlanketTest}
          />

          <MainLayout exact path={routeTemplates.companies.skillMatrix} component={EditCompany} />
          <MainLayout exact path={routeTemplates.hack.event} component={HackathonClaimSubmission} />

          <MainLayout
            exact
            path={routeTemplates.companies.editCareerPath}
            component={EditCompany}
          />

          {/*<MainLayout exact path={routeTemplates.companies.analysis} component={EditCompany} />*/}
          <MainLayout
            exact
            path={routeTemplates.companies.employeeDetails}
            component={EditCompany}
          />
          <MainLayout exact path={routeTemplates.companies.organization} component={EditCompany} />
          <MainLayout exact path={routeTemplates.companies.edit} component={EditCompany} />
          <MainLayout exact path={routeTemplates.companies.emsSetup} component={EmsSetupWizard} />
          <MainLayout
            exact
            path={routeTemplates.companies.magicLinkCandidates}
            component={ViewCandidates}
          />

          <MainLayout
            exact
            path={routeTemplates.companies.testDetails}
            component={ViewTestDetails}
          />

          <MainLayout
            exact
            path={routeTemplates.companies.buyCredits}
            component={ListCreditPackage}
          />

          <MainLayout
            exact
            path={routeTemplates.companies.onBoarding}
            component={OnBoardingCompanyAdmin}
          />

          <MainLayout
            exact
            path={routeTemplates.companies.getStarted}
            component={GetStartedCompanyAdmin}
          />

          <MainLayout
            exact
            path={routeTemplates.companies.emsGetStarted}
            component={EMSGetStartedCompanyAdmin}
          />

          <MainLayout exact path={routeTemplates.blockchain101.root} component={BlockChain101} />

          <MainLayout exact path={routeTemplates.validate} component={Validate} />

          <MainLayout exact path={routeTemplates.assignments.root} component={Assignment} />
          <MainLayout exact path={routeTemplates.assignments.thanks} component={Thanks} />
          <MainLayout
            exact
            path={routeTemplates.assignments.myEvaluations}
            component={MyEvaluations}
          />
          <MainLayout
            exact
            path={routeTemplates.assignments.details}
            component={AssignmentDetails}
          />
          <MainLayout
            exact
            path={routeTemplates.validatorsLeaderboard}
            component={ValidatorsLeaderboard}
          />

          <MainLayout
            exact
            path={routeTemplates.hackathonLeaderboard}
            component={HackathonLeaderboard}
          />

          <MainLayout
            exact
            path={routeTemplates.magicLinks.assessmentMagicLink}
            component={AssessmentMagicLink}
          />
          <MainLayout
            exact
            path={routeTemplates.ems.assessmentMagicLink}
            component={EmsAssessmentMagicLink}
          />

          <MainLayout
            exact
            path={routeTemplates.ems.employee.invite}
            component={EmsHandleInviteLink}
          />

          <MainLayout
            exact
            path={routeTemplates.ems.employee.onBoard}
            component={EmsEmployeeOnBoard}
          />

          <MainLayout
            exact
            path={routeTemplates.partner.acceptInvite}
            component={PartnerAcceptInvite}
          />

          <MainLayout exact path={routeTemplates.becomeAValidator} component={BecomeAValidator} />
          <MainLayout
            exact
            path={routeTemplates.devControlPanel.root}
            component={DevControlPanel}
          />
          <MainLayout
            exact
            path={routeTemplates.devControlPanel.auditTrail}
            component={DevControlPanelAuditTable}
          />

          {/* DO NOT remove add-new-route-top */}
          {/* Code generator needs those to insert route dynamically */}
          {/* add-new-route-top */}
          <MainLayout path={routeTemplates.badges.root} component={Badges} />
          {/* This should always be last */}

          <MainLayout exact path={routeTemplates.users.vanityProfile} component={UserProfile} />
          <MainLayout component={LinkNotFound} />
        </Switch>

        <AuthenticatePromptModal />
        <ConfirmationDialog />
        <NewEducationEntryDialog />
        <NewWorkExperienceEntryDialog />
        <CookieConsent
          buttonText="OK, Understood"
          cookieName="indorse-cookie-v1"
          expires={365}
          acceptOnScroll={true}
          acceptOnScrollPercentage={25}
          cookieValue="accepted"
        >
          This website uses cookies to enhance the user experience. By clicking 'OK' or by
          continuing to use Indorse, you agree to the policy. For more information please review our
          cookie policy. <Link to="/cookie-policy">Cookie Policy</Link>{' '}
        </CookieConsent>
      </div>
    );
  }

  componentDidCatch(e) {
    console.log(e.message);
  }
}

export default injectIntl(App);

// I wrote this comment to trigger deploy
