/*
  AssignmentDetails
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { reduxForm, Field, FormSection } from 'redux-form';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { scrollTo } from 'ui/common/scroller';
import moment from 'moment';
import { camelizeApiResponseFragment } from 'utils/humps';
import { mapKeys } from 'lodash';
import { show as showModal } from 'redux-modal';
import PropTypes from 'prop-types';
import Loading from 'ui/common/loading';

import ProgressChart from 'ui/common/charts/progress-chart';
import themeConfig from 'ui/theme/config';
import Alert from 'ui/common/alert';
import routeTemplates from 'ui/common/routes/templates';
import TextField from 'ui/common/form/fields/text-field';
import SimpleSelectField from 'ui/common/form/fields/simple-select-field';
import validator from 'ui/common/form/validator';
import SubmitButton from 'ui/common/form/submit-button';
import fields, { fieldNames } from './model';
import * as messages from './messages';
import authQuery from './authenticated-query.gql';
import unAuthQuery from './unauthenticated-query.gql';
import * as actionTypes from 'store/graphql/AssignmentDetails/action-types';

import { Table } from 'reactstrap';
import Icon from 'ui/common/icon';
import Button from 'ui/common/button';
import GithubAuthButton from 'ui/graphql/GithubAuthButton';
import LinkGithubButton from 'ui/graphql/LinkGithubButton';
import UserClaimSurveyModal from 'ui/graphql/UserClaimSurveyModal/loadable';
import { MODAL_NAME as USER_CLAIM_SURVEY_MODAL_NAME } from 'ui/graphql/UserClaimSurveyModal';

import GithubLogo from 'resources/page/home/hero/img/github-logo.svg';
import StartAssignmentBtn from 'ui/graphql/StartAssignmentBtn';
import Countdown from 'ui/common/countdown/loadable';
import { parseApiDate } from 'store/common/schemas';
import ProgressBar from 'ui/common/rebranding/components/ProgressBar';
import indorsedIcon from 'resources/common/indorsed.svg';
import flaggedIcon from 'resources/common/flagged.svg';
import styles from './index.module.scss';

import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';

import { selectIsLoggedIn, selectHasCurrentUserLinkedGithub } from 'store/features/auth/selectors';
import { selectLinkedWithGithub } from 'store/graphql/LinkGithubButton/selectors';

import {
  selectIsFetchingAssignment,
  selectAssignment,
  selectIsSubmittingAssignment,
  selectError,
} from 'store/graphql/AssignmentDetails/selectors';

import { compose, bindActionCreators } from 'redux';
import injectReducer from 'utils/injectReducer';
import AssignmentDetailsReducer from 'store/graphql/AssignmentDetails/reducer';

// img
// INTENTIONALLY COMMENTING OUT, will RETOUCH next week
// import blueSquare from 'resources/common/blue-squre.svg';
// import orangeCircle from 'resources/common/orange-circle.svg';
// import purpleTriangle from 'resources/common/purple-triangle.svg';

// add png for print-friendly pdf...
// INTENTIONALLY COMMENTING OUT, will RETOUCH next week
// import orangeCirclePNG from 'resources/common/orange-circle.png';
// import purpleTrianglePNG from 'resources/common/purple-triangle.png';

import circleFill from 'resources/common/circle-fill.png';
import squareFill from 'resources/common/square-fill.png';
import triFill from 'resources/common/tri-fill.png';

const validate = validator(fields);
const FORM_NAME = 'Assignment-submission';
class AssignmentDetails extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      sampleGithubRepos: [],
      githubUserDetails: {},
      isDropDownOpen: false,
    };
  }

  toggleDropdown() {
    this.setState(prevState => ({
      isDropDownOpen: !prevState.isDropDownOpen,
    }));
  }

  componentDidMount() {
    const { id } = this.props;

    if (id) {
      this.getAssignment(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.id !== nextProps.id) {
      this.getAssignment(nextProps);
    }
  }

  async getAssignment(props) {
    const {
      client,
      id,
      getAssignmentStart,
      getAssignmentSuccess,
      getAssignmentFailure,
      isFetchingAssignment,
      isLoggedIn,
    } = props;

    if (isFetchingAssignment) return;

    getAssignmentStart();

    const query = isLoggedIn ? authQuery : unAuthQuery;

    try {
      const response = await client.query({
        query,
        variables: {
          id,
        },
      });

      const { data, errors } = response;

      if (errors && errors[0].message) {
        getAssignmentFailure(errors[0].message);
      } else {
        const assignment = camelizeApiResponseFragment(data.getAssignment);
        if (assignment.jobRole && assignment.jobRole.skills) {
          assignment.jobRole.skills = assignment.jobRole.skills.filter(
            s => !s.disabledFromFrameworkListing
          );
        }
        getAssignmentSuccess({ assignment });
      }
    } catch (error) {
      console.log(error);
      getAssignmentFailure(error);
    }
  }

  async onSubmit(values) {
    const {
      client,
      finishAssignmentStart,
      finishAssignmentSuccess,
      finishAssignmentFailure,
      id,
      assignment: { jobRole },
      showModal,
    } = this.props;

    const { proof, skills } = values;

    finishAssignmentStart();

    try {
      const skillTags = (jobRole && jobRole.skillTags) || [];
      const skillsToSubmit = skillTags
        .map(tag => {
          if (skills && skills[tag] && skills[tag][tag]) {
            return {
              skillTag: tag,
              skillId: skills[tag][tag],
            };
          }

          return undefined;
        })
        .filter(Boolean);

      if (skillsToSubmit.length < skillTags.length) {
        throw new Error('Please select the frameworks used to solve this assignment');
      }

      const response = await client.mutate({
        mutation: gql`
          mutation finishAssignment($assignmentID: String!, $form: FinishAssignmentForm!) {
            finishAssignment(assignmentID: $assignmentID, form: $form) {
              _id
              assignment_id
              claim_id
            }
          }
        `,
        variables: {
          assignmentID: id,
          form: {
            proof,
            skills: skillsToSubmit,
          },
        },
      });

      const { data } = response;

      if (resultHasErrors(response)) {
        throw new Error(errorsToSingleMessage(response));
      } else {
        finishAssignmentSuccess({ assignment: camelizeApiResponseFragment(data.finishAssignment) });
        showModal(USER_CLAIM_SURVEY_MODAL_NAME);
      }
    } catch (error) {
      finishAssignmentFailure(error.message);
    }
  }

  async graphqlGetGithubRepos() {
    const { client } = this.props;
    // Don't retrieve repos if this is already been done
    /*
      We might need to add skillId for dynamic response
    */

    const githubRepoResult = await client.query({
      query: gql`
        query getRepos($languages: [String]) {
          githubGetRepos(languages: $languages) {
            name
            username
            repos {
              title
              description
              skills
              last_updated_at
              stars
              url
            }
          }
        }
      `,
      variables: {
        languages: [], // empty array to retrieve all repos without specifying any coding languages
      },
    });

    if (!githubRepoResult.errors) {
      const { githubGetRepos } = githubRepoResult.data;
      this.setState({
        sampleGithubRepos: githubGetRepos.repos,
        githubUserDetails: {
          login: githubGetRepos.username,
          name: githubGetRepos.name,
        },
      });
    }
  }

  renderForm() {
    const {
      isLoggedIn,
      handleSubmit,
      errorMessage,
      intl: { formatMessage },
      assignment: { jobRole },
    } = this.props;

    const serverkeyMap = {
      name: 'text',
      id: 'value',
      tags: 'tags',
    };

    const options =
      jobRole &&
      jobRole.skills &&
      jobRole.skills.map(skill => {
        const ObjectWithRenamedKeys = mapKeys(skill, function(value, key) {
          return serverkeyMap[key];
        });
        return ObjectWithRenamedKeys;
      });

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        {errorMessage && (
          <Alert color="danger" inverse>
            {errorMessage}
          </Alert>
        )}
        <div>
          <p className="pb-3">{formatMessage(messages.content.postRepoLink)}</p>
        </div>
        <div>
          <Field
            name={fieldNames.proof}
            component={TextField}
            label={formatMessage(messages.labels[fieldNames.proof])}
          />
        </div>
        {this.renderGithubButtons()}
        <div className="pt-5">
          <p className="pb-3 offset-sm-2">{formatMessage(messages.content.selectFrameworks)}</p>
        </div>
        <div className="d-flex flex-column">
          <FormSection name="skills">
            {jobRole &&
              jobRole.skillTags.map(tag => (
                <FormSection key={tag} name={tag}>
                  <Field
                    name={tag}
                    options={options.filter(option => option.tags.indexOf(tag) >= 0)}
                    component={SimpleSelectField}
                    label={tag.toUpperCase() + ` FRAMEWORK`}
                  />
                </FormSection>
              ))}
          </FormSection>
        </div>
        <div className="py-3">
          <b>
            <FormattedMessage
              id="assignment.best-practices"
              defaultMessage={`Read {link} article before you submit the code.`}
              values={{
                link: (
                  <a
                    href="https://medium.com/joinindorse/guide-for-submitting-code-91f400980e10"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    this
                  </a>
                ),
              }}
            />
          </b>
        </div>
        <div className="mt-3 text-center">
          {!isLoggedIn && <p className="text-muted pb-3">Please login to submit assignment</p>}
          <SubmitButton
            className="col-12 justify-content-center d-flex"
            disabled={!isLoggedIn}
            isReady={false}
            formName={FORM_NAME}
            label={formatMessage(messages.buttons.submit)}
          >
            {formatMessage(messages.buttons.submit)}
          </SubmitButton>
        </div>
      </form>
    );
  }

  renderGithubButtons() {
    const { isLoggedIn, finallyLinkedWithGithub, intl: { formatMessage } } = this.props;

    return (
      <div className="pt-3">
        {isLoggedIn && finallyLinkedWithGithub ? (
          <Button
            isReady={false}
            onClick={() => {
              this.graphqlGetGithubRepos();
              setTimeout(() => {
                scrollTo('githubRepositories', -250);
              }, 500);
            }}
          >
            <Icon name="fa fa-github" className="pr-2 m-0" />
            <div className="d-inline">{formatMessage(messages.buttons.browseGithub)}</div>
          </Button>
        ) : isLoggedIn && !finallyLinkedWithGithub ? (
          <LinkGithubButton isReady={false}>
            <Icon name="fa fa-github" className="pr-2 m-0" />
            <div className="d-inline">{formatMessage(messages.buttons.loginWithGithub)}</div>
          </LinkGithubButton>
        ) : (
          <GithubAuthButton redirectToFromPage isReady={false}>
            <Icon name="fa fa-github" className="pr-2 m-0" />
            <div className="d-inline">{formatMessage(messages.buttons.loginWithGithub)}</div>
          </GithubAuthButton>
        )}
      </div>
    );
  }

  renderGithubRepos() {
    const { intl: { formatMessage } } = this.props;

    return (
      <div className="row py-5" id="githubRepositories">
        <div className={classnames('col-12')}>
          <div className="text-center">
            <h1>{formatMessage(messages.githubTitle.title)}</h1>
            <p className="gray mt-3">{formatMessage(messages.githubTitle.miniOne)}</p>
            <p className="gray">{formatMessage(messages.githubTitle.miniTwo)}</p>
          </div>
          <div className="mt-5 mb-3">
            <div className="table-row" style={{ height: '100%' }}>
              <div className="table-cell">
                <img className="mx-auto d-inline" src={GithubLogo} alt={'Github'} width={70} />
              </div>
              <div className="table-cell px-3">
                <p>
                  <strong>{this.state.githubUserDetails.name}</strong>
                </p>
                <p className="gray">{this.state.githubUserDetails.login}</p>
              </div>
            </div>
          </div>
          <Table responsive className="my-5">
            <thead>
              <tr style={{ width: '100%' }}>
                <th style={{ width: '10%' }}>{formatMessage(messages.tableHeaders.title)}</th>
                <th style={{ width: '25%' }}>{formatMessage(messages.tableHeaders.description)}</th>
                <th style={{ width: '20%' }}>{formatMessage(messages.tableHeaders.skills)}</th>
                <th style={{ width: '15%' }}>{formatMessage(messages.tableHeaders.update)}</th>
                <th style={{ width: '10%' }}>{formatMessage(messages.tableHeaders.stars)}</th>
                <th style={{ width: '20%' }}>{formatMessage(messages.tableHeaders.status)}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.sampleGithubRepos.map((repo, key) => this.renderGithubRepoEntry(repo))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }

  renderGithubRepoEntry(entry) {
    const { autofill, intl: { formatMessage } } = this.props;
    const { title, description, skills, last_updated_at, stars, url } = entry;

    return (
      <tr>
        <td>
          <strong>{title}</strong>
        </td>
        <td>{description}</td>
        <td>
          <p className="d-inline gray">{skills}</p>
        </td>
        <td>{`Updated ${moment(last_updated_at).fromNow()}`}</td>
        <td>{stars}</td>
        <td>
          <Button
            isReady={false}
            onClick={() => {
              autofill(fieldNames.proof, url);
              scrollTo('assignment-test-section', 0);
            }}
          >
            {formatMessage(messages.buttons.useThisRepo)}
          </Button>
        </td>
      </tr>
    );
  }

  renderLabels(type) {
    const { intl: { formatMessage } } = this.props;
    if (type === 'indorsed') {
      return (
        <Button svgIcon={indorsedIcon} iconPosition="prefix" className="cat-health gradient px-4">
          <span className="ml-2">{formatMessage(messages.statuses.indorsed)}</span>
        </Button>
      );
    } else if (type === 'flagged') {
      return (
        <Button
          svgIcon={flaggedIcon}
          iconPosition="prefix"
          className="cat-technology gradient px-4"
        >
          <span className="ml-2">{formatMessage(messages.statuses.flagged)}</span>
        </Button>
      );
    } else {
      return (
        <Button disabled className={styles.disabledCursor} isReady={false}>
          {formatMessage(messages.statuses.pendingValidation)}
        </Button>
      );
    }
  }

  renderClaimStatus(claim) {
    const { assignment, intl: { formatMessage } } = this.props;

    if (!assignment) return null;

    if (claim) {
      const { status } = claim;
      const inProgress = status === 'claim_submitted' || status === 'in_progress';
      const isFlagged = status === 'rejected' || status === 'no_consensus';
      const isIndorsed = status === 'indorsed';
      const isDisapproved = status === 'claim_disapproved';

      return (
        <div>
          {isDisapproved && (
            <StartAssignmentBtn
              assignmentId={assignment.id}
              enableEndpointCall={true}
              label={formatMessage(messages.statuses.retake)}
              refreshOnSuccess
            />
          )}
          {inProgress && this.renderLabels('pending')}
          {isIndorsed && <div>{this.renderLabels('indorsed')}</div>}
          {isFlagged && <div>{this.renderLabels('flagged')}</div>}
        </div>
      );
    }
  }

  renderStatus() {
    const { assignment, intl: { formatMessage } } = this.props;
    if (!assignment) return null;
    const { lastViewerAssignment } = assignment;
    const inProgress =
      lastViewerAssignment &&
      lastViewerAssignment.status === 'submitted' &&
      !lastViewerAssignment.claim;

    const claim = lastViewerAssignment && lastViewerAssignment.claim;

    const claimInProgress =
      claim && (claim.status === 'claim_submitted' || claim.status === 'in_progress');

    // const { status, expires_at: expiresAt, votesThreshold, completedVoteCount } = claim;
    const showProgressBar = claim && claim.status === 'in_progress' && claim.expiresAt;
    const isFlagged = claim && (claim.status === 'rejected' || claim.status === 'no_consensus');
    const isIndorsed = claim && claim.status === 'indorsed';
    let dataSets = [];

    if (claim && (isFlagged || isIndorsed)) {
      dataSets = [
        {
          label: 'Indorsed',
          value: claim.indorsedVotesCount,
          color: [themeConfig.palette.category.healthA, themeConfig.palette.category.healthB],
        },
        {
          label: 'Flagged',
          value: claim.flaggedVotesCount,
          color: [
            themeConfig.palette.category.technologyA,
            themeConfig.palette.category.technologyB,
          ],
        },
      ];
    }
    return (
      <div className="row justify-content-center">
        <div className="col col-md-12 col-lg-8">
          {claim && (
            <div className="row align-items-center py-3">
              <div className="col-md-4 col-12">{formatMessage(messages.statuses.statusLabel)}</div>
              <div className="col-md-8 col-12">
                {inProgress && this.renderLabels('pending')}
                {claim && this.renderClaimStatus(claim)}
              </div>
            </div>
          )}
          {claimInProgress &&
            showProgressBar && (
              <div className="row align-items-center pb-3">
                <div className="col-md-4 col-12">{formatMessage(messages.statuses.votesLabel)}</div>
                <div className="col-md-8 col-12">
                  <ProgressBar
                    min={0}
                    max={claim.votesThreshold}
                    value={claim.completedVoteCount}
                    label="Votes"
                  />
                </div>
              </div>
            )}
          {claimInProgress &&
            showProgressBar && (
              <div className="row align-items-center pb-3">
                <div className="col-md-4 col-12">
                  {formatMessage(messages.statuses.votingExpiry)}
                </div>
                <div className="col-md-8 col-12">
                  <Countdown deadline={parseApiDate(claim.expiresAt)} />
                </div>
              </div>
            )}
          {dataSets &&
            dataSets.length > 0 && (
              <div className="row align-items-center pb-3">
                <div className="col-md-4 col-12">
                  {formatMessage(messages.statuses.votesReceived)}
                </div>
                <div className="col-md-5 col-8">
                  <ProgressChart labelLegend="Votes" dataSets={dataSets} />
                </div>
                <div className="col-md-3 col-4 d-flex align-self-center">
                  <ul>
                    {dataSets.map(data => {
                      return (
                        <li>
                          <p>
                            <FormattedMessage
                              id="graphql.AssignmentDetails.voteCounts"
                              defaultMessage={`{value, number} {value, plural,
                        one {vote}
                        other {votes}
                      } `}
                              values={{ value: data.value }}
                            />
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          {(isFlagged || isIndorsed) && (
            <div className="row align-items-center pb-3">
              <div className="col-md-4 col-12">{formatMessage(messages.statuses.votingExpiry)}</div>
              <div className="col-md-8 col-12">
                <span>{formatMessage(messages.statuses.votingEnded)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const {
      assignment,
      intl: { formatMessage },
      routeParams,
      id,
      isFetchingAssignment,
    } = this.props;
    const { sampleGithubRepos } = this.state;

    if (isFetchingAssignment) return <Loading />;

    if (!assignment || !id) return null;

    const { lastViewerAssignment } = assignment;
    const isStarted = lastViewerAssignment && lastViewerAssignment.status === 'started';
    const canStartFirstTime = !lastViewerAssignment;
    const canStart =
      lastViewerAssignment &&
      lastViewerAssignment.claim &&
      (lastViewerAssignment.claim.status === 'claim_disapproved' ||
        lastViewerAssignment.claim.status === 'rejected' ||
        lastViewerAssignment.claim.status === 'no_consensus');
    return (
      <div
        className={classnames('container', {
          'py-5': !routeParams.print,
          'py-3': routeParams.print,
        })}
      >
        <Helmet>
          {assignment.title && (
            <title>{formatMessage(messages.header.title, { title: assignment.title })}</title>
          )}
        </Helmet>
        <UserClaimSurveyModal />
        {!routeParams.print && (
          <div className={classnames('row justify-content-center', { 'mx-1': routeParams.print })}>
            <div
              className={classnames('col-12 col-md-12', {
                'col-lg-8': !routeParams.print,
                [styles.whiteShadedBox]: routeParams.print,
              })}
            >
              {assignment.title && (
                <div className="border-bottom text-center">
                  <ul className="list-inline d-inline-flex">
                    {assignment.imgUrl && (
                      <li className="pb-3 pr-3 list-inline-item align-self-center">
                        <img
                          src={assignment.imgUrl}
                          alt={assignment.title}
                          height="120px"
                          width="120px"
                        />
                      </li>
                    )}
                    <li className="list-inline-item">
                      <h1 className="align-self-center">
                        <FormattedMessage
                          id="graphql.AssignmentDetails.header.title"
                          defaultMessage="{title}"
                          values={{ title: assignment.title }}
                        />
                      </h1>
                    </li>
                  </ul>
                </div>
              )}
              {routeParams &&
                !routeParams.print && (
                  <div className="text-center pt-4">
                    <Button isReady={false} onClick={() => this.props.history.push('/profile')}>
                      {formatMessage(messages.buttons.back)}
                    </Button>
                  </div>
                )}

              {assignment.scope && (
                <div className="text-center text-md-left pt-5 text-break-word text-whitespace-pre-wrap">
                  <h3>{formatMessage(messages.content.scope)}</h3>
                  <div
                    className="pt-3"
                    dangerouslySetInnerHTML={{
                      __html: assignment.scope,
                    }}
                  />
                </div>
              )}

              {assignment.duration && (
                <div className="py-2">
                  <FormattedMessage
                    id="graphql.AssignmentDetails.content.timePeriod"
                    defaultMessage="Estimated Duration: {duration} "
                    values={{
                      duration: <b>{assignment.duration}</b>,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {/* ID-1742 Only render this section if not in PDF mode */
        !routeParams.print && this.renderStatus()}

        <div
          id="assignment-test-section"
          className={classnames('row justify-content-center', {
            'mx-1': routeParams.print,
            'py-5': !routeParams.print,
          })}
        >
          <div
            className={classnames('col-12 col-md-12', {
              'col-lg-8': !routeParams.print,
              [styles.whiteShadedBox]: routeParams.print,
            })}
          >
            <div className="border-bottom text-center">
              <h2 className="pb-1">
                {routeParams.print
                  ? formatMessage(messages.header.title, { title: assignment.title })
                  : formatMessage(messages.content.testTitle)}
              </h2>
            </div>

            {assignment.description && (
              <div className="text-center text-md-left pt-3 text-break-word text-whitespace-pre-wrap">
                <p
                  dangerouslySetInnerHTML={{
                    __html: assignment.description,
                  }}
                />
              </div>
            )}

            {assignment.features &&
              assignment.features.length > 0 && (
                <div className="pt-3">
                  <p className="pb-3">{formatMessage(messages.content.basicFeature)}</p>
                  <ul>
                    {assignment.features.map((feature, index) => {
                      return (
                        <li className="d-flex" key={index}>
                          <img
                            className="mr-2"
                            width="13px"
                            height="13px"
                            src={
                              index % 3 === 0
                                ? triFill
                                : index % 3 === 1 ? squareFill : index % 3 === 2 ? circleFill : null
                            }
                            alt={feature}
                          />
                          <p>{feature}</p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

            {/*
              INTENTIONALLY COMMENTING THIS OUT, this will be RETOUCHED next week
              <div className="pt-3">
                <p>{formatMessage(messages.content.evaluate)}</p>
                <ul className="list-inline pt-3 text-center">
                  <li className="list-inline-item px-0 px-md-5 pt-3">
                    <ul style={{ listStyle: 'none' }}>
                      <li className="pb-3">
                        <img
                          className="d-inline"
                          src={routeParams && routeParams.print ? purpleTrianglePNG : purpleTriangle}
                          alt="purple-triangle"
                          width="20px"
                          height="20px"
                        />
                      </li>
                      <li>
                        <h3>{formatMessage(messages.content.evaluateItemOne)}</h3>
                      </li>
                    </ul>
                  </li>
                  <li className="list-inline-item px-0 px-md-5 pt-3">
                    <ul style={{ listStyle: 'none' }}>
                      <li className="pb-3">
                        <img
                          className="d-inline"
                          src={blueSquare}
                          alt="blue-squre"
                          width="20px"
                          height="20px"
                        />
                      </li>
                      <li>
                        <h3>{formatMessage(messages.content.evaluateItemTwo)}</h3>
                      </li>
                    </ul>
                  </li>
                  <li className="list-inline-item px-0 px-md-5 pt-3">
                    <ul style={{ listStyle: 'none' }}>
                      <li className="pb-3">
                        <img
                          className="d-inline"
                          src={routeParams && routeParams.print ? orangeCirclePNG : orangeCircle}
                          alt="orange-circle"
                          width="20px"
                          height="20px"
                        />
                      </li>
                      <li>
                        <h3>{formatMessage(messages.content.evaluateItemThree)}</h3>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            */}
          </div>
        </div>
        {routeParams && !routeParams.print ? (
          <div>
            {isStarted && (
              <div>
                <div className="row justify-content-center py-5">
                  <div className="col-12 col-md-8">{this.renderForm()}</div>
                </div>
                <div>
                  {sampleGithubRepos && sampleGithubRepos.length > 0 && this.renderGithubRepos()}
                </div>
              </div>
            )}
            {canStartFirstTime && (
              <div className="row justify-content-center py-3">
                <div className="col-12 col-md-6 text-center">
                  <StartAssignmentBtn
                    assignmentId={assignment.id}
                    enableEndpointCall={true}
                    label={formatMessage(messages.statuses.startAssignment)}
                    refreshOnSuccess
                  />
                </div>
              </div>
            )}
            {canStart && (
              <div className="row justify-content-center py-3">
                <div className="col-12 col-md-6 text-center">
                  <StartAssignmentBtn
                    assignmentId={assignment.id}
                    enableEndpointCall={true}
                    label={formatMessage(messages.statuses.retake)}
                    refreshOnSuccess
                  />
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { match: { params: { skillId } } } = ownProps;

  const id =
    ownProps && ownProps.match && ownProps.match.path === routeTemplates.assignments.details
      ? ownProps.match.params && ownProps.match.params.assignmentId
      : ownProps.assignmentId;
  const isLoggedIn = selectIsLoggedIn(state);
  const isLinkedWithGithub = selectHasCurrentUserLinkedGithub(state);
  const linkedWithGithub = selectLinkedWithGithub(state);

  const query = new URLSearchParams(ownProps.location.search);
  const routeParams = {
    print: query.get('print') || ownProps.isPrintPDF,
  };

  return {
    id,
    skillId,
    isLoggedIn,
    assignment: selectAssignment(state),
    isFetchingAssignment: selectIsFetchingAssignment(state),
    isSubmittingAssignment: selectIsSubmittingAssignment(state),
    errorMessage: selectError(state),
    finallyLinkedWithGithub: isLinkedWithGithub || linkedWithGithub,
    routeParams,
  };
}

AssignmentDetails.propTypes = {
  isPrintPDF: PropTypes.bool,
  assignmentId: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    getAssignmentStart: () => dispatch({ type: actionTypes.GET_ASSIGNMENT.START }),
    getAssignmentSuccess: ({ assignment }) =>
      dispatch({ type: actionTypes.GET_ASSIGNMENT.SUCCESS, payload: { assignment } }),
    getAssignmentFailure: error =>
      dispatch({ type: actionTypes.GET_ASSIGNMENT.FAILURE, payload: { error } }),
    finishAssignmentStart: () => dispatch({ type: actionTypes.FINISH_ASSIGNMENT.START }),
    finishAssignmentSuccess: ({ assignment }) =>
      dispatch({ type: actionTypes.FINISH_ASSIGNMENT.SUCCESS, payload: { assignment } }),
    finishAssignmentFailure: error =>
      dispatch({ type: actionTypes.FINISH_ASSIGNMENT.FAILURE, payload: { error } }),
    push: bindActionCreators(push, dispatch),
    showModal: bindActionCreators(showModal, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'AssignmentDetails',
  reducer: AssignmentDetailsReducer,
});

export default withRouter(
  compose(withReducer, withConnect)(
    reduxForm({
      form: FORM_NAME,
      validate,
    })(injectIntl(withApollo(AssignmentDetails)))
  )
);
