import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';

export class ProgressChart extends Component {
  static propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    legendLabel: PropTypes.string,
    dataSets: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        color: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
      })
    ),
  };

  static defaultProps = {
    height: 60,
    options: {
      showLines: false,
      beginAtZero: true,
      min: 0,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              display: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem, data) {
            return '';
          },
        },
      },
    },
  };

  render() {
    const { className, dataSets, options, height, width, legendLabel } = this.props;

    const data = canvas => {
      const ctx = canvas.getContext('2d');

      const backgroundColors = dataSets.map(data => {
        let ctxGradient = ctx.createLinearGradient(0, 0, 200, 0);

        if (data.color.length >= 0 && typeof data.color !== 'string') {
          data.color.map((color, index) => {
            ctxGradient.addColorStop(index, color);
            return ctxGradient;
          });
          return ctxGradient;
        }

        return data.color;
      });

      return {
        labels: dataSets.map(data => {
          return data.label;
        }),
        datasets: [
          {
            backgroundColor: backgroundColors,
            label: legendLabel,
            data: dataSets.map(data => {
              if (data.value === 0) {
                return data.value + 0.1;
              }

              return data.value;
            }),
          },
        ],
      };
    };

    return (
      <div className={className}>
        <HorizontalBar height={height} width={width} data={data} options={options} />
      </div>
    );
  }
}

export default ProgressChart;
