import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

export default function reducer(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_BY_PRETTY_ID.START: {
      const { background: fetchingInBackground } = action.meta || {};
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: true,
            fetchingInBackground,
          },
        },
        { deep: true }
      );
    }
    case actionTypes.FETCH_COMPANY_BY_PRETTY_ID.SUCCESS:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: false,
            lastFetchedAt: new Date().toISOString(),
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_COMPANY_BY_PRETTY_ID.FAILURE:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: false,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.START:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetchingTeamMembers: true,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.SUCCESS:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetchingTeamMembers: false,
            lastFetchedTeamMembersAt: new Date().toISOString(),
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.FAILURE:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetchingTeamMembers: false,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.START:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetchingAdvisors: true,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.SUCCESS:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetchingAdvisors: false,
            lastFetchedAdvisorsAt: new Date().toISOString(),
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.FAILURE:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetchingAdvisors: false,
          },
        },
        { deep: true }
      );
    default:
      return state;
  }
}
