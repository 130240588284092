/*
 * EmsContactForm Messages
 *
 * This contains all the text for the EmsContactForm component.
 */
import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export default defineMessages({
  [fieldNames.fullName]: {
    id: 'graphql.EmsContactForm.fullName',
    defaultMessage: 'Full Name',
  },
  [fieldNames.businessEmail]: {
    id: 'graphql.EmsContactForm.businessEmail',
    defaultMessage: 'Business email',
  },
  [fieldNames.companyName]: {
    id: 'graphql.EmsContactForm.companyName',
    defaultMessage: 'Company name',
  },
  [fieldNames.country]: {
    id: 'graphql.EmsContactForm.country',
    defaultMessage: 'Country',
  },
  [fieldNames.product]: {
    id: 'graphql.EmsContactForm.product',
    defaultMessage: 'Choose Product',
  },
  [fieldNames.message]: {
    id: 'graphql.EmsContactForm.message',
    defaultMessage: 'Message',
  },
  submitButton: {
    id: 'graphql.EmsContactForm.submitButton',
    defaultMessage: 'Submit',
  },
});
