/**
 *
 * Asynchronously loads the component for AdminDeleteBadge
 *
 */

import React, { Suspense } from 'react';
import Loading from 'ui/common/loading';

const LazyComponent = React.lazy(() => import('./index'));

export default props => (
  <Suspense fallback={<Loading />}>
    <LazyComponent {...props} />
  </Suspense>
);
