import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';

const feature1 = 'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-feature1.png';
const feature2 = 'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-usage-img-2.png';
// const feature2bg =
// 'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-feature2-bg.png';
const feature3 = 'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-feature3.png';

const RenderDesktop = props => {
  return (
    <div className={classnames(styles.features)}>
      <div className="">
        <div className={styles.heading}>
          <h1 className={styles.title}>Who uses the Metamorph extension</h1>
          <h3 className={styles.subTitle}>
            Metamorph for developers will bring your performance on GitHub to the next level
          </h3>
        </div>
        <div
          className={classnames(
            styles.featureList,
            'd-flex align-items-end justify-content-center'
          )}
        >
          <div className={styles.featureBox}>
            <img src={feature2} alt="feature2" width="220" />
            <div className={styles.info}>
              <h3>The Explorator</h3>
              <p>
                Find out what is happening behind the scenes: how many contributors were active last
                month? How are PRs managed?
              </p>
            </div>
          </div>
          <div className={styles.featureBox}>
            <img src={feature1} alt="feature1" width="220" />
            <div className={styles.info}>
              <h3>#100DaysOfCode</h3>
              <p>
                Your extension to easily see the activity of the repo: how active is it? Is it
                legit? Can I use the package?
              </p>
            </div>
          </div>
          <div className={styles.featureBox}>
            <img src={feature3} alt="feature3" width="220" />
            <div className={styles.info}>
              <h3>The Manager</h3>
              <p>
                Get a quick overview of Git repos: identify bottlenecks, learn more about how it is
                maintained, create quick reports about your team’s repo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderMobile = props => {
  return (
    <div className={classnames(styles.features)}>
      <div className="">
        <div className={styles.heading}>
          <h1 className={styles.title}>
            Who uses the Metamorph extension
            {/* See Git repos like<br /> never before */}
          </h1>
          <h3 className={styles.subTitle}>
            Metamorph for developers will bring your<br />performance on GitHub to the next level
          </h3>
        </div>
        <div className={classnames(styles.featureList, 'mx-auto')}>
          <div className={styles.featureBox}>
            <img src={feature2} alt="feature2" width="220" />
            <div className={styles.info}>
              <h3>The Explorator</h3>
              <p>
                Find out what is happening behind the scenes: how many contributors were active last
                month? How are PRs managed?
              </p>
            </div>
          </div>
          <div className={styles.featureBox}>
            <img src={feature1} alt="feature1" width="220" />
            <div className={styles.info}>
              <h3>#100DaysOfCode</h3>
              <p>
                Your extension to easily see the activity of the repo: how active is it? Is it
                legit? Can I use the package?
              </p>
            </div>
          </div>
          <div className={styles.featureBox}>
            <img src={feature3} alt="feature3" width="220" />
            <div className={styles.info}>
              <h3>The Manager</h3>
              <p>
                Get a quick overview of Git repos: identify bottlenecks, learn more about how it is
                maintained, create quick reports about your team’s repo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const usageSection = props => {
  return (
    <div className={classnames(styles.section)}>
      <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
        <RenderDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
        <RenderMobile />
      </MediaQuery>
    </div>
  );
};

export default usageSection;
