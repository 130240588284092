import objectPath from 'object-path';
import { camelizeKeys } from 'humps';

import unconventional from './uncoventional-keys';

const apiErrorMessage = error => error.message;

export function apiValidationErrorsToFormErrors(apiErrors = [], options = {}) {
  options.errorMessage = options.errorMessage || apiErrorMessage;

  let formErrors = {};

  apiErrors.forEach(apiError => {
    const error = apiError.params.errors ? apiError.params.errors[0] : apiError;

    const rootPath = error.dataPath;
    const property = error.params.missingProperty ? `/${error.params.missingProperty}` : '';
    // property = error.params.additionalProperty ? `/${error.params.additionalProperty}` : '';
    let fullPath = `${rootPath}${property}`.replace(/\//g, '.').substring(1);

    if (error.parentSchema && error.parentSchema.type === 'array') {
      fullPath += '._error';
    }

    const message = options.errorMessage(apiError);

    objectPath.set(formErrors, fullPath, message);
  });

  formErrors = camelizeKeys(formErrors, (key, convert) => unconventional[key] || convert(key));

  return formErrors;
}

window.apiErrorsToReduxFormErrors = apiValidationErrorsToFormErrors;
