import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  fetching: false,
  error: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_RATING_CRITERIA_DETAILS.START:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: true,
            error: false,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_RATING_CRITERIA_DETAILS.SUCCESS:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: false,
            lastFetchedAt: new Date().toISOString(),
            error: false,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_RATING_CRITERIA_DETAILS.FAILURE:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: false,
            error: true,
          },
        },
        { deep: true }
      );
    default:
      return state;
  }
}
