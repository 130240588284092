import { createSelector } from 'reselect';

const selectClaimReportsFeatureState = state => state.features.claimReports;

export const selectIsFetchingClaimReport = createSelector(
  selectClaimReportsFeatureState,
  (state, props) => props.id,
  (claimReportsFeatureState, claimReportId) =>
    claimReportsFeatureState[claimReportId] && claimReportsFeatureState[claimReportId].fetching
);

export const selectClaimReportFetchedAtleastOnce = createSelector(
  selectClaimReportsFeatureState,
  (state, props) => props.id,
  (claimReportsFeatureState, claimReportId) =>
    claimReportsFeatureState[claimReportId] &&
    !!claimReportsFeatureState[claimReportId].lastFetchedAt
);

export const selectClaimIdByReportId = createSelector(
  selectClaimReportsFeatureState,
  (state, props) => props.reportId,
  (claimReportsFeatureState, claimReportId) =>
    claimReportsFeatureState[claimReportId] && claimReportsFeatureState[claimReportId].claimId
);
