import shortid from 'shortid';

import { defineAction } from 'store/common/action-helpers';
import * as actionTypes from './action-types';

export const extraBasicInfoFromWeb3 = {
  request: ({ web3 }) => defineAction(actionTypes.EXTRACT_BASIC_INFO_FROM_WEB3.REQUEST, { web3 }),
  start: () => defineAction(actionTypes.EXTRACT_BASIC_INFO_FROM_WEB3.START),
  success: ({ providerName, networkName, accounts, medianGasPriceInGwei }) =>
    defineAction(actionTypes.EXTRACT_BASIC_INFO_FROM_WEB3.SUCCESS, {
      providerName,
      networkName,
      accounts,
      medianGasPriceInGwei,
    }),
  failure: ({ error }) => defineAction(actionTypes.EXTRACT_BASIC_INFO_FROM_WEB3.FAILURE, { error }),
};

export const pollToRextractBasicInfoFromWeb3 = {
  register: ({ web3, pollerId = shortid.generate() }) =>
    defineAction(actionTypes.POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3.REGISTER, { web3, pollerId }),
  unregister: ({ pollerId }) =>
    defineAction(actionTypes.POLL_TO_REXTRACT_BASIC_INFO_FROM_WEB3.UNREGISTER, { pollerId }),
};
