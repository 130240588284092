// // This component is mainly used in PDF report for the SME candidates
// // @author - mbj36

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { LineChart, Line, XAxis, YAxis, ReferenceLine } from 'recharts';
export default class PercentileChart extends Component {
  static propTypes = {
    x: PropTypes.arrayOf(PropTypes.number),
    y: PropTypes.arrayOf(PropTypes.number),
    score: PropTypes.number,
    percentile: PropTypes.number,
  };

  render() {
    const { x, y, score } = this.props;

    let data =
      x &&
      x.map((label, index) => {
        return {
          name: label,
          value: y[index],
        };
      });

    const closest =
      x &&
      x.reduce(function(prev, curr) {
        return Math.abs(curr - score) < Math.abs(prev - score) ? curr : prev;
      });

    return (
      <LineChart
        width={450}
        height={150}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis
          tickLine={false}
          interval={4}
          dataKey="name"
          tick={false}
          // label={{ value: 'Normalised Score', position: 'insideBottomCenter', dy: 15 }}
        />

        <YAxis dataKey="value" tick={false} tickLine={false} />
        <ReferenceLine
          x={closest}
          // label={{
          //   position: 'insideBottomRight',
          //   value: `Percentile - ${Math.round(percentile)}%`,
          //   fill: 'black',
          //   fontSize: 11,
          // }}
          stroke="#20b6c7"
          strokeWidth={3}
        />
        <Line dot={false} type="monotone" dataKey="value" stroke="#20b6c7" strokeWidth={3} />
      </LineChart>
    );
  }
}
