import { user as schema } from 'store/common/schemas';
import { createEntitiesReducer, createEntitiesSelector } from './helpers';

import { FETCH_CURRENT_USER, UPDATE_CURRENT_USER } from 'store/features/auth/action-types';
import {
  APPROVE_USER,
  DISAPPROVE_USER,
  ASSIGN_ROLE,
  SET_ETHEREUM_ADDRESS,
  SET_CURRENT_USER_ETHEREUM_ADDRESS,
} from 'store/features/users/action-types';
import { ADD_USER_SKILL } from 'store/features/users/skills/action-types';
import { DELETE_USER_SKILL } from 'store/graphql/UserDeleteSkillButton/action-types';
import * as adminActionTypes from 'store/graphql/AdminDeleteUserButton/action-types';

export const reducer = createEntitiesReducer(schema, (state, action) => {
  switch (action.type) {
    case APPROVE_USER.SUCCESS:
      const approvedUsers = {};
      approvedUsers[action.payload.userId] = { approved: true };
      return state.merge(approvedUsers, { deep: true });
    case DISAPPROVE_USER.SUCCESS:
      const disapprovedUsers = {};
      disapprovedUsers[action.payload.userId] = { approved: false };
      return state.merge(disapprovedUsers, { deep: true });
    case FETCH_CURRENT_USER.SUCCESS:
      const fetchedUserId = action.payload.user && action.payload.user.id;
      return fetchedUserId
        ? state.merge(
            {
              [fetchedUserId]: action.payload.user,
            },
            { deep: true }
          )
        : state;
    case UPDATE_CURRENT_USER.SUCCESS:
      const updatedUserId = action.payload.user && action.payload.user.id;
      return updatedUserId
        ? state.merge(
            {
              [updatedUserId]: action.payload.user,
            },
            { deep: true }
          )
        : state;
    case ASSIGN_ROLE.SUCCESS:
      const assignRoleUsers = {};
      assignRoleUsers[action.payload.userId] = { role: action.payload.role };
      return state.merge(assignRoleUsers, { deep: true });
    case SET_ETHEREUM_ADDRESS.SUCCESS:
      const {
        userId: setEthereumAddressUserId,
        ethereumAddress: newEthereumAddress,
      } = action.payload;
      if (setEthereumAddressUserId) {
        return state.merge(
          {
            [setEthereumAddressUserId]: {
              ethereumAddress: newEthereumAddress,
            },
          },
          { deep: true }
        );
      }
      return state;
    case SET_CURRENT_USER_ETHEREUM_ADDRESS.SUCCESS:
      const { currentUserId: setEthereumAddressCurrentUserId } = action.payload;
      if (setEthereumAddressCurrentUserId) {
        const {
          ethereumAddress: newEthereumAddress,
          ethereumAddressVerified: newEthereumAddressVerified,
        } = action.payload;
        return state.merge(
          {
            [setEthereumAddressCurrentUserId]: {
              ethereumAddress: newEthereumAddress,
              ethereumAddressVerified: newEthereumAddressVerified,
            },
          },
          { deep: true }
        );
      }
      return state;
    case ADD_USER_SKILL.SUCCESS:
      const { userId: addedSkillToUserId, userSkillId: addedUserSkillId } = action.payload;

      const user = state[addedSkillToUserId];
      if (user) {
        const newUserSkillIds = [...(user.skills || []), addedUserSkillId];
        return state.merge(
          {
            [addedSkillToUserId]: {
              skills: newUserSkillIds,
            },
          },
          { deep: true }
        );
      }

      return state;

    case DELETE_USER_SKILL.SUCCESS:
      const { userId, skillId } = action.payload;
      const userEntity = state[userId];
      const newUserSkillIds = userEntity.skills.filter(skillIdFromEntity => {
        return skillIdFromEntity !== skillId;
      });

      return state.merge(
        {
          [userId]: {
            skills: newUserSkillIds,
          },
        },
        { deep: true }
      );
    case adminActionTypes.DELETE_USER.SUCCESS: {
      const { id: deletedId } = action.payload;
      return state.merge(
        {
          [deletedId]: {
            isDeleted: true,
          },
        },
        { deep: true }
      );
    }

    default:
      return state;
  }
});

export const selector = createEntitiesSelector(schema);
