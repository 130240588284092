export const tabs = [
  {
    info:
      'From individual software engineers to the entire engineering team, identify the strengths & weaknesses in terms of their technical capability',
    points: [
      'Continuous tech competency audit',
      'Engineers spend only 10 minutes a week',
      'Top-notch challenges created by experts',
    ],
    alt: 'micro-tests',
    img_url: 'https://indorse-staging-bucket.s3.amazonaws.com/Coding%2BMicro-tests%2BImage.jpg',
  },
  {
    info: 'Measure coding behavior and performance with continuous, real-time analytics',
    points: [
      'Specific to software practices',
      'Real-time analytics on critical metrics such as quality, productivity and efficiency amongst others',
      'Get continuous feedback over time',
    ],
    alt: 'pulse-survey',
    img_url: 'https://indorse-staging-bucket.s3.amazonaws.com/Pulse-Surveys-Image.png',
  },
  {
    info:
      'Easily track changes & gaps of tech performance and see things as perceived by your software engineers',
    points: [
      'Intuitive graphs for actionable analytics',
      'Recommendations based on industry benchmarks',
      'Organizational charts as well as individual charts for each software engineer',
    ],
    alt: 'insights',
    img_url: 'https://indorse-staging-bucket.s3.amazonaws.com/Continuous-Insights.png',
  },
];

export const slackbotTabs = [
  {
    info:
      'From individual software engineers to the entire engineering team, identify the strengths & weaknesses in terms of their technical capability',
    points: [
      'Continuous tech competency audit',
      'Engineers spend only 10 minutes a week',
      'Top-notch challenges created by experts',
    ],
    alt: 'micro-tests',
    img_url:
      'https://indorse-staging-bucket.s3.amazonaws.com/Coding_Micro-Challenges_Slackbot_2.png',
  },
  {
    info: 'Measure coding behavior and performance with continuous, real-time analytics',
    points: [
      'Specific to software practices',
      'Real-time analytics on critical metrics such as quality, productivity and efficiency amongst others',
      'Get continuous feedback over time',
    ],
    alt: 'pulse-survey',
    img_url: 'https://indorse-staging-bucket.s3.amazonaws.com/Pulse_Surveys_Slackbot.png',
  },
  {
    info:
      'Easily track changes & gaps of tech performance and see things as perceived by your software engineers',
    points: [
      'Intuitive graphs for actionable analytics',
      'Recommendations based on industry benchmarks',
      'Organizational charts as well as individual charts for each software engineer',
    ],
    alt: 'insights',
    img_url: 'https://indorse-staging-bucket.s3.amazonaws.com/Continuous_Insights_Slackbot.png',
  },
];
