import { createSelector } from 'reselect';

const selectGetStartedCompanyAdminState = state => state.GetStartedCompanyAdmin;

export const selectIsFetching = createSelector(
  selectGetStartedCompanyAdminState,
  (state, props) => state.fetching
);

export const selectIsUpdating = createSelector(
  selectGetStartedCompanyAdminState,
  (state, props) => state.updating
);

export const selectError = createSelector(
  selectGetStartedCompanyAdminState,
  (state, props) => state.error
);

export const selectVersion = createSelector(
  selectGetStartedCompanyAdminState,
  (state, props) => state.data
);

export const selectCompanyDetails = createSelector(
  selectGetStartedCompanyAdminState,
  (state, props) => state.companyDetails
);

export const selectHasCompletedTour = createSelector(
  selectGetStartedCompanyAdminState,
  (state, props) => state.hasCompletedTour
);

export const selectHasOnboarded = createSelector(
  selectGetStartedCompanyAdminState,
  (state, props) => state.hasOnboarded
);
