import { defineMessages } from 'react-intl';

export const feedbackRatingTypeIds = {
  quality: 'quality',
  designPatterns: 'designPatterns',
  gitFlow: 'gitFlow',
  readability: 'readability',
  extensibility: 'extensibility',
  completion: 'completion',
  testCoverage: 'testCoverage',
};

export const feedbackRatingTypeIdsOrder = [
  feedbackRatingTypeIds.quality,
  feedbackRatingTypeIds.designPatterns,
  feedbackRatingTypeIds.gitFlow,
  feedbackRatingTypeIds.readability,
  feedbackRatingTypeIds.extensibility,
  feedbackRatingTypeIds.completion,
  feedbackRatingTypeIds.testCoverage,
];

const messages = {
  titles: defineMessages({
    [feedbackRatingTypeIds.quality]: {
      id: 'claims.details.feedbacks.rating.quality',
      defaultMessage: 'Code Quality',
    },
    [feedbackRatingTypeIds.designPatterns]: {
      id: 'claims.details.feedbacks.rating.designPatterns',
      defaultMessage: 'Knowledge of Design Patterns',
    },
    [feedbackRatingTypeIds.gitFlow]: {
      id: 'claims.details.feedbacks.rating.gitFlow',
      defaultMessage: `Source Control Usage (Gitflow)`,
    },
    [feedbackRatingTypeIds.completion]: {
      id: 'claims.details.feedbacks.rating.completion',
      defaultMessage: `Assignment completion`,
    },
    [feedbackRatingTypeIds.testCoverage]: {
      id: 'claims.details.feedbacks.rating.testCoverageTitle',
      defaultMessage: `Test Coverage`,
    },
    [feedbackRatingTypeIds.readability]: {
      id: 'claims.details.feedbacks.rating.readabilityTitle',
      defaultMessage: `Readability`,
    },
    [feedbackRatingTypeIds.extensibility]: {
      id: 'claims.details.feedbacks.rating.extensibilityTitle',
      defaultMessage: `Extensibility`,
    },
  }),
};

export const feedbackRatingTypes = {
  [feedbackRatingTypeIds.quality]: {
    retired: false,
  },
  [feedbackRatingTypeIds.designPatterns]: {
    retired: false,
  },
  [feedbackRatingTypeIds.gitFlow]: {
    retired: true,
  },
  [feedbackRatingTypeIds.readability]: {
    retired: false,
  },
  [feedbackRatingTypeIds.extensibility]: {
    retired: false,
  },
  [feedbackRatingTypeIds.completion]: {
    retired: false,
  },
  [feedbackRatingTypeIds.testCoverage]: {
    retired: false,
  },
};

feedbackRatingTypeIdsOrder.forEach(typeId => {
  feedbackRatingTypes[typeId] = {
    ...feedbackRatingTypes[typeId],
    id: typeId,
    title: messages.titles[typeId],
  };
});

export function isRatingTypeAllowedForClaimType(ratingTypeId, claimType) {
  if (ratingTypeId === feedbackRatingTypeIds.completion) return claimType === 'ASSIGNMENT';

  return true;
}
