export function autoSuggestCompanies(companyName) {
  return {
    endpoint: 'findcompany',
    method: 'POST',
    data: { companyName },
    requireAuth: true,
  };
}

function sanitizeWorkExperienceEntry(values) {
  const finalValues = {};
  if (values.currentlyWorking || !values.endDate) delete values.endDate;

  if (values.skills) finalValues.skills = values.skills;
  if (values.companyName) finalValues.companyName = values.companyName;
  if (values.companyId) finalValues.companyId = values.companyId;
  if (values.title) finalValues.title = values.title;
  if (values.currentlyWorking) finalValues.currentlyWorking = values.currentlyWorking;
  if (values.location) finalValues.location = values.location;
  if (values.activities) finalValues.activities = values.activities;
  if (values.description) finalValues.description = values.description;
  if (values.startDate) finalValues.startDate = values.startDate;
  if (values.endDate) finalValues.endDate = values.endDate;

  return finalValues;
}

export function addWorkExperienceEntry(values) {
  return {
    endpoint: 'updatework',
    method: 'POST',
    data: sanitizeWorkExperienceEntry(values),
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function updateWorkExperienceEntry({ id: itemKey, ...values }) {
  return {
    endpoint: 'updatework',
    method: 'POST',
    data: { itemKey, ...sanitizeWorkExperienceEntry(values) },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function deleteWorkExperienceEntry({ id: itemKey }) {
  return {
    endpoint: 'deletework',
    method: 'POST',
    data: { itemKey },
    requireAuth: true,
  };
}
