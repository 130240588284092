import { defineApiActionTypes, defineConfirmationActionTypes } from 'store/common/action-helpers';

const prefix = 'features/work-experience';

export const ADD_WORK_EXPERIENCE_ENTRY = defineApiActionTypes(`${prefix}/ADD_ENTRY`);

export const UPDATE_WORK_EXPERIENCE_ENTRY = defineApiActionTypes(`${prefix}/UPDATE_ENTRY`);

export const DELETE_WORK_EXPERIENCE_ENTRY = {
  ...defineApiActionTypes(`${prefix}/DELETE_ENTRY`),
  ...defineConfirmationActionTypes(`${prefix}/DELETE_ENTRY`),
};
