import { generateSortParameterURLValue } from 'utils/sort';
import { decamelize } from 'humps';

export function fetchCompanies({
  filters = {},
  pageNo,
  perPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  sort,
}) {
  const sortParam = generateSortParameterURLValue(sort);
  const qs = new URLSearchParams();
  if (filters)
    Object.keys(filters).forEach(filterName => {
      const filterValue = filters[filterName];
      if (typeof filterValue !== undefined && filterValue !== null && filterValue !== [])
        qs.set(filterName, filterValue);
    });
  if (sortParam && sortParam.length > 0) qs.set('sort', decamelize(sortParam));
  qs.set('pageNumber', pageNo || 1);
  qs.set('pageSize', perPage);

  return {
    endpoint: `companies?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}

function ignoreEmptySocialLinks(socialLinks) {
  return (socialLinks || []).filter(link => !!link.type && !!link.url);
}

export function createCompany({
  companyName,
  prettyId,
  email,
  tagline,
  description,
  visibleToPublic,
  logoData,
  coverData,
  admin,
  socialLinks,
  additionalData,
  features,
}) {
  return {
    endpoint: 'companies',
    method: 'POST',
    data: {
      company_name: companyName,
      pretty_id: prettyId,
      email,
      tagline,
      description,
      visible_to_public: visibleToPublic,
      logo_data: logoData,
      cover_data: coverData,
      admin,
      social_links: ignoreEmptySocialLinks(socialLinks),
      features,
      additional_data: additionalData,
    },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
    decamlizeInput: false,
  };
}

export function updateCompany({
  prettyId: currentPrettyId,
  values: {
    companyName,
    email,
    tagline,
    description,
    visibleToPublic,
    logoData,
    coverData,
    admin,
    socialLinks,
    features,
    additional_data: additionalData,
  },
}) {
  return {
    endpoint: `companies/${currentPrettyId}`,
    method: 'PATCH',
    data: {
      company_name: companyName,
      email,
      tagline,
      description,
      visible_to_public: visibleToPublic,
      logo_data: logoData,
      cover_data: coverData,
      admin,
      social_links: ignoreEmptySocialLinks(socialLinks),
      features,
      additionalData,
    },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
    decamlizeInput: false,
  };
}

export function deleteCompany({ prettyId }) {
  return {
    endpoint: `companies/${prettyId}`,
    method: 'DELETE',
    requireAuth: true,
  };
}

export function fetchCompanyByPrettyId({ prettyId }) {
  return {
    endpoint: `companies/${prettyId}`,
    method: 'GET',
    requireAuth: false,
    optionalAuth: true,
  };
}

export function fetchCompanyAdvisorConnectionsAsAdminByPrettyId({ prettyId }) {
  return {
    endpoint: `companies/${prettyId}/advisors/admin`,
    method: 'GET',
    requireAuth: true,
  };
}

export function inviteAdvisorToCompany({ name, email, imgUrl, prettyId, soft = true }) {
  return {
    endpoint: `companies/${prettyId}/advisor${soft ? '/soft-invite' : ''}`,
    method: 'POST',
    data: {
      name,
      email,
      imgUrl,
    },
    decamlizeInput: false,
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function updateAdvisorToCompany({ name, imgUrl, prettyId, advisorId }) {
  return {
    endpoint: `companies/${prettyId}/advisors/${advisorId}/update`,
    method: 'POST',
    data: {
      name,
      imgUrl,
    },
    decamlizeInput: false,
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function deleteAdvisorFromCompany({ advisorId, prettyId }) {
  return {
    endpoint: `companies/${prettyId}/advisors/${advisorId}`,
    method: 'DELETE',
    requireAuth: true,
  };
}

export function fetchCompanyAdvisorsByPrettyId({ prettyId }) {
  return {
    endpoint: `companies/${prettyId}/advisors`,
    method: 'GET',
  };
}

export function updateSoftConnectionAdminStatus({ prettyId, advisorId, status }) {
  return {
    endpoint: `companies/${prettyId}/advisors/${advisorId}/admin-update`,
    method: 'POST',
    data: { status },
    requireAuth: true,
  };
}

export function fetchCompanyTeamMemberConnectionsAsAdminByPrettyId({ prettyId }) {
  return {
    endpoint: `companies/${prettyId}/team_members/admin`,
    method: 'GET',
    requireAuth: true,
  };
}

export function inviteTeamMemberToCompany({ name, imgUrl, email, designation, prettyId }) {
  return {
    endpoint: `companies/${prettyId}/team_member`,
    method: 'POST',
    data: {
      email,
      designation,
      name,
      imgUrl,
    },
    decamlizeInput: false,
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function updateTeamMemberToCompany({
  name,
  imgUrl,
  designation,
  prettyId,
  teamMemberId,
  isRegisteredUser,
}) {
  let data = isRegisteredUser ? { designation } : { designation, name, imgUrl };
  return {
    endpoint: `companies/${prettyId}/team_members/${teamMemberId}/update`,
    method: 'POST',
    data,
    decamlizeInput: false,
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function deleteTeamMemberFromCompany({ teamMemberId, prettyId }) {
  return {
    endpoint: `companies/${prettyId}/team_members/${teamMemberId}`,
    method: 'DELETE',
    requireAuth: true,
  };
}

export function fetchCompanyTeamMembersByPrettyId({ prettyId }) {
  return {
    endpoint: `companies/${prettyId}/team_members`,
    method: 'GET',
  };
}

export function fetchCompaniesVerificationDetails({
  filters = {},
  pageNo,
  perPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  sort,
}) {
  const sortParam = generateSortParameterURLValue(sort);
  const qs = new URLSearchParams();
  if (filters)
    Object.keys(filters).forEach(filterName => {
      const filterValue = filters[filterName];
      if (typeof filterValue !== undefined && filterValue !== null && filterValue !== [])
        qs.set(filterName, filterValue);
    });
  if (sortParam && sortParam.length > 0) qs.set('sort', decamelize(sortParam));
  qs.set('pageNumber', pageNo || 1);
  qs.set('pageSize', perPage);

  return {
    endpoint: `companies-validation?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}
