import { vote as schema } from 'store/common/schemas';
import { createEntitiesReducer, createEntitiesSelector } from './helpers';

import {
  REGISTER_TO_VOTE,
  ENDORSE,
  FLAG,
  CHECK_STATUS,
  CAST,
} from 'store/features/votes/action-types';

export const reducer = createEntitiesReducer(schema, (state, action) => {
  switch (action.type) {
    case CHECK_STATUS.START:
      const checkingStatusForVotes = {};
      checkingStatusForVotes[action.payload.voteId] = { checkingStatus: true };
      return state.merge(checkingStatusForVotes, { deep: true });
    case CHECK_STATUS.SUCCESS:
      const checkedStatusForVotes = {};
      checkedStatusForVotes[action.payload.voteId] = {
        checkingStatus: false,
        endorsed: action.payload.endorsed,
        voteCompleted: true,
      };
      return state.merge(checkedStatusForVotes, { deep: true });
    case CHECK_STATUS.FAILURE:
      const checkedStatusFailedForVotes = {};
      checkedStatusFailedForVotes[action.payload.voteId] = { checkingStatus: false };
      return state.merge(checkingStatusForVotes, { deep: true });
    case REGISTER_TO_VOTE.SUCCESS:
      const registeredVotes = {};
      registeredVotes[action.payload.voteId] = { registered: true };
      return state.merge(registeredVotes, { deep: true });
    case ENDORSE.SUCCESS:
      const { votingToken: endorseVotingToken } = action.payload;
      const endorsedVotes = {
        [action.payload.voteId]: {
          endorsed: true,
          votedAt: Date.now(),
          votingToken: endorseVotingToken,
        },
      };
      return state.merge(endorsedVotes, { deep: true });
    case FLAG.SUCCESS:
      const { votingToken: flagVotingToken } = action.payload;
      const flaggedVotes = {
        [action.payload.voteId]: {
          endorsed: false,
          votedAt: Date.now(),
          votingToken: flagVotingToken,
        },
      };
      return state.merge(flaggedVotes, { deep: true });
    case CAST.START:
      const { voteId: castingVoteId } = action.payload;
      if (castingVoteId) {
        return state.merge(
          {
            [castingVoteId]: {
              casting: true,
            },
          },
          { deep: true }
        );
      }
      return state;
    case CAST.SUCCESS:
      const { voteId: castedVoteId } = action.payload;
      if (castedVoteId) {
        return state.merge(
          {
            [castedVoteId]: {
              casting: false,
            },
          },
          { deep: true }
        );
      }
      return state;
    case CAST.FAILURE:
      const { voteId: castFailedVoteId } = action.payload;
      if (castFailedVoteId) {
        return (
          state.merge({
            [castFailedVoteId]: {
              casting: false,
            },
          }),
          { deep: true }
        );
      }
      return state;
    default:
      return state;
  }
});

export const selector = createEntitiesSelector(schema);
