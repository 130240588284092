import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';
import * as badgeActionTypes from './action-types';

const defaultPerPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50;

const listingInitialState = Immutable({
  ids: [],
  fetching: false,
  currentPage: null,
  perPage: defaultPerPage,
  totalBadges: 0,
  sort: { field: '', order: null },
  matchingBadges: 0,
});

function listing(state = listingInitialState, action) {
  switch (action.type) {
    case badgeActionTypes.FETCH_BADGES.REQUEST:
      const { pageNo: fetchingPage, perPage: fetchingPerPage, sort: fetchingSort } = action.payload;
      return state.merge({
        ids: [],
        fetching: true,
        currentPage: fetchingPage,
        perPage: fetchingPerPage || defaultPerPage,
        totalBadges: 0,
        sort: fetchingSort,
        matchingBadges: 0,
      });
    case badgeActionTypes.FETCH_BADGES.SUCCESS:
      const { pageNo: currentPage } = action.payload.input;
      const { badges: fetchedBadgeIds, totalBadges, matchingBadges } = action.payload.result;

      return state.merge({
        ids: fetchedBadgeIds,
        fetching: false,
        currentPage,
        totalBadges,
        matchingBadges: typeof matchingBadges === 'number' ? matchingBadges : totalBadges,
        error: null,
      });
    case badgeActionTypes.FETCH_BADGES.FAILURE:
      return state.merge({
        ids: [],
        fetching: false,
      });
    case badgeActionTypes.DELETE_BADGE.SUCCESS:
      const idInListing = state.ids.indexOf(action.payload.prettyId) >= 0;
      if (!idInListing) return state;

      return state.merge(
        {
          ids: state.ids.filter(id => id !== action.payload.prettyId),
          totalBadges: Math.max(0, state.totalBadges - 1),
          matchingBadges: Math.max(0, state.matchingBadges - 1),
        },
        { deep: true }
      );
    default:
      return state;
  }
}

function byPrettyId(state = Immutable({}), action) {
  switch (action.type) {
    case badgeActionTypes.DELETE_BADGE.REQUEST:
      return state.merge(
        {
          [action.payload.prettyId]: {
            deleting: true,
          },
        },
        { deep: true }
      );

    case badgeActionTypes.DELETE_BADGE.SUCCESS:
      return state.merge(
        {
          [action.payload.prettyId]: {
            deleting: false,
          },
        },
        {}
      );

    case badgeActionTypes.DELETE_BADGE.FAILURE:
      return state.merge(
        {
          [action.payload.prettyId]: {
            deleting: false,
          },
        },
        { deep: true }
      );
    default:
      return state;
  }
}

export default combineReducers({
  listing,
  byPrettyId,
});
