import { createSelector } from 'reselect';

export const selectAcceptInviteState = state => {
  return state.VerifyTokenCompanyAdmin;
};

export const selectIsAccepting = createSelector(
  selectAcceptInviteState,
  (state, props) => state.updating
);

export const selectAcceptError = createSelector(selectAcceptInviteState, (state, props) => {
  return state.error;
});

export const selectCurrentAcceptedCompany = createSelector(
  selectAcceptInviteState,
  (state, props) => state.acceptedCompany
);
