import { call, put, fork, takeEvery } from 'redux-saga/effects';

import * as actionTypes from './action-types';
import * as actions from './actions';
import callApi from 'store/api/saga';
import * as api from 'api/config/users';
import { normalize } from 'normalizr';
import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';

const ethereumNetwork = process.env.REACT_APP_SC_NETWORK;

function* fetchSmartContractByName({ payload }) {
  const { contractName } = payload;
  yield put(actions.fetchSmartContractByName.start({ contractName }));

  try {
    const response = yield call(
      callApi,
      api.getSmartContractDeatils({ network: ethereumNetwork, contractName: contractName })
    );

    const schema = schemas.smartContract;
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));

    yield put(
      actions.fetchSmartContractByName.success({
        contractName: contractName,
      })
    );
  } catch (error) {
    yield put(
      actions.fetchSmartContractByName.failure({ contractName: contractName, error: error.message })
    );
  }
}

function* watchFetchSmartContractByName() {
  yield takeEvery(actionTypes.FETCH_SMART_CONTRACT_BY_NAME.REQUEST, fetchSmartContractByName);
}

export default function* smartContracts() {
  yield fork(watchFetchSmartContractByName);
}
