import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';
import { errorsToSingleMessage } from 'utils/graphql-errors';

const initialState = Immutable({
  data: [],
  fetching: false,
  updating: false,
  error: false,
  aipSkills: [],
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_AIP_SKILLS.START: {
      return state.set('fetching', true);
    }

    case actionTypes.FETCH_AIP_SKILLS.FAILURE: {
      const { errors } = action.payload;
      return state.set('fetching', false).set('error', errorsToSingleMessage({ errors }));
    }

    case actionTypes.FETCH_AIP_SKILLS.SUCCESS: {
      const { aipSkills } = action.payload;
      return state
        .set('fetching', false)
        .set('error', false)
        .set('aipSkills', aipSkills);
    }

    default:
      return state;
  }
}
