/*
 * StartAssignmentBtn Messages
 *
 * This contains all the text for the StartAssignmentBtn component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  startAssignmentBtn: {
    id: 'graphql.StartAssignmentBtn.startAssignmentBtn',
    defaultMessage: 'Start Challenge',
  },
  submitAssignmentBtn: {
    id: 'graphql.StartAssignmentBtn.submitAssignmentBtn',
    defaultMessage: 'Submit Challenge',
  },
});
