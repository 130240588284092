import { createSelector } from 'reselect';

const selectSmartRouteFeatureState = state => state.features.smartRoute;

export const selectRouteAfterClaimCreation = createSelector(
  selectSmartRouteFeatureState,
  state => state.routeAfterClaimCreation
);

export const selectRouteAfterQuizbotUsage = createSelector(
  selectSmartRouteFeatureState,
  state => state.routeAfterQuizbotUsage
);

export const selectRouteAfterSignUp = createSelector(
  selectSmartRouteFeatureState,
  state => state.routeAfterSignUp
);

export const selectRouteAfterClaimVerify = createSelector(
  selectSmartRouteFeatureState,
  state => state.routeAfterClaimVerify
);

export const selectRouteAfterEmailVerify = createSelector(
  selectSmartRouteFeatureState,
  state => state.routeAfterEmailVerify
);

export const selectIsClaimVerify = createSelector(
  selectSmartRouteFeatureState,
  state => state.isClaimVerify
);

export const selectIsEmailVerify = createSelector(
  selectSmartRouteFeatureState,
  state => state.isEmailVerify
);

export const selectLocationState = createSelector(
  selectSmartRouteFeatureState,
  state => state.locationState
);
