import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { connectModal as reduxModal } from 'redux-modal';
import { submit } from 'redux-form';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';

import Button from 'ui/common/button';
import { deleteWorkExperienceEntry } from 'store/features/work-experience/actions';
import { selectWorkExperienceEntryById } from 'store/features/work-experience/selectors';

const {
  confirm: deleteWorkExperienceEntryConfirm,
  cancel: deleteWorkExperienceEntryCancel,
} = deleteWorkExperienceEntry;

const messages = defineMessages({
  title: {
    id: 'delete-work-experience-entry-confirmation-dialog.title',
    defaultMessage: 'Delete',
  },
  content: {
    id: 'delete-work-experience-entry-confirmation-dialog.content',
    defaultMessage: 'Are you sure you want to delete "{entryName}" from work experience?',
  },
  defaultEntryName: {
    id: 'delete-work-experience-entry-confirmation-dialog.defaultEntryName',
    defaultMessage: 'this entry',
  },
  confirm: {
    id: 'delete-work-experience-entry-confirmation-dialog.confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'delete-work-experience-entry-confirmation-dialog.cancel',
    defaultMessage: 'Cancel',
  },
});

export const MODAL_NAME = id => `delete-work-experience-entry-confirmation-dialog-${id}`;

class DeleteWorkExperienceEntryConfirmationDialog extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  confirm() {
    const { id } = this.props;
    this.props.deleteWorkExperienceEntryConfirm({ id });
  }

  cancel() {
    const { id } = this.props;
    this.props.deleteWorkExperienceEntryCancel({ id });
  }

  render() {
    const { entry, show, handleHide, intl: { formatMessage } } = this.props;

    return (
      <Modal isOpen={show}>
        <ModalHeader toggle={handleHide} />
        <ModalBody>
          <FormattedMessage
            {...messages.content}
            values={{
              entryName: entry
                ? [entry.title, entry.companyName].filter(val => val).join(', ')
                : formatMessage(messages.defaultEntryName),
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.confirm}>
            {formatMessage(messages.confirm)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { id } = ownProps;

  return {
    id,
    entry: id ? selectWorkExperienceEntryById(state, { id }) : undefined,
  };
}

const mapDispatchToProps = {
  submit,
  deleteWorkExperienceEntryConfirm,
  deleteWorkExperienceEntryCancel,
};

const ConnectedDeleteWorkExperienceEntryConfirmationDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(DeleteWorkExperienceEntryConfirmationDialog));

export default function({ id }) {
  const name = MODAL_NAME(id);
  const WrappedModal = reduxModal({
    name,
  })(ConnectedDeleteWorkExperienceEntryConfirmationDialog);
  return <WrappedModal id={id} />;
}
