import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connectModal as reduxModal } from 'redux-modal';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import Button from 'ui/common/button';

import routeGenerators from 'ui/common/routes/generators';

export const MODAL_NAME = 'PayForClaimReportSuccessModal';

class SuccessModal extends Component {
  render() {
    const { reportId, show, handleHide } = this.props;

    return (
      <Modal isOpen={show} toggle={handleHide}>
        <ModalHeader />
        <ModalBody>
          <h1 className="typography-section-headline-small d-flex flex-column">
            <i className="fa fa-check-circle text-success pb-3 align-self-center fa-3x" />
            <FormattedMessage
              id="graphql.PayForClaimReport.success.title"
              defaultMessage="Your payment was successful"
            />
          </h1>
          <p className="typography-hl-sub">
            <FormattedMessage
              id="graphql.PayForClaimReport.success.subtitle"
              defaultMessage="You can view the full report by clicking on the button below"
            />
          </p>
          <div className="d-flex justify-content-center">
            <Button to={routeGenerators.claimReports.details({ reportId })}>
              <i className="fa fa-eye mr-2" />
              <FormattedMessage
                id="graphql.PayForClaimReport.success.viewReport"
                defaultMessage="View Report"
              />
            </Button>
          </div>
        </ModalBody>
        <ModalFooter />
      </Modal>
    );
  }
}

export default reduxModal({ name: MODAL_NAME })(injectIntl(SuccessModal));
