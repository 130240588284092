import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckboxIcon from './CheckboxIcon';
import './styles.scss';

export class Checkbox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked,
      isDisabled: this.props.isDisabled,
    };
  }

  handleChange = evt => {
    if (!this.state.isDisabled) {
      this.setState(prevState => {
        const nextChange = !prevState.isChecked;
        this.props.onChange(nextChange);

        return {
          isChecked: nextChange,
        };
      });
    }
  };

  render() {
    const className = this.props.className;
    const labelClasses = classNames('checkbox-label w-100');
    const wrapperClasses = classNames(
      className,
      'form-item',
      'checkbox-wrapper',
      { 'is-checked': this.state.isChecked },
      { 'is-disabled': this.state.isDisabled }
    );

    const { id, errorMessage } = this.props;

    const errorId = id + '-error-msg';

    const error = errorMessage ? (
      <div>
        <div className="form-requirement" id={errorId} />
        {errorMessage}
      </div>
    ) : null;

    return (
      <div className={classNames(this.props.widthClassName, this.props.wrapperClassName)}>
        <label htmlFor={this.props.id} className={wrapperClasses}>
          <CheckboxIcon />
          <input
            type="checkbox"
            // checked={this.state.isChecked}
            onChange={this.handleChange}
            className="checkbox-input input-hidden"
            id={this.props.id}
          />
          <span className={labelClasses}>{this.props.labelText}</span>
        </label>
        {this.props.hasError && <p className="text-danger">{error}</p>}
      </div>
    );
  }
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  labelText: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  wrapperClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
};

Checkbox.defaultProps = {
  onChange: () => {},
  isDisabled: false,
  wrapperClassName: 'py-2',
};

export default Checkbox;
