import React from 'react';
import classnames from 'classnames';
import { Progress } from 'reactstrap';
import styles from './styles.module.scss';
import './styles.scss';

export default ({ label, ...passThrough }) => {
  const { min, max, value } = passThrough;
  const percent = value <= max && max !== 0 ? value / max * 100 : 0;

  return (
    <div className={styles.progressContainer}>
      <div
        className={classnames(styles.circle)}
        style={{
          left: `${percent}%`,
        }}
      >
        {value}
      </div>
      {label}
      <Progress {...passThrough} className="my-3" />
      <span className="d-flex justify-content-between mt-1">
        <div>{min}</div>
        <div className="text-right">{max}</div>
      </span>
    </div>
  );
};
