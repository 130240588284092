import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Image = ({ className, imgProps, id, ...other }) => {
  return (
    <img alt="" id={id} className={classnames(styles.img, className)} {...imgProps} {...other} />
  );
};

Image.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  alt: PropTypes.string,
  srcSet: PropTypes.string,
  sizes: PropTypes.string,
  src: PropTypes.string,
};

Image.defaultProps = {
  imgProps: {
    alt: 'This is an example alt text.',
    src: 'https://picsum.photos/627/375/?blur',
  },
};

export default Image;
