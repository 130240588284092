import { workExperienceEntry as schema } from 'store/common/schemas';
import { createEntitiesReducer, createEntitiesSelector } from './helpers';

import { DELETE_WORK_EXPERIENCE_ENTRY } from 'store/features/work-experience/action-types';

export const reducer = createEntitiesReducer(schema, (state, action) => {
  switch (action.type) {
    case DELETE_WORK_EXPERIENCE_ENTRY.SUCCESS:
      return state.without(action.payload.id);
    default:
      return state;
  }
});

export const selector = createEntitiesSelector(schema);
