import { createSelector } from 'reselect';

const selectConfirmationDialogFeatureState = state => state.features.common.confirmationDialog;

export const selectIsOpen = createSelector(
  selectConfirmationDialogFeatureState,
  confirmationDialog => confirmationDialog.open
);

export const selectMessage = createSelector(
  selectConfirmationDialogFeatureState,
  confirmationDialog => confirmationDialog.message
);
