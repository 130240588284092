import { startCaseHandleSpecialChars } from 'utils/string';

const skillNameExceptions = {
  // key should always be lower case
  php: 'PHP',
  html: 'HTML',
  javascript: 'JavaScript',
};

export function getSkillDisplayName({ skill: { name } = {} } = {}) {
  if (!name) return null;
  if (skillNameExceptions[name.toLowerCase()]) {
    return skillNameExceptions[name.toLowerCase()];
  } else {
    return startCaseHandleSpecialChars(name);
  }
}
