import React from 'react';
import { Helmet } from 'react-helmet';

const MetamorphHelmet = () => {
  return (
    <Helmet
      titleTemplate={`%s | Become the leader that developers trust | Metamorph by Indorse`}
      defaultTitle={'Indorse | Become the leader that developers trust'}
    >
      <meta name="title" content="Become the leader that developers trust | Metamorph by Indorse" />
      <meta
        name="description"
        data-react-helmet="true"
        content="Insights and recommendations for you to keep up the morale and performance of your remote tech team."
      />
      <meta
        property="og:title"
        content="Become the leader that developers trust | Metamorph by Indorse"
        data-react-helmet="true"
      />
      <meta
        property="og:description"
        content="Insights and recommendations for you to keep up the morale and performance of your remote tech team."
        data-react-helmet="true"
      />
      <meta
        property="og:site_name"
        content="Become the leader that developers trust | Metamorph by Indorse"
        data-react-helmet="true"
      />
      <meta
        property="og:image"
        content="https://indorse.io/brand-meta.png"
        data-react-helmet="true"
      />

      <meta
        property="twitter:title"
        content="Insights & recommendations for high-performing "
        data-react-helmet="true"
      />
      <meta
        property="twitter:description"
        content="Insights and recommendations for you to keep up the morale and performance of your remote tech team."
        data-react-helmet="true"
      />
      <meta
        property="twitter:site"
        content="Become the leader that developers trust | Metamorph by Indorse"
        data-react-helmet="true"
      />
      <meta
        property="twitter:image"
        content="https://indorse.io/brand-meta.png"
        data-react-helmet="true"
      />
      <meta property="twitter:card" content="summary_large_image" data-react-helmet="true" />
    </Helmet>
  );
};

export default MetamorphHelmet;
