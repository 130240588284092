import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const fetchRatingCriteriaDetails = {
  request: ({ ratingCriteriaKeys }) =>
    defineAction(types.FETCH_RATING_CRITERIA_DETAILS.REQUEST, { ratingCriteriaKeys }),
  start: ({ ratingCriteriaKeys }) =>
    defineAction(types.FETCH_RATING_CRITERIA_DETAILS.START, { ratingCriteriaKeys }),
  success: ({ ratingCriteriaKeys, result }) =>
    defineAction(types.FETCH_RATING_CRITERIA_DETAILS.SUCCESS, { ratingCriteriaKeys, result }),
  failure: ({ ratingCriteriaKeys, error }) =>
    defineAction(types.FETCH_RATING_CRITERIA_DETAILS.FAILURE, { ratingCriteriaKeys, error }),
};
