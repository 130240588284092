import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.scss';

const OverflowMenuHeader = ({ children, closeMenu, className, ...other }) => {
  const classNames = classnames('overflow-menu-head', className);

  return (
    <li className={classNames} {...other}>
      {children}
    </li>
  );
};

OverflowMenuHeader.propTypes = {
  children: PropTypes.node,
  /**
   * A callback to tell the parent menu component that the menu should be closed.
   */
  closeMenu: PropTypes.func,
  className: PropTypes.string,
};

export default OverflowMenuHeader;
