export const fieldNames = {
  howItBenefits: 'howItBenefits',
  howItWorks: 'howItWorks',
  featuresMissing: 'featuresMissing',
  suggestions: 'suggestions',
};

export default {
  [fieldNames.howItBenefits]: {
    presence: false,
  },
  [fieldNames.howItWorks]: {
    presence: false,
  },
  [fieldNames.featuresMissing]: {
    presence: false,
  },
  [fieldNames.suggestions]: {
    presence: false,
  },
};
