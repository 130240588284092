/*
 * PayForClaimReportButton Messages
 *
 * This contains all the text for the PayForClaimReportButton component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  viewReport: {
    id: 'graphql.PayForClaimReportButton.viewReport',
    defaultMessage: 'View Full Report',
  },
  viewReportWithPrice: {
    id: 'graphql.PayForClaimReportButton.viewReportWithPrice',
    defaultMessage: 'View Full Report for {currencySymbol}{amount} {currency}',
  },
  isPaying: {
    id: 'graphql.PayForClaimReportButton.isPaying',
    defaultMessage: 'Processing...',
  },
});
