import { defineMessages } from 'react-intl';

export const header = defineMessages({
  title: {
    id: 'page.heroV2.header.title',
    defaultMessage: 'Validate your skills',
  },
});

export const content = defineMessages({
  scroll: {
    id: 'page.heroV2.content.scroll',
    defaultMessage: 'Learn More',
  },
  photoCaption: {
    id: 'page.heroV2.content.photoCaption',
    defaultMessage: 'Photo caption in two lines',
  },
});
