export const sortOrders = {
  ascending: 'asc',
  descending: 'desc',
};

export function toggleOrder(order, defaultOrder = sortOrders.ascending) {
  if (order === sortOrders.ascending) return sortOrders.descending;
  if (order === sortOrders.descending) return sortOrders.ascending;

  return defaultOrder;
}

export function parseSortParameterURLValue(value = '') {
  if (value && value.length > 0) {
    if (value[0] === '-') return { field: value.substring(1), order: sortOrders.descending };
    else return { field: value, order: sortOrders.ascending };
  }

  return undefined;
}

export function generateSortParameterURLValue({ field = '', order = sortOrders.ascending } = {}) {
  if (field && field.length > 0) {
    if (order === sortOrders.descending) return `-${field}`;
    else return field;
  }

  return '';
}

export function generateSortParameterGQLValue({ field = '', order = sortOrders.ascending } = {}) {
  if (field && field.length > 0) {
    if (order === sortOrders.descending) return `desc_${field}`;
    else return `asc_${field}`;
  }

  return '';
}

export function generateSortParameterMongooseGQLValue(
  { field = '', order = sortOrders.ascending } = {}
) {
  if (field && field.length > 0) {
    if (order === sortOrders.descending) return `${field}_desc`.toUpperCase();
    else return `${field}_asc`.toUpperCase();
  }

  return '';
}
