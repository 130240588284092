export function compareNumber(left, right) {
  if (!left || !right) {
    if (left) return 1;
    if (right) return -1;
    return 0;
  }

  if (left > right) return 1;
  if (left < right) return -1;
  return 0;
}
