/*
  EmsPricingWizard
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';

import Helmet from 'react-helmet';
import { addMessage } from 'store/features/common/flash/actions';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import { show as showModal } from 'redux-modal';
import { logEvent } from 'services/amplitude-helper';

import TextInput from 'ui/common/rebranding/components/TextInput';
import { messageTypes } from 'store/features/common/flash/builder';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import TextField from 'ui/common/form/fields/text-field';
import validator from 'ui/common/form/validator';
import fields, { fieldNames } from './model';
import * as messages from './messages';
import routeTemplates from 'ui/common/routes/templates';
import StripeForm from './stripe-checkout';

import { Card } from 'ui/common/card';
import { withRouter } from 'react-router';
import styles from './index.module.scss';
import { Step, Steps } from 'ui/common/stepper';
import Button from 'ui/common/button';
import * as flashActions from 'store/features/common/flash/actions';
import StripePaymentComponent from 'ui/components/stripe-payment-component';
import WaitlistModal, {
  MODAL_NAME as EMS_WAITLIST_MODAL,
} from 'ui/pages/ems/hero/waitlist-modal.js';

const FORM_NAME = 'ems-pricing-email';

const validate = validator(fields);

const PRICING_STEPS = {
  registration: 0,
  selectPlan: 1,
  payment: 2,
};

const PLAN_TYPES = {
  growth: 'growth',
  premium: 'premium',
  enterprise: 'enterprise',
};

class EmsPricingWizard extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.initialState = {
      setupStep: PRICING_STEPS.registration,
      growthMonthly: null,
      growthAnnually: null,
      premiumMonthly: null,
      premiumAnnually: null,
      selectedPlan: null,
      discountedPlan: null,
      params: null,
      email: null,
      done: false,
      applyCoupon: false,
      couponName: '',
      isCouponApplied: false,
      couponValue: null,
    };

    this.state = this.initialState;
  }

  componentDidMount() {
    this.getPlanDetails();
  }

  getSelectedPlan() {
    const {
      growthAnnually,
      growthMonthly,
      premiumAnnually,
      premiumMonthly,
      params,
      couponName,
      couponValue,
    } = this.state;
    const { history } = this.props;

    let selectedPlan, discountedPlan;
    if (params.plan === PLAN_TYPES.growth) {
      selectedPlan = params.isAnnually ? growthAnnually : growthMonthly;
      discountedPlan = params.isAnnually ? growthAnnually : growthMonthly;
    } else if (params.plan === PLAN_TYPES.premium) {
      selectedPlan = params.isAnnually ? premiumAnnually : premiumMonthly;
      discountedPlan = params.isAnnually ? premiumAnnually : premiumMonthly;
    } else {
      history.push(routeTemplates.emsPricingPage);
    }

    if (couponName !== '') {
      discountedPlan = params.isAnnually ? selectedPlan * 12 : discountedPlan;
      discountedPlan = discountedPlan - couponValue / 100 * discountedPlan;
    } else {
      discountedPlan = params.isAnnually ? selectedPlan * 12 : discountedPlan;
    }
    this.setState({
      selectedPlan: params.isAnnually ? selectedPlan * 12 : selectedPlan,
      discountedPlan,
    });
  }

  async getPlanDetails() {
    const { client, params, history } = this.props;

    if (!params || !params.plan) history.push(routeTemplates.emsPricingPage);

    try {
      const response = await client.query({
        query: gql`
          query getEMSPlans {
            getEMSPlans {
              planType
              subscriptionType
              price {
                currency
                amountInCents
              }
            }
          }
        `,
      });

      const { data, errors } = response;

      if (errors && errors[0].message) {
        flashActions.addMessage({
          id: 'ems-plans.error',
          kind: 'danger',
          content: errors[0].message,
        });
      } else if (data && data.getEMSPlans) {
        const growthMonthlyObj = data.getEMSPlans.find(
          plan => plan.planType === 'GROWTH' && plan.subscriptionType === 'MONTHLY'
        );
        const growthAnnuallyObj = data.getEMSPlans.find(
          plan => plan.planType === 'GROWTH' && plan.subscriptionType === 'YEARLY'
        );
        const premiumMonthlyObj = data.getEMSPlans.find(
          plan => plan.planType === 'PREMIUM' && plan.subscriptionType === 'MONTHLY'
        );
        const premiumAnnuallyObj = data.getEMSPlans.find(
          plan => plan.planType === 'PREMIUM' && plan.subscriptionType === 'YEARLY'
        );

        this.setState(
          {
            growthMonthly: growthMonthlyObj.price.amountInCents / 100,
            growthAnnually: growthAnnuallyObj.price.amountInCents / (100 * 12),
            premiumMonthly: premiumMonthlyObj.price.amountInCents / 100,
            premiumAnnually: premiumAnnuallyObj.price.amountInCents / (100 * 12),
            params,
          },
          this.getSelectedPlan
        );
      }
    } catch (error) {
      flashActions.addMessage({
        id: 'ems-plans-get.error',
        kind: 'danger',
        content: error.message,
      });
    }
  }

  async getCouponDiscount(coupon) {
    const { discountedPlan } = this.state;
    const { client, addMessage } = this.props;

    try {
      const response = await client.query({
        query: gql`
          query getCoupon($coupon: String!) {
            getCoupon(coupon: $coupon) {
              name
              percent_off
            }
          }
        `,
        variables: {
          coupon,
        },
      });

      const { data, errors } = response;

      if (resultHasErrors({ errors })) {
        this.setState({
          couponName: '',
        });
        addMessage({
          kind: messageTypes.danger,
          content: `${coupon}: Invalid promo code`,
          disableTimeout: true,
        });
      } else {
        const { getCoupon } = data;
        this.setState({
          couponName: getCoupon.name,
          isCouponApplied: true,
          discountedPlan: discountedPlan - getCoupon.percent_off / 100 * discountedPlan,
          couponValue: getCoupon.percent_off,
        });
      }
    } catch (error) {
      addMessage({
        kind: messageTypes.danger,
        content: 'Promo code could not be applied. Contact us!',
        disableTimeout: true,
      });
    }
  }

  async addToWaitlist() {
    const { email } = this.props;
    const { client, addMessage } = this.props;

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_waitlist($email: String!) {
            ems_waitlist(email: $email)
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
      } else {
        this.props.showModal(EMS_WAITLIST_MODAL);
      }
    } catch (error) {
      console.log(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
    }
  }

  getMonthlyPrice() {
    const { params, growthMonthly, premiumMonthly } = this.state;
    if (!params) return;
    if (params.plan === PLAN_TYPES.growth) return growthMonthly;

    return premiumMonthly;
  }

  getAnnualPrice() {
    const { params, premiumAnnually, growthAnnually } = this.state;
    if (!params) return;
    if (params.plan === PLAN_TYPES.growth) return growthAnnually;

    return premiumAnnually;
  }

  getToNextFunc() {
    const { setupStep } = this.state;

    if (setupStep === PRICING_STEPS.registration) {
      this.setState({
        email: this.props.email,
      });
    }

    this.setState({
      setupStep: setupStep + 1,
    });
  }

  selectMonthly = () => {
    const { params } = this.state;
    const newParams = {
      ...params,
      isAnnually: false,
    };

    this.setState(
      {
        params: newParams,
      },
      this.getSelectedPlan
    );
  };

  selectAnnually = () => {
    const { params } = this.state;
    const newParams = {
      ...params,
      isAnnually: true,
    };

    this.setState(
      {
        params: newParams,
      },
      this.getSelectedPlan
    );
  };

  renderNextButton() {
    const { setupStep } = this.state;
    return setupStep === PRICING_STEPS.selectPlan ? (
      <div className="row justify-content-center mt-4">
        <Button
          onClick={() => {
            logEvent('ems_pricing_next_clicked');
            this.getToNextFunc();
          }}
          textCenter
          className={classnames(styles.stepBtn, 'px-5')}
        >
          Next <i className="fa fa-arrow-right ml-2" />
        </Button>
      </div>
    ) : null;
  }

  renderSteps() {
    const { setupStep } = this.state;
    return (
      <Steps
        current={setupStep}
        icons={{ finish: <i className="fa fa-check" /> }}
        size="large"
        direction="vertical"
      >
        <Step style={{ height: '120px' }} title={<h6>Registration</h6>} />
        <Step style={{ height: '120px' }} title={<h6>Select a Plan</h6>} />
        <Step style={{ height: '120px' }} title={<h6>Payment</h6>} />
      </Steps>
    );
  }

  getAmount() {
    const { params, growthMonthly, premiumMonthly } = this.state;

    let displayAmount;

    if (!params) return;
    if (params.plan === PLAN_TYPES.growth) {
      if (params.isAnnually) displayAmount = growthMonthly * 12;
      else displayAmount = growthMonthly;
    }

    if (params.plan === PLAN_TYPES.premium) {
      if (params.isAnnually) displayAmount = premiumMonthly * 12;
      else displayAmount = premiumMonthly;
    }

    return displayAmount;
  }

  getDiscount() {
    const { selectedPlan } = this.state;
    const displayAmount = this.getAmount();
    return Math.abs(selectedPlan - displayAmount) || 0;
  }

  removePromoCode() {
    this.setState({
      discountedPlan: null,
      applyCoupon: false,
      couponName: '',
      isCouponApplied: false,
      selectedPlan: null,
      couponValue: null,
    });
    this.getPlanDetails();
  }

  renderPlanSummary() {
    const {
      selectedPlan,
      done,
      isCouponApplied,
      applyCoupon,
      couponName,
      setupStep,
      discountedPlan,
    } = this.state;
    return (
      <div className={classnames(styles.orderCard, 'col-md-4')}>
        <Card borderless className={classnames('p-3')}>
          <h2 className={classnames(styles.orderSummary, 'px-2')}>
            {done ? 'E-Receipt' : 'Order Summary'}
          </h2>
          <div className="d-flex row justify-content-between px-4">
            <h3 className={styles.plan}>Plan</h3>
            <h3 className={styles.planType}>Premium</h3>
          </div>
          <div className="d-flex row justify-content-between px-4">
            <h3 className={styles.amount}>Amount</h3>
            <h3 className={styles.amountVal}>${this.getAmount()}.00</h3>
          </div>
          <div className="d-flex mt-3 row justify-content-between px-4">
            <h3 className={styles.charge}>Annual plan discount</h3>
            <h3 className={styles.chargeVal}>- ${this.getDiscount()}.00</h3>
          </div>

          {isCouponApplied && (
            <div className="d-flex mt-1 row justify-content-between px-4">
              <h3 className={styles.charge}>Promo discount</h3>
              <h3 className={styles.chargeVal}>- ${(selectedPlan - discountedPlan).toFixed(2)}</h3>
            </div>
          )}

          {setupStep !== PRICING_STEPS.registration && (
            <div className="d-flex">
              {applyCoupon ? (
                <React.Fragment>
                  {' '}
                  <div className="ml-2">
                    <TextInput
                      id="coupon"
                      value={couponName}
                      className="border border-secondary rounded"
                      placeholder="Coupon Code"
                      style={{ padding: '2%' }}
                      disabled={isCouponApplied}
                      onChange={e => this.setState({ couponName: e.target.value })}
                    />{' '}
                  </div>
                  <div className="mt-1 pt-2 ml-2">
                    {!isCouponApplied && (
                      <Button
                        className="py-1"
                        isBlock
                        disabled={couponName === '' || isCouponApplied}
                        textCenter
                        style={{ padding: '23px' }}
                        onClick={() =>
                          this.getCouponDiscount(document.getElementById('coupon').value)}
                      >
                        Apply
                      </Button>
                    )}
                    {isCouponApplied && (
                      <Button className="py-1" onClick={() => this.removePromoCode()}>
                        Remove
                      </Button>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <p
                  className="ml-2"
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => this.setState({ applyCoupon: true })}
                >
                  +Apply Promo Code
                </p>
              )}
            </div>
          )}
          <hr className={styles.line} />
          <div className="d-flex mt-3 row justify-content-between px-4">
            {/*{TODO: Add a line here saying not charging today}*/}
            <h3 className={styles.totalAmount}>Total Amount</h3>
            <h3 className={styles.totalAmountVal}>
              {' '}
              $
              {selectedPlan
                ? selectedPlan > discountedPlan
                  ? `${discountedPlan.toFixed(2)}`
                  : `${selectedPlan}.00`
                : `0.00`}
            </h3>
          </div>
        </Card>
      </div>
    );
  }

  renderRegistration() {
    const { intl: { formatMessage }, invalid } = this.props;
    return (
      <div>
        <h1 className="ml-2 text-center">Registration</h1>
        <h3 className="ml-2 mt-4 text-center">Sign up with Email</h3>

        <div className={classnames(styles.emailInput, 'mt-3 mx-5 px-5')}>
          <Field
            name={fieldNames.adminEmail}
            component={TextField}
            label={formatMessage(messages.label[fieldNames.adminEmail])}
            hideLabel
            hint={formatMessage(messages.label[fieldNames.adminEmail])}
            className={classnames(styles.email, 'border border-secondary rounded')}
            data-hj-whitelist
          />
        </div>
        <div className="text-center row justify-content-center position-relative mt-2 mb-5">
          <Button
            onClick={() => {
              logEvent('ems_pricing_register_clicked');
              this.getToNextFunc();
            }}
            textCenter
            className={classnames(styles.stepBtn, 'px-5')}
            disabled={invalid}
          >
            Register
          </Button>
        </div>
        {/* <h3 className="ml-2 mt-4 text-center">Or, Register with Google</h3>
        <div className="text-center row justify-content-center position-relative mt-3">
          <Button
            // onClick={this.getToNextFunc}
            textCenter
            className={classnames(styles.stepBtn, 'px-5')}
            disabled
            isReady
          >
            <i className="fab fa-google mr-2" />
            Google
          </Button>
        </div> */}

        <div className="text-center row justify-content-center position-relative mt-4">
          <small className="col-md-9 px-4 text-muted">
            By clicking "Register" button, you are creating an Indorse account, and you agree to
            Indorse's <a href="https://indorse.io/terms-of-use">Terms of Use</a> and{' '}
            <a href="https://indorse.io/privacy-policy">Privacy Policy</a>
          </small>
        </div>
        <WaitlistModal />
      </div>
    );
  }

  renderSelectPlan() {
    const { params } = this.state;
    return (
      <div>
        <h1 className="ml-2 text-center">Select a Plan</h1>
        <div className="row justify-content-center mt-4">
          <div className="col-md-6">
            <div
              className={classnames(
                styles.planBox,
                `${params && !params.isAnnually && styles.active}`,
                'p-3 mx-0 text-center'
              )}
              onClick={this.selectMonthly}
            >
              <h2>Monthly</h2>
              <h3 className={styles.price}>
                ${this.getMonthlyPrice()} <span> / mo </span>
              </h3>
              <h3 className={classnames(styles.billed, 'mt-3')}>Billed monthly</h3>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className={classnames(
                styles.planBox,
                `${params && params.isAnnually && styles.active}`,
                'p-3 mx-0 text-center'
              )}
              onClick={this.selectAnnually}
            >
              <h2>Yearly</h2>
              <h3 className={styles.price}>
                ${this.getAnnualPrice()} <span> / mo </span>
              </h3>
              <h3 className={classnames(styles.billed, 'mt-3')}>
                ${this.getAnnualPrice() * 12}.00 Billed annually
              </h3>
            </div>
            <div className="text-center mt-2">
              <h3 className="text-muted">Save 20% on a annual plan</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pt-3">
          <h3 className="text-center w-75">
            <b>Note:</b> You won't be charged immediately. Your billing starts once the free trial
            ends.
          </h3>
        </div>
      </div>
    );
  }

  setDone = () => {
    this.setState({ done: true });
  };

  renderPayment() {
    const { params, email, done, couponName } = this.state;
    return (
      <div>
        <h1 className="ml-2 text-center">{!done && 'Payment'}</h1>
        <StripePaymentComponent>
          <StripeForm
            params={params}
            email={email}
            setDone={this.setDone}
            couponName={couponName}
          />
        </StripePaymentComponent>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.background)}>
        <Helmet>
          <title>Metamorph | Order</title>
        </Helmet>
        <div className="container company-page-max-width mt-3 pt-5">
          <div className="row py-3 justify-content-center">
            <div className="row col-11">
              <div className="col-md-2 pl-5">{this.renderSteps()}</div>
              <div className="col-md-6">
                <div className={'row justify-content-center'}>
                  <div className="col-md-11">
                    {this.state.setupStep === PRICING_STEPS.registration &&
                      this.renderRegistration()}
                    {this.state.setupStep === PRICING_STEPS.selectPlan && this.renderSelectPlan()}
                    {this.state.setupStep === PRICING_STEPS.payment && this.renderPayment()}
                  </div>
                </div>

                {this.renderNextButton()}
              </div>

              {this.renderPlanSummary()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const selector = formValueSelector(FORM_NAME);
  const email = selector(state, fieldNames.adminEmail);
  const query = new URLSearchParams(ownProps.location.search);
  const params = {
    plan: query.get('plan'),
    isAnnually: query.get('isAnnually') === 'true' ? true : false,
  };
  return {
    params,
    email,
  };
}

const mapDispatchToProps = {
  addMessage,
  showModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    validate,
  })(injectIntl(withApollo(withRouter(EmsPricingWizard))))
);
