import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './styles.scss';

import Avatar from '../Avatar/Avatar';

const CardTitle = ({ className, children, avatar, avatarProps, ...other }) => {
  const cardClasses = classNames(className, 'card-title', { 'no-avatar': avatar === false });

  const avatarHtml = avatar ? <Avatar {...avatarProps} /> : '';

  return (
    <div className={cardClasses} {...other}>
      {avatarHtml}
      <h2>{children}</h2>
    </div>
  );
};

CardTitle.propTypes = {
  className: PropTypes.string,
  avatarProps: PropTypes.object,
  avatar: PropTypes.bool.isRequired,
};

CardTitle.defaultProps = {
  avatar: false,
  avatarProps: {},
};

export default CardTitle;
