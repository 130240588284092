/*
 * UserClaimSurveyModal Messages
 *
 * This contains all the text for the UserClaimSurveyModal component.
 */
import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const header = defineMessages({
  title: {
    id: 'graphql.UserClaimSurveyModal.header.title',
    defaultMessage: 'Thank you for submitting your claim',
  },
  description: {
    id: 'graphql.UserClaimSurveyModal.header.description',
    defaultMessage:
      'We would like to understand your reasoning behind using the Indorse platform. What is your main motivation behind submitting this claim?',
  },
});

export const options = defineMessages({
  browseMoreJobs: {
    id: 'graphql.UserClaimSurveyModal.options.browseMoreJobs',
    defaultMessage: 'Browse more jobs',
  },
  referred: {
    id: 'graphql.UserClaimSurveyModal.options.referred',
    defaultMessage: 'Referred to by hiring manager/company',
  },
  trying: {
    id: 'graphql.UserClaimSurveyModal.options.trying',
    defaultMessage: 'Trying out the platform',
  },
  others: {
    id: 'graphql.UserClaimSurveyModal.options.others',
    defaultMessage: 'Others',
  },
});

export const labels = defineMessages({
  [fieldNames.survey]: {
    id: 'graphql.UserClaimSurveyModal.labels.survey',
    defaultMessage: 'Survey',
  },
  textFieldLabel: {
    id: 'graphql.UserClaimSurveyModal.textFieldLabel.survey',
    defaultMessage: 'If others, please specify',
  },
});

export const button = defineMessages({
  submit: {
    id: 'graphql.UserClaimSurveyModal.button.submit',
    defaultMessage: 'Submit',
  },
});
