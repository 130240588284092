import React from 'react';
import { ensureScheme } from 'utils/url';

const ExternalLink = ({
  children,
  href,
  className,
  openInNewTab,
  onClick,
  download,
  target,
  defaultScheme = 'https',
}) => {
  const finalHref = ensureScheme(href, defaultScheme);

  const linkProps = {
    href: finalHref,
    className,
    onClick,
    download,
    target,
  };

  if (openInNewTab) {
    linkProps.target = '_blank';
    linkProps.rel = 'noreferrer noopener';
  }

  return <a {...linkProps}>{children || finalHref}</a>;
};

export default ExternalLink;
