import { createMiddleware } from 'redux-beacon';
import { GoogleAnalytics } from 'redux-beacon/targets/google-analytics';
import { LOCATION_CHANGE } from 'react-router-redux';

import { SIGN_UP, LOGIN, IMPORT_LINKED_IN_PROFILE_TEST } from 'store/features/auth/action-types';
import { CREATE_CLAIM } from 'store/features/claims/action-types';
import { REGISTER_TO_VOTE, ENDORSE, FLAG } from 'store/features/votes/action-types';
import {
  ADD_EDUCATION_ENTRY,
  UPDATE_EDUCATION_ENTRY,
  DELETE_EDUCATION_ENTRY,
} from 'store/features/education/action-types';
import {
  ADD_WORK_EXPERIENCE_ENTRY,
  UPDATE_WORK_EXPERIENCE_ENTRY,
  DELETE_WORK_EXPERIENCE_ENTRY,
} from 'store/features/work-experience/action-types';
import { BLOCKCHAIN_101 } from 'store/features/blockchain-101/action-types';

import { TRACK_COMPANY_MEMBER_VERIFIED_CLICK } from 'store/features/companies/action-types';
import { TRACK_GET_BADGE_CLICK } from 'store/features/badges/action-types';
import { TRACK_MERKLE_VIZ_NODE_CLICK } from 'store/graphql/ClaimMerkleDetails/action-types';

import formEventDefinitions from './form-event-definitions';
import modalEventDefinitions from './modal-event-definitions';

const pageView = action => ({
  hitType: 'pageview',
  page: action.payload && action.payload.pathname,
});

const signupSuccess = action => ({
  hitType: 'event',
  eventCategory: 'auth',
  eventAction: 'signedUp',
});

const loginSuccess = action => ({
  hitType: 'event',
  eventCategory: 'auth',
  eventAction: 'loggedIn',
});

const createClaimSuccess = action => ({
  hitType: 'event',
  eventCategory: 'claims',
  eventAction: 'created',
  eventLabel:
    action.payload &&
    action.payload.claim &&
    action.payload.claim.length &&
    action.payload.claim[0].title,
});

const registerToVoteSuccess = action => ({
  hitType: 'event',
  eventCategory: 'votes',
  eventAction: 'registered',
  eventLabel: action.payload.claimId,
});

const indorseSuccess = action => ({
  hitType: 'event',
  eventCategory: 'votes',
  eventAction: 'indorsed',
  eventLabel: action.payload.claimId,
});

const flagSuccess = action => ({
  hitType: 'event',
  eventCategory: 'votes',
  eventAction: 'flagged',
  eventLabel: action.payload.claimId,
});

const importLinkedInProfileTest = action => ({
  hitType: 'event',
  eventCategory: 'test',
  eventAction: 'import-linkedin-profile',
});

const addEducationEntrySuccess = action => ({
  hitType: 'event',
  eventCategory: 'education',
  eventAction: 'entry-added',
  eventLabel:
    action.payload && action.payload.educationEntry && action.payload.educationEntry.schoolName,
});

const updateEducationEntrySuccess = action => ({
  hitType: 'event',
  eventCategory: 'education',
  eventAction: 'entry-updated',
  eventLabel:
    action.payload && action.payload.educationEntry && action.payload.educationEntry.schoolName,
});

const deleteEducationEntrySuccess = action => ({
  hitType: 'event',
  eventCategory: 'education',
  eventAction: 'entry-deleted',
  eventLabel:
    action.payload && action.payload.educationEntry && action.payload.educationEntry.schoolName,
});

const deleteEducationEntryCancel = action => ({
  hitType: 'event',
  eventCategory: 'education',
  eventAction: 'entry-delete-cancelled',
  eventLabel:
    action.payload && action.payload.educationEntry && action.payload.educationEntry.schoolName,
});

const addWorkExperienceEntrySuccess = action => ({
  hitType: 'event',
  eventCategory: 'work-experience',
  eventAction: 'entry-added',
  eventLabel:
    action.payload &&
    action.payload.workExperienceEntry &&
    action.payload.workExperienceEntry.companyName,
});

const updateWorkExperienceEntrySuccess = action => ({
  hitType: 'event',
  eventCategory: 'work-experience',
  eventAction: 'entry-updated',
  eventLabel:
    action.payload &&
    action.payload.workExperienceEntry &&
    action.payload.workExperienceEntry.companyName,
});

const deleteWorkExperienceEntrySuccess = action => ({
  hitType: 'event',
  eventCategory: 'work-experience',
  eventAction: 'entry-deleted',
  eventLabel:
    action.payload &&
    action.payload.workExperienceEntry &&
    action.payload.workExperienceEntry.companyName,
});

const deleteWorkExperienceEntryCancel = action => ({
  hitType: 'event',
  eventCategory: 'work-experience',
  eventAction: 'entry-delete-cancelled',
  eventLabel:
    action.payload &&
    action.payload.workExperienceEntry &&
    action.payload.workExperienceEntry.companyName,
});

const companyMemberVerifiedClick = action => ({
  hitType: 'event',
  eventCategory: 'company-member',
  eventAction: 'verified-clicked',
  eventLabel:
    action.payload &&
    action.payload.company &&
    action.payload.member &&
    `${action.payload.company.companyName} - ${action.payload.member.name}`,
});

const getBadgeClick = action => ({
  hitType: 'event',
  eventType: 'profile-badge',
  eventAction: 'get-badge-cliked',
  eventLabel:
    action.payload &&
    action.payload.badge &&
    action.payload.member &&
    `${action.payload.badge.name} - ${action.payload.member.name}`,
});

const blockchain101 = action => ({
  hitType: 'event',
  eventCategory: 'blockchain101-static',
  eventAction: 'button-click',
  eventLabel: action.payload,
});

const trackMerkleVizNodeClick = action => ({
  hitType: 'event',
  eventCategory: 'claim-merkle-viz',
  eventAction: 'node-clicked',
  eventLabel:
    action.payload &&
    action.payload.claimId &&
    action.payload.member &&
    `${action.payload.claimId} - ${action.payload.member.name}`,
});
const eventsMap = {
  [LOCATION_CHANGE]: pageView,
  [SIGN_UP.SUCCESS]: signupSuccess,
  [LOGIN.SUCCESS]: loginSuccess,
  [CREATE_CLAIM.SUCCESS]: createClaimSuccess,
  [REGISTER_TO_VOTE.SUCCESS]: registerToVoteSuccess,
  [ENDORSE.SUCCESS]: indorseSuccess,
  [FLAG.SUCCESS]: flagSuccess,
  [IMPORT_LINKED_IN_PROFILE_TEST]: importLinkedInProfileTest,
  [ADD_EDUCATION_ENTRY.SUCCESS]: addEducationEntrySuccess,
  [UPDATE_EDUCATION_ENTRY.SUCCESS]: updateEducationEntrySuccess,
  [DELETE_EDUCATION_ENTRY.SUCCESS]: deleteEducationEntrySuccess,
  [DELETE_EDUCATION_ENTRY.CANCEL]: deleteEducationEntryCancel,
  [ADD_WORK_EXPERIENCE_ENTRY.SUCCESS]: addWorkExperienceEntrySuccess,
  [UPDATE_WORK_EXPERIENCE_ENTRY.SUCCESS]: updateWorkExperienceEntrySuccess,
  [DELETE_WORK_EXPERIENCE_ENTRY.SUCCESS]: deleteWorkExperienceEntrySuccess,
  [DELETE_WORK_EXPERIENCE_ENTRY.CANCEL]: deleteWorkExperienceEntryCancel,
  [TRACK_COMPANY_MEMBER_VERIFIED_CLICK]: companyMemberVerifiedClick,
  [TRACK_GET_BADGE_CLICK]: getBadgeClick,
  [BLOCKCHAIN_101]: blockchain101,
  [TRACK_MERKLE_VIZ_NODE_CLICK]: trackMerkleVizNodeClick,
  ...formEventDefinitions,
  ...modalEventDefinitions,
};

export default createMiddleware(eventsMap, GoogleAnalytics);
