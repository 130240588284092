import { wizardNames } from 'ui/wizard/constants';

export function signUp({
  username,
  name,
  email,
  password,
  tokenFromClaimVerify,
  companyAdminInviteToken,
}) {
  return {
    endpoint: 'signup',
    method: 'POST',
    data: {
      username,
      name,
      email,
      password,
      claimToken: tokenFromClaimVerify,
      companyAdminInviteToken,
    },
    convertApiErrorsToReduxFormErrors: true,
    decamlizeInput: false,
  };
}

export function verifyEmail({ email, verifyToken }) {
  return {
    endpoint: 'verify-email',
    method: 'POST',
    data: { email, verifyToken },
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function resendVerificationEmail({ email }) {
  return {
    endpoint: 'resendverification',
    method: 'POST',
    data: { email },
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function login({ email, password }) {
  return {
    endpoint: 'login',
    method: 'POST',
    data: { email, password },
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function logout() {
  return {
    endpoint: 'logout',
    method: 'POST',
    requireAuth: true,
  };
}

export function changePassword({ oldPassword, newPassword }) {
  return {
    endpoint: 'password/change',
    method: 'POST',
    data: { oldPassword, newPassword },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function setPassword({ newPassword }) {
  return {
    endpoint: 'password/change',
    method: 'POST',
    // TODO: Remove oldPassword hack
    data: { oldPassword: 'fake-password', newPassword },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function forgotPassword({ email, isLogoutAllDevices }) {
  return {
    endpoint: 'password/forgot',
    method: 'POST',
    data: { email, isLogoutAllDevices },
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function resetPassword({ email, password, passwordToken: passToken, isLogoutAllDevices }) {
  return {
    endpoint: 'password/reset',
    method: 'POST',
    data: { email, password, passToken, isLogoutAllDevices },
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function fetchCurrentUser() {
  return {
    endpoint: 'me',
    method: 'POST',
    requireAuth: true,
  };
}

export function updateCurrentUser({ username, name, ethereumAddress, bio, imgData, socialLinks }) {
  const data = { username, name, ethaddress: ethereumAddress, bio, imgData, socialLinks };
  if (!data.username) delete data.username;
  if (!data.ethaddress) delete data.ethaddress;
  if (!data.imgData) delete data.imgData;

  return {
    endpoint: 'updateprofile',
    method: 'POST',
    data: data,
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function checkUsername({ username }) {
  return {
    endpoint: 'findusername',
    method: 'POST',
    data: { username },
    requireAuth: false,
  };
}

export function facebookAuth(authData) {
  const { accessToken, userID } = authData;

  return {
    endpoint: 'auth/facebook',
    method: 'POST',
    data: { facebook: { accessToken, userID } },
    requireAuth: false,
    decamlizeInput: false,
  };
}

export function facebookSignUp({ username, name, facebook }) {
  const { accessToken, userID } = facebook;

  return {
    endpoint: 'signup/facebook',
    method: 'POST',
    data: { username, name, facebook: { accessToken, userID } },
    requireAuth: false,
    decamlizeInput: false,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function linkFacebook(authData) {
  const { accessToken, userID } = authData;

  return {
    endpoint: 'link/facebook',
    method: 'POST',
    data: { facebook: { accessToken, userID } },
    requireAuth: true,
    decamlizeInput: false,
  };
}

export function googleAuth(authData) {
  const { id_token } = authData;

  return {
    endpoint: 'auth/google',
    method: 'POST',
    data: { google: { id_token } },
    requireAuth: false,
  };
}

export function googleSignUp({ username, name, google, tokenFromClaimVerify }) {
  const { id_token } = google;

  return {
    endpoint: 'signup/google',
    method: 'POST',
    data: { username, name, google: { id_token }, claimToken: tokenFromClaimVerify },
    requireAuth: false,
    decamlizeInput: false,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function linkGoogle(authData) {
  const { id_token } = authData;

  return {
    endpoint: 'link/google',
    method: 'POST',
    data: { google: { id_token } },
    requireAuth: true,
  };
}

export function linkedInAuth({ username, linkedIn }) {
  const { code, state, redirectUri } = linkedIn;

  return {
    endpoint: 'auth/linked-in',
    method: 'POST',
    data: { username, linkedIn: { code, state, redirectUri } },
    requireAuth: false,
  };
}

export function linkedInSignUp({ username, name, linkedIn, tokenFromClaimVerify }) {
  const { signupToken } = linkedIn;

  return {
    endpoint: 'signup/linked-in',
    method: 'POST',
    data: { username, name, linkedIn: { signupToken }, claimToken: tokenFromClaimVerify },
    requireAuth: false,
    convertApiErrorsToReduxFormErrors: true,
    decamlizeInput: false,
  };
}

export function linkLinkedIn({ linkedIn }) {
  const { code, state, redirectUri } = linkedIn;

  return {
    endpoint: 'link/linked-in',
    method: 'POST',
    data: { linkedIn: { code, state, redirectUri } },
    requireAuth: true,
  };
}

export function uPortAuth() {
  // console.log(credentials, ' are @uPortAuth credentials');
  return {
    endpoint: 'auth/uport',
    method: 'POST',
    // data: { uport: credentials },
    requireAuth: false,
  };
}

export function linkUPort() {
  return {
    endpoint: 'auth/link/uport',
    method: 'POST',
    requireAuth: true,
  };
}

export function pollUPort({ token }) {
  return {
    endpoint: `auth/uport/poll/${token}`,
    method: 'POST',
    requireAuth: false,
  };
}

export const proceedSignUpWizard = function(currentUserId, currentStepNumber) {
  return {
    endpoint: `wizards/${wizardNames.signUp}/${currentUserId}/complete/${currentStepNumber}`,
    method: 'POST',
    requireAuth: true,
  };
};

export const skipSignUpWizard = function(currentUserId, currentStepNumber) {
  return {
    endpoint: `wizards/${wizardNames.signUp}/${currentUserId}/skip/${currentStepNumber}`,
    method: 'POST',
    requireAuth: true,
  };
};

export const finishSignUpWizard = function(currentUserId, currentStepNumber) {
  return {
    endpoint: `wizards/${wizardNames.signUp}/${currentUserId}/finished`,
    method: 'POST',
    requireAuth: true,
  };
};

export const fetchSignUpWizardStatus = function(currentUserId) {
  return {
    endpoint: `wizards/${wizardNames.signUp}/${currentUserId}`,
    method: 'GET',
    requireAuth: true,
  };
};

export function acceptCurrentTermsAndPrivacy() {
  return {
    endpoint: 'acceptterms',
    method: 'POST',
    convertApiErrorsToReduxFormErrors: true,
    requireAuth: true,
  };
}
