import { defineAction } from 'store/common/action-helpers';

import * as actionTypes from './action-types';

export const addEducationEntry = {
  request: (values, { form, modal, wizard }) =>
    defineAction(actionTypes.ADD_EDUCATION_ENTRY.REQUEST, values, { form, modal, wizard }),
  start: values => defineAction(actionTypes.ADD_EDUCATION_ENTRY.START, values),
  success: ({ education: educationEntry }) =>
    defineAction(actionTypes.ADD_EDUCATION_ENTRY.SUCCESS, { educationEntry }),
  failure: error => defineAction(actionTypes.ADD_EDUCATION_ENTRY.FAILURE, error),
};

export const updateEducationEntry = {
  request: (values, form, modal) =>
    defineAction(actionTypes.UPDATE_EDUCATION_ENTRY.REQUEST, values, { form, modal }),
  start: values => defineAction(actionTypes.UPDATE_EDUCATION_ENTRY.START, values),
  success: ({ education: educationEntry }) =>
    defineAction(actionTypes.UPDATE_EDUCATION_ENTRY.SUCCESS, { educationEntry }),
  failure: error => defineAction(actionTypes.UPDATE_EDUCATION_ENTRY.FAILURE, error),
};

export const deleteEducationEntry = {
  request: ({ id }, modal) =>
    defineAction(actionTypes.DELETE_EDUCATION_ENTRY.REQUEST, { id }, { modal }),
  confirm: ({ id }, modal) =>
    defineAction(actionTypes.DELETE_EDUCATION_ENTRY.CONFIRM, { id }, { modal }),
  start: ({ id }) => defineAction(actionTypes.DELETE_EDUCATION_ENTRY.START, { id }),
  success: ({ id, education: educationEntry }) =>
    defineAction(actionTypes.DELETE_EDUCATION_ENTRY.SUCCESS, { id, educationEntry }),
  failure: error => defineAction(actionTypes.DELETE_EDUCATION_ENTRY.FAILURE, error),
  cancel: ({ id }, modal) =>
    defineAction(actionTypes.DELETE_EDUCATION_ENTRY.CANCEL, { id }, { modal }),
};
