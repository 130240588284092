import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/admin/companies';

export const FETCH_COMPANIES = defineApiActionTypes(`${prefix}/FETCH_COMPANIES`);

export const CREATE_COMPANY = defineApiActionTypes(`${prefix}/CREATE`);

export const UPDATE_COMPANY = defineApiActionTypes(`${prefix}/UPDATE`);

export const DELETE_COMPANY = defineApiActionTypes(`${prefix}/DELETE`);
