import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import { connectModal as reduxModal } from 'redux-modal';

export const MODAL_NAME = 'ems-waitlist-modal';

class WaitlistModal extends Component {
  render() {
    const { show, handleHide } = this.props;

    return (
      <Modal isOpen={show} className="modal-lg">
        <ModalHeader toggle={handleHide} />
        <ModalBody className="row justify-content-center">
          <div className="col-md-11 text-center">
            <h1
              className="text-center mx-3 indorse-blue mb-5"
              style={{ fontSize: '36px', fontWeight: '900', lineHeight: '50px' }}
            >
              Hello! You caught us before we're ready!
            </h1>
            <div className="mt-4 text-left">
              <h3 style={{ fontSize: '20px', lineHeight: '30px' }}>
                We’re working hard to put finishing touches on the Metamorph tool. Things are going
                pretty well and we’ll launch it very soon.
                <div className="mb-4" />
                Meanwhile, please check your email and let us know if you would like to be a part of
                the exclusive group of engineering leaders and and product managers to get early
                access to the tool. :)
                <div className="mb-4" />
                In any case, we will get in touch with you once we launch. Stay tuned!
              </h3>
            </div>
          </div>
        </ModalBody>
        <ModalFooter />
      </Modal>
    );
  }
}

export default reduxModal({
  name: MODAL_NAME,
})(injectIntl(WaitlistModal));
