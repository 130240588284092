/*
 * ViewJobPost Messages
 *
 * This contains all the text for the ViewJobPost component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  signUp: {
    id: 'auth.prompt-modal.signUp',
    defaultMessage: 'Sign Up Now',
  },
  login: {
    id: 'auth.prompt-modal.login',
    defaultMessage: 'Login',
  },
});
