import shortid from 'shortid';

export const messageTypes = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  danger: 'danger',
};

export const messageIds = {
  auth: {
    status: 'authStatus',
    emailVerfied: 'emailVerfied',
    passwordStatus: 'passwordStatus',
    profileUpdated: 'profileUpdated',
    resendVerificationEmail: 'resendVerificationEmail',
  },

  claims: {
    createSuccess: 'claimCreateSuccess',
  },
};

export function buildMessage({
  id = shortid.generate(),
  kind = messageTypes.info,
  content,
  dismissable = true,
  disableTimeout = false,
}) {
  return {
    id,
    kind,
    content,
    dismissable,
    disableTimeout,
  };
}
