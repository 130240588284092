import { createSelector } from 'reselect';

const selectHackathonClaimSubmissionState = state => state.HackathonClaimSubmission;

export const selectIsFetching = createSelector(
  selectHackathonClaimSubmissionState,
  (state, props) => state.fetching
);

export const selectIsUpdating = createSelector(
  selectHackathonClaimSubmissionState,
  (state, props) => state.updating
);

export const selectError = createSelector(
  selectHackathonClaimSubmissionState,
  (state, props) => state.error
);
