import React from 'react';
import PropTypes from 'prop-types';
import Select from 'ui/common/rebranding/components/Select/Select';

const SimpleSelect = ({
  type,
  options,
  showBlankOption,
  onChange,
  hint,
  input,
  label,
  meta: { form, touched, submitting, error },
  wrapperClass = 'py-2',
  hidden,
  disabled,
  ...passThrough
}) => {
  if (hidden) return null;

  const inputId = `${form}-${input.name}`;
  const hasError = (touched || submitting) && !!error;
  const errorText = Array.isArray(error) ? error.join('\n') : error;

  return (
    <Select
      id={inputId}
      options={options}
      onSelect={input.onChange}
      value={input.value}
      placeholder={label}
      hasError={hasError}
      errorMessage={errorText}
      wrapperClass={wrapperClass}
      disabled={disabled}
      {...passThrough}
    >
      {input && input.value ? label : null}
    </Select>
  );
};

SimpleSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default SimpleSelect;
