import slugify from 'slugify';
import { capitalize } from 'lodash';

export function getInitials(name, options = { max: null }) {
  if (!name) return name;
  const parts = name.split(' ');
  let initials = '';

  for (var i = 0; i < options.max || parts.length; i++) {
    initials += parts[i].substr(0, 1).toUpperCase();
  }

  return initials;
}

export function truncate(str, maxLength, suffix = '...') {
  if (!str || typeof str !== 'string' || str.length <= maxLength) return str;

  return str.substr(0, maxLength) + suffix;
}

export function generateSlug(parts = [], options) {
  return slugify((parts || []).filter(Boolean).join(' '), options);
}

export function getContentFromHtml(htmlContent = '') {
  const div = document.createElement('div');
  div.innerHTML = htmlContent;
  return div.textContent || '';
}

export function startCaseHandleSpecialChars(str) {
  if (!str) return str;

  return str.replace(/\w+/g, capitalize);
}

export function ensureTrailingSlash(value) {
  if (!value) return value;

  const str = typeof value === 'string' ? value : value.toString();
  return str.endsWith('/') ? str : `${str}/`;
}
