import { combineReducers } from 'redux';

import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  saving: false,
  deleting: false,
});

export function editWorkExperience(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_WORK_EXPERIENCE_ENTRY.START:
    case actionTypes.UPDATE_WORK_EXPERIENCE_ENTRY.START:
      return state.merge({
        saving: true,
      });

    case actionTypes.ADD_WORK_EXPERIENCE_ENTRY.SUCCESS:
    case actionTypes.ADD_WORK_EXPERIENCE_ENTRY.FAILURE:
    case actionTypes.UPDATE_WORK_EXPERIENCE_ENTRY.SUCCESS:
    case actionTypes.UPDATE_WORK_EXPERIENCE_ENTRY.FAILURE:
      return state.merge({
        saving: false,
      });
    default:
      return state;
  }
}

export function deleteWorkExperience(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.START:
      return state.merge({
        deleting: true,
      });

    case actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.SUCCESS:
    case actionTypes.DELETE_WORK_EXPERIENCE_ENTRY.FAILURE:
      return state.merge({
        deleting: false,
      });
    default:
      return state;
  }
}

export default combineReducers({
  editWorkExperience,
  deleteWorkExperience,
});
