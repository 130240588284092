import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormError, submit } from 'redux-form';

import Notification from 'ui/common/rebranding/components/Notification';

const propTypes = {
  formName: PropTypes.string.isRequired,
  error: PropTypes.string,
  closable: PropTypes.bool,
  ...Notification.propTypes,
};

const defaultProps = { type: 'error', closable: false };

const FormError = ({ formName, error, closable, ...passThrough }) =>
  error ? (
    <Notification {...passThrough} open closeBtn={closable} disableTimeout>
      {error}
    </Notification>
  ) : null;

FormError.propTypes = propTypes;

FormError.defaultProps = defaultProps;

function mapStateToProps(state, ownProps) {
  const { formName, error } = ownProps || {};

  if (formName) {
    return { error: error || getFormError(formName)(state) };
  } else {
    return {};
  }
}

const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormError);
