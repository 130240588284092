import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
// import { action } from '@storybook/addon-actions';
import Icon from '../Icon/Icon';
import './styles.scss';
import { FormattedMessage } from 'react-intl';

const isFormattedMessage = error => error && !!error.id && !!error.defaultMessage;

export class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
    };
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      open: nextProps.open,
    });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCloseButtonClick = evt => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleTimeout() {
    setTimeout(() => {
      this._isMounted && this.handleCloseButtonClick();
    }, 2000);
  }

  render() {
    if (!this.state.open) {
      return null;
    }

    const {
      className,
      children,
      id,
      type,
      size,
      round,
      arrow,
      closeBtn,
      disableTimeout,
      ...other
    } = this.props;

    const notificationClasses = classNames(className, 'notification', {
      error: type === 'error',
      success: type === 'success',
      neutral: type === 'neutral',
      large: size === 'large',
      round: round,
      'arrow-up': arrow === 'up',
      'arrow-left': arrow === 'left',
      'arrow-down': arrow === 'down',
      'arrow-right': arrow === 'right',
      'hide-close-btn': !closeBtn,
    });

    const messages =
      Array.isArray(children) && children && children.length > 0 ? (
        children.map(
          message => (isFormattedMessage(message) ? <FormattedMessage {...message} /> : message)
        )
      ) : isFormattedMessage(children) ? (
        <FormattedMessage {...children} />
      ) : (
        children
      );

    if (!messages) return null;

    const closeBtnEl = closeBtn ? (
      <Icon name="icon--close" background={true} tooltip="" onClick={this.handleCloseButtonClick} />
    ) : (
      ''
    );

    !disableTimeout && this.handleTimeout();
    return (
      <div id={id} className={notificationClasses} {...other}>
        <div className="notification-text">{messages}</div>
        {closeBtnEl}
      </div>
    );
  }
}

Notification.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  round: PropTypes.bool,
  size: PropTypes.string,
  arrow: PropTypes.string,
  closeBtn: PropTypes.bool,
};

Notification.defaultProps = {
  type: 'neutral',
  round: false,
  size: 'normal',
  closeBtn: true,
};

export default Notification;
