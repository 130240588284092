import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';

import TextField from 'ui/common/form/fields/text-field';
import { RadioButton, RadioGroup } from 'ui/common/rebranding/components/RadioForm';

import classnames from 'classnames';

export class RadioAndTextField extends Component {
  static propTypes = {
    textFieldLabel: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ).isRequired,
    checked: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      showTextField: false,
    };
  }

  handleChange(valueFromRadioButton) {
    const { input } = this.props;
    const { onChange } = input; //from redux-form

    if (valueFromRadioButton !== 'others') {
      this.setState({
        showTextField: false,
      });

      // passing value from RadioButton to reduxForm form
      onChange(valueFromRadioButton);
    } else {
      this.setState({
        showTextField: true,
      });
      /* 
        we dont have to pass value to refux-form onChange as
        <TextField/> component already handle the value
      */
    }
  }

  render() {
    const { options, textFieldLabel, checked, isFlex, className } = this.props;
    return (
      <div>
        <RadioGroup
          onChange={this.handleChange}
          style={
            isFlex
              ? {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }
              : {}
          }
        >
          {options.map((option, index) => {
            return (
              <RadioButton
                key={index}
                value={option.value}
                checked={option.value === checked}
                isFlex={isFlex}
                className={className}
              >
                {option.name}
              </RadioButton>
            );
          })}
        </RadioGroup>
        {this.state.showTextField && (
          <TextField
            smallLabel
            label={textFieldLabel}
            wrapperClass={classnames('pt-3')}
            {...this.props}
          />
        )}
      </div>
    );
  }
}

export default RadioAndTextField;
