import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as adminSelectors from 'store/features/admin/selectors';
import * as entitySelectors from 'store/entities/selectors';
import * as commonClaimHelpers from 'store/features/claims/helpers';

const selectAdminClaimsFeatureState = createSelector(
  adminSelectors.selectAdminFeatureState,
  admin => admin.claims
);

const selectAdminClaimsListingState = createSelector(
  selectAdminClaimsFeatureState,
  feature => feature.listing
);

const selectAdminClaimsByIdState = createSelector(
  selectAdminClaimsFeatureState,
  feature => feature.byId
);

export const selectAdminClaimsPaginationInfo = createSelector(
  selectAdminClaimsListingState,
  (state, params) => params,
  (adminClaimsListingState, params) => {
    const currentPage = params.pageNo || adminClaimsListingState.currentPage;
    const perPage = params.perPage || adminClaimsListingState.perPage;
    const { totalClaims, matchingClaims } = adminClaimsListingState;
    const totalPages = Math.ceil(matchingClaims / perPage);
    const isFirstPage = currentPage === 1;
    const hasPreviousPage = !isFirstPage;
    const isLastPage = currentPage === totalPages;
    const hasNextPage = !isLastPage;
    const startOffset = totalPages && totalPages > 0 && (currentPage - 1) * perPage + 1;
    const endOffset =
      totalPages &&
      totalPages > 0 &&
      startOffset &&
      startOffset > 0 &&
      Math.min(startOffset + perPage - 1, matchingClaims);

    return {
      currentPage,
      perPage,
      totalClaims,

      matchingClaims,
      totalPages,
      isFirstPage,
      hasPreviousPage,
      isLastPage,
      hasNextPage,
      startOffset,
      endOffset,
    };
  }
);

export const selectAdminClaimsSortInfo = createSelector(
  selectAdminClaimsListingState,
  (state, params) => params,
  (adminClaimsListingState, params) => {
    return { ...adminClaimsListingState.sort, ...params.sort };
  }
);

export const selectAdminClaims = createSelector(
  entitySelectors.selectAllEntities,
  selectAdminClaimsListingState,
  (allEntities, adminClaimsListingState) => {
    return denormalize(adminClaimsListingState.ids, [schemas.claim], allEntities).map(c => ({
      ...c,
      skill: c.skills && Array.isArray(c.skills) && c.skills.length > 0 ? c.skills[0].skill : {},
      level: commonClaimHelpers.calculateClaimLevelInfo(c),
      status: commonClaimHelpers.calculateClaimStatus(c),
    }));
  }
);

export const selectAdminClaimsFetching = createSelector(
  selectAdminClaimsListingState,
  adminClaimsListingState => adminClaimsListingState.fetching
);

export const selectRejectionReasons = createSelector(
  selectAdminClaimsListingState,
  adminClaimsListingState => adminClaimsListingState.rejectionReasons
);

export const selectIsApprovingClaim = createSelector(
  selectAdminClaimsByIdState,
  (state, props) => props.claimId,
  (byId, claimId) => byId && claimId && byId[claimId] && byId[claimId].approving
);

export const selectIsDisapprovingClaim = createSelector(
  selectAdminClaimsByIdState,
  (state, props) => props.claimId,
  (byId, claimId) => byId && claimId && byId[claimId] && byId[claimId].disapproving
);

export const selectIsModeratingClaim = createSelector(
  selectAdminClaimsByIdState,
  (state, props) => props.claimId,
  (byId, claimId) => byId && claimId && byId[claimId] && byId[claimId].moderating
);

export const selectApproveClaimRequestErrorById = createSelector(
  selectAdminClaimsByIdState,
  (claimsByIdState, props) => props.claimId,
  (claimsByIdState, claimId) =>
    claimsByIdState && claimId && claimsByIdState[claimId] && claimsByIdState[claimId].error
);
