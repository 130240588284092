import { call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { startSubmit, stopSubmit } from 'redux-form';
import { push } from 'react-router-redux';
import { show as showModal } from 'redux-modal';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as selectors from './selectors';
import * as api from 'api/config/claims';
import callApi from 'store/api/saga';
import routeTemplates from 'ui/common/routes/templates';
import { selectCurrentUserId } from 'store/features/auth/selectors';
import { checkStatus } from 'store/features/votes/actions';
import { fetchRatingCriteriaDetails } from 'store/features/rating-criterias/actions';
import { selectVoteById } from 'store/features/votes/selectors';
import { STATUSES as VOTE_STATUSES } from 'store/features/votes/helpers';
import { MODAL_NAME as USER_CLAM_SURVEY_MODAL_NAME } from 'ui/graphql/UserClaimSurveyModal';

function* fetchUserClaims({ payload }) {
  const { userId } = payload;

  const isFetching = yield select(selectors.selectIsFetchingUserClaims, { userId });
  if (isFetching || !userId) return;

  yield put(actions.fetchUserClaims.start({ userId }));

  try {
    const response = yield call(callApi, api.fetchUserClaims({ userId }));
    const schema = {
      claims: [
        {
          claim: schemas.claim,
          votingRound: schemas.votingRound,
        },
      ],
    };
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchUserClaims.success({ userId, result }));
  } catch (error) {
    yield put(actions.fetchUserClaims.failure({ userId, error }));
  }
}

function* watchFetchUserClaims() {
  yield takeEvery(actionTypes.FETCH_USER_CLAIMS.REQUEST, fetchUserClaims);
}

function* createClaim({ payload }) {
  const { values, assessmentId, form } = payload;
  yield put(actions.createClaim.start({ values, assessmentId }));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.createClaim(values, assessmentId));
    if (form) yield put(stopSubmit(form));

    const schema = { claim: schemas.claim };
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));

    yield put(actions.createClaim.success(result));

    if (assessmentId) {
      window.location.reload();
    }

    // ID-1511 open survey modal before redirecting to job listing page
    yield put(showModal(USER_CLAM_SURVEY_MODAL_NAME));
  } catch (error) {
    yield put(actions.createClaim.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchCreateClaim() {
  yield takeEvery(actionTypes.CREATE_CLAIM.REQUEST, createClaim);
}

function* fetchClaim({ payload, meta = {} }) {
  const { claimId } = payload;
  const { background = false } = meta;

  const isFetching = yield select(selectors.selectIsFetchingClaim, { id: claimId });
  if (isFetching) return;

  yield put(actions.fetchClaim.start({ claimId }, { background }));

  try {
    const response = yield call(callApi, api.fetchClaim({ claimId }));

    // TODO: Remove after api is fixed
    if (response.votes) {
      const currentUserId = yield select(selectCurrentUserId);
      const currentUserVotes = response.votes.filter(v => v.voterId === currentUserId);
      const currentUserVote = currentUserVotes[0];
      response.vote = currentUserVote;
      delete response.votes;
    }

    const schema = {
      claim: schemas.claim,
      vote: schemas.vote,
      votingRound: schemas.votingRound,
    };

    const { entities, result } = normalize(response, schema);
    // ID-1926 fetch dynamic rating criterias from BE
    yield put(
      fetchRatingCriteriaDetails.request({ ratingCriteriaKeys: response.claim.evaluationCriteria })
    );

    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchClaim.success({ claimId }));

    if (result && result.vote) {
      const voteId = result.vote;
      const vote = yield select(selectVoteById, { id: voteId });
      if (vote.status === VOTE_STATUSES.requiresContractCheck)
        yield put(checkStatus.request({ voteId }));
    }
  } catch (error) {
    yield put(actions.fetchClaim.failure({ claimId, error }));
    yield put(push(routeTemplates.app.linkNotFound));
  }
}

function* watchFetchClaim() {
  yield takeEvery(actionTypes.FETCH_CLAIM.REQUEST, fetchClaim);
}

export default function* claims() {
  yield fork(watchFetchUserClaims);
  yield fork(watchCreateClaim);
  yield fork(watchFetchClaim);
}
