import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/admin/badges';

export const FETCH_BADGES = defineApiActionTypes(`${prefix}/FETCH_BADGES`);

export const CREATE_BADGE = defineApiActionTypes(`${prefix}/CREATE`);

export const UPDATE_BADGE = defineApiActionTypes(`${prefix}/UPDATE`);

export const DELETE_BADGE = defineApiActionTypes(`${prefix}/DELETE`);

export const ASSIGN_BADGES = defineApiActionTypes(`${prefix}/ASSIGN`);
