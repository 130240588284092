import { createSelector } from 'reselect';

const selectEmsContactFormState = state => state.EmsContactForm;

export const selectIsFetching = createSelector(
  selectEmsContactFormState,
  (state, props) => state.fetching
);

export const selectIsUpdating = createSelector(
  selectEmsContactFormState,
  (state, props) => state.updating
);

export const selectError = createSelector(selectEmsContactFormState, (state, props) => state.error);
