/*
  LinkGithubButton
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import shortid from 'shortid';
import { buildMessage } from 'store/features/common/flash/builder';
import * as storage from 'store/features/auth/storage';
import * as flashActions from 'store/features/common/flash/actions';
import Button from 'ui/common/button';
import { githubMethod } from 'resources/github';
import * as authCallbackActionTypes from 'store/graphql/GithubAuthCallback/action-types';
import * as actionTypes from 'store/graphql/LinkGithubButton/action-types';
import {
  selectAuthenticatingWithGithub,
  selectFetchingStart,
  selectGithubAuthCallbackState,
} from 'store/graphql/GithubAuthCallback/selectors';
import {
  selectError,
  selectLinkingWithGithub,
  selectLinkedWithGithub,
} from 'store/graphql/LinkGithubButton/selectors';
import { selectHasCurrentUserLinkedGithub } from 'store/features/auth/selectors';
import * as libSelectors from 'store/libs/selectors';
import messages from './messages';
import Loading from 'ui/common/loading';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import LinkGithubButtonReducer from 'store/graphql/LinkGithubButton/reducer';
import GithubAuthCallbackReducer from 'store/graphql/GithubAuthCallback/reducer';

class LinkGithubButton extends Component {
  static propTypes = {
    existingValues: PropTypes.object,
    from: PropTypes.string,
    ctaGithubAuth: PropTypes.bool,
    linkingWithGithub: PropTypes.bool,
    authenticationWithGithub: PropTypes.bool,
    isReady: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { linkingWithGithubStart } = this.props;
    const linkingWithGithub = storage.getDataInLocalStorage(githubMethod.linkingWithGithub);
    const location = window.location;
    const authData = new URLSearchParams(location.search);
    const code = authData.get('code');
    const state = authData.get('state');

    if (location && location.search && linkingWithGithub && code && state) {
      linkingWithGithubStart();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const previousRecaptchaStatus = !!(
      prevProps.recaptchaStatus && prevProps.recaptchaStatus.loaded
    );
    const currentRecaptchaStatus = !!(
      this.props.recaptchaStatus && this.props.recaptchaStatus.loaded
    );
    if (previousRecaptchaStatus !== currentRecaptchaStatus) {
      this.ultimateLinkWithGithub();
    }
  }

  async ultimateLinkWithGithub() {
    const { fetchingStart, githubAuthSuccess } = this.props;
    const fromLocation = storage.getDataInLocalStorage('from');
    const linkingWithGithub = storage.getDataInLocalStorage(githubMethod.linkingWithGithub);

    if (!fetchingStart) {
      const location = window.location;
      const authData = new URLSearchParams(location.search);
      const code = authData.get('code');
      const state = authData.get('state');

      if (location && location.search && linkingWithGithub && code && state) {
        githubAuthSuccess({ code, state });
        this.linkingWithGithub(code, state, fromLocation);
      }
    }
  }

  async linkingWithGithub(code, state, fromLocation) {
    const {
      linkingWithGithubSuccess,
      linkingWithGithubFailure,
      client,
      redirectUri,
      push,
    } = this.props;

    try {
      const recaptchaToken = await window.grecaptcha.execute(
        process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
        { action: 'linkgithub' }
      );

      const response = await client.mutate({
        mutation: gql`
          mutation githubLink($code: String!, $state: String!, $redirect_uri: String!) {
            githubLink(code: $code, state: $state, redirect_uri: $redirect_uri) {
              userExists
              token
            }
          }
        `,
        variables: {
          code: code,
          state: state,
          redirect_uri: redirectUri,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });

      const { data, errors } = response;

      if (errors && errors[0].message) {
        push(fromLocation, {
          flash: buildMessage({
            id: 'linking-with-github.error',
            kind: 'danger',
            content: errors[0].message,
          }),
        });
        linkingWithGithubFailure({ error: errors[0].message });
      } else if (data && data.githubLink) {
        storage.deleteDataInLocalStorage(githubMethod.linkingWithGithub);

        push(fromLocation, {
          flash: buildMessage({
            id: 'graphql.linking-github-button.success',
            kind: 'info',
            content: messages.success,
          }),
        });
        linkingWithGithubSuccess();
      }
    } catch (error) {}
  }

  async githubAuthCallback() {
    const { githubAuthStart, githubAuthFailure, redirectUri, from } = this.props;

    githubAuthStart();

    try {
      const urlParams = new URLSearchParams();
      const requestState = shortid.generate();

      urlParams.set('client_id', process.env.REACT_APP_GITHUB_CLIENT_ID);
      urlParams.set('redirect_uri', redirectUri);
      urlParams.set('scope', 'read:user,user:email');
      urlParams.set('state', requestState);
      urlParams.set('allow_signup', 'true');
      window.location = `${process.env.REACT_APP_GITHUB_API_URL}?${urlParams}`;

      storage.setDataInLocalStorage('from', from);
      storage.setDataInLocalStorage(githubMethod.linkingWithGithub, true);
    } catch (apiError) {
      flashActions.addMessage({
        id: 'github.auth.status',
        kind: 'danger',
        content: apiError,
      });
      githubAuthFailure({ error: apiError });
    }
  }

  render() {
    const {
      isAuthenticatingWithGithub,
      linkedWithGithub,
      linkingWithGithub,
      linkedWithCurrentUserGithub,
      children,
      isReady,
    } = this.props;

    if (linkingWithGithub) {
      return <Loading />;
    }

    return (
      <Button
        isReady={isReady || false}
        onClick={this.githubAuthCallback}
        disabled={linkedWithGithub || linkingWithGithub || linkedWithCurrentUserGithub}
        loading={isAuthenticatingWithGithub || linkingWithGithub}
        iconPosition="suffix"
      >
        {children ? (
          <div>{children}</div>
        ) : (
          <div>
            <i className="fab fa-github mr-2" />
            <FormattedMessage id="auth.github-button" defaultMessage="Github" />
            {(linkedWithGithub || linkedWithCurrentUserGithub) && (
              <i className="fa fa-check ml-3" />
            )}
          </div>
        )}
      </Button>
    );
  }
}

function mapStateToProps(state, props) {
  const redirectUri = `${window.location.origin}/github-auth`;
  const githubAuthCallback = selectGithubAuthCallbackState(state);
  const fetchingStart = githubAuthCallback && selectFetchingStart(state);
  const isAuthenticatingWithGithub = githubAuthCallback && selectAuthenticatingWithGithub(state);
  const linkingWithGithub = selectLinkingWithGithub(state);
  const linkedWithCurrentUserGithub = selectHasCurrentUserLinkedGithub(state);
  const linkedWithGithub = selectLinkedWithGithub(state);
  const recaptchaStatus = libSelectors.selectRecaptchaSdkLibLoadState(state);

  const from =
    (state && state.router && state.router.location && state.router.location.pathname) || '';

  return {
    errorMessage: selectError(state),
    redirectUri,
    fetchingStart,
    isAuthenticatingWithGithub,
    linkingWithGithub,
    linkedWithCurrentUserGithub,
    linkedWithGithub,
    recaptchaStatus,
    from,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    githubAuthStart: () =>
      dispatch({ type: authCallbackActionTypes.GITHUB_AUTH.START, payload: {} }),
    githubAuthSuccess: ({ code, state }) =>
      dispatch({ type: authCallbackActionTypes.GITHUB_AUTH.SUCCESS, payload: { code, state } }),
    githubAuthFailure: error =>
      dispatch({ type: authCallbackActionTypes.GITHUB_AUTH.FAILURE, payload: { error } }),

    linkingWithGithubStart: () => dispatch({ type: actionTypes.LINKING_WITH_GITHUB.START }),
    linkingWithGithubSuccess: () => dispatch({ type: actionTypes.LINKING_WITH_GITHUB.SUCCESS }),
    linkingWithGithubFailure: () => dispatch({ type: actionTypes.LINKING_WITH_GITHUB.FAILURE }),
    push: (routeName, locationState) => dispatch(push(routeName, locationState)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withLinkGithubButtonReducer = injectReducer({
  key: 'LinkGithubButton',
  reducer: LinkGithubButtonReducer,
});
const withGithubAuthCallbackReducer = injectReducer({
  key: 'GithubAuthCallback',
  reducer: GithubAuthCallbackReducer,
});

export default compose(withLinkGithubButtonReducer, withGithubAuthCallbackReducer, withConnect)(
  injectIntl(withApollo(LinkGithubButton))
);
