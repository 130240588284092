import { call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { push } from 'react-router-redux';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as selectors from './selectors';
import * as api from 'api/config/claim-reports';
import callApi from 'store/api/saga';
import routeTemplates from 'ui/common/routes/templates';
import { messageTypes, buildMessage } from 'store/features/common/flash/builder';
import { selectCurrentUserId } from 'store/features/auth/selectors';
import { checkStatus } from 'store/features/votes/actions';
import { selectVoteById } from 'store/features/votes/selectors';
import { STATUSES as VOTE_STATUSES } from 'store/features/votes/helpers';
import { fetchRatingCriteriaDetails } from 'store/features/rating-criterias/actions';

function* fetchClaimReport({ payload }) {
  const { reportId } = payload;

  const isFetching = yield select(selectors.selectIsFetchingClaimReport, { id: reportId });
  if (isFetching) return;

  yield put(actions.fetchClaimReport.start({ reportId }));

  try {
    const response = yield call(callApi, api.fetchClaimReport({ reportId }));

    // TODO: Remove after api is fixed
    if (response.votes) {
      const currentUserId = yield select(selectCurrentUserId);
      const currentUserVotes = response.votes.filter(v => v.voterId === currentUserId);
      const currentUserVote = currentUserVotes[0];
      response.vote = currentUserVote;
      delete response.votes;
    }

    const schema = {
      claim: schemas.claim,
      vote: schemas.vote,
      votingRound: schemas.votingRound,
    };

    const { entities, result } = normalize(response, schema);

    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchClaimReport.success({ reportId, claimId: response.claim.id }));

    // ID-1926 fetch dynamic rating criterias from BE
    yield put(
      fetchRatingCriteriaDetails.request({ ratingCriteriaKeys: response.claim.evaluationCriteria })
    );

    if (result && result.vote) {
      const voteId = result.vote;
      const vote = yield select(selectVoteById, { id: voteId });
      if (vote.status === VOTE_STATUSES.requiresContractCheck)
        yield put(checkStatus.request({ voteId }));
    }
  } catch (error) {
    yield put(actions.fetchClaimReport.failure({ reportId, error }));
    yield put(
      push(routeTemplates.root, {
        flash: buildMessage({ kind: messageTypes.danger, content: error.message }),
      })
    );
  }
}

function* watchFetchClaimReport() {
  yield takeEvery(actionTypes.FETCH_CLAIM_REPORT.REQUEST, fetchClaimReport);
}

export default function* claims() {
  yield fork(watchFetchClaimReport);
}
