import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function InternalLink({ openInNewTab, ...passThrough }) {
  const linkProps = {
    ...passThrough,
  };

  if (openInNewTab) {
    linkProps.target = '_blank';
    linkProps.rel = 'noreferrer noopener';
  }

  return <Link {...linkProps} />;
}

InternalLink.propTypes = {
  ...Link.propTypes,
  openInNewTab: PropTypes.bool,
};
