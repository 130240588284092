import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const addUserSkill = {
  request: ({ userId, values, isHighlighted }, { form, modal }) =>
    defineAction(types.ADD_USER_SKILL.REQUEST, { userId, values, isHighlighted }, { form, modal }),
  start: ({ userId, values }) => defineAction(types.ADD_USER_SKILL.START, { userId, values }),
  success: ({ userId, userSkillId }) =>
    defineAction(types.ADD_USER_SKILL.SUCCESS, { userId, userSkillId }),
  failure: ({ userId, values, error }) =>
    defineAction(types.ADD_USER_SKILL.FAILURE, {
      userId,
      values,
      error,
    }),
};
