import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { unflatten } from 'flat';
import { reduxForm, FormSection, Field, Fields } from 'redux-form';

import buildModel, { fieldNames, dateYearMonthFieldNames } from './model';
import * as messages from './messages';
import FormError from 'ui/common/form/form-error';

import TextField from 'ui/common/form/fields/text-field';
import ApiAutoCompleteField from 'ui/common/form/fields/api-auto-complete-field';
import YearField from 'ui/common/form/fields/year-field';
import MonthField from 'ui/common/form/fields/month-field';
import CheckboxField from 'ui/common/form/fields/checkbox-field';
import validator from 'ui/common/form/validator';
import { autoSuggestSchools } from 'api/config/education';

window.validator = validator;
const baseModel = buildModel({});

function validate(values) {
  const model = buildModel(values);

  const errors = validator(model)(values);
  return unflatten(errors);
}

class EntryForm extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  renderDateYearMonthFields({ year, month, model, parentFieldName, onChange }) {
    const { intl: { locale, formatMessage } } = this.props;

    const yearFieldFullName = `${parentFieldName}.${dateYearMonthFieldNames.year}`;
    const monthFieldFullName = `${parentFieldName}.${dateYearMonthFieldNames.month}`;

    return (
      <div className="row">
        <div className="col-md-12">
          <label>{formatMessage(messages.labels[parentFieldName])}</label>
        </div>

        <div className="col-md-6">
          <YearField
            {...year}
            name={dateYearMonthFieldNames.year}
            min={model[yearFieldFullName].numericality.greaterThanOrEqualTo}
            max={model[yearFieldFullName].numericality.lessThanOrEqualTo}
            label={formatMessage(messages.labels[yearFieldFullName])}
            onChange={onChange}
            showBlankOption
          />
        </div>
        <div className="col-md-6">
          <MonthField
            {...month}
            name={dateYearMonthFieldNames.month}
            min={model[monthFieldFullName].numericality.greaterThanOrEqualTo}
            max={model[monthFieldFullName].numericality.lessThanOrEqualTo}
            locale={locale}
            label={formatMessage(messages.labels[monthFieldFullName])}
            onChange={onChange}
            showBlankOption
          />
        </div>
      </div>
    );
  }

  renderTimePeriod({ startDate, endDate, currentlyStudying }) {
    const { intl: { formatMessage } } = this.props;
    const model = buildModel({
      startDate: startDate.input.value,
      endDate: endDate.input.value,
      currentlyStudying: currentlyStudying.input.value,
    });

    return (
      <div className="row py-3">
        <div className="col-md-6">
          <FormSection name={startDate.input.name}>
            <Fields
              names={[dateYearMonthFieldNames.year, dateYearMonthFieldNames.month]}
              component={this.renderDateYearMonthFields}
              model={model}
              parse={Number}
              parentFieldName={startDate.input.name}
            />
          </FormSection>
          <br />
          <CheckboxField
            {...currentlyStudying}
            label={formatMessage(messages.labels.currentlyStudying)}
            size="lg"
            wrapperClassName="pb-3"
          />
        </div>

        <div className="col-md-6">
          {!currentlyStudying.input.value && (
            <FormSection name={endDate.input.name}>
              <Fields
                names={[dateYearMonthFieldNames.year, dateYearMonthFieldNames.month]}
                component={this.renderDateYearMonthFields}
                model={model}
                parse={Number}
                parentFieldName={endDate.input.name}
              />
            </FormSection>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, form: formName, intl: { formatMessage } } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormError formName={formName} />
        <div className="row">
          <div className="col-md-6">
            <Fields
              names={['schoolName', 'schoolId', fieldNames.schoolNameSearch]}
              textFieldName="schoolName"
              valueFieldName="schoolId"
              searchFieldName={fieldNames.schoolNameSearch}
              component={ApiAutoCompleteField}
              label={formatMessage(messages.labels.schoolName)}
              required={baseModel[fieldNames.schoolName].presence}
              apiRequestConfig={autoSuggestSchools}
              apiResponseConfig={{ resultsKey: 'schools', textKey: 'schoolName', valueKey: 'id' }}
            />
          </div>
          <div className="col-md-6">
            <Field
              name={fieldNames.fieldOfStudy}
              component={TextField}
              label={formatMessage(messages.labels.fieldOfStudy)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Field
              name={fieldNames.degree}
              component={TextField}
              label={formatMessage(messages.labels.degree)}
            />
          </div>
          <div className="col-md-6">
            <Field
              name={fieldNames.grade}
              component={TextField}
              label={formatMessage(messages.labels.grade)}
            />
          </div>
        </div>

        <div>
          <Field
            name={fieldNames.activities}
            component={TextField}
            multiLine
            rows={5}
            label={formatMessage(messages.labels.activities)}
          />
        </div>

        <Fields
          names={[fieldNames.startDate, fieldNames.endDate, fieldNames.currentlyStudying]}
          component={this.renderTimePeriod}
        />

        <div>
          <Field
            name={fieldNames.description}
            component={TextField}
            multiLine
            rows={5}
            label={formatMessage(messages.labels.description)}
          />
        </div>
      </form>
    );
  }
}

export default function createForm({ form, initialValues, onSubmit }) {
  const modalSubmit = values => {
    if (!values[fieldNames.schoolName]) {
      values[fieldNames.schoolName] = values[fieldNames.schoolNameSearch];
    }

    delete values[fieldNames.schoolNameSearch];

    onSubmit(values);
  };

  return reduxForm({
    form,
    initialValues,
    validate,
    onSubmit: modalSubmit,
  })(injectIntl(EntryForm));
}
