import { defineAsyncActionTypes } from 'store/common/action-helpers';

const prefix = 'graphql/ConfigureCompanyAipLimit';

export const CONFIGURE_COMPANY_AIP_LIMIT = defineAsyncActionTypes(
  `${prefix}/CONFIGURE_COMPANY_AIP_LIMIT`
);

export const ALLOW_REPORT_FOR_CANDIDATE = defineAsyncActionTypes(
  `${prefix}/ALLOW_REPORT_FOR_CANDIDATE`
);

export const UPDATE_MAGIC_LINK_VERSION = defineAsyncActionTypes(
  `${prefix}/UPDATE_MAGIC_LINK_VERSION`
);

export const REQUIRE_CV_FOR_COMPANY = defineAsyncActionTypes(`${prefix}/REQUIRE_CV_FOR_COMPANY`);

export const UPDATE_CLAIM_REVIEW = defineAsyncActionTypes(`${prefix}/UPDATE_CLAIM_REVIEW`);
