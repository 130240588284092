import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import themeConfig from 'ui/theme/config';
import { show as showModal } from 'redux-modal';
import styles from './index.module.scss';
import { scrollTo } from 'ui/common/scroller';
import arrowDownImg from 'resources/common/arrow_down_blue.svg';
// import Button from "ui/common/button";
import AnimatedBackgroundDesktop from 'ui/common/animated-background/desktop-version';

import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';

import AddToSlackButton from 'ui/graphql/AddToSlackButton';

import * as messages from './messages';

class Hero extends Component {
  renderDesktop() {
    const { intl: { formatMessage } } = this.props;
    return (
      <div className={classnames(styles.hero)}>
        <div className="row justify-content-center position-relative mt-4">
          <div className={classnames('col-10 col-sm-10 col-md-10 col-lg-10 col-xl-8 pt-4')}>
            <h1
              className={classnames(styles.heroTitle, 'mt-4 mx-auto text-center font-weight-bold')}
            >
              Indorse for Slack
            </h1>

            <h3 className={styles.heroSubTitle}>{formatMessage(messages.hero.subTitle)}</h3>

            <div className="col-md-10 offset-sm-1">
              <div className="my-4 row justify-content-center">
                <AddToSlackButton />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <img
            src="https://indorse-staging-bucket.s3.amazonaws.com/Slackbot-Indorse_2.png"
            alt="slackbot"
            width="850"
          />
        </div>

        {/* {this.renderLearnMoreScroll()} */}
      </div>
    );
  }

  renderMobile = () => {
    const { intl: { formatMessage } } = this.props;

    return (
      <div className={classnames('container-fluid mt-5')}>
        <div className="row justify-content-center position-relative">
          <div className={classnames('col-11 col-sm-10 col-md-8 col-lg-8 col-xl-6 pt-md-5')}>
            <h1 className={classnames(styles.heroTitle, 'mt-5')}>Indorse for Slack</h1>

            <h3 className={styles.heroSubTitle}>{formatMessage(messages.hero.subTitle)}</h3>

            <div className="mt-4 col-md-10 offset-sm-1">
              <div className="my-4 row justify-content-center">
                <AddToSlackButton />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="row justify-content-center">
          <img
            src="https://indorse-staging-bucket.s3.amazonaws.com/Slackbot-Indorse_2.png"
            alt="slackbot"
            width="390"
          />
        </div>
      </div>
    );
  };

  renderLearnMoreScroll() {
    return (
      <div className={classnames(styles.heroScrollIndicator, 'text-center py-3')}>
        <img
          onClick={() => scrollTo('predict-control-attrition', 50)}
          className="d-block mx-auto pt-3 mb-2 pointer bounce"
          src={arrowDownImg}
          width="20px"
          alt="arrow-down"
        />
        <p className="text-secondary">
          <FormattedMessage id="ui.pages.homeV2.hero.scrollDown" defaultMessage="Learn more" />
        </p>
      </div>
    );
  }

  render() {
    return (
      <div>
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
          <div className={classnames(styles.hero, 'page-max-width')}>
            <div className={classnames(styles.maxHeightDesktop)}>
              <AnimatedBackgroundDesktop />
              <div className={classnames(styles.heroContent, 'container-fluid')}>
                {this.renderDesktop()}
              </div>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
          {this.renderMobile()}
        </MediaQuery>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
    addMessage: bindActionCreators(addMessage, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(injectIntl(withApollo(Hero)));
