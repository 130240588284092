export const fieldNames = {
  ethereumAddress: 'ethereumAddress',
};

export default {
  [fieldNames.ethereumAddress]: {
    presence: true,
    ethereumAddress: true,
  },
};
