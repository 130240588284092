export { selector as selectClaimEntities } from './claims';
export { selector as selectEducationEntities } from './education';
export { selector as selectUserEntities } from './users';
export { selector as selectVoteEntities } from './votes';
export { selector as selectVotingRoundEntities } from './voting-rounds';
export { selector as selectWorkExperienceEntities } from './work-experience';
export { selector as selectCompanyEntities } from './companies';
export { selector as selectSkillCategoriesEntities } from './skill-categories';
export { selector as selectSkillsEntities } from './skills';
export { selector as selectUserSkillEntities } from './user-skills';
export { selector as selectBadgesEntities } from './badges';
export { selector as selectWizardDefinitionEntities } from './wizard-definitions';
export { selector as selectSmartContractEntities } from './smart-contracts';
export { selector as selectRatingCriteriaEntities } from './rating-criterias';

export const selectAllEntities = rootState => rootState.entities;
