import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

function getDisplayName(Component) {
  return Component.displayName || Component.name || 'Component';
}

const messages = defineMessages({
  requiredLabel: {
    id: 'common.form.indicators.required.label',
    defaultMessage: '{baseLabel} (Required)',
  },
});

export default function injectRequiredIndicator(WrappedComponent) {
  const Wrapper = injectIntl(function({
    required,
    label: baseLabel,
    intl: { formatMessage },
    ...passThrough
  }) {
    const label = required ? formatMessage(messages.requiredLabel, { baseLabel }) : baseLabel;
    return <WrappedComponent required={required} label={label} {...passThrough} />;
  });

  Wrapper.displayName = `InjectRequiredIndicator(${getDisplayName(WrappedComponent)})`;

  return Wrapper;
}
