export const fieldNames = {
  adminEmail: 'email',
};

export default {
  [fieldNames.adminEmail]: {
    presence: true,
    email: true,
  },
};

export const pageName = 'ems-landing-page';
