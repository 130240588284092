// redux-modal does not export action types. So include them here
// redux-modal/src/actionTypes.js
const SHOW = '@redux-modal/SHOW';
const HIDE = '@redux-modal/HIDE';
// const DESTROY = "@redux-modal/DESTROY";

const modalShow = action => ({
  hitType: 'event',
  eventCategory: 'modal',
  eventAction: 'show',
  eventLabel: action.payload.modal,
});

const modalHide = action => ({
  hitType: 'event',
  eventCategory: 'modal',
  eventAction: 'hide',
  eventLabel: action.payload.modal,
});

export default {
  [SHOW]: modalShow,
  [HIDE]: modalHide,
};
