import { defineMessages } from 'react-intl';

import { fieldNames, dateYearMonthFieldNames } from './model';

export const labels = defineMessages({
  [fieldNames.schoolName]: {
    id: `education.new.fields.schoolName.label`,
    defaultMessage: 'School',
  },
  [fieldNames.degree]: {
    id: `education.new.fields.degree.label`,
    defaultMessage: 'Degree',
  },
  [fieldNames.fieldOfStudy]: {
    id: `education.new.fields.fieldOfStudy.label`,
    defaultMessage: 'Field of study',
  },
  [fieldNames.grade]: {
    id: `education.new.fields.grade.label`,
    defaultMessage: 'Grade',
  },
  [fieldNames.activities]: {
    id: `education.new.fields.activities.label`,
    defaultMessage: 'Activities and Societies',
  },
  [`${fieldNames.startDate}`]: {
    id: `education.new.fields.startDate.label`,
    defaultMessage: 'From',
  },
  [`${fieldNames.startDate}.${dateYearMonthFieldNames.year}`]: {
    id: `education.new.fields.startDate.year.label`,
    defaultMessage: 'Year',
  },
  [`${fieldNames.startDate}.${dateYearMonthFieldNames.month}`]: {
    id: `education.new.fields.startDate.month.label`,
    defaultMessage: 'Month',
  },
  [`${fieldNames.endDate}`]: {
    id: `education.new.fields.endDate.label`,
    defaultMessage: 'To',
  },
  [`${fieldNames.endDate}.${dateYearMonthFieldNames.year}`]: {
    id: `education.new.fields.endDate.year.label`,
    defaultMessage: 'Year',
  },
  [`${fieldNames.endDate}.${dateYearMonthFieldNames.month}`]: {
    id: `education.new.fields.endDate.month.label`,
    defaultMessage: 'Month',
  },
  [fieldNames.currentlyStudying]: {
    id: `education.new.fields.endDate.cuurentlyStudying.label`,
    defaultMessage: 'Currently Studying',
  },
  [fieldNames.description]: {
    id: `education.new.fields.description.label`,
    defaultMessage: 'Description',
  },
});
