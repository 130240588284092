import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styles from './index.module.scss';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
// import { logEvent } from "services/amplitude-helper";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';

export class Dashboard extends Component {
  renderDesktop() {
    return (
      <div>
        <h2 className={classnames('text-center', styles.title)}>
          Your engineering team will love it
        </h2>

        <div className="row justify-content-center">
          <div className={classnames(styles.column, 'col-4')}>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Effortless-Image.png"
              alt="efforless"
              width="268"
              height="194"
            />
          </div>
          <div className={classnames(styles.column, 'col-4')}>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Experience-centric-Image.png"
              alt="experience-centric"
              width="286"
              height="194"
            />
          </div>
          <div className={classnames(styles.column, 'col-4')}>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Personalised%2BImage.png"
              alt="personalised"
              width="183"
              height="194"
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className={classnames(styles.column, 'col-4')}>
            <h2>Effortless</h2>
          </div>
          <div className={classnames(styles.column, 'col-4')}>
            <h2>Experience-centric</h2>
          </div>
          <div className={classnames(styles.column, 'col-4')}>
            <h2>Personalised</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className={classnames(styles.column, 'col-4')}>
            <p>Takes only 10 minutes a week and doesn’t distract your software engineers</p>
          </div>
          <div className={classnames(styles.column, 'col-4')}>
            <p>
              Slack integration for a smooth <br /> and intuitive experience
            </p>
          </div>
          <div className={classnames(styles.column, 'col-4')}>
            <p>Your engineers will have their own dashboard with customised insights</p>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        <h2 className={classnames('text-center', styles.title)}>
          Your engineering team will love it
        </h2>

        <div className="row justify-content-center">
          <div className={classnames(styles.column, 'col-10')}>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Effortless-Image.png"
              alt="efforless"
              width="268"
              height="194"
            />
            <h2>Effortless</h2>
            <p>
              Takes only 10 minutes a week and <br />doesn’t distract your software engineers
            </p>
          </div>
          <div className={classnames(styles.column, 'col-10')}>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Experience-centric-Image.png"
              alt="experience-centric"
              width="286"
              height="194"
            />
            <h2>Experience-centric</h2>
            <p>
              Slack integration for a smooth <br /> and intuitive experience
            </p>
          </div>
          <div className={classnames(styles.column, 'col-10')}>
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/Personalised%2BImage.png"
              alt="personalised"
              width="183"
              height="194"
            />
            <h2>Personalised</h2>
            <p>
              Your engineers will have their own <br /> dashboard with customised insights
            </p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className={classnames('page-max-width')}>
          <div className={classnames('container-fluid my-5 py-4')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addMessage: bindActionCreators(addMessage, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(injectIntl(withApollo(Dashboard)));
