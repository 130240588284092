import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { connectModal as reduxModal } from 'redux-modal';
import Button from 'ui/common/button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';

import {
  selectIsApprovingClaim,
  selectApproveClaimRequestErrorById,
} from 'store/features/admin/claims/selectors';
import { approveClaim } from 'store/features/admin/claims/actions';

const { request: approveClaimRequest } = approveClaim;

export const MODAL_NAME = 'admin-claim-force-approve-modal';

export class AdminClaimForceApproveModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick() {
    const { claimId, approveClaimRequest } = this.props;

    approveClaimRequest({
      claimId: claimId,
      force: true,
    });
  }

  render() {
    const { show, error, handleHide, approving } = this.props;

    return (
      <Modal size="lg" isOpen={show}>
        <ModalHeader toggle={handleHide}>Approve Claim</ModalHeader>
        <ModalBody>
          {error &&
            error.warning &&
            error.companyName && (
              <div>
                There are no credits remaining for the company {error.companyName} . Do you still
                want to continue ?
              </div>
            )}
        </ModalBody>
        <ModalFooter className="float-right">
          <Button color="danger" onClick={this.onClick} isReady={false} loading={approving}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { claimId } = ownProps;
  return {
    approving: selectIsApprovingClaim(state, { claimId }),
    error: selectApproveClaimRequestErrorById(state, { claimId }),
  };
}

const mapDispatchToProps = {
  approveClaimRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(AdminClaimForceApproveModal)
);
