import React, { useEffect } from 'react';
import classnames from 'classnames';
import commonStyles from './index.module.scss';
import Helmet from 'react-helmet';
import PricingTable from './pricing-table';
import ExistingCustomers from 'ui/pages/home-V2/existing-customers/loadable';
import Cta from './cta';
import Dashboard from './dashboard';
import { logEvent, logUserProperties } from 'services/amplitude-helper';
import routeTemplates from 'ui/common/routes/templates';
import { withRouter } from 'react-router-dom';

const EMSPricing = props => {
  useEffect(() => {
    if (props.match.path === '/pricing') props.history.push(routeTemplates.emsPricingPage);
    const query = new URLSearchParams(props.location.search);
    const utm_source = query.get('utm_source');
    const utm_content = query.get('utm_content');

    const ampIns = window.amplitude.getInstance();

    if (window.localStorage.getItem('email')) {
      ampIns.setUserId(window.localStorage.getItem('email'));
    } else if (!window.localStorage.getItem('deviceId')) {
      // create random deviceId
      const randomDeviceId = Math.random()
        .toString(36)
        .substr(2, 9);
      window.localStorage.setItem('deviceId', randomDeviceId);
      ampIns.setDeviceId(randomDeviceId);
      if (utm_source) window.localStorage.setItem('utm_source', utm_source);
    } else {
      ampIns.setDeviceId(window.localStorage.getItem('deviceId'));
    }

    // if we have utm_source
    if (utm_source) {
      logUserProperties({ utm_source });
    }

    // if we have utm_content
    if (utm_content) {
      logUserProperties({ utm_content });
    }
    logEvent('landed_on_pricing', {
      source: window && window.location.pathname,
    });
  }, []);

  return (
    <div className={classnames(commonStyles.emsPricing)}>
      <Helmet>
        <title>Metamorph | Pricing</title>
        <meta
          name="description"
          content="Pricing structure of Metamorph tool"
          data-react-helmet="true"
        />
      </Helmet>
      <PricingTable />
      <div className={classnames(commonStyles.blueBackground, 'row justify-content-center pt-5')}>
        <div className="col-12 text-center">
          <h2 className={classnames(commonStyles.trustText, 'px-4 pt-4')}>
            Companies that trust Indorse
          </h2>
          <ExistingCustomers />
        </div>
      </div>
      <Dashboard />
      <Cta />
    </div>
  );
};

export default withRouter(EMSPricing);
