import { defineAsyncActionTypes } from 'store/common/action-helpers';

const prefix = 'graphql/EmsOrgConfig';
// FOR ONE GRAPHQL QUERY
export const FETCH_EMS_ORG_ROOT = defineAsyncActionTypes(`${prefix}/FETCH_EMS_ORG_ROOT`);
export const UPDATE_EMS_ORG_ROOT = defineAsyncActionTypes(`${prefix}/UPDATE_EMS_ORG_ROOT`);

export const FETCH_EMS_PROFICIENCY_LIST = defineAsyncActionTypes(
  `${prefix}/FETCH_EMS_PROFICIENCY_LIST`
);
export const UPDATE_EMS_PROFICIENCY_LIST = defineAsyncActionTypes(
  `${prefix}/UPDATE_EMS_PROFICIENCY_LIST`
);
