import { TweenMax, TimelineMax } from 'gsap/TweenMax';
let duration = '0.75';
let duration1 = '0.5';
let delay2 = 0.75;
let delay = 0.75;
let easing = ['Power2.easeOut', 'Power4.easeOut'];

export function animateHeadline() {
  let tl0 = new TimelineMax();

  /* --- Draw black shapes --- */

  TweenMax.set(
    '#whitepaper-headline #stroke_1 ,#whitepaper-headline #stroke_2 ,#whitepaper-headline #stroke_3 ,#whitepaper-headline #stroke_4 ,#whitepaper-headline #stroke_5 ,#whitepaper-headline #stroke_6 ,#whitepaper-headline #stroke_7 ,#whitepaper-headline #stroke_8 ,#whitepaper-headline #stroke_9 ,#whitepaper-headline #stroke_10 ,#whitepaper-headline #stroke_11 ,#whitepaper-headline #stroke_12 ,#whitepaper-headline #stroke_13',
    { drawSVG: '50% 50%', opacity: '0' }
  );

  tl0
    .to(
      '#whitepaper-headline #stroke_1',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_2',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_3',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_4',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_5',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_6',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_7',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_8',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_9',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_10',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_11',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_12',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #stroke_13',
      duration1,
      {
        drawSVG: '0% 100%',
        opacity: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay2,
      },
      'start'
    );

  /* --- Scale colored shapes --- */

  TweenMax.set(
    '#whitepaper-headline #Ebene_3 , #whitepaper-headline #Ebene_4 , #whitepaper-headline #Ebene_5 , #whitepaper-headline #Ebene_6 , #whitepaper-headline #Ebene_7 , #whitepaper-headline #Ebene_8 , #whitepaper-headline #Ebene_9 , #whitepaper-headline #Ebene_10 , #whitepaper-headline #Ebene_11 , #whitepaper-headline #Ebene_12 , #whitepaper-headline #Ebene_13 , #whitepaper-headline #Ebene_14 , #whitepaper-headline #Ebene_15 , #whitepaper-headline #Ebene_16 , #whitepaper-headline #Ebene_17 , #whitepaper-headline #Ebene_18 , #whitepaper-headline #Ebene_19 , #whitepaper-headline #Ebene_20 , #whitepaper-headline #Ebene_21 , #whitepaper-headline #Ebene_22 , #whitepaper-headline #Ebene_23 , #whitepaper-headline #Ebene_24 , #whitepaper-headline #Ebene_25 , #whitepaper-headline #Ebene_26 , #whitepaper-headline #Ebene_27 , #whitepaper-headline #Ebene_28 , #whitepaper-headline #Ebene_29 , #whitepaper-headline #Ebene_30',
    { scale: '0', transformOrigin: '50% 50%' }
  );

  tl0
    .to(
      '#whitepaper-headline #Ebene_3',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_4',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_5',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_6',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_7',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_8',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_9',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_10',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_11',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_12',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_13',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_14',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_15',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_16',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_17',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_18',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_19',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_20',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_21',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_22',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_23',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_24',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_25',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_26',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_27',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_28',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_29',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    )
    .to(
      '#whitepaper-headline #Ebene_30',
      duration,
      {
        scale: '1',
        ease: easing[Math.floor(Math.random() * 1) + 0],
        delay: delay,
      },
      'start'
    );

  /* --- Bounce shape yoyo --- */

  TweenMax.set(
    '#whitepaper-headline #Ebene_23, #whitepaper-headline #Ebene_21, #whitepaper-headline #Ebene_12',
    { scale: '1', transformOrigin: '50% 50%' }
  );

  tl0
    .to(
      '#whitepaper-headline #Ebene_23',
      1,
      {
        scale: '0.7',
        ease: easing[0],
        delay: delay[Math.floor(Math.random() * 2) + 0],
        repeat: -1,
        yoyo: true,
      },
      'end'
    )
    .to(
      '#whitepaper-headline #Ebene_21',
      1,
      {
        scale: '0.7',
        ease: easing[0],
        delay: delay[Math.floor(Math.random() * 2) + 0],
        repeat: -1,
        yoyo: true,
      },
      'end'
    )
    .to(
      '#whitepaper-headline #Ebene_12',
      1,
      {
        scale: '0.7',
        ease: easing[0],
        delay: delay[Math.floor(Math.random() * 2) + 0],
        repeat: -1,
        yoyo: true,
      },
      'end'
    );
}
