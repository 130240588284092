export const fieldNames = {
  acceptTermsAndConditions: 'acceptTermsAndConditions',
};

export default {
  [fieldNames.acceptTermsAndConditions]: {
    truthy: {
      message: '^Please read and accept "Terms of use" and "Privacy policy" to continue',
    },
  },
};
