import React from 'react';
import { ModalBody } from 'reactstrap';

import classnames from 'classnames';
import styles from './index.module.scss';

const ModalBodyV2 = ({ className, children, ...passThrough }) => {
  return (
    <ModalBody className={classnames(className, styles.modalBody)} {...passThrough}>
      {children}
    </ModalBody>
  );
};

export default ModalBodyV2;
