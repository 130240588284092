import { camelizeKeys, decamelizeKeys } from 'humps';
import isAbsoluteUrl from 'is-absolute-url';

import unconventional from './uncoventional-keys';

function getApiRoot() {
  if (process.env.NODE_ENV === 'development')
    return window.devApiRoot || process.env.REACT_APP_API_ROOT;

  return process.env.REACT_APP_API_ROOT;
}

function buildFullUrl(url) {
  if (isAbsoluteUrl(url)) {
    return url;
  }
  let apiRoot = getApiRoot();
  if (!apiRoot.endsWith('/')) apiRoot = `${apiRoot}/`;
  return apiRoot + url;
}

// Fetches an API response
// This makes every API response have the same shape, regardless of how nested it was.
export const callApiRaw = ({ endpoint, method, headers, body }) => {
  const fullUrl = buildFullUrl(endpoint);

  const finalsHeaders = {
    ...headers,
  };

  if (window.amplitude && fullUrl.startsWith(getApiRoot())) {
    const ampInstance = window.amplitude.getInstance();
    if (ampInstance) {
      const ampDeviceId = ampInstance.options && ampInstance.options.deviceId;
      if (ampDeviceId) finalsHeaders['X-Amp-Device-Id'] = ampDeviceId;
      const ampSessionId = ampInstance._session_id;
      if (ampSessionId) finalsHeaders['X-Amp-Session-Id'] = ampSessionId;
    }
  }

  return fetch(fullUrl, {
    method,
    headers: finalsHeaders,
    body,
  }).then(response => {
    const contentType = response.headers.get('content-type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then(json => {
        if (!response.ok) {
          const error = { ...json, response };
          return Promise.reject(error);
        }

        const camelizedJson = camelizeKeys(
          json,
          (key, convert) => unconventional[key] || convert(key)
        );

        return camelizedJson;
      });
    } else {
      return response.text().then(text => {
        if (!response.ok) {
          const error = { text, response };
          return Promise.reject(error);
        }

        return text;
      });
    }
  });
};

export const callApiJson = ({ endpoint, method, headers, data, decamlizeInput = true }) => {
  const finalMethod = (method || 'GET').toUpperCase();

  const finalsHeaders = {
    ...headers,
    'Content-Type': 'application/json',
  };

  return callApiRaw({
    endpoint,
    method: finalMethod,
    headers: finalsHeaders,
    body:
      finalMethod !== 'GET'
        ? JSON.stringify(decamlizeInput ? decamelizeKeys({ ...data }) : data)
        : undefined,
  });
};

export function insertAuthToken(config, token) {
  let finalConfig = { ...config };
  finalConfig.headers = {
    ...finalConfig.headers,
    Authorization: `Bearer ${token.value}`,
  };

  return finalConfig;
}
