import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const label = defineMessages({
  [fieldNames.password]: {
    id: `user.delete.fields.password.label`,
    defaultMessage: 'Password',
  },
});

export const header = defineMessages({
  title: {
    id: 'user.delete.header.title',
    defaultMessage: 'Deleting Your Account',
  },
  subtitle: {
    id: 'user.delete.header.subtitle',
    defaultMessage:
      "If you're sure that you want to delete your account, you'll need to enter your password and agree to the following:",
  },
});

export const buttons = defineMessages({
  delete: {
    id: 'user.delete.header.delete',
    defaultMessage: 'Delete Account',
  },
  cancel: {
    id: 'user.delete.header.cancel',
    defaultMessage: 'Cancel',
  },
});

export const footer = defineMessages({
  needAssistace: {
    id: 'user.delete.footer.needAssistance',
    defaultMessage: 'Need assistance? Drop us a note at',
  },
  passwordNote: {
    id: 'user.delete.footer.passwordNote',
    defaultMessage:
      "If you've logged in via Google, LinkedIn, or Facebook, just press Delete Account.",
  },
});
