import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './styles.scss';
import Image from '../Image/Image';

const LoadingSpinner = ({ className, type }) => {
  let imgSrc = '';

  switch (type) {
    case 'switch':
      imgSrc = require('../../assets/loading-spinner-switch-2x.gif');
      break;
    case 'pulse':
      imgSrc = require('../../assets/loading-spinner-pulse-2x.gif');
      break;
    default:
      return null;
  }

  const loadingSpinnerClasses = classNames(className, 'loading-spinner', {
    switch: type === 'switch',
    pulse: type === 'pulse',
    'pulse-light': type === 'pulse-light',
  });

  return (
    <div className={loadingSpinnerClasses}>
      <Image src={imgSrc} />
    </div>
  );
};

LoadingSpinner.propTypes = {
  type: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  type: 'switch',
};

export default LoadingSpinner;
