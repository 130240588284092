/*
  ApplyJobPostModal
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connectModal as reduxModal } from 'redux-modal';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import Button from 'ui/common/button';
import messages from './messages';
import routeTemplates from 'ui/common/routes/templates';
import { logEvent } from 'services/amplitude-helper';

export const MODAL_NAME = 'authenticate-prompt';

class AuthenticatePromptModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  onClick(eventName) {
    const { eventRef, eventProps, handleHide } = this.props;

    logEvent(eventName, { ...eventProps, ref: eventRef });
    handleHide();
  }

  render() {
    const { title, location, handleHide, intl: { formatMessage }, show } = this.props;

    return (
      <Modal isOpen={show}>
        <ModalHeader toggle={handleHide}>{title}</ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-start pt-3">
            <Button
              color="primary"
              to={{ pathname: routeTemplates.auth.signUp, state: { from: location } }}
              onClick={() => this.onClick('signup_click')}
            >
              {formatMessage(messages.signUp)}
            </Button>
            <Button
              color="primary"
              isReady={false}
              className="ml-3"
              to={{ pathname: routeTemplates.auth.login, state: { from: location } }}
              onClick={() => this.onClick('login_click')}
            >
              {formatMessage(messages.login)}
            </Button>
          </div>
        </ModalBody>
        <ModalFooter />
      </Modal>
    );
  }
}

export default reduxModal({
  name: MODAL_NAME,
})(injectIntl(AuthenticatePromptModal));
