import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entitySelectors from 'store/entities/selectors';
import { selectCurrentUserId } from 'store/features/auth/selectors';
import { normalizeUsername } from './helpers';

const selectUsersFeatureState = state => state.features.users;

const selectUserIdsByUsername = createSelector(
  selectUsersFeatureState,
  usersFeatureState => usersFeatureState.idsByUserName
);

export const selectUserById = createSelector(
  entitySelectors.selectAllEntities,
  (state, props) => props.id,
  (entities, id) => denormalize(id, schemas.user, entities)
);

export const selectUserByUsername = createSelector(
  entitySelectors.selectAllEntities,
  selectUserIdsByUsername,
  (state, props) => props.username,
  (entities, userIdsByUserName, username) => {
    const normalizedUsername = normalizeUsername(username);
    return denormalize(userIdsByUserName[normalizedUsername], schemas.user, entities);
  }
);

export const selectIsFetchingUserById = createSelector(
  selectUsersFeatureState,
  (state, props) => props.id,
  (usersFeatureState, userId) =>
    usersFeatureState.byId[userId] && usersFeatureState.byId[userId].fetching
);

export const selectIsUserFetchedAtleastOnceById = createSelector(
  selectUsersFeatureState,
  (state, props) => props.id,
  (usersFeatureState, userId) =>
    usersFeatureState.byId[userId] && !!usersFeatureState.byId[userId].lastFetchedAt
);

export const selectIsFetchingUserByUsername = createSelector(
  selectUsersFeatureState,
  (state, props) => props.username,
  (usersFeatureState, username) => {
    const normalizedUsername = normalizeUsername(username);
    return (
      usersFeatureState.byUsername[normalizedUsername] &&
      usersFeatureState.byUsername[normalizedUsername].fetching
    );
  }
);

export const selectIsUserFetchedAtleastOnceByUsername = createSelector(
  selectUsersFeatureState,
  (state, props) => props.username,
  (usersFeatureState, username) => {
    const normalizedUsername = normalizeUsername(username);
    return (
      usersFeatureState.byUsername[normalizedUsername] &&
      !!usersFeatureState.byUsername[normalizedUsername].lastFetchedAt
    );
  }
);

export const selectIsUpdatingUserApprovalStatus = createSelector(
  selectUsersFeatureState,
  (state, props) => props.id,
  (usersFeatureState, userId) =>
    usersFeatureState.byId[userId] && !!usersFeatureState.byId[userId].updatingApprovalStatus
);

export const selectUpdateUserApprovalStatusError = createSelector(
  selectUsersFeatureState,
  (state, props) => props.id,
  (usersFeatureState, userId) =>
    usersFeatureState.byId[userId] && usersFeatureState.byId[userId].updateApprovalStatusError
);

export const selectIsAssigningRole = createSelector(
  selectUsersFeatureState,
  (state, props) => props.id,
  (usersFeatureState, userId) =>
    usersFeatureState.byId[userId] && usersFeatureState.byId[userId].assigningRole
);

export const selectAssignRoleError = createSelector(
  selectUsersFeatureState,
  (state, props) => props.id,
  (usersFeatureState, userId) =>
    usersFeatureState.byId[userId] && usersFeatureState.byId[userId].assignRoleError
);

export const selectIsImportingLinkedInPdf = createSelector(
  selectUsersFeatureState,
  usersFeatureState => usersFeatureState.linkedInImport.importing
);

export const selectLinkedInPdfImportError = createSelector(
  selectUsersFeatureState,
  usersFeatureState => usersFeatureState.linkedInImport.error
);

export const selectCurrentProfileSection = createSelector(
  selectUsersFeatureState,
  usersFeatureState => usersFeatureState.waypoint
);

export const selectShowWorkSectionInProfileOfId = createSelector(
  selectUserById,
  selectCurrentUserId,
  (user, currentUserId) =>
    user && (user.id === currentUserId || (user.work && user.work.length > 0))
);

export const selectShowEducationSectionInProfileOfId = createSelector(
  selectUserById,
  selectCurrentUserId,
  (user, currentUserId) =>
    user && (user.id === currentUserId || (user.education && user.education.length > 0))
);

export const selectShowSkillsSectionInProfileOfId = createSelector(
  selectUserById,
  selectCurrentUserId,
  (user, currentUserId) =>
    user && (user.id === currentUserId || (user.skills && user.skills.length > 0))
);

export const selectShowBadgesSectionInProfileOfId = createSelector(
  selectUserById,
  selectCurrentUserId,
  (user, currentUserId) => user && (user.badges && user.badges.length > 0)
);

export const selectSkillIsHighlighted = createSelector(
  selectUsersFeatureState,
  usersFeatureState => usersFeatureState.skillsTemp.isHighlighted
);
