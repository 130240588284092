import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

export const noOpReducer = (state, action) => state;

export function createEntitiesReducer(schema, reducer = noOpReducer, initialState = {}) {
  return (state = Immutable(initialState), action) => {
    const schemaKey = schema.key;
    let newState = state;

    switch (action.type) {
      case actionTypes.MERGE_ENTITIES:
        const options = { deep: true, ...(action.meta ? action.meta[schemaKey] : {}) };

        newState = action.payload[schemaKey]
          ? state.merge(action.payload[schemaKey], options)
          : state;

        break;
      case actionTypes.REMOVE_ENTITIES:
        newState = action.payload[schemaKey] ? state.without(action.payload[schemaKey]) : state;

        break;
      default:
        break;
    }

    return reducer(newState, action);
  };
}

export function createEntitiesSelector(schema) {
  return state => state.entities[schema.key];
}
