import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';
import Image from '../Image/Image';

function Avatar(props) {
  const {
    alt,
    initials,
    avatarSize,
    childrenClassName: childrenClassNameProp,
    className: classNameProp,
    component: Component,
    imgProps,
    sizes,
    src,
    srcSet,
    ...other
  } = props;

  const className = classNames(
    classNameProp,
    'avatar',
    { large: avatarSize === 'large' },
    { 'typography-text-small': avatarSize !== 'large' }
  );

  let children = initials;

  if (src || srcSet) {
    const standardImgProps = {
      alt: alt,
      src: src,
      srcSet: srcSet,
      sizes: sizes,
      className: 'avatar-img',
    };
    children = <Image {...standardImgProps} {...imgProps} />;
  }

  return (
    <Component className={className} {...other}>
      {children}
    </Component>
  );
}

Avatar.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  avatarSize: PropTypes.string,
  childrenClassName: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  imgProps: PropTypes.object,
  sizes: PropTypes.string,
  src: PropTypes.string,
  srcSet: PropTypes.string,
};

Avatar.defaultProps = {
  component: 'div',
};

export default Avatar;
