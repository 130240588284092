import { defineAsyncActionTypes, defineApiActionTypes } from 'store/common/action-helpers';

export const LOAD_FACEBOOK_SDK_LIB = defineApiActionTypes('api/LOAD_FACEBOOK_SDK_LIB');

export const LOAD_GOOGLE_API_LIB = defineAsyncActionTypes('api/LOAD_GOOGLE_API_LIB');

export const LOAD_GOOGLE_AUTH_LIB = defineApiActionTypes('api/LOAD_GOOGLE_AUTH_LIB');

export const LOAD_RECAPTCHA_SDK_LIB = defineAsyncActionTypes('api/LOAD_RECAPTCHA_SDK_LIB');

export const LOAD_WEB3_LIB = defineApiActionTypes('api/LOAD_WEB3_LIB');

export const LOAD_STRIPE_CHECKOUT_LIB = defineApiActionTypes('api/LOAD_STRIPE_CHECKOUT_LIB');
