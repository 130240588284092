import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as modalReducer } from 'redux-modal';

import libs from 'store/libs/reducer.js';
import entities from 'store/entities/reducer.js';
import features from 'store/features/reducer.js';

export const createReducers = (graphqlInjectedReducers = {}) => {
  const rootReducers = combineReducers({
    intl: intlReducer,
    router: routerReducer,
    form: formReducer,
    modal: modalReducer,
    libs,
    entities,
    features,
    ...graphqlInjectedReducers,
  });

  return rootReducers;
};

export default createReducers();
