import React from 'react';
import classNames from 'classnames';

const CheckboxIcon = () => {
  const checkBoxWrapperClasses = classNames('checkbox-icon-wrapper');
  const checkBoxIconClasses = classNames('checkbox-icon');
  const checkBoxIconInnerClasses = classNames('checkbox-icon-inner');
  return (
    <span className={checkBoxWrapperClasses}>
      <div className={checkBoxIconClasses}>
        <div className={checkBoxIconInnerClasses} />
      </div>
    </span>
  );
};

export default CheckboxIcon;
