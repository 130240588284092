import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './styles.scss';

const TextArea = ({
  className,
  id,
  labelText,
  hideLabel,
  onChange,
  onClick,
  hasError,
  errorMessage,
  wrapperClass,
  value,
  characterCount,
  characterCountMax,
  smallLabel,
  disabled,
  ...other
}) => {
  const textareaProps = {
    id,
    value,
    onChange: evt => {
      if (!other.disabled) {
        onChange(evt, evt.currentTarget.value);
      }
    },
    onClick: evt => {
      if (!other.disabled) {
        onClick(evt);
      }
    },
  };

  const textareaClasses = classNames('text-area', {
    [className]: true,
    'text-muted': disabled,
  });
  const labelClasses = classNames('text-area-label', {
    small: smallLabel,
    'd-none': hideLabel,
    'is-invalid': hasError,
    'text-muted': disabled,
  });

  const label = labelText ? (
    <label htmlFor={id} className={labelClasses}>
      {labelText}
    </label>
  ) : null;

  const error = hasError ? <div className="form-message-requirement">{errorMessage}</div> : null;

  const input = hasError ? (
    <textarea {...other} {...textareaProps} className={textareaClasses} data-invalid />
  ) : (
    <textarea {...other} {...textareaProps} className={textareaClasses} />
  );

  return (
    <div className={wrapperClass}>
      <div className="form-item">
        {label}
        {input}
        {characterCount && (
          <div className="d-flex flex-row-reverse mt-1">
            <span className={classNames(value.length > characterCountMax && 'text-danger')}>
              {value.length}/{characterCountMax}
            </span>
          </div>
        )}
      </div>
      {hasError && <span className="text-danger">{error}</span>}
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  cols: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hideLabel: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  wrapperClass: PropTypes.string,
  characterCount: PropTypes.bool,
  characterCountMax: PropTypes.number,
};

TextArea.defaultProps = {
  disabled: false,
  onChange: () => {},
  onClick: () => {},
  placeholder: '',
  rows: 4,
  cols: 50,
  errorMessage: '',
  wrapperClass: 'py-2',
  characterCount: false,
  characterCountMax: 200,
};

export default TextArea;
