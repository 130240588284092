import { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { loadWeb3Lib } from 'store/libs/actions';
import { selectWeb3LibLoadState } from 'store/libs/selectors';
import { extraBasicInfoFromWeb3 } from 'store/features/ethereum/actions';

import Web3Facade from 'ethereum/web3-facade';
import { createInteractiveWeb3FacadeFromInjectedProviderAsync } from 'ethereum/web3-facade-factory';

const { request: loadWeb3LibRequest } = loadWeb3Lib;
const { request: extraBasicInfoFromWeb3Request } = extraBasicInfoFromWeb3;

class Web3Provider extends Component {
  static childContextTypes = {
    web3: PropTypes.instanceOf(Web3Facade),
  };

  constructor(props, context) {
    super(props, context);

    this.initializeWeb3 = this.initializeWeb3.bind(this);

    this.web3 = null;

    this.state = {
      initializedWeb3: false,
    };
  }

  async componentDidMount() {
    const { web3Status: { loaded, loading, error } } = this.props;
    if (!error) {
      if (loaded) await this.initializeWeb3();
      else if (!loading) this.props.loadWeb3LibRequest();
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.web3Status && nextProps.web3Status && !this.props.web3Status.loaded) {
      if (!nextProps.web3Status.error) {
        if (nextProps.web3Status.loaded) await this.initializeWeb3();
        else if (!nextProps.web3Status.loading) nextProps.loadWeb3LibRequest();
      }
    }
  }

  async initializeWeb3() {
    this.web3 = await createInteractiveWeb3FacadeFromInjectedProviderAsync();
    this.props.extraBasicInfoFromWeb3Request({ web3: this.web3 });
    this.setState({ initializedWeb3: true });
  }

  getChildContext() {
    return {
      web3: this.web3,
    };
  }

  render() {
    return Children.only(this.props.children);
  }
}

function mapStateToProps(state) {
  const web3Status = selectWeb3LibLoadState(state);

  return {
    web3Status,
  };
}

const mapDispatchToProps = {
  loadWeb3LibRequest,
  extraBasicInfoFromWeb3Request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Web3Provider);
