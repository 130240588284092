import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

export default function reducer(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.COMPLETE_STEP.START:
    case actionTypes.SKIP_STEP.START:
    case actionTypes.FETCH_WIZARD_STATUS.START:
    case actionTypes.FINISH_WIZARD.START:
      return state.merge(
        {
          [action.payload.name]: {
            fetching: true,
          },
        },
        { deep: true }
      );
    case actionTypes.COMPLETE_STEP.SUCCESS:
    case actionTypes.SKIP_STEP.SUCCESS:
    case actionTypes.FETCH_WIZARD_STATUS.SUCCESS:
    case actionTypes.FINISH_WIZARD.SUCCESS:
      return state.merge(
        {
          [action.payload.name]: {
            ...action.payload.response,
            fetching: false,
            lastFetchedAt: new Date().toISOString(),
            failed: action.payload.response.failed || false,
            reason: action.payload.response.reason || null,
          },
        },
        { deep: true }
      );
    case actionTypes.COMPLETE_STEP.FAILURE:
    case actionTypes.SKIP_STEP.FAILURE:
    case actionTypes.FETCH_WIZARD_STATUS.FAILURE:
    case actionTypes.FINISH_WIZARD.FAILURE:
      return state.merge(
        {
          [action.payload.name]: {
            fetching: false,
          },
        },
        { deep: true }
      );
    default:
      return state;
  }
}
