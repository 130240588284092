// Exit intent

function addEvent(fn, obj = document, evt = 'mouseout') {
  if (obj.addEventListener) {
    obj.addEventListener(evt, fn, false);
  } else if (obj.attachEvent) {
    obj.attachEvent('on' + evt, fn);
  }
}

// Exit intent trigger
// addEvent(document, 'mouseout', function(evt) {

//     if (evt.toElement == null && evt.relatedTarget == null ) {
//         console.log("out!")
//     };

// });

export default addEvent;
