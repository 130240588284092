import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Radar } from 'react-chartjs-2';

export class RadarChart extends Component {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string),
    dataPoints: PropTypes.arrayOf(PropTypes.number),
  };

  render() {
    const { labels, dataPoints } = this.props;

    const data = dataPoints;

    const dataObj = {
      labels,
      datasets: [
        {
          fill: true,
          backgroundColor: 'rgba(32, 181, 197, 0.76)',
          borderColor: 'rgba(32, 181, 197, 0.76)',
          pointBackgroundColor: '#fa8072',
          borderCapStyle: 'butt',
          pointBorderColor: '#fa8072',
          pointHoverBackgroundColor: '#fa8072',
          pointHoverBorderColor: '#20b6c7',
          borderJoinStyle: 'miter',
          data,
        },
      ],
    };

    // decimel fix https://stackoverflow.com/questions/37699485/skip-decimal-points-on-y-axis-in-chartjs

    const options = {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          left: 5,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      gridLines: {
        display: false,
      },
      scale: {
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 5,
        },
        pointLabels: {
          fontSize: 12,
          fontColor: 'black',
        },
      },
    };

    return <Radar data={dataObj} options={options} />;
  }
}

export default RadarChart;
