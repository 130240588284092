import { defineMessages } from 'react-intl';

export default defineMessages({
  entryAdded: {
    id: 'work-experience.entries.added',
    defaultMessage: 'Added work experience entry successfully',
  },
  entryUpdated: {
    id: 'work-experience.entries.updated',
    defaultMessage: 'Updated work experience entry successfully',
  },
  entryDeleted: {
    id: 'work-experience.entries.deleted',
    defaultMessage: 'Deleted work experience entry successfully',
  },
});
