import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  data: [],
  fetching: false,
  updating: false,
  error: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SUBMIT_FORM.START: {
      return state.set('updating', true);
    }

    case actionTypes.SUBMIT_FORM.FAILURE: {
      const { error } = action.payload;
      return state.set('updating', false).set('error', error);
    }

    case actionTypes.SUBMIT_FORM.SUCCESS: {
      return state.set('updating', false).set('error', false);
    }
    default:
      return state;
  }
}
