export const skillLevelIds = {
  beginner: 'beginner',
  intermediate: 'intermediate',
  expert: 'expert',
};

export const skillLevelIdsOrder = [
  skillLevelIds.beginner,
  skillLevelIds.intermediate,
  skillLevelIds.expert,
];

export const skillLevels = {
  [skillLevelIds.beginner]: {
    name: 'Beginner',
  },
  [skillLevelIds.intermediate]: {
    name: 'Intermediate',
  },
  [skillLevelIds.expert]: {
    name: 'Expert',
  },
};

Object.keys(skillLevelIds).forEach((levelId, index) => {
  skillLevels[levelId].id = levelId;
  skillLevels[levelId].power = index + 1;
});

export const isBetterLevel = (levelOne, levelTwo) => {
  const power = {
    preBeginner: 0,
    beginner: 1,
    intermediate: 2,
    expert: 3,
  };

  return power[levelOne] >= power[levelTwo];
};
