import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entitySelectors from 'store/entities/selectors';
import { selectCurrentUser } from 'store/features/auth/selectors';

const selectCompaniesFeatureState = state => state.features.companies;

export const selectSmartContractDetails = createSelector();

export const selectCompanyByPrettyId = createSelector(
  entitySelectors.selectAllEntities,
  (state, props) => props.prettyId,
  (entities, prettyId) => denormalize(prettyId, schemas.company, entities)
);

export const selectIsFetchingCompanyByPrettyIdInBackground = createSelector(
  selectCompaniesFeatureState,
  (state, props) => props.prettyId,
  (companiesFeatureState, prettyId) =>
    companiesFeatureState[prettyId] &&
    companiesFeatureState[prettyId].fetching &&
    companiesFeatureState[prettyId].fetchingInBackground
);

export const selectIsFetchingCompanyByPrettyId = createSelector(
  selectCompaniesFeatureState,
  (state, props) => props.prettyId,
  (companiesFeatureState, prettyId) =>
    companiesFeatureState[prettyId] && companiesFeatureState[prettyId].fetching
);

export const selectIsCompanyFetchedAtleastOnceByPrettyId = createSelector(
  selectCompaniesFeatureState,
  (state, props) => props.prettyId,
  (companiesFeatureState, prettyId) =>
    companiesFeatureState[prettyId] && !!companiesFeatureState[prettyId].lastFetchedAt
);

export const selectIsFetchingCompanyTeamMembersByPrettyId = createSelector(
  selectCompaniesFeatureState,
  (state, props) => props.prettyId,
  (companiesFeatureState, prettyId) =>
    companiesFeatureState[prettyId] && companiesFeatureState[prettyId].fetchingTeamMembers
);

export const selectIsCompanyTeamMembersFetchedAtleastOnceByPrettyId = createSelector(
  selectCompaniesFeatureState,
  (state, props) => props.prettyId,
  (companiesFeatureState, prettyId) =>
    companiesFeatureState[prettyId] && !!companiesFeatureState[prettyId].lastFetchedTeamMembersAt
);

export const selectIsCurrentUserAclMemberOfCompanyByPrettyId = createSelector(
  selectCurrentUser,
  (state, props) => props.prettyId,
  (currentUser, prettyId) =>
    currentUser &&
    currentUser.myCompanies &&
    (currentUser.myCompanies || []).find(c => c && c.prettyId === prettyId)
);
