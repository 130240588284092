import { defineMessages } from 'react-intl';

export const labels = defineMessages({
  title: {
    id: 'companies.ems.getStarted.title',
    defaultMessage: 'Indorse at a glance!',
  },
  subtitle: {
    id: 'companies.ems.getStarted.subtitle',
    defaultMessage: 'Below are some of our main features!',
  },
  stepOne: {
    id: 'companies.ems.getStarted.stepOne',
    defaultMessage: 'Competency mapping',
  },
  stepOneDetails: {
    id: 'companies.ems.getStarted.stepOneDetails',
    defaultMessage:
      'Identify key tech competencies to facilitate future workforce planning and career mapping.',
  },
  stepTwo: {
    id: 'companies.ems.getStarted.stepTwo',
    defaultMessage: 'Skill gap analysis',
  },
  stepTwoDetails: {
    id: 'companies.ems.getStarted.stepTwoDetails',
    defaultMessage:
      "Use extensive & customizable skills matrices to identify and close tech teams' skills gap.",
  },
  stepThree: {
    id: 'companies.ems.getStarted.stepThree',
    defaultMessage: 'Career paths',
  },
  stepThreeDetails: {
    id: 'companies.ems.getStarted.stepThreeDetails',
    defaultMessage:
      'Use our platform to seamlessly visualize & build tech career paths and career ladders.',
  },
  stepFour: {
    id: 'companies.ems.getStarted.stepFour',
    defaultMessage: 'Performance Management',
  },
  stepFourDetails: {
    id: 'companies.ems.getStarted.stepFourDetails',
    defaultMessage:
      'Leverage various micro tests & surveys for same day skills metrics and boost your workforce visibility!',
  },
  getStarted: {
    id: 'companies.ems.getStarted.getStarted',
    defaultMessage: 'Next',
  },
});
