/*
  SlackAuthButton
*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import shortid from 'shortid';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { buildMessage } from 'store/features/common/flash/builder';
import * as storage from 'store/features/auth/storage';
import * as flashActions from 'store/features/common/flash/actions';
import jwtDecode from 'jwt-decode';
import Button from 'ui/common/button';
import { slackMethod } from 'resources/slack';
import routeTemplates from 'ui/common/routes/templates';
import messages from './messages';
import * as authCallbackActionTypes from 'store/graphql/SlackAuthCallback/action-types';
import * as actionTypes from 'store/graphql/SlackAuthButton/action-types';
import * as authActionTypes from 'store/features/auth/action-types';
import {
  selectFetchingStart,
  selectAuthenticatingWithSlack,
  selectSlackAuthCallbackState,
} from 'store/graphql/SlackAuthCallback/selectors';
import {
  selectLoginWithSlack,
  selectError,
  selectSlackAuthButtonState,
} from 'store/graphql/SlackAuthButton/selectors';
import Loading from 'ui/common/loading';
import * as smartRouteActions from 'store/features/smart-route/actions';
import * as libSelectors from 'store/libs/selectors';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import SlackAuthButtonReducer from 'store/graphql/SlackAuthButton/reducer';
import SlackAuthCallbackReducer from 'store/graphql/SlackAuthCallback/reducer';
import styles from './index.module.scss';
import SlackPNG from 'resources/slack/slack.png';

class SlackAuthButton extends Component {
  static propTypes = {
    existingValues: PropTypes.object,
    from: PropTypes.string,
    isReady: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { loginWithSlackCodeStart } = this.props;

    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );

    const location = window.location;
    const authData = new URLSearchParams(location.search);
    const code = authData.get('code');
    const state = authData.get('state');

    if (location && location.search && authenticationWithSlack && code && state) {
      // Move this here so that the loading spinner can load immediate.
      loginWithSlackCodeStart();
    }
  }

  async ultimateLoginWithSlack() {
    const { isFetchingStart, slackAuthSuccess, slackAuthFailure, push } = this.props;
    const fromLocation = storage.getDataInLocalStorage('slack-from');
    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );

    if (!isFetchingStart) {
      const location = window.location;
      const authData = new URLSearchParams(location.search);
      const code = authData.get('code');
      const state = authData.get('state');
      const error = authData.get('error');

      if (location && location.search && authenticationWithSlack && code && state && !error) {
        slackAuthSuccess({ code, state });

        this.loginWithSlackCode(code, fromLocation);
      } else if (error) {
        slackAuthFailure({ error });
        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);
        push(routeTemplates.auth.login);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const previousRecaptchaStatus = !!(
      prevProps.recaptchaStatus && prevProps.recaptchaStatus.loaded
    );
    const currentRecaptchaStatus = !!(
      this.props.recaptchaStatus && this.props.recaptchaStatus.loaded
    );
    if (previousRecaptchaStatus !== currentRecaptchaStatus) {
      this.ultimateLoginWithSlack();
    }
  }

  async loginWithSlackCode(code, fromLocation) {
    const {
      client,
      existingValues,
      loginSuccessRequest,
      redirectUri,
      loginWithSlackCodeSuccess,
      loginWithSlackCodeFailure,
      push,
      setSmartRoute,
    } = this.props;

    try {
      // const recaptchaToken = await window.grecaptcha.execute(
      //   process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
      //   { action: "loginwithslack" }
      // );

      const response = await client.mutate({
        mutation: gql`
          mutation signInWithSlack($code: String!, $redirectUrl: String!) {
            signInWithSlack(code: $code, redirectUrl: $redirectUrl) {
              ok
              token
            }
          }
        `,
        variables: {
          code: code,
          redirectUrl: redirectUri,
        },
        // context: {
        //   headers: {
        //     "x-recaptcha-token": recaptchaToken
        //   }
        // }
      });

      const { data, errors } = response;
      if (errors && errors[0].message) {
        push(fromLocation, {
          flash: buildMessage({
            id: 'login-with-slack.error',
            kind: 'danger',
            content: errors[0].message,
          }),
        });

        loginWithSlackCodeFailure({ error: errors[0].message });
      } else if (data && data.signInWithSlack && data.signInWithSlack.token) {
        storage.setToken(data.signInWithSlack.token);
        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);
        storage.setDataInLocalStorage(slackMethod.loginWithSlack, true);

        loginSuccessRequest({
          token: data.signInWithSlack.token,
          redirect: true,
          from: fromLocation,
        });
        loginWithSlackCodeSuccess({
          ok: data.signInWithSlack.ok,
          token: data.signInWithSlack.token,
        });

        if (fromLocation) {
          storage.deleteDataInLocalStorage('slack-from');
        }
      } else if (data && data.signInWithSlack && data.signInWithSlack.ok === false) {
        const decoded = jwtDecode(data.signInWithSlack.token);
        const { name } = decoded;

        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);

        push(routeTemplates.auth.signUp, {
          fromLocation,
          provider: 'slack',
          fieldValues: { ...existingValues, name },
          providerValues: { token: data.signInWithSlack.token },
          flash: buildMessage({
            id: 'slack-auth-button.additional-fields-required',
            kind: 'info',
            content: messages.additionalFieldsRequired,
          }),
        });
        loginWithSlackCodeFailure({
          error: messages.additionalFieldsRequired,
        });

        setSmartRoute({
          isEmailVerify: true,
        });

        if (fromLocation) {
          setSmartRoute({
            routeAfterEmailVerify: fromLocation,
          });

          storage.deleteDataInLocalStorage('slack-from');
        }
      }
    } catch (error) {
      loginWithSlackCodeFailure({ error });
    }
  }

  async slackAuthCallback() {
    const { slackAuthStart, slackAuthFailure, redirectUri } = this.props;

    slackAuthStart();

    try {
      const urlParams = new URLSearchParams();
      const requestState = shortid.generate();

      urlParams.set('client_id', process.env.REACT_APP_SLACK_CLIENT_ID);
      urlParams.set('redirect_uri', redirectUri);
      urlParams.set('user_scope', 'identity.basic,identity.email');
      urlParams.set('state', requestState);
      urlParams.set('allow_signup', 'true');
      window.location = `${process.env.REACT_APP_SLACK_API_URL}?${urlParams}`;

      storage.setDataInLocalStorage(slackMethod.authenticationWithSlack, true);
    } catch (apiError) {
      flashActions.addMessage({
        id: 'slack.auth.status',
        kind: 'danger',
        content: apiError,
      });
      slackAuthFailure({ error: apiError });
    }
  }

  render() {
    const {
      isAuthenticatingWithSlack,
      isLoginWithSlack,
      isFetchingStart,
      children,
      isReady,
      isBlock,
      ...other
    } = this.props;

    if (isLoginWithSlack) {
      return <Loading />;
    }

    return (
      <Button
        isReady={isReady || false}
        // onClick={this.githubAuthCallback}
        onClick={this.slackAuthCallback}
        loading={isAuthenticatingWithSlack || isFetchingStart || isLoginWithSlack}
        disabled={isAuthenticatingWithSlack}
        iconPosition="suffix"
        isBlock={isBlock}
        {...other}
        className={classnames(styles.slackBtn, styles.socialBtn, 'px-4 position-relative')}
      >
        {children ? (
          <div>{children}</div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            {/* <i className="fab fa-slack mr-2" /> */}
            <img src={SlackPNG} alt={'slack-img'} width="16" height="16" />
            <FormattedMessage id="auth.slack-button" defaultMessage="Slack" />
          </div>
        )}
      </Button>
    );
  }
}

function mapStateToProps(state, props) {
  const redirectUri = `${window.location.origin}/slack-auth`;
  // const redirectUri = `https://ecs-integration-frontend.indorse.io/slack-auth`;
  const slackAuthButtonState = selectSlackAuthButtonState(state);
  const slackAuthCallback = selectSlackAuthCallbackState(state);
  const isFetchingStart = slackAuthCallback && selectFetchingStart(state);
  const isAuthenticatingWithSlack = slackAuthCallback && selectAuthenticatingWithSlack(state);
  const isLoginWithSlack = slackAuthButtonState && selectLoginWithSlack(state);
  const recaptchaStatus = libSelectors.selectRecaptchaSdkLibLoadState(state);

  const from =
    (state && state.router && state.router.location && state.router.location.pathname) || '';

  return {
    errorMessage: slackAuthButtonState && selectError(state),
    redirectUri,
    isFetchingStart,
    isAuthenticatingWithSlack,
    isLoginWithSlack,
    recaptchaStatus,
    from,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    slackAuthStart: () => dispatch({ type: authCallbackActionTypes.SLACK_AUTH.START }),
    slackAuthSuccess: ({ code, state }) =>
      dispatch({
        type: authCallbackActionTypes.SLACK_AUTH.SUCCESS,
        payload: { code, state },
      }),
    slackAuthFailure: error =>
      dispatch({
        type: authCallbackActionTypes.SLACK_AUTH.FAILURE,
        payload: { error },
      }),

    loginWithSlackCodeStart: () => dispatch({ type: actionTypes.LOGIN_WITH_SLACK_CODE.START }),
    loginWithSlackCodeSuccess: ({ ok, token }) =>
      dispatch({
        type: actionTypes.LOGIN_WITH_SLACK_CODE.SUCCESS,
        payload: { ok, token },
      }),
    loginWithSlackCodeFailure: ({ error }) =>
      dispatch({
        type: actionTypes.LOGIN_WITH_SLACK_CODE.FAILURE,
        payload: { error },
      }),
    loginSuccessRequest: ({ token, redirect, from }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
      }),
    push: (routeName, locationState) => dispatch(push(routeName, locationState)),
    setSmartRoute: bindActionCreators(smartRouteActions.setSmartRoute, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSlackAuthButtonReducer = injectReducer({
  key: 'SlackAuthButton',
  reducer: SlackAuthButtonReducer,
});
const withSlackAuthCallbackReducer = injectReducer({
  key: 'SlackAuthCallback',
  reducer: SlackAuthCallbackReducer,
});

export default compose(withSlackAuthButtonReducer, withSlackAuthCallbackReducer, withConnect)(
  injectIntl(withApollo(SlackAuthButton))
);
