/**
 *
 * Asynchronously loads the component for PdfExportButton
 *
 */

import React, { Suspense } from 'react';

const LazyComponent = React.lazy(() => import('./index'));

export default props => (
  <Suspense fallback={null}>
    <LazyComponent {...props} />
  </Suspense>
);
