import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from 'ui/common/loading';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import EmsApps from 'store/graphql/EmsApps/reducer';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { errorsToSingleMessage, resultHasErrors } from '../../../utils/graphql-errors';
import { messageTypes } from '../../../store/features/common/flash/builder';
import { addMessage } from '../../../store/features/common/flash/actions';
import { show as showModal } from 'redux-modal';
import { push } from 'react-router-redux';
import routeGenerators from '../../common/routes/generators';

const GitInstallSucessRedirectSetup = props => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const updateSetupStep = async () => {
    setLoading(true);
    try {
      const { client, addMessage, push, companyId, prettyId } = props;

      const response = await client.mutate({
        mutation: gql`
          mutation wizard_updateStep(
            $companyId: String!
            $wizardStepKey: String!
            $status: WizardStepStatusEnum!
          ) {
            wizard_updateStep(
              companyId: $companyId
              wizardStepKey: $wizardStepKey
              status: $status
            ) {
              key
            }
          }
        `,
        variables: {
          companyId: companyId,
          wizardStepKey: 'ems_gitAppInstallation',
          status: 'DONE',
        },
      });

      const { errors } = response;
      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        setError(true);
      } else {
        // const { wizard_updateStep } = data;
        push(
          routeGenerators.companies.emsSetup({
            prettyId: prettyId,
            stepId: '',
          })
        );
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    updateSetupStep();
  }, []);

  if (isError) return <h1 className={'mt-5 text-center'}>Error</h1>;

  return isLoading && <Loading />;
};

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps && ownProps.match && ownProps.match.params;
  const companyId = routeParams && routeParams.companyId;
  const prettyId = routeParams && routeParams.prettyId;

  return {
    companyId,
    prettyId,
  };
}

const mapDispatchToProps = {
  addMessage,
  showModal,
  push,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'EmsApps',
  reducer: EmsApps,
});

export default compose(withReducer, withConnect)(withApollo(GitInstallSucessRedirectSetup));

// http://localhost:4000/ems/github-app-callback?code=d3ca17496d490e3ae05f&installation_id=10872288&setup_action=install
