import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const label = defineMessages({
  [fieldNames.adminEmail]: {
    id: 'graphql.create-pricing-wizard.company-admin.email',
    defaultMessage: `Email Address`,
  },
});

export const buttons = defineMessages({
  submitButton: {
    id: 'graphql.create-pricing-wizard.submit.company',
    defaultMessage: 'Register',
  },
});
