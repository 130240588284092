import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

function Logo(props) {
  const { isIcon, className: classNameProp, titleAccess, viewBox, ...other } = props;

  const logoClassName = classNames('logo', { 'logo-icon': isIcon }, classNameProp);

  const logo = isIcon ? (
    <svg
      className={logoClassName}
      focusable="false"
      viewBox="0 0 26 26"
      aria-hidden={titleAccess ? 'false' : 'true'}
      {...other}
    >
      {titleAccess ? <title>{titleAccess}</title> : null}
      <g>
        <polygon points="0,13 0,26 13,26 26,26 26,13 13,13 	" />
        <path d="M26,0H0c0,7.2,5.8,13,13,13C20.2,13,26,7.2,26,0z" />
      </g>
    </svg>
  ) : (
    <svg
      className={logoClassName}
      focusable="false"
      viewBox="0 0 1920 280.4"
      aria-hidden={titleAccess ? 'false' : 'true'}
      {...other}
    >
      {titleAccess ? <title>{titleAccess}</title> : null}
      <g id="Layer_2">
        <g>
          <g>
            <path d="M508.5,85.2h46.6v40.5c15.7-30.8,42.6-46.2,80.7-46.2c27,0,47.5,7.5,61.4,22.4c13.9,14.9,20.8,36.9,20.8,65.9v106.8
				h-48.1V175c0-38.4-17.4-57.6-52.3-57.6c-18.9,0-33.9,5.7-44.7,17.2c-10.9,11.5-16.3,27.2-16.3,47.2v92.8h-48.1V85.2z" />
            <path d="M808.4,267.7c-14.5-8.5-25.8-20.3-33.9-35.4c-8.1-15.2-12.1-32.6-12.1-52.3s4.2-37.1,12.5-52.3c8.3-15.2,20-27,35-35.4
				c15-8.5,32.1-12.7,51.3-12.7c34.4,0,59.5,11.7,75.4,35.2V0h48.1v274.7h-46.2v-35.6c-7.6,13.1-18.2,23.3-32,30.5
				c-13.8,7.2-29.9,10.8-48.3,10.8C839.6,280.4,822.9,276.1,808.4,267.7z M920.2,228.3c11.5-10.7,17.2-24.9,17.2-42.6v-10.2
				c0-18.2-5.8-32.8-17.4-43.8c-11.6-11-27-16.5-46.2-16.5c-19.2,0-34.2,5.7-44.9,17c-10.7,11.4-16.1,27.2-16.1,47.4
				c0,20,5.6,35.7,16.7,47.4c11.1,11.6,26.3,17.4,45.5,17.4C893.6,244.4,908.7,239,920.2,228.3z" />
            <path d="M1084.3,268.3c-17.1-8.1-30.3-19.7-39.8-34.9c-9.5-15.2-14.2-33-14.2-53.4c0-20.5,4.7-38.3,14.2-53.4
				c9.5-15.2,22.7-26.8,39.8-34.9c17-8.1,36.7-12.1,58.9-12.1c22.2,0,41.9,4,58.9,12.1c17,8.1,30.3,19.7,39.8,34.9
				c9.5,15.2,14.2,33,14.2,53.4c0,20.5-4.7,38.3-14.2,53.4c-9.5,15.2-22.7,26.8-39.8,34.9c-17.1,8.1-36.7,12.1-58.9,12.1
				C1121,280.4,1101.3,276.3,1084.3,268.3z M1189.6,227.9c11.2-11.2,16.9-27.2,16.9-47.9c0-20.7-5.6-36.7-16.9-47.9
				c-11.2-11.2-26.7-16.9-46.4-16.9c-19.7,0-35.1,5.6-46.2,16.9c-11.1,11.2-16.7,27.2-16.7,47.9c0,20.7,5.6,36.7,16.9,47.9
				c11.2,11.2,26.6,16.9,46,16.9C1162.9,244.8,1178.4,239.1,1189.6,227.9z" />
            <path d="M1370.6,95.1c12.4-9.8,27.2-14.8,44.3-14.8c6.3,0,10.9,0.3,13.6,0.8v45.1c-5.1-0.8-10.1-1.1-15.2-1.1
				c-20.2,0-35.7,5.4-46.6,16.3c-6.8,6.8-11.6,15.2-14.4,25.2c-2.8,10-4.2,21.3-4.2,33.9v74.3h-48.1V85.3h44.3v50
				C1349.5,118.3,1358.2,105,1370.6,95.1z" />
            <path d="M1499.6,267.5c-18.4-8.6-32.1-21.2-40.9-37.9l33-18.6c14.1,23.5,40.3,35.2,78.4,35.2c15.2,0,26.6-2.1,34.3-6.4
				c7.7-4.3,11.6-10.4,11.6-18.2c0-6.6-2.2-11.4-6.6-14.4c-4.4-3-12.2-5.3-23.3-6.8l-56.1-6.4c-41.4-5.3-62.1-23.6-62.1-54.9
				c0-18.4,8.2-33,24.4-43.6c16.3-10.6,39-15.9,68-15.9c24.2,0,45,3.7,62.3,11.2c17.3,7.5,29.7,17.9,37.3,31.3l-31.1,19.7
				c-7.3-9.6-16.8-16.8-28.4-21.6c-11.6-4.8-26.1-7.2-43.6-7.2c-29.6,0-44.3,7.8-44.3,23.5c0,6.1,2.3,10.7,7,14
				c4.7,3.3,11.9,5.6,21.8,6.8l58.7,6.8c21.2,2.8,36.6,8.6,46.2,17.4c9.6,8.8,14.4,21.1,14.4,36.7c0,19.2-8,34.4-23.9,45.5
				c-15.9,11.1-39,16.7-69.3,16.7C1540.6,280.4,1518,276.1,1499.6,267.5z" />
            <path d="M1920,192.1h-170.9c1,16.2,7.4,28.9,19.3,38.3c11.9,9.3,27.7,14,47.4,14c14.9,0,27.7-2.8,38.5-8.3
				c10.7-5.5,19-13.8,24.8-24.6l37.9,17.4c-9.9,16.7-23.4,29.4-40.7,38.3c-17.3,8.8-38,13.3-61.9,13.3c-34.9,0-62.3-8.7-82.2-26.1
				c-20-17.4-29.9-41.5-29.9-72.4c0-21.2,4.5-39.5,13.5-54.9c9-15.4,21.7-27.2,38.3-35.2c16.5-8.1,35.9-12.1,58.2-12.1
				c34.1,0,60.6,8.3,79.6,24.8c18.9,16.5,28.4,40,28.4,70.3V192.1z M1871.5,159.5c0-15.4-5.5-27.4-16.5-36
				c-11-8.6-25.5-12.9-43.4-12.9c-18.4,0-33.2,4.5-44.3,13.6c-11.1,9.1-17.2,21.5-18.2,37.1h122.4V159.5z" />
            <path d="M411.4,85.3h48.1v189.4h-48.1V85.3z" />
            <rect x="411.5" width="48.1" height="48.1" />
          </g>
          <g>
            <polygon points="0,137.5 0,275 137.3,275 274.5,275 274.5,137.5 137.3,137.5 			" />
            <path d="M274.5,0H0c0,75.9,61.5,137.5,137.3,137.5C213.1,137.5,274.5,75.9,274.5,0z" />
          </g>
        </g>
      </g>
    </svg>
  );

  return <div className="logo-container">{logo}</div>;
}

Logo.propTypes = {
  // isIcon: PropTypes.bool,
  className: PropTypes.string,
  titleAccess: PropTypes.string,
  viewBox: PropTypes.string,
};

Logo.defaultProps = {
  color: 'inherit',
  viewBox: '0 0 24 24',
};

export default Logo;
