import React from 'react';
import LoadingSpinner from 'ui/common/rebranding/components/LoadingSpinner';

export default ({ className, type }) => {
  return (
    <div className="py-4 w-100 h-100 d-flex justify-content-center align-items-center">
      <LoadingSpinner className={className} type={type} />
    </div>
  );
};
