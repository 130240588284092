import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { omit } from 'lodash';

import { selectStripeCheckoutSdkLibLoadState } from 'store/libs/selectors';
import brand from 'resources/brand';

import Button from 'ui/common/button';

const unsupportedButtonProps = ['onClick', 'to', 'href'];
const omitFromButtonProps = [...unsupportedButtonProps, 'sdkLoadState', 'dispatch'];

class StripeCheckoutButton extends Component {
  static propTypes = {
    stripeOptions: PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      description: PropTypes.string,
      locale: PropTypes.string,
      panelLabel: PropTypes.string,
      amountInCents: PropTypes.number,
      currency: PropTypes.oneOf(['USD']),
      email: PropTypes.string,
      askBillingAddress: PropTypes.bool,
      askShippingAddress: PropTypes.bool,
      validateZipCode: PropTypes.bool,
      allowRememberMe: PropTypes.bool,
      onTokenReceived: PropTypes.func,
    }),
    ...omit(Button.propTypes, unsupportedButtonProps),
  };

  static defaultProps = {
    stripeOptions: {
      key: process.env.REACT_APP_STRIPE_KEY,
      name: brand.name,
      imageUrl: `https://indorse-staging-bucket.s3.amazonaws.com/static-images/logo-orange-blue.jpg`,
      locale: 'auto',
      currency: 'USD',
      askBillingAddress: false,
      askShippingAddress: false,
      validateZipCode: false,
    },
  };

  constructor(props) {
    super(props);

    autoBind(this);

    this.state = { isReady: false, isOpening: false, isOpen: false };
    this.handler = null;
  }

  componentDidMount() {
    this.initializeHandler(this.props);
  }

  componentWillUnmount() {
    if (this.handler && typeof this.handler.close === 'function') {
      this.handler.close();
    }
  }

  mapComponentPropsToStripeOptions(props) {
    const { stripeOptions } = props;
    if (!stripeOptions) return null;

    const {
      imageUrl,
      amountInCents,
      askBillingAddress,
      askShippingAddress,
      validateZipCode,
      onTokenReceived,
      ...passThrough
    } = { ...StripeCheckoutButton.defaultProps.stripeOptions, ...stripeOptions };

    return {
      image: imageUrl,
      amount: amountInCents,
      billingAddress: askBillingAddress,
      shippingAddress: askShippingAddress,
      zipCode: validateZipCode,
      token: onTokenReceived,
      opened: this.handleOnOpen,
      closed: this.handleOnClose,
      ...passThrough,
    };
  }

  initializeHandler(props) {
    const options = this.mapComponentPropsToStripeOptions(props);

    // eslint-disable-next-line no-undef
    this.handler = StripeCheckout.configure(options);
    this.setState({ isReady: true });
  }

  handleOnClick(e) {
    e.preventDefault();

    if (this.state.isOpening || this.state.isOpen || !this.state.isReady || !this.handler) return;

    this.setState({ isOpening: true });

    const options = this.mapComponentPropsToStripeOptions(this.props);
    this.handler.open(options);

    if (this.props.onClick) this.props.onClick(e);
  }

  handleOnOpen() {
    this.setState({ isOpening: false, isOpen: true });
  }

  handleOnClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { stripeOptions, ...buttonProps } = this.props;

    const finalButtonProps = omit(buttonProps, omitFromButtonProps);

    return (
      <Button
        {...finalButtonProps}
        onClick={this.handleOnClick}
        loading={buttonProps.loading || this.state.isOpening}
        disabled={buttonProps.disabled || !this.state.isReady || this.state.isOpen}
      />
    );
  }
}

function mapStateToProps(state) {
  const sdkLoadState = selectStripeCheckoutSdkLibLoadState(state);

  return {
    sdkLoadState,
  };
}

export default connect(mapStateToProps)(StripeCheckoutButton);
