import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styles from './index.module.scss';
import classnames from 'classnames';
import { show as showModal } from 'redux-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import autoBind from 'react-autobind';
import { logEvent } from 'services/amplitude-helper';
import Button from 'ui/common/button';
import { MODAL_NAME as EMS_CONTACT_MODAL } from 'ui/graphql/EmsContactForm';
import gql from 'graphql-tag';
import { messageTypes } from 'store/features/common/flash/builder';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import { Modal, ModalBody } from 'ui/common/modal';
// import SubmitButton from 'ui/common/form/submit-button';
import validator from 'ui/common/form/validator';
import fields from '../hero/model';
import * as messages from '../hero/messages';
import WaitlistModal, {
  MODAL_NAME as EMS_WAITLIST_MODAL,
} from 'ui/pages/ems/hero/waitlist-modal.js';

import { reduxForm } from 'redux-form';
// import FormError from 'ui/common/form/form-error';
// import TextField from 'ui/common/form/fields/text-field';
import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';

const validate = validator(fields);
const FORM_NAME = 'create-ems-form-beta-three';
const CLIENT_LOGO_IMAGES = [
  {
    imgUrl:
      'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/home-credit.png',
  },

  {
    imgUrl: 'https://d1czgnl1rmm8mf.cloudfront.net/static-images/existing-customers/grab.svg',
  },
  {
    imgUrl:
      'https://indorse-staging-bucket.s3.amazonaws.com/static-images/existing-customers/capita-sg.png',
  },
  {
    imgUrl: 'https://indorse-staging-bucket.s3.amazonaws.com/Adobe-Logo.svg',
  },
];
export class Cta extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isCompanyCreated: false,
    };
  }

  componentDidMount() {
    const { currentUser } = this.props;
    window.fcWidget &&
      window.fcWidget.user.setProperties({
        firstName: currentUser && currentUser.name,
        email: currentUser && currentUser.email,
      });
  }

  async onSubmit(values) {
    const { email } = values;
    const { client, addMessage, intl: { formatMessage } } = this.props;

    logEvent('create_ems_company_cta_clicked', {
      label: formatMessage(messages.buttons.submitButton),
    });

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation createCompany($form: CreateCompanyForm) {
            createCompany(form: $form) {
              id
            }
          }
        `,
        variables: {
          form: {
            adminEmail: email,
            features: {
              ems: true,
            },
          },
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { data, errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
        logEvent('start_free_trial_failed', {
          error: errorsToSingleMessage({ errors }),
        });
      } else {
        const { createCompany: { id } } = data;
        if (id) {
          this.setState({
            isCompanyCreated: true,
          });
        }
        logEvent('start_free_trial_success', {
          label: formatMessage(messages.buttons.submitButton),
        });
      }
    } catch (error) {
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
      logEvent('start_free_trial_failed', {
        error: error.message,
        label: formatMessage(messages.buttons.submitButton),
      });
    }
  }

  async addToWaitlist(values) {
    const { email } = values;
    const { client, addMessage, intl: { formatMessage } } = this.props;

    logEvent('create_ems_company_cta_waitlist_clicked', {
      label: formatMessage(messages.buttons.submitButton),
      source: window && window.location.pathname,
      email,
      userId: email,
    });

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'createcompany' }
        );
      }

      const result = await client.mutate({
        mutation: gql`
          mutation ems_waitlist($email: String!) {
            ems_waitlist(email: $email)
          }
        `,
        variables: {
          email,
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });
      const { errors } = result;

      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
      } else {
        if (window.amplitude) {
          const ampInstance = window.amplitude.getInstance();
          if (ampInstance) ampInstance.setUserId(email);
        }
        this.props.showModal(EMS_WAITLIST_MODAL);
      }
    } catch (error) {
      console.log(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
    }
  }

  renderCompanyCreatedThanksModal() {
    const { intl: { formatMessage } } = this.props;
    const { isCompanyCreated } = this.state;

    return (
      <Modal isOpen={isCompanyCreated} className="modal-lg">
        <ModalBody className="col-md-10 mt-5 offset-sm-1">
          <div className="pb-3 text-center">
            <h1
              className="text-center mx-3 indorse-blue mb-5"
              style={{ fontSize: '70px', fontWeight: '900' }}
            >
              {formatMessage(messages.content.pageTitle)}
            </h1>
            <div className="mt-4 text-center">
              <h3 style={{ fontSize: '20px', lineHeight: '30px' }}>
                {formatMessage(messages.content.pageSubTitle)}
              </h3>
            </div>
          </div>
          <hr className={classnames(styles.divider)} />
          <div className="my-5 text-center">
            <h3 style={{ fontSize: '20px' }}>{formatMessage(messages.content.pageCompanies)}</h3>
            <div className="d-flex justify-content-between align-items-center py-4 col-md-10 offset-sm-1">
              <img src={CLIENT_LOGO_IMAGES[0].imgUrl} alt="home credit" style={{ width: '20%' }} />
              <img src={CLIENT_LOGO_IMAGES[1].imgUrl} alt="grab" style={{ width: '20%' }} />
              <img
                className="mt-2"
                src={CLIENT_LOGO_IMAGES[2].imgUrl}
                alt="capita"
                style={{ width: '20%', height: '60%' }}
              />
              <img
                className="mt-2"
                src={CLIENT_LOGO_IMAGES[3].imgUrl}
                alt="singsaver"
                style={{ width: '15%', height: '60%' }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  showContactForm = () => {
    this.props.showModal(EMS_CONTACT_MODAL, { showForm: true });
  };

  renderDesktop() {
    // const { handleSubmit, intl: { formatMessage }, form: formName, invalid } = this.props;
    return (
      <div>
        <h2 className={classnames('text-center', styles.title)}>Leadership is about people</h2>

        <h3 className={classnames('text-center', styles.subtitle)}>
          Pulse Surveys for remote teams
        </h3>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <img
              src={`https://indorse-staging-bucket.s3.amazonaws.com/TechTeams.png`}
              alt="techteams"
              width="546"
              className="m-auto"
            />
          </div>
          <div className="col-12 col-md-6 mt-5 pt-2">
            <div className={classnames(styles.infobox)}>
              <p className={classnames(styles.info, 'pb-4')}>
                Connect the dots between your developer’s feedback and performance. Metamorph helps
                identify your blind spots and provides practical tips to improve productivity.
              </p>
              <Button
                isReady={true}
                className={classnames(styles.selectBtn, 'mt-3')}
                onClick={() => {
                  window.fcWidget &&
                    window.fcWidget.open({
                      replyText:
                        'Hey there, I would like to request a Demo and discover more about Metamorph!',
                    });
                  logEvent('see_a_demo');
                }}
              >
                See a demo
              </Button>
            </div>
          </div>
        </div>
        {/* <div className={classnames(styles.signupContainer, 'row justify-content-center')}>
          <div className="col-10 col-md-3">
            <h2 className={classnames(styles.signUp, 'mt-4')}>
              Start your 14-days
              <br />
              free trial
            </h2>
          </div>
          <div className="d-flex col-10 col-md-5 pt-2">
            <form onSubmit={handleSubmit(this.addToWaitlist)} className="w-100">
              <FormError formName={formName} />
              <div className="d-flex flex-wrap ">
                <div className="flex-grow-1">
                  <Field
                    name={fieldNames.adminEmail}
                    component={TextField}
                    // hint={formatMessage(messages.content.email)}
                    label={formatMessage(messages.label[fieldNames.adminEmail])}
                    style={{ maxWidth: '300px' }}
                    data-hj-whitelist
                  />
                </div>

                <SubmitButton
                  disabled={invalid}
                  formName={FORM_NAME}
                  className={classnames(styles.submitBtn, 'ml-4')}
                >
                  Start Free Trial
                </SubmitButton>
              </div>
            </form>
          </div>
        </div> */}
        <WaitlistModal />
      </div>
    );
  }

  renderMobile() {
    // const { handleSubmit, intl: { formatMessage }, form: formName } = this.props;
    return (
      <div>
        <h2 className={classnames('text-center', styles.title)}>Leadership is about people</h2>

        <h3 className={classnames('text-center', styles.subtitle)}>
          Pulse Surveys for remote teams
        </h3>
        <div className="row justify-content-center mt-4">
          <div className="col-11 col-md-6">
            <img
              src={`https://indorse-staging-bucket.s3.amazonaws.com/TechTeams.png`}
              alt="techteams"
              width="300"
              className="m-auto"
            />
          </div>
          <div className="col-11 col-md-6">
            <div className={classnames(styles.infobox)}>
              <div className={classnames(styles.info, 'pb-4')}>
                Connect the dots between your developer’s feedback and performance. Metamorph helps
                identify your blind spots and provides practical tips to improve productivity.
                <div>
                  <Button
                    isReady={true}
                    className={classnames(styles.selectBtn, 'mt-3')}
                    onClick={() => {
                      window.fcWidget &&
                        window.fcWidget.open({
                          replyText:
                            'Hey there, I would like to request a Demo and discover more about Metamorph!',
                        });
                      logEvent('see_a_demo');
                    }}
                  >
                    See a demo
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={classnames(styles.signupContainer, 'row justify-content-center')}>
          <div className="col-10 col-md-4">
            <h2 className={classnames(styles.signUp, 'mt-4')}>Sign up for free</h2>
          </div>
          <div>
            <form onSubmit={handleSubmit(this.addToWaitlist)} className="w-100">
              <FormError formName={formName} />
              <div className="flex-grow-1">
                <Field
                  name={fieldNames.adminEmail}
                  component={TextField}
                  // hint={formatMessage(messages.content.email)}
                  label={formatMessage(messages.label[fieldNames.adminEmail])}
                  data-hj-whitelist
                />
              </div>
              <div className="row justify-content-center">
                <SubmitButton
                  formName={FORM_NAME}
                  className={classnames(styles.submitBtn, 'mx-auto my-3')}
                  isReady
                >
                  Start Free Trial
                </SubmitButton>
              </div>
            </form>
          </div>
        </div> */}
        <WaitlistModal />
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.blueBackground)}>
        {this.state.isCompanyCreated && this.renderCompanyCreatedThanksModal()}
        <div className={classnames('page-max-width')}>
          <div className={classnames('container-fluid my-5 py-2')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addMessage: bindActionCreators(addMessage, dispatch),
    showModal: bindActionCreators(showModal, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: FORM_NAME,
    validate,
  })(injectIntl(withApollo(Cta)))
);
