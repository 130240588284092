import { call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';
import { normalize } from 'normalizr';

import callApi from 'store/api/saga';
import * as entityActions from 'store/entities/actions';
import * as schemas from 'store/common/schemas';
import * as types from './action-types';
import * as actions from './actions';
import * as api from 'api/config/skills';
import messages from 'ui/admin/skills/table/messages';

import { push } from 'react-router-redux';
import routeTemplates from 'ui/common/routes/templates';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes, buildMessage } from 'store/features/common/flash/builder';
import { confirmSaga as confirm } from 'store/features/common/confirmation-dialog/saga';
import { selectSkillById } from 'store/features/skills/selectors';

function* fetchSkills({ payload }) {
  yield put(actions.fetchSkills.start(payload));

  try {
    const response = yield call(callApi, api.fetchSkills(payload));

    if (response) {
      const schema = { skills: [schemas.skill] };
      const { entities, result } = normalize(response, schema);

      yield put(entityActions.mergeEntities(entities));
      yield put(
        actions.fetchSkills.success({
          input: payload,
          result: {
            skills: result.skills,
            totalSkills: result.skills.length,
            matchingSkills: result.matchingSkills ? result.matchingSkills : result.skills.length,
          },
        })
      );
    }
  } catch (error) {
    yield put(actions.fetchSkills.failure({ input: payload, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchSkills() {
  yield takeEvery(types.FETCH_SKILLS.REQUEST, fetchSkills);
}

function* createSkill({ payload: values, meta }) {
  const { form } = meta;

  yield put(actions.createSkill.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.createSkill(values));
    yield put(actions.createSkill.success(response));

    yield put(
      push(routeTemplates.admin.skills.root, {
        flash: buildMessage({
          id: 'create-skill',
          kind: messageTypes.success,
          content: response.message,
        }),
      })
    );

    if (form) yield put(stopSubmit(form));
  } catch (error) {
    yield put(actions.createSkill.failure(error));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchCreateSkill() {
  yield takeEvery(types.CREATE_SKILL.REQUEST, createSkill);
}

function* deleteSkill({ payload: id, meta }) {
  const skill = yield select(selectSkillById, { id });

  const confirmationMessage = {
    ...messages.confirmDelete,
    values: { ...skill },
  };

  const confirmed = yield call(confirm, { message: confirmationMessage });
  if (!confirmed) {
    return;
  }

  yield put(actions.deleteSkill.start(id));

  try {
    const response = yield call(callApi, api.deleteSkill(id));
    yield put(actions.deleteSkill.success({ id }));
    // yield put(push(routeTemplates.admin.skills.root));
    yield put(addMessage({ kind: messageTypes.success, content: response.message }));
  } catch (error) {
    yield put(actions.deleteSkill.failure(error));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchDeleteSkill() {
  yield takeEvery(types.DELETE_SKILL.REQUEST, deleteSkill);
}

export default function* skills() {
  yield fork(watchCreateSkill);
  yield fork(watchDeleteSkill);
  yield fork(watchFetchSkills);
}
