import { defineMessages } from 'react-intl';

export const content = defineMessages({
  title: {
    id: 'page.company.intro.title',
    defaultMessage: '20 People. 6 Countries.<br/>One mission:<br/>Make skills matter.',
  },
  copy: {
    id: 'page.company.intro.copy',
    defaultMessage:
      'We believe that it doesn’t matter where you’re from, how you look, if you’re rich or poor, male or female. All that matters is your skills. Imagine a world in which everyone is able to do what they are best at, and every job is done by the person best qualified for it. This is what we’re striving to achieve with Indorse.<br/><br/>Indorse is based in Singapore, and that’s no coincidence: This city is home to one of the most diverse workforces in the world, and it’s a hotspot of blockchain technology. The perfect environment to create an innovative, equality-enforcing approach to measure professional qualification.<br/><br/>Indorse was founded in 2017 by Gaurang Torvekar, David Moskowitz, Avadhoot Kulkarni, and Dipesh Sukhani while sharing offices with the Ethereum foundation. The platform launched publicly in October 2017 and has since grown rapidly in both features and user base.',
  },
  imgCaption1: {
    id: 'page.company.intro.imgCaption1',
    defaultMessage: 'Photo caption in two lines',
  },
  imgCaption2: {
    id: 'page.company.intro.imgCaption2',
    defaultMessage: 'Photo caption in two lines',
  },
});
