export function isFile(value) {
  return typeof value === 'object' && value instanceof File;
}

export function convertToDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );
    reader.addEventListener(
      'error',
      event => {
        reject(event.error);
      },
      false
    );
    // This log is used in sentry to check type of file variable.
    // This is done to debug a nasty non reproducible issue that is appearing on sentry
    // So do NOT remove this log until the issue is solved
    console.log(
      `Attempting to read file variable: Type - ${typeof file}, IsFile - ${isFile(file)}`
    );
    reader.readAsDataURL(file);
  });
}

export function convertToDataText(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );
    reader.addEventListener(
      'error',
      event => {
        reject(event.error);
      },
      false
    );
    // This log is used in sentry to check type of file variable.
    // This is done to debug a nasty non reproducible issue that is appearing on sentry
    // So do NOT remove this log until the issue is solved
    console.log(
      `Attempting to read file variable: Type - ${typeof file}, IsFile - ${isFile(file)}`
    );
    reader.readAsText(file);
  });
}
