/*
 * HackathonClaimSubmission Messages
 *
 * This contains all the text for the HackathonClaimSubmission component.
 */
import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export default defineMessages({
  [fieldNames.url]: {
    id: 'graphql.HackathonClaimSubmission.url',
    defaultMessage: 'Paste your project’s URL here',
  },
  title: {
    id: 'graphql.HackathonClaimSubmission.title',
    defaultMessage: 'Submission',
  },
  submitCode: {
    id: 'graphql.HackathonClaimSubmission.submitCode',
    defaultMessage: 'Submit your Project',
  },
  submitButton: {
    id: 'graphql.HackathonClaimSubmission.submitButton',
    defaultMessage: 'Submit',
  },
  addParticipant: {
    id: 'graphql.HackathonClaimSubmission.addParticipant',
    defaultMessage: 'Add Participant',
  },
  pageTitle: {
    id: 'graphql.HackathonClaimSubmission.pageTitle',
    defaultMessage: 'Showcase your project and skills!',
  },
  subPageTitle: {
    id: 'graphql.HackathonClaimSubmission.subPageTitle',
    defaultMessage: 'Submit your project and get valuable feedback from experts.',
  },
  successTitle: {
    id: 'graphql.HackathonClaimSubmission.successTitle',
    defaultMessage: 'Congrats!',
  },
  successSubtitle: {
    id: 'graphql.HackathonClaimSubmission.successSubtitle',
    defaultMessage:
      'You have successfully submitted your project. It’s time for the panelists to review it!',
  },
  closeBtn: {
    id: 'graphql.HackathonClaimSubmission.closeBtn',
    defaultMessage: 'Close',
  },
});
