import React, { Component } from 'react';
import { connect } from 'react-redux';
import routeTemplates from 'ui/common/routes/templates';

import AddToSlackButton from 'ui/graphql/AddToSlackButton';
import Loading from 'ui/common/loading';

import * as storage from 'store/features/auth/storage';
import { slackMethod } from 'resources/slack';
import {
  selectAuthenticatingWithSlack,
  selectError,
} from 'store/graphql/SlackAuthCallback/selectors';
import { selectOk } from 'store/graphql/SlackAuthButton/selectors';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import SlackAuthCallbackReducer from 'store/graphql/SlackAuthCallback/reducer';
import SlackAuthButtonReducer from 'store/graphql/SlackAuthButton/reducer';
import Button from 'ui/common/button';

export class SlackAddCallback extends Component {
  render() {
    const { isAuthenticatingWithSlack, error, isOk } = this.props;
    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );

    if (error)
      return (
        <div className="text-center">
          <h1>{error}</h1>
          <Button to={routeTemplates.emsLandingPage} isReady>
            Go Back
          </Button>
        </div>
      );

    if (isAuthenticatingWithSlack || isOk) return <Loading />;

    return <div>{authenticationWithSlack && <AddToSlackButton />}</div>;
  }
}

function mapStateToProps(state) {
  const isAuthenticatingWithSlack = selectAuthenticatingWithSlack(state);
  const error = selectError(state);
  const isOk = selectOk(state);

  return {
    isAuthenticatingWithSlack,
    error,
    isOk,
  };
}

const withConnect = connect(mapStateToProps, null);
const withReducer = injectReducer({
  key: 'SlackAuthCallback',
  reducer: SlackAuthCallbackReducer,
});

const withSlackAuthButtonReducer = injectReducer({
  key: 'SlackAuthButton',
  reducer: SlackAuthButtonReducer,
});

export default compose(withSlackAuthButtonReducer, withReducer, withConnect)(SlackAddCallback);
