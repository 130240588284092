import React from 'react';
import PropTypes from 'prop-types';
import { fieldPropTypes } from 'redux-form';

import SimpleSelectField from './simple-select-field';
// import Select from 'ui/common/rebranding/components/Select/Select';
const { input: inputPropTypes, ...restFieldPropTypes } = fieldPropTypes;
const { onDragStart, ...restInputPropTypes } = inputPropTypes;

function generateAvailableYears(min, max, value) {
  const result = [];
  for (let year = max; year >= min; year--) {
    result.push({
      value: year,
      text: year,
    });
  }
  if (value && (value < min || value > max)) {
    result.unshift({ value, text: value || '' });
  }
  return result;
}

export default class YearField extends React.Component {
  constructor(props) {
    super(props);

    const { min, max, options, input: { value } } = props;

    this.state = {
      options: !options && min && max ? generateAvailableYears(min, max, value) : options,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { min, max } = this.props;
    if (!nextProps.children && nextProps.min && nextProps.max && nextProps.input) {
      if (nextProps.min !== min || nextProps.max !== max) {
        this.setState({
          options: generateAvailableYears(nextProps.min, nextProps.max, nextProps.input.value),
        });
      }
    }
  }

  render() {
    const { min, max, ...rest } = this.props;
    return <SimpleSelectField options={this.state.options} {...rest} />;
  }
}

YearField.propTypes = {
  ...restFieldPropTypes,
  input: PropTypes.shape(restInputPropTypes),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};
