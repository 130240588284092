import { claim as schema } from 'store/common/schemas';
import { createEntitiesReducer, createEntitiesSelector } from './helpers';
import { VOTE_SUBMISSION_RECEIVED } from 'store/features/claims/action-types';
import { APPROVE_CLAIM, DISAPPROVE_CLAIM } from 'store/features/admin/claims/action-types';

import * as actionTypes from 'store/graphql/AdminModerateClaimModal/action-types';

export const reducer = createEntitiesReducer(schema, (state, action) => {
  switch (action.type) {
    case APPROVE_CLAIM.SUCCESS:
      const { claimId: approvedClaimId } = action.payload;

      if (approvedClaimId) {
        return state.merge(
          {
            [approvedClaimId]: {
              approved: new Date().toISOString(),
            },
          },
          { deep: true }
        );
      }

      return state;
    case DISAPPROVE_CLAIM.SUCCESS:
      const { claimId: disapprovedClaimId } = action.payload;

      if (disapprovedClaimId) {
        return state.merge(
          {
            [disapprovedClaimId]: {
              disapproved: new Date().toISOString(),
            },
          },
          { deep: true }
        );
      }

      return state;

    case actionTypes.MODERATE_CLAIM.SUCCESS:
      const { claimId: moderatedClaimId, moderation } = action.payload;
      if (moderatedClaimId) {
        return state.merge(
          {
            [moderatedClaimId]: {
              moderation: moderation,
              moderated: new Date().toISOString(),
            },
          },
          {
            deep: true,
          }
        );
      }

      return state;
    case VOTE_SUBMISSION_RECEIVED:
      const {
        claimId: voteSubmittedClaimId,
        skill: voteSubmittedSkill,
        noOfTentativeVotes,
        isMaxVoteReached,
      } = action.payload; // Note that action.payload.tiers are included.

      if (voteSubmittedClaimId) {
        let voteSubmittedClaim = state[voteSubmittedClaimId];

        if (voteSubmittedClaim) {
          voteSubmittedClaim = {
            ...voteSubmittedClaim,
          };

          const voteSubmittedClaimSkillIndex = (voteSubmittedClaim.skills || []).findIndex(
            cs => cs.skill && cs.skill.id === voteSubmittedSkill._id
          );

          if (voteSubmittedClaimSkillIndex >= 0) {
            let voteSubmittedClaimSkill = voteSubmittedClaim.skills[voteSubmittedClaimSkillIndex];
            if (
              voteSubmittedClaimSkill.noOfTentativeVotes !== noOfTentativeVotes ||
              voteSubmittedClaimSkill.isMaxVoteReached !== isMaxVoteReached
            ) {
              voteSubmittedClaimSkill = {
                ...voteSubmittedClaimSkill,
                noOfTentativeVotes,
                isMaxVoteReached,
              };

              voteSubmittedClaim = {
                ...voteSubmittedClaim,
                skills: voteSubmittedClaim.skills.map(
                  (cs, csi) => (csi === voteSubmittedClaimSkillIndex ? voteSubmittedClaimSkill : cs)
                ),
              };
            }
          }

          return state.merge(
            {
              [voteSubmittedClaimId]: voteSubmittedClaim,
            },
            { deep: true }
          );
        }
      }
      return state;
    default:
      return state;
  }
});

export const selector = createEntitiesSelector(schema);
