import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import { selectAllEntities, selectVoteEntities } from 'store/entities/selectors';
import * as authSelectors from 'store/features/auth/selectors';
import * as helpers from './helpers';
import * as claimHelpers from 'store/features/claims/helpers';

const selectVotesFeatureState = state => state.features.votes;

export const selectCurrentUserVotes = createSelector(
  selectAllEntities,
  selectVoteEntities,
  authSelectors.selectCurrentUserId,
  (entities, votesById, currentUserId) => {
    let result = denormalize(Object.keys(votesById), [schemas.vote], entities);

    result = result
      .filter(v => {
        return (
          v.voter &&
          v.voter.id === currentUserId &&
          v.claim &&
          v.claim.owner &&
          v.claim.owner.id !== currentUserId &&
          (!v.claim.isScClaim || !!v.claim.scClaimId)
        );
      })
      .map(v => ({
        ...v,
        status: helpers.calculateVoteStatus(v),
        claim: {
          ...v.claim,
          skill:
            v.claim && v.claim.skills && Array.isArray(v.claim.skills) && v.claim.skills.length > 0
              ? v.claim.skills[0].skill
              : {},
          level: claimHelpers.calculateClaimLevelInfo(v.claim),
          status: claimHelpers.calculateClaimStatus(v.claim),
        },
      }))
      .sort((v1, v2) => {
        const [v1p1, v1p2] = helpers.calculateCurrentUserVoteSortOrder(v1);
        const [v2p1, v2p2] = helpers.calculateCurrentUserVoteSortOrder(v2);
        if (v1p1 > v2p1) return 1;
        else if (v1p1 < v2p1) return -1;
        else {
          if (v1p2 > v2p2) return 1;
          else if (v1p2 < v2p2) return -1;
          else return 0;
        }
      });

    return result;
  }
);

export const selectCurrentUserVotesFetching = createSelector(
  selectVotesFeatureState,
  votesFeatureState => votesFeatureState.currentUser.fetching
);

export const selectCurrentUserVotesFetchedAtleastOnce = createSelector(
  selectVotesFeatureState,
  votesFeatureState =>
    !!votesFeatureState.currentUser && votesFeatureState.currentUser.lastFetchedAt
);

export const selectVoteById = createSelector(
  selectAllEntities,
  (state, props) => props.id,
  (entities, id) => {
    const vote = denormalize(id, schemas.vote, entities);
    if (vote) return { ...vote, status: helpers.calculateVoteStatus(vote) };
    else return null;
  }
);

export const selectIsRegisteringToVote = createSelector(
  selectVotesFeatureState,
  (state, props) => props.id,
  (votesFeatureState, voteId) =>
    votesFeatureState.byId[voteId] && votesFeatureState.byId[voteId].registering
);

export const selectIsEndorsing = createSelector(
  selectVotesFeatureState,
  (state, props) => props.id,
  (votesFeatureState, voteId) =>
    votesFeatureState.byId[voteId] && votesFeatureState.byId[voteId].endorsing
);

export const selectIsFlagging = createSelector(
  selectVotesFeatureState,
  (state, props) => props.id,
  (votesFeatureState, voteId) =>
    votesFeatureState.byId[voteId] && votesFeatureState.byId[voteId].flagging
);

export const selectIsVoting = createSelector(
  selectVotesFeatureState,
  (state, props) => props.id,
  (votesFeatureState, voteId) =>
    votesFeatureState.byId[voteId] &&
    (votesFeatureState.byId[voteId].endorsing && votesFeatureState.byId[voteId].flagging)
);
