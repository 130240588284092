/*
  AdminVoteValidatorCommentButton
*/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Immutable from 'seamless-immutable';

import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { compose, bindActionCreators } from 'redux';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import * as messages from './messages';

import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import { UncontrolledTooltip } from 'reactstrap';
import ConfirmationDialog from './confirmation-dialog';

import * as actionTypes from 'store/graphql/AdminVoteValidatorCommentButton/action-types';
import {
  selectIsUpdatingButtonByVoteId,
  selectValidatorCommentByVoteId,
} from 'store/graphql/AdminVoteValidatorCommentButton/selectors';
import AdminVoteValidatorCommentButtonReducer from 'store/graphql/AdminVoteValidatorCommentButton/reducer';
import injectReducer from 'utils/injectReducer';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import { normalize } from 'normalizr';

import styles from './index.module.scss';
import classnames from 'classnames';

export const voteActionTypes = {
  DOWN_VOTE: 'downvote',
  UP_VOTE: 'upvote',
  HIDE: 'hide',
};

class AdminVoteValidatorCommentButton extends Component {
  static propTypes = {
    voteType: PropTypes.oneOf([
      voteActionTypes.DOWN_VOTE,
      voteActionTypes.UP_VOTE,
      voteActionTypes.HIDE,
    ]).isRequired,
    voteId: PropTypes.string.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    dialogMessage: PropTypes.string, //only Required if (voteType === voteActionTypes.HIDE)
    mergeWithEntites: PropTypes.bool, // a boolean that trigger an action to merge with claim entities
    claim: function(props, propName, componentName) {
      if (
        props['mergeWithEntites'] === true &&
        (props[propName] === undefined || typeof props[propName] !== 'object')
      ) {
        return new Error(
          `Props claim typeof object is required!, instead typeof ${typeof props[
            propName
          ]} was supplied in ${componentName}`
        );
      }
    }, // checked if mergeWithEntities is true, claim isRequired
  };

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      [props.voteId]: false,
      isOpen: false,
    };
  }

  async updateValidatorComment() {
    const {
      voteValidatorId,
      client,
      voteId,
      voteType,
      updateValidatorCommentStart,
      updateValidatorCommentSuccess,
      updateValidatorCommentError,
      addMessage,
      mergeWithEntites,
    } = this.props;

    updateValidatorCommentStart(voteId);

    try {
      const resultFromGraphQl = await client.mutate({
        mutation: gql`
          mutation updateValidatorComment($voteId: String, $actionOnComment: actionType) {
            updateValidatorComment(voteId: $voteId, actionOnComment: $actionOnComment) {
              voteId
              noOfDownvote
              noOfUpvote
              isCommentHidden
              isAdminVoteOnComment
            }
          }
        `,
        variables: {
          voteId,
          actionOnComment: voteType,
        },
      });

      const { data, errors } = resultFromGraphQl;

      if (resultHasErrors({ errors })) {
        updateValidatorCommentError({ error: errorsToSingleMessage({ errors }), voteId });
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
      } else {
        const { updateValidatorComment } = data;

        updateValidatorCommentSuccess({
          result: updateValidatorComment,
          voteId,
          voteValidatorId,
        });

        addMessage({
          kind: messageTypes.success,
          content: messages.successMessages[voteType],
        });

        if (voteType === voteActionTypes.HIDE) {
          this.setState({
            isOpen: !this.state.isOpen,
          });
        }

        if (mergeWithEntites) {
          this.mergeClaimEntities(updateValidatorComment);
        }
      }
    } catch (error) {
      updateValidatorCommentError({ error: error.message, voteId });
    }
  }

  mergeClaimEntities(updateValidatorComment) {
    const { claim, mergeEntities } = this.props;

    const newExplanation =
      claim &&
      claim.feedback &&
      claim.feedback.explanation.map(exp => {
        if (exp.voteId === updateValidatorComment.voteId) {
          const newExp = Immutable.merge(exp, updateValidatorComment);
          return newExp;
        } else {
          return exp;
        }
      });

    const newClaim = Immutable.replace(
      claim,
      {
        id: claim.id,
        feedback: {
          explanation: newExplanation,
        },
      },
      { deep: true }
    );

    const schema = {
      claim: schemas.claim,
    };

    const targetObj = {
      claim: newClaim,
    };

    const { entities } = normalize(targetObj, schema);

    mergeEntities(entities);
  }

  toggleConfirmationModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {
      voteType,
      finalValue,
      disabled,
      updatingButtonByVoteId,
      dialogMessage,
      voteValidatorId,
      intl: { formatMessage },
    } = this.props;

    return (
      <div>
        <button
          id={voteValidatorId}
          onClick={
            voteType === voteActionTypes.HIDE
              ? () => this.toggleConfirmationModal()
              : this.updateValidatorComment
          }
          disabled={disabled || updatingButtonByVoteId}
          className={classnames(styles.voteButton, {
            [styles.pointerEventNone]: disabled || updatingButtonByVoteId,
          })}
        >
          {voteType === voteActionTypes.HIDE ? '' : finalValue}
          <i
            className={classnames({
              'pl-1 ': true,
              'fa fa-arrow-up text-success': voteType === voteActionTypes.UP_VOTE,
              'fa fa-arrow-down text-danger': voteType === voteActionTypes.DOWN_VOTE,
              'fa fa-eye-slash': voteType === voteActionTypes.HIDE,
              'text-muted': disabled || updatingButtonByVoteId,
            })}
          />

          <UncontrolledTooltip
            className={classnames({ [styles.hideTooltip]: disabled || updatingButtonByVoteId })}
            autohide
            placement="top"
            target={voteValidatorId}
          >
            {formatMessage(messages.tooltip[voteType])}
          </UncontrolledTooltip>
        </button>

        <ConfirmationDialog
          isOpen={this.state.isOpen}
          toggle={() => this.toggleConfirmationModal}
          onSubmit={() => this.updateValidatorComment}
          loading={updatingButtonByVoteId}
          message={dialogMessage}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const voteId = ownProps && ownProps.voteId;
  const voteType = ownProps && ownProps.voteType;
  const voteValidatorId = `${voteType}-${voteId}`;
  // const adminVoteValidatorCommentButtonState = selectAdminVoteValidatorCommentButtonState(state);

  const validatorComment = voteId && selectValidatorCommentByVoteId(state, voteId);
  let finalValue = ownProps.value || 0;
  let disabled = ownProps.disabled;

  if (validatorComment && Object.keys(validatorComment).length) {
    if (voteType === voteActionTypes.UP_VOTE) {
      finalValue = validatorComment.noOfUpvote;
    } else if (voteType === voteActionTypes.DOWN_VOTE) {
      finalValue = validatorComment.noOfDownvote;
    }

    // Huge assumption, once a validator object is stored in reducer,
    // we assume user already click upvote/downvote hence disable the button
    disabled = true;
  }

  return {
    updatingButtonByVoteId: selectIsUpdatingButtonByVoteId(state, voteId),
    finalValue,
    disabled,
    voteValidatorId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateValidatorCommentStart: voteId =>
      dispatch({ type: actionTypes.ADMIN_UPDATE_VALIDATOR_COMMENT.START, payload: { voteId } }),
    updateValidatorCommentSuccess: ({ result, voteId, voteValidatorId }) =>
      dispatch({
        type: actionTypes.ADMIN_UPDATE_VALIDATOR_COMMENT.SUCCESS,
        payload: { result, voteId, voteValidatorId },
      }),
    updateValidatorCommentError: ({ error, voteId }) =>
      dispatch({
        type: actionTypes.ADMIN_UPDATE_VALIDATOR_COMMENT.FAILURE,
        payload: { error, voteId },
      }),
    addMessage: bindActionCreators(addMessage, dispatch),
    mergeEntities: bindActionCreators(entityActions.mergeEntities, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'AdminVoteValidatorCommentButton',
  reducer: AdminVoteValidatorCommentButtonReducer,
});

export default compose(withReducer, withConnect)(
  injectIntl(withApollo(AdminVoteValidatorCommentButton))
);
