import React from 'react';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

export default ({ className, children, isOpen, ...passThrough }) => {
  return (
    <Collapse className={classnames(className)} isOpen={isOpen} {...passThrough}>
      <div className="px-2 py-3">{children}</div>
    </Collapse>
  );
};
