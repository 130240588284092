import Immutable from 'seamless-immutable';
import * as actionTypes from './action-types';

const initialState = Immutable({
  error: false,
  linkingWithGithub: false,
  linkedWithGithub: false,
  token: null,
  code: null,
  state: null,
  userExists: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LINKING_WITH_GITHUB.START: {
      return state.merge({
        linkingWithGithub: true,
      });
    }
    case actionTypes.LINKING_WITH_GITHUB.SUCCESS: {
      return state.merge({
        linkingWithGithub: false,
        linkedWithGithub: true,
      });
    }
    case actionTypes.LINKING_WITH_GITHUB.FAILURE: {
      const { error } = action.payload;

      return state.merge({
        linkingWithGithub: false,
        error,
      });
    }

    default:
      return state;
  }
}
