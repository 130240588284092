import { defineMessages } from 'react-intl';

export const labels = defineMessages({
  title: {
    id: 'companies.getStarted.title',
    defaultMessage: 'Technical assessments as easy as 1-2-3',
  },
  subtitle: {
    id: 'companies.getStarted.subtitle',
    defaultMessage:
      'Below are the steps to evaluate tech skills on Indorse. Simply click Start and follow the steps!',
  },
  stepOne: {
    id: 'companies.getStarted.stepOne',
    defaultMessage: 'Customise your assessment',
  },
  stepOneDetails: {
    id: 'companies.getStarted.stepOneDetails',
    defaultMessage:
      'Your customised assessment will incorporate a range of coding tests. These tests will be automatically selected based on the job position you are hiring for & the coding skills needed. To showcase their real skills, your candidates will have to complete one of these tests!',
  },
  stepTwo: {
    id: 'companies.getStarted.stepTwo',
    defaultMessage: 'Share it with candidates',
  },
  stepTwoDetails: {
    id: 'companies.getStarted.stepTwoDetails',
    defaultMessage:
      'Once you have your custom assessment and its coding tests, it is time to invite the tech candidates! After receiving your invitation, the candidates will be seamlessly redirected to undertake one of the tests.',
  },
  stepThree: {
    id: 'companies.getStarted.stepThree',
    defaultMessage: 'Shortlist candidates',
  },
  stepThreeDetails: {
    id: 'companies.getStarted.stepThreeDetails',
    defaultMessage:
      'After sharing your assessment link, the candidates will start viewing & completing one of the suggested coding tests. Track their progress directly from your dashboard, check their Coding Skills Report and simply spot those that showcase the right skills!',
  },
  getStarted: {
    id: 'companies.getStarted.getStarted',
    defaultMessage: 'Start!',
  },
});
