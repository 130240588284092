/*
  GetStartedCompanyAdmin
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import routeGenerators from 'ui/common/routes/generators';
import * as messages from './messages';
import indorseLogo from 'resources/brand/logo-icon.svg';
import styles from './index.module.scss';
import Button from 'ui/common/button';
import { Collapse } from 'reactstrap';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import { FETCH_COMPANY_VERSION } from 'store/graphql/GetStartedCompanyAdmin/action-types';
import { selectIsFetching, selectVersion } from 'store/graphql/GetStartedCompanyAdmin/selectors';
import GetStartedCompanyAdminReducer from 'store/graphql/GetStartedCompanyAdmin/reducer';
import injectReducer from 'utils/injectReducer';
import Loading from 'ui/common/loading';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import { selectCurrentAcceptedCompany } from 'store/graphql/VerifyTokenCompanyAdmin/selectors';

class GetStartedCompanyAdmin extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      openCardStepId: null,
    };
  }

  componentDidMount() {
    this.fetchCompanyVersion(this.props);
  }

  async fetchCompanyVersion(props) {
    const {
      client,
      fetchCompanyVersionStart,
      fetchCompanyVersionSuccess,
      fetchCompanyVersionFailure,
      prettyId,
    } = props;

    fetchCompanyVersionStart();
    try {
      const response = await client.query({
        query: gql`
          query getCompanyByPrettyId($prettyId: String!) {
            getCompanyByPrettyId(prettyId: $prettyId) {
              magicLink {
                version
              }
            }
          }
        `,
        variables: {
          prettyId: prettyId,
        },
      });

      const { data, errors } = response;
      if (resultHasErrors({ errors })) {
        fetchCompanyVersionFailure(errorsToSingleMessage({ errors }));
      } else {
        const { getCompanyByPrettyId } = data;
        fetchCompanyVersionSuccess({ version: getCompanyByPrettyId.magicLink.version });
      }
    } catch (error) {
      fetchCompanyVersionFailure(error.message);
    }
  }

  toggleStepCard(id) {
    if (this.state.openCardStepId === id) {
      this.setState({
        openCardStepId: null,
      });
    } else {
      this.setState({
        openCardStepId: id,
      });
    }
  }

  render() {
    const { intl: { formatMessage }, prettyId, fetching, version, currentCompany } = this.props;
    const isEms = currentCompany && currentCompany.features && currentCompany.features.ems;
    if (fetching) return <Loading />;
    const isV3 = version === 'v3';
    return (
      <div className={classnames('container py-4')}>
        <div className={classnames('row justify-content-center align-items-center')}>
          <div className="col-12 mt-5">
            {/* <h1 className="text-center">{formatMessage(messages[fieldNames.fieldOne])}</h1> */}
            <img
              src={indorseLogo}
              className="d-block mx-auto pt-3 mb-4"
              width="60px"
              alt="arrow-down"
            />

            <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
              <h1 className={classnames(styles.pageTitle, 'indorse-blue text-center')}>
                {formatMessage(messages.labels.title)}
              </h1>
              {isV3 ? (
                <h3 className={classnames(styles.pageSubTitle, 'text-center pb-4 mx-5')}>
                  Evaluate the tech skills of your employees on Indorse. We will recommend online
                  courses based on identified skill gaps.
                </h3>
              ) : (
                <h3 className={classnames(styles.pageSubTitle, 'text-center pb-4 mx-5')}>
                  {formatMessage(messages.labels.subtitle)}
                </h3>
              )}
            </MediaQuery>
            <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
              <h1 className={classnames('indorse-blue text-center')}>
                {formatMessage(messages.labels.title)}
              </h1>
              {isV3 ? (
                <h3 className={classnames(styles.pageSubTitle, 'text-center pb-4 mx-5')}>
                  Evaluate the tech skills of your employees on Indorse. We will recommend online
                  courses based on identified skill gaps.
                </h3>
              ) : (
                <h3 className={classnames(styles.pageSubTitle, 'text-center pb-4 mx-5')}>
                  {formatMessage(messages.labels.subtitle)}
                </h3>
              )}
            </MediaQuery>
            {!isV3 && (
              <div>
                <div
                  className={classnames(styles.step, 'mb-3')}
                  onClick={() => this.toggleStepCard('1')}
                >
                  <div className={classnames(styles.header)}>
                    <div className={classnames(styles.number)}>
                      <b>1</b>
                    </div>
                    <p>
                      <b>{formatMessage(messages.labels.stepOne)}</b>
                    </p>
                  </div>
                  <div className={classnames(styles.details)}>
                    <Collapse isOpen={this.state.openCardStepId === '1'}>
                      <p className="mt-3 px-2">{formatMessage(messages.labels.stepOneDetails)}</p>
                    </Collapse>
                  </div>
                </div>

                <div
                  className={classnames(styles.step, 'mb-3')}
                  onClick={() => this.toggleStepCard('2')}
                >
                  <div className={classnames(styles.header)}>
                    <div className={classnames(styles.number)}>
                      <b>2</b>
                    </div>
                    <p>
                      <b>{formatMessage(messages.labels.stepTwo)}</b>
                    </p>
                  </div>
                  <div className={classnames(styles.details)}>
                    <Collapse isOpen={this.state.openCardStepId === '2'}>
                      <p className="mt-3 px-2">{formatMessage(messages.labels.stepTwoDetails)}</p>
                    </Collapse>
                  </div>
                </div>

                <div
                  className={classnames(styles.step, 'mb-3')}
                  onClick={() => this.toggleStepCard('3')}
                >
                  <div className={classnames(styles.header)}>
                    <div className={classnames(styles.number)}>
                      <b>3</b>
                    </div>
                    <p>
                      <b>{formatMessage(messages.labels.stepThree)}</b>
                    </p>
                  </div>
                  <div className={classnames(styles.details)}>
                    <Collapse isOpen={this.state.openCardStepId === '3'}>
                      <p className="mt-3 px-2">{formatMessage(messages.labels.stepThreeDetails)}</p>
                    </Collapse>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-5 text-center">
              <Button
                style={{ backgroundColor: '#2552CB', padding: '13px 50px' }}
                className={classnames(styles.getStarted)}
                tag={Link}
                to={
                  !isEms
                    ? routeGenerators.companies.edit({ prettyId, section: 'jobs-management' })
                    : routeGenerators.companies.edit({ prettyId, section: 'organization' })
                }
              >
                <b>{formatMessage(messages.labels.getStarted)}</b> &nbsp;
                <i className="fa fa-arrow-right ml-3" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps && ownProps.match && ownProps.match.params;

  const prettyId = routeParams && routeParams.prettyId;
  const currentAcceptedCompany = selectCurrentAcceptedCompany(state);
  return {
    prettyId,
    fetching: selectIsFetching(state),
    version: selectVersion(state),
    currentCompany: currentAcceptedCompany && currentAcceptedCompany.company,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchCompanyVersionStart: () => dispatch({ type: FETCH_COMPANY_VERSION.START }),
    fetchCompanyVersionSuccess: ({ version }) =>
      dispatch({
        type: FETCH_COMPANY_VERSION.SUCCESS,
        payload: { version },
      }),
    fetchCompanyVersionFailure: error =>
      dispatch({ type: FETCH_COMPANY_VERSION.FAILURE, payload: { error } }),
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'GetStartedCompanyAdmin',
  reducer: GetStartedCompanyAdminReducer,
});

export default withRouter(
  compose(withReducer, withConnect)(injectIntl(withApollo(GetStartedCompanyAdmin)))
);
