import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/companies';

export const FETCH_COMPANY_BY_PRETTY_ID = defineApiActionTypes(
  `${prefix}/FETCH_SINGLE_BY_PRETTY_ID`
);

export const FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID = defineApiActionTypes(
  `${prefix}/FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID`
);

export const FETCH_COMPANY_ADVISORS_BY_PRETTY_ID = defineApiActionTypes(
  `${prefix}/FETCH_COMPANY_ADVISORS_BY_PRETTY_ID`
);

export const TRACK_COMPANY_MEMBER_VERIFIED_CLICK = `${prefix}/TRACK_COMPANY_MEMBER_VERIFIED_CLICK`;
