export const fieldNames = {
  confirmation: 'confirmation',
  password: 'password',
};

export default function buildModel({ currentUserHasPassword }) {
  return {
    [fieldNames.confirmation]: {
      presence: true,
      length: { is: 2 },
    },
    [fieldNames.password]: {
      presence: currentUserHasPassword ? true : false,
    },
  };
}
