import Immutable from 'seamless-immutable';
import { combineReducers } from 'redux';

import * as actionTypes from './action-types';

const sdkInitialState = Immutable({
  loading: false,
  loaded: false,
  error: null,
});

function createLibraryReducer({ startType, successType, failureType }) {
  return function(state = sdkInitialState, action) {
    switch (action.type) {
      case startType:
        return state.merge({ loading: true });
      case successType:
        return state.merge({ loading: false, loaded: true, error: null });
      case failureType:
        return state.merge({
          loading: false,
          loaded: false,
          error: action.payload && action.payload.message,
        });
      default:
        return state;
    }
  };
}

export default combineReducers({
  facebookSdk: createLibraryReducer({
    startType: actionTypes.LOAD_FACEBOOK_SDK_LIB.START,
    successType: actionTypes.LOAD_FACEBOOK_SDK_LIB.SUCCESS,
    failureType: actionTypes.LOAD_FACEBOOK_SDK_LIB.FAILURE,
  }),
  googleApi: createLibraryReducer({
    startType: actionTypes.LOAD_GOOGLE_API_LIB.START,
    successType: actionTypes.LOAD_GOOGLE_API_LIB.SUCCESS,
    failureType: actionTypes.LOAD_GOOGLE_API_LIB.FAILURE,
  }),
  googleAuth: createLibraryReducer({
    startType: actionTypes.LOAD_GOOGLE_AUTH_LIB.START,
    successType: actionTypes.LOAD_GOOGLE_AUTH_LIB.SUCCESS,
    failureType: actionTypes.LOAD_GOOGLE_AUTH_LIB.FAILURE,
  }),
  reCaptchaSdk: createLibraryReducer({
    startType: actionTypes.LOAD_RECAPTCHA_SDK_LIB.START,
    successType: actionTypes.LOAD_RECAPTCHA_SDK_LIB.SUCCESS,
    failureType: actionTypes.LOAD_RECAPTCHA_SDK_LIB.FAILURE,
  }),
  web3: createLibraryReducer({
    startType: actionTypes.LOAD_WEB3_LIB.START,
    successType: actionTypes.LOAD_WEB3_LIB.SUCCESS,
    failureType: actionTypes.LOAD_WEB3_LIB.FAILURE,
  }),
  stripeCheckoutSdk: createLibraryReducer({
    startType: actionTypes.LOAD_STRIPE_CHECKOUT_LIB.START,
    successType: actionTypes.LOAD_STRIPE_CHECKOUT_LIB.SUCCESS,
    failureType: actionTypes.LOAD_STRIPE_CHECKOUT_LIB.FAILURE,
  }),
});
