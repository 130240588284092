/*
  AdminClaimDisapproveModal
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { withApollo } from 'react-apollo';
import { connectModal as reduxModal } from 'redux-modal';
import gql from 'graphql-tag';

import TextField from 'ui/common/form/fields/text-field';
import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import validator from 'ui/common/form/validator';
import SubmitButton from 'ui/common/form/submit-button';
import fields, { fieldNames } from './model';
import messages from './messages';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import { disapproveClaim } from 'store/features/admin/claims/actions';
import {
  selectIsDisapprovingClaim,
  selectRejectionReasons,
} from 'store/features/admin/claims/selectors';
import { FETCH_REJECTION_REASONS } from 'store/features/admin/claims/action-types';
import { bindActionCreators } from 'redux';
import SelectField from 'ui/common/form/fields/simple-select-field';

const validate = validator(fields);

const { request: disapproveClaimRequest } = disapproveClaim;

export const MODAL_NAME = 'admin-claim-disapprove-modal';
export const FORM_NAME = 'admin-claim-disapprove-form';

class AdminClaimDisapproveModal extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    this.fetchRejectionReasons();
  }

  async fetchRejectionReasons() {
    const {
      client,
      fetchRejectionReasonsStart,
      fetchRejectionReasonsSuccess,
      fetchRejectionReasonsError,
    } = this.props;
    fetchRejectionReasonsStart();

    try {
      const response = await client.query({
        query: gql`
          query lookupItemsMany($filter: FilterFindManylookupItemsInput) {
            lookupItemsMany(filter: $filter) {
              value
            }
          }
        `,
        variables: {
          filter: {
            type: 'list',
            group: 'adminRejectionReason',
            status: 'active',
          },
        },
      });

      const { data, errors } = response;

      if (resultHasErrors(response)) {
        fetchRejectionReasonsError(errorsToSingleMessage({ errors }));
      } else {
        const { lookupItemsMany } = data;
        const rejectionReasons = lookupItemsMany.map(({ value: { name, value } }) => {
          return { text: name, value: value };
        });
        fetchRejectionReasonsSuccess(rejectionReasons);
      }
    } catch (error) {
      fetchRejectionReasonsError(errorsToSingleMessage(error));
    }
  }

  onSubmit(values) {
    const { claimId, disapproveClaimRequest } = this.props;
    const { disapprovalReason } = values;
    disapproveClaimRequest({ claimId, disapprovalReason });
  }

  render() {
    const {
      show,
      handleHide,
      handleSubmit,
      isDisapprovingClaim,
      rejectionReasons,
      intl: { formatMessage },
    } = this.props;

    return (
      <Modal isOpen={show}>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <ModalHeader toggle={handleHide}>
            <p>{formatMessage(messages.modalTitle)}</p>
          </ModalHeader>
          <ModalBody>
            <Field
              name={fieldNames.selectReason}
              component={SelectField}
              options={rejectionReasons}
              label={formatMessage(messages[fieldNames.selectReason])}
            />
            <Field
              name={fieldNames.disapprovalReason}
              component={TextField}
              multiLine
              rows={5}
              label={formatMessage(messages[fieldNames.disapprovalReason])}
            />
          </ModalBody>
          <ModalFooter className="float-right">
            <SubmitButton size="md" formName={FORM_NAME} loading={isDisapprovingClaim}>
              {formatMessage(messages.submitButton)}
            </SubmitButton>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state, props) {
  const { claimId } = props;
  const selector = formValueSelector(FORM_NAME);
  return {
    isDisapprovingClaim: selectIsDisapprovingClaim(state, { claimId }),
    rejectionReasons: selectRejectionReasons(state),
    initialValues: {
      [fieldNames.disapprovalReason]: selector(state, fieldNames.selectReason),
    },
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    disapproveClaimRequest: bindActionCreators(disapproveClaimRequest, dispatch),
    fetchRejectionReasonsStart: () => dispatch({ type: FETCH_REJECTION_REASONS.START }),
    fetchRejectionReasonsSuccess: rejectionReasons =>
      dispatch({ type: FETCH_REJECTION_REASONS.SUCCESS, payload: { rejectionReasons } }),
    fetchRejectionReasonsError: error =>
      dispatch({ type: FETCH_REJECTION_REASONS.FAILURE, payload: { error } }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(
    reduxForm({
      form: FORM_NAME,
      validate,
    })(injectIntl(withApollo(AdminClaimDisapproveModal)))
  )
);
