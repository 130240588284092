import React from 'react';
import autoBind from 'react-autobind';
import Autosuggest from 'react-autosuggest';
import TextInput from '../TextInput';
import './styles.scss';

class IntegrationAutosuggest extends React.Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      value: props.value || '',
      suggestions: [],
    };
  }

  renderInput(inputProps) {
    return <TextInput {...inputProps} useValue />;
  }

  getSuggestions(value) {
    // const inputValue = value.trim().toLowerCase();
    // const inputLength = inputValue.length;
    // const suggestionVal =
    //   inputLength > 0
    //     ? this.state.suggestions.filter(
    //         suggestion => suggestion.label.toLowerCase().slice(0, inputLength) === inputValue
    //       )
    //     : [];
    // const suggestionVal = inputLength > 0 ? [{label: 'test'}, {label: 'tesast'}] : [];

    return this.state.suggestions;
  }

  componentDidUpdate(prevProps) {
    // VERY IMPORTANT: This is for suggestion buttons to work
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue(suggestion) {
    return suggestion;
  }

  // Use your imagination to render suggestions.
  renderSuggestion(suggestion) {
    return <span className="suggestion">{suggestion[this.props.suggestionField]}</span>;
  }

  onChange(event, newValueObj) {
    this.props.onChange(newValueObj.newValue);
    if (!newValueObj.newValue) this.setState({ suggestions: [] });
    this.setState({
      value:
        typeof newValueObj.newValue === 'object'
          ? newValueObj.newValue[this.props.suggestionField]
          : newValueObj.newValue,
    });
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  async onSuggestionsFetchRequested(value) {
    if (value.reason !== 'suggestion-selected') {
      const results = await this.props.suggestionFn(value.value);

      this.setState({
        suggestions: results,
      });
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  render() {
    const { value, suggestions } = this.state;
    const { placeholder, labelText, id } = this.props;
    const inputProps = {
      id,
      placeholder,
      value,
      label: 'test',
      labelText,
      wrapperClass: 'py-0',
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        renderInputComponent={this.renderInput}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        alwaysRenderSuggestions={true}
        focusInputOnSuggestionClick={false}
      />
    );
  }
}

export default IntegrationAutosuggest;
