import { defineMessages } from 'react-intl';

export default defineMessages({
  importLindekedInPdfSuccess: {
    id: 'users.messages.import-linked-in-pdf.success',
    defaultMessage:
      'We have attempted to import your profile from the uploaded PDF. This is an experimental feature so please verify the imported work experience and update it if required.',
  },
  importLindekedInPdfFailure: {
    id: 'users.messages.import-linked-in-pdf.failure',
    defaultMessage: 'There was a error in importing your LinkedIn PDF. Please try again',
  },
  addCurrentUserEthAddressSuccess: {
    id: 'users.messages.set-current-user-eth-address.add.success',
    defaultMessage: 'Your ETH address has been saved!',
  },
  verifyCurrentUserEthAddressSuccess: {
    id: 'users.messages.set-current-user-eth-address.verify.success',
    defaultMessage: 'Your ETH address has been verified!',
  },
});
