import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import themeConfig from 'ui/theme/config';
import { hide as hideModal, show as showModal } from 'redux-modal';
import styles from './index.module.scss';
import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';
// import AnimatedBackground from 'ui/pages/home-V2/hero/animated-background';
// import heroStyles from 'ui/pages/home-V2/hero/index.module.scss';
// import MobileSvgBackground from 'ui/pages/home-V2/hero/mobile-background';
// import blackSquare from 'resources/common/black-square.svg';
// import blackCircle from 'resources/common/black-circle.svg';
// import blackTriangle from 'resources/common/black-triangle.svg';
import { withRouter } from 'react-router';
import * as authActionTypes from '../../../../store/features/auth/action-types';
// import Button from 'ui/common/button';
import { selectCurrentUser, selectIsLoggedIn } from '../../../../store/features/auth/selectors';

const CHROME = 'https://api.iconify.design/logos:chrome.svg';
const FIREFOX = 'https://api.iconify.design/logos:firefox.svg';
const OPERA = 'https://api.iconify.design/logos:opera.svg';

class Hero extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderDesktop() {
    return (
      <div className={classnames(styles.hero, 'mt-5')}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="px-0 mt-5">
            <h3 className={styles.hilightedMicro}>METAMORPH EXTENSION</h3>
            <h1 className={classnames(styles.heroTitle, 'mt-3 mx-auto text-left mb-0')}>
              See how <span className={styles.bold}>Git Repos</span> look<br />like{' '}
              <span className={styles.bold}>behind the scenes</span>
            </h1>
            <div className="my-4">
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                Analyze Git repos instantly
              </h3>
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                See the health of a repo
              </h3>
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                Doesn't access your code
              </h3>
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                Dev tool, free forever
              </h3>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <h3 className={classnames(styles.heroCTA)}>Download extension</h3>
                <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                  <img src={CHROME} alt="chrome" className={classnames('')} />
                </a>
                <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                  <img src={FIREFOX} alt="firefox" className={classnames('')} />
                </a>
                <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                  <img src={OPERA} alt="opera" className={classnames()} />
                </a>
              </div>
              {/* <div className={classnames(styles.policy, 'text-center row position-relative ml-1')}>
                <span className="mb-2 d-flex justify-content-center mr-2">
                  <img src={blackCircle} alt="square" className={classnames(styles.svg, 'mr-2')} />
                  Free forever
                </span>
                <span className="mb-2 d-flex justify-content-center mx-2">
                  <img src={blackSquare} alt="square" className={classnames(styles.svg, 'mr-2')} />
                  Dev tool
                </span>
                <span className="mb-2 d-flex justify-content-center mx-2">
                  <img
                    src={blackTriangle}
                    alt="square"
                    className={classnames(styles.svg, 'mr-2')}
                  />
                  Productivity
                </span>
              </div> */}
            </div>
          </div>
          {/* <div className="px-0 mt-5">
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/ems-ext-b2c-hero.png"
              width="700"
              className="ml-auto"
              alt="hero"
            />
          </div> */}
          <div
            className="px-0 mr-3 mt-5"
            style={{ boxShadow: '-5px 0px 20px 5px rgba(0,0,0,0.1)', borderRadius: '5px' }}
          >
            <video
              muted
              autoplay="autoplay"
              loop="true"
              playsInline
              width="700"
              className="m-auto"
              style={{ borderRadius: '5px' }}
            >
              <source
                src="https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-landing-hero-video-3.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    );
  }

  renderMobile = () => {
    return (
      <div className={classnames(styles.hero, 'mt-4')}>
        <div>
          <div className="px-0">
            <h3 className={classnames(styles.hilightedMicro, 'text-center')}>
              METAMORPH EXTENSION
            </h3>
            <h1 className={classnames(styles.heroTitle, 'mt-3 mx-auto text-center mb-0')}>
              See how <span className={styles.bold}>Git Repos</span> look<br />like{' '}
              <span className={styles.bold}>behind the scenes</span>
            </h1>
            <div className="my-4 text-left">
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                Analyze Git repos instantly
              </h3>
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                See the health of a repo
              </h3>
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                Doesn't access your code
              </h3>
              <h3 className={styles.heroSubTitle}>
                <i style={{ color: '#00B2A4' }} className="mr-2 fa fa-check-circle" />
                Dev tool, free forever
              </h3>
            </div>
            <div className="text-center">
              <h3 className={classnames(styles.heroCTA)}>Download extension</h3>
              <div className="d-flex align-items-center justify-content-center">
                <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                  <img src={CHROME} alt="chrome" className={classnames('')} />
                </a>
                <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                  <img src={FIREFOX} alt="firefox" className={classnames('')} />
                </a>
                <a className={classnames(styles.downloadBtn, 'mb-4 mt-3')}>
                  <img src={OPERA} alt="opera" className={classnames()} />
                </a>
              </div>
            </div>
          </div>
          <div className="px-0 mt-3">
            <img
              src="https://indorse-staging-bucket.s3.amazonaws.com/ems-ext-b2c-hero.png"
              // width="70%"
              className="mx-auto"
              alt="hero"
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={classnames(styles.heroCompany, 'page-max-width')}>
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
          {this.renderDesktop()}
        </MediaQuery>
        <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
          {this.renderMobile()}
        </MediaQuery>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const query = new URLSearchParams(ownProps.location.search);
  const alreadyExists = Boolean(query.get('already_exists'));
  const adminEmail = query.get('admin_email');
  return {
    adminEmail,
    alreadyExists,
    isLoggedIn: selectIsLoggedIn(state),
    currentUser: selectCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
    addMessage: bindActionCreators(addMessage, dispatch),
    loginSuccessRequest: ({ token, redirect, from }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
      }),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withApollo(Hero)))
);
