import { createSelector } from 'reselect';

export const selectAdminVoteValidatorCommentButtonState = state =>
  state.AdminVoteValidatorCommentButton;

export const selectIsUpdatingButtonByVoteId = createSelector(
  selectAdminVoteValidatorCommentButtonState,
  (state, props) => props && props.voteId,
  (state, voteId) => state && state[voteId] && state[voteId].updating
);

export const selectValidatorCommentByVoteId = createSelector(
  selectAdminVoteValidatorCommentButtonState,
  (state, voteId) => voteId,
  (state, voteId) => {
    const validatorComment = (voteId && state[voteId] && state[voteId].validatorComment) || null;
    return validatorComment;
  }
);
