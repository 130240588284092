import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';
import * as authActionTypes from 'store/features/auth/action-types';
import * as entityActionTypes from 'store/entities/action-types';
import { normalizeUsername } from './helpers';

function byId(state = Immutable({}), action) {
  switch (action.type) {
    case authActionTypes.FETCH_CURRENT_USER.SUCCESS:
    case authActionTypes.UPDATE_CURRENT_USER.SUCCESS:
      const { user: fetchedOrUpdatedUser } = action.payload;
      if (fetchedOrUpdatedUser && fetchedOrUpdatedUser.id) {
        return state.merge(
          {
            [fetchedOrUpdatedUser.id]: {
              fetching: false,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_USER_BY_ID.START:
      const { userId: fetchingUserId } = action.payload;
      if (fetchingUserId) {
        return state.merge(
          {
            [fetchingUserId]: {
              fetching: true,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_USER_BY_ID.SUCCESS:
      const { userId: fetchedUserId } = action.payload;
      if (fetchedUserId) {
        return state.merge(
          {
            [fetchedUserId]: {
              fetching: false,
              lastFetchedAt: new Date().toISOString(),
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.APPROVE_USER.START:
    case actionTypes.DISAPPROVE_USER.START:
      const { userId: approvalStatusUpdatingUserId } = action.payload;
      if (approvalStatusUpdatingUserId) {
        return state.merge(
          {
            [approvalStatusUpdatingUserId]: {
              updatingApprovalStatus: true,
              updateApprovalStatusError: null,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.APPROVE_USER.SUCCESS:
    case actionTypes.DISAPPROVE_USER.SUCCESS:
      const { userId: approvalStatusUpdatedUserId } = action.payload;
      if (approvalStatusUpdatedUserId) {
        return state.merge(
          {
            [approvalStatusUpdatedUserId]: {
              updatingApprovalStatus: false,
              lastUpdatedApprovalStatusAt: new Date().toISOString(),
              updateApprovalStatusError: null,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.APPROVE_USER.FAILURE:
    case actionTypes.DISAPPROVE_USER.FAILURE:
      const { userId: approvalStatusUpdateFailedUserId, error } = action.payload;
      if (approvalStatusUpdateFailedUserId) {
        return state.merge(
          {
            [approvalStatusUpdateFailedUserId]: {
              updatingApprovalStatus: false,
              updateApprovalStatusError: error && (error.message || 'Error'),
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.ASSIGN_ROLE.START:
      const { userId: assigningRoleUserId } = action.payload;
      if (assigningRoleUserId) {
        return state.merge(
          {
            [assigningRoleUserId]: {
              assigningRole: true,
              assignRoleError: null,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.ASSIGN_ROLE.SUCCESS:
      const { userId: assignedRoleUserId } = action.payload;
      if (assignedRoleUserId) {
        return state.merge(
          {
            [assignedRoleUserId]: {
              assigningRole: false,
              lastAssignedRoleAt: new Date().toISOString(),
              assignRoleError: null,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.ASSIGN_ROLE.FAILURE:
      const { userId: assignRoleFailedUserId, error: assignRoleError } = action.payload;
      if (assignRoleFailedUserId) {
        return state.merge(
          {
            [assignRoleFailedUserId]: {
              assigningRole: false,
              assignRoleError: assignRoleError && (assignRoleError.message || 'Error'),
            },
          },
          { deep: true }
        );
      }
      return state;
    default:
      return state;
  }
}

function byUsername(state = Immutable({}), action) {
  switch (action.type) {
    case authActionTypes.FETCH_CURRENT_USER.SUCCESS:
    case authActionTypes.UPDATE_CURRENT_USER.SUCCESS:
      const { user: fetchedOrUpdatedUser } = action.payload;
      if (fetchedOrUpdatedUser && fetchedOrUpdatedUser.username) {
        const fetchedOrUpdatedUsername = normalizeUsername(fetchedOrUpdatedUser.username);
        return state.merge(
          {
            [fetchedOrUpdatedUsername]: {
              fetching: false,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_USER_BY_USERNAME.START:
      let { username: fetchingUsername } = action.payload;
      fetchingUsername = normalizeUsername(fetchingUsername);
      if (fetchingUsername) {
        return state.merge(
          {
            [fetchingUsername]: {
              fetching: true,
            },
          },
          { deep: true }
        );
      }
      return state;
    case actionTypes.FETCH_USER_BY_USERNAME.SUCCESS:
      let { username: fetchedUsername } = action.payload;
      fetchedUsername = normalizeUsername(fetchedUsername);
      if (fetchedUsername) {
        return state.merge(
          {
            [fetchedUsername]: {
              fetching: false,
              lastFetchedAt: new Date().toISOString(),
            },
          },
          { deep: true }
        );
      }
      return state;
    default:
      return state;
  }
}

export function idsByUserName(state = Immutable({}), action) {
  switch (action.type) {
    case entityActionTypes.MERGE_ENTITIES:
      const { users } = action.payload;
      if (users) {
        const entriesToMerge = Object.keys(users).reduce((result, userId) => {
          const user = users[userId];
          const username = normalizeUsername(user ? user.username : null);
          if (username) {
            result[username] = userId;
          }

          return result;
        }, {});

        return state.merge(entriesToMerge);
      }
      return state;
    default:
      return state;
  }
}

export function linkedInImport(state = Immutable({ importing: false, error: undefined }), action) {
  switch (action.type) {
    case actionTypes.IMPORT_LINKEDIN_PDF.START:
      return state.merge({ importing: true });
    case actionTypes.IMPORT_LINKEDIN_PDF.SUCCESS:
      return state.merge({ importing: false, error: undefined });
    case actionTypes.IMPORT_LINKEDIN_PDF.FAILURE:
      const { error } = action.payload;
      return state.merge({ importing: false, error });
    case '@redux-modal/SHOW':
      if (action && action.payload && action.payload.modal === 'import-linkedin-modal') {
        return state.merge({ importing: false, error });
      }
      return state;
    default:
      return state;
  }
}

export function waypoint(state = Immutable({ userId: null, sectionId: '' }), action) {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_PROFILE_SECTION:
      const { userId, sectionId } = action.payload;
      return state.merge({ userId, sectionId });
    default:
      return state;
  }
}

export function skillsTemp(state = Immutable({ isHighlighted: false }), action) {
  switch (action.type) {
    case actionTypes.UPDATE_SKILL_IS_HIGHLIGHTED:
      const { isHighlighted } = action.payload;
      return state.merge({ isHighlighted });
    default:
      return state;
  }
}

export default combineReducers({
  byId,
  byUsername,
  idsByUserName,
  linkedInImport,
  waypoint,
  skillsTemp,
});
