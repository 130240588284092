import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'admin-delete-skill-header-title',
    defaultMessage: 'Delete Skill Confirmation',
  },
  confirmDelete: {
    id: 'admin-delete-skill-desciption',
    defaultMessage: `<p class="lead">Are you sure you want to delete skill <strong>{name}</strong>? This will effect users skills.</p>`,
  },
  confirm: {
    id: 'admin-delete-skill-buttons-confirm',
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: 'admin-delete-skill-buttons-cancel',
    defaultMessage: 'Cancel',
  },
});
