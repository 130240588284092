export default {
  palette: {
    white: '#fff', // color('white')
    primary: '#0080d6', // theme-color('primary')
    info: '#17a2b8', // theme-color('info')
    success: '#28a745', // theme-color('success')
    danger: '#dc3545', // theme-color('danger')
    warning: '#ffc107', // theme-color('warning')
    category: {
      artsA: '#fce312',
      artsB: '#f9fd01',
      technologyA: '#fb021f',
      technologyB: '#f76507',
      craftsA: '#fb66c9',
      craftsB: '#f81e8e',
      businessA: '#f76d15',
      businessB: '#f9b300',
      healthA: '#00b1a4',
      healthB: '#14afc4',
      culinaryA: '#b000c4',
      culinaryB: '#870f6e',
      serviceA: '#9fa1ff',
      serviceB: '#a659f7',
      educationA: '#0000ff',
      educationB: '#2001b8',
      miscA: '#ccbddd',
      miscB: '#e1eaf7',
      badgesA: '#aaaab2',
      badgesB: '#555458',
    },
  },
  font: {
    baseSizeInPixels: 16,
  },
  layout: {
    gridBreakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1400,
    },
  },
};
