import { defineMessages } from 'react-intl';

export default defineMessages({
  created: {
    id: 'admin.companies.messages.create',
    defaultMessage: 'Company created successfully',
  },
  updated: {
    id: 'admin.companies.messages.updated',
    defaultMessage: 'Company updated successfully',
  },
  confirmDelete: {
    id: 'admin.companies.messages.delete.confirm',
    defaultMessage: `<p class="lead">Are you sure you want to delete company <strong>{prettyId}</strong>?</p>`,
  },
});
