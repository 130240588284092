import React from 'react';
import V2Icon from 'ui/common/rebranding/components/Icon';

export default ({
  name,
  size,
  tooltip,
  description,
  className,
  onClick,
  children,
  disabled,
  ...passThrough
}) => {
  const nameType = name.substring(0, 6) === 'icon--' ? name : name || '';
  const defaultIcon =
    name.substring(0, 6) === 'fa fa-' ? name : name.substring(0, 3) === 'fa-' ? name : '';

  const others = {
    name: nameType,
  };

  return (
    <V2Icon
      className={className}
      onClick={onClick}
      tooltip={tooltip}
      description={description}
      disabled={disabled}
      {...others}
      {...passThrough}
    >
      {defaultIcon && <i className={defaultIcon} />}
    </V2Icon>
  );
};
