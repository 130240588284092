import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

const fetchBadgesInput = input => ({
  pageNo: 1,
  perPage: Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  ...input,
});

export const fetchBadges = {
  request: payload => defineAction(types.FETCH_BADGES.REQUEST, fetchBadgesInput(payload)),
  start: payload => defineAction(types.FETCH_BADGES.START, fetchBadgesInput(payload)),
  success: ({ input, result }) =>
    defineAction(types.FETCH_BADGES.SUCCESS, { input: fetchBadgesInput(input), result }),
  failure: ({ input, error }) =>
    defineAction(types.FETCH_BADGES.FAILURE, { input: fetchBadgesInput(input), error }),
};

export const createBadge = {
  request: (values, form) => defineAction(types.CREATE_BADGE.REQUEST, values, { form }),
  start: values => defineAction(types.CREATE_BADGE.START, values),
  success: ({ company }) => defineAction(types.CREATE_BADGE.SUCCESS, { company }),
  failure: error => defineAction(types.CREATE_BADGE.FAILURE, error),
};

export const updateBadge = {
  request: ({ prettyId, values }, form) =>
    defineAction(types.UPDATE_BADGE.REQUEST, { prettyId, values }, { form }),
  start: ({ prettyId, values }) => defineAction(types.UPDATE_BADGE.START, { prettyId, values }),
  success: ({ prettyId, company }) =>
    defineAction(types.UPDATE_BADGE.SUCCESS, { prettyId, company }),
  failure: ({ prettyId, error }) => defineAction(types.UPDATE_BADGE.FAILURE, { prettyId, error }),
};

export const deleteBadge = {
  request: ({ prettyId }) => defineAction(types.DELETE_BADGE.REQUEST, { prettyId }),
  start: ({ prettyId }) => defineAction(types.DELETE_BADGE.START, { prettyId }),
  success: ({ prettyId }) => defineAction(types.DELETE_BADGE.SUCCESS, { prettyId }),
  failure: ({ prettyId, error }) => defineAction(types.DELETE_BADGE.FAILURE, { prettyId, error }),
};

export const assignBadges = {
  request: ({ values }, form) => defineAction(types.ASSIGN_BADGES.REQUEST, { values }, { form }),
  start: ({ values }) => defineAction(types.ASSIGN_BADGES.START, { values }),
  success: ({ values }) => defineAction(types.ASSIGN_BADGES.SUCCESS, { values }),
  failure: ({ error }) => defineAction(types.ASSIGN_BADGES.FAILURE, { error }),
};
