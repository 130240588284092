import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const OverflowMenuItem = ({
  children,
  className,
  itemText,
  isActive,
  hasDivider,
  disabled,
  closeMenu,
  primaryFocus,
  onClick,
  wrapperClassName,
  ...other
}) => {
  const overflowMenuItemClasses = classNames(
    className,
    'overflow-menu-option',
    {
      'overflow-menu-divider': hasDivider,
      'is-disabled': disabled,
      'is-active': isActive,
    },
    wrapperClassName
  );

  const handleClick = evt => {
    onClick(evt);
    closeMenu();
  };

  const primaryFocusProp = !primaryFocus;

  const item = (
    <li
      {...primaryFocusProp}
      className={overflowMenuItemClasses}
      onClick={handleClick}
      {...other}
      role="menuitem"
    >
      {children}
    </li>
  );

  return item;
};

OverflowMenuItem.propTypes = {
  /**
   * The CSS class name to be placed on the button element
   */
  className: PropTypes.string,

  /**
   * The CSS class name to be placed on the wrapper list item element
   */
  wrapperClassName: PropTypes.string,

  /**
   * `true` to make this menu item a divider.
   */
  hasDivider: PropTypes.bool,

  /**
   * `true` to make this menu item active.
   */
  isActive: PropTypes.bool,

  /**
   * `true` to make this menu item disabled.
   */
  disabled: PropTypes.bool,

  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onMouseUp: PropTypes.func,

  /**
   * A callback to tell the parent menu component that the menu should be closed.
   */
  closeMenu: PropTypes.func,

  /**
   * `true` if this menu item should get focus when the menu gets open.
   */
  primaryFocus: PropTypes.bool,
};

OverflowMenuItem.defaultProps = {
  hasDivider: false,
  disabled: false,
  itemText: 'Provide itemText',
  onClick: () => {},
};

export default OverflowMenuItem;
