/*
 * AdminVoteValidatorCommentButton Messages
 *
 * This contains all the text for the AdminVoteValidatorCommentButton component.
 */
import { defineMessages } from 'react-intl';

export const voteActionTypes = {
  DOWN_VOTE: 'downvote',
  UP_VOTE: 'upvote',
  HIDE: 'hide',
};

export const tooltip = defineMessages({
  [voteActionTypes.UP_VOTE]: {
    id: 'graphql.AdminVoteValidatorCommentButton.button.upvote',
    defaultMessage: 'Upvote comment',
  },
  [voteActionTypes.DOWN_VOTE]: {
    id: 'graphql.AdminVoteValidatorCommentButton.button.downvote',
    defaultMessage: 'Down vote comment',
  },
  [voteActionTypes.HIDE]: {
    id: 'graphql.AdminVoteValidatorCommentButton.button.hide',
    defaultMessage: 'Hide comment',
  },
});

export const successMessages = defineMessages({
  [voteActionTypes.UP_VOTE]: {
    id: 'graphql.AdminVoteValidatorCommentButton.successMessage.upvote',
    defaultMessage: 'Upvote comment is successful',
  },
  [voteActionTypes.DOWN_VOTE]: {
    id: 'graphql.AdminVoteValidatorCommentButton.successMessage.downvote',
    defaultMessage: 'Downvote comment is successful',
  },
  [voteActionTypes.HIDE]: {
    id: 'graphql.AdminVoteValidatorCommentButton.successMessage.hide',
    defaultMessage: 'Hide comment is successful',
  },
});
