import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.scss';

function getInitialCheckedIndex(children) {
  let checkedIndex;

  for (let i = 0; i < children.length; i++) {
    if (!children[i].props.disabled) {
      checkedIndex = i;
      break;
    }
  }

  return checkedIndex;
}

export class RadioGroup extends Component {
  constructor({ children, value }) {
    super();

    const index = children.findIndex(c => c.props.value === value);
    let checkedIndex;
    if (value === undefined)
      // This is the case where it is not specified
      checkedIndex = -1;
    else {
      if (index > -1 && !children[index].props.disabled) checkedIndex = index;
      else checkedIndex = getInitialCheckedIndex(children);
    }
    this.state = { checkedIndex: checkedIndex };

    this.renderChild = this.renderChild.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  get value() {
    const { checkedIndex } = this.state;
    const { children } = this.props;

    const child = children.find(c => c.props.index === checkedIndex);
    return (child && child.props.value) || '';
  }

  // This is the case to handle late arriving props,
  // and set the state according to the value
  // as long as it's not disabled
  UNSAFE_componentWillReceiveProps(nextProps) {
    const children = this.props.children;
    const index = children.findIndex(c => c.props.value === nextProps.value && !c.props.disabled);
    if (index !== -1 && index !== this.state.checkedIndex) {
      this.setState({ checkedIndex: index });
    }
  }

  onChange(index) {
    const { onChange, children } = this.props;
    const child = children[index];
    if (!child) return;

    this.setState({ checkedIndex: index });
    onChange && onChange(child.props.value || '');
  }

  renderChild(child, index, checked) {
    const { children, horizontal, group } = this.props;
    return React.cloneElement(child, {
      horizontal,
      group,
      index,
      key: index,
      last: index === children.length - 1,
      onChange: this.onChange,
      ...child.props,
      checked: checked || !!child.props.checked,
    });
  }

  render() {
    const { checkedIndex } = this.state;
    const { horizontal, children, ...props } = this.props;
    const style = horizontal ? { display: 'inline-flex', width: '100%' } : {};
    const radioButtonClassnames = classNames('radio-group');
    return (
      <div style={style} {...props} className={radioButtonClassnames}>
        {children.map((c, i) => this.renderChild(c, i, i === checkedIndex))}
      </div>
    );
  }
}

RadioGroup.propTypes = {
  horizontal: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
  group: PropTypes.string,
};

export class RadioButton extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onChange, index, disabled } = this.props;
    !disabled && onChange && onChange(index);
  }

  render() {
    const { checked, children, disabled, group, value, hideIcon, className } = this.props;

    const radioClasses = classNames(`${className} radio-input my-0`, {
      'is-disabled': disabled,
      'is-checked': checked,
    });
    const labelClasses = classNames('radio-label');

    return (
      <div className={radioClasses} value={value} onClick={this.onClick}>
        {!hideIcon && <RadioIcon checked={checked} disabled={disabled} />}
        <div style={{ flex: 1 }} className={labelClasses}>
          {children}
        </div>
        <input
          className="input-hidden"
          type="radio"
          checked={checked}
          name={group}
          value={value}
          id={`${group}[${value}]`}
        />
      </div>
    );
  }
}

RadioButton.propTypes = {
  value: PropTypes.string,
  index: PropTypes.number,
  checked: PropTypes.bool,
  children: PropTypes.node,
  horizontal: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledColor: PropTypes.string,
  hideIcon: PropTypes.bool,
};

export class RadioIcon extends Component {
  render() {
    const radioIconClasses = classNames('radio-icon');
    const radioIconInnerClasses = classNames('radio-icon-inner');
    return (
      <div className={radioIconClasses}>
        <div className={radioIconInnerClasses} />
      </div>
    );
  }
}

RadioIcon.propTypes = {
  checked: PropTypes.bool,
};
