import { createSelector } from 'reselect';
import * as libSelectors from 'store/libs/selectors';
import brand from 'resources/brand';

const selectAuthFeatureState = state => state.features.auth;

export const selectIsSignedUp = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.signedUp
);

export const selectEmailVerification = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.emailVerification
);

export const selectAuthToken = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.token
);

export const selectIsLoggedIn = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.loggedIn
);

export const selectCurrentUser = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.currentUser
);

export const selectIsFetchingCurrentUser = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.fetchingCurrentUser
);

export const selectCurrentUserFetchedAtleastOnce = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.currentUserFetchedAtleastOnce
);

export const selectCurrentUserInitialized = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.currentUserInitialized
); // dummy

export const selectIsCurrentUserApproved = createSelector(
  selectCurrentUser,
  currentUser =>
    currentUser && currentUser.approved ? currentUser.approved.toString() === 'true' : false
);

export const selectIsCurrentUserAdmin = createSelector(
  selectCurrentUser,
  currentUser => (currentUser ? currentUser.role === 'admin' : false)
);

export const selectIsCurrentUserCompanyAdmin = createSelector(
  selectCurrentUser,
  currentUser =>
    currentUser ? currentUser.myCompanies && currentUser.myCompanies.length > 0 : false
);

export const selectIsCurrentUserFullAccess = createSelector(
  selectCurrentUser,
  currentUser => (currentUser ? currentUser.role === 'full_access' : false)
);

// validator.group
export const selectIsCurrentUserTier3 = createSelector(
  selectCurrentUser,
  currentUser =>
    currentUser && currentUser.validator && currentUser.validator.group === '_3' ? true : false
);

export const selectIsCurrentUserPanelReviewer = createSelector(
  selectCurrentUser,
  currentUser => (currentUser ? currentUser.isPanelReviewer : false)
);

export const selectIsCurrentUserNoAccess = createSelector(
  selectCurrentUser,
  currentUser => (currentUser ? !currentUser.role || currentUser.role === 'no_access' : false)
);

export const selectCurrentUserId = createSelector(
  selectAuthFeatureState,
  authFeatureState => (authFeatureState.currentUser ? authFeatureState.currentUser.id : undefined)
);

export const selectCurrentUsername = createSelector(
  selectAuthFeatureState,
  authFeatureState =>
    authFeatureState.currentUser ? authFeatureState.currentUser.username : undefined
);

export const selectCurrentUserEmail = createSelector(
  selectAuthFeatureState,
  authFeatureState =>
    authFeatureState.currentUser ? authFeatureState.currentUser.email : undefined
);

export const selectCurrentUserHasAutoGeneratedUsername = createSelector(
  selectAuthFeatureState,
  authFeatureState =>
    authFeatureState.currentUser ? authFeatureState.currentUser.isAutoGeneratedUsername : undefined
);

export const selectCurrentUserEthereumAddress = createSelector(
  selectAuthFeatureState,
  authFeatureState =>
    authFeatureState.currentUser ? authFeatureState.currentUser.ethereumAddress : undefined
);

export const selectCurrentUserRole = createSelector(
  selectAuthFeatureState,
  authFeatureState => (authFeatureState.currentUser ? authFeatureState.currentUser.role : undefined)
);

export const selectCurrentUserHasPassword = createSelector(
  selectAuthFeatureState,
  authFeatureState =>
    authFeatureState.currentUser ? authFeatureState.currentUser.isPass : undefined
);

export const selectIsForgotPasswordEmailSent = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.forgotPasswordEmailSent
);

export const selectIsFacebookAuthAvailable = createSelector(
  libSelectors.selectFacebookSdkLibLoadState,
  facebookSdkLibLoadState =>
    facebookSdkLibLoadState &&
    facebookSdkLibLoadState.loaded &&
    process.env.REACT_APP_FACEBOOK_AUTH_ENABLED === 'true'
);

export const selectIsAuthenticatingWithFacebook = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.authenticatingWithFacebook
);

export const selectIsLinkingFacebook = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.linkingWithFacebook
);

export const selectHasCurrentUserLinkedFacebook = createSelector(
  selectCurrentUser,
  currentUser => currentUser && !!currentUser.isFacebookLinked
);

export const selectIsGoogleAuthAvailable = createSelector(
  libSelectors.selectGoogleApiLibLoadState,
  libSelectors.selectGoogleAuthLibLoadState,
  libSelectors.selectGoogleAuthLibLoadState,
  (googleApiLibLoadState, googleAuthLibLoadState) =>
    googleApiLibLoadState &&
    googleApiLibLoadState.loaded &&
    googleAuthLibLoadState &&
    googleAuthLibLoadState.loaded
);

export const selectIsAuthenticatingWithGoogle = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.authenticatingWithGoogle
);

export const selectIsLinkingGoogle = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.linkingWithGoogle
);

export const selectHasCurrentUserLinkedGoogle = createSelector(
  selectCurrentUser,
  currentUser => currentUser && !!currentUser.isGoogleLinked
);

export const selectIsLinkedInAuthAvailable = createSelector(() => true);

export const selectIsAuthenticatingWithLinkedIn = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.authenticatingWithLinkedIn
);

export const selectIsLinkingLinkedIn = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.linkingWithLinkedIn
);

export const selectHasCurrentUserLinkedLinkedIn = createSelector(
  selectCurrentUser,
  currentUser => currentUser && !!currentUser.isLinkedInLinked
);

export const selectIsAuthenticatingWithUPort = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.authenticatingWithUPort
);

export const selectIsLinkingUPort = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.linkingWithUPort
);

export const selectHasCurrentUserLinkedUPort = createSelector(
  selectCurrentUser,
  currentUser => !!currentUser.isUPortLinked
);

export const selectIsPollingUPort = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.pollingUPort
);

export const selectUPortData = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.uPortData
);

export const selectPollingUPortStatus = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.pollingUPortStatus
);

export const selectHasCurrentUserLinkedGithub = createSelector(
  selectCurrentUser,
  currentUser => currentUser && currentUser.githubUid && !!currentUser.githubUid
);

export const selectIsAuthenticatingWithExternalAccount = createSelector(
  selectIsAuthenticatingWithFacebook,
  selectIsAuthenticatingWithGoogle,
  selectIsAuthenticatingWithLinkedIn,
  selectIsAuthenticatingWithUPort,
  (
    authenticatingWithFacebook,
    authenticatingWithGoogle,
    authenticatingWithLinkedIn,
    authenticatingWithUPort
  ) =>
    authenticatingWithFacebook ||
    authenticatingWithGoogle ||
    authenticatingWithLinkedIn ||
    authenticatingWithUPort
);

export const selectIsLinkingWithExternalAccount = createSelector(
  selectIsLinkingFacebook,
  selectIsLinkingGoogle,
  selectIsLinkingLinkedIn,
  selectIsLinkingUPort,
  (linkingWithFacebook, linkingWithGoogle, linkingWithLinkedIn, linkingWithUPort) =>
    linkingWithFacebook || linkingWithGoogle || linkingWithLinkedIn || linkingWithUPort
);

export const selectCurrentUserImportedLinkedInPdfAtleastOnce = createSelector(
  selectCurrentUser,
  currentUser =>
    currentUser &&
    currentUser.lastLinkedinPdfImportAt &&
    currentUser.lastLinkedinPdfImportAt.length &&
    currentUser.lastLinkedinPdfImportAt[0]
);

export const selectIsSavingCurrentUser = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.saving
);

export const selectCurrentUserHasConnectionsEntity = createSelector(
  selectCurrentUser,
  currentUser => currentUser && currentUser.hasConnectionEntity
);

export const selectCountsOfLinkedAccounts = createSelector(
  selectHasCurrentUserLinkedFacebook,
  selectHasCurrentUserLinkedGoogle,
  selectHasCurrentUserLinkedLinkedIn,
  (linkedFacebook, linkedGoogle, linkedLinkedIn) => {
    return [linkedFacebook, linkedGoogle, linkedLinkedIn].filter(val => val).length;
  }
);

export const selectImpressApiDetails = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.impressApi
);

export const selectIsSettingCurrentUserEthereumAddress = createSelector(
  selectAuthFeatureState,
  authFeatureState => authFeatureState.settingCurrentUserEthereumAddress
);

export const selectIsCurrentUserEthereumAddressVerified = createSelector(
  selectAuthFeatureState,
  authFeatureState =>
    authFeatureState.currentUser ? authFeatureState.currentUser.ethereumAddressVerified : undefined
);

export const selectCanCurrentUserChangeEthereumAddress = createSelector(
  selectCurrentUserEthereumAddress,
  (currentUserEthereumAddress, pollingAnyAdvisory, pollingAnyTeamMembership) => {
    const result = !!currentUserEthereumAddress && !pollingAnyAdvisory && !pollingAnyTeamMembership;
    return result;
  }
);

export const selectCurrentUserTcAndPrivacyConsents = createSelector(
  selectCurrentUser,
  currentUser => {
    return (currentUser && currentUser.termsPrivacy) || [];
  }
);

export const selectCurrentUserLatestTcAndPrivacyConsent = createSelector(
  selectCurrentUserTcAndPrivacyConsents,
  consents => {
    let mutableConsents = consents.asMutable ? consents.asMutable() : consents;
    mutableConsents = mutableConsents.sort((left, right) => {
      if (left.timestamp >= right.timestamp) return -1;
      return 1;
    });
    return mutableConsents[0];
  }
);

export const selectLatestAcceptedTcVersionByCurrentUser = createSelector(
  selectCurrentUserLatestTcAndPrivacyConsent,
  consent => {
    return consent ? Number(consent.tcVersion) : undefined;
  }
);

export const selectLatestAcceptedPrivacyVersionByCurrentUser = createSelector(
  selectCurrentUserLatestTcAndPrivacyConsent,
  consent => (consent ? Number(consent.privacyVersion) : undefined)
);

export const selectLatestAcceptedTcAndPrivacyByCurrentUserAt = createSelector(
  selectCurrentUserLatestTcAndPrivacyConsent,
  consent => (consent ? consent.timestamp : undefined)
);

export const selectHasCurrentUserAcceptedCurrentTermsAndPrivacy = createSelector(
  selectLatestAcceptedTcVersionByCurrentUser,
  selectLatestAcceptedPrivacyVersionByCurrentUser,
  (lastAcceptedTcVersionByCurrentUser, lastAcceptedPrivacyVersionByCurrentUser) => {
    return (
      (lastAcceptedTcVersionByCurrentUser &&
        lastAcceptedTcVersionByCurrentUser >= brand.termsOfUse.currentVersion.version) ||
      (lastAcceptedPrivacyVersionByCurrentUser &&
        lastAcceptedPrivacyVersionByCurrentUser >= brand.privacyPolicy.currentVersion.version)
    );
  }
);

export const selectCurrentUserDeletionRequests = createSelector(
  selectCurrentUser,
  currentUser =>
    currentUser.dataRequest && currentUser.dataRequest.filter(data => data.requestType === 'delete')
);

export const selectCurrentUserDeletionRequestedAt = createSelector(
  selectCurrentUserDeletionRequests,
  data => data && data.length && data[data.length - 1].timestamp
);

export const selectCurrentUsersJobRoles = createSelector(
  selectAuthFeatureState,
  authFeatureState => (authFeatureState.currentUser ? authFeatureState.currentUser.jobRoles : [])
);

export const selectCurrentUsersCurrentJobRoleId = createSelector(
  selectCurrentUsersJobRoles,
  jobRoles => (jobRoles && jobRoles.length ? jobRoles[0] : undefined)
);
