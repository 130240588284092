import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  error: false,
  fetchingStart: false,
  authenticatingWithGithub: false,
  code: null,
  state: null,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GITHUB_AUTH.START: {
      return state.merge({
        fetchingStart: true,
        authenticatingWithGithub: true,
      });
    }
    case actionTypes.GITHUB_AUTH.SUCCESS: {
      return state.merge({
        authenticatingWithGithub: false,
        code: action.payload.code,
        state: action.payload.state,
      });
    }
    case actionTypes.GITHUB_AUTH.FAILURE: {
      const { error } = action.payload;

      return state.merge({
        authenticatingWithGithub: false,
        error,
      });
    }

    default:
      return state;
  }
}
