import { combineReducers } from 'redux';

import { reducer as users } from './users';
import { reducer as claims } from './claims';
import { reducer as votingRounds } from './voting-rounds';
import { reducer as votes } from './votes';
import { reducer as education } from './education';
import { reducer as workExperience } from './work-experience';
import { reducer as companies } from './companies';
import { reducer as skillCategories } from './skill-categories';
import { reducer as skills } from './skills';
import { reducer as userSkills } from './user-skills';
import { reducer as badges } from './badges';
import { reducer as wizardDefinitions } from './wizard-definitions';
import { reducer as smartContracts } from './smart-contracts';
import { reducer as ratingCriterias } from './rating-criterias';

export default combineReducers({
  users,
  claims,
  votingRounds,
  votes,
  education,
  workExperience,
  companies,
  skillCategories,
  skills,
  userSkills,
  badges,
  wizardDefinitions,
  smartContracts,
  ratingCriterias,
});
