import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { startSubmit, stopSubmit } from 'redux-form';
import { push } from 'react-router-redux';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/companies';
import callApi from 'store/api/saga';
import routeTemplates from 'ui/common/routes/templates';
import routeGenerators from 'ui/common/routes/generators';
import { addMessage } from 'store/features/common/flash/actions';
import { buildMessage, messageTypes } from 'store/features/common/flash/builder';
import { isFile, convertToDataURL } from 'utils/file';
import { confirmSaga as confirm } from 'store/features/common/confirmation-dialog/saga';

import messages from 'ui/admin/companies/messages';

function* fetchCompanies({ payload }) {
  yield put(actions.fetchCompanies.start(payload));

  try {
    const response = yield call(callApi, api.fetchCompanies(payload));

    if (response && response.companies) {
      const schema = { companies: [schemas.company] };
      const { entities, result } = normalize(response, schema);
      yield put(entityActions.mergeEntities(entities));
      yield put(actions.fetchCompanies.success({ input: payload, result }));
    } else {
      // TODO: Remove this when current companies backend limitations are resolved
      const schema = [schemas.company];
      const { entities, result } = normalize(response, schema);
      yield put(entityActions.mergeEntities(entities));
      yield put(
        actions.fetchCompanies.success({
          input: payload,
          result: {
            companies: result,
            totalCompanies: result.length,
            matchingCompanies: result.length,
          },
        })
      );
    }
  } catch (error) {
    yield put(actions.fetchCompanies.failure({ input: payload, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchCompanies() {
  yield takeEvery(actionTypes.FETCH_COMPANIES.REQUEST, fetchCompanies);
}

function* convertImagesToDataUrls(values) {
  const { logo, cover, admin, ...newValues } = values;

  if (logo && isFile(logo)) {
    newValues.logoData = yield call(convertToDataURL, logo);
  }

  if (cover && isFile(cover)) {
    newValues.coverData = yield call(convertToDataURL, cover);
  }

  return newValues;
}

function* createCompany({ payload: values, meta }) {
  const form = meta && meta.form;
  yield put(actions.createCompany.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const finalValues = yield call(convertImagesToDataUrls, values);

    const response = yield call(callApi, api.createCompany(finalValues));
    if (form) yield put(stopSubmit(form));

    const schema = schemas.company;
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.createCompany.success(result));

    yield put(
      push(routeTemplates.admin.companies.root, {
        flash: buildMessage({ kind: messageTypes.success, content: messages.created }),
      })
    );
  } catch (error) {
    yield put(actions.createCompany.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchCreateCompany() {
  yield takeEvery(actionTypes.CREATE_COMPANY.REQUEST, createCompany);
}

function* updateCompany({ payload: { prettyId, values, refetchCallback }, meta }) {
  const form = meta && meta.form;
  yield put(actions.updateCompany.start({ prettyId, values }));
  if (form) yield put(startSubmit(form));

  try {
    const finalValues = yield call(convertImagesToDataUrls, values);

    const response = yield call(callApi, api.updateCompany({ prettyId, values: finalValues }));
    if (form) yield put(stopSubmit(form));

    const schema = schemas.company;
    const { entities, result } = normalize(response, schema);
    if (prettyId !== finalValues.prettyId)
      yield put(entityActions.removeEntities({ [schema.key]: [prettyId] }));
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.updateCompany.success(result));

    yield put(
      push(routeGenerators.companies.edit({ prettyId, section: 'basic-info' }), {
        flash: buildMessage({ kind: messageTypes.success, content: messages.updated }),
      })
    );
  } catch (error) {
    yield put(actions.updateCompany.failure({ prettyId, error }));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchUpdateCompany() {
  yield takeEvery(actionTypes.UPDATE_COMPANY.REQUEST, updateCompany);
}

function* deleteCompany({ payload }) {
  const { prettyId, companyName, refetchCallback } = payload;

  const confirmationMessage = {
    ...messages.confirmDelete,
    values: { prettyId: companyName },
  };
  const confirmed = yield call(confirm, { message: confirmationMessage });
  if (!confirmed) {
    return;
  }

  yield put(actions.deleteCompany.start({ prettyId }));

  try {
    yield call(callApi, api.deleteCompany({ prettyId }));
    yield put(actions.deleteCompany.success({ prettyId }));
    // console.log('refetchCallback', refetchCallback);
    if (!!refetchCallback) {
      // console.log('call back init');
      yield put(refetchCallback());
      // console.log('call back done');
    }
  } catch (error) {
    yield put(actions.deleteCompany.failure({ prettyId, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchDeleteCompany() {
  yield takeEvery(actionTypes.DELETE_COMPANY.REQUEST, deleteCompany);
}

export default function* companies() {
  yield fork(watchFetchCompanies);
  yield fork(watchCreateCompany);
  yield fork(watchUpdateCompany);
  yield fork(watchDeleteCompany);
}
