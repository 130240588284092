export const networkNames = {
  local: 'LOCAL',
  rinkeby: 'RINKEBY',
  mainnet: 'MAINNET',
};

export const networks = {
  [networkNames.local]: {
    etherscanBaseUrl: `https://etherscan.io/`,
    displayName: `Local`,
    displayNameMEW: `Local`,
    imageMEW: require('./mew-LOCAL.png'),
    httpProviderUrl: `https://localhost:8545/`,
  },
  [networkNames.rinkeby]: {
    etherscanBaseUrl: `https://rinkeby.etherscan.io/`,
    displayName: `Rinkeby`,
    displayNameMEW: `Rinkeby`,
    imageMEW: require('./mew-RINKEBY.png'),
    httpProviderUrl: `https://rinkeby.infura.io/${process.env.REACT_APP_INFURA_TOKEN}`,
  },
  [networkNames.mainnet]: {
    etherscanBaseUrl: `https://etherscan.io/`,
    displayName: `Mainnet`,
    displayNameMEW: `ETH`,
    imageMEW: require('./mew-MAINNET.png'),
    httpProviderUrl: `https://mainnet.infura.io/${process.env.REACT_APP_INFURA_TOKEN}`,
  },
};

export function findNetworkNameByWeb3NetworkId(networkId) {
  switch (networkId) {
    case '1':
      return networkNames.mainnet;
    case '4':
      return networkNames.rinkeby;
    case '2':
    case '3':
    case '42':
      return undefined;
    default:
      return networkNames.local;
  }
}
