import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

export default function reducer(state = Immutable({}), action) {
  switch (action.type) {
    case actionTypes.FETCH_BADGE_BY_PRETTY_ID.START:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: true,
            error: false,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_BADGE_BY_PRETTY_ID.SUCCESS:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: false,
            lastFetchedAt: new Date().toISOString(),
            error: false,
          },
        },
        { deep: true }
      );
    case actionTypes.FETCH_BADGE_BY_PRETTY_ID.FAILURE:
      return state.merge(
        {
          [action.payload.prettyId]: {
            fetching: false,
            error: true,
          },
        },
        { deep: true }
      );
    default:
      return state;
  }
}
