import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const signUp = {
  request: (values, form, from) => defineAction(types.SIGN_UP.REQUEST, values, { form, from }),
  start: values => defineAction(types.SIGN_UP.START, values),
  success: response => defineAction(types.SIGN_UP.SUCCESS, response),
  failure: error => defineAction(types.SIGN_UP.FAILURE, error),
};

export const verifyEmail = {
  request: values => defineAction(types.VERIFY_EMAIL.REQUEST, values),
  start: values => defineAction(types.VERIFY_EMAIL.START, values),
  success: response => defineAction(types.VERIFY_EMAIL.SUCCESS, response),
  failure: error => defineAction(types.VERIFY_EMAIL.FAILURE, error),
};

export const resendVerificationEmail = {
  request: (values, form) =>
    defineAction(types.RESEND_VERIFICATION_EMAIL.REQUEST, values, { form }),
  start: () => defineAction(types.RESEND_VERIFICATION_EMAIL.START),
  success: () => defineAction(types.RESEND_VERIFICATION_EMAIL.SUCCESS),
  failure: error => defineAction(types.RESEND_VERIFICATION_EMAIL.FAILURE, error),
};

export const login = {
  request: (values, { form, from }) => defineAction(types.LOGIN.REQUEST, values, { form, from }),
  start: values => defineAction(types.LOGIN.START, values),
  success: ({ token, user }) => defineAction(types.LOGIN.SUCCESS, { token, user }),
  failure: error => defineAction(types.LOGIN.FAILURE, error),
};

export const logout = {
  request: () => defineAction(types.LOGOUT.REQUEST),
  start: () => defineAction(types.LOGOUT.START),
  success: () => defineAction(types.LOGOUT.SUCCESS),
  failure: error => defineAction(types.LOGOUT.FAILURE, error),
};

export const changePassword = {
  request: (values, form) => defineAction(types.CHANGE_PASSWORD.REQUEST, values, { form }),
  start: () => defineAction(types.CHANGE_PASSWORD.START),
  success: () => defineAction(types.CHANGE_PASSWORD.SUCCESS),
  failure: error => defineAction(types.CHANGE_PASSWORD.FAILURE, error),
};

export const setPassword = {
  request: ({ values, form, redirect }) => {
    return defineAction(types.SET_PASSWORD.REQUEST, { values, form, redirect });
  },
  start: () => defineAction(types.SET_PASSWORD.START),
  success: () => defineAction(types.SET_PASSWORD.SUCCESS),
  failure: error => defineAction(types.SET_PASSWORD.FAILURE, error),
};
// dummy
export const forgotPassword = {
  request: (values, form) => defineAction(types.FORGOT_PASSWORD.REQUEST, values, { form }),
  start: () => defineAction(types.FORGOT_PASSWORD.START),
  success: () => defineAction(types.FORGOT_PASSWORD.SUCCESS),
  failure: error => defineAction(types.FORGOT_PASSWORD.FAILURE, error),
};

export const clearForgotPasswordEmailStatus = () =>
  defineAction(types.CLEAR_FORGOT_PASSWORD_EMAIL_STATUS);

export const resetPassword = {
  request: (values, form) => defineAction(types.RESET_PASSWORD.REQUEST, values, { form }),
  start: () => defineAction(types.RESET_PASSWORD.START),
  success: () => defineAction(types.RESET_PASSWORD.SUCCESS),
  failure: error => defineAction(types.RESET_PASSWORD.FAILURE, error),
};

export const invalidateSession = () => defineAction(types.INVALIDATE_SESSION);

export const fetchCurrentUser = {
  request: () => defineAction(types.FETCH_CURRENT_USER.REQUEST),
  start: () => defineAction(types.FETCH_CURRENT_USER.START),
  success: ({ profile }) => defineAction(types.FETCH_CURRENT_USER.SUCCESS, { user: profile }),
  failure: error => defineAction(types.FETCH_CURRENT_USER.FAILURE, error),
};

export const updateCurrentUser = {
  request: (values, form, modal) =>
    defineAction(types.UPDATE_CURRENT_USER.REQUEST, values, { form, modal }),
  start: values => defineAction(types.UPDATE_CURRENT_USER.START, values),
  success: ({ profile }) => defineAction(types.UPDATE_CURRENT_USER.SUCCESS, { user: profile }),
  failure: error => defineAction(types.UPDATE_CURRENT_USER.FAILURE, error),
};

export const unauthorized = message => defineAction(types.UNAUTHORIZED, { message });

export const importLinkedInProfileTest = () => defineAction(types.IMPORT_LINKED_IN_PROFILE_TEST);

export const facebookAuth = {
  request: ({ existingValues, from }) =>
    defineAction(types.FACEBOOK_AUTH.REQUEST, null, { existingValues, from }),
  start: () => defineAction(types.FACEBOOK_AUTH.START),
  success: () => defineAction(types.FACEBOOK_AUTH.SUCCESS),
  failure: ({ error }) => defineAction(types.FACEBOOK_AUTH.FAILURE, error),
};

export const linkFacebook = {
  request: ({ existingValues }) =>
    defineAction(types.LINK_FACEBOOK.REQUEST, null, { existingValues }),
  start: () => defineAction(types.LINK_FACEBOOK.START),
  success: () => defineAction(types.LINK_FACEBOOK.SUCCESS),
  failure: ({ error }) => defineAction(types.LINK_FACEBOOK.FAILURE, error),
};

export const googleAuth = {
  request: ({ existingValues, from }) =>
    defineAction(types.GOOGLE_AUTH.REQUEST, null, { existingValues, from }),
  start: () => defineAction(types.GOOGLE_AUTH.START),
  success: ({ authData }) => defineAction(types.GOOGLE_AUTH.SUCCESS, { authData }),
  failure: ({ errorCode, message }) =>
    defineAction(types.GOOGLE_AUTH.FAILURE, { errorCode, message }),
};

export const linkGoogle = {
  request: ({ existingValues }) =>
    defineAction(types.LINK_GOOGLE.REQUEST, null, { existingValues }),
  start: () => defineAction(types.LINK_GOOGLE.START),
  success: ({ authData }) => defineAction(types.LINK_GOOGLE.SUCCESS, { authData }),
  failure: ({ errorCode, message }) =>
    defineAction(types.LINK_GOOGLE.FAILURE, { errorCode, message }),
};

export const linkedInAuth = {
  request: ({ existingValues, from }) =>
    defineAction(types.LINKED_IN_AUTH.REQUEST, null, { existingValues, from }),
  start: () => defineAction(types.LINKED_IN_AUTH.START),
  success: () => defineAction(types.LINKED_IN_AUTH.SUCCESS),
  failure: ({ error }) => defineAction(types.LINKED_IN_AUTH.FAILURE, error),
};

export const linkLinkedIn = {
  request: ({ existingValues }) =>
    defineAction(types.LINK_LINKED_IN.REQUEST, null, { existingValues }),
  start: () => defineAction(types.LINK_LINKED_IN.START),
  success: () => defineAction(types.LINK_LINKED_IN.SUCCESS),
  failure: ({ errorCode, message }) =>
    defineAction(types.LINK_LINKED_IN.FAILURE, { errorCode, message }),
};

export const uPortAuth = {
  request: () => defineAction(types.UPORT_AUTH.REQUEST),
  start: () => defineAction(types.UPORT_AUTH.START),
  success: ({ qr, token }) => defineAction(types.UPORT_AUTH.SUCCESS, { qr, token }),
  failure: ({ error }) => defineAction(types.UPORT_AUTH.FAILURE, error),
};

export const linkUPort = {
  request: () => defineAction(types.LINK_UPORT.REQUEST),
  start: () => defineAction(types.LINK_UPORT.START),
  success: ({ qr, token }) => defineAction(types.LINK_UPORT.SUCCESS, { qr, token }),
  failure: ({ errorCode, message }) =>
    defineAction(types.LINK_UPORT.FAILURE, { errorCode, message }),
};

export const pollUPort = {
  request: ({ token }) => defineAction(types.POLL_UPORT.REQUEST, { token }),
  start: () => defineAction(types.POLL_UPORT.START),
  success: ({ status }) => defineAction(types.POLL_UPORT.SUCCESS, { status }),
  failure: ({ errorCode, message }) =>
    defineAction(types.POLL_UPORT.FAILURE, { errorCode, message }),
};

export const fetchImpressAiAuth = {
  request: ({ skillName, selfTrigger, refreshAfterClosing = true }) =>
    defineAction(types.IMPRESS_AI_AUTH.REQUEST, { skillName, selfTrigger, refreshAfterClosing }),
  start: ({ skillName }) => defineAction(types.IMPRESS_AI_AUTH.START, { skillName }),
  success: ({ impressOAuthKey, chatbotUuid, skillName }) =>
    defineAction(types.IMPRESS_AI_AUTH.SUCCESS, { impressOAuthKey, chatbotUuid, skillName }),
  failure: ({ error, skillName }) =>
    defineAction(types.IMPRESS_AI_AUTH.FAILURE, { error, skillName }),
};

export const setHasConnectionsEntity = hasEntity =>
  defineAction(types.SET_HAS_CONNECTIONS_ENTITY, { hasEntity });

export const markCurrentUserInitialized = () => defineAction(types.MARK_CURRENT_USER_INITIALIZED);

export const acceptCurrentTermsAndPrivacy = {
  request: (values, form) =>
    defineAction(types.ACCEPT_CURRENT_TERMS_AND_PRIVACY.REQUEST, values, { form }),
  start: values => defineAction(types.ACCEPT_CURRENT_TERMS_AND_PRIVACY.START, values),
  success: response => defineAction(types.ACCEPT_CURRENT_TERMS_AND_PRIVACY.SUCCESS, response),
  failure: error => defineAction(types.ACCEPT_CURRENT_TERMS_AND_PRIVACY.FAILURE, error),
};

export const loginAdvisor = ({ token }) => defineAction(types.LOGIN_ADVISOR, { token });

export const loginCompanyAdmin = ({ token }) => defineAction(types.LOGIN_COMPANY_ADMIN, { token });

export const loginClaimsFlow = ({ token }) => defineAction(types.LOGIN_CLAIMS_FLOW, { token });

export const loginEmsEmployee = ({ token }) => defineAction(types.LOGIN_EMS_EMPLOYEE, { token });
