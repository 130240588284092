import { defineMessages } from 'react-intl';

const imagesPath = './images';

export default [
  {
    type: 'team',
    name: 'Gaurang Torvekar',
    username: 'gaurang',
    position: defineMessages({
      member: {
        id: 'team.gaurang-torvekar.position',
        defaultMessage: 'CEO and Co-Founder',
      },
    }),
    bio:
      'Gaurang has a Masters from Singapore Management University in Information Systems and is an experienced developer, writing smart contracts since 2015.',
    img: require(`${imagesPath}/Gaurang.jpg`),
    indorseUrl: 'https://indorse.io/gaurang',
    // linkedInUrl: 'https://www.linkedin.com/in/gaurangtorvekar',
    // twitterUrl: 'https://twitter.com/gaurangtorvekar',
  },
  {
    type: 'team',
    name: 'David Moskowitz',
    username: 'davidmoskowitz',
    position: defineMessages({
      member: {
        id: 'team.david-moskowitz.position',
        defaultMessage: 'Co-Founder',
      },
    }),
    bio:
      'Having previously founded Attores with Gaurang, David has been working in the blockchain industry since 2013 and well connected within the global community.',
    img: require(`${imagesPath}/David.jpg`),
    indorseUrl: 'https://indorse.io/davidmoskowitz',
    // linkedInUrl: 'https://www.linkedin.com/in/davidmoskowitz',
    // twitterUrl: 'https://twitter.com/david_mosk',
  },
  {
    type: 'team',
    name: 'Dipesh Sukhani',
    username: 'dipeshsukhani',
    position: defineMessages({
      member: {
        id: 'team.dipesh-sukhani.position',
        defaultMessage: 'CBO and Co-Founder',
      },
    }),
    bio:
      'Dipesh is a CPA with 11 years of work experience in structuring cross-border business transactions, among others at PWC.',
    img: require(`${imagesPath}/Dipesh.jpg`),
    indorseUrl: 'https://indorse.io/dipeshsukhani',
    // linkedInUrl: 'https://www.linkedin.com/in/dipeshsukhani/',
    // twitterUrl: 'https://twitter.com/dipeshsukhani',
  },
  {
    type: 'team',
    name: 'Avadhoot Kulkarni',
    username: 'avadhoot',
    position: defineMessages({
      member: {
        id: 'team.avadhoot-kulkarni.position',
        defaultMessage: 'CMO and Co-Founder',
      },
    }),
    bio:
      'Avadhoot excels in building strong brands and communities. He embraces a growth mindset and continuous learning, which eventually brought him to the Blockchain.',
    img: require(`${imagesPath}/Avadhoot.jpg`),
    indorseUrl: 'https://indorse.io/avadhoot',
    // linkedInUrl: 'https://www.linkedin.com/in/avadhootkulkarni16/',
    // twitterUrl: 'https://twitter.com/avadhoot_kulk',
  },
  {
    type: 'team',
    name: 'Richard Klatt',
    username: 'richardklatt',
    position: defineMessages({
      member: {
        id: 'team.richard-klatt.position',
        defaultMessage: 'Partnerships',
      },
    }),
    bio:
      'Richard has worked in venture development and investment banking roles. He is the co-founder of a co-living space business and holds an MBA.',
    img: require(`${imagesPath}/richard.jpg`),
    indorseUrl: 'https://indorse.io/richardklatt',
    // linkedInUrl: 'https://www.linkedin.com/in/arif-rahman-86785a136/',
    // twitterUrl: null,
    excludeFromLocales: ['zh-hans'],
  },
  {
    type: 'team',
    name: 'Miah Paculba',
    username: 'miahpaculba',
    position: defineMessages({
      member: {
        id: 'team.miah.position',
        defaultMessage: 'Graphic Designer',
      },
    }),
    bio: `Specialising in digital design and branding. Her goal is to create designs that will inspire and motivate people while leaving a lasting impression.`,
    img: require(`${imagesPath}/miah.jpg`),
    indorseUrl: 'https://indorse.io/miahpaculba',
    // linkedInUrl: 'https://www.linkedin.com/in/arif-rahman-86785a136/',
    // twitterUrl: null,
    excludeFromLocales: ['zh-hans'],
  },
  {
    type: 'team',
    name: 'Aneri Merchant',
    username: 'aneri0612',
    position: defineMessages({
      member: {
        id: 'team.aneri.position',
        defaultMessage: 'Associate',
      },
    }),
    bio: `I have the fantastic opportunity to speak and interact with developers from all across the world and get the best into Indorse.`,
    img: require(`${imagesPath}/Aneri.jpeg`),
    indorseUrl: 'https://indorse.io/aneri0612',
    // linkedInUrl: 'https://www.linkedin.com/in/arif-rahman-86785a136/',
    // twitterUrl: null,
    excludeFromLocales: ['zh-hans'],
  },
  {
    type: 'team',
    name: 'Ivo Zieliński',
    username: 'Ivozie',
    position: defineMessages({
      member: {
        id: 'team.ivo-zieliński.position',
        defaultMessage: 'Tech Lead',
      },
    }),
    bio:
      'Specializing in backend systems, cloud architectures and DLT. Proponent of clean code and simple software design.',
    img: require(`${imagesPath}/Ivo.jpg`),
    indorseUrl: 'https://indorse.io/Ivozie',
    // linkedInUrl: 'https://www.linkedin.com/in/ivo-zieli%C5%84ski-711813b3/',
    // twitterUrl: null,
    excludeFromLocales: ['zh-hans'],
  },
  // {
  //   type: 'team',
  //   name: 'Mark Le',
  //   username: 'markleup',
  //   position: defineMessages({
  //     member: {
  //       id: 'team.mark-le.position',
  //       defaultMessage: 'Senior Developer',
  //     },
  //   }),
  //   bio: `Serial technopreneur | Bachelor of Information Systems and Technopreneurship @ SMU, Singapore | Blockchain software engineer | Believer of VR and Blockchain`,
  //   img: require(`${imagesPath}/mark.jpeg`),
  //   indorseUrl: 'https://indorse.io/markleup',
  //   // linkedInUrl: 'https://www.linkedin.com/in/arif-rahman-86785a136/',
  //   // twitterUrl: null,
  //   excludeFromLocales: ['zh-hans'],
  // },
  {
    type: 'team',
    name: 'Vijay Krishnavanshi',
    username: 'vijaykrishnavanshi',
    position: defineMessages({
      member: {
        id: 'team.vijay.position',
        defaultMessage: 'Backend Engineer',
      },
    }),
    bio: `Vijay is back-end engineer responsible for development in Node.js. `,
    img: require(`${imagesPath}/vijay.jpg`),
    indorseUrl: 'https://indorse.io/vijaykrishnavanshi',
    // linkedInUrl: 'https://www.linkedin.com/in/arif-rahman-86785a136/',
    // twitterUrl: null,
    excludeFromLocales: ['zh-hans'],
  },
  // {
  //   type: 'team',
  //   name: 'Arif Rahman',
  //   username: 'arifrahman',
  //   position: defineMessages({
  //     member: {
  //       id: 'team.arif-rahman.position',
  //       defaultMessage: 'Junior Developer',
  //     },
  //   }),
  //   bio:
  //     'Arif is a Full stack developer that is responsible on working on front-end development and design aspect on the product.',
  //   img: require(`${imagesPath}/Arif.jpg`),
  //   indorseUrl: 'https://indorse.io/arifrahman',
  //   // linkedInUrl: 'https://www.linkedin.com/in/arif-rahman-86785a136/',
  //   // twitterUrl: null,
  //   excludeFromLocales: ['zh-hans'],
  // },
  {
    type: 'team',
    name: 'Mohit Bajoria',
    username: 'mbj36',
    position: defineMessages({
      member: {
        id: 'team.mohit.position',
        defaultMessage: 'Front End Engineer',
      },
    }),
    bio:
      'Mohit is a front-end engineer responsible for the development of the product. Mohit was Google Summer of Code 2017 developer and he is also an active open source contributor.',
    img: require(`${imagesPath}/mohit.gif`),
    indorseUrl: 'https://indorse.io/mbj36',
    // linkedInUrl: 'https://www.linkedin.com/in/arif-rahman-86785a136/',
    // twitterUrl: null,
    excludeFromLocales: ['zh-hans'],
  },
];
