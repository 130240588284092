import { createSelector } from 'reselect';

const selectStartAssignmentBtnState = state => state.StartAssignmentBtn;

export const selectIsFetching = createSelector(
  selectStartAssignmentBtnState,
  (state, props) => props.assignmentId,
  (assignmentBtnState, assignmentId) =>
    assignmentBtnState[assignmentId] && assignmentBtnState[assignmentId].fetching
);

export const selectError = createSelector(
  selectStartAssignmentBtnState,
  (state, props) => props.assignmentId,
  (assignmentBtnState, assignmentId) =>
    assignmentBtnState[assignmentId] && assignmentBtnState[assignmentId].error
);
