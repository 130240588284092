import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Hero from './hero';
// import BrandStory from './brand-story';
import Intro from './intro';
import WhitePaper from './white-paper';
import TeamsAndAdvisors from './teams-and-advisors';
import MediaAndPartners from './media-and-partners';
import classnames from 'classnames';
import commonStyles from './index.module.scss';
import Fade from 'react-reveal/Fade';
import { Helmet } from 'react-helmet';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'app.helmet.title.about.page',
    defaultMessage: 'Indorse - About us!',
  },
  metaDescription: {
    id: 'app.helmet.meta.description.about.page',
    defaultMessage:
      "We believe that it doesn't matter where you're from, how you look, if you're rich or poor, male or female. All that matters is your skills.",
  },
});
export class Company extends Component {
  static contextTypes = {
    parallaxController: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.context.parallaxController.update();
  }

  render() {
    const { intl: { formatMessage } } = this.props;
    return (
      <div className={classnames(commonStyles.company)}>
        <Helmet
          titleTemplate={`%s | ${formatMessage(messages.title)}`}
          defaultTitle={formatMessage(messages.title)}
        >
          <meta name="description" content={formatMessage(messages.metaDescription)} />
        </Helmet>
        <Hero />
        <Fade bottom duration={1600} distance="40px">
          <Intro />
          {/*<BrandStory />*/}
        </Fade>
        <WhitePaper />
        <Fade bottom duration={1600} distance="40px">
          <TeamsAndAdvisors />
          <MediaAndPartners />
        </Fade>
      </div>
    );
  }
}

export default injectIntl(Company);
