import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import gql from 'graphql-tag';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import { client } from 'ui/../client';

function* fetchRatingCriteriaDetails({ payload }) {
  const { ratingCriteriaKeys } = payload;
  yield put(actions.fetchRatingCriteriaDetails.start({ ratingCriteriaKeys }));

  try {
    const response = yield call(client.query, {
      query: gql`
        query getDetails($ratingCriteriaKeys: [String], $lang: EnumevaluationCriteriaLang) {
          evaluationCriteriaMany(
            filter: { _operators: { key: { in: $ratingCriteriaKeys } }, lang: $lang }
          ) {
            key
            name
            isMandatory
            tooltipDescription
          }
        }
      `,
      variables: {
        ratingCriteriaKeys,
        lang: 'en',
      },
    });

    const schema = [schemas.ratingCriterias];

    const { entities } = normalize(response.data.evaluationCriteriaMany, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchRatingCriteriaDetails.success({ ratingCriteriaKeys }));
  } catch (error) {
    yield put(
      actions.fetchRatingCriteriaDetails.failure({
        ratingCriteriaKeys: payload.ratingCriteriaKeys,
        error,
      })
    );
    // yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchRatingCriteriaDetails() {
  yield takeEvery(actionTypes.FETCH_RATING_CRITERIA_DETAILS.REQUEST, fetchRatingCriteriaDetails);
}

export default function* badgesSaga() {
  yield fork(watchFetchRatingCriteriaDetails);
}
