import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const label = defineMessages({
  [fieldNames.adminEmail]: {
    id: 'graphql.create-ems-pricing.company-admin.email',
    defaultMessage: `Email`,
  },
});

export const buttons = defineMessages({
  submitButton: {
    id: 'graphql.create-ems-pricing.submit.company',
    defaultMessage: 'Start Free Trial',
  },
});

export const content = defineMessages({
  pageTitle: {
    id: 'pages.create-ems-pricing.page-title',
    defaultMessage: 'Verify',
  },
  pageSubTitle: {
    id: 'pages.create-ems-pricing.page-sub-title',
    defaultMessage:
      'To verify your email, please check your inbox. Do not forget to check your spam folder and whitelist our email - info@indorse.io',
  },
  pageCompanies: {
    id: 'pages.create-ems-pricing.page-companies',
    defaultMessage: "You're in good company.",
  },
});

export const pricingTable = {
  subTitle: {
    id: 'pages.ems.pricingTable.graphql.subtitle',
    defaultMessage:
      '5-minute surveys laser-focused on your team’s morale & software development practices',
  },
};
