import React from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';

const feature1 =
  'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-landing-sec2-1.png';
const feature2 =
  'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-landing-sec2-2.png';
const feature3 =
  'https://indorse-staging-bucket.s3.amazonaws.com/ems-ext/ems-ext-landing-sec2-3.png';

const RenderDesktop = props => {
  return (
    <div className={classnames(styles.features)}>
      <div className="">
        <div className={styles.heading}>
          <h1 className={styles.title}>As easy as 1-2… that’s it</h1>
          <h3 className={styles.subTitle}>Start for free, no account needed</h3>
        </div>
        <div
          className={classnames(
            styles.featureList,
            'd-flex align-items-center justify-content-center'
          )}
        >
          <div className={styles.featureBox}>
            <img src={feature1} alt="feature1" width="344" />
            <div className={classnames(styles.info, 'mt-4')}>
              <h3>STEP 1</h3>
              <p>Download Metamoph’s browser extension: Chrome, Firefox, or Opera.</p>
            </div>
          </div>
          <div className="mb-5 pb-5 mx-3">
            <img src={feature2} alt="feature2" width="240" />
          </div>
          <div className={styles.featureBox}>
            <img src={feature3} alt="feature3" width="340" />
            <div className={styles.info}>
              <h3>STEP 2</h3>
              <p>
                Go to a public or private GitHub repository, and simply click on the Metamorph
                sidebar.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderMobile = props => {
  return (
    <div className={classnames(styles.features)}>
      <div className="">
        <div className={styles.heading}>
          <h1 className={styles.title}>As easy as 1-2… that’s it</h1>
          <h3 className={styles.subTitle}>Start for free, no account needed</h3>
        </div>
        <div className={classnames(styles.featureList, 'mx-auto')}>
          <div className={styles.featureBox}>
            <img src={feature1} alt="feature1" width="280" />
            <div className={styles.info}>
              <h3>STEP 1</h3>
              <p>Download Metamoph’s browser extension: Chrome, Firefox, or Opera.</p>
            </div>
          </div>
          <div className={styles.featureBox}>
            <img src={feature3} alt="feature3" width="280" />
            <div className={styles.info}>
              <h3>STEP 2</h3>
              <p>
                Go to a public or private GitHub repository, and simply click on the Metamorph
                sidebar.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const howToSection = props => {
  return (
    <div className={classnames(styles.section)}>
      <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
        <RenderDesktop />
      </MediaQuery>
      <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}>
        <RenderMobile />
      </MediaQuery>
    </div>
  );
};

export default howToSection;
