/*
	validator.js is used to validate all fields in any forms
	https://validatejs.org/#validators for all validations.
*/
export const fieldNames = {
  fullName: 'fullName',
  businessEmail: 'businessEmail',
  companyName: 'companyName',
  country: 'country',
  product: 'product',
  message: 'message',
};

export default {
  [fieldNames.fullName]: {
    presence: true,
  },
  [fieldNames.businessEmail]: {
    presence: true,
    email: true,
  },
  [fieldNames.companyName]: {
    presence: true,
  },
  [fieldNames.country]: {
    presence: true,
  },
  [fieldNames.product]: {
    presence: true,
  },
  [fieldNames.message]: {
    presence: false,
  },
};
