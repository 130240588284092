import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

const fetchUsersInput = input => ({
  pageNo: 1,
  perPage: Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  ...input,
});

export const fetchUsers = {
  request: payload => defineAction(types.FETCH_USERS.REQUEST, fetchUsersInput(payload)),
  start: payload => defineAction(types.FETCH_USERS.START, fetchUsersInput(payload)),
  success: ({ input, result }) =>
    defineAction(types.FETCH_USERS.SUCCESS, { input: fetchUsersInput(input), result }),
  failure: ({ input, error }) =>
    defineAction(types.FETCH_USERS.FAILURE, { input: fetchUsersInput(input), error }),
};
