import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import styles from './index.module.scss';
import classnames from 'classnames';
import { show as showModal } from 'redux-modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import autoBind from 'react-autobind';
import { logEvent } from 'services/amplitude-helper';
import Button from 'ui/common/button';
import { MODAL_NAME as EMS_CONTACT_MODAL } from 'ui/graphql/EmsContactForm';
import routeTemplates from 'ui/common/routes/templates';

export class Cta extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  showContactForm = () => {
    this.props.showModal(EMS_CONTACT_MODAL, { showForm: true });
  };

  renderDesktop() {
    return (
      <div>
        <h2 className={classnames('text-center', styles.title)}>The human side of technology</h2>

        <h3 className={classnames('text-center', styles.subtitle)}>
          Manage the human side of your engineering teams with one intuitive tool built for you
        </h3>
        <div className="row justify-content-center mt-4">
          <div className="col-12 col-md-6">
            <img
              src={`https://indorse-staging-bucket.s3.amazonaws.com/TechTeams.png`}
              alt="techteams"
              width="546"
              className="m-auto"
            />
          </div>
          <div className="col-12 col-md-6">
            <div className={classnames(styles.infobox)}>
              <p className={classnames(styles.info, 'pb-4')}>
                It doesn't matter whether your team is working remotely, or from the office, or
                whether you have 20 software developers or a large team, Indorse will become your
                single source of truth when it comes to performance specific to software
                development.
              </p>
              <Button
                isReady={true}
                to={routeTemplates.emsPricingPage}
                className={classnames(styles.selectBtn, 'mt-3')}
                onClick={() => logEvent('select_a_plan_ems')}
              >
                Select a plan
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        <h2 className={classnames('text-center', styles.title)}>The human side of technology</h2>

        <h3 className={classnames('text-center', styles.subtitle)}>
          Manage the human side of your engineering teams with one intuitive tool built for you
        </h3>
        <div className="row justify-content-center mt-4">
          <div className="col-11 col-md-6">
            <img
              src={`https://indorse-staging-bucket.s3.amazonaws.com/TechTeams.png`}
              alt="techteams"
              width="320"
              className="m-auto"
            />
          </div>
          <div className="col-11 col-md-6">
            <div className={classnames(styles.infobox)}>
              <div className={classnames(styles.info, 'pb-4')}>
                It doesn't matter whether your team is working remotely, or from the office, or
                whether you have 20 software developers or a large team, Indorse will become your
                single source of truth when it comes to performance specific to software
                development.
                <div>
                  <Button
                    isReady={true}
                    to={routeTemplates.emsPricingPage}
                    className={classnames(styles.selectBtn, 'mt-3')}
                    onClick={() => logEvent('select_a_plan_ems')}
                  >
                    Select a plan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className={classnames('page-max-width')}>
          <div className={classnames('container-fluid my-5 py-2')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
            </div>
            <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
              {this.renderMobile()}
            </MediaQuery>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    showModal: bindActionCreators(showModal, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(injectIntl(Cta));
