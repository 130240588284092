/*
  ClaimViewPdf
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { compose, bindActionCreators } from 'redux';
import Helmet from 'react-helmet';

import { addMessage } from 'store/features/common/flash/actions';

import { fetchUserById } from 'store/features/users/actions';
import { fetchClaimReport } from 'store/features/claim-reports/actions';
import { selectIsCurrentUserAdminOfCompanyWithId } from 'store/graphql/AdminFetchMyCompanies/selectors';
import {
  selectIsFetchingUserById,
  selectIsUserFetchedAtleastOnceById,
} from 'store/features/users/selectors';
import { selectClaimById } from 'store/features/claims/selectors';
import {
  selectClaimIdByReportId,
  selectIsFetchingClaimReport,
  selectClaimReportFetchedAtleastOnce,
} from 'store/features/claim-reports/selectors';

import { selectAIPSkills } from 'store/graphql/FetchAipSkills/selectors';
import FetchAIPSkillsReducer from 'store/graphql/FetchAipSkills/reducer';
import injectReducer from 'utils/injectReducer';

import { selectCurrentUserId, selectIsCurrentUserAdmin } from 'store/features/auth/selectors';

import { feedbackRatingTypeIdsOrder } from 'resources/feedback/ratings';

import themeConfig from 'ui/theme/config';
import ClaimReportView from 'ui/claims/report-view';

const { request: fetchUserRequest } = fetchUserById;
const { request: fetchClaimReportRequest } = fetchClaimReport;

class ClaimViewPdf extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { reportId, fetchClaimReportRequest } = this.props;

    if (reportId) fetchClaimReportRequest({ reportId });
  }

  componentDidUpdate(prevProps, prevState) {
    const { fetchUserRequest, claim, ownerId } = prevProps;

    const { ownerFetchedAtleastOnce } = this.props;

    if (ownerId && !claim.owner.name && !ownerFetchedAtleastOnce) {
      fetchUserRequest({ userId: claim.owner.id });
    }
  }

  render() {
    const {
      id,
      claim,
      ownerId,
      isCurrentUserAdmin,
      dataSets,
      noOfMinVotes,
      endorsedCount,
      flaggedCount,
      isUserCompanyAdmin,
      isClaimOwnerProfileOwner,
      totalNumberOfVotes,
    } = this.props;

    // Very important, without claim all render functions will not work
    if (!claim) return null;

    return (
      <div>
        <Helmet>
          <meta
            property="og:title"
            content="Coding Assessment Platform, Online Code Review | Indorse"
          />
          <meta
            property="og:description"
            content="The Skills Assessment Platform by programmers, for programmers. Improve your code and level up with feedback from expert developers. Get your own code review at a push of a button!"
          />
          <meta
            property="description"
            content="The Skills Assessment Platform by programmers, for programmers. Improve your code and level up with feedback from expert developers. Get your own code review at a push of a button!"
          />

          <meta
            property="twitter:title"
            content="Coding Assessment Platform, Online Code Review | Indorse"
          />
          <meta
            property="twitter:description"
            content="The Skills Assessment Platform by programmers, for programmers. Improve your code and level up with feedback from expert developers. Get your own code review at a push of a button!"
          />
          <meta
            property="twitter:site"
            content="Coding Assessment Platform, Online Code Review | Indorse"
          />
        </Helmet>
        <ClaimReportView
          id={id}
          claim={claim}
          ownerId={ownerId}
          isCurrentUserAdmin={isCurrentUserAdmin}
          dataSets={dataSets}
          noOfMinVotes={noOfMinVotes}
          endorsedCount={endorsedCount}
          flaggedCount={flaggedCount}
          isUserCompanyAdmin={isUserCompanyAdmin}
          isClaimOwnerProfileOwner={isClaimOwnerProfileOwner}
          showSocialShareButtons
          totalNumberOfVotes={totalNumberOfVotes}
        />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { match: { params: { reportId } } } = ownProps;
  const id = reportId ? selectClaimIdByReportId(state, { reportId }) : undefined;
  const aipSkills = selectAIPSkills(state);
  const claim = id ? selectClaimById(state, { id, aipSkills }) : undefined;

  const ownerId = claim && claim.owner && claim.owner.id;
  const vote = claim ? claim.vote : null;
  const currentUserId = selectCurrentUserId(state);

  const isClaimOwnerProfileOwner = ownerId === currentUserId;

  const isAssignment = claim && claim.type === 'ASSIGNMENT';

  const feedback = claim ? claim.feedback : null;
  const explanation = feedback ? feedback.explanation : null;
  const totalNumberOfVotes = claim ? claim.totalNumberOfVotes : null;

  /*
    ID-1631 noOfMinVotes, endorsedCount, flaggedCount this to ensure that
    the number and feedback are no shown in the PDF report
  */

  const noOfMinVotes =
    explanation &&
    explanation.filter(exp => {
      return exp && !exp.isCommentHidden;
    }).length;

  const isUserCompanyAdmin =
    claim &&
    claim.company &&
    claim.company.id &&
    selectIsCurrentUserAdminOfCompanyWithId(state, { companyId: claim.company.id });

  const endorsedCount =
    explanation &&
    explanation.filter(exp => {
      return exp && !exp.isCommentHidden && exp.endorsed;
    }).length;

  const flaggedCount =
    explanation &&
    explanation.filter(exp => {
      return exp && !exp.isCommentHidden && !exp.endorsed;
    }).length;

  const hasFeedback =
    feedback &&
    !(
      feedbackRatingTypeIdsOrder.every(typeId => !feedback[typeId]) &&
      (!feedback.explanation || !feedback.explanation.length)
    );

  const dataSets = [
    {
      label: 'Recommended',
      value: endorsedCount,
      color: [themeConfig.palette.category.healthA, themeConfig.palette.category.healthB],
    },
    {
      label: 'Not Recommended',
      value: flaggedCount,
      color: [themeConfig.palette.category.technologyA, themeConfig.palette.category.technologyB],
    },
  ];

  return {
    id: id,
    reportId,
    ownerId,
    fetchingClaim: id ? selectIsFetchingClaimReport(state, { id }) : false,
    claim,
    claimFetchedAtleastOnce: id ? selectClaimReportFetchedAtleastOnce(state, { id }) : false,
    vote,
    fetchingOwner: ownerId ? selectIsFetchingUserById(state, { id: ownerId }) : undefined,
    ownerFetchedAtleastOnce: ownerId
      ? selectIsUserFetchedAtleastOnceById(state, { id: ownerId })
      : undefined,
    currentUserId: selectCurrentUserId(state),
    isAssignment,
    hasFeedback,
    isCurrentUserAdmin: selectIsCurrentUserAdmin(state),
    dataSets,
    noOfMinVotes,
    endorsedCount,
    flaggedCount,
    isUserCompanyAdmin,
    isClaimOwnerProfileOwner,
    totalNumberOfVotes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addMessage: bindActionCreators(addMessage, dispatch),
    fetchUserRequest: bindActionCreators(fetchUserRequest, dispatch),
    fetchClaimReportRequest: bindActionCreators(fetchClaimReportRequest, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withFetchAIPSkillsReducer = injectReducer({
  key: 'FetchAIPSkills',
  reducer: FetchAIPSkillsReducer,
});

export default withRouter(
  compose(withFetchAIPSkillsReducer, withConnect)(injectIntl(withApollo(ClaimViewPdf)))
);
