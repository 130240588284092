import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { injectIntl } from 'react-intl';
import { unflatten } from 'flat';
import { reduxForm, FormSection, Field, Fields } from 'redux-form';

import buildModel, { fieldNames, dateYearMonthFieldNames } from './model';
import * as messages from './messages';
import FormError from 'ui/common/form/form-error';
import TextField from 'ui/common/form/fields/text-field';
import ApiAutoCompleteField from 'ui/common/form/fields/api-auto-complete-field';
import YearField from 'ui/common/form/fields/year-field';
import MonthField from 'ui/common/form/fields/month-field';
import CheckboxField from 'ui/common/form/fields/checkbox-field';
import validator from 'ui/common/form/validator';
import { autoSuggestCompanies } from 'api/config/work-experience';

const baseModel = buildModel({});

function validate(values) {
  const model = buildModel(values);

  const errors = validator(model)(values);
  return unflatten(errors);
}

class EntryForm extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  renderDateYearMonthFields({ year, month, model, parentFieldName, onChange }) {
    const { intl: { locale, formatMessage } } = this.props;

    const yearFieldFullName = `${parentFieldName}.${dateYearMonthFieldNames.year}`;
    const monthFieldFullName = `${parentFieldName}.${dateYearMonthFieldNames.month}`;

    return (
      <div className="row">
        <div className="col-md-12">
          <label>{formatMessage(messages.labels[parentFieldName])}</label>
        </div>

        <div className="col-md-6">
          <YearField
            {...year}
            name={dateYearMonthFieldNames.year}
            min={model[yearFieldFullName].numericality.greaterThanOrEqualTo}
            max={model[yearFieldFullName].numericality.lessThanOrEqualTo}
            label={formatMessage(messages.labels[yearFieldFullName])}
            onChange={onChange}
            showBlankOption
          />
        </div>
        <div className="col-md-6">
          <MonthField
            {...month}
            name={dateYearMonthFieldNames.month}
            min={model[monthFieldFullName].numericality.greaterThanOrEqualTo}
            max={model[monthFieldFullName].numericality.lessThanOrEqualTo}
            locale={locale}
            label={formatMessage(messages.labels[monthFieldFullName])}
            onChange={onChange}
            showBlankOption
          />
        </div>
      </div>
    );
  }

  renderTimePeriod({ startDate, endDate, currentlyWorking }) {
    const { intl: { formatMessage } } = this.props;
    const model = buildModel({
      startDate: startDate.input.value,
      endDate: endDate.input.value,
      currentlyWorking: currentlyWorking.input.value,
    });

    return (
      <div className="row pt-3">
        <div className="col-md-6">
          <FormSection name={startDate.input.name}>
            <Fields
              names={[dateYearMonthFieldNames.year, dateYearMonthFieldNames.month]}
              component={this.renderDateYearMonthFields}
              model={model}
              parse={Number}
              parentFieldName={startDate.input.name}
            />
          </FormSection>
          <CheckboxField
            {...currentlyWorking}
            label={formatMessage(messages.labels.currentlyWorking)}
            size="lg"
            wrapperClassName="pb-3"
          />
        </div>

        <div className="col-md-6">
          {!currentlyWorking.input.value && (
            <FormSection name={endDate.input.name}>
              <Fields
                names={[dateYearMonthFieldNames.year, dateYearMonthFieldNames.month]}
                component={this.renderDateYearMonthFields}
                model={model}
                parse={Number}
                parentFieldName={endDate.input.name}
              />
            </FormSection>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit, form: formName, intl: { formatMessage } } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormError formName={formName} />

        <div className="row">
          <div className="col-md-4">
            <Field
              name={fieldNames.title}
              component={TextField}
              label={formatMessage(messages.labels.title)}
              required={baseModel[fieldNames.title].presence}
            />
          </div>
          <div className="col-md-4">
            <Fields
              names={[fieldNames.companyName, fieldNames.companyId, fieldNames.companyNameSearch]}
              textFieldName={fieldNames.companyName}
              searchFieldName={fieldNames.companyNameSearch}
              valueFieldName={fieldNames.companyId}
              component={ApiAutoCompleteField}
              label={formatMessage(messages.labels.companyName)}
              required={baseModel[fieldNames.companyName].presence}
              apiRequestConfig={autoSuggestCompanies}
              apiResponseConfig={{
                resultsKey: 'companies',
                textKey: 'companyName',
                valueKey: 'id',
              }}
            />
          </div>
          <div className="col-md-4">
            <Field
              name={fieldNames.location}
              component={TextField}
              required={baseModel[fieldNames.location].presence}
              label={formatMessage(messages.labels.location)}
            />
          </div>
        </div>

        <div>
          <Fields
            names={[fieldNames.startDate, fieldNames.endDate, fieldNames.currentlyWorking]}
            component={this.renderTimePeriod}
          />
        </div>

        <div className="py-3">
          <Field
            name={fieldNames.description}
            component={TextField}
            multiLine
            rows={5}
            label={formatMessage(messages.labels.description)}
          />
        </div>
      </form>
    );
  }
}

export default function createForm({ form, initialValues, onSubmit }) {
  const modalSubmit = values => {
    if (!values[fieldNames.companyName]) {
      values[fieldNames.companyName] = values[fieldNames.companyNameSearch];
    }
    delete values[fieldNames.companyNameSearch];
    if (values[fieldNames.currentlyWorking]) delete values[fieldNames.endDate];
    onSubmit(values);
  };

  return reduxForm({
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
    form,
    initialValues,
    validate,
    onSubmit: modalSubmit,
  })(injectIntl(EntryForm));
}
