import React from 'react';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import autobind from 'react-autobind';
import MediaQuery from 'react-responsive';
import Slider from 'react-slick';
import SkillsGapAnalysisSVG from 'resources/page/ems/icons/skills-gap-analysis.svg';
import SkillsMatricesSVG from 'resources/page/ems/icons/skills-matrices.svg';
import ActionableInsightsSVG from 'resources/page/ems/icons/actionable-insights.svg';
import localStyles from './index.module.scss';
import themeConfig from 'ui/theme/config';

class Features extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.state = {
      currentOption: 1,
    };
  }

  onSelectBenefit(optionNo) {
    this.setState({
      currentOption: optionNo,
    });

    this._slider.slickGoTo(optionNo - 1);
  }

  renderLeftMenu() {
    const { currentOption } = this.state;

    return (
      <div className="text-left mx-3">
        <h1 className={classnames(localStyles.mainTitle, 'mb-3 font-weight-bold')}>
          Intuitive dashboard
        </h1>
        <h3 className={classnames(localStyles.subtitle, 'mb-4 pb-3')}>
          Your single source of truth when it comes to measuring the performance of software
          engineers.
        </h3>
        <ul style={{ listStyle: 'none' }}>
          <li className="mb-3">
            <div
              className="d-inline-flex d-flex align-items-center h-100 cursor-pointer"
              onClick={() => this.onSelectBenefit(1)}
            >
              <div
                className={classnames(
                  localStyles.inactive,
                  currentOption === 1 && localStyles.optionCard
                )}
              >
                <div className={localStyles.imageContainer}>
                  <img
                    src={SkillsGapAnalysisSVG}
                    alt="skillsgap"
                    width="70"
                    className="mt-1 ml-2"
                  />
                </div>
                <div className={classnames(localStyles.boldTitle, 'ml-2')}>
                  Tech performance analytics
                </div>
              </div>
            </div>
          </li>

          <li className="mb-3">
            <div
              className="d-inline-flex d-flex align-items-center h-100 cursor-pointer"
              onClick={() => this.onSelectBenefit(2)}
            >
              <div
                className={classnames(
                  localStyles.inactive,
                  currentOption === 2 && localStyles.optionCard
                )}
              >
                <div className={localStyles.imageContainer}>
                  <img src={SkillsMatricesSVG} alt="skillsgap" width="70" className="mt-1 ml-2" />
                </div>
                <div className={classnames(localStyles.boldTitle, 'ml-2')}>
                  Skills gaps overview
                </div>
              </div>
            </div>
          </li>

          <li className="mb-3">
            <div
              className="d-inline-flex d-flex align-items-center h-100 cursor-pointer"
              onClick={() => this.onSelectBenefit(3)}
            >
              <div
                className={classnames(
                  localStyles.inactive,
                  currentOption === 3 && localStyles.optionCard
                )}
              >
                <div className={localStyles.imageContainer}>
                  <img
                    src={ActionableInsightsSVG}
                    alt="skillsgap"
                    width="70"
                    className="mt-1 ml-2"
                  />
                </div>
                <div className={classnames(localStyles.boldTitle, 'ml-2')}>Actionable insights</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    );
  }

  renderRightIllustration() {
    const sliderSettings = {
      centerMode: false,
      swipeToSlide: true,
      infinite: true,
      lazyLoad: true,
      arrows: false,
      dots: false, // !TODO Looking into how to position the dots the proper way
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 10000,
      afterChange: index => this.setState({ currentOption: index + 1 }),
    };

    return (
      <div className="row">
        <div className="col-12">
          <Slider ref={slider => (this._slider = slider)} {...sliderSettings}>
            <div>
              <img
                src={
                  'https://indorse-staging-bucket.s3.amazonaws.com/Tech-Performance-Analytics-Image.png'
                }
                width="100%"
                alt="tech-performance-png"
              />
            </div>
            <div className="mt-5">
              <img
                src={'https://indorse-staging-bucket.s3.amazonaws.com/SkillsGapsOverview.png'}
                width="100%"
                alt="skills-gap-png"
              />
            </div>
            <div style={{ margin: '5em 0' }}>
              <img
                src={
                  'https://indorse-staging-bucket.s3.amazonaws.com/Actionable-Insights-Image.png'
                }
                width="100%"
                alt="actionable-insights-png"
              />
            </div>
          </Slider>
        </div>
      </div>
    );
  }

  // renderCustomers() {
  //   return (
  //     <div className="mt-5 pt-1">
  //       <h2 className="text-center mb-5">Companies that trust Indorse</h2>
  //       <ExistingCustomers />
  //     </div>
  //   );
  // }

  renderDesktop() {
    return (
      <div className="container mt-4">
        <div
          className={classnames(
            'row d-flex align-items-stretch h-100 align-items-center',
            localStyles.minHeightDesktop
          )}
        >
          <div className="col-6">{this.renderLeftMenu()}</div>
          <div className="col-6">{this.renderRightIllustration()}</div>
        </div>
      </div>
    );
  }

  renderIpad() {
    return (
      <div className="container mt-4">
        <div className="row d-flex align-items-stretch h-100 align-items-center">
          <div className="col-12">{this.renderLeftMenu()}</div>
          <div className="col-12">{this.renderRightIllustration()}</div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div className="container mt-4">
        <div className="row d-flex align-items-stretch h-100 align-items-center">
          <div className="col-12">{this.renderLeftMenu()}</div>
          <div className="col-12">{this.renderRightIllustration()}</div>
          {/* <div className="col-12">{this.renderCustomers()}</div> */}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(localStyles.blueBackground)} id="predict-control-attrition">
        <div className={classnames('pt-4')}>
          <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
            {this.renderDesktop()}
          </MediaQuery>
          <MediaQuery
            minWidth={themeConfig.layout.gridBreakpoints.sm}
            maxWidth={themeConfig.layout.gridBreakpoints.lg - 1}
          >
            {this.renderIpad()}
          </MediaQuery>
          <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
            {this.renderMobile()}
          </MediaQuery>
        </div>
      </div>
    );
  }
}

export default injectIntl(Features);
