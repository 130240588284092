import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

export const fetchClaimPrice = {
  start: ({ claimId }) => defineAction(types.FETCH_CLAIM_REPORT_PRICE.START, { claimId }),
  success: ({ claimId, result }) =>
    defineAction(types.FETCH_CLAIM_REPORT_PRICE.SUCCESS, { claimId, result }),
  failure: ({ claimId, error }) =>
    defineAction(types.FETCH_CLAIM_REPORT_PRICE.FAILURE, { claimId, error }),
};

export const payClaimPrice = {
  request: ({ claimId }) => defineAction(types.PAY_FOR_CLAIM_REPORT.REQUEST, { claimId }),
  start: ({ claimId, paymentInfo }) =>
    defineAction(types.PAY_FOR_CLAIM_REPORT.START, { claimId, paymentInfo }),
  success: ({ claimId, result }) =>
    defineAction(types.PAY_FOR_CLAIM_REPORT.SUCCESS, { claimId, result }),
  failure: ({ claimId, error }) =>
    defineAction(types.PAY_FOR_CLAIM_REPORT.FAILURE, { claimId, error }),
};
