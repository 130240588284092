import { createSelector } from 'reselect';

const selectClaimantPercentile = state => state.getClaimantPercentile;

export const selectError = createSelector(selectClaimantPercentile, (state, props) => state.error);

export const selectClaimantPercentileDetails = createSelector(
  selectClaimantPercentile,
  (state, props) => state.claimantPercentile
);

export const selectIsFetching = createSelector(selectClaimantPercentile, state => state.fetching);
