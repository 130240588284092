import React from 'react';
import Card from 'ui/common/rebranding/components/Card/Card';

export default ({ id, className, children, ...passThrough }) => {
  return (
    <Card id={id} className={className} {...passThrough}>
      {children}
    </Card>
  );
};
