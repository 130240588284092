/*
	validator.js is used to validate all fields in any forms
	https://validatejs.org/#validators for all validations.
*/
export const fieldNames = {
  url: 'url',
  participants: 'participants',
  name: 'name',
  email: 'email',
};

export default {
  [fieldNames.url]: {
    presence: true,
    url: true,
  },
  [fieldNames.name]: {
    presence: true,
  },
  [fieldNames.email]: {
    presence: true,
    email: true,
  },
};
