import React from 'react';
import classnames from 'classnames';
import commonStyles from './index.module.scss';
import { connect } from 'react-redux';

import Hero from './hero';
// import Steps from './steps';
// import Features from './features';
import Cta from './cta';
import ExistingCustomers from 'ui/pages/home-V2/existing-customers/loadable';
// import Specialities from './specialities';
import Stats from './stats';
import HowItWorks from './how-it-works';
import { logEvent, logUserProperties } from 'services/amplitude-helper';
import addEvent from 'utils/exit-intent';
import { show as showModal } from 'redux-modal';
import ExitIntentModal, { MODAL_NAME as EXIT_INTENT_MODAL } from 'ui/pages/ems/exit-intent-modal';
// import { MODAL_NAME as EXIT_INTENT_MODAL } from 'ui/pages/ems/exit-intent-modal';
import MetamorphHelmet from './metamorph-helmet';

class EMS extends React.Component {
  clearExitIntent() {
    localStorage.setItem('exitIntent', 'false');
  }

  componentDidMount() {
    this.clearExitIntent();
    addEvent(evt => {
      if (evt.toElement == null && evt.relatedTarget == null) {
        this.props.showModal(EXIT_INTENT_MODAL);
      }
    });
    const query = new URLSearchParams(this.props.location.search);
    const utm_source = query.get('utm_source');
    const utm_content = query.get('utm_content');

    const ampIns = window.amplitude.getInstance();

    if (window.localStorage.getItem('email')) {
      ampIns.setUserId(window.localStorage.getItem('email'));
    } else if (!window.localStorage.getItem('deviceId')) {
      // create random deviceId
      const randomDeviceId = Math.random()
        .toString(36)
        .substr(2, 9);
      window.localStorage.setItem('deviceId', randomDeviceId);
      ampIns.setDeviceId(randomDeviceId);
      if (utm_source) window.localStorage.setItem('utm_source', utm_source);
    } else {
      ampIns.setDeviceId(window.localStorage.getItem('deviceId'));
    }

    // if we have utm_source
    if (utm_source) {
      logUserProperties({ utm_source });
    }

    // if we have utm_content
    if (utm_content) {
      logUserProperties({ utm_content });
    }
    logEvent('landed_on_ems', {
      source: window && window.location.pathname,
    });
  }
  render() {
    return (
      <div className={classnames(commonStyles.home)}>
        <MetamorphHelmet />
        <Hero />
        <h2 className={classnames(commonStyles.trust)}>Companies that trust us</h2>
        <ExistingCustomers autoplay={false} dots={true} />
        <HowItWorks />
        {/* <Specialities /> */}
        <Stats />
        {/* <Features /> */}
        <Cta />
        <ExitIntentModal />
      </div>
    );
  }
}
const mapDispatchToProps = {
  showModal,
};

export default connect(null, mapDispatchToProps)(EMS);
