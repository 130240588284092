import Immutable from 'seamless-immutable';
import { combineReducers } from 'redux';

import * as actionTypes from './action-types';

const initialOperationState = Immutable({});

const defaultDataAccessor = payload => payload.result;

const defaultErrorAccessor = payload => payload.error;

function createOperationReducer(
  operationActionTypes,
  dataAccessor = defaultDataAccessor,
  errorAccessor = defaultErrorAccessor
) {
  return function(state = initialOperationState, action) {
    switch (action.type) {
      case operationActionTypes.START: {
        return state.merge({ [action.payload.claimId]: { inProgress: true } }, { deep: true });
      }

      case operationActionTypes.SUCCESS: {
        return state.merge(
          { [action.payload.claimId]: { inProgress: false, data: dataAccessor(action.payload) } },
          { deep: true }
        );
      }

      case operationActionTypes.FAILURE: {
        return state.merge(
          { [action.payload.claimId]: { inProgress: false, error: errorAccessor(action.payload) } },
          { deep: true }
        );
      }

      default:
        return state;
    }
  };
}

export default combineReducers({
  fetchPrice: createOperationReducer(actionTypes.FETCH_CLAIM_REPORT_PRICE),
  pay: createOperationReducer(actionTypes.PAY_FOR_CLAIM_REPORT),
});
