import { call, fork, put, takeEvery, select, take, race } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { startSubmit, stopSubmit } from 'redux-form';
import { show as showModal, hide as hideModal } from 'redux-modal';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as wizardActions from 'store/features/wizard/actions';
import * as selectors from './selectors';
import * as api from 'api/config/education';
import callApi from 'store/api/saga';
import * as authSelectors from 'store/features/auth/selectors';
import { messageTypes } from 'store/features/common/flash/builder';
import { addMessage } from 'store/features/common/flash/actions';
import messages from 'ui/education/messages';
import { MODAL_NAME as DELETE_EDUCATION_ENTRY_CONFIRMATION_MODAL_NAME } from 'ui/education/delete/confirmation-dialog';

function* addEducationEntry({ payload: values, meta }) {
  const { form, modal, wizard } = meta;
  yield put(actions.addEducationEntry.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.addEducationEntry(values));
    yield put(actions.addEducationEntry.success(response));
    if (form) yield put(stopSubmit(form));
    if (modal) yield put(hideModal(modal));

    const currentUserId = yield select(authSelectors.selectCurrentUserId);
    const schema = { education: schemas.educationEntry };
    if (response.education) {
      response.education.userId = currentUserId;
    }
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    if (!wizard) {
      yield put(
        addMessage({
          id: 'education-entry-operation',
          kind: messageTypes.success,
          content: messages.entryAdded,
        })
      );
    }
    if (wizard) {
      const { name, apiConfig } = wizard;
      yield put(wizardActions.completeStep.request({ name, apiConfig }));
    }
  } catch (error) {
    yield put(actions.addEducationEntry.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchAddEducationEntry() {
  yield takeEvery(actionTypes.ADD_EDUCATION_ENTRY.REQUEST, addEducationEntry);
}

function* updateEducationEntry({ payload: values, meta }) {
  const { form, modal } = meta;
  yield put(actions.updateEducationEntry.start(values));
  if (form) yield put(startSubmit(form));

  try {
    const response = yield call(callApi, api.updateEducationEntry(values));
    yield put(actions.updateEducationEntry.success(response));
    if (form) yield put(stopSubmit(form));
    if (modal) yield put(hideModal(modal));

    const schema = { education: schemas.educationEntry };
    if (response.education && !response.education.userId) {
      const currentUserId = yield select(authSelectors.selectCurrentUserId);
      response.education.userId = currentUserId;
    }
    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities, { [schema.education.key]: { deep: false } }));
    yield put(
      addMessage({
        id: 'education-entry-operation',
        kind: messageTypes.success,
        content: messages.entryUpdated,
      })
    );
  } catch (error) {
    yield put(actions.updateEducationEntry.failure(error));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchUpdateEducationEntry() {
  yield takeEvery(actionTypes.UPDATE_EDUCATION_ENTRY.REQUEST, updateEducationEntry);
}

function* askDeleteEducationEntryConfirmation({ id }) {
  const modalName = DELETE_EDUCATION_ENTRY_CONFIRMATION_MODAL_NAME(id);
  yield put(showModal(modalName));
  const { yes } = yield race({
    yes: take(
      action =>
        action.type === actionTypes.DELETE_EDUCATION_ENTRY.CONFIRM &&
        action.payload &&
        action.payload.id === id
    ),
    no: take(
      action =>
        action.type === actionTypes.DELETE_EDUCATION_ENTRY.CANCEL &&
        action.payload &&
        action.payload.id === id
    ),
  });
  yield put(hideModal(modalName));
  return !!yes;
}

function* deleteEducationEntry({ payload: values, meta }) {
  const { id } = values;
  const confirmed = yield call(askDeleteEducationEntryConfirmation, { id });
  if (!confirmed) {
    return;
  }

  const { modal } = meta;
  yield put(actions.deleteEducationEntry.start(values));

  try {
    yield call(callApi, api.deleteEducationEntry(values));
    const entry = yield select(selectors.selectEducationEntryById, { id });
    yield put(actions.deleteEducationEntry.success({ id, education: entry }));
    if (modal) yield put(hideModal(modal));
    yield put(
      addMessage({
        id: 'education-entry-operation',
        kind: messageTypes.success,
        content: messages.entryDeleted,
      })
    );
  } catch (error) {
    yield put(actions.deleteEducationEntry.failure(error));
  }
}

function* watchDeleteEducationEntry() {
  yield takeEvery(actionTypes.DELETE_EDUCATION_ENTRY.REQUEST, deleteEducationEntry);
}

export default function* education() {
  yield fork(watchAddEducationEntry);
  yield fork(watchUpdateEducationEntry);
  yield fork(watchDeleteEducationEntry);
}
