import * as types from './action-types';

import { defineAction } from 'store/common/action-helpers';

const fetchSkillsInput = input => ({
  pageNo: 1,
  perPage: Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  ...input,
});

export const fetchSkills = {
  request: payload => defineAction(types.FETCH_SKILLS.REQUEST, fetchSkillsInput(payload)),
  start: payload => defineAction(types.FETCH_SKILLS.START, fetchSkillsInput(payload)),
  success: ({ input, result }) =>
    defineAction(types.FETCH_SKILLS.SUCCESS, { input: fetchSkillsInput(input), result }),
  failure: ({ input, error }) =>
    defineAction(types.FETCH_SKILLS.FAILURE, { input: fetchSkillsInput(input), error }),
};

export const createSkill = {
  request: (values, form) => defineAction(types.CREATE_SKILL.REQUEST, values, { form }),
  start: values => defineAction(types.CREATE_SKILL.START, values),
  success: response => defineAction(types.CREATE_SKILL.SUCCESS, response),
  failure: error => defineAction(types.CREATE_SKILL.FAILURE, error),
};

export const deleteSkill = {
  request: id => defineAction(types.DELETE_SKILL.REQUEST, id),
  start: id => defineAction(types.DELETE_SKILL.START, id),
  success: response => defineAction(types.DELETE_SKILL.SUCCESS, response),
  failure: error => defineAction(types.DELETE_SKILL.FAILURE, error),
};
