import { votingRound as schema } from 'store/common/schemas';
import { createEntitiesReducer, createEntitiesSelector } from './helpers';

import {
  SET_VOTING_ROUND_STATUSES,
  SET_VOTING_ROUND_STATUS,
} from 'store/features/voting-rounds/action-types';

export const reducer = createEntitiesReducer(schema, (state, action) => {
  switch (action.type) {
    case SET_VOTING_ROUND_STATUSES:
      const { statusesById } = action.payload;
      const patch = Object.keys(statusesById).reduce((res, id) => {
        res[id] = { status: statusesById[id], statusUpdatedAt: action.payload.statusUpdatedAt };
        return res;
      }, {});
      return state.merge(patch, { deep: true });
    case SET_VOTING_ROUND_STATUS:
      const statusVotingRound = {};
      statusVotingRound[action.payload.votingRoundId] = {
        status: action.payload.status,
        statusUpdatedAt: action.payload.statusUpdatedAt,
      };
      return state.merge(statusVotingRound, { deep: true });
    default:
      return state;
  }
});

export const selector = createEntitiesSelector(schema);
