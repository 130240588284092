import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/users';
import callApi from 'store/api/saga';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';

function* fetchUsers({ payload }) {
  yield put(actions.fetchUsers.start(payload));

  try {
    const response = yield call(callApi, api.fetchUsers(payload));
    const schema = { users: [schemas.user] };
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));

    yield put(actions.fetchUsers.success({ input: payload, result }));
  } catch (error) {
    yield put(actions.fetchUsers.failure({ input: payload, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchUsers() {
  yield takeEvery(actionTypes.FETCH_USERS.REQUEST, fetchUsers);
}

export default function* users() {
  yield fork(watchFetchUsers);
}
