import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import Button from 'ui/common/button';

export const ConfirmationDialog = props => {
  const { isOpen, toggle, onSubmit, loading, message } = props;

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle()}>Hide comment</ModalHeader>
      <ModalBody>
        <div>{message}</div>
      </ModalBody>
      <ModalFooter className="float-right">
        <Button onClick={onSubmit()} isReady={false} loading={loading}>
          Confirm
        </Button>
        <Button color="danger" onClick={toggle()} isReady={false}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  message: PropTypes.string,
};

export default ConfirmationDialog;
