export function getInstance(...args) {
  if (window.amplitude) {
    return window.amplitude.getInstance(...args);
  } else {
    return null;
  }
}

function logToConsoleInDevelopment(method, ...rest) {
  if (process.env.NODE_ENV === 'development') {
    console.log('amp', method, ...rest);
  }
}

export function logEvent(...args) {
  const ampInstance = getInstance();
  if (ampInstance) ampInstance.logEvent(...args);
  logToConsoleInDevelopment('logEvent', args);
}

export function logUserProperties(...args) {
  const ampInstance = getInstance();
  if (ampInstance) ampInstance.setUserProperties(...args);
  logToConsoleInDevelopment('logUserProperties', args);
}
