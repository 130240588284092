import React, { Component } from 'react';
import { connect } from 'react-redux';
import routeTemplates from 'ui/common/routes/templates';

import Loading from 'ui/common/loading';
import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';
import gql from 'graphql-tag';
import { messageTypes } from 'store/features/common/flash/builder';
import { bindActionCreators } from 'redux';

import { resultHasErrors, errorsToSingleMessage } from 'utils/graphql-errors';
import routeGenerators from 'ui/common/routes/generators';

import {
  selectIsUpdating,
  selectError,
  selectPrettyId,
} from 'store/graphql/MetamorphLoginCallback/selectors';
import * as actionTypes from 'store/graphql/MetamorphLoginCallback/action-types';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import MetamorphLoginCallbackReducer from 'store/graphql/MetamorphLoginCallback/reducer';
import Button from 'ui/common/button';
import { withRouter } from 'react-router-dom';
import { fetchCurrentUser } from '../../../store/features/auth/actions';
import { selectCurrentUser, selectIsLoggedIn } from '../../../store/features/auth/selectors';

const { request: fetchCurrentUserRequest } = fetchCurrentUser;
export class MetamorphLoginCallback extends Component {
  componentDidMount() {
    if (this.props.loggedIn) {
      this.createEmsCompany();
    } else {
      this.props.fetchCurrentUserRequest();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loggedIn !== this.props.loggedIn) {
      this.createEmsCompany();
    }
  }

  async createEmsCompany() {
    const {
      client,
      addMessage,
      createEmsCompanyStart,
      createEmsCompanySuccess,
      createEmsCompanyError,
    } = this.props;
    // console.log('starting to create company - google signup : ', Date.now());
    createEmsCompanyStart();
    try {
      const source = localStorage.getItem('ems_social_login_with');
      // console.log('source', source);
      const result = await client.mutate({
        mutation: gql`
          mutation emsCompany($createIfNotExist: Boolean!, $source: String) {
            emsCompany(createIfNotExist: $createIfNotExist, source: $source) {
              status
              company {
                id
                prettyId
                email
              }
              companyAdminEmail
            }
          }
        `,
        variables: {
          createIfNotExist: true,
          source,
        },
      });
      const { data, errors } = result;
      localStorage.setItem('ems_social_login_with', 'unknown');
      if (resultHasErrors({ errors })) {
        addMessage({
          kind: messageTypes.danger,
          content: errorsToSingleMessage({ errors }),
        });
      } else {
        const { emsCompany } = data;
        const { status, company, companyAdminEmail } = emsCompany;
        if (status === 'SAME_DOMAIN_DIFFERENT_EMAIL_COMPANY_EXISTS_LOGGED_IN') {
          this.props.history.push(
            routeTemplates.emsLandingPage + `?already_exists=true&admin_email=${companyAdminEmail}`
          );
        } else if (company && company.prettyId) {
          createEmsCompanySuccess(company.prettyId);
          this.props.history.push(
            routeGenerators.companies.onBoard({
              prettyId: company.prettyId,
            })
          );
        }
      }
    } catch (error) {
      createEmsCompanyError(error);
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
    }
  }

  render() {
    const { updating, error, prettyId } = this.props;

    if (error)
      return (
        <div className="text-center">
          <h1>{error}</h1>
          <Button to={routeTemplates.emsLandingPage} isReady>
            Go Back
          </Button>
        </div>
      );

    if (updating) return <Loading />;

    return <div>{prettyId && <Loading />}</div>;
  }
}

function mapStateToProps(state) {
  const prettyId = selectPrettyId(state);
  const error = selectError(state);
  const updating = selectIsUpdating(state);

  return {
    prettyId,
    error,
    updating,
    loggedIn: selectIsLoggedIn(state),
    currentUser: selectCurrentUser(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentUserRequest: bindActionCreators(fetchCurrentUserRequest, dispatch),
    createEmsCompanyStart: () =>
      dispatch({ type: actionTypes.METAMORPH_LOGIN_CREATE_COMPANY.START }),
    createEmsCompanySuccess: prettyId =>
      dispatch({ type: actionTypes.METAMORPH_LOGIN_CREATE_COMPANY.SUCCESS, payload: { prettyId } }),
    createEmsCompanyError: error =>
      dispatch({ type: actionTypes.METAMORPH_LOGIN_CREATE_COMPANY.FAILURE, payload: { error } }),
    // push: (routeName, locationState) => dispatch(push(routeName, locationState)),
    addMessage: bindActionCreators(addMessage, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({
  key: 'MetamorphLoginCallback',
  reducer: MetamorphLoginCallbackReducer,
});

export default withRouter(compose(withReducer, withConnect)(withApollo(MetamorphLoginCallback)));
