import React from 'react';
import { connect } from 'react-redux';

import { withApollo } from 'react-apollo';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import PercentileChart from 'ui/common/charts/percentile-chart';
import injectReducer from 'utils/injectReducer';

import { resultHasErrors } from 'utils/graphql-errors';
import getPercentileGraphReducer from 'store/graphql/ClaimantPercentileGraph/reducer';

import { selectClaimantPercentileDetails } from 'store/graphql/ClaimantPercentileGraph/selectors';

import { getClaimantPercentile } from 'store/graphql/ClaimantPercentileGraph/actions';

import gql from 'graphql-tag';

const {
  start: getClaimantPercentileStart,
  success: getClaimantPercentileSuccess,
  failure: getClaimantPercentileFailure,
} = getClaimantPercentile;
class PercentileCurve extends React.Component {
  componentDidMount() {
    const { claim } = this.props;
    if (claim) {
      this.getPercentileGraph(claim);
    }
  }

  async getPercentileGraph(claim) {
    const { id } = claim;

    const {
      client,
      getClaimantPercentileStart,
      getClaimantPercentileSuccess,
      getClaimantPercentileFailure,
    } = this.props;
    getClaimantPercentileStart();
    try {
      const result = await client.query({
        query: gql`
          query getClaimantPercentileGraph($claimId: String!) {
            getClaimantPercentileGraph(claimId: $claimId) {
              x
              y
              claimantScore
              claimantPercentile
            }
          }
        `,
        variables: {
          claimId: id,
        },
      });
      if (resultHasErrors(result)) {
        getClaimantPercentileFailure({ errors: result.errors });
      } else {
        const { getClaimantPercentileGraph } = result.data;
        getClaimantPercentileSuccess(getClaimantPercentileGraph);
      }
    } catch (error) {
      getClaimantPercentileFailure(error);
    }
  }

  render() {
    const { claimantPercentileDetails, showBottomText } = this.props;
    const { x, y, claimantScore, claimantPercentile } = claimantPercentileDetails || {};

    return (
      <div>
        {claimantPercentileDetails && (
          <span>
            <div className="col-12 text-center pt-2">
              <FormattedMessage
                id="graphql.ClaimViewPdf.pdfViewMessages.curvePercentile"
                defaultMessage="How do this Candidate's skills compare?"
              />
            </div>
            <div className="col-12 offset-sm-1" style={{ marginTop: '5%' }}>
              <PercentileChart x={x} y={y} score={claimantScore} percentile={claimantPercentile} />
            </div>
            <div>
              {showBottomText && (
                <div className="text-center text-muted">
                  <FormattedHTMLMessage
                    id="id.graphql.show.percentile."
                    defaultMessage="This candidate's code is better than <b>{percentage}%</b> of all the code evaluated on Indorse"
                    values={{
                      percentage: Math.round(claimantPercentile),
                    }}
                  />
                </div>
              )}
            </div>
          </span>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    claimantPercentileDetails: selectClaimantPercentileDetails(state),
  };
}

const mapDispatchToProps = {
  getClaimantPercentileStart,
  getClaimantPercentileSuccess,
  getClaimantPercentileFailure,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({
  key: 'getClaimantPercentile',
  reducer: getPercentileGraphReducer,
});

export default withRouter(
  compose(withReducer, withConnect)(injectIntl(withApollo(PercentileCurve)))
);
