import { defineMessages } from 'react-intl';

export default defineMessages({
  created: {
    id: 'claims.messages.create',
    defaultMessage: 'Claim created successfully',
  },
  registration: {
    id: 'claims.messages.registration',
    defaultMessage: 'Pending registrations',
  },
  voting: {
    id: 'claims.messages.voting',
    defaultMessage: 'Pending votes',
  },
  tallying: {
    id: 'claims.messages.tallying',
    defaultMessage: 'Tallying',
  },
  endorsed: {
    id: 'claims.messages.endorsed',
    defaultMessage: 'Indorsed',
  },
  flagged: {
    id: 'claims.messages.flagged',
    defaultMessage: 'Flagged',
  },
  tentativelyIndorsed: {
    id: 'claims.messages.tentativelyIndorsed',
    defaultMessage: 'Tentatively Indorsed',
  },
  tentativelyFlagged: {
    id: 'claims.messages.tentativelyFlagged',
    defaultMessage: 'Tentatively Flagged',
  },
  unverified: {
    id: 'claims.messages.unverified',
    defaultMessage: 'No consensus',
  },
  pendingApprovalFromAdmin: {
    id: 'claims.messages.pendingApprovalFromAdmin',
    defaultMessage: 'Pending',
  },
  disapprovedByAdmin: {
    id: 'claims.messages.disapprovedByAdmin',
    defaultMessage: 'Disqualified',
  },
  queuedForRelease: {
    id: 'claims.messages.queuedForRelease',
    defaultMessage: 'Queued for Release',
  },
  adminCanReOpenClaim: {
    id: 'claims.messages.adminCanReOpenClaim',
    defaultMessage: 'No consensus',
  },
});
