import createHash from 'create-hash';

// export function sha256Async(message) {
//   if (!crypto || !crypto.subtle) throw new Error('This feature is not supported by your browser');

//   const msgBuffer = new TextEncoder('utf-8').encode(message);

//   // hash the message
//   return crypto.subtle.digest('SHA-256', msgBuffer).then(hashBuffer => {
//     // convert ArrayBuffer to Array
//     const hashArray = Array.from(new Uint8Array(hashBuffer));

//     // convert bytes to hex string
//     const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
//     return hashHex;
//   });
// }

export function sha256(message) {
  const hash = createHash('sha256')
    .update(message)
    .digest()
    .toString('hex');

  return `0x${hash}`;
}
