import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import routeTemplates from 'ui/common/routes/templates';

import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import Flash from 'ui/common/flash';

import Logo from 'ui/common/rebranding/components/Logo';
import PropTypes from 'prop-types';

const Layout = ({
  title,
  children,
  colClass = 'col-12 col-md-8 col-lg-6 col-xl-5',
  showFooter = true,
  footerContent,
  standalone = true,
  bgBig = false,
  bgMobile = false,
}) => (
  <div className={classnames('container-fluid')}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <div className={styles.flashWrapper}>
      <Flash className="container mt-3" />
    </div>
    <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.md}>
      <div className={classnames('row position-relative', styles.pageContent)}>
        <div
          className={classnames(
            bgBig ? 'col-md-6' : 'col-md-3',
            bgBig ? styles.bgImageBig : styles.bgImageSmall,
            'position-fixed'
          )}
        >
          <Link to={routeTemplates.root}>
            <div className={classnames(styles.logo)}>
              <Logo />
            </div>
          </Link>
        </div>
        <div className={classnames(bgBig ? 'col-md-6 offset-md-6' : 'col-md-9 offset-md-3')}>
          {children}
        </div>
      </div>
    </MediaQuery>
    <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.md - 1}>
      {bgBig ? (
        <div className={classnames(styles.bgMobile)}>
          <Link to={routeTemplates.root}>
            <div className={classnames(styles.logo)}>
              <Logo />
            </div>
          </Link>
        </div>
      ) : (
        <Link to={routeTemplates.root}>
          <div className={classnames(styles.logo)}>
            <Logo />
          </div>
        </Link>
      )}
      {children}
    </MediaQuery>
  </div>
);

Layout.displayName = 'AuthLayout';

Layout.propTypes = {
  title: PropTypes.string,
  colClass: PropTypes.string,
  showFooter: PropTypes.bool,
  footerContent: PropTypes.element,
  standalone: PropTypes.bool,
  bgBig: PropTypes.bool,
};
export default Layout;
