import { combineReducers } from 'redux';

import users from './users/reducer';
import companies from './companies/reducer';
import skills from './skills/reducer';
import badges from './badges/reducer';
import claims from './claims/reducer';

export default combineReducers({
  users,
  companies,
  skills,
  badges,
  claims,
});
