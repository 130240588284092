import React, { Component } from 'react';
import { TweenMax, TimelineMax } from 'gsap/TweenMax';

import './svg-styles.scss';

export class AnimatedBackground extends Component {
  componentDidMount() {
    /* --- GSAP TweenMax --- */
    const tl = new TimelineMax({ onComplete: restartLine, onCompleteParams: ['loop-start'] }),
      duration3 = '1',
      duration4 = '1.2',
      delay3 = '1.2',
      easingOut = 'Power2.easeIn',
      easingIn = 'Power4.easeOut';

    TweenMax.set('#c50-purple, #c50-orange, #c50-purple, #s-green', { opacity: '0', scale: '0' });
    /* make svg invisible  */

    TweenMax.set('#c50-purple .mask', { drawSVG: '0% 100%', transformOrigin: '50% 50%' });
    /* initial setting half circle masks */

    TweenMax.set('#c50-orange .mask', {
      drawSVG: '75% 75%',
      rotation: -90,
      transformOrigin: '50% 50%',
    });
    /* initial setting half circle masks */

    TweenMax.set('#line-square path, #line-circle path, #line-diagon path', { drawSVG: '0% 0%' });
    /* initial setting lines */

    TweenMax.set('#t-pink, #t-yellow', { scale: '0', opacity: '0', transformOrigin: '50% 50%' });
    /* initial setting triangles */

    /* step-1 */
    tl
      .to('#t-pink', duration3, { scale: '1', opacity: '1', ease: easingIn }, 'intro') // in
      .to('#line-square path', duration4, { drawSVG: '0% 100%', ease: easingIn }, 'intro') // in
      .to('#line-square', duration4, { opacity: '1', ease: easingIn }, 'intro') // in
      .to('#line-circle path', duration3, { drawSVG: '0% 100%', ease: easingIn }, 'intro') // in
      .to('#line-circle', duration3, { opacity: '1', ease: easingIn }, 'intro') // in
      .to('#c50-orange .mask', duration4, { drawSVG: '0% 0%', ease: easingIn }, 'intro') // in
      .to('#c50-orange', duration4, { opacity: '1', scale: '1', ease: easingIn }, 'intro') // in
      .to(
        '#line-square path',
        duration4,
        { drawSVG: '0% 0%', ease: easingOut, delay: delay3 },
        'loop-start'
      ) // out
      .to('#s-green', duration3, { opacity: '1', scale: '1', ease: easingIn }, 's-green-in') // in
      .to('#t-pink', duration3, { scale: '0', opacity: '0', ease: easingOut }) // out
      .to(
        '#c50-purple .mask',
        duration3,
        { drawSVG: '25% 100%', ease: easingIn, delay: delay3 },
        'c-purple-in'
      ) // in
      .to(
        '#c50-purple',
        duration3,
        { opacity: '1', scale: '1', ease: easingIn, delay: delay3 },
        'c-purple-in'
      ) // in
      .to('#c50-orange .mask', duration4, { drawSVG: '0% 100%', ease: easingOut }, 'c-orange-out') // out
      .to('#c50-orange', duration4, { opacity: '0', ease: easingOut }, 'c-orange-out') // out
      .to('#line-diagon path', duration4, { drawSVG: '0% 100%', ease: easingIn }, 'line-diagon-in') // in
      .to('#line-diagon', duration4, { opacity: '1', ease: easingIn }, 'line-diagon-in') // in
      .to('#line-circle path', duration3, { drawSVG: '0% 0%', ease: easingOut }, 'line-circle-out') // out
      .to('#t-yellow', duration3, { scale: '1', opacity: '1', ease: easingIn }, 't-yellow-in') // in
      .to(
        '#s-green',
        duration3,
        { opacity: '0', scale: '0', ease: easingOut, delay: delay3 },
        's-green-out'
      ) // out
      .to('#c50-orange .mask', duration4, { drawSVG: '0% 0%', ease: easingIn }, 'c-orange-in') // in
      .to('#c50-orange', duration4, { opacity: '1', scale: '1', ease: easingIn }, 'c-orange-in') // in
      .to(
        '#c50-purple .mask',
        duration3,
        { drawSVG: '0% 100%', ease: easingOut, delay: delay3 },
        'c-purple-out'
      ) // out
      .to(
        '#c50-purple',
        duration3,
        { opacity: '0', ease: easingOut, delay: delay3 },
        'c-purple-out'
      ) // out
      .to('#t-pink', duration3, { scale: '1', opacity: '1', ease: easingIn }) // in
      .to('#line-diagon path', duration4, { drawSVG: '0% 0%', ease: easingOut }, 'line-diagon-out') // out
      .to('#line-diagon', duration4, { opacity: '0', ease: easingOut }, 'line-diagon-out') // out
      .to('#line-square path', duration4, { drawSVG: '0% 100%', ease: easingIn }, 'line-square-in') // in
      .to('#line-square', duration4, { opacity: '1', ease: easingIn }, 'line-square-in') // in
      .to('#t-yellow', duration3, { scale: '0', opacity: '0', ease: easingOut }) // out
      .to('#line-circle path', duration3, { drawSVG: '0% 100%', ease: easingIn }, 'line-circle-in') // in
      .to('#line-circle', duration3, { opacity: '1', ease: easingIn }, 'line-circle-in'); // in

    //restart the timeline at a given position.
    function restartLine(position) {
      tl.play(position);
    }

    /* --- GSAP timeline controls --- */
    //GSDevTools.create();
  }

  render() {
    return (
      <div id="scene" className="hero-container">
        <svg
          id="c50-purple"
          className="circle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <linearGradient
            id="c50-purple-gradient"
            gradientUnits="userSpaceOnUse"
            x1="57.5"
            y1="116"
            x2="57.5"
            y2="1"
            gradientTransform="matrix(1 0 0 -1 0 116)"
          >
            <stop offset="0" stopColor="#9FA1FF" />
            <stop offset="0.31" stopColor="#9F9EFF" />
            <stop offset="0.52" stopColor="#A095FE" />
            <stop offset="0.7" stopColor="#A286FC" />
            <stop offset="0.87" stopColor="#A471FA" />
            <stop offset="1" stopColor="#A659F7" />
          </linearGradient>
          <circle fill="url(#c50-purple-gradient)" cx="57.5" cy="57.5" r="57.5" />
          <circle
            className="mask"
            fill="none"
            stroke="#fff"
            strokeWidth="57.5"
            strokeMiterlimit="10"
            cx="57.5"
            cy="57.5"
            r="29"
          />
        </svg>
        <svg
          id="c50-orange"
          className="circle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <linearGradient
            id="c50-orange-gradient"
            gradientUnits="userSpaceOnUse"
            x1="57.5"
            y1="116"
            x2="57.5"
            y2="1"
            gradientTransform="matrix(1 0 0 -1 0 116)"
          >
            <stop offset="0" stopColor="#f9b300" />
            <stop offset="0.4" stopColor="#f9b101" />
            <stop offset="0.6" stopColor="#f9a903" />
            <stop offset="0.75" stopColor="#f89c07" />
            <stop offset="0.87" stopColor="#f88a0c" />
            <stop offset="0.98" stopColor="#f77213" />
            <stop offset="1" stopColor="#f76d15" />
          </linearGradient>
          <circle fill="url(#c50-orange-gradient)" cx="57.5" cy="57.5" r="57.5" />
          {/* <circle
            className="mask"
            fill="none"
            stroke="#fff"
            strokeWidth="57.5"
            strokeMiterlimit="10"
            cx="57.5"
            cy="57.5"
            r="29"
          /> */}
        </svg>
        <svg
          id="s-green"
          className="square"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <linearGradient
            id="s-green-gradient"
            gradientUnits="userSpaceOnUse"
            x1="0"
            y1="58.5"
            x2="115"
            y2="58.5"
            gradientTransform="matrix(1 0 0 -1 0 116)"
          >
            <stop offset="0" stopColor="#00B1A4" />
            <stop offset="0.5" stopColor="#01B1A6" />
            <stop offset="0.73" stopColor="#06B0AE" />
            <stop offset="0.91" stopColor="#0EB0BB" />
            <stop offset="1" stopColor="#14AFC4" />
          </linearGradient>
          <rect width="115" height="115" fill="url(#s-green-gradient)" />
        </svg>
        <svg
          id="t-yellow"
          className="triangle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <linearGradient
            id="t-yellow-gradient"
            gradientUnits="userSpaceOnUse"
            x1="11251.9805"
            y1="-4755.52"
            x2="11366.9805"
            y2="-4755.52"
            gradientTransform="matrix(1 0 0 -1 -11251.9805 -4698.02)"
          >
            <stop offset="0" stopColor="#FAE112" />
            <stop offset="0.41" stopColor="#F9E80D" />
            <stop offset="1" stopColor="#F7FB01" />
          </linearGradient>
          <polygon className="path" points="0,115 115,0 0,0 " fill="url(#t-yellow-gradient)" />
        </svg>
        <svg
          id="t-pink"
          className="triangle"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <linearGradient
            id="t-pink-gradient"
            gradientUnits="userSpaceOnUse"
            x1="11251.9805"
            y1="-4755.52"
            x2="11366.9805"
            y2="-4755.52"
            gradientTransform="matrix(1 0 0 -1 -11251.9805 -4698.02)"
          >
            <stop offset="0" stopColor="#f81e8e" />
            <stop offset=".38" stopColor="#f82090" />
            <stop offset=".58" stopColor="#f82896" />
            <stop offset=".73" stopColor="#f935a1" />
            <stop offset=".86" stopColor="#fa48b1" />
            <stop offset=".98" stopColor="#fb60c4" />
            <stop offset="1" stopColor="#fb66c9" />
          </linearGradient>
          <polygon className="path" points="0,115 115,0 0,0 " fill="url(#t-pink-gradient)" />
        </svg>
        <svg
          id="line-square"
          className="line"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <path className="path" d="M113 115V2H0" />
        </svg>
        <svg
          id="line-circle"
          className="line"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <path className="path" d="M2,115C2,52.6,52.6,2,115,2" />
        </svg>
        <svg
          id="line-diagon"
          className="line"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 115 115"
        >
          <path className="path" d="M-1.3,1.3l115,115" />
        </svg>
      </div>
    );
  }
}

export default AnimatedBackground;
