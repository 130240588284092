import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import axios from 'axios';
import styles from './index.module.scss';
import Loading from 'ui/common/loading';
import ExternalLink from 'ui/common/external-link';

class JsonIPFS extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
    this.state = { data: null };
  }

  componentDidMount() {
    this.RESTJsonIPFS();
  }

  RESTJsonIPFS() {
    const backendURL = process.env.REACT_APP_API_ROOT;
    const getMerkleRootsSchema = 'ifs/merkle';

    try {
      axios({
        url: `${backendURL}${getMerkleRootsSchema}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          this.setState({ data: response.data });
        })
        .catch(err => {
          console.log(err.message);
        });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { data } = this.state;
    return (
      <div className="container py-5">
        <div className={classnames('row px-3 justify-content-center')}>
          <div className="col-12 col-sm-8 col-md-10">
            <h1>Merkle Trees in IPFS</h1>
            <h3>
              You can also view this data on the{' '}
              <ExternalLink
                href="https://api.ipfsbrowser.com/ipfs/get.php?hash=QmXdLjnrCqk27gptxsjhcLRR6AtWztWWueDCvWw4BqRVtz"
                openInNewTab
              >
                IPFS browser.
              </ExternalLink>
            </h3>
            {!data && <Loading />}
            {data && (
              <div className={classnames(styles.codeContainer)}>
                <pre className={classnames(styles.json)}>
                  {data && JSON.stringify(data, null, 2)}
                </pre>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(JsonIPFS);
