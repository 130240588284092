import Immutable from 'seamless-immutable';
import * as actionTypes from './action-types';
import * as startAssignmentActionTypes from 'store/graphql/StartAssignmentBtn/action-types';

const initialState = Immutable({
  assignment: {},
  submitting: false,
  fetching: false,
  error: null,
  refetchAssignment: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ASSIGNMENT.START: {
      return state.merge({ fetching: true });
    }

    case startAssignmentActionTypes.START_ASSIGNMENT.SUCCESS:
      const { result } = action.payload;
      return state.merge({
        fetching: false,
        assignment: {
          ...state.assignment,
          lastViewerAssignment: result.lastViewerAssignment,
        },
        error: null,
      });

    case actionTypes.GET_ASSIGNMENT.SUCCESS: {
      const { assignment } = action.payload;

      return state.merge({
        fetching: false,
        assignment,
        error: null,
      });
    }

    case actionTypes.GET_ASSIGNMENT.FAILURE: {
      const { error } = action.payload;

      return state.merge({
        fetching: false,
        error,
      });
    }

    case actionTypes.FINISH_ASSIGNMENT.START: {
      return state.merge({ submitting: true });
    }

    case actionTypes.FINISH_ASSIGNMENT.SUCCESS: {
      const { assignment } = action.payload;

      return state.merge({ assignment, submitting: false, error: null });
    }

    case actionTypes.FINISH_ASSIGNMENT.FAILURE: {
      const { error } = action.payload;
      return state.merge({
        submitting: false,
        error,
      });
    }

    default:
      return state;
  }
}
