import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/badges';
import callApi from 'store/api/saga';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';

function* fetchBadgeByPrettyId({ payload }) {
  const { prettyId } = payload;
  yield put(actions.fetchBadgeByPrettyId.start({ prettyId }));

  try {
    const response = yield call(callApi, api.fetchBadgeByPrettyId({ prettyId }));
    if (!response.prettyId) {
      response['prettyId'] = response.id;
    }
    const schema = schemas.badge;

    const { entities } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));
    yield put(actions.fetchBadgeByPrettyId.success({ prettyId }));
  } catch (error) {
    yield put(actions.fetchBadgeByPrettyId.failure({ prettyId, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchBadgeByPrettyId() {
  yield takeEvery(actionTypes.FETCH_BADGE_BY_PRETTY_ID.REQUEST, fetchBadgeByPrettyId);
}

export default function* badgesSaga() {
  yield fork(watchFetchBadgeByPrettyId);
}
