import { call, put, takeEvery, fork } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/claims';
import adminClaimsMessages from 'ui/admin/claims/messages';
import callApi from 'store/api/saga';
import { fetchClaim } from 'store/features/claims/actions';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import { hide as hideModal, show as showModal } from 'redux-modal';
import { MODAL_NAME as ADMIN_CLAIM_DISAPPROVE_MODAL } from 'ui/admin/claims/AdminClaimDisapproveModal';
import { MODAL_NAME as ADMIN_CLAIM_FORCE_APPROVE_MODAL } from 'ui/admin/claims/AdminClaimForceApproveModal';

function* fetchClaims({ payload }) {
  yield put(actions.fetchClaims.start(payload));

  try {
    const response = yield call(callApi, api.fetchClaims(payload));
    response.claims = response.claims.map(claim => {
      claim.voteCounts = claim.votes;
      delete claim.votes;
      return claim;
    });

    const schema = { claims: [schemas.claim] };
    const { entities, result } = normalize(response, schema);
    yield put(entityActions.mergeEntities(entities));

    yield put(actions.fetchClaims.success({ input: payload, result }));
  } catch (error) {
    yield put(actions.fetchClaims.failure({ input: payload, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchFetchClaims() {
  yield takeEvery(actionTypes.FETCH_CLAIMS.REQUEST, fetchClaims);
}

function* approveClaim({ payload }) {
  const { claimId, force } = payload;

  yield put(actions.approveClaim.start(payload));

  try {
    yield call(callApi, api.approveClaim({ claimId, force }));
    yield put(actions.approveClaim.success({ claimId, force }));
    yield put(hideModal(ADMIN_CLAIM_FORCE_APPROVE_MODAL));
    yield put(
      addMessage({ kind: messageTypes.success, content: adminClaimsMessages.forceApproveSuccess })
    );

    yield put(fetchClaim.request({ claimId }));
  } catch (error) {
    if (error && error.warning) {
      yield put(actions.disapproveClaim.failure({ claimId, error }));
      yield put(showModal(ADMIN_CLAIM_FORCE_APPROVE_MODAL));
    }
    yield put(actions.approveClaim.failure({ claimId, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchApproveClaim() {
  yield takeEvery(actionTypes.APPROVE_CLAIM.REQUEST, approveClaim);
}

function* disapproveClaim({ payload }) {
  const { claimId, disapprovalReason } = payload;

  yield put(actions.disapproveClaim.start(payload));

  try {
    yield call(callApi, api.disapproveClaim({ claimId, disapprovalReason }));
    yield put(actions.disapproveClaim.success({ claimId }));
    yield put(hideModal(ADMIN_CLAIM_DISAPPROVE_MODAL));
    yield put(fetchClaim.request({ claimId }));
  } catch (error) {
    yield put(actions.disapproveClaim.failure({ claimId, error }));
    yield put(addMessage({ kind: messageTypes.danger, content: error.message }));
  }
}

function* watchDisapproveClaim() {
  yield takeEvery(actionTypes.DISAPPROVE_CLAIM.REQUEST, disapproveClaim);
}

export default function* users() {
  yield fork(watchFetchClaims);
  yield fork(watchApproveClaim);
  yield fork(watchDisapproveClaim);
}
