/*
 * AdminClaimDisapproveModal Messages
 *
 * This contains all the text for the AdminClaimDisapproveModal component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  downloadCSVButton: {
    id: 'admin.claims.downloadCSVButton',
    defaultMessage: 'Retrieve Validators Payment CSV',
  },
  creditLow: {
    id: 'admin.claims.creditLow',
    defaultMessage: 'The claim credit limit is low, Please top up.',
  },
  forceApproveSuccess: {
    id: 'admin.claims.forceApproveSuccess',
    defaultMessage: 'You have successfully approved the claim.',
  },
});

export const linkDetails = defineMessages({
  accessible: {
    id: 'admin.claims.accessible',
    defaultMessage: 'Link is accessible',
  },
  notAccessible: {
    id: 'admin.claims.notAccessible',
    defaultMessage: 'Link is NOT accessible',
  },
  forked: {
    id: 'admin.claims.forked',
    defaultMessage: 'Forked repo. Click to visit the original repo',
  },
  language: {
    id: 'admin.claims.language',
    defaultMessage: 'Programming language',
  },
  authCommits: {
    id: 'admin.claims.authCommits',
    defaultMessage: 'Number of authenticated commits',
  },
  unAuthCommits: {
    id: 'admin.claims.unAuthCommits',
    defaultMessage: 'Number of unauthenticated commits',
  },
  ownerCommits: {
    id: 'admin.claims.ownerCommits',
    defaultMessage: 'Commits by owner',
  },
  totalCommits: {
    id: 'admin.claims.totalCommits',
    defaultMessage: 'Total commits',
  },
  duplicateClaim: {
    id: 'admin.claims.duplicateClaim',
    defaultMessage: 'Duplicate claim',
  },
});
