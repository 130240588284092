import { defineApiActionTypes } from 'store/common/action-helpers';

const prefix = 'features/wizard';

export const FETCH_WIZARD_DEFINITION = defineApiActionTypes(`${prefix}/FETCH_WIZARD_DEFINITION`);
export const FETCH_WIZARD_STATUS = defineApiActionTypes(`${prefix}/FETCH_WIZARD_STATUS`);
export const COMPLETE_STEP = defineApiActionTypes(`${prefix}/COMPLETE_STEP`);
export const FINISH_WIZARD = defineApiActionTypes(`${prefix}/FINISH_WIZARD`);
export const WIZARD_FINISHED = `${prefix}/WIZARD_FINISHED`;
export const SKIP_STEP = defineApiActionTypes(`${prefix}/SKIP_STEP`);
