import Immutable from 'seamless-immutable';

import * as actionTypes from './action-types';

const initialState = Immutable({
  data: null,
  rootEmployeeList: null,
  fetching: false,
  updating: false,
  error: false,

  proficiencyLevelList: null,
  fetchingProficiencyLevelList: false,
  updatingProficiencyLevelList: false,
  errorProficiencyLevelList: false,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_EMS_ORG_ROOT.START: {
      return state.set('fetching', true);
    }

    case actionTypes.FETCH_EMS_ORG_ROOT.FAILURE: {
      const { error } = action.payload;
      return state
        .set('fetching', false)
        .set('error', error)
        .set('rootEmployeeList', []);
    }

    case actionTypes.FETCH_EMS_ORG_ROOT.SUCCESS: {
      const { data } = action.payload;
      return state
        .set('fetching', false)
        .set('error', false)
        .set('rootEmployeeList', data);
    }

    case actionTypes.FETCH_EMS_PROFICIENCY_LIST.START: {
      return state.set('fetchingProficiencyLevelList', true);
    }

    case actionTypes.FETCH_EMS_PROFICIENCY_LIST.FAILURE: {
      const { error } = action.payload;
      return state
        .set('fetchingProficiencyLevelList', false)
        .set('errorProficiencyLevelList', error)
        .set('proficiencyLevelList', []);
    }

    case actionTypes.FETCH_EMS_PROFICIENCY_LIST.SUCCESS: {
      const { data } = action.payload;
      return state
        .set('fetchingProficiencyLevelList', false)
        .set('errorProficiencyLevelList', false)
        .set('proficiencyLevelList', data);
    }

    case actionTypes.UPDATE_EMS_ORG_ROOT.START: {
      return state.set('updating', true);
    }

    case actionTypes.UPDATE_EMS_ORG_ROOT.FAILURE: {
      const { error } = action.payload;
      return state.set('updating', false).set('error', error);
    }

    case actionTypes.UPDATE_EMS_ORG_ROOT.SUCCESS: {
      return state.set('updating', false).set('error', false);
    }

    case actionTypes.UPDATE_EMS_PROFICIENCY_LIST.START: {
      return state.set('updatingProficiencyLevelList', true);
    }

    case actionTypes.UPDATE_EMS_PROFICIENCY_LIST.FAILURE: {
      const { error } = action.payload;
      return state
        .set('updatingProficiencyLevelList', false)
        .set('errorProficiencyLevelList', error);
    }

    case actionTypes.UPDATE_EMS_PROFICIENCY_LIST.SUCCESS: {
      return state
        .set('updatingProficiencyLevelList', false)
        .set('errorProficiencyLevelList', false);
    }
    default:
      return state;
  }
}
