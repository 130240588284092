import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Tooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import autoBind from 'react-autobind';
import { withApollo } from 'react-apollo';
import { addMessage } from 'store/features/common/flash/actions';
import PayForClaimReportButton from 'ui/graphql/PayForClaimReportButton';
import RatingCriteriaTextHelper from 'ui/components/rating-criteria-text-helper/loadable';

import ContentLoader from 'react-content-loader';
import routeGenerators from 'ui/common/routes/generators';

import Rating from 'react-rating';
import Icon from 'ui/common/icon';
import RadarChart from 'ui/common/charts/radar-chart';

import { Table } from 'reactstrap';
import { selectIsCurrentUserAdmin, selectCurrentUserId } from 'store/features/auth/selectors';

import AdminVoteValidatorCommentButton from 'ui/graphql/AdminVoteValidatorCommentButton/loadable';
import { voteActionTypes } from 'ui/graphql/AdminVoteValidatorCommentButton';

import { selectRatingCriteriaEntities } from 'store/entities/selectors';
import * as messages from './messages';
import ExternalLink from 'ui/common/external-link';
import moment from 'moment';
import EditFeedback from './edit-feedback';
import AceEditor from 'react-ace';
import ReactQuill from 'react-quill';
// // add png for print-friendly pdf...
// import blueSquare from 'resources/common/blue-squre.svg';
// import orangeCirclePNG from 'resources/common/orange-circle.png';
// import purpleTrianglePNG from 'resources/common/purple-triangle.png';

export class Feedback extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isEditingFeedback: {},
      isEditingImprovements: {},
    };
  }
  static propTypes = {
    feedback: PropTypes.shape({
      quality: PropTypes.number,
      designPatterns: PropTypes.number,
      gitFlow: PropTypes.number,
      readability: PropTypes.number,
      extensibility: PropTypes.number,
      completion: PropTypes.number,
      testCoverage: PropTypes.number,
      startCommentIndex: PropTypes.number,
      endCommentIndex: PropTypes.number,
      explanation: PropTypes.arrayOf(
        PropTypes.shape({
          feedback: PropTypes.string,
          endorsed: PropTypes.bool,
          noOfUpvote: PropTypes.number,
          noOfDownvote: PropTypes.number,
          isCommentHidden: PropTypes.bool,
          isAdminVoteOnComment: PropTypes.bool,
          voteId: PropTypes.string,
          voteHiddenUsername: PropTypes.string,
          flaggedReasonType: PropTypes.string,
        })
      ),
    }),
    showOnly: PropTypes.oneOf(['starRatings', 'textFeedback']),
    isPrintView: PropTypes.bool,
  };

  editFeedback(id) {
    const { isEditingFeedback } = this.state;
    const isEditing = !!isEditingFeedback[id];
    this.setState({
      isEditingFeedback: {
        [id]: !isEditing,
      },
    });
  }

  editImprovements(id) {
    const { isEditingImprovements } = this.state;
    const isEditing = !!isEditingImprovements[id];
    this.setState({
      isEditingImprovements: {
        [id]: !isEditing,
      },
    });
  }

  renderFeedbackCommentsSection() {
    const {
      isCurrentUserAdmin,
      feedback,
      claim,
      showOnly,
      routeParams,
      isClaimOwnedByProfileOwner,
      intl: { formatMessage },
      startCommentIndex,
      endCommentIndex,
    } = this.props;

    const { isEditingFeedback, isEditingImprovements } = this.state;

    // ID-1453 currently only admin can see this section. Backend already checks
    // if current user is admin before retuning the `explanation` field.
    // => FE does not need to check if user is admin anymore. Thanks Ivo

    // ID-1470, add this in to render text feedback section only.
    if (showOnly && showOnly !== 'textFeedback') return null;

    if (feedback && feedback.explanation && feedback.explanation.length) {
      const { explanation, hiddenFeedbackCount } = feedback;

      return (
        <div className="col-12 px-sm-0 mx-0">
          <Table>
            <thead>
              <tr>
                <th className="text-uppercase">
                  {formatMessage(messages.feedbackSectionMessages.feedback)}
                </th>
                <th className="text-uppercase">
                  {formatMessage(messages.feedbackSectionMessages.voted)}
                </th>
                {isCurrentUserAdmin &&
                  routeParams &&
                  !routeParams.print && (
                    <th className={classnames(styles.tableHeader, 'text-uppercase')}>
                      {formatMessage(messages.feedbackSectionMessages.hideBy)}
                    </th>
                  )}
                {isCurrentUserAdmin &&
                  routeParams &&
                  !routeParams.print && (
                    <th className="text-uppercase w-25">
                      {formatMessage(messages.feedbackSectionMessages.actions)}
                    </th>
                  )}
              </tr>
            </thead>
            <tbody>
              {explanation
                .filter(comment => (routeParams.print ? !comment.isCommentHidden : comment))
                .map((exp, index) => {
                  if (exp && exp.isCommentHidden && !isCurrentUserAdmin) return null;

                  // for PDF, show first 5 comments in first page,
                  // last 5 comments on second page

                  if (
                    startCommentIndex > -1 &&
                    endCommentIndex > -1 &&
                    (index < startCommentIndex || index >= endCommentIndex)
                  )
                    return null;

                  if (
                    exp &&
                    exp.isCommentHidden &&
                    isCurrentUserAdmin &&
                    routeParams &&
                    routeParams.print
                  )
                    return null;

                  return (
                    <tr
                      key={index}
                      className={classnames(styles.tableTr, {
                        'table-danger':
                          exp.flaggedReasonType === 'plagiarism' &&
                          claim.flaggedReasonType['plagiarism'] > 1,
                      })}
                    >
                      <td>
                        {exp.suggestedImprovements &&
                          exp.suggestedImprovements.length && (
                            <p className="font-weight-bold">
                              {formatMessage(messages.content.explanationTitle)}
                            </p>
                          )}
                        <p
                          className={classnames('gray', styles.niceWordBreak)}
                          onDoubleClick={() => this.editFeedback(exp.voterUserId)}
                        >
                          {exp.feedback}
                        </p>
                        {isCurrentUserAdmin &&
                          exp.plagiarisedFromLinks &&
                          exp.plagiarisedFromLinks.length && (
                            <p className={classnames('gray', styles.niceWordBreak)}>
                              Link to original repo:{' '}
                              {exp.plagiarisedFromLinks.map(link => (
                                <ExternalLink key={link} href={link} openInNewTab>
                                  {link}
                                </ExternalLink>
                              ))}
                            </p>
                          )}

                        {isCurrentUserAdmin &&
                          !routeParams.print &&
                          isEditingFeedback[exp.voterUserId] && (
                            <EditFeedback
                              initialValues={{ feedback: exp.feedback }}
                              exp={exp}
                              claim={claim}
                            />
                          )}

                        {/* ID-1743 - Split long comments to two shorter comments: What do you like and any suggestions to improve*/
                        exp.suggestedImprovements &&
                          exp.suggestedImprovements.length && (
                            <p>
                              <p className="font-weight-bold">
                                {formatMessage(messages.content.suggestedImprovementsTitle)}
                              </p>
                              <p
                                onDoubleClick={() => this.editImprovements(exp.voterUserId)}
                                className={classnames('gray', styles.niceWordBreak)}
                              >
                                {exp.suggestedImprovements}
                              </p>
                            </p>
                          )}

                        {isCurrentUserAdmin &&
                          !routeParams.print &&
                          isEditingImprovements[exp.voterUserId] && (
                            <EditFeedback
                              initialValues={{ suggestedImprovements: exp.suggestedImprovements }}
                              exp={exp}
                              claim={claim}
                            />
                          )}

                        {exp &&
                          exp.voterUsername &&
                          isCurrentUserAdmin &&
                          !routeParams.print && (
                            <span className="text-muted">
                              {' '}
                              <small>
                                <a
                                  href={`${routeGenerators.admin.validators.details({
                                    validator_id: exp.voterUserId,
                                  })}`}
                                >
                                  {exp.voterUsername}
                                </a>
                              </small>{' '}
                              &nbsp;{' '}
                              <small>
                                {exp.timeTakenForFeedback && exp.timeTakenForFeedback + `min`}
                              </small>{' '}
                              &nbsp;
                              <small>{moment(exp.votedAt * 1000).format('lll')}</small>
                            </span>
                          )}
                      </td>
                      <td>
                        <span className="gray">
                          {exp.endorsed && formatMessage(messages.feedbackSectionMessages.indorsed)}

                          {!exp.endorsed &&
                            !exp.flaggedReasonType &&
                            formatMessage(messages.feedbackSectionMessages.flagged)}
                          {!exp.endorsed &&
                            exp.flaggedReasonType &&
                            formatMessage(messages.feedbackSectionMessages.reported)}
                        </span>
                      </td>
                      {isCurrentUserAdmin &&
                        exp &&
                        exp.voteHiddenUsername &&
                        routeParams &&
                        !routeParams.print && (
                          <td>
                            <span className="text-danger">{exp.voteHiddenUsername}</span>
                          </td>
                        )}

                      {isCurrentUserAdmin &&
                        exp &&
                        !exp.voteHiddenUsername &&
                        routeParams &&
                        !routeParams.print && <td />}

                      {isCurrentUserAdmin &&
                        exp &&
                        exp.voteId &&
                        routeParams &&
                        !routeParams.print && (
                          <td className="w-25">
                            <ul className="list-inlind">
                              <li className="list-inline-item pr-3">
                                <AdminVoteValidatorCommentButton
                                  claim={claim}
                                  value={exp.noOfDownvote}
                                  disabled={exp.isAdminVoteOnComment}
                                  voteId={exp.voteId}
                                  voteType={voteActionTypes.DOWN_VOTE}
                                  mergeWithEntites
                                />
                              </li>
                              <li className="list-inline-item ">
                                <AdminVoteValidatorCommentButton
                                  claim={claim}
                                  value={exp.noOfUpvote}
                                  disabled={exp.isAdminVoteOnComment}
                                  voteId={exp.voteId}
                                  voteType={voteActionTypes.UP_VOTE}
                                  mergeWithEntites
                                />
                              </li>
                              <li className="list-inline-item ">
                                <AdminVoteValidatorCommentButton
                                  claim={claim}
                                  disabled={exp.isCommentHidden}
                                  voteId={exp.voteId}
                                  voteType={voteActionTypes.HIDE}
                                  dialogMessage={formatMessage(messages.dialog.confirm)}
                                  mergeWithEntites
                                />
                              </li>
                            </ul>
                          </td>
                        )}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {isClaimOwnedByProfileOwner &&
            (hiddenFeedbackCount > 0 && (
              <div className="pb-5">
                <ContentLoader
                  style={{ width: '100%' }}
                  height={400}
                  width={2200}
                  speed={1}
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                >
                  <rect x="0" y="20" rx="0" ry="0" width="2200" height="400" />
                </ContentLoader>
                <div className={styles.report}>
                  {routeParams &&
                    !routeParams.print && (
                      <span>
                        <Icon name="fa fa-lock fa-lg fa-3x" className="pb-3" />
                        <br />
                        <PayForClaimReportButton claimId={claim.id} />
                      </span>
                    )}
                </div>
              </div>
            ))}
        </div>
      );
    }
  }

  tooltipToggle(feedbackRatingTypeId) {
    this.setState({
      [`isTooltipOpen_${feedbackRatingTypeId}`]: !this.state[
        `isTooltipOpen_${feedbackRatingTypeId}`
      ],
    });
  }

  renderStarRatings() {
    const {
      intl: { formatMessage },
      feedback,
      routeParams,
      showOnly,
      feedbackRatingTypeIdsOrder,
    } = this.props;

    // ID-1470, add this in to render star ratings section only.
    if (showOnly && showOnly !== 'starRatings') return null;
    return (
      <div className={classnames(routeParams && routeParams.print ? 'col-12' : 'col-12 col-lg-6')}>
        <h3 className="pb-4 font-weight-bold">
          {formatMessage(messages.content.feedbackSubtitle)}
        </h3>

        <ul>
          {feedbackRatingTypeIdsOrder
            .map(feedbackRatingTypeId => {
              const rating = feedback[feedbackRatingTypeId];

              if (rating > 0) {
                return (
                  <li key={feedbackRatingTypeId} className="d-flex align-items-center pb-3">
                    <p className="font-weight-bold w-50">
                      <RatingCriteriaTextHelper
                        ratingCriteriaKey={feedbackRatingTypeId}
                        fieldToReturn="name"
                      />
                      {routeParams &&
                        routeParams.print !== 'true' && (
                          <span id={`${feedbackRatingTypeId}-tooltip`} className="ml-2">
                            <Icon name="fa fa-question" background />
                            <Tooltip
                              placement="right"
                              target={`${feedbackRatingTypeId}-tooltip`}
                              isOpen={this.state[`isTooltipOpen_${feedbackRatingTypeId}`]}
                              toggle={() => this.tooltipToggle(feedbackRatingTypeId)}
                              autohide={false}
                            >
                              <div className="text-left">
                                <strong>{formatMessage(messages.content.toolTipsHeader)}</strong>
                                <RatingCriteriaTextHelper
                                  ratingCriteriaKey={feedbackRatingTypeId}
                                  fieldToReturn="tooltipDescription"
                                />
                              </div>
                            </Tooltip>
                          </span>
                        )}
                    </p>

                    <div className="w-auto d-flex ">
                      <b className="align-self-center pr-2">:</b>
                      <Rating readonly initialRating={rating} />
                    </div>
                  </li>
                );
              } else {
                return null;
              }
            })
            .filter(Boolean)}
        </ul>
      </div>
    );
  }

  renderStarRatingsPDF() {
    const { feedback, showOnly, feedbackRatingTypeIdsOrder, ratingCriteriaEntities } = this.props;
    // ID-1470, add this in to render star ratings section only.
    if (showOnly && showOnly !== 'starRatings') return null;

    if (!Object.keys(ratingCriteriaEntities.asMutable()).length) return null;

    const labels = feedbackRatingTypeIdsOrder
      .asMutable()
      .map(feedbackRatingTypeId => {
        const rating = feedback[feedbackRatingTypeId];
        if (rating && rating > 0) return ratingCriteriaEntities[feedbackRatingTypeId].name;
        return null;
      })
      .filter(item => !!item);

    const dataPoints = feedbackRatingTypeIdsOrder
      .asMutable()
      .map(feedbackRatingTypeId => {
        const rating = feedback[feedbackRatingTypeId];
        if (rating && rating > 0) return rating;
        return null;
      })
      .filter(item => !!item);

    return (
      <div className="col-12">
        <div className="row justify-content-center">
          <div className="offset-col-2 col-8">
            <RadarChart labels={labels} dataPoints={dataPoints} />
          </div>
        </div>
      </div>
    );
  }

  renderDescription(description) {
    if (description.type === 'CODE') {
      return (
        <AceEditor
          value={description.content}
          readOnly={true}
          // minLines={5}
          maxLines={50}
          mode="javascript"
          theme="github"
          showGutter={true}
          width="100%"
        />
      );
    } else {
      return (
        <div className={classnames('my-1', styles.htmlEditor)}>
          <ReactQuill
            className={styles.quillEditor}
            theme="snow"
            readOnly={true}
            modules={{ toolbar: false }}
            value={description.content}
          />
        </div>
      );
    }
  }

  renderAnswer(type, answer) {
    if (type === 'CODE') {
      return (
        <AceEditor
          value={answer.answerString}
          readOnly={true}
          // minLines={5}
          maxLines={50}
          mode="javascript"
          theme="github"
          showGutter={true}
          width="100%"
        />
      );
    } else {
      // return <div className={styles.answerBox}>{answer.answerString}</div>;
      return (
        <div className={classnames('my-1', styles.htmlEditor)}>
          <ReactQuill
            className={styles.quillEditor}
            theme="snow"
            readOnly={true}
            modules={{ toolbar: false }}
            value={answer.answerString}
          />
        </div>
      );
    }
  }

  getSubmittedAnswerById(questionId) {
    const { claim } = this.props;
    const caseStudyAssignment = claim && claim.caseStudyAssignment;

    return (
      caseStudyAssignment &&
      caseStudyAssignment.caseStudyAssignmentSubmission &&
      caseStudyAssignment.caseStudyAssignmentSubmission.submission.filter(
        each_sub => questionId === each_sub.questionId
      )
    );
  }

  getQuestionById(questionId) {
    const { claim } = this.props;
    const caseStudyAssignmentQuestions =
      claim &&
      claim.caseStudyAssignment &&
      claim.caseStudyAssignment.caseStudyAssignmentReport &&
      claim.caseStudyAssignment.caseStudyAssignmentReport.questions;
    const found = caseStudyAssignmentQuestions.filter(question => question.id === questionId);

    return found;
  }

  renderValidatorFeedbackByQuestionId(questionId) {
    const { feedback } = this.props;
    const feedbackArr = feedback[questionId].feedbackArray;

    return (
      <div className={styles.commentSection}>
        {feedbackArr &&
          feedbackArr.map(feed => {
            // console.log('[Feedback] : ', feed);
            const date = new Date(feed.votedAt * 1000);
            let formatted_date =
              date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
            return (
              <div key={feed.voteId} className={classnames(styles.feedback, 'mt-2')}>
                <p>
                  <span className={styles.validator}>@{feed.voter.username}</span>{' '}
                  <span className={styles.date}>{formatted_date}</span>
                </p>
                <div className="mt-3">
                  <p>
                    <span className={styles.label}>Rating:</span> {feed.rating}
                  </p>
                  <hr />
                  <p>
                    <span className={styles.label}>Comment:</span> <br />
                    <br />
                    {feed.comment}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  renderEachQuestionFeedback(questionId) {
    let question = this.getQuestionById(questionId);
    question = question.length > 0 ? question[0] : null;
    if (!question) return null;

    // console.log('[Question] : ', question);
    let userAnswer = this.getSubmittedAnswerById(questionId);
    userAnswer = userAnswer.length > 0 ? userAnswer[0] : null;

    return (
      <div key={questionId} className={styles.questionBox}>
        <h3 className={styles.primaryTitle}>
          Question <span className="mt-2">{question.order}. </span>
        </h3>

        <div className={styles.userAnswerBox}>
          <div className="ml-2">{this.renderDescription(question.description)}</div>
          {question.suggestedSolution && (
            <div className="m-4">
              <h3 className={styles.secondaryTitle}>Suggested Solution</h3>
              <div className={styles.solution}>
                {this.renderDescription(question.suggestedSolution)}
              </div>
            </div>
          )}
          {userAnswer && (
            <div className="m-4">
              <h3 className={styles.secondaryTitle}>Candidate's Response</h3>
              <div className={styles.solution}>
                {this.renderAnswer(question.answer.type, userAnswer)}
              </div>
            </div>
          )}
        </div>
        <div>
          <h3>Feedback</h3>
          <div className={styles.validatorFeedback}>
            {this.renderValidatorFeedbackByQuestionId(questionId)}
          </div>
        </div>
      </div>
    );
  }

  renderCaseStudyFeedback() {
    const { feedback, intl: { formatMessage } } = this.props;
    const questionIds = Object.keys(feedback);

    return (
      <div className={'col-12'}>
        <h3 className="pb-4 font-weight-bold">
          Case Study {formatMessage(messages.content.feedbackSubtitle)}
        </h3>
        <div>{questionIds.map(id => this.renderEachQuestionFeedback(id))}</div>
      </div>
    );
  }

  render() {
    const { className, id, routeParams, isCaseStudyFeedback } = this.props;
    if (isCaseStudyFeedback) {
      return (
        <div id={id} className={className}>
          {this.renderCaseStudyFeedback()}
        </div>
      );
    }

    return (
      <div id={id} className={className}>
        <div className="row">
          {routeParams.print ? this.renderStarRatingsPDF() : this.renderStarRatings()}
        </div>
        <div className="row">{this.renderFeedbackCommentsSection()}</div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const query = new URLSearchParams(ownProps.location.search);
  const routeParams = { print: query.get('print') || ownProps.isPrintView };

  const explanation = ownProps && ownProps.feedback && ownProps.feedback.explanation;
  const isCaseStudyFeedback = ownProps && ownProps.claim && ownProps.claim.type === 'CASE_STUDY';

  const currentUserId = selectCurrentUserId(state);

  const claimOwnerId =
    ownProps && ownProps.claim && ownProps.claim.owner && ownProps.claim.owner.id;

  const voteHiddenUsernameLength =
    explanation &&
    explanation.filter(exp => {
      return exp.voteHiddenUsername;
    }).length;

  const feedbackRatingTypeIdsOrder =
    (ownProps && ownProps.claim && ownProps.claim.evaluationCriteria) || [];

  return {
    routeParams,
    feedbackRatingTypeIdsOrder,
    isCaseStudyFeedback,
    hasVoteHiddenUsername: !!voteHiddenUsernameLength,
    isCurrentUserAdmin: selectIsCurrentUserAdmin(state),
    isClaimOwnedByProfileOwner: currentUserId === claimOwnerId,
    ratingCriteriaEntities: selectRatingCriteriaEntities(state),
  };
}

const mapDispatchToProps = {
  addMessage,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withApollo(Feedback)))
);
