import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Notification from 'ui/common/rebranding/components/Notification';

export default class Alert extends Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string.isRequired,
    inverse: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    color: 'info',
    inverse: false,
    open: true,
  };

  render() {
    const { className, color, inverse, ...passThrough } = this.props;

    let type = 'neutral';
    if (color === 'success') type = 'success';
    if (color === 'danger') type = 'error';

    return <Notification type={type} className={className} {...passThrough} />;
  }
}
