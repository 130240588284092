/*
  AddToSlackButton
*/
import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import shortid from 'shortid';
import { bindActionCreators } from 'redux';
import { buildMessage } from 'store/features/common/flash/builder';
import * as storage from 'store/features/auth/storage';
import * as flashActions from 'store/features/common/flash/actions';
import jwtDecode from 'jwt-decode';
import Button from 'ui/common/button';
import { slackMethod } from 'resources/slack';
import routeTemplates from 'ui/common/routes/templates';
import messages from './messages';

import * as authCallbackActionTypes from 'store/graphql/SlackAuthCallback/action-types';
import * as actionTypes from 'store/graphql/SlackAuthButton/action-types';
import * as authActionTypes from 'store/features/auth/action-types';
import {
  selectFetchingStart,
  selectAuthenticatingWithSlack,
  selectSlackAuthCallbackState,
} from 'store/graphql/SlackAuthCallback/selectors';
import {
  selectLoginWithSlack,
  selectError,
  selectSlackAuthButtonState,
} from 'store/graphql/SlackAuthButton/selectors';

import { logEvent, logUserProperties } from 'services/amplitude-helper';
import SlackPNG from './slack.png';

import Loading from 'ui/common/loading';
import * as smartRouteActions from 'store/features/smart-route/actions';
import * as libSelectors from 'store/libs/selectors';
import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import SlackAuthButtonReducer from 'store/graphql/SlackAuthButton/reducer';
import SlackAuthCallbackReducer from 'store/graphql/SlackAuthCallback/reducer';

import styles from './index.module.scss';

class AddToSlackButton extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentDidMount() {
    const { addToSlackCodeStart } = this.props;

    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );

    const location = window.location;
    const authData = new URLSearchParams(location.search);
    const code = authData.get('code');
    const state = authData.get('state');

    if (location && location.search && authenticationWithSlack && code && state) {
      // Move this here so that the loading spinner can load immediate.
      addToSlackCodeStart();
    }
  }

  async ultimateAddToSlack() {
    const { isFetchingStart, slackAddSuccess, slackAddFailure, push, from } = this.props;
    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );

    const fromLocation = from;

    if (!isFetchingStart) {
      const location = window.location;
      const authData = new URLSearchParams(location.search);
      const code = authData.get('code');
      const state = authData.get('state');
      const error = authData.get('error');

      if (location && location.search && authenticationWithSlack && code && state && !error) {
        slackAddSuccess({ code, state });

        this.addToSlackCode(code, fromLocation);
      } else if (error) {
        slackAddFailure({ error });
        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);

        push(routeTemplates.emsLandingPage);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const previousRecaptchaStatus = !!(
      prevProps.recaptchaStatus && prevProps.recaptchaStatus.loaded
    );
    const currentRecaptchaStatus = !!(
      this.props.recaptchaStatus && this.props.recaptchaStatus.loaded
    );
    if (previousRecaptchaStatus !== currentRecaptchaStatus) {
      this.ultimateAddToSlack();
    }
  }

  async addToSlackCode(code, fromLocation) {
    const {
      client,
      existingValues,
      loginSuccessRequest,
      redirectUri,
      addToSlackCodeSuccess,
      addToSlackCodeFailure,
      push,
      setSmartRoute,
    } = this.props;

    try {
      // const recaptchaToken = await window.grecaptcha.execute(
      //   process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
      //   { action: "AddToslack" }
      // );

      const response = await client.mutate({
        mutation: gql`
          mutation addToSlack($code: String!, $redirectUrl: String!) {
            addToSlack(code: $code, redirectUrl: $redirectUrl) {
              ok
              token
              company {
                prettyId
              }
              status
              companyAdminEmail
            }
          }
        `,
        variables: {
          code: code,
          redirectUrl: redirectUri,
        },
        // context: {
        //   headers: {
        //     "x-recaptcha-token": recaptchaToken
        //   }
        // }
      });

      const { data, errors } = response;

      if (errors && errors[0].message) {
        push(fromLocation, {
          flash: buildMessage({
            id: 'login-with-slack.error',
            kind: 'danger',
            content: errors[0].message,
          }),
        });

        addToSlackCodeFailure({ error: errors[0].message });
      } else if (data && data.addToSlack && data.addToSlack.token) {
        storage.setToken(data.addToSlack.token);
        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);
        const decoded = jwtDecode(data.addToSlack.token);
        const { email } = decoded;
        if (window.amplitude) {
          const ampIns = window.amplitude.getInstance();
          if (email) {
            ampIns.setUserId(email);
          }
        }

        logUserProperties({ signup_source: 'slack' });

        const { status, companyAdminEmail } = data.addToSlack;
        loginSuccessRequest({
          token: data.addToSlack.token,
          redirect: true,
          from: fromLocation,
          currentPrettyId: data.addToSlack.company.prettyId,
          status,
          companyAdminEmail,
        });
        addToSlackCodeSuccess({
          ok: data.addToSlack.ok,
          token: data.addToSlack.token,
        });

        if (fromLocation) {
          storage.deleteDataInLocalStorage('slack-from');
        }
      } else if (data && data.addToSlack && data.addToSlack.ok === false) {
        const decoded = jwtDecode(data.addToSlack.token);
        const { name } = decoded;

        storage.deleteDataInLocalStorage(slackMethod.authenticationWithSlack);

        push(routeTemplates.auth.signUp, {
          fromLocation,
          provider: 'slack',
          fieldValues: { ...existingValues, name },
          providerValues: { token: data.addToSlack.token },
          flash: buildMessage({
            id: 'slack-add-button.additional-fields-required',
            kind: 'info',
            content: messages.additionalFieldsRequired,
          }),
        });
        addToSlackCodeFailure({
          error: messages.additionalFieldsRequired,
        });

        setSmartRoute({
          isEmailVerify: true,
        });

        if (fromLocation) {
          setSmartRoute({
            routeAfterEmailVerify: fromLocation,
          });

          storage.deleteDataInLocalStorage('slack-from');
        }
      }
    } catch (error) {
      addToSlackCodeFailure({ error });
    }
  }

  async slackAuthCallback() {
    const { slackAddStart, slackAddFailure, redirectUri } = this.props;

    slackAddStart();

    logEvent('try_with_slack_waitlist_clicked', {
      source: window && window.location.pathname,
    });

    try {
      const urlParams = new URLSearchParams();
      const requestState = shortid.generate();

      urlParams.set('client_id', process.env.REACT_APP_SLACK_CLIENT_ID);
      urlParams.set('redirect_uri', redirectUri);
      urlParams.set(
        'scope',
        'channels:read,chat:write,commands,users.profile:read,users:read,users:read.email,team:read,im:history'
      );
      urlParams.set('state', requestState);
      urlParams.set('allow_signup', 'true');
      window.location = `${process.env.REACT_APP_SLACK_API_URL}?${urlParams}`;
      storage.setDataInLocalStorage(slackMethod.authenticationWithSlack, true);
    } catch (apiError) {
      flashActions.addMessage({
        id: 'slack.add.status',
        kind: 'danger',
        content: apiError,
      });
      slackAddFailure({ error: apiError });
    }
  }

  render() {
    const { isLoginWithSlack, isAuthenticatingWithSlack, isFetchingStart, disabled } = this.props;

    if (isLoginWithSlack) {
      return <Loading />;
    }

    return (
      <Button
        className={classnames(styles.slackBtn, 'd-flex')}
        disabled={isAuthenticatingWithSlack || disabled}
        onClick={this.slackAuthCallback}
        loading={isAuthenticatingWithSlack || isFetchingStart || isLoginWithSlack}
      >
        <img src={SlackPNG} width="20" alt="slack" />
        <span className={classnames(styles.btnText, 'ml-2 font-weight-bold')}>
          <b>
            <FormattedMessage id="auth.try-with-slack-button" defaultMessage={`Try with Slack`} />
          </b>
        </span>
      </Button>
    );
  }
}

function mapStateToProps(state, props) {
  const redirectUri = `${window.location.origin}/slack-add`;
  // const redirectUri = `https://ecs-integration-frontend.indorse.io/slack-add`;
  const slackAuthButtonState = selectSlackAuthButtonState(state);
  const slackAuthCallback = selectSlackAuthCallbackState(state);
  const isFetchingStart = slackAuthCallback && selectFetchingStart(state);
  const isAuthenticatingWithSlack = slackAuthCallback && selectAuthenticatingWithSlack(state);
  const isLoginWithSlack = selectLoginWithSlack(state);
  const recaptchaStatus = libSelectors.selectRecaptchaSdkLibLoadState(state);

  const from =
    (state && state.router && state.router.location && state.router.location.pathname) || '';

  return {
    errorMessage: slackAuthButtonState && selectError(state),
    redirectUri,
    isFetchingStart,
    isLoginWithSlack,
    isAuthenticatingWithSlack,
    recaptchaStatus,
    from,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    slackAddStart: () => dispatch({ type: authCallbackActionTypes.SLACK_AUTH.START }),
    slackAddSuccess: ({ code, state }) =>
      dispatch({
        type: authCallbackActionTypes.SLACK_AUTH.SUCCESS,
        payload: { code, state },
      }),
    slackAddFailure: error =>
      dispatch({
        type: authCallbackActionTypes.SLACK_AUTH.FAILURE,
        payload: { error },
      }),

    addToSlackCodeStart: () => dispatch({ type: actionTypes.LOGIN_WITH_SLACK_CODE.START }),
    addToSlackCodeSuccess: ({ ok, token }) =>
      dispatch({
        type: actionTypes.LOGIN_WITH_SLACK_CODE.SUCCESS,
        payload: { ok, token },
      }),
    addToSlackCodeFailure: ({ error }) =>
      dispatch({
        type: actionTypes.LOGIN_WITH_SLACK_CODE.FAILURE,
        payload: { error },
      }),
    loginSuccessRequest: ({ token, redirect, from, currentPrettyId, status, companyAdminEmail }) =>
      dispatch({
        type: authActionTypes.LOGIN_SUCCESS_REQUEST,
        token,
        redirect,
        from,
        currentPrettyId,
        status,
        companyAdminEmail,
      }),
    push: (routeName, locationState) => dispatch(push(routeName, locationState)),
    setSmartRoute: bindActionCreators(smartRouteActions.setSmartRoute, dispatch),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSlackAuthButtonReducer = injectReducer({
  key: 'SlackAuthButton',
  reducer: SlackAuthButtonReducer,
});
const withSlackAuthCallbackReducer = injectReducer({
  key: 'SlackAuthCallback',
  reducer: SlackAuthCallbackReducer,
});

export default compose(withSlackAuthButtonReducer, withSlackAuthCallbackReducer, withConnect)(
  injectIntl(withApollo(AddToSlackButton))
);
