import { fork } from 'redux-saga/effects';

import users from './users/saga';
import companies from './companies/saga';
import skills from './skills/saga';
import badges from './badges/saga';
import claims from './claims/saga';

export default function* entities() {
  yield fork(users);
  yield fork(companies);
  yield fork(skills);
  yield fork(badges);
  yield fork(claims);
}
