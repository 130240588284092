import { createSelector } from 'reselect';

export const selectSlackAuthButtonState = state => state.SlackAuthButton;

export const selectError = createSelector(
  selectSlackAuthButtonState,
  (state, props) => state.error
);

export const selectFetchingStart = createSelector(
  selectSlackAuthButtonState,
  state => state && state.fetchingStart
);

export const selectLoginWithSlack = createSelector(
  selectSlackAuthButtonState,
  state => state && state.loginWithSlack
);

export const selectOk = createSelector(selectSlackAuthButtonState, state => state && state.ok);
