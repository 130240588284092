import { company as schema } from 'store/common/schemas';
import { createEntitiesReducer, createEntitiesSelector } from './helpers';
import * as companyActionTypes from 'store/features/companies/action-types';
import * as companyAIPLimitActionTypes from 'store/graphql/ConfigureCompanyAipLimit/action-types';

export const reducer = createEntitiesReducer(schema, (state, action) => {
  switch (action.type) {
    case companyActionTypes.FETCH_COMPANY_TEAM_MEMBERS_BY_PRETTY_ID.SUCCESS:
      const { prettyId: fetchTeamMembersForPrettyId, teamMemberIds } = action.payload;
      if (fetchTeamMembersForPrettyId) {
        return state.merge(
          {
            [fetchTeamMembersForPrettyId]: {
              teamMembers: teamMemberIds,
            },
          },
          { deep: true }
        );
      }
      return state;
    case companyActionTypes.FETCH_COMPANY_ADVISORS_BY_PRETTY_ID.SUCCESS:
      const { prettyId: fetchAdvisorsForPrettyId, advisorIds } = action.payload;
      if (fetchAdvisorsForPrettyId) {
        return state.merge(
          {
            [fetchAdvisorsForPrettyId]: {
              advisors: advisorIds,
            },
          },
          { deep: true }
        );
      }
      return state;
    case companyAIPLimitActionTypes.CONFIGURE_COMPANY_AIP_LIMIT.SUCCESS:
      const { company, aipLimit, caseStudyAssignmentAipLimit } = action.payload;
      const { prettyId } = company;
      if (aipLimit) {
        return state.merge(
          {
            [prettyId]: {
              aipLimit,
            },
          },
          { deep: true }
        );
      } else if (caseStudyAssignmentAipLimit) {
        return state.merge(
          {
            [prettyId]: {
              caseStudyAssignmentAipLimit,
            },
          },
          { deep: true }
        );
      }
      return state;
    case companyAIPLimitActionTypes.ALLOW_REPORT_FOR_CANDIDATE.SUCCESS:
      const { company: { prettyId: id }, claimReportAllowed } = action.payload;

      return state.merge(
        {
          [id]: {
            magicLink: {
              claimReportAllowed,
            },
          },
        },
        { deep: true }
      );

    case companyAIPLimitActionTypes.UPDATE_CLAIM_REVIEW.SUCCESS:
      const { company: { prettyId: companyId }, claimReview } = action.payload;

      return state.merge(
        {
          [companyId]: {
            claimReview,
          },
        },
        { deep: true }
      );

    case companyAIPLimitActionTypes.REQUIRE_CV_FOR_COMPANY.SUCCESS:
      const { company: { prettyId: ID }, requireCV } = action.payload;

      return state.merge(
        {
          [ID]: {
            magicLink: {
              requireCV,
            },
          },
        },
        {
          deep: true,
        }
      );

    case companyAIPLimitActionTypes.UPDATE_MAGIC_LINK_VERSION.SUCCESS:
      const { company: { prettyId: companyPrettyId }, version } = action.payload;
      return state.merge(
        {
          [companyPrettyId]: {
            magicLink: {
              version,
            },
          },
        },
        {
          deep: true,
        }
      );
    default:
      return state;
  }
});

export const selector = createEntitiesSelector(schema);
