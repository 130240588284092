import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import autoBind from 'react-autobind';
import { connectModal as reduxModal } from 'redux-modal';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import Button from 'ui/common/button';

export const MODAL_NAME = 'hackathon-claim-submission-success-modal';

class HackathonClaimSubmissionSuccessModal extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  render() {
    const { show, handleHide } = this.props;

    return (
      <Modal style={{ maxWidth: '600px' }} size="lg" isOpen={show}>
        <ModalHeader toggle={handleHide} />
        <ModalBody>
          <div className="text-center">
            <h2 className="text-center" style={{ fontSize: '50px' }}>
              Congrats!
            </h2>
            <div className="row justify-content-center mt-4">
              <h3 className="col-8 text-center pt-3">
                You have successfully submitted your project. It’s time for the panelists to review
                it!
              </h3>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="m-auto text-center">
          <Button isReady={true} onClick={handleHide}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default reduxModal({
  name: MODAL_NAME,
})(injectIntl(HackathonClaimSubmissionSuccessModal));
