/*
  GetStartedCompanyAdmin
*/

import React, { Component } from 'react';
import autoBind from 'react-autobind';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import routeGenerators from 'ui/common/routes/generators';
import indorseLogo from 'resources/brand/logo-icon.svg';
import styles from './index.module.scss';
import Button from 'ui/common/button';
import HR_TEAM from './Images/HR-team.svg';
import PRODUCT_TEAM from './Images/product-team.svg';
import GetStartedCompanyAdminReducer from 'store/graphql/GetStartedCompanyAdmin/reducer';
import injectReducer from 'utils/injectReducer';
import * as messages from './messages';
import CHECK from 'ui/graphql/EmsBlanketTest/check.svg';
import { Card } from 'ui/common/card';
import { selectCompanyByPrettyId } from 'store/features/companies/selectors';
import gql from 'graphql-tag';

import { logEvent, logUserProperties } from 'services/amplitude-helper';
import FeatureCard from './FeatureCard';

const FEATURE_LIST = [
  {
    id: 'hr_team',
    image: HR_TEAM,
    heading: 'HR Team',
  },
  {
    id: 'product-team',
    image: PRODUCT_TEAM,
    heading: 'Tech Team',
  },
];

class GetStartedCompanyAdmin extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      selectedCard: null,
    };
  }

  async submitLookingForFeatures() {
    const { client, company, prettyId } = this.props;
    try {
      await client.mutate({
        mutation: gql`
          mutation($webForm: CompanyAdminWebFormInput!) {
            company_postCompanyAdminWebForm(webForm: $webForm) {
              _id
            }
          }
        `,
        variables: {
          webForm: {
            companyId: company && company.id,
            type: 'EMS_COMPANY_LOOKING_FOR_FEATURES',
            mode: 'UPSERT',
            content: {
              selected: this.state.selectedCard,
            },
          },
        },
      });

      setTimeout(
        () =>
          this.props.history.push(
            routeGenerators.companies.edit({ prettyId, section: 'engagement' })
          ),
        100
      );
    } catch (error) {
      console.log(error.message);
    }
  }

  selectFeatureCard(fId) {
    const { selectedCard } = this.state;
    logEvent('select-team');
    if (selectedCard === fId) {
      this.setState(
        {
          selectedCard: null,
        },
        function() {
          logUserProperties({ team: this.state.selectedCard });
        }
      );
    } else {
      this.setState({ selectedCard: fId }, function() {
        logUserProperties({ team: this.state.selectedCard });
      });
    }
  }

  renderFeatureCard(feature) {
    const isSelected = this.state.selectedCard === feature.id;
    return (
      <FeatureCard feature={feature} isSelected={isSelected} onSelect={this.selectFeatureCard} />
    );
  }

  render() {
    const { prettyId, intl: { formatMessage }, setEvent } = this.props;
    const isNoneSelected = this.state.selectedCard === 'none';
    return (
      <div className={classnames('container')}>
        <div className={classnames('row justify-content-center align-items-center')}>
          <div className="col-12">
            <img
              src={indorseLogo}
              className="d-block mx-auto pt-3 mt-3"
              width="60px"
              alt="arrow-down"
            />

            <h1 className={classnames(styles.pageTitle, 'indorse-blue text-center')}>Team</h1>
            <h3 className={classnames(styles.pageSubTitle, 'text-center pb-4')}>
              What is your job function?
            </h3>

            <div className={classnames('my-3 py-3', styles.partsGrid)}>
              {FEATURE_LIST.map(feature => this.renderFeatureCard(feature))}
              <Card
                className={classnames(
                  'py-5 px-auto align-items-center',
                  isNoneSelected ? styles.featureNoneCardSelected : styles.featureNoneCard
                )}
                onClick={() => this.selectFeatureCard('none')}
              >
                <h4 className={classnames('text-center mt-5')}>None of These</h4>
                <img
                  className={classnames(
                    'position-absolute',
                    isNoneSelected ? styles.check : styles.unchecked
                  )}
                  src={CHECK}
                  height="28px"
                  width="28px"
                  alt="checkbox"
                />
              </Card>
            </div>

            <div className="text-center">
              {this.props.isSlackConnected ? (
                <Button
                  style={{ padding: '13px 30px' }}
                  className={classnames(styles.getStarted)}
                  tag={Link}
                  to={routeGenerators.companies.edit({
                    prettyId,
                    section: 'engagement',
                  })}
                  onClick={setEvent}
                  disabled={this.state.selectedCard === null}
                >
                  Let's Get Started &nbsp;
                  <i className="fa fa-arrow-right ml-3" />
                </Button>
              ) : (
                <div
                  className={classnames(
                    styles.actionBtns,
                    'd-flex align-items-center justify-content-between'
                  )}
                >
                  <Button
                    className={classnames(styles.getStartedSkip)}
                    tag={Link}
                    onClick={this.props.toNextStep}
                    color={'border-black'}
                  >
                    Skip
                  </Button>
                  <Button
                    className={classnames(styles.getStarted)}
                    tag={Link}
                    onClick={() => {
                      logEvent('submit_choosing_team');
                      this.props.toNextStep();
                    }}
                    disabled={this.state.selectedCard === null}
                  >
                    {formatMessage(messages.labels.getStarted)}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const routeParams = ownProps && ownProps.match && ownProps.match.params;

  const prettyId = routeParams && routeParams.prettyId;
  const company = selectCompanyByPrettyId(state, { prettyId }) || {};
  return {
    prettyId,
    company,
  };
}

const withConnect = connect(mapStateToProps, null);

const withReducer = injectReducer({
  key: 'GetStartedCompanyAdmin',
  reducer: GetStartedCompanyAdminReducer,
});

export default withRouter(
  compose(withReducer, withConnect)(injectIntl(withApollo(withRouter(GetStartedCompanyAdmin))))
);
