import { call, fork, put, takeEvery } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import { startSubmit, stopSubmit } from 'redux-form';
import { hide as hideModal } from 'redux-modal';

import * as schemas from 'store/common/schemas';
import * as entityActions from 'store/entities/actions';
import * as actionTypes from './action-types';
import * as actions from './actions';
import * as api from 'api/config/skills';
import callApi from 'store/api/saga';

function* addUserSkill({ payload, meta = {} }) {
  const { userId, values, isHighlighted } = payload;
  const { form, modal } = meta;

  const { skillName: name, level } = values;

  if (form) yield put(startSubmit(form));
  yield put(actions.addUserSkill.start({ userId, values }));

  try {
    const response = yield call(
      callApi,
      api.addUserSkill({ userId, skill: { name, level, isHighlighted } })
    );
    const schema = schemas.userSkill;
    const { entities, result } = normalize(response, schema);

    yield put(entityActions.mergeEntities(entities));
    yield put(actions.addUserSkill.success({ userId, userSkillId: result }));

    if (form) yield put(stopSubmit(form));
    if (modal) yield put(hideModal(modal));
  } catch (error) {
    yield put(actions.addUserSkill.failure({ userId, values, error }));
    if (form) yield put(stopSubmit(form, error.formErrors));
  }
}

function* watchAddUserSkill() {
  yield takeEvery(actionTypes.ADD_USER_SKILL.REQUEST, addUserSkill);
}

export default function* skills() {
  yield fork(watchAddUserSkill);
}
