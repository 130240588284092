module.exports = {
  root: '/',
  faq: '/faq',
  thankYou: '/thank-you',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  cookieConsent: '/cookie-policy',
  company: '/company',
  hackathons: '/hackathons',
  aboutCompany: '/about-company',
  products: '/solutions',
  developersPage: '/for-developers',
  validate: '/validate',
  submitAJob: '/submit-a-job',
  githubAuth: '/github-auth',
  slackAuth: '/slack-auth',
  slackAdd: '/slack-add',
  metamorphLogin: '/metamorph-login',
  slackConnect: '/slack-connect/:prettyId',
  validatorsLeaderboard: '/validators-leaderboard',
  becomeAValidator: '/become-a-validator',
  merkle: '/admin/merkle',
  ipfs: '/ipfs',
  hackathonLeaderboard: '/leaderboard',
  panelReviews: '/panel-reviews',
  emsLandingPage: '/metamorph',
  emsExtLandingPage: '/metamorph/extension',
  emsLandingPageLegacy: '/ems',
  emsPricingPageLegacy: '/pricing',
  emsIndorseLandingPage: '/metamorph-indorse',
  emsLandingPageSlack: '/metamorph/slack',
  emsPricingPage: '/metamorph/pricing',
  emsPricingWizard: '/metamorph/pricing/wizard',
  devControlPanel: {
    root: '/dev-control-panel',
    auditTrail: '/dev-control-panel/audit-trail',
  },
  imports: {
    linkedInCsv: '/imports/linked-in-csv',
  },
  auth: {
    signUp: '/sign-up',
    verificationEmailSent: '/verification-email-sent',
    resendVerificationEmail: '/resend-verification-email',
    verifyEmail: '/verify-email',
    login: '/login',
    loginWithUport: '/login-with-uport',
    changePassword: '/password/change',
    setPassword: '/password/set',
    linkedAccounts: '/linked-accounts',
    forgotPassword: '/password/forgot',
    resetPassword: '/password/reset',
    approvalRequired: '/approval-required',
  },
  users: {
    root: '/users',
    myProfile: '/profile',
    profile: '/users/:id',
    vanityProfile: '/:username',
    delete: '/users/delete',
  },
  hack: {
    event: '/hack/:event',
  },
  admin: {
    mongoMails: '/admin/mongo-mails',
    root: '/admin',
    users: {
      index: '/admin/users',
    },
    companies: {
      root: '/admin/companies',
      new: '/admin/companies/new',
      edit: '/admin/companies/:prettyId/edit/:section?',
      accept: '/company/:token',
    },
    skills: {
      root: '/admin/skills',
      new: '/admin/skills/new',
      edit: '/admin/skills/:name/edit',
    },
    badges: {
      root: '/admin/badges',
      new: '/admin/badges/new',
      edit: '/admin/badges/:prettyId/edit',
      assign: '/admin/badges/assign',
    },
    claims: {
      root: '/admin/claims',
    },
    jobs: {
      root: '/admin/jobs',
      edit: '/admin/jobs/:id/edit',
      applicants: '/admin/jobs/:id/applicants',
      referrals: '/admin/jobs/:id/referrals',
    },
    assignments: {
      root: '/admin/assignments',
      new: '/admin/assignments/new',
      edit: '/admin/assignments/:id/edit',
    },
    stats: {
      root: '/admin/stats',
    },
    validators: {
      details: '/admin/validator/:validator_id',
      add: '/admin/validator-add',
      update: '/admin/validator-update/:validator_id',
    },
    features: {
      root: '/admin/features',
      new: '/admin/features/new',
      edit: '/admin/features/:id/edit',
    },
    reports: {
      root: '/admin/reports',
    },
    caseStudy: {
      root: '/admin/case-study',
      new: '/admin/case-study/new',
      edit: '/admin/case-study/:id/edit',
    },
    ems: {
      root: '/admin/ems',
      new: '/admin/ems/new',
      edit: '/admin/ems/:id/edit',
    },
  },
  ems: {
    githubAppCallback: '/ems/github-app-callback',
    bitBucketAppCallback: '/ems/bitbucket-app-callback',
    gitlabAppCallback: '/ems/gitlab-app-callback',
    gitAppInstallSuccessSetup: '/ems/git-install-success/:prettyId/:companyId',
    employee: {
      invite: '/ems/employee/invite',
      onBoard: '/ems/employee/on-board',
      blanketTest: '/ems/employee/blanket-test/:id',
      learningPath: '/ems/employee/learning-path',
    },
    assessmentMagicLink: '/ems/magic/:publicId',
  },
  validatorFeatures: {
    root: '/validator-features',
    new: '/validator-features/new',
    details: '/validator-features/:id',
  },
  claims: {
    root: '/claims',
    my: '/claims',
    new: '/claims/new',
    details: '/claims/:id',
    verify: '/claims/verify',
    detailsViewPdf: '/claims/view-pdf/:id',
  },
  votes: {
    root: '/votes',
    my: '/votes',
    cast: '/votes/:claimId/cast',
  },
  companies: {
    vanityProfile: '/c/:prettyId',
    acceptAsAdvisor: '/c/:prettyId/accept-as-advisor/:advisoryId',
    revokeAsAdvisor: '/c/:prettyId/revoke-as-advisor/:advisoryId',
    acceptAsTeamMember: '/c/:prettyId/accept-as-team/:teamMembershipId',
    revokeAsTeamMember: '/c/:prettyId/revoke-as-team/:teamMembershipId',
    root: '/companies',
    listing: {
      root: '/companies/listing',
    },
    acceptAdvisorConnection: '/companies/:prettyId/advisors/:id',
    edit: '/companies/:prettyId/edit/:section?',
    employeeDetails: '/companies/:prettyId/edit/organization/employee/:empId',
    organization: '/companies/:prettyId/edit/organization/:subMenuId?',
    emsSetup: '/companies/:prettyId/edit/ems/setup/:stepId?',
    analysis: '/companies/:prettyId/edit/organization/analysis/:designationId?',
    engagement: '/companies/:prettyId/edit/engagement',
    skillMatrix: '/companies/:prettyId/edit/skill-matrix/:designationId?',
    editCareerPath: '/companies/:prettyId/edit/career-path/:departmentId?',
    // departmentDetails: '/companies/:prettyId/edit/depart-details/:departmentId?',
    magicLinkCandidates: '/companies/:prettyId/edit/candidates/view/:publicId',
    buyCredits: '/companies/:prettyId/pricing',
    onBoarding: '/companies/:prettyId/onboard',
    getStarted: '/companies/:prettyId/get-started',
    emsGetStarted: '/companies/:prettyId/ems/get-started',
    // tests: {
    //   root: '/companies/:prettyId/tests',
    //   details: '/companies/:prettyId/tests/:assignmentId',
    // }
    testDetails: '/companies/:prettyId/edit/test-details',
  },
  blockchain101: {
    root: '/blockchain101',
  },
  wizard: {
    signUp: '/wizards/signUp',
  },
  badges: {
    root: '/badges',
    details: '/badges/:prettyId',
  },
  assignments: {
    myEvaluations: '/my-evaluations',
    root: '/assignments',
    thanks: '/assignment-submitted',
    details: '/assignments/:assignmentId',
  },
  magicLinks: {
    assessmentMagicLink: '/magic/:publicId',
  },
  partner: {
    acceptInvite: '/partner/accept-invite',
  },
  claimReports: {
    details: '/claim-reports/:reportId',
  },
  app: {
    root: '/app',
    linkNotFound: '/404',
  },
  caseStudyChatbotReports: {
    details: '/casestudy-reports/:magicLinkId/:csaId/:userId',
  },
};
