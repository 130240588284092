import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';

import autoBind from 'react-autobind';
import { fieldNames } from './model';
import { reduxForm, Field } from 'redux-form';
import SubmitButton from 'ui/common/form/submit-button';
import TextField from 'ui/common/form/fields/text-field';
import CheckboxField from 'ui/common/form/fields/checkbox-field';
import * as messages from './messages';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import styles from './index.module.scss';
import FormError from 'ui/common/form/form-error';
import { hide as hideModal } from 'redux-modal';
import { addMessage } from 'store/features/common/flash/actions';
import { messageTypes } from 'store/features/common/flash/builder';
import { MODAL_NAME as EXIT_INTENT_MODAL } from './index';
import Button from 'ui/common/button';
const FORM_NAME = 'exit-intent-form';

class ExitIntentForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      showPopup: true,
    };
  }

  componentDidMount() {
    const showPopup = localStorage.getItem('popup');
    const shownExitIntent = localStorage.getItem('exitIntent');
    this.setState({ showPopup: !(showPopup === 'false' || shownExitIntent === 'true') });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showPopup && !this.state.showPopup) {
      this.props.hideModal(EXIT_INTENT_MODAL);
    }
  }

  async onSubmit(values) {
    const { client, addMessage } = this.props;

    try {
      let recaptchaToken;
      if (process.env.NODE_ENV !== 'development') {
        recaptchaToken = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_CLIENT_ID,
          { action: 'resonsforleavingems' }
        );
      }
      const graphqlResult = await client.mutate({
        mutation: gql`
          mutation postAnonWebForm($webForm: AnonWebFormInput!) {
            postAnonWebForm(webForm: $webForm) {
              type
            }
          }
        `,
        variables: {
          webForm: {
            type: 'reasons-for-leaving-ems',
            content: values,
          },
        },
        context: {
          headers: {
            'x-recaptcha-token': recaptchaToken,
          },
        },
      });

      if (graphqlResult.errors) {
        // show error message here.
        const { message } = graphqlResult.errors[0];
        addMessage({
          kind: messageTypes.danger,
          content: message,
        });
      } else {
        addMessage({
          kind: messageTypes.success,
          content: 'Your response has been successfully recorded.',
          disableTimeout: true,
        });
        this.handleDontShow();
      }
    } catch (error) {
      addMessage({
        kind: messageTypes.danger,
        content: error.message,
      });
    }
  }

  handleDontShow = () => {
    localStorage.setItem('popup', 'false');
    this.setState({ showPopup: false });
    this.props.hideModal(EXIT_INTENT_MODAL);
  };

  render() {
    const { intl: { formatMessage }, handleSubmit, pristine, invalid } = this.props;

    return (
      <div className="row justify-content-center">
        <form onSubmit={handleSubmit(this.onSubmit)} className="col-10">
          <FormError formName={FORM_NAME} />
          <h2 className={classnames(styles.heading, 'mb-2')}>Still leaving?</h2>
          <p className={classnames(styles.text)}>Sorry to see you go :(</p>
          <hr className={styles.divider} />
          <p className={classnames(styles.text, 'pt-3')}>
            What stopped you from going ahead with the trial of Metamorph?
          </p>
          <div className="row pt-2 pl-3">
            <Field
              name={fieldNames.howItBenefits}
              component={CheckboxField}
              label={formatMessage(messages.labels[fieldNames.howItBenefits])}
            />
          </div>
          <div className="row pt-2 pl-3">
            <Field
              name={fieldNames.howItWorks}
              component={CheckboxField}
              label={formatMessage(messages.labels[fieldNames.howItWorks])}
            />
          </div>
          <div className="row pt-2 pl-3">
            <Field
              name={fieldNames.featuresMissing}
              component={CheckboxField}
              label={formatMessage(messages.labels[fieldNames.featuresMissing])}
            />
          </div>
          <div className="row pt-3 pl-3">
            <Field
              name={fieldNames.suggestions}
              component={TextField}
              label={formatMessage(messages.labels.suggestions)}
              hideLabel
              hint={formatMessage(messages.labels.suggestions)}
              multiLine
              rows={4}
              class
            />
          </div>
          <div className="d-flex pt-3 mb-5 align-items-center">
            <div>
              <SubmitButton disabled={invalid || pristine} isReady className={styles.btn}>
                Submit
              </SubmitButton>
            </div>
            <Button
              color="secondary"
              style={{ background: 'none' }}
              className="ml-4"
              isReady={false}
              onClick={this.handleDontShow}
            >
              <h3 className={'m-0'}>Don't show this to me again.</h3>
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  addMessage,
  hideModal,
};

export default withRouter(
  connect(null, mapDispatchToProps)(
    reduxForm({
      form: FORM_NAME,
    })(injectIntl(withApollo(ExitIntentForm)))
  )
);
