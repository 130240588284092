import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export const label = defineMessages({
  [fieldNames.adminEmail]: {
    id: 'graphql.create-ems.company-admin.email',
    defaultMessage: `Email Address`,
  },
});

export const buttons = defineMessages({
  submitButton: {
    id: 'graphql.create-ems.submit.company',
    defaultMessage: 'Try It Free',
  },
  slackButton: {
    id: 'graphql.create-ems.slack.company',
    defaultMessage: 'Add to Slack',
  },
});
export const content = defineMessages({
  pageTitle: {
    id: 'pages.assignment-magic-thanks.page-title',
    defaultMessage: 'Tada!',
  },
  pageSubTitle: {
    id: 'pages.assignment-magic-thanks.page-sub-title',
    defaultMessage:
      'A verification email has been sent to you, please verify your email address to access your dashboard!',
  },
  pageCompanies: {
    id: 'pages.assignment-magic-thanks.page-companies',
    defaultMessage: "You're in good company.",
  },
});

export const hero = {
  subTitle: {
    id: 'pages.ems.hero.graphql.subtitle',
    defaultMessage:
      'The data and insights you need to develop, sustain and empower a high performing software engineering team',
  },
};
