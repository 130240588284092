import { combineReducers } from 'redux';
import Immutable from 'seamless-immutable';

import smartContracts from './smart-contracts/reducer';

import * as actionTypes from './action-types';

const initialState = Immutable({
  networkName: null,
  providerName: null,
  accounts: [],
  currentAccount: undefined,
  medianGasPriceInGwei: '1',
});

function basicInfo(state = initialState, action) {
  switch (action.type) {
    case actionTypes.EXTRACT_BASIC_INFO_FROM_WEB3.SUCCESS:
      const accounts = action.payload.accounts;
      const currentAccount = accounts && accounts.length > 0 ? accounts[0] : undefined;
      return state.merge({
        networkName: action.payload.networkName,
        providerName: action.payload.providerName,
        medianGasPriceInGwei: action.payload.medianGasPriceInGwei || '1',
        accounts,
        currentAccount,
      });
    default:
      return state;
  }
}

export default combineReducers({
  basicInfo,
  smartContracts,
});
