import React from 'react';

import classnames from 'classnames';
import Slider from 'react-slick';
import styles from './index.module.scss';
import themeConfig from 'ui/theme/config';

import MemberCard from 'ui/pages/home-V2/members-card';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: 'none' }} onClick={onClick} />;
}

const defaultSettings = {
  centerMode: true,
  centerPadding: '50px',
  swipeToSlide: true,
  infinite: true,
  slidesToShow: 1,
  lazyLoad: true,
  arrows: false,
  responsive: [
    {
      breakpoint: themeConfig.layout.gridBreakpoints.xl,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: themeConfig.layout.gridBreakpoints.lg,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: themeConfig.layout.gridBreakpoints.md,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function memberSlider({ members, cardSmall, isResponsive, ...passThrough }) {
  const settings = {
    nextArrow: <SampleNextArrow />,
    ...defaultSettings,
    ...passThrough,
  };

  return (
    <Slider className={classnames(styles.customSlider)} {...settings}>
      {members.map((member, index) => {
        return (
          <div key={index}>
            <MemberCard
              member={member}
              cardSmall={cardSmall}
              isResponsive={isResponsive}
              {...passThrough}
            />
          </div>
        );
      })}
    </Slider>
  );
}
