import React, { Component } from 'react';
import autobind from 'react-autobind';
import { Navbar, Nav, NavItem } from 'reactstrap';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { animateScroll } from 'react-scroll';
import Menu from 'ui/common/menu';
import UserIndToken from './userIndorseToken';
import ExternalLink from 'ui/common/external-link';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import classnames from 'classnames';
import styles from './index.module.scss';
import routeGenerator from 'ui/common/routes/generators';
import { logEvent } from 'services/amplitude-helper';
import Avatar from 'ui/users/avatar';
import Logo from 'ui/common/rebranding/components/Logo/Logo';
import AdminNavItems from './admin';
import Icon from 'ui/common/icon';
import Companies from './companies';
import AdminPendingApprovalCompanyClaimsNavItem from 'ui/graphql/AdminPendingApprovalCompanyClaimsNavItem/loadable';

import Button from 'ui/common/button';
import OverflowMenu from 'ui/common/rebranding/components/OverflowMenu/OverflowMenu';
import OverflowMenuItem from 'ui/common/rebranding/components/OverflowMenuItem/OverflowMenuItem';
import OverflowMenuHeader from 'ui/common/rebranding/components/OverflowMenu/OverflowMenuHeader';
import OverflowMenuBottom from 'ui/common/rebranding/components/OverflowMenu/OverflowMenuBottom';
import AddToSlackButton from 'ui/graphql/AddToSlackButton';
import {
  selectIsLoggedIn,
  selectCurrentUser,
  selectCurrentUserFetchedAtleastOnce,
  selectCurrentUserHasPassword,
  selectIsCurrentUserAdmin,
  selectIsCurrentUserFullAccess,
  selectIsCurrentUserNoAccess,
  selectCurrentUserEthereumAddress,
  selectIsCurrentUserEthereumAddressVerified,
  selectIsCurrentUserTier3,
  selectIsCurrentUserPanelReviewer,
} from 'store/features/auth/selectors';
import {
  selectIsCurrentUsersClaimById,
  selectCanCurrentUserVoteOnClaimById,
} from 'store/features/claims/selectors';
import { logout } from 'store/features/auth/actions';

import routeTemplates from 'ui/common/routes/templates';
import { createMatchSelector } from 'ui/common/routes/selectors';

import { AIPValidationEnabled, ImpressAIValidationEnabled } from 'resources/validations';
import { hide as hideModal, show as showModal } from 'redux-modal';

const scroll = animateScroll;
const EMS_USER = 'EMS_USER';

const navLinkOptions = {
  activeClass: 'active',
  delay: 100,
  spy: true,
  smooth: true,
  duration: 1500,
  offset: -100,
};

const aboutCompanyPageEnabled = process.env.REACT_APP_ENABLE_ABOUT_COMPANY_PAGE === 'true';

class Header extends Component {
  static propTypes = {
    intl: intlShape,
  };

  constructor(props) {
    super(props);

    autobind(this);

    this.navbar = null;
    this.state = {
      navLinkOptions,
      openSubMenu: false,
    };
  }

  toggleSubMenu() {
    this.setState({
      openSubMenu: !this.state.openSubMenu,
    });
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  logout() {
    this.props.logout.request();
  }

  renderAdminNavItem() {
    const { currentUserIsAdmin } = this.props;

    return currentUserIsAdmin && <AdminNavItems />;
  }

  renderUserNav() {
    const {
      currentUser,
      // currentUserIsAdmin,
      // currentUserIsFullAccess,
      loggedIn,
      companies,
      isEmsUser,
    } = this.props;

    return (
      <Nav className="ml-3 mr-auto">
        {!isEmsUser &&
          companies &&
          companies.length === 0 && (
            <NavItem className="my-auto pr-4">
              <RouterLink
                exact
                activeClassName={classnames(styles.active)}
                to={routeGenerator.users.bestProfile({
                  user: currentUser,
                  isCurrentUser: true,
                })}
              >
                <FormattedMessage id="app.layout.header.profile" defaultMessage="My Profile" />
              </RouterLink>
            </NavItem>
          )}
        {companies.length > 0 && <Companies companies={companies} />}
        {/* <NavItem className="my-auto pr-4">
          <RouterLink
            exact
            activeClassName={classnames(styles.active)}
            to={routeTemplates.assignments.myEvaluations}
          >
            <FormattedMessage id="app.layout.header.evaluations" defaultMessage="My Evaluations" />
          </RouterLink>
        </NavItem> */}
        {!loggedIn &&
          aboutCompanyPageEnabled && (
            <NavItem className={classnames('my-auto pr-4', styles.navItem)}>
              <RouterLink
                exact
                className={classnames('my-auto')}
                activeClassName={classnames(styles.active)}
                to={{
                  pathname: routeTemplates.aboutCompany,
                  state: { ref: 'navbar' },
                }}
              >
                <FormattedMessage id="app.layout.header.about" defaultMessage="About Us" />
              </RouterLink>
            </NavItem>
          )}

        {!isEmsUser &&
          companies &&
          companies.length === 0 && (
            <NavItem className="my-auto pr-4">
              <RouterLink
                exact
                activeClassName={classnames(styles.active)}
                to={routeTemplates.validatorsLeaderboard}
              >
                <FormattedMessage
                  id="app.layout.header.validatorsLeaderboard"
                  defaultMessage="Leaderboard"
                />
              </RouterLink>
            </NavItem>
          )}

        {/*
        !isEmsUser &&
          (currentUserIsAdmin || currentUserIsFullAccess) && (
            <NavItem className="my-auto pr-4">
              <RouterLink
                exact
                activeClassName={classnames(styles.active)}
                to={routeTemplates.validatorFeatures.root}
              >
                <FormattedMessage
                  id="app.layout.header.validatorFeatures"
                  defaultMessage="Features"
                />
              </RouterLink>
            </NavItem>
          )
        */}

        {/*jobsNavEnabled &&
          loggedIn && (
            <NavItem className="my-auto pr-4">
              <RouterLink
                exact
                activeClassName={classnames(styles.active)}
                to={{ pathname: routeTemplates.jobs.root, state: { ref: 'navbar' } }}
              >
                <FormattedMessage id="app.layout.header.jobPosts" defaultMessage="Jobs" />
              </RouterLink>
            </NavItem>
          )*/}
      </Nav>
    );
  }

  renderAnonymousNav() {
    return (
      <Nav className={classnames('ml-auto text-center', styles.nav)}>
        {/*<NavItem*/}
        {/*  className={classnames('my-auto px-3', styles.navItem)}*/}
        {/*  onClick={() => logEvent('navbar-to-dev-page-v4')}*/}
        {/*>*/}
        {/*  <RouterLink*/}
        {/*    exact*/}
        {/*    className={classnames('my-auto')}*/}
        {/*    activeClassName={classnames(styles.active)}*/}
        {/*    to={{*/}
        {/*      pathname: routeTemplates.developersPage,*/}
        {/*      state: { ref: 'navbar' },*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <FormattedMessage*/}
        {/*      id="app.layout.header.company-offering-developers"*/}
        {/*      defaultMessage="For Developers"*/}
        {/*    />*/}
        {/*  </RouterLink>*/}
        {/*</NavItem>*/}
        {/*
        <NavItem className={classnames('my-auto px-3', styles.navItem)}>
          <RouterLink
            exact
            className={classnames('my-auto')}
            activeClassName={classnames(styles.active)}
            to={{ pathname: routeTemplates.company, state: { ref: 'navbar' } }}
          >
            <FormattedMessage
              id="app.layout.header.company-offering"
              defaultMessage="For Companies"
            />
          </RouterLink>
        </NavItem>
      */}

        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-product-page-v4')}
        >
          <RouterLink
            exact
            className={classnames('my-auto')}
            activeClassName={classnames(styles.active)}
            to={{ pathname: routeTemplates.products, state: { ref: 'navbar' } }}
          >
            <FormattedMessage
              id="app.layout.header.company-offering-products"
              defaultMessage="Solutions"
            />
          </RouterLink>
        </NavItem>

        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-pricing-page')}
        >
          <RouterLink
            exact
            className={classnames('my-auto')}
            activeClassName={classnames(styles.active)}
            to={{ pathname: routeTemplates.emsPricingPage, state: { ref: 'navbar' } }}
          >
            <FormattedMessage id="app.layout.header.metamorph-pricing" defaultMessage="Pricing" />
          </RouterLink>
        </NavItem>

        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-resources-page')}
        >
          <ExternalLink openInNewTab href="https://resources.indorse.io">
            <FormattedMessage
              id="app.layout.header.metamorph-resources"
              defaultMessage="Resources"
            />
          </ExternalLink>
        </NavItem>

        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-to-login-v4')}
        >
          <RouterLink
            exact
            className={classnames('my-auto')}
            activeClassName={classnames(styles.active)}
            to={{ pathname: routeTemplates.auth.login }}
          >
            <FormattedMessage
              id="app.layout.header.company-offering.login"
              defaultMessage="Login"
            />
          </RouterLink>
        </NavItem>

        <NavItem
          className={classnames('my-auto px-3', styles.navItem)}
          onClick={() => logEvent('navbar-request-demo-v4')}
        >
          {/* <Button
            isReady={true}
            onClick={() => {
              window.fcWidget &&
                window.fcWidget.open({
                  replyText:
                    'Hey there, I would like to request a Demo and discover more about Metamorph with Slack!',
                });
            }}
          >
            <FormattedMessage
              id="app.layout.header.company-offering.request-demo"
              defaultMessage="Try with Slack"
            />
          </Button> */}
          <AddToSlackButton />
        </NavItem>
      </Nav>
    );
  }

  renderDropdownProfile() {
    const {
      currentUser,
      history,
      currentUserIsAdmin,
      currentUserIsFullAccess,
      currentUserIsNoAccess,
      currentUserHasPassword,
      isCurrentUserPanelReviewer,
      companies,
      isEmsUser,
    } = this.props;

    return (
      <Nav className="ml-auto">
        <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
          {this.renderAdminNavItem()}
        </MediaQuery>
        {currentUserIsAdmin && <AdminPendingApprovalCompanyClaimsNavItem className="pr-3" />}
        {!isEmsUser &&
          currentUser &&
          companies.length === 0 && (
            <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
              <NavItem className={classnames(styles.navBtn, 'pr-3')}>
                <Button
                  to={{
                    pathname: routeTemplates.validate,
                    state: { ref: 'navbar' },
                  }}
                  color="primary"
                  isReady={false}
                >
                  <FormattedMessage
                    id="app.layout.header.validate"
                    defaultMessage="Validate a Skill"
                  />
                </Button>
              </NavItem>
            </MediaQuery>
          )}
        <NavItem className="pr-3">{this.renderUserIndToken()}</NavItem>
        <NavItem>
          <OverflowMenu
            renderIcon={iconProps => (
              <Avatar user={currentUser} size={33} className={classnames('text-center')} />
            )}
          >
            <OverflowMenuHeader
              className="py-3"
              onClick={() =>
                companies && companies.length > 0 ? null : history.push(`/${currentUser.username}`)}
            >
              {currentUser.name || currentUser.email}
            </OverflowMenuHeader>

            {/* <OverflowMenuItem
              className="py-3"
              onClick={() => history.push(routeTemplates.assignments.myEvaluations)}
            >
              <FormattedMessage
                id="app.layout.header.evaluations"
                defaultMessage="My Evaluations"
              />
            </OverflowMenuItem> */}
            {!isEmsUser &&
              companies &&
              companies.length === 0 && (
                <OverflowMenuItem
                  className="py-3"
                  onClick={() => history.push(routeTemplates.claims.root)}
                >
                  <FormattedMessage id="app.layout.header.claims" defaultMessage="My Claims" />
                </OverflowMenuItem>
              )}
            {(currentUserIsAdmin || currentUserIsFullAccess) && (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.votes.root)}
              >
                <FormattedMessage id="app.layout.header.votes" defaultMessage="My Votes" />
              </OverflowMenuItem>
            )}
            {isCurrentUserPanelReviewer && (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.panelReviews)}
              >
                <FormattedMessage
                  id="app.layout.header.panel-reviews"
                  defaultMessage="My Panel Reviews"
                />
              </OverflowMenuItem>
            )}

            {!isEmsUser &&
              !currentUserIsNoAccess &&
              companies &&
              companies.length === 0 && (
                <OverflowMenuItem
                  className="py-3"
                  onClick={() => history.push(routeTemplates.auth.linkedAccounts)}
                >
                  <FormattedMessage
                    id="app.layout.header.linked-accounts"
                    defaultMessage="Linked Accounts"
                  />
                </OverflowMenuItem>
              )}

            {/*currentUser && (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.users.myJobApplications)}
              >
                <FormattedMessage
                  id="app.layout.header.myJobApplications"
                  defaultMessage="My Job Applications"
                />
              </OverflowMenuItem>
            )*/}

            {currentUserHasPassword ? (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.auth.changePassword)}
              >
                <FormattedMessage
                  id="app.layout.header.change-password"
                  defaultMessage="Change Password"
                />
              </OverflowMenuItem>
            ) : (
              <OverflowMenuItem
                className="py-3"
                onClick={() => history.push(routeTemplates.auth.setPassword)}
              >
                <FormattedMessage
                  id="app.layout.header.set-password"
                  defaultMessage="Set Password"
                />
              </OverflowMenuItem>
            )}

            <OverflowMenuBottom>
              <Button className="mt-2" isReady={false} onClick={this.logout}>
                <FormattedMessage id="app.layout.header.log-out" defaultMessage="Logout" />
              </Button>
            </OverflowMenuBottom>
          </OverflowMenu>
        </NavItem>
      </Nav>
    );
  }

  renderUserBurgerMenu() {
    const {
      currentUserIsAdmin,
      currentUser,
      companies,
      isCurrentUserPanelReviewer,
      isEmsUser,
    } = this.props;

    return (
      <Nav>
        <NavItem className="my-auto">
          <Menu burger isOpenSubMenu={this.state.openSubMenu}>
            {currentUserIsAdmin && (
              <div>
                <FormattedMessage id="app.layout.header.admin" defaultMessage="Admin" />
                <ul>
                  <li className="text-left pl-4 py-4">
                    <Icon
                      background
                      name="icon--arrow-left"
                      tooltip="Previous"
                      description="previous"
                    />
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.users.index}>
                      <FormattedMessage id="app.layout.header.admin.users" defaultMessage="Users" />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.companies.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.companies"
                        defaultMessage="Companies"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.skills.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.skills"
                        defaultMessage="Skills"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.badges.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.badges"
                        defaultMessage="Badges"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.claims.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.claims"
                        defaultMessage="Claims"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.assignments.root}>
                      <FormattedMessage
                        id="app.layout.header.admin.assignments"
                        defaultMessage="Assignments"
                      />
                    </DropdownItem>
                  </li>
                  <li className="pb-4">
                    <DropdownItem tag={RouterLink} to={routeTemplates.admin.stats.root}>
                      <FormattedMessage id="app.layout.header.admin.stats" defaultMessage="Stats" />
                    </DropdownItem>
                  </li>
                </ul>
              </div>
            )}
            {isCurrentUserPanelReviewer && (
              <DropdownItem tag={RouterLink} to={routeTemplates.panelReviews}>
                <FormattedMessage
                  id="app.layout.header.panel-reviews"
                  defaultMessage="My Panel Reviews"
                />
              </DropdownItem>
            )}
            {companies.length === 0 && (
              <DropdownItem
                tag={RouterLink}
                to={routeGenerator.users.bestProfile({ user: currentUser })}
              >
                <FormattedMessage id="app.layout.header.profile" defaultMessage="My Profile" />
              </DropdownItem>
            )}

            {!isEmsUser &&
              companies.length === 0 && (
                <DropdownItem
                  tag={RouterLink}
                  to={{
                    pathname: routeTemplates.validatorsLeaderboard,
                    state: { ref: 'navbar' },
                  }}
                >
                  <FormattedMessage
                    id="app.layout.header.validatorsLeaderboard"
                    defaultMessage="Leaderboard"
                  />
                </DropdownItem>
              )}
            {!isEmsUser &&
              currentUser &&
              companies.length === 0 && (
                <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm}>
                  <DropdownItem
                    tag={RouterLink}
                    to={{
                      pathname: routeTemplates.validate,
                      state: { ref: 'navbar' },
                    }}
                    color="primary"
                  >
                    <FormattedMessage
                      id="app.layout.header.validate"
                      defaultMessage="Validate a Skill"
                    />
                  </DropdownItem>
                </MediaQuery>
              )}
            {/*{!isEmsUser && (*/}
            {/*  <DropdownItem tag={RouterLink} to={routeTemplates.validatorFeatures.root}>*/}
            {/*    <FormattedMessage*/}
            {/*      id="app.layout.header.validatorFeatures"*/}
            {/*      defaultMessage="Features"*/}
            {/*    />*/}
            {/*  </DropdownItem>*/}
            {/*)}*/}
            <DropdownItem>
              <Companies companies={companies} />
            </DropdownItem>
            <Button className="mt-2" onClick={this.logout}>
              <h3 className="m-0">
                <FormattedMessage id="app.layout.header.log-out" defaultMessage="Logout" />
              </h3>
            </Button>
          </Menu>
        </NavItem>
      </Nav>
    );
  }

  renderAnonymousBurgerMenu() {
    return (
      <Nav>
        <Menu burger>
          {/*<DropdownItem*/}
          {/*  tag={RouterLink}*/}
          {/*  to={routeTemplates.developersPage}*/}
          {/*  onClick={() => logEvent('navbar-to-dev-page-v4')}*/}
          {/*>*/}
          {/*  <FormattedMessage*/}
          {/*    id="app.layout.header.company-offering-developers"*/}
          {/*    defaultMessage="For Developers"*/}
          {/*  />*/}
          {/*</DropdownItem>*/}

          <DropdownItem
            tag={RouterLink}
            to={routeTemplates.emsPricingPage}
            onClick={() => logEvent('navbar-to-ems-pricing-page')}
          >
            <FormattedMessage id="app.layout.header.metamorph-pricing" defaultMessage="Pricing" />
          </DropdownItem>

          <DropdownItem
            tag={RouterLink}
            to={routeTemplates.products}
            onClick={() => logEvent('navbar-to-product-page-v4')}
          >
            <FormattedMessage
              id="app.layout.header.company-offering-products"
              defaultMessage="Solutions"
            />
          </DropdownItem>

          <DropdownItem>
            <ExternalLink openInNewTab href="https://resources.indorse.io">
              <FormattedMessage
                id="app.layout.header.metamorph-resources"
                defaultMessage="Resources"
              />
            </ExternalLink>
          </DropdownItem>

          <DropdownItem
            tag={RouterLink}
            to={routeTemplates.auth.login}
            onClick={() => logEvent('navbar-to-login-v4')}
          >
            <FormattedMessage id="app.layout.header.log-in" defaultMessage="Login" />
          </DropdownItem>
        </Menu>
      </Nav>
    );
  }

  renderUserIndToken() {
    const {
      currentUser,
      currentUserEthereumAddress,
      currentUserHasEthereumAddress,
      isCurrentUserTier3,
    } = this.props;

    if (
      !currentUser ||
      !currentUserEthereumAddress ||
      !currentUserHasEthereumAddress ||
      isCurrentUserTier3
    )
      return null;

    return <UserIndToken userEthereumAddress={currentUserEthereumAddress} />;
  }

  render() {
    const { loggedIn, className, history } = this.props;

    return (
      <Navbar
        id="main-header"
        tag="header"
        fixed="top"
        className={classnames(className, styles.navbar, 'border-bottom')}
      >
        <section
          ref={el => (this.navbar = el && el.parentElement)}
          className={classnames('container pr-md-0 page-max-width')}
        >
          <div
            className="pr-3"
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(routeTemplates.root)}
          >
            <Logo />
          </div>

          <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.sm}>
            {loggedIn ? this.renderUserNav() : this.renderAnonymousNav()}
          </MediaQuery>

          {loggedIn && this.renderDropdownProfile()}
          <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm}>
            {loggedIn ? this.renderUserBurgerMenu() : this.renderAnonymousBurgerMenu()}
          </MediaQuery>
        </section>
      </Navbar>
    );
  }
}

const myClaimsMatchSelector = createMatchSelector({
  path: routeTemplates.claims.my,
  exact: true,
});
const claimsDetailsMatchSelector = createMatchSelector({
  path: routeTemplates.claims.details,
  exact: true,
});
const myVotesMatchSelector = createMatchSelector({
  path: routeTemplates.votes.my,
  exact: true,
});

function mapStateToProps(state) {
  const myProfileRouteMatch = !!createMatchSelector({
    path: routeTemplates.users.myProfile,
    exact: true,
  })(state);
  const otherProfileRouteMatch = !!createMatchSelector({
    path: routeTemplates.users.vanityProfile,
    exact: true,
  })(state);
  const myClaimsMatch = myClaimsMatchSelector(state);
  const myVotesMatch = myVotesMatchSelector(state);
  const claimsDetailsMatch = claimsDetailsMatchSelector(state);
  const shouldMyClaimsBeActive =
    !!myClaimsMatch ||
    (claimsDetailsMatch &&
      claimsDetailsMatch.params &&
      claimsDetailsMatch.params.id &&
      selectIsCurrentUsersClaimById(state, {
        id: claimsDetailsMatch.params.id,
      }));
  const shouldMyVotesBeActive =
    !shouldMyClaimsBeActive &&
    (!!myVotesMatch ||
      (claimsDetailsMatch &&
        claimsDetailsMatch.params &&
        claimsDetailsMatch.params.id &&
        selectCanCurrentUserVoteOnClaimById(state, {
          id: claimsDetailsMatch.params.id,
        })));

  const canVisitNewClaimPage =
    AIPValidationEnabled &&
    (selectIsCurrentUserFullAccess(state) || selectIsCurrentUserAdmin(state));

  const canVisitValidationChoicePage = ImpressAIValidationEnabled;

  const showValidationButton = canVisitValidationChoicePage || canVisitNewClaimPage;
  const currentUserEthereumAddress = selectCurrentUserEthereumAddress(state);
  const currentUser = selectCurrentUser(state);
  const isEmsUser = currentUser && currentUser.type === EMS_USER;
  // console.log('currentUser : ', currentUser);
  return {
    isCurrentUserTier3: selectIsCurrentUserTier3(state),
    isCurrentUserPanelReviewer: selectIsCurrentUserPanelReviewer(state),
    loggedIn: selectIsLoggedIn(state),
    currentUser,
    isEmsUser,
    currentUserFetchedAtleastOnce: selectCurrentUserFetchedAtleastOnce(state),
    currentUserHasPassword: selectCurrentUserHasPassword(state),
    currentUserEthereumAddress,
    currentUserHasEthereumAddress: !!selectCurrentUserEthereumAddress(state),
    currentUserHasVerifiedEthereumAddress: selectIsCurrentUserEthereumAddressVerified(state),
    currentUserIsAdmin: selectIsCurrentUserAdmin(state),
    currentUserIsFullAccess: selectIsCurrentUserFullAccess(state),
    shouldMyClaimsBeActive,
    shouldMyVotesBeActive,
    currentUserIsNoAccess: selectIsCurrentUserNoAccess(state),
    canVisitValidationChoicePage,
    canVisitNewClaimPage,
    showValidationButton,
    myProfileRouteMatch,
    otherProfileRouteMatch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    logout: {
      request: bindActionCreators(logout.request, dispatch),
    },
    showModal: bindActionCreators(showModal, dispatch),
    hideModal: bindActionCreators(hideModal, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false /* This is required for link 'active' feature to work when route is changed. It works on refresh without this */,
})(injectIntl(withRouter(Header)));
