export default function loadPolyfills() {
  const fillIntl = () =>
    new Promise(resolve => {
      if ('Intl' in window) return resolve();

      require.ensure(
        [],
        () => {
          require('intl');
          require('intl/locale-data/jsonp/en.js');

          resolve();
        },
        'Intl'
      );
    });

  const fillURLSearchParams = () =>
    new Promise(resolve => {
      if ('URLSearchParams' in window) return resolve();

      require.ensure(
        [],
        () => {
          window.URLSearchParams = require('url-search-params');

          resolve();
        },
        'URLSearchParams'
      );
    });

  const fillLocalStorage = () => {
    new Promise(resolve => {
      if ('localStorage' in window) return resolve();

      require.ensure(
        [],
        () => {
          const { storage } = require('local-storage-fallback');
          window.localStorage = storage;

          resolve();
        },
        'local-storage-fallback'
      );
    });
  };

  const fillNodeListForEach = () => {
    new Promise(resolve => {
      if (!window.NodeList || window.NodeList.prototype.forEach) return resolve();

      require.ensure(
        [],
        () => {
          require('nodelist-foreach-polyfill');

          resolve();
        },
        'nodelist-foreach-polyfill'
      );
    });
  };

  return Promise.all([
    fillIntl(),
    fillURLSearchParams(),
    fillLocalStorage(),
    fillNodeListForEach(),
  ]);
}
