import React, { Component } from 'react';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import { connectModal as reduxModal } from 'redux-modal';
import { submit } from 'redux-form';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'ui/common/modal';
import { addWorkExperienceEntry } from 'store/features/work-experience/actions';

import SubmitButton from 'ui/common/form/submit-button';
import createForm from 'ui/work-experience/form';

const { request: addWorkExperienceEntryRequest } = addWorkExperienceEntry;

const messages = defineMessages({
  title: {
    id: 'new-work-experience-entry-dialog.title',
    defaultMessage: 'Add Experience',
  },
  save: {
    id: 'new-work-experience-entry-dialog.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'new-work-experience-entry-dialog.cancel',
    defaultMessage: 'Cancel',
  },
});

export const MODAL_NAME = 'new-work-experience-entry-modal';
const FORM_NAME = 'new-work-experience-entry-form-modal';

class NewWorkExperienceEntry extends Component {
  constructor(props) {
    super(props);

    autoBind(this);
  }

  onSubmit(values) {
    const { currentlyWorking } = values;
    if (currentlyWorking) values.endDate = null;
    this.props.addWorkExperienceEntryRequest(values, { form: FORM_NAME, modal: MODAL_NAME });
  }

  getForm() {
    const Form = createForm({
      form: FORM_NAME,
      onSubmit: this.onSubmit,
    });
    return <Form />;
  }

  render() {
    const { show, intl: { formatMessage }, handleHide } = this.props;

    const form = this.getForm();

    return (
      <Modal isOpen={show} size="lg">
        <ModalHeader toggle={handleHide}>{formatMessage(messages.title)}</ModalHeader>
        <ModalBody>{form}</ModalBody>
        <ModalFooter>
          <SubmitButton size="md" formName={FORM_NAME} explicitlyTriggerSubmit>
            {formatMessage(messages.save)}
          </SubmitButton>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  submit,
  addWorkExperienceEntryRequest,
};

export default connect(null, mapDispatchToProps)(
  reduxModal({
    name: MODAL_NAME,
  })(injectIntl(NewWorkExperienceEntry))
);
