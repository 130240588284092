import { compose, createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import createRavenMiddleware from './raven';

import enMessages from 'resources/translations/locales/en.json';
import analyticsMiddleware from './analytics/middleware';
import rootReducer from './reducer';
import rootSaga from './saga';

export default function creator(raven, history) {
  if (history && history.location && history.location.state && history.location.state.flash)
    delete history.location.state.flash;

  const initialState = {
    intl: {
      locale: 'en',
      messages: enMessages,
    },
  };

  const ravenMiddleware = raven.enabled ? createRavenMiddleware(raven) : null;
  const router = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware({
    onError: ex => raven.captureException(ex),
  });

  const middlewares = [ravenMiddleware, router, sagaMiddleware].filter(Boolean);
  if (window.ga) middlewares.push(analyticsMiddleware);
  const middleware = applyMiddleware(...middlewares);

  const composeEnhancers =
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          serialize: {
            replacer: (key, value) => {
              // Redux Devtools tries to serialize web3 and this causes problems
              // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#it-fails-to-serialize-data-when-passing-synthetic-events-or-calling-an-action-directly-with-redux-actions
              // See workaround 3
              if (key === 'web3') return '[Web3]';

              return value;
            },
          },
        })
      : compose;
  const enhancer = composeEnhancers(middleware);

  const store = createStore(rootReducer, initialState, enhancer);
  sagaMiddleware.run(rootSaga, raven).done.catch(ex => {
    raven.captureException(ex);
  });

  return store;
}
