/*
 * AdminClaimDisapproveModal Messages
 *
 * This contains all the text for the AdminClaimDisapproveModal component.
 */
import { defineMessages } from 'react-intl';
import { fieldNames } from './model';

export default defineMessages({
  [fieldNames.disapprovalReason]: {
    id: 'graphql.AdminClaimDisapproveModal.disapprovalReason',
    defaultMessage: 'Disapproval Reason',
  },
  [fieldNames.selectReason]: {
    id: 'graphql.AdminClaimDisapproveModal.selectReason',
    defaultMessage: 'Select a reason',
  },
  modalTitle: {
    id: 'graphql.AdminClaimDisapproveModal.modalTitle',
    defaultMessage: 'Why do you disapprove this claim?',
  },
  submitButton: {
    id: 'graphql.AdminClaimDisapproveModal.submitButton',
    defaultMessage: 'Disapprove',
  },
});
