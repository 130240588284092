import React, { Component } from 'react';
import { injectIntl } from 'react-intl';

import styles from './index.module.scss';
import classnames from 'classnames';
import MediaQuery from 'react-responsive';
import themeConfig from 'ui/theme/config';
import autoBind from 'react-autobind';
import LineSvg from 'resources/page/ems/metamorph/Line.svg';
import ExternalLink from 'ui/common/external-link';

export class Stats extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  renderDesktop() {
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-5">
            <div className={classnames(styles.mainTitle, 'mb-4')}>
              {/* According to <span>"State of DevOps 2019"</span>, Elite Performers */}
              Research* proves that
            </div>
            <div className={classnames(styles.mainSubtitle)}>Elite performers have...</div>
            <div className={classnames(styles.mainSubtitle, 'mt-5 pt-5 mb-5')}>
              Our pulse surveys and insights are backed by thorough research and extensive
              interviews with more than 100 Engineering Managers across Europe and Southeast Asia
            </div>
            <small>
              *Based on the “State of the DevOps Report 2019”. You can download it{' '}
              <ExternalLink href="https://cloud.google.com/devops/state-of-devops/" openInNewTab>
                here
              </ExternalLink>
            </small>
          </div>
          <div className="col-7">
            <div className="row justify-content-center">
              <div className="col-6">
                <div className={classnames(styles.statCard, styles.blueText)}>
                  <img
                    src="https://indorse-staging-bucket.s3.amazonaws.com/code_metamorph.svg"
                    alt="code_metamorph"
                    width="90"
                  />
                  <div className={styles.number}>208</div>
                  <div className={styles.title}>Times More</div>
                  <div className={styles.subtitle}>Frequent code deployments</div>
                </div>
              </div>
              <div className="col-6">
                <div className={classnames(styles.statCard, styles.blueText)}>
                  <img
                    src="https://indorse-staging-bucket.s3.amazonaws.com/chronometer_metamorph.svg"
                    alt="chronometer_metamorph"
                    width="80"
                  />
                  <div className={styles.number}>106</div>
                  <div className={styles.title}>Times Faster</div>
                  <div className={styles.subtitle}>lead time from commit to deploy</div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-5">
              <div className="col-6">
                <div className={classnames(styles.statCard, styles.greenText)}>
                  <img
                    src="https://indorse-staging-bucket.s3.amazonaws.com/clock_metamorph.svg"
                    alt="clock_metamorph"
                    width="80"
                  />
                  <div className={styles.number}>2,604</div>
                  <div className={styles.title}>Times Faster</div>
                  <div className={styles.subtitle}>time to recover from incidents</div>
                </div>
              </div>
              <div className="col-6">
                <div className={classnames(styles.statCard, styles.greenText)}>
                  <img
                    src="https://indorse-staging-bucket.s3.amazonaws.com/warning-sign_metamorph.svg"
                    alt="warning-sign_metamorph"
                    width="90"
                  />
                  <div className={styles.number}>7</div>
                  <div className={styles.title}>Times Lower</div>
                  <div className={styles.subtitle}>
                    change failure rate<br />
                    <span>(changes are 1/7 as likely to fail)</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classnames(styles.lineOne)}>
              <img src={LineSvg} width="100%" alt="line1" />
            </div>
            <div className={classnames(styles.lineTwo)}>
              <img src={LineSvg} width="100%" alt="line2" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMobile() {
    return (
      <div>
        <div className="row justify-content-center">
          <div className="col-10">
            <div className={classnames(styles.mainTitle, 'mb-4')}>
              {/* According to <span>"State of DevOps 2019"</span>, Elite Performers */}
              Research* proves that
            </div>
          </div>
          <div className="col-10">
            <div className={classnames(styles.mainSubtitle)}>Elite performers have...</div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-10 my-2">
            <div className={classnames(styles.statCard, styles.blueText)}>
              <img
                src="https://indorse-staging-bucket.s3.amazonaws.com/code_metamorph.svg"
                alt="code_metamorph"
                width="90"
              />
              <div className={styles.number}>208</div>
              <div className={styles.title}>Times More</div>
              <div className={styles.subtitle}>Frequent code deployments</div>
            </div>
          </div>
          <div className="col-10 my-2">
            <div className={classnames(styles.statCard, styles.blueText)}>
              <img
                src="https://indorse-staging-bucket.s3.amazonaws.com/chronometer_metamorph.svg"
                alt="chronometer_metamorph"
                width="80"
              />
              <div className={styles.number}>106</div>
              <div className={styles.title}>Times Faster</div>
              <div className={styles.subtitle}>lead time from commit to deploy</div>
            </div>
          </div>
          <div className="col-10 my-2">
            <div className={classnames(styles.statCard, styles.greenText)}>
              <img
                src="https://indorse-staging-bucket.s3.amazonaws.com/clock_metamorph.svg"
                alt="clock_metamorph"
                width="80"
              />
              <div className={styles.number}>2,604</div>
              <div className={styles.title}>Times Faster</div>
              <div className={styles.subtitle}>time to recover from incidents</div>
            </div>
          </div>
          <div className="col-10 my-2">
            <div className={classnames(styles.statCard, styles.greenText)}>
              <img
                src="https://indorse-staging-bucket.s3.amazonaws.com/warning-sign_metamorph.svg"
                alt="warning-sign_metamorph"
                width="90"
              />
              <div className={styles.number}>7</div>
              <div className={styles.title}>Times Lower</div>
              <div className={styles.subtitle}>
                change failure rate<br />
                <span>(changes are 1/7 as likely to fail)</span>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-10">
            <div className={classnames(styles.mainSubtitle, 'mt-4 mb-4')}>
              Our pulse surveys and insights are backed by thorough research and extensive
              interviews with more than 100 Engineering Managers across Europe and Southeast Asia
            </div>
            <small>
              *Based on the “State of the DevOps Report 2019”. You can download it{' '}
              <ExternalLink href="https://cloud.google.com/devops/state-of-devops/" openInNewTab>
                here
              </ExternalLink>
            </small>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={classnames(styles.whiteBackground)}>
        <div className={classnames('page-max-width')}>
          <div className={classnames(styles.heroContentCompany, 'container-fluid')}>
            <div>
              <MediaQuery minWidth={themeConfig.layout.gridBreakpoints.lg}>
                {this.renderDesktop()}
              </MediaQuery>
              <MediaQuery maxWidth={themeConfig.layout.gridBreakpoints.sm - 1}>
                {this.renderMobile()}
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Stats);
