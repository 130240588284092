import { generateSortParameterURLValue } from 'utils/sort';
export { updateCurrentUser } from './auth';

export function fetchUsers({
  filters = {},
  pageNo,
  perPage = Number(process.env.REACT_APP_ADMIN_DEFAULT_PAGE_SIZE) || 50,
  sort,
  role,
}) {
  const sortParam = generateSortParameterURLValue(sort);
  const qs = new URLSearchParams();
  if (filters)
    Object.keys(filters).forEach(filterName => {
      const filterValue = filters[filterName];
      if (typeof filterValue !== undefined && filterValue !== null && filterValue !== [])
        qs.set(filterName, filterValue);
    });
  if (sortParam && sortParam.length > 0) qs.set('sort', sortParam);
  qs.set('pageNo', pageNo || 1);
  qs.set('perPage', perPage || 1);
  qs.set('role', role || '');

  return {
    endpoint: `users?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function approveUser({ userId: approveUserId }) {
  return {
    endpoint: 'users/approve',
    method: 'POST',
    data: { approveUserId },
    requireAuth: true,
  };
}

export function disapproveUser({ userId: approveUserId }) {
  return {
    endpoint: 'users/disapprove',
    method: 'POST',
    data: { approveUserId },
    requireAuth: true,
  };
}

export function fetchUserById({ userId }) {
  return {
    endpoint: 'me',
    method: 'POST',
    data: { userId },
    requireAuth: !userId,
  };
}

export function fetchUserByUsername({ username }) {
  return {
    endpoint: 'me',
    method: 'POST',
    data: { username },
    requireAuth: !username,
  };
}

export function assignRole({ userId, role }) {
  return {
    endpoint: 'changeuserrole',
    method: 'POST',
    data: { userId, role },
    requireAuth: true,
  };
}

export function importLinkedInPdf({ file }) {
  return {
    endpoint: 'parse-cv',
    method: 'POST',
    data: { cv: file },
    requireAuth: true,
  };
}

export function autoSuggestUsers(values) {
  const fields = ['name', 'username', 'email'].join(',');

  const qs = new URLSearchParams();
  qs.set('search', values);
  qs.set('fields', fields);

  return {
    endpoint: `users/?${qs}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function fetchUserAdvisories({ userId }) {
  return {
    endpoint: `users/${userId}/advisories`,
    method: 'GET',
  };
}

export function fetchCurrentUserAdvisories() {
  return {
    endpoint: `me/advisories`,
    method: 'GET',
    requireAuth: true,
  };
}

export function fetchAdvisory({ prettyId, advisoryId }) {
  return {
    endpoint: `companies/${prettyId}/advisor/${advisoryId}`,
    method: 'GET',
    optionalAuth: true,
  };
}

export function acceptAdvisory({ companyPrettyId, advisoryId, txHash }) {
  const data = { txHash };
  if (!txHash) delete data.txHash;

  return {
    endpoint: `companies/${companyPrettyId}/advisors/${advisoryId}/accept`,
    method: 'POST',
    requireAuth: true,
    data: data,
  };
}

export function rejectAdvisory({ companyPrettyId, advisoryId }) {
  return {
    endpoint: `companies/${companyPrettyId}/advisors/${advisoryId}/reject`,
    method: 'POST',
    requireAuth: true,
  };
}

export function revokeAdvisory({ companyPrettyId, advisoryId, txHash }) {
  const data = { txHash };
  if (!txHash) delete data.txHash;

  return {
    endpoint: `companies/${companyPrettyId}/advisors/${advisoryId}`,
    method: 'DELETE',
    requireAuth: true,
    data: data,
  };
}

export function fetchUserTeamMemberships({ userId }) {
  return {
    endpoint: `users/${userId}/team_memberships`,
    method: 'GET',
  };
}

export function fetchCurrentUserTeamMemberships() {
  return {
    endpoint: `me/team_memberships`,
    method: 'GET',
    requireAuth: true,
  };
}

export function fetchTeamMembership({ prettyId, teamMembershipId }) {
  return {
    endpoint: `companies/${prettyId}/team_member/${teamMembershipId}`,
    method: 'GET',
    requireAuth: true,
  };
}

export function acceptTeamMembership({ companyPrettyId, teamMembershipId, txHash }) {
  const data = { txHash };
  if (!txHash) delete data.txHash;

  return {
    endpoint: `companies/${companyPrettyId}/team_members/${teamMembershipId}/accept`,
    method: 'POST',
    requireAuth: true,
    data: data,
  };
}

export function rejectTeamMembership({ companyPrettyId, teamMembershipId }) {
  return {
    endpoint: `companies/${companyPrettyId}/team_members/${teamMembershipId}/reject`,
    method: 'POST',
    requireAuth: true,
  };
}

export function revokeTeamMembership({ companyPrettyId, teamMembershipId, txHash }) {
  const data = { txHash };
  if (!txHash) delete data.txHash;

  return {
    endpoint: `companies/${companyPrettyId}/team_members/${teamMembershipId}`,
    method: 'DELETE',
    requireAuth: true,
    data: data,
  };
}

// Only set
export function setEthereumAddress({ userId, ethereumAddress }) {
  return {
    endpoint: `users/${userId}/ethaddress`,
    method: 'POST',
    data: { address: ethereumAddress },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function getSmartContractDeatils({ network, contractName }) {
  return {
    endpoint: `ethereum/${network}/contracts/${contractName}`,
    method: 'GET',
    requireAuth: true,
  };
}

// Set and verify
export function setCurrentUserEthereumAddress({ ethereumAddress, signature }) {
  return {
    endpoint: `users/ethaddress`,
    method: 'POST',
    data: { ethaddress: ethereumAddress, signature },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function deleteCurrentUser({ values }) {
  const { password } = values;
  return {
    endpoint: `mydatarequest`,
    method: 'POST',
    decamlizeInput: false,
    data: { password, requestType: 'delete' },
    requireAuth: true,
    convertApiErrorsToReduxFormErrors: true,
  };
}

export function softAcceptAdvisory({ companyPrettyId, advisoryId }) {
  return {
    endpoint: `companies/${companyPrettyId}/advisors/${advisoryId}/soft-accept`,
    method: 'POST',
    optionalAuth: true,
  };
}

export function softRejectAdvisory({ companyPrettyId, advisoryId }) {
  return {
    endpoint: `companies/${companyPrettyId}/advisors/${advisoryId}/soft-reject`,
    method: 'POST',
    optionalAuth: true,
  };
}

export function softValidateAdvisoryUsingLinkedin({ companyPrettyId, advisoryId, linkedIn }) {
  // const { code, state, redirectUri } = linkedIn;
  return {
    endpoint: `companies/${companyPrettyId}/advisors/${advisoryId}/validate/linked-in`,
    method: 'POST',
    data: { linkedIn },
    optionalAuth: true,
  };
}
