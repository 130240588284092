import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isSubmitting, isValid, submit } from 'redux-form';

import Button from 'ui/common/button';

const propTypes = {
  formName: PropTypes.string.isRequired,
  explicitlyTriggerSubmit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  ...Button.propTypes,
};

const defaultProps = { color: 'primary', size: 'lg', style: { cursor: 'pointer' } };

const SubmitButton = ({
  className,
  formName,
  loading,
  valid,
  submitting,
  submit,
  dispatch,
  explicitlyTriggerSubmit,
  ...passThrough
}) => (
  <Button
    className={className}
    type={explicitlyTriggerSubmit ? 'button' : 'submit'}
    loading={loading || submitting}
    isReady={valid}
    onClick={e => {
      let submitOnClick = false;
      if (explicitlyTriggerSubmit)
        submitOnClick =
          typeof explicitlyTriggerSubmit === 'function'
            ? explicitlyTriggerSubmit(e)
            : !!explicitlyTriggerSubmit;
      if (!(e && e.defaultPrevented) && submitOnClick && formName && submit) submit(formName);
    }}
    {...passThrough}
  />
);

SubmitButton.propTypes = propTypes;

SubmitButton.defaultProps = defaultProps;

function mapStateToProps(state, ownProps) {
  const formName = ownProps && ownProps.formName;

  if (formName) {
    return { submitting: isSubmitting(formName)(state), valid: isValid(formName)(state) };
  } else {
    return {};
  }
}

const mapDispatchToProps = {
  submit,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitButton);
