import React, { Component } from 'react';
import { connect } from 'react-redux';

import ConnectToSlackButton from 'ui/graphql/ConnectToSlackButton';
import Loading from 'ui/common/loading';
import { withRouter } from 'react-router-dom';

import * as storage from 'store/features/auth/storage';
import { slackMethod } from 'resources/slack';
import {
  selectAuthenticatingWithSlack,
  selectError,
} from 'store/graphql/SlackAuthCallback/selectors';
import { selectOk } from 'store/graphql/SlackAuthButton/selectors';

import { compose } from 'redux';
import injectReducer from 'utils/injectReducer';
import SlackAuthCallbackReducer from 'store/graphql/SlackAuthCallback/reducer';
import SlackAuthButtonReducer from 'store/graphql/SlackAuthButton/reducer';

export class SlackConnectCallback extends Component {
  render() {
    const { isAuthenticatingWithSlack, isOk, prettyId, from } = this.props;
    const authenticationWithSlack = storage.getDataInLocalStorage(
      slackMethod.authenticationWithSlack
    );
    if (isAuthenticatingWithSlack || isOk) return <Loading />;

    return (
      <div>
        {authenticationWithSlack && <ConnectToSlackButton prettyId={prettyId} from={from} />}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const isAuthenticatingWithSlack = selectAuthenticatingWithSlack(state);
  const error = selectError(state);
  const routeParams = props && props.match && props.match.params;
  const prettyId = routeParams && routeParams.prettyId;
  const location = window.location;
  const authData = new URLSearchParams(location.search);
  const from = authData.get('from');
  const isOk = selectOk(state);

  return {
    isAuthenticatingWithSlack,
    error,
    prettyId,
    from,
    isOk,
  };
}

const withConnect = connect(mapStateToProps, null);
const withReducer = injectReducer({
  key: 'SlackAuthCallback',
  reducer: SlackAuthCallbackReducer,
});

const withSlackAuthButtonReducer = injectReducer({
  key: 'SlackAuthButton',
  reducer: SlackAuthButtonReducer,
});

export default withRouter(
  compose(withSlackAuthButtonReducer, withReducer, withConnect)(SlackConnectCallback)
);
