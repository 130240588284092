import { call, put, fork, takeLatest, take } from 'redux-saga/effects';
import { eventChannel, END, delay } from 'redux-saga';

import loadScript from 'load-script';

import { getWeb3Async, getInjectedWeb3Provider } from 'ethereum/utils';

import * as actionTypes from './action-types';
import * as actions from './actions';

function loadScriptToPromise(src) {
  return new Promise((resolve, reject) => {
    loadScript(src, function(err, script) {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });
}

function* loadScriptWithRetry(src, times) {
  for (let i = 1; i <= times; i++) {
    try {
      yield call(loadScriptToPromise, src);
      return;
    } catch (ex) {
      if (i < times) {
        yield delay(100);
      } else {
        throw ex;
      }
    }
  }
}

function checkIfGrecaptchaExecuteAvailable(retryTimes) {
  const intervalDuration = 300;
  return new Promise((resolve, reject) => {
    try {
      const interval = window.setInterval(() => {
        if (window.grecaptcha && window.grecaptcha.execute) {
          window.clearInterval(interval);
          resolve(true);
        }
      }, intervalDuration);

      window.setTimeout(() => {
        window.clearInterval(interval);
        resolve(false);
      }, intervalDuration * retryTimes);
    } catch (err) {
      reject(err.message);
    }
  });
}

function* loadFacebookLibs() {
  try {
    yield put(actions.loadFacebookSdkLib.start());
    yield call(loadScriptWithRetry, 'https://connect.facebook.net/en_US/sdk.js', 3);
    yield put(actions.loadFacebookSdkLib.success());
  } catch (ex) {
    yield put(actions.loadFacebookSdkLib.failure(ex));
  }
}

function loadGoogleAuthLib() {
  return new Promise(resolve => {
    window.gapi.load('auth2', resolve);
  });
}

function* loadGoogleLibs() {
  try {
    yield put(actions.loadGoogleApiLib.start());
    yield call(loadScriptWithRetry, 'https://apis.google.com/js/api.js', 3);
    yield put(actions.loadGoogleApiLib.success());

    try {
      yield put(actions.loadGoogleAuthLib.start());
      yield call(loadGoogleAuthLib);
      yield call(window.gapi.auth2.init, {
        client_id: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
        scopes: 'profile,email',
      });
      yield put(actions.loadGoogleAuthLib.success());
    } catch (authEx) {
      yield put(actions.loadGoogleAuthLib.failure(authEx));
    }
  } catch (ex) {
    yield put(actions.loadGoogleApiLib.failure(ex));
  }
}

function createDocumentReadyChannel() {
  return eventChannel(emitter => {
    if (document.readyState === 'complete') {
      emitter(document.readyState);
      emitter(END);

      return () => {};
    } else {
      const handler = function(e) {
        emitter(document.readyState);
        emitter(END);
      };

      window.addEventListener('load', handler);

      return () => {
        window.removeEventListener('load', handler);
      };
    }
  });
}

function* loadWeb3Lib() {
  yield put(actions.loadWeb3Lib.start());

  try {
    const documentReadyChannel = yield call(createDocumentReadyChannel);
    yield take(documentReadyChannel);

    const Web3 = yield call(getWeb3Async);
    const web3Provider = getInjectedWeb3Provider();
    if (Web3 && web3Provider) {
      yield put(actions.loadWeb3Lib.success());
    } else {
      yield put(actions.loadWeb3Lib.failure(new Error('Not injected')));
    }
  } catch (ex) {
    yield put(actions.loadWeb3Lib.failure(ex));
  }
}

/* ID-1816, only load social libs when necessary to speed up the landing page */
function* watchLoadWeb3() {
  yield takeLatest(actionTypes.LOAD_WEB3_LIB.REQUEST, loadWeb3Lib);
}

function* watchLoadFacebookLibs() {
  yield takeLatest(actionTypes.LOAD_FACEBOOK_SDK_LIB.REQUEST, loadFacebookLibs);
}

function* watchLoadGoogleLibs() {
  yield takeLatest(actionTypes.LOAD_GOOGLE_AUTH_LIB.REQUEST, loadGoogleLibs);
}

function* loadReCaptchaSdkLibs() {
  try {
    yield put(actions.loadReCaptchaSdkLib.start());
    yield call(
      loadScriptWithRetry,
      `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_CLIENT_ID}`,
      3
    );
    const isGrecaptchaAvailable = yield call(checkIfGrecaptchaExecuteAvailable, 10);
    if (isGrecaptchaAvailable) {
      yield put(actions.loadReCaptchaSdkLib.success());
    } else {
      yield put(actions.loadReCaptchaSdkLib.failure('window.grecaptcha.execute is not available'));
    }
  } catch (ex) {
    yield put(actions.loadReCaptchaSdkLib.failure(ex));
  }
}

function* loadStripeCheckoutLib() {
  try {
    yield put(actions.loadStripeCheckoutLib.start());
    yield call(loadScriptWithRetry, 'https://checkout.stripe.com/checkout.js', 3);
    yield put(actions.loadStripeCheckoutLib.success());
  } catch (ex) {
    yield put(actions.loadStripeCheckoutLib.failure(ex));
  }
}

export default function* libs() {
  yield fork(loadReCaptchaSdkLibs);
  yield fork(watchLoadFacebookLibs);
  yield fork(watchLoadGoogleLibs);
  yield fork(watchLoadWeb3);
  yield fork(loadStripeCheckoutLib);
}
